import Header from "../../../../Components/Header/Header"
import Sidebar from "../../../../Components/Sidebar/Sidebar"
import React, { useEffect, useState } from 'react'
import DesignationPopup from "./DesignationPopup"
import DeletePopup from "../GardenPopup/DeletePopup"
import { DesignationDDL } from "../../../../Components/CommonDDL/DesignationDDL"
import { useAuthState } from "../../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { DesignationDeleteAPI, DesignationTableDataAPI } from "../../../../Redux/SidebarMasterSlice/DesignationSlice"
import { Pegination } from "../../../../Components/Pegination/Pegination"
import { Loading } from "../../../../Helper/Loading"
import { useTranslation } from "react-i18next"

export const Designation = () => {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [DeletePopupShow, setDeletePopupShow] = useState(false)
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')

    const [Designation, setDesignation] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    const [DesignationPopupShow, setDesignationPopupShow] = useState()

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setDesignationPopupShow(true)
    }
    const handleEditOnClick = (item) => {
        setDesignationPopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }

    const handleOnCloseClick = () => {
        setDesignationPopupShow(false)
    }

    const handleClear = () => {
        setFrom(1)
        setTo(10)
        setCurrentPage(0)
        setIsClear(!IsClear)
        setDesignation({
            ...Designation,
            ID: 0,
            Label: 'Select...',
        })

    }


    const handleIsPost = () => {
        setIsPost(!IsPost)
    }


    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }

    useEffect(() => {
        const data = {
            M_DesignationID: Designation.ID,
            DesignationName: '',
            M_UsersID: UserID,
            token: token,
            From: From,
            To: To,
            Flag: ApiFlag,
        }
        {Language.Label !='' && dispatch(DesignationTableDataAPI({ data }))}

    }, [IsPost, To, IsClear, Designation.ID,Language.Label])

    const { tableData, isLoading } = useSelector(state => state.DesignationTableData)

    // useEffect(() => {
    //     setCurrentPage(0)
    //     setFrom(1)
    //     setTo(10)
    // }, [Designation.ID])

    const handleDeleteApi = (item) => {

        const data = {
            M_DesignationID: item.id,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost
        }
        dispatch(DesignationDeleteAPI({ data }))
    }
    const { isDeleteLoading } = useSelector(state => state.DesignationDeleteData)

    return (
        <>
            {isLoading && <Loading />}
            {isDeleteLoading && <Loading />}
            <div>
                <Header
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper p-3">
                    <Sidebar
                        Language={Language}
                        ProjectTypeId={ProjectTypeId}
                        active="designation" listActive="active" />
                    <div id="" className="content1">
                        <div className="data_mob">
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div>
                                    <h5 className="ps-1 py-1">{t('AdminDashboard.Admin_Sidebar_Grid_Heading.Designation_Master')}</h5>
                                    <div className="col-12 col-lg-7 float-end">
                                        <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                                            style={{ color: "white", marginTop: '-2.5em' }} onClick={handleAddOnClick}
                                        >
                                            <text className="ml-2 poppins">Add</text>
                                        </i>
                                    </div>
                                </div>
                                <div className="row m-0 ">
                                    <div className="col-12 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12">

                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-4 col-lg-3">
                                                    <DesignationDDL
                                                        Designation={Designation}
                                                        setDesignation={setDesignation}
                                                        IsPost={IsPost}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3 col-md-1 mt-md-4 mt-lg-4" >
                                                    <button type="button"
                                                        className="btn-clear btn btn-primary"
                                                        onClick={handleClear}>Clear</button>
                                                </div>
                                            </div>
                                            <div className="row mob_table table-responsive pt-1">

                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            <th>Designation Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) =>
                                                                <tr style={{ width: '100%' }}>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                    <td>{item.designationName ? item.designationName : '-'}</td>
                                                                    <td style={{ textAlign: "center", width: '7%' }}>
                                                                        <span>
                                                                            <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => handleEditOnClick(item)}
                                                                            >
                                                                            </i>
                                                                        </span>

                                                                        <span>
                                                                            <DeletePopup
                                                                                open={DeletePopupShow}
                                                                                handleDeleteApi={handleDeleteApi}
                                                                                item={item}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ) :
                                                                <>No Found data</>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {
                    DesignationPopupShow ?
                        <DesignationPopup
                            open={DesignationPopupShow}                       
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                        />
                        : <></>
                }
            </div>

        </>
    )
}