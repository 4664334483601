
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

// Get Table Data
export const WardWiseWorkTableDataAPI = createAsyncThunk("WardWiseWorkTableData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_WardID,
        M_UsersID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_RM_T_KPI_WorkProgressCompleted_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : '0'}&M_MonthID=${M_MonthID ? M_MonthID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_UsersID=${M_UsersID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            // console.log(result.data1, result.data) 
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result

            } else {
                return result
            }
        })
})

const WardWiseWorkTableDataSlice = createSlice({
    name: "WardWiseWorkTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WardWiseWorkTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WardWiseWorkTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WardWiseWorkTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WardWiseWorkTableDataReducer = WardWiseWorkTableDataSlice.reducer


