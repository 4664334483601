import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup as Pop, FeatureGroup, Polyline } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css"
import moment from "moment";
import { useAuthState } from "../../Helper/Context";
import { Loading } from "../../Helper/Loading";
import Popup from 'reactjs-popup'
import pin from './pin.png'
import flag from './dot.png'
import ViewURL from "../ViewURL/ViewURL";


const StartMarker = new L.Icon({
    iconUrl: pin,
    iconSize: [60, 60],
    iconAnchor: [30, 58],
    popupAnchor: [2, -50]
});

const customMarker = new L.Icon({
    iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
    iconSize: [35, 53],
    iconAnchor: [17, 50],
    popupAnchor: [2, -40]
});

const logo = "./logo-01.png"
const CurrentLocationMarker = new L.Icon({
    // iconUrl: "https://gayatrierp.in/assets/img/GME%20LOGO.jpg",
    iconUrl: logo,
    iconSize: [41, 41],
    iconAnchor: [20, 41],
    popupAnchor: [2, -40]
});


const FlagLocationMarker = new L.Icon({
    // iconUrl: "https://gayatrierp.in/assets/img/GME%20LOGO.jpg",
    iconUrl: flag,
    iconSize: [22, 22],
    iconAnchor: [7, 15],
    popupAnchor: [2, -40]
});

const MapView = (props) => {
    const { handleCloseMap, ShowMap, LoactionData, currEmployeeName, item } = props

    const userDetails = useAuthState();
    const { username } = userDetails

    const [Loader, setLoading] = useState(false)
    const [Heading, setHeading] = useState('')


    const markerData = [
        [18.50, 73.11],
        [18.709314735041126, 73.24093007633333],
        [18.326834494919822, 73.01220830472941],
        [18.746092403058576, 72.89410528292431],
        [18.57695068266547, 73.19897587409558]
    ]


    let geometries = [];

    LoactionData.forEach(item => {
        geometries = [...geometries, [item.beforeWorkLatitude, item.beforeWorkLongitude]];
    });


    const LoactionDataLength = LoactionData ? LoactionData.length - 1 : 0

    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')

    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }

    // console.log(LoactionData)

    return (
        <>
            <Popup className='assigns' open={ShowMap} closeOnDocumentClick={false}
                onClose={handleCloseMap}
                contentStyle={{ overflowY: 'auto', overflowX: 'auto', height: '95vh' }}
            >

                <div className="call d-flex justify-content-between mb-2">
                    <h5> Track Location</h5>

                    <span className="close" onClick={handleCloseMap}
                        style={{ cursor: 'pointer' }}
                    >
                        &times;
                    </span>
                </div>
                <div className="modal-body" >
                    {
                        Loader ? <Loading />
                            :

                            <MapContainer
                                center={[LoactionData && LoactionData[0].beforeWorkLatitude, LoactionData && LoactionData[0].beforeWorkLongitude]}
                                zoom={13}
                                style={{ height: "80vh" }}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                />

                                {/* <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                /> */}

                                <FeatureGroup>
                                    {
                                        LoactionData && LoactionData.length > 0 && LoactionData.map((item) => {
                                            return (
                                                <Marker position={[item.beforeWorkLatitude, item.beforeWorkLongitude]} icon={customMarker} title={`Current Location of ${currEmployeeName}`}>

                                                    <Pop>

                                                        <div className='marker-popup'>

                                                            <p className="m-1">	Name of Contractor : <b>{item && item.contractorName ? item.contractorName : "-"}</b></p>
                                                            <p className="m-1">	Prabhag No : <b>{item && item.wardName ? item.wardName : "-"}</b></p>
                                                            <p className="m-1">	Sector Name : <b>{item.locationName ? item.locationName : "-"}</b></p>
                                                            <p className="m-1">	Area Name : <b>{item.workArea ? item.workArea : "-"}</b></p>
                                                            <p className="m-1">	Work Name : <b>{item.workName ? item.workName : "-"}</b></p>
                                                            <p className="m-1">	Work Upload By : <b>{item.uploadBy ? item.uploadBy : "-"}</b></p>
                                                            <p className="m-1">	Before Work Image: <b><i class="fa-solid fa-image ms-3" style={{ cursor: 'pointer' }} onClick={() => (handleShowImage(item.beforeWork), setHeading('Before Work Image'))} ></i></b></p>
                                                            <p className="m-1">	Before Photo Latitude: <b>{item.beforeWorkLatitude ? item.beforeWorkLatitude : "-"}</b></p>
                                                            <p className="m-1">	Before Photo Longitude: <b>{item.beforeWorkLongitude ? item.beforeWorkLongitude : "-"}</b></p>
                                                            <p className="m-1">	Work Upload Date: <b>{item.workUploadDate ? moment(item.workUploadDate).format('DD/MM/YYYY') : "-"}</b></p>
                                                            <p className="m-1">	Work Approved By: <b>{item.approved_By ? item.approved_By : "-"}</b></p>
                                                            <p className="m-1">	JE/DE Name: <b>{item.jE_DE_ByName ? item.jE_DE_ByName : "-"}</b></p>
                                                            <p className="m-1">	Approved Date: <b>{item.workUploadDate ? moment(item.workUploadDate).format('DD/MM/YYYY') : "-"}</b></p>
                                                            <p className="m-1">	Work done date: <b>{item.doneDate ? moment(item.doneDate).format('DD/MM/YYYY') : "-"}</b></p>
                                                            <p className="m-1">	After Work Image: <b><i class="fa-solid fa-image ms-3" style={{ cursor: 'pointer' }} onClick={() => (handleShowImage(item.afterWork), setHeading('After Work Image'))} ></i></b></p>
                                                            <p className="m-1">	After Photo Latitude: <b>{item.afterWorkLatitude ? item.afterWorkLatitude : "-"}</b></p>
                                                            <p className="m-1">	After Photo Longitude: <b>{item.afterWorkLongitude ? item.afterWorkLongitude : "-"}</b></p>
                                                            <p className="m-1">	Estimated Amount(Rs.): <b>{item.estimatedAmount ? item.estimatedAmount : "-"}</b></p>

                                                        </div>
                                                    </Pop>
                                                </Marker>
                                            )
                                        })
                                    }

                                    {/* <Polyline positions={[geometries]} color={'blue'} /> */}

                                </FeatureGroup>
                            </MapContainer>


                    }

                </div>
                {
                    showImagePopup ?
                        <ViewURL
                            Heading={Heading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''


                }
            </Popup>
        </>
    );
}


export default MapView;
