
import { useDispatch, useSelector } from "react-redux"
import Header from "../../../../Components/Header/Header"
import Sidebar from "../../../../Components/Sidebar/Sidebar"
import { useAuthState } from "../../../../Helper/Context"
import DeletePopup from "../GardenPopup/DeletePopup"
import ZoneMasterPopup from "./ZoneMasterPopup"
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { ZoneMasterDeleteAPI, ZoneMasterTableDataAPI } from "../../../../Redux/SidebarMasterSlice/ZoneMasterSlice"
import { Loading } from "../../../../Helper/Loading"
import { ZoneDataDDLS } from "../../../../Components/CommonDDL/ZoneDataDDLS"
import { Pegination } from "../../../../Components/Pegination/Pegination"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"


export const ZoneMaster = () => {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [ZoneName, setZoneName] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [IsPost, setIsPost] = useState(false)

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsClear, setIsClear] = useState(false)

    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')
    const [ZoneMasterPopupShow, setZoneMasterPopupShow] = useState()

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setZoneMasterPopupShow(true)
    }
    const handleEditOnClick = (item) => {
        setZoneMasterPopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }

    const handleOnCloseClick = () => {
        setZoneMasterPopupShow(false)
    }

    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select Ward",
    // })
    // const [Language, setLanguage] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: Lang,
    // })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [Language])

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setZoneName({
            ...ZoneName,
            ID: 0,
            Label: 'Select...'
        })
    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
        // if (tableData && tableData.table && tableData.table.length > 0) {
        //     setCurrentPage(0)
        //     setFrom(1)
        //     setTo(10)
        // }    
    }

    useEffect(() => {
        const data = {
            M_ZoneID: ZoneName.ID,
            ZoneName: '',
            M_UsersID: UserID,
            token: token,
            From: From,
            To: To,
            Language: Language.Label
        }
        {Language.Label !='' && dispatch(ZoneMasterTableDataAPI({ data }))}
    }, [ZoneName.ID, IsPost, IsClear,Language.Label])

    const { tableData, isLoading } = useSelector(state => state.ZoneMasterTableData)

    const handleDeleteApi = (item) => {
        const data = {
            M_ZoneID: item.m_ZoneID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost
        }

        dispatch(ZoneMasterDeleteAPI({ data }))
    }

    return (
        <>
            {isLoading && <Loading />}

            <div>
                <Header
                    // DashboardHeading="Garden Maintainance"
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar
                        Language={Language}
                        ProjectTypeId={ProjectTypeId} />
                    <div className="content">
                        <div className="data_mob">
                            <div className="row  m-1 py-1 pb-4 master_border">
                                <div>
                                    <h5 className="ps-1 py-1">{t('AdminDashboard.Admin_Sidebar_Grid_Heading.Zone_Master')}</h5>
                                    <div className="col-12 col-lg-7 float-end">
                                        <i className="fa-solid fa-plus btn btn-secondary float-end mb-2"
                                            onClick={handleAddOnClick} style={{ color: "white", marginTop: '-2.5em' }}>
                                            <text className="ml-2 poppins">Add</text>
                                        </i>
                                    </div>
                                </div>
                                <div className="row m-0 ">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12  ">

                                            <div className="row rounded2 d-flex">
                                                <div className="col-12 col-md-4 col-lg-3">
                                                    <ZoneDataDDLS
                                                        ZoneName={ZoneName}
                                                        setZoneName={setZoneName}
                                                        IsPost={IsPost}
                                                        Language={Language}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-1 col-lg-1 mt-lg-4 mt-md-4" >
                                                    <button type="button" className="btn-clear btn btn-primary"
                                                        onClick={handleClear}
                                                    >Clear</button>
                                                </div>
                                            </div>
                                            <div className="row mob_table table-responsive pt-1">

                                                <table id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                                            <th>{t('AdminDashboard.Admin_Sidebar_Table_Heading.Zone_Name')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Action')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => (
                                                                <tr style={{ width: '100%' }}>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum}</td>
                                                                    <td>{item.zoneName ? item.zoneName : '-'}</td>
                                                                    <td style={{ textAlign: "center", width: '7%' }}>
                                                                        <span>
                                                                            <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => handleEditOnClick(item)}
                                                                            >
                                                                            </i>
                                                                        </span>
                                                                        <DeletePopup
                                                                            open={DeletePopupShow}
                                                                            item={item}
                                                                            handleDeleteApi={handleDeleteApi}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))
                                                                :
                                                                <></>
                                                        }

                                                    </tbody>
                                                </table>

                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {
                    ZoneMasterPopupShow ?
                        <ZoneMasterPopup
                            open={ZoneMasterPopupShow}
                            // popupHeading="Location Name"                         
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                        />
                        : <></>
                }
            </div>
        </>

    )
}