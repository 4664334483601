import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { ProjectNameDataDDL } from '../../../../Components/CommonDDL/ProjectNameDataDDL';
import { ContractorNameDDL } from '../../../../Components/CommonDDL/ContractorNameDDL';
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { ProjectWiseContractorPostDataAPI } from '../../../../Redux/SidebarMasterSlice/ProjectWiseContractorSlice';
import moment from "moment";
import { ProjectTypeDataDDL } from '../../../../Components/CommonDDL/ProjectTypeDataDDL';
import { GDProjectNameDDL } from '../../../../Components/CommonDDL/GDProjectNameDDL';
import { AstricSign } from '../../../../Helper/AstricSign';
import { WorkTypeDDL } from '../../../../Components/CommonDDL/WorkTypeDDL';
import { ContractorWiseAssetDDL } from '../../../../Components/CommonDDL/WorkTypeWiseContractorDDL';


export default function ProjectWiseContractorPopup(props) {

    const { handleOnCloseClick, open, EditIcon, Flag, GardenMasterFlag, popupHeading, M_AssetTypeID, handleIsPost, apiFlag, rowData, HWard, WLable } = props
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    // const [rowData, setRowData] = useState(rowData)

    const [ProjectType, setProjectType] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_ProjectTypeID : 0,
        Label: Flag === 'Update' ? rowData.projectType : "Select...",
    })
    const [ProjectName, setProjectName] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.projectNameID : 0,
        Label: Flag === 'Update' ? rowData.projectName : "Select...",
    })

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_WorkTypeID : 0,
        Label: Flag === 'Update' ? rowData.workTypeName : "Select...",
    })

    const [ContractorName, setContractorName] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.contractorNameID : 0,
        Label: Flag === 'Update' ? rowData.contractorName : "Select...",
    })
    const [ContractorWiseAsset, setContractorWiseAsset] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    // const handleClearField = () => {
    //     if (Flag != 'Update') {
    //         // ProjectWiseContractorPopUpField('')
    //     }
    // }

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    const [gridData, setgridData] = useState([])

    const [ToDate, setToDate] = useState(Flag === 'Update' ? moment(rowData?.toDate).format('YYYY-MM-DD') : "")
    const [FromDate, setFromDate] = useState(Flag === 'Update' ? moment(rowData?.fromDate).startOf('month').format('YYYY-MM-DD') : "")
    const [IsOpen, setIsOpen] = useState(false)

    const handlePost = () => {
        // console.log(ProjectName.ID)
        const data = {
            M_ProjectWiseContractorID: Flag === 'Update' ? rowData.m_ProjectWiseContractorID : '0',
            M_FinancialYearID: '0',
            M_MonthID: '0',
            M_WorkTypeID: WorkType.ID,
            M_ProjectID: ProjectName.ID ? ProjectName.ID : Flag === 'Update' ? rowData.projectNameID : '0',
            M_ContractorID: ContractorName.ID,
            FromDate: FromDate,
            ToDate: ToDate,
            M_StatusID: '1',
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(ProjectWiseContractorPostDataAPI({ data }))

    }

    return (

        // <Popup
        //     onOpen={() => setIsOpen(!IsOpen)}
        //     contentStyle={{ top: '5%' }}
        //     trigger={
        //         Flag === 'Update' ?
        //             <i className="fa-solid fa-pen-to-square"
        //             style={{ cursor: "pointer" }} title='Edit'
        //             ></i>
        //             :
        //             <i className="fa-solid fa-plus btn btn-secondary mb-2 float-end" style={{ color: "white", fontSize: '15px', marginTop: '-2.5em' }}>
        //                 <text className="ml-2 poppins">Add</text>
        //             </i>
        //     }
        //     modal>
        // {
        //     close => (
        // n_popup-content 
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick} contentStyle={{ width: "65vw" }}
        >
            <div className="area_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Project Wise Contractor</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">
                                <div className="col-12 col-lg-6">
                                    <WorkTypeDDL
                                        WorkType={WorkType}
                                        setWorkType={setWorkType}
                                        Language={Language}
                                        Flag1='ProjectType'
                                        rowData={rowData}
                                        Flag={Flag}
                                    />
                                </div>
                                {/* <div className="col-12 col-md-5 col-lg-6">
                                    <WorkTypeDDL
                                        WorkType={WorkType}
                                        setWorkType={setWorkType}
                                        // IsPost={IsPost}
                                        Language={Language}
                                        Flag1='ProjectType'
                                    />
                                </div> */}
                                {/* <div className="col-12 col-md-6 col-lg-6">
                                    <ContractorWiseAssetDDL
                                        ContractorWiseAsset={ContractorWiseAsset}
                                        setContractorWiseAsset={setContractorWiseAsset}
                                        Language={Language}
                                        rowData={rowData}
                                        // Flag={Flag}
                                        M_WorkTypeID={WorkType.ID}
                                    // Flag1='ContractorWiseAsset'
                                    />
                                </div> */}
                                <div className="col-12 col-lg-6">
                                    <GDProjectNameDDL
                                        ProjectName={ProjectName}
                                        setProjectName={setProjectName}
                                        rowData={rowData}
                                        Flag={Flag}
                                        M_ProjectTypeID={ProjectType.ID}

                                    />
                                </div>
                                <div className="col-12 col-lg-6 ">

                                    <ContractorNameDDL
                                        ContractorName={ContractorName}
                                        setContractorName={setContractorName}
                                        rowData={rowData}
                                        Flag='Master'
                                        Language={Language}
                                    />
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <label for="exampleInputEmail1" class="text-black"><b>From Date <AstricSign /> :</b></label>
                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={FromDate}
                                        onChange={(e) => setFromDate(e.target.value)}
                                    />
                                </div>
                                <div class="col-12 col-md-6 col-lg-6">
                                    <label for="exampleInputEmail1" class="text-black"><b>To Date <AstricSign /> :</b></label>
                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={ToDate}
                                        onChange={(e) => setToDate(e.target.value)}
                                        // min={FromDate}
                                        // max={ToDate}
                                    />
                                </div>

                            </div>

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                        disabled={
                                            ProjectName.ID == 0 || ContractorName.ID == 0 || FromDate == '' || ToDate == ''
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* )} */}
        </Popup>
    )

}