import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";


// Get Table Data
export const PHTableDataAPI = createAsyncThunk("PHTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_WS_LocationID,
        M_UserID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_WS_T_WS_WaterQualityPH_Select?FromDate=${FromDate? FromDate : ''}&ToDate=${ToDate ? ToDate : ''}&M_WardID=${M_WardID ? M_WardID : '0'}&M_WS_LocationID=${M_WS_LocationID ? M_WS_LocationID: '0'}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const PHTableDataSlice = createSlice({
    name: "PHTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PHTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PHTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PHTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const PHTableDataReducer = PHTableDataSlice.reducer


export const PHPostDataAPI = createAsyncThunk("PumpingStationPostData", async ({ data }) => {
    const {
        T_WS_WaterQualityPHID,
        PH,       
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_WaterQualityPHID", T_WS_WaterQualityPHID);
    formdata.append("PH", PH);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/Post_WEB_WS_T_WS_WaterQualityPH_Update`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const PHPostDataSlice = createSlice({
    name: "PHPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PHPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PHPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PHPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PHPostDataReducer = PHPostDataSlice.reducer

// -----------------Delete API ---------------

export const PHDeleteAPI = createAsyncThunk("PHDelete", async ({ data }) => {
    const {
        T_WS_WaterQualityPHID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_WaterQualityPHID",T_WS_WaterQualityPHID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/WEB_WS_T_WS_WaterQualityPH_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const PHDeleteSlice = createSlice({
    name: "PHDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PHDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(PHDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(PHDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PHDeleteReducer = PHDeleteSlice.reducer