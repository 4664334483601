
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import {  WorkPeriodDDLAPI } from '../../Redux/DDLSlice'
import { AstricSign } from '../../Helper/AstricSign'

export const SeverageWorkPeriodDataDDL = (props) => {
    const { WorkPeriod, setWorkPeriod, rowData, Flag, IsPost } = props
    // console.log(rowData)
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token }
        dispatch(WorkPeriodDDLAPI({ data }))
    }, [IsPost])

    const { WorkPeriodData } = useSelector(state => state.WorkPeriodDDLData)


    useEffect(() => {
        handleWorkPeriodDDL()
    }, [WorkPeriodData])

    const handleWorkPeriodDDL = () => {
        // console.log(DeptDDLDataa)
        if (WorkPeriodData && WorkPeriodData.table && WorkPeriodData.table.length > 0) {
            let list = WorkPeriodData.table.map((item, index) => ({
                value: item.m_sW_WorkPeriodID,
                label: item.parameterDescription,
            }))

            setWorkPeriod({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_sW_WorkPeriodID : 0,
                Label: Flag === 'Update' ? rowData.m_sW_WorkPeriod : "Select...",
            })
        }
        else {
            setWorkPeriod({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Work Period {Flag && <AstricSign />} </b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width2"
                isSearchable
                maxMenuHeight={150}
                value={{ value: WorkPeriod.ID, label: WorkPeriod.Label }}
                onChange={(e) => {
                    e ?
                        setWorkPeriod({ ...WorkPeriod, ID: e.value, Label: e.label })
                        :
                        setWorkPeriod({ ...WorkPeriod, ID: 0, Label: "Select..." })

                }}
                options={WorkPeriod.DDL}
            />
        </div>
    )
}