import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";


// Get Table Data
export const DailyWorkSheetTableDataAPI = createAsyncThunk("DailyWorkSheetTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_AssetTypeID,
        M_DepartmentID,
        OfficerNameID,
        M_DesignationID,
        token,
        M_UserID,
        From,
        To,
        // handleGetPdfUrl
        // M_WardID,
        // M_GD_SectorID,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_WEB_GD_T_DailyWork_Select?FromDate=${FromDate}&ToDate=${ToDate}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&OfficerNameID=${OfficerNameID ? OfficerNameID : '0'}&M_DesignationID=${M_DesignationID ? M_DesignationID : 0}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                // handleGetPdfUrl(result.url)
                return result.data
            } else {
                return result
            }
        })
})

const DailyWorkSheetTableDataSlice = createSlice({
    name: "DailyWorkSheetTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DailyWorkSheetTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DailyWorkSheetTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(DailyWorkSheetTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DailyWorkSheetTableDataReducer = DailyWorkSheetTableDataSlice.reducer

// PDF Report

export const DailyWorkSheetTablePDFDataAPI = createAsyncThunk("DailyWorkSheetTablePDFData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_AssetTypeID,
        M_DepartmentID,
        OfficerNameID,
        M_DesignationID,
        token,
        M_UserID,
        From,
        To,
        handleGetPDFReportUrl
        // M_WardID,
        // M_GD_SectorID,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_RM_GD_T_DailyWork_Select?FromDate=${FromDate}&ToDate=${ToDate}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : 0}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : 0}&OfficerNameID=${OfficerNameID ? OfficerNameID : 0}&M_DesignationID=${M_DesignationID ? M_DesignationID : 0}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleGetPDFReportUrl(result.url)
                return result.data
            } else {
                handleGetPDFReportUrl(result.url)
                return result
            }
        })
})

const DailyWorkSheetTablePDFDataSlice = createSlice({
    name: "DailyWorkSheetTablePDFData",
    initialState: {
        isExportLoading: false,
        ExportTableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DailyWorkSheetTablePDFDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(DailyWorkSheetTablePDFDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExportTableData = action.payload;
        });
        builder.addCase(DailyWorkSheetTablePDFDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExportTableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DailyWorkSheetTablePDFDataReducer = DailyWorkSheetTablePDFDataSlice.reducer


// InsertUpdate Table Data

// export const GardenMasterPostDataAPI = createAsyncThunk("GardenMasterPostData", async ({ data }) => {
//     const {
//         M_AssetID,
//         M_AssetTypeIDS,
//         M_WardID,
//         // M_GD_SectorID,
//         AssetName,
//         AreaName,
//         AreaInSquareMetre,
//         IsGetPresent,
//         IsWateringOfEdgesPresent,
//         IsWateringOfTreesPresent,
//         IsWateringOfLawnsPresent,
//         IsMaintingYou,
//         M_StatusID,
//         M_UserID,
//         MaintainByIndicatorAgencyID,
//         token,
//         Flag,
//         handleIsPost,
//         handleClearField,
//         handleOnCloseClick
//     } = data


//     var myHeaders = new Headers();
//     myHeaders.append("Authorization", "Bearer " + token);

//     var formdata = new FormData();
//     formdata.append("M_AssetID", M_AssetID);
//     formdata.append("M_AssetTypeID", M_AssetTypeIDS);
//     formdata.append("M_WardID", M_WardID ? M_WardID : '0');
//     // formdata.append("M_GD_SectorID", M_GD_SectorID);
//     formdata.append("AssetName", AssetName);
//     formdata.append("AreaName", AreaName);
//     formdata.append("AreaInSquareMetre", AreaInSquareMetre ? AreaInSquareMetre : '0');
//     formdata.append("IsGetPresent", IsGetPresent ? IsGetPresent : '0');
//     formdata.append("IsWateringOfEdgesPresent", IsWateringOfEdgesPresent ? IsWateringOfEdgesPresent : '0');
//     formdata.append("IsWateringOfTreesPresent", IsWateringOfTreesPresent ? IsWateringOfTreesPresent : '0');
//     formdata.append("IsWateringOfLawnsPresent", IsWateringOfLawnsPresent ? IsWateringOfLawnsPresent : '0');
//     formdata.append("IsMaintingYou", IsMaintingYou ? IsMaintingYou : '0');
//     formdata.append("M_StatusID", M_StatusID);
//     formdata.append("M_UserID", M_UserID);
//     formdata.append("MaintainByIndicatorAgencyID", MaintainByIndicatorAgencyID);
//     formdata.append("Flag", Flag);

//     var requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: formdata,
//         redirect: 'follow'
//     };

//     fetch(`${BaseUrl}/Master/Post_M_Asset_InsertUpdate`, requestOptions)
//         .then((res) => res.json())
//         .then((result) => {
//             if (result.code >= 200 && result.code <= 300 && result.data) {
//                 handleIsPost()
//                 // handleClearField()
//                 // handleOnCloseClick()
//                 handleOnCloseClick()
//                 toastSuccesss(result.message)
//                 return result.data
//             } else {
//                 handleOnCloseClick()
//                 toastErrorr(result.message)
//             }

//             return result
//         })
// })

// const GardenMasterPostDataSlice = createSlice({
//     name: "GardenMasterPostData",
//     initialState: {
//         isLoading: false,
//         tableData: null,
//         isError: false,
//     },
//     extraReducers: (builder) => {
//         builder.addCase(GardenMasterPostDataAPI.pending, (state, action) => {
//             state.isLoading = true;
//         });
//         builder.addCase(GardenMasterPostDataAPI.fulfilled, (state, action) => {
//             state.isLoading = false;
//             state.tableData = action.payload;
//         });
//         builder.addCase(GardenMasterPostDataAPI.rejected, (state, action) => {
//             console.log("Error", action.payload);
//             state.isLoading = false;
//             state.isError = true;
//             state.tableData = null;
//             // toastErrorr('Something went wrong')
//         });
//     },
// })

// export const GardenMasterPostDataReducer = GardenMasterPostDataSlice.reducer



// Delete Table Data

// export const GardenMasterDeleteAPI = createAsyncThunk("GardenMasterDelete", async ({ data }) => {

//     const {
//         M_AssetID,
//         M_UserID,
//         handleIsPost,
//         token,
//     } = data

//     var myHeaders = new Headers();
//     myHeaders.append("Authorization", "Bearer " + token);

//     var formdata = new FormData();
//     formdata.append("M_AssetID", M_AssetID);
//     formdata.append("M_UserID", M_UserID);

//     var requestOptions = {
//         method: 'DELETE',
//         headers: myHeaders,
//         body: formdata,
//         redirect: 'follow'
//     };

//     return fetch(`${BaseUrl}/Master/Post_M_Asset_Delete`, requestOptions)
//         .then((res) => res.json())
//         .then((result) => {
//             if (result.status) {
//                 handleIsPost()
//                 // handleDeleteCloseClick()
//             } else {
//                 toastErrorr(result.message)
//                 // handleDeleteCloseClick()
//             }
//             return result

//         })
// })

// const GardenMasterDeleteSlice = createSlice({
//     name: "GardenMasterDelete",
//     initialState: {
//         isDeleteLoading: false,
//         tableData: null,
//         isError: false,
//     },
//     extraReducers: (builder) => {
//         builder.addCase(GardenMasterDeleteAPI.pending, (state, action) => {
//             state.isDeleteLoading = true;
//         });
//         builder.addCase(GardenMasterDeleteAPI.fulfilled, (state, action) => {
//             state.isDeleteLoading = false;
//             state.tableData = action.payload;
//             toastSuccesss(action.payload.message)
//         });
//         builder.addCase(GardenMasterDeleteAPI.rejected, (state, action) => {
//             console.log("Error", action.payload);
//             state.isDeleteLoading = false;
//             state.isError = true;
//             state.tableData = null;
//             toastErrorr('Something went wrong')
//         });
//     },
// })

// export const GardenMasterDeleteReducer = GardenMasterDeleteSlice.reducer



// PDF Report

export const DailyWorkSheetPDFDataAPI = createAsyncThunk("DailyWorkSheetPDFData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_AssetTypeID,
        M_DepartmentID,
        OfficerNameID,
        M_DesignationID,
        token,
        Flag,
        M_UserID,
        From,
        To,
        handleGetPDFReportUrl
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_RM_GD_T_DailyWork_Wise_CheckList_Select?FromDate=${FromDate}&ToDate=${ToDate}&M_AssetTypeID=${M_AssetTypeID}&M_DepartmentID=${M_DepartmentID}&OfficerNameID=${OfficerNameID}&M_DesignationID=${M_DesignationID}&M_UserID=${M_UserID}&From=${From}&To=${To}&Flag=${Flag}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.url) {
                handleGetPDFReportUrl(result.url)
                return result.data
            } else {
                handleGetPDFReportUrl(null)
                return result
            }
        })
})

const DailyWorkSheetPDFDataSlice = createSlice({
    name: "DailyWorkSheetPDFData",
    initialState: {
        isExportLoading: false,
        ExportTableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DailyWorkSheetPDFDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(DailyWorkSheetPDFDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExportTableData = action.payload;
        });
        builder.addCase(DailyWorkSheetPDFDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExportTableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DailyWorkSheetPDFDataReducer = DailyWorkSheetPDFDataSlice.reducer