import React from "react";
import { useNavigate } from "react-router-dom";



export default function SportsWorksProgressByContractorCard({cardHeight, benchmark,AssignedHeading, PendingHeading,CompletedHeading, navLink, AssignedCount,PendingCount,CompletedCount, m_gD_WorkPeriodID, cardName, SewerageWorkNavigate, cardHeading,title1,title2,title3, SportsWorkProgressByContractorNavigate, RoleID }) {
    const navigate = useNavigate()

    const handleSportsWorkProgressByContractorNavigate = () => {
        navigate(navLink)
    }
    return (
        <div style={{ cursor: 'pointer' }} className="mb-1 p-3 shadow newpurple_borders bg-white mb-md-2 daily-card-width">
            <div className="row">
                <p className="mb-3 text-center" style={{ fontSize: "15px", fontWeight: "bolder", color: "purple", marginTop: "-0.8em" }}>{cardHeading}</p>
                <div className="col-4" style={{ borderRight: "2px solid #0e4174f7" }} onClick={() => SportsWorkProgressByContractorNavigate(title1, benchmark, m_gD_WorkPeriodID, 'WorkAssign', 'Assigned',"WorkAssign")} >
                    <div class={cardHeight} className="d-flex flex-column justify-content-between">
                        <div className="text-center" >
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{AssignedCount}</h4>
                                {/* <img style={{ height: '35px', width: '35px' }} src={imgPathsecond} alt="" /> */}
                            </div>
                        </div>
                        <div>
                            <p style={{ fontSize: "14px" }} className="text-black mb-0 pb-0"><b>{AssignedHeading}</b></p>
                        </div>
                    </div>
                </div>
                <div className="col-4" style={{ borderRight: "2px solid #0e4174f7" }} onClick={() => SportsWorkProgressByContractorNavigate(title2, benchmark, m_gD_WorkPeriodID, 'WorkAssign', 'Pending','WorkAssignPending')} >
                    <div className="d-flex flex-column justify-content-between">
                        <div className="text-center" >
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{PendingCount}</h4>
                                {/* <img style={{ height: '30px', width: '30px' }} src={imgPath} alt="" /> */}
                            </div>

                        </div>
                        <div>
                            <p style={{ fontSize: "14px" }} className="text-black mb-0 pb-0"><b>{PendingHeading}</b></p>
                        </div>
                    </div>
                </div>

                <div className="col-4" onClick={() => SportsWorkProgressByContractorNavigate(title3, benchmark, m_gD_WorkPeriodID, 'WorkAssign', 'Completed',"WorkAssignCompleted")} >
                    <div className="d-flex flex-column justify-content-between">
                        <div className="text-center" >
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{CompletedCount}</h4>
                                {/* <img style={{ height: '30px', width: '30px' }} src={imgPath} alt="" /> */}
                            </div>

                        </div>
                        <div>
                            <p style={{ fontSize: "14px" }} className="text-black mb-0 pb-0"><b>{CompletedHeading}</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}