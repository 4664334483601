export const DailyHorticultureCountObj = {
    "table": [
        {
            "id": 1,
            "m_RoleID": 3,
            "roleName": "DeputyMunicipalCommissioner",
            "DeputyMunicipalCommissioner": 25
        },
        {
            "id": 2,
            "m_RoleID": 7,
            "roleName": "GardenSuperitendent",
            "GardenSuperitendent": 50
        },
        {
            "id": 3,
            "m_RoleID": 13,
            "roleName": "AssistantGardenSuperintendent",
            "AssistantGardenSuperintendent": 15
        },
        {
            "id": 4,
            "m_RoleID": 11,
            "roleName": "GardenInspector",
            "GardenInspector": 35
        },
        {
            "id": 5,
            "m_RoleID": 5,
            "roleName": "AssistantGardenInspector",
            "AssistantGardenInspector": 30
        },
        {
            "id": 6,
            "m_RoleID": 9,
            "roleName": "GradenAssistant",
            "GradenAssistant": 45
        }
    ]
}
export const TwoDaysHorticultureCountObj = {
    "table": [
        {
            "id": 1,
            "m_RoleID": 3,
            "roleName": "DeputyMunicipalCommissioner",
            "DeputyMunicipalCommissioner": 10
        },
        {
            "id": 2,
            "m_RoleID": 7,
            "roleName": "GardenSuperitendent",
            "GardenSuperitendent": 15
        },
        {
            "id": 3,
            "m_RoleID": 13,
            "roleName": "AssistantGardenSuperintendent",
            "AssistantGardenSuperintendent": 25
        },
        {
            "id": 4,
            "m_RoleID": 11,
            "roleName": "GardenInspector",
            "GardenInspector": 30
        },
        {
            "id": 5,
            "m_RoleID": 5,
            "roleName": "AssistantGardenInspector",
            "AssistantGardenInspector": 30
        },
        {
            "id": 6,
            "m_RoleID": 9,
            "roleName": "GradenAssistant",
            "GradenAssistant": 40
        }
    ]
}

// console.log("DailyHorticultureCountObj", DailyHorticultureCountObj.table[0].DeputyMunicipalCommissioner)

export const TotalDaysHorticultureCountObj = {
    "table": [
        {
            "id": 1,
            "m_RoleID": 3,
            "roleName": "DeputyMunicipalCommissioner",
            "DeputyMunicipalCommissioner": DailyHorticultureCountObj.table[0].DeputyMunicipalCommissioner + TwoDaysHorticultureCountObj.table[0].DeputyMunicipalCommissioner
        },
        {
            "id": 2,
            "m_RoleID": 7,
            "roleName": "GardenSuperitendent",
            "GardenSuperitendent": DailyHorticultureCountObj.table[1].GardenSuperitendent + TwoDaysHorticultureCountObj.table[1].GardenSuperitendent
        },
        {
            "id": 3,
            "m_RoleID": 13,
            "roleName": "AssistantGardenSuperintendent",
            "AssistantGardenSuperintendent": DailyHorticultureCountObj.table[2].AssistantGardenSuperintendent + TwoDaysHorticultureCountObj.table[2].AssistantGardenSuperintendent
        },
        {
            "id": 4,
            "m_RoleID": 11,
            "roleName": "GardenInspector",
            "GardenInspector": DailyHorticultureCountObj.table[3].GardenInspector + TwoDaysHorticultureCountObj.table[3].GardenInspector
        },
        {
            "id": 5,
            "m_RoleID": 5,
            "roleName": "AssistantGardenInspector",
            "AssistantGardenInspector": DailyHorticultureCountObj.table[4].AssistantGardenInspector + TwoDaysHorticultureCountObj.table[4].AssistantGardenInspector
        },
        {
            "id": 6,
            "m_RoleID": 9,
            "roleName": "GradenAssistant",
            "GradenAssistant": DailyHorticultureCountObj.table[5].GradenAssistant + TwoDaysHorticultureCountObj.table[5].GradenAssistant
        }
    ]
}


// export const TotalDaysHorticultureCountObj = {
//     "table": [
//         {
//             "id": 1,
//             "m_RoleID": 3,
//             "roleName": "DeputyMunicipalCommissioner",
//             "DeputyMunicipalCommissioner": DailyHorticultureCountObj.table[0].DeputyMunicipalCommissioner + TwoDaysHorticultureCountObj.table[0].DeputyMunicipalCommissioner
//         },
//         {
//             "id": 2,
//             "m_RoleID": 7,
//             "roleName": "GardenSuperitendent",
//             "GardenSuperitendent": DailyHorticultureCountObj.table[1].GardenSuperitendent + TwoDaysHorticultureCountObj.table[1].GardenSuperitendent
//         },
//         {
//             "id": 3,
//             "m_RoleID": 13,
//             "roleName": "AssistantGardenSuperintendent",
//             "AssistantGardenSuperintendent": DailyHorticultureCountObj.table[2].AssistantGardenSuperintendent + TwoDaysHorticultureCountObj.table[2].AssistantGardenSuperintendent
//         },
//         {
//             "id": 4,
//             "m_RoleID": 11,
//             "roleName": "GardenInspector",
//             "GardenInspector": DailyHorticultureCountObj.table[3].GardenInspector + TwoDaysHorticultureCountObj.table[3].GardenInspector
//         },
//         {
//             "id": 5,
//             "m_RoleID": 5,
//             "roleName": "AssistantGardenInspector",
//             "AssistantGardenInspector": DailyHorticultureCountObj.table[4].AssistantGardenInspector + TwoDaysHorticultureCountObj.table[4].AssistantGardenInspector
//         },
//         {
//             "id": 6,
//             "m_RoleID": 9,
//             "roleName": "GradenAssistant",
//             "GradenAssistant": DailyHorticultureCountObj.table[5].GradenAssistant + TwoDaysHorticultureCountObj.table[5].GradenAssistant
//         }
//     ]
// }

export const CountDummyData = [
    {
        "m_gD_WorkPeriodID": 1,
        "benchMark": "1 Day",
        "reopenAssignTotalCount": 50,
        "pendingTotalCount": 25,
        "completed_TotalCount": 25
    },
    {
        "m_gD_WorkPeriodID": 3,
        "benchMark": "2 Days",
        "reopenAssignTotalCount": 75,
        "pendingTotalCount": 40,
        "completed_TotalCount": 35
    },
    {
        "m_gD_WorkPeriodID": 5,
        "benchMark": "7 Days",
        "reopenAssignTotalCount": 0,
        "pendingTotalCount": 0,
        "completed_TotalCount": 0
    },
    {
        "m_gD_WorkPeriodID": 6,
        "benchMark": "8 Days",
        "reopenAssignTotalCount": 0,
        "pendingTotalCount": 0,
        "completed_TotalCount": 0
    },
    {
        "m_gD_WorkPeriodID": 2,
        "benchMark": "15 Days",
        "reopenAssignTotalCount": 0,
        "pendingTotalCount": 0,
        "completed_TotalCount": 0
    },

    {
        "m_gD_WorkPeriodID": 4,
        "benchMark": "3 Months",
        "reopenAssignTotalCount": 0,
        "pendingTotalCount": 0,
        "completed_TotalCount": 0
    },
   
]

export const CountDummyDataCivilEle = [
    {
        "m_gD_WorkPeriodID": 1,
        "benchMark": "1 Day",
        "reopenAssignTotalCount": 0,
        "pendingTotalCount": 0,
        "completed_TotalCount": 0
    },
    {
        "m_gD_WorkPeriodID": 3,
        "benchMark": "2 Days",
        "reopenAssignTotalCount": 0,
        "pendingTotalCount": 0,
        "completed_TotalCount": 0
    },
    {
        "m_gD_WorkPeriodID": 5,
        "benchMark": "7 Days",
        "reopenAssignTotalCount": 0,
        "pendingTotalCount": 0,
        "completed_TotalCount": 0
    },
    {
        "m_gD_WorkPeriodID": 6,
        "benchMark": "8 Days",
        "reopenAssignTotalCount": 0,
        "pendingTotalCount": 0,
        "completed_TotalCount": 0
    },
    {
        "m_gD_WorkPeriodID": 2,
        "benchMark": "15 Days",
        "reopenAssignTotalCount": 0,
        "pendingTotalCount": 0,
        "completed_TotalCount": 0
    },

    {
        "m_gD_WorkPeriodID": 4,
        "benchMark": "3 Months",
        "reopenAssignTotalCount": 0,
        "pendingTotalCount": 0,
        "completed_TotalCount": 0
    },
   
]
