import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const SeverageWorkNameTableDataAPI = createAsyncThunk("SeverageWorkNameTableData", async ({ data }) => {
    const {
        M_SW_WorkNameID,
        M_SW_WorkTypeID,
        M_SW_WorkPeriodID,
        WorkName,
        M_UserID,
        token,
        From,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_WEB_M_SW_WorkName_Select?M_SW_WorkNameID=${M_SW_WorkNameID}&M_SW_WorkTypeID=${M_SW_WorkTypeID ? M_SW_WorkTypeID : '0'}&M_SW_WorkPeriodID=${M_SW_WorkPeriodID ? M_SW_WorkPeriodID : '0'}&WorkName=${WorkName ? WorkName : ''}&M_UserID=${M_UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const SeverageWorkNameTableDataSlice = createSlice({
    name: "SeverageWorkNameTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SeverageWorkNameTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SeverageWorkNameTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(SeverageWorkNameTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const SeverageWorkNameTableDataReducer = SeverageWorkNameTableDataSlice.reducer


export const SeverageWorkNamePostDataAPI = createAsyncThunk("SeverageWorkNamePostData", async ({ data }) => {
    const {
        M_SW_WorkNameID,
        M_SW_WorkTypeID,
        M_SW_WorkPeriodID,
        WorkName,
        BenchMarks,
        PenaltyDescription,
        PenaltyAmount,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_SW_WorkNameID", M_SW_WorkNameID);
    formdata.append("M_SW_WorkTypeID", M_SW_WorkTypeID);
    formdata.append("M_SW_WorkPeriodID", M_SW_WorkPeriodID);
    formdata.append("WorkName", WorkName);
    formdata.append("BenchMarks", BenchMarks);
    formdata.append("PenaltyDescription", PenaltyDescription);
    formdata.append("PenaltyAmount", PenaltyAmount);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_WEB_M_SW_WorkName_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const SeverageWorkNamePostDataSlice = createSlice({
    name: "SeverageWorkNamePostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SeverageWorkNamePostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SeverageWorkNamePostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(SeverageWorkNamePostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const SeverageWorkNamePostDataReducer = SeverageWorkNamePostDataSlice.reducer

// -----------------Delete API ---------------

export const SeverageWorkNameDeleteAPI = createAsyncThunk("SeverageWorkNameDelete", async ({ data }) => {
    const {
        M_SW_WorkNameID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    var formdata = new FormData();
    formdata.append("M_SW_WorkNameID", M_SW_WorkNameID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/WEB_M_SW_WorkName_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const SeverageWorkNameDeleteSlice = createSlice({
    name: "SeverageWorkNameDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SeverageWorkNameDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(SeverageWorkNameDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(SeverageWorkNameDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const SeverageWorkNameDeleteReducer = SeverageWorkNameDeleteSlice.reducer