
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import Header from '../../../../../../Components/Header/Header'
import Sidebar from '../../../../../../Components/Sidebar/Sidebar'
import { useSearchParams } from 'react-router-dom'




export default function ContractorWiseWardNamePage() {

    // const [DeletePopupShow, setDeletePopupShow] = useState()
    // const [RoasMasterWardPopupShow, setRoasMasterWardPopupShow] = useState()
    // const handleAddOnClick = () => {
    //     console.log("add")
    //     setRoasMasterWardPopupShow(true)
    // }

    // const [WorkType, setWorkType] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select...",
    // })

   

    // const handleClear = () => {
    //     // setCurrentPage(0)
    //     // setIsClear(!IsClear)

    //     setWorkType({
    //         ...WorkType,
    //         ID: 0,
    //         Label: 'Select...',
    //     })
    //     // console.log("call")
    // }

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")

    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    

    return (
        <div>
            <Header
                MLable={MLable}
                HMonth={HMonth}
                YLable={YLable}
                HYear={HYear}
                HWard={HWard}
                WLable={WLable}
                flag='Screen'
                DashboardName={DashboardName}
            />
            <div className="wrapper">
                <Sidebar ProjectTypeId={ProjectTypeId} />
                <div className="content">
                    {/* <!-- [ breadcrumb ] start --> */}
                    <div className="page-header ">
                        <div className="page-block">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    {/* <div className="page-header-title">
                                        <h5 className="ps-2 data_mob">MASTER */}

                                    {/* <span className="float-end fs-6">
                                                <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                        :
                                                        setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                }}
                                                options={SelectWard.DDL}
                                            />

                                            </span> */}
                                    {/* </h5>

                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- [ Main Content ] start --> */}
                    <div className='data_mob'>
                        <div className="row m-1 pb-4 master_border">
                            <div className='col-12'>
                                <h5 className="mt-2">Contractor Wise Prabhag No </h5>
                            </div>
                            {/* <div className='col-7 mar_add'>
                                <div className="float-end butt">
                                    <button type="button" className="btn btn-primary ml-1 float-end">Export</button>
                                    <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                                        style={{ color: "white" }}
                                    > Add</i>
                                </div>
                            </div> */}
                            <div className="row m-0 ">
                                <div className="col-12 py-0 rounded rounded-2 shadow"
                                    style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                    <div className="col-12">

                                        {/* <div className="row py-1 rounded2">
                                            <div className="col-lg-3 col-md-6">
                                                <label for=""><b>Work Type :</b></label>
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    // className="ddl_width"
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: WorkType.ID, label: WorkType.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setWorkType({ ...WorkType, ID: e.value, Label: e.label })
                                                            :
                                                            setWorkType({ ...WorkType, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={WorkType.DDL}
                                                />

                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <label for=""><b>Work Name :</b></label>
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    // className="ddl_width"
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: WorkName.ID, label: WorkName.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setWorkName({ ...WorkName, ID: e.value, Label: e.label })
                                                            :
                                                            setWorkName({ ...WorkName, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={WorkName.DDL}
                                                />

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-1 mt-lg-4 mt-md-4 mt-1" >
                                                <button type="button"
                                                    className="btn-clear btn btn-primary"
                                                    // onClick={handleClear}
                                                    >
                                                        Clear</button>

                                            </div>

                                        </div> */}
                                        <div className="row mob_table table-responsive pt-3">
                                            <table id="totalordergenerated"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                className="table table-bordered">

                                                <thead>
                                                    <tr>
                                                        <th className="sr_no_col">Sr No.</th>
                                                        <th>Contractor Name</th>
                                                        <th>Prabhag No</th>
                                                        {/* <th>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ textAlign: "center" }}>1</td>
                                                        <td></td>
                                                        <td></td>
                                                        {/* <td style={{ textAlign: "center" }}>
                                                        <span>
                                                                            <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                style={{ cursor: 'pointer' }}
                                                                                // onClick={() => handleEditOnClick(item)}
                                                                            >
                                                                            </i>
                                                                        </span>
                                                                        <span>
                                                                            <DeletePopup
                                                                                open={DeletePopupShow}
                                                                                // item={item}
                                                                                // handleDeleteApi={handleDeleteApi}
                                                                            />
                                                                        </span>
                                                        </td> */}
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}