import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
    useLocation,
    Navigate,
} from "react-router-dom";

import Login from "./Pages/Login/login";
import { DashboardNew } from "./Pages/Private/Dashboard/DashboardNew";
import { GardenDashboard } from "./Pages/Private/Dashboard/GardenDashboard";
import MasterData from "./Pages/Private/Dashboard/GardenData/GardenMasterData";
import RoadDividerData from "./Pages/Private/Dashboard/GardenData/RoadDividerData";
import OpenSpace from "./Pages/Private/Dashboard/GardenData/OpenSpaceData";
import TreeBelt from "./Pages/Private/Dashboard/GardenData/TreeBeltData";
import ChowkData from "./Pages/Private/Dashboard/GardenData/ChowksData";
import GardenDailyPreWorkPending from "./Pages/Private/Dashboard/GardenDailyPreWork/GardenDailyPreWorkPending";
import GardenDailyPreWorkComplete from "./Pages/Private/Dashboard/GardenDailyPreWork/GardenDailyPreWorkCompleted";
import GardenReopenPending from "./Pages/Private/Dashboard/GardenReopenProgressData/GardenReopenPending";
import GardenReopenComplete from "./Pages/Private/Dashboard/GardenReopenProgressData/GardenReopenComplete";
import WorkAssignedDMCTable from "./Pages/Private/Dashboard/GarderDashboardComponent/GardenWorkAssign/WorkAssignedDMCTable";
import { SewerageDashboard } from "./Pages/Private/Dashboard/SewerageDashboard";
import { Department } from "./Pages/Private/Dashboard/MasterSideData/Department";
import { Designation } from "./Pages/Private/Dashboard/MasterSideData/Designation";
import { Employee } from "./Pages/Private/Dashboard/MasterSideData/Employee";
import { UserCreation } from "./Pages/Private/Dashboard/MasterSideData/UserCreation";
import { ZoneMaster } from "./Pages/Private/Dashboard/MasterSideData/ZoneMaster";
import { WardMaster } from "./Pages/Private/Dashboard/MasterSideData/WardMaster";
import { SectorMaster } from "./Pages/Private/Dashboard/MasterSideData/SectorMaster";
import { ContractorName } from "./Pages/Private/Dashboard/MasterSideData/ContractorName";
import { ProjectName } from "./Pages/Private/Dashboard/MasterSideData/ProjectName";
import { ProjectWiseContractor } from "./Pages/Private/Dashboard/MasterSideData/ProjectWiseContractor";
import { WorkType } from "./Pages/Private/Dashboard/MasterSideData/WorkType";
import { WorkSubType } from "./Pages/Private/Dashboard/MasterSideData/WorkSubType";
import { Work } from "./Pages/Private/Dashboard/MasterSideData/Work";
import { ProjectWiseWorkName } from "./Pages/Private/Dashboard/MasterSideData/ProjectWiseWorkName";
import RoadMasterWardData from "./Pages/Private/Dashboard/RoadData/RoadMasterData/RoadMasterWardData/RoadMasterWardData";
import RoadMasterSectorData from "./Pages/Private/Dashboard/RoadData/RoadMasterSectorData/RoadMasterSectorName";
import RoadMasterWorkData from "./Pages/Private/Dashboard/RoadData/RoadMasterWorkNameData/RoadMasterWorkName";
import { useAuthState } from "./Helper/Context";
import DeletePopup from "./Pages/Private/Dashboard/GardenPopup/DeletePopup";
import RoadWorkUpload from "./Pages/Private/Dashboard/RoadData/RoadWorkUploadData/RoadWorkUpload";
import RoadWorkApproval from "./Pages/Private/Dashboard/RoadData/RoadWorkApprovalData/RoadWorkApproval";
import RoadWorkProgressPending from "./Pages/Private/Dashboard/RoadData/RoadWorkProgressData/WorkProgressPending";
import RoadWorkProgressCompleted from "./Pages/Private/Dashboard/RoadData/RoadWorkProgressData/WardWiseWork";
import RoadWorkeEstimated from "./Pages/Private/Dashboard/RoadData/RoadEstimatedData/RoadEstimate";
import SewerageWorkTypeData from "./Pages/Private/Dashboard/SewerageDashboardComponent/SewerageMaster/SewerageMasterData/SewerageWorkTypeData/SewerageWorktypeData";
import SewerageWorkNameData from "./Pages/Private/Dashboard/SewerageDashboardComponent/SewerageMaster/SewerageMasterData/SewerageWorkNameData/SewerageWorkNameData";
import SeweragePumpingData from "./Pages/Private/Dashboard/SewerageDashboardComponent/SewerageMaster/SewerageMasterData/SeweragePumpingStationData/SeweragePumpingData";
import SeweragePipelineData from "./Pages/Private/Dashboard/SewerageDashboardComponent/SewerageMaster/SewerageMasterData/SeweragePipelinesData/SeweragePipelineData";
import PreventivePumpingOperationData from "./Pages/Private/Dashboard/SewerageDashboardComponent/SeweragePreventive/SeweragePreventiveWorkData/PreventivePumpingOperationData";
import PreventiveCustomerServiceData from "./Pages/Private/Dashboard/SewerageDashboardComponent/SeweragePreventive/SeweragePreventiveWorkData/PreventiveCustomerServiceData";
import PreventiveSecurityData from "./Pages/Private/Dashboard/SewerageDashboardComponent/SeweragePreventive/SeweragePreventiveWorkData/PreventiveSecurityData";
import PreventiveEnergyAuditData from "./Pages/Private/Dashboard/SewerageDashboardComponent/SeweragePreventive/SeweragePreventiveWorkData/PreventiveEnergyAuditdata";
import PreventiveHouseKeepingData from "./Pages/Private/Dashboard/SewerageDashboardComponent/SeweragePreventive/SeweragePreventiveWorkData/PreventiveHouseKeepingData";
import CommonServiceData from "./Pages/Private/Dashboard/SewerageDashboardComponent/SewerageService/CommonServiceData";
import CommonAssignWorkData from "./Pages/Private/Dashboard/SewerageDashboardComponent/SewerageAssign/CommonAssignWorkData";
import GardenWisemaintenanceStatus from "./Pages/Private/Dashboard/MasterSideData/GardenWisemaintenanceStatus";
import { RMWorkType } from "./Pages/Private/Dashboard/MasterSideData/RMWorkType";
import WardWiseWork from "./Pages/Private/Dashboard/RoadData/RoadWorkProgressData/WardWiseWork";
import { LocationMaster } from "./Pages/Private/Dashboard/SewerageDashboardComponent/SewerageMaster/LocationMaster/LocationMaster";
import { PumpingStation } from "./Pages/Private/Dashboard/SewerageDashboardComponent/SewerageMaster/PumpingStation/PumpingStation";
import { AreaMaster } from "./Pages/Private/Dashboard/SewerageDashboardComponent/SewerageMaster/AreaMaster/AreaMaster";
import PreventiveWorkReport from "./Pages/Private/Dashboard/GardenReports/PreventiveWorkReport";
import CompletedWorkReport from "./Pages/Private/Dashboard/GardenReports/CompletedWorkReport";
import RoadReportCompelete from "./Pages/Private/Dashboard/RoadData/RoadReports/RoadReportCompelete";
import { WaterSupplyDashboard } from "./Pages/Private/Dashboard/WaterSupplyDashboard";
import PipelineMasterPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyMasterPages/PipelineMasterPage/PipelineMasterPage";
import PumpingStationMasterPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyMasterPages/PumpStationMasterPage/PumpStationMasterPage";
import WorkMasterPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyMasterPages/WorkMasterPage/WorkMasterPage";
import ContractorWiseWardNamePage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyMasterPages/ContractorWiseWardName/ContractorWiseWardNamePage";
import WaterQualityPhServicePage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyServicePages/WaterQualityPHService/WaterQualityPhServicePage";
import WaterQualityChlorineServicePage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyServicePages/WaterQualityChlorineService/WaterQualityChlorineServicePage";
import WaterQualityTurbidityServicePage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyServicePages/WaterQualityTurbidityService/WaterQualityTurbidityServicePage";
import WaterQualityEcoliServicePage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyServicePages/WaterQualityEcoilService/WaterQualityEcoliServicePage";
import WaterPressurePreventiveWorkPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyPreventiveWorkPages/WaterPressurePreventiveWorkPage/WaterPressurePreventiveWorkPage";
import WaterFlowPreventiveWorkPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyPreventiveWorkPages/WaterFlowPreventiveWorkPage/WaterFlowPreventiveWorkPage";
import WaterQuantityPreventiveWorkPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyPreventiveWorkPages/WaterQuantityPreventiveWorkPage/WaterQuantityPreventiveWorkPage";
import ChemicalSupplyPreventiveWorkPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyPreventiveWorkPages/WaterChemicalSupplyPreventiveWorkPage/WaterChemicalSupplyPreventiveWorkPage";
import WaterAuditPreventiveWorkPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyPreventiveWorkPages/WaterAuditPreventiveWorkPage/WaterAuditPreventiveWorkPage";
import EnergyConsumptionPreventiveWorkPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyPreventiveWorkPages/WaterEnergyConsumptionPreventiveWorkPage/WaterEnergyConsumptionPreventiveWorkPage";
import WaterSecurityPreventiveWorkPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyPreventiveWorkPages/WaterSecurityPreventiveWorkPage/WaterSecurityPreventivework";
import WaterHouseKeepingPreventiveWorkPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyPreventiveWorkPages/WaterHouseKeepingPreventiveWorkPage/WaterHouseKeepingPreventiveWork";
import WaterValveOperationPreventiveWorkPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyPreventiveWorkPages/WaterValueOperationPreventiveWorkPage/WaterSupplyValueOperationPreventiveWork";
import CommonWaterSupplyPreventiveMaintainanceWorkPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyPreventiveMaintainanceWorkPages/CommonWaterSupplyPreventiveMaintainanceWorkPage";
import CommonForAllAssignWorkMaintainancePageData from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterAssignMaintainanceWorkPages/CommonForAllAssignedMaintainanceWorkPage";
import UserWiseWardMapping from "./Pages/Private/Dashboard/MasterSideData/UserWiseWardMapping";
import WaterSupplySidebarWorkTypeDataPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplySidebarMasterPage/WorkType/WaterSupplySidebarWorkTypeData";
import WaterSupplySidebarLocationMasterDataPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplySidebarMasterPage/LocationMaster/WaterSupplySidebarLocationMasterData";
import WaterSupplySidebarLocationWiseAreMasterDataPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplySidebarMasterPage/LocationWiseAreaMaster/WaterSupplySidebarLocationWiseAreaMasterData";
import { STPDashboard } from "./Pages/Private/Dashboard/STPDashboard";
import STPMasterTankDetailsData from "./Pages/Private/Dashboard/STPDashboardPages/STPMasterCardPages/MasterTanksDetailData/STPMasterTanksDetailsData";
import MasterEnergyMPCNoData from "./Pages/Private/Dashboard/STPDashboardPages/STPMasterCardPages/MasterEnergyMPCNoData/MasterEnergyMPCNoData";
import STPEquipmentMasterData from "./Pages/Private/Dashboard/STPDashboardPages/STPMasterCardPages/MasterEquipmentsMasterData/EquipmentMasterData";
import STPEquipmentWorkData from "./Pages/Private/Dashboard/STPDashboardPages/STPMasterCardPages/MasterEquipmentsWorkData/MasterEquipmentWorkData";
import STPQualityParameterData from "./Pages/Private/Dashboard/STPDashboardPages/STPMasterCardPages/MasterQualityParameterData/MasterQualityParameterData";
import STPPreventiveSewerageQualityData from "./Pages/Private/Dashboard/STPDashboardPages/STPPreventiveWorkCardPages/STPPreventiveSewerageQualityData";
import STPPreventiveMachineryOperationData from "./Pages/Private/Dashboard/STPDashboardPages/STPPreventiveWorkCardPages/STPPreventiveMachineryOperationData";
import STPPreventiveEnergyConsumptionData from "./Pages/Private/Dashboard/STPDashboardPages/STPPreventiveWorkCardPages/STPPreventiveEnergyConsumptionData";
import STPPreventiveMachineryChecklistData from "./Pages/Private/Dashboard/STPDashboardPages/STPPreventiveWorkCardPages/STPPreventiveMachineryChecklistData";
import STPPreventiveMPCBTestData from "./Pages/Private/Dashboard/STPDashboardPages/STPPreventiveWorkCardPages/STPPreventiveMPCBTestData";
import STPPreventiveHouseKeepingData from "./Pages/Private/Dashboard/STPDashboardPages/STPPreventiveWorkCardPages/STPPreventiveHouseKeepingData";
import STPPreventiveEnergyAuditData from "./Pages/Private/Dashboard/STPDashboardPages/STPPreventiveWorkCardPages/STPPreventiveEnergyauditData";
import STPBreakdownMaintainanceData from "./Pages/Private/Dashboard/STPDashboardPages/STPPreventiveWorkCardPages/STPPreventiveBreakdownMaintainanceData";
import STPPreventiveMaintainanceData from "./Pages/Private/Dashboard/STPDashboardPages/STPPreventiveWorkCardPages/STPPreventiveMaintainanceData";
import STPUnitTypeMasterData from "./Pages/Private/Dashboard/STPDashboardComponent/STPSidebarData/STPUnitTypeMaster";
import STPEquipmentTypeMasterData from "./Pages/Private/Dashboard/STPDashboardComponent/STPSidebarData/STPEquipmentTypeMaster";
import STPMachineryDetailsMasterData from "./Pages/Private/Dashboard/STPDashboardComponent/STPSidebarData/STPMachineryDetailsMaster";
import STPMPCBParameterMasterData from "./Pages/Private/Dashboard/STPDashboardComponent/STPSidebarData/STPMPCBParameterMaster";
import STPMachEquipmentTypeMasterData from "./Pages/Private/Dashboard/STPDashboardComponent/STPSidebarData/STPMachEquipmentTypeMaster";
import PcmcLoginPage from "./Pages/Login/PcmcLoginPage";
import { BaseUrl } from "./Helper/BaseUrl";
import LoginPageComp from "./Pages/Login/LoginPageComp";
import WaterSupplyDistributionNetworkPreventiveWorkPage from "./Pages/Private/Dashboard/WaterSupplyDashboardPages/WaterSupplyPreventiveWorkPages/WaterSupplyDistributionNetworkPreventiveWork/WaterSupplyDistributionNetworkPreventiveWorkPage";
import { OfficialDashboard } from "./Pages/Private/Dashboard/OfficialDashboard";
import WorkAssignedByOfficialTable from "./Pages/Private/Dashboard/NewDashBoardPages/WorkAssignedByOfficial/WorkAssignedByOfficialsPage";
import CivilWorkAssignedByOfficialTable from "./Pages/Private/Dashboard/NewDashBoardPages/WorkAssignedByOfficial/CivilWorkAssignedByOfficial";
import ElectricalWorkAssignedByOfficialTable from "./Pages/Private/Dashboard/NewDashBoardPages/WorkAssignedByOfficial/ElectricalWorkAssignedByOfficial";
import HorticultureWorkProgressByContractorTable from "./Pages/Private/Dashboard/NewDashBoardPages/WorkProgressByContractor/HorticultureWorkProgressByContractor";
import EmployeeTrackingTable from "./Pages/Private/Dashboard/MasterSideData/EmployeeTracking";
import HorticultureWorkReopenByContractor from "./Pages/Private/Dashboard/NewDashBoardPages/WorkReopenProgressByContractor/HorticultureWorkReopenByContractor";
import CivilWorkProgressByContractorTable from "./Pages/Private/Dashboard/NewDashBoardPages/WorkProgressByContractor/CivilWorkProgressByContractor";
import ElectricalWorkProgressByContractorTable from "./Pages/Private/Dashboard/NewDashBoardPages/WorkProgressByContractor/ElectricalWorkProgressByContractor";
import CivilWorkReopenByContractor from "./Pages/Private/Dashboard/NewDashBoardPages/WorkReopenProgressByContractor/CivilWorkReopenProgressByContractor";
import ElectricalWorkReopenByContractor from "./Pages/Private/Dashboard/NewDashBoardPages/WorkReopenProgressByContractor/ElectricalWorkReopenProgressByContractor";
import { ContractorWiseAssets } from "./Pages/Private/Dashboard/MasterSideData/ContractorWiseAssets";
import PlayEquipmentData from "./Pages/Private/Dashboard/GardenData/PlayEquipmentData";
import SportsWorkAssignedByOfficialTable from "./Pages/Private/Dashboard/NewDashBoardPages/WorkAssignedByOfficial/SportsWorkAssignedByOfficials";
import DailyWorkSheetTable from "./Pages/Private/Dashboard/MasterSideData/DailyWorkSheet";
import SportsWorkProgressByContractorTable from "./Pages/Private/Dashboard/NewDashBoardPages/WorkProgressByContractor/SportsWorkProgressByContractor";
import SportsWorkReopenProgressByContractorTable from "./Pages/Private/Dashboard/NewDashBoardPages/WorkReopenProgressByContractor/SportWorkReopenProgressByContractor";
import SecurityWorkAssignedByOfficialTable from "./Pages/Private/Dashboard/NewDashBoardPages/WorkAssignedByOfficial/SecurityWorkAssignByOfficial";

export default function AllRoutes() {

    const RequiredAuth = () => {

        const userDetails = useAuthState()
        const { RoleID } = userDetails
        let Auth = RoleID

        const location = useLocation()
        if (!Auth) {
            return <Navigate to="/" state={{ from: location }} />
        }
        return <Outlet />
    }
    return (
        <>
            <Router>
                <Routes>
                    {/* Login Page Start */}

                    <Route exact path="/" element={<LoginPageComp />} />

                    {/* Login Page End */}
                    <Route exact element={<RequiredAuth />}>

                        {/* SideBar Pages */}
                        <Route exact path="/department" element={<Department />} />
                        <Route exact path="/designation" element={<Designation />} />
                        <Route exact path="/employee" element={<Employee />} />
                        <Route exact path="/usercreation" element={<UserCreation />} />
                        <Route exact path="/zonemaster" element={<ZoneMaster />} />
                        <Route exact path="/wardmaster" element={<WardMaster />} />
                        <Route exact path="/sectormaster" element={<SectorMaster />} />
                        <Route exact path="/contractorname" element={<ContractorName />} />
                        <Route exact path="/projectname" element={<ProjectName />} />
                        <Route exact path="/projectwisecontractor" element={<ProjectWiseContractor />} />
                        <Route exact path="/worktype" element={<WorkType />} />
                        <Route exact path="/worksubtype" element={<WorkSubType />} />
                        <Route exact path="/work" element={<Work />} />
                        <Route exact path="/projectwiseworkname" element={<ProjectWiseWorkName />} />
                        <Route exact path="/gardenwisemaintenancestatus" element={<GardenWisemaintenanceStatus />} />
                        <Route exact path="/RMWorkType" element={<RMWorkType />} />
                        <Route exact path="/userwisewardmapping" element={<UserWiseWardMapping />} />
                        <Route exact path="/contractorwiseassets" element={<ContractorWiseAssets />} />

                        {/* SideBar Pages */}

                        {/* Road Dashboard start */}

                        <Route exact path="/dashboard" element={<DashboardNew />} />
                        <Route exact path="/masterwarddata" element={<RoadMasterWardData />} />
                        <Route exact path="/mastersectordata" element={<RoadMasterSectorData />} />
                        <Route exact path="/masterworkdata" element={<RoadMasterWorkData />} />
                        <Route exact path="/roadworkupload" element={<RoadWorkUpload />} />
                        <Route exact path="/roadworkapproval" element={<RoadWorkApproval />} />
                        <Route exact path="/roadworkprogresspending" element={<RoadWorkProgressPending />} />
                        <Route exact path="/roadworkprogresscompleted" element={<WardWiseWork />} />
                        <Route exact path="/roadworkestimated" element={<RoadWorkeEstimated />} />

                        {/* Road Dashboard end */}

                        {/* Road Reports start */}

                        <Route exact path="/roadreportcompelete" element={<RoadReportCompelete />} />

                        {/* Garden Dashboard start */}

                        <Route exact path="/garden" element={<GardenDashboard />} />

                        <Route exact path="/officialdashboard" element={<OfficialDashboard />} />
                        <Route exact path="/workassignedbyofficialtable" element={<WorkAssignedByOfficialTable />} />
                        <Route exact path="/civilworkassignedbyofficialtable" element={<CivilWorkAssignedByOfficialTable />} />
                        <Route exact path="/electricalworkassignedbyofficialtable" element={<ElectricalWorkAssignedByOfficialTable />} />
                        <Route exact path="/sportsworkassignedbyofficialtable" element={<SportsWorkAssignedByOfficialTable />} />
                        <Route exact path="/horticultureworkprogressbycontractortable" element={<HorticultureWorkProgressByContractorTable />} />
                        <Route exact path="/employeetrackingtable" element={<EmployeeTrackingTable />} />
                        <Route exact path="/HorticultureWorkReopenByContractor" element={<HorticultureWorkReopenByContractor />} />
                        <Route exact path="/civilworkprogressbycontractortable" element={<CivilWorkProgressByContractorTable />} />
                        <Route exact path="/electricalworkprogressbycontractortable" element={<ElectricalWorkProgressByContractorTable />} />
                        <Route exact path="/civilworkreopenbycontractor" element={<CivilWorkReopenByContractor />} />
                        <Route exact path="/electricalworkreopenbycontractor" element={<ElectricalWorkReopenByContractor />} />
                        <Route exact path="/dailyworksheettable" element={<DailyWorkSheetTable />} />
                        <Route exact path="/sportsworkprogressbycontractortable" element={<SportsWorkProgressByContractorTable />} />
                        <Route exact path="/sportsworkreopenprogressbycontractortable" element={<SportsWorkReopenProgressByContractorTable />} />
                        <Route exact path="/securityworkassignedbyofficialtable" element={<SecurityWorkAssignedByOfficialTable />} />

                        
                        <Route exact path="/gardenmaster" element={<MasterData />} />
                        <Route exact path="/roaddivider" element={<RoadDividerData />} />
                        <Route exact path="/openspace" element={<OpenSpace />} />
                        <Route exact path="/treebelt" element={<TreeBelt />} />
                        <Route exact path="/chowks" element={<ChowkData />} />
                        <Route exact path="/playequipment" element={<PlayEquipmentData />} />
                        <Route exact path="/gardenworkpending" element={<GardenDailyPreWorkPending />} />
                        <Route exact path="/gardenworkcomplete" element={<GardenDailyPreWorkComplete />} />
                        <Route exact path="/gardenreopenpending" element={<GardenReopenPending />} />
                        <Route exact path="/gardereopencomplete" element={<GardenReopenComplete />} />
                        <Route exact path="/WorkAssignedDMCTable" element={<WorkAssignedDMCTable />} />
                        <Route exact path="/deletepopup" element={<DeletePopup />} />

                        {/* Garden Dashboard end */}

                        {/* Garden Reports */}

                        <Route exact path="/preventiveWorkReport" element={<PreventiveWorkReport />} />
                        <Route exact path="/completedWorkReport" element={<CompletedWorkReport />} />

                        {/* Sewerage Dashboard Start */}

                        <Route exact path="/sewerage" element={<SewerageDashboard />} />
                        <Route exact path="/sewerageworktypedata" element={<SewerageWorkTypeData />} />
                        <Route exact path="/sewerageworknamedata" element={<SewerageWorkNameData />} />
                        <Route exact path="/seweragepumpingdata" element={<SeweragePumpingData />} />
                        <Route exact path="/seweragepipelinedata" element={<SeweragePipelineData />} />
                        <Route exact path="/preventivepumping" element={<PreventivePumpingOperationData />} />
                        <Route exact path="/preventivecustomer" element={<PreventiveCustomerServiceData />} />
                        <Route exact path="/preventivesecurity" element={<PreventiveSecurityData />} />
                        <Route exact path="/preventiveenergyaudit" element={<PreventiveEnergyAuditData />} />
                        <Route exact path="/preventivehousekeeping" element={<PreventiveHouseKeepingData />} />
                        <Route exact path="/preventiveservicecommondata" element={<CommonServiceData />} />
                        <Route exact path="/preventiveassigncommondata" element={<CommonAssignWorkData />} />
                        <Route exact path="/locationMaster" element={<LocationMaster />} />
                        <Route exact path="/pumpingStation" element={<PumpingStation />} />
                        <Route exact path="/areaMaster" element={<AreaMaster />} />

                        {/* Sewerage Dashboard End */}

                        {/* Water Supply Dashboard Start */}

                        <Route exact path="/watersupply" element={<WaterSupplyDashboard />} />
                        <Route exact path="/pipelinemasterpage" element={<PipelineMasterPage />} />
                        <Route exact path="/pumpingstationmasterpage" element={<PumpingStationMasterPage />} />
                        <Route exact path="/workmasterpage" element={<WorkMasterPage />} />
                        <Route exact path="/contractorwisewardname" element={<ContractorWiseWardNamePage />} />
                        <Route exact path="/waterqualityphservicepage" element={<WaterQualityPhServicePage />} />
                        <Route exact path="/waterqualityecoliservicepage" element={<WaterQualityEcoliServicePage />} />
                        <Route exact path="/waterqualityturbidityservicepage" element={<WaterQualityTurbidityServicePage />} />
                        <Route exact path="/waterqualitychlorineservicepage" element={<WaterQualityChlorineServicePage />} />
                        <Route exact path="/waterpressurepreventiveworkpage" element={<WaterPressurePreventiveWorkPage />} />
                        <Route exact path="/waterflowpreventiveworkpage" element={<WaterFlowPreventiveWorkPage />} />
                        <Route exact path="/waterquantitypreventiveworkpage" element={<WaterQuantityPreventiveWorkPage />} />
                        <Route exact path="/chemicalsupplypreventiveworkpage" element={<ChemicalSupplyPreventiveWorkPage />} />
                        <Route exact path="/waterauditpreventiveworkpage" element={<WaterAuditPreventiveWorkPage />} />
                        <Route exact path="/energyconsumptionpreventiveworkpage" element={<EnergyConsumptionPreventiveWorkPage />} />
                        <Route exact path="/watersecuritypreventiveworkpage" element={<WaterSecurityPreventiveWorkPage />} />
                        <Route exact path="/waterhousekeepingpreventiveworkpage" element={<WaterHouseKeepingPreventiveWorkPage />} />
                        <Route exact path="/watervalveoperationpreventiveworkpage" element={<WaterValveOperationPreventiveWorkPage />} />
                        <Route exact path="/watersupplydistributionnetworkpreventiveworkpage" element={<WaterSupplyDistributionNetworkPreventiveWorkPage />} />
                        <Route exact path="/commonwatersupplypreventivemaintainanceworkpage" element={<CommonWaterSupplyPreventiveMaintainanceWorkPage />} />
                        <Route exact path="/commonforallassignworkmaintainancepagedata" element={<CommonForAllAssignWorkMaintainancePageData />} />
                        <Route exact path="/watersupplysidebarworktypedatapage" element={<WaterSupplySidebarWorkTypeDataPage />} />
                        <Route exact path="/watersupplysidebarlocationmasterdatapage" element={<WaterSupplySidebarLocationMasterDataPage />} />
                        <Route exact path="/watersupplysidebarlocationwisearemasterdatapage" element={<WaterSupplySidebarLocationWiseAreMasterDataPage />} />

                        {/* Water Supply Dashboard End */}

                        {/* STP Dashboard Start */}

                        <Route exact path="/stpdashboard" element={<STPDashboard />} />
                        <Route exact path="/stpmastertankdetailsdata" element={<STPMasterTankDetailsData />} />
                        <Route exact path="/masterenergympcnodata" element={<MasterEnergyMPCNoData />} />
                        <Route exact path="/stpequipmentmasterdata" element={<STPEquipmentMasterData />} />
                        <Route exact path="/stpequipmentworkdata" element={<STPEquipmentWorkData />} />
                        <Route exact path="/stpqualityparameterdata" element={<STPQualityParameterData />} />
                        <Route exact path="/stppreventiveseweragequalitydata" element={<STPPreventiveSewerageQualityData />} />
                        <Route exact path="/stppreventivemachineryoperationdata" element={<STPPreventiveMachineryOperationData />} />
                        <Route exact path="/stppreventiveenergyconsumptiondata" element={<STPPreventiveEnergyConsumptionData />} />
                        <Route exact path="/stppreventivemachinerychecklistdata" element={<STPPreventiveMachineryChecklistData />} />
                        <Route exact path="/stppreventivempcbtestdata" element={<STPPreventiveMPCBTestData />} />
                        <Route exact path="/stppreventivehousekeepingdata" element={<STPPreventiveHouseKeepingData />} />
                        <Route exact path="/stppreventiveenergyauditdata" element={<STPPreventiveEnergyAuditData />} />
                        <Route exact path="/stpbreakdownmaintainancedata" element={<STPBreakdownMaintainanceData />} />
                        <Route exact path="/stppreventivemaintainancedata" element={<STPPreventiveMaintainanceData />} />
                        <Route exact path="/stpunittypemasterdata" element={<STPUnitTypeMasterData />} />
                        <Route exact path="/stpequipmenttypemasterdata" element={<STPEquipmentTypeMasterData />} />
                        <Route exact path="/stpmachinerydetailsmasterdata" element={<STPMachineryDetailsMasterData />} />
                        <Route exact path="/stpmpcbparametermasterdata" element={<STPMPCBParameterMasterData />} />
                        <Route exact path="/stpmachequipmenttypemasterdata" element={<STPMachEquipmentTypeMasterData />} />

                        {/* STP Dashboard End */}

                    </Route>
                </Routes>
            </Router>
        </>
    )
}
