import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

export const ProjectWiseContractorTableDataAPI = createAsyncThunk("ProjectWiseContractorTableData", async ({ data }) => {
    const {
        M_ProjectWiseContractorID,
        M_WorkTypeID,
        M_ProjectTypeID,
        M_ProjectID,
        M_ContractorID,
        M_UserID,
        token,
        From,
        To,
    } = data



    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };


    // 
    return fetch(`${BaseUrl}/Master/Get_M_ProjectWiseContractor_Select?M_ProjectWiseContractorID=${M_ProjectWiseContractorID ? M_ProjectWiseContractorID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : '0'}&M_ProjectID=${M_ProjectID ? M_ProjectID : '0'}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ProjectWiseContractorTableDataSlice = createSlice({
    name: "ProjectWiseContractorTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectWiseContractorTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectWiseContractorTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ProjectWiseContractorTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectWiseContractorTableDataReducer = ProjectWiseContractorTableDataSlice.reducer



export const ProjectWiseContractorPostDataAPI = createAsyncThunk("ProjectWiseContractorPostData", async ({ data }) => {
    const {
        M_ProjectWiseContractorID,
        M_FinancialYearID,
        M_MonthID,
        M_WorkTypeID,
        M_ProjectID,
        M_ContractorID,
        FromDate,
        ToDate,
        M_StatusID,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ProjectWiseContractorID", M_ProjectWiseContractorID);
    formdata.append("M_FinancialYearID", M_FinancialYearID);
    formdata.append("M_MonthID", M_MonthID);
    formdata.append("M_WorkTypeID", M_WorkTypeID);
    formdata.append("M_ProjectID", M_ProjectID);
    formdata.append("M_ContractorID", M_ContractorID);
    formdata.append("FromDate", FromDate);
    formdata.append("ToDate", ToDate);
    formdata.append("M_StatusID", M_StatusID);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_ProjectWiseContractor_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const ProjectWiseContractorPostDataSlice = createSlice({
    name: "ProjectWiseContractorPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectWiseContractorPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectWiseContractorPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ProjectWiseContractorPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectWiseContractorPostDataReducer = ProjectWiseContractorPostDataSlice.reducer


export const ProjectWiseContractorDeleteAPI = createAsyncThunk("ProjectWiseContractorDelete", async ({ data }) => {
    const {
        M_ProjectWiseContractorID,
        M_UserID,
        token,
        Flag,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ProjectWiseContractorID", M_ProjectWiseContractorID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_ProjectWiseContractor_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const ProjectWiseContractorDeleteSlice = createSlice({
    name: "ProjectWiseContractorDelete",
    initialState: {
        isDeleteLoading: false,
        ProjectWiseContractortableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectWiseContractorDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ProjectWiseContractorDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.ProjectWiseContractortableData = action.payload;

        });
        builder.addCase(ProjectWiseContractorDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.ProjectWiseContractortableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectWiseContractorDeleteReducer = ProjectWiseContractorDeleteSlice.reducer