
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import Header from '../../../../../Components/Header/Header'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import RoadMasterWorkNamePopup from './RoadMasterWorkNamePopup'
import DeletePopup from '../../GardenPopup/DeletePopup'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { WorkNameMasterDeleteAPI, WorkNameTableDataAPI } from '../../../../../Redux/RoadDashboardSlice/WorkNameSlice'
import { Loading } from '../../../../../Helper/Loading'
import { Pegination } from '../../../../../Components/Pegination/Pegination'
import { Work } from '../../MasterSideData/Work'
import { useSearchParams } from 'react-router-dom'


export default function RoadMasterWorkData() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsPost, setIsPost] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')
    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [RoasMasterWorkPopupShow, setRoasMasterWorkPopupShow] = useState()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    const [WorkName, setWorkName] = useState('')
    const [IsSearch, setIsSearch] = useState(false)

    // const [WorkName, setWorkName] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select...",
    // })
    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select...",
    // })

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setRoasMasterWorkPopupShow(true)
    }
    const handleEditOnClick = (item) => {
        setRoasMasterWorkPopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }

    const handleOnCloseClick = () => {
        setRoasMasterWorkPopupShow(false)
    }

    const handleIsSearch = () => {
        setIsSearch(!IsSearch)
        setCurrentPage(0)
    }

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setWorkName('')
    }
    const handleIsPost = () => {
        setIsPost(!IsPost)
    }


    const handleDeleteApi = (item) => {
        const data = {
            M_RM_WorkID: item.m_rM_WorkID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            // handleDeleteCloseClick
        }
        dispatch(WorkNameMasterDeleteAPI({ data }))
    }

    const { isDeleteLoading } = useSelector(state => state.WorkNameMasterDeleteData)

    useEffect(() => {
        const data = {
            M_RM_WorkID: '0',
            WorkName: WorkName,
            M_UserID: UserID,
            token: token,
            From: From,
            To: To,
        }
        dispatch(WorkNameTableDataAPI({ data }))

    }, [IsPost, To, IsSearch, IsClear])

    const { tableData, isLoading } = useSelector(state => state.WorkNameTableData)
    // console.log(tableData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
        handleIsSearch()
    }, [])

    return (
        <>
            {isLoading && <Loading />}

            <div>
                <Header
                    // DashboardHeading="Road Maintainance"
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName} 
                />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId} />
                    <div className="content">
                        {/* <!-- [ breadcrumb ] start --> */}
                        <div className="page-header ">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        {/* <div className="page-header-title">
                                            <h5 className="ps-2 data_mob">MASTER */}

                                        {/* <!-- <span className="float-end">namita</span> --> */}
                                        {/* <span className="float-end fs-6">
                                                <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                        :
                                                        setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                }}
                                                options={SelectWard.DDL}
                                            />

                                            </span> */}
                                        {/* </h5>

                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- [ Main Content ] start --> */}
                        <div className='data_mob'>
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className='col-6'>
                                    <h5 className="ps-1 py-1">Work Name Master</h5>
                                </div>
                                <div className='road_new'>
                                    <div className="float-end butt">
                                        <button type="button" className="btn btn-primary float-end mr-1 ml-1">Export</button>
                                        {/* <RoadMasterWorkNamePopup
                                            open={RoasMasterWorkPopupShow}
                                            handleIsPost={handleIsPost} /> */}
                                        <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                                            style={{ color: "white" }}
                                            onClick={handleAddOnClick}
                                        > Add</i>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                        <div className="col-12">

                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <label for=""><b>Work Name</b></label>
                                                    <input type="text" className="form-control" id="workname" aria-describedby="emailHelp"
                                                        value={WorkName}
                                                        onChange={(e) => setWorkName(e.target.value)}
                                                    />
                                                    {/* <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                className="ddl_width"
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: WorkName.ID, label: WorkName.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setWorkName({ ...WorkName, ID: e.value, Label: e.label })
                                                        :
                                                        setWorkName({ ...WorkName, ID: 0, Label: "Select..." })

                                                }}
                                                options={WorkName.DDL}
                                            /> */}

                                                </div>
                                                <div className="col-12 col-md-6 col-lg-1 d-flex " >
                                                    {/* <button type="button" className="btn-clear btn btn-primary ml-2 mt-4"
                                                        onClick={handleIsSearch}
                                                    >Search</button> */}
                                                     <button type="button" className="btn-search btn btn btn-secondary mr-1 mt-4"
                                                        onClick={handleIsSearch}
                                                    >
                                                        Search
                                                    </button>
                                                    <button type="button" className="btn-clear btn btn-primary ml-3 mt-4"
                                                        onClick={handleClear}
                                                    >Clear</button>
                                                </div>
                                            </div>
                                            <div className="row mob_table table-responsive pt-1">

                                                <table id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            <th>Work Type</th>
                                                            <th>Work Name</th>
                                                            <th>BenchMark (Days)</th>
                                                            <th>Penalty Description</th>
                                                            <th>Penalty Amount (Rs.)</th>
                                                            {/* <th>Heading</th> */}
                                                            <th>Action</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => (
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                    <td>-</td>
                                                                    <td>{item.workName ? item.workName : '-'}</td>
                                                                    <td>{item.benchMark ? item.benchMark : '-'}</td>
                                                                    <td>{item.penalty_Description ? item.penalty_Description : '-'}</td>
                                                                    <td>{item.penalty_Values ? (item.penalty_Values).toFixed(2) : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {/* <RoadMasterWorkNamePopup
                                                                            open={RoasMasterWorkPopupShow}
                                                                            handleIsPost={handleIsPost}
                                                                            item={item}
                                                                            Flag='Update'
                                                                        /> */}
                                                                        <span>
                                                                            <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => handleEditOnClick(item)}
                                                                            >
                                                                            </i>
                                                                        </span>
                                                                        <span>
                                                                            <DeletePopup
                                                                                open={DeletePopupShow}
                                                                                item={item}
                                                                                handleDeleteApi={handleDeleteApi}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                                :
                                                                <></>
                                                        }

                                                    </tbody>
                                                </table>

                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                
                     {
                        RoasMasterWorkPopupShow ?
                            <RoadMasterWorkNamePopup
                                open={RoasMasterWorkPopupShow}
                                popupHeading="Work Name"
                                handleIsPost={handleIsPost}
                                handleOnCloseClick={handleOnCloseClick}
                                Flag={apiFlag}
                                rowData={rowData}
                            />
                            : <></>
                    }
                
            </div>
        </>


    )
}