// import { Dcoptions, Pieoptions, WorkAssign } from "../../../Helper/Data"
import React, { useEffect, useState } from 'react'
import { Loading } from '../../../Helper/Loading'
import Header from '../../../Components/Header/Header'
import Sidebar from '../../../Components/Sidebar/Sidebar'
import GardenMaintainanceCardManage from './GarderDashboardComponent/GardenMaintainanceCard/GardenMaintainanceCardManage'
import WaterMasterCardManage from './WaterSupplyDashboardComponent/WaterMasterCard/WaterMasterCardManage'
import PreventiveWaterQualityCardManage from './WaterSupplyDashboardComponent/WaterPreventiveQualityCard/PreventiveWaterQualityCardManage'
import WaterPreventiveWorkCardManage from './WaterSupplyDashboardComponent/WaterPreventiveWorkCard/WaterPreventiveWorkCardManage'
import WaterAssignMainWorkCardManage from './WaterSupplyDashboardComponent/WaterAssignedMaintainanceWorkCard/WaterAssignMainWorkCardManage'
import WaterPreMainWorkCardManage from './WaterSupplyDashboardComponent/WaterPreventiveMaintainanceWorkCard/WaterPreMainWorkCardManage'
import { useAuthState } from '../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

export const WaterSupplyDashboard = () => {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 1,
        Label: "Select Ward",
    })
    const [Countdata, setCountdata] = useState({})
    // const [FinancialYearID, setFinancialYear] = useState('')
    const [HeaderFinancialYear, setHeaderFinancialYear] = useState('')
    const [HeaderMonthID, setHeaderMonthID] = useState('')
    const [MonthID, setMonthID] = useState('')
    const [YearValue, setYearValue] = useState(null)
    const [YearLabel, setYearLabel] = useState(null)
    const [MonthValue, setMonthValue] = useState(null)
    const [MonthLabel, setMonthLabel] = useState(null)
    const [WardValue, setWardValue] = useState(null)
    const [WardLabel, setWardLabel] = useState(null)

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    const handleGetFinancialYearID = (FinancialYear, Month) => {
        setHeaderMonthID(Month)
        setHeaderFinancialYear(FinancialYear)
    }

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_WardID: WardValue,
            M_MonthID: MonthValue,
            M_UserID: UserID,
            Flag: "WEB",
            token: token,
            handleGetCount: handleGetCount
        }
        if (YearValue && MonthValue && WardValue) {
            // dispatch(GardenDashMainCountDataAPI({ data }))
        }
    }, [YearValue, MonthValue, WardValue])

    const { GardenDashCountData, isLoading } = useSelector(state => state.GardenDashMainCountData)

    const handleGetCount = (data) => {
        // console.log("data", data)
        let tempData = {}
        data.forEach((item) => {
            tempData[item.paramName] = item.totalCount
        })
        setCountdata(tempData)
    }

    return (
        <>
            {isLoading && <Loading />}  
            {/* <div className="main-content side_shrink position-relative border-radius-lg "></div> */}
            <div>
                <Header
                    // DashboardHeading="Water Supply Maintenance"
                    setYearValue={setYearValue}
                    setMonthValue={setMonthValue}
                    FinancialYear={FinancialYear}
                    setFinancialYear={setFinancialYear}
                    Month={Month}
                    setMonth={setMonth}
                    setYearLabel={setYearLabel}
                    setMonthLabel={setMonthLabel}
                    setWardValue={setWardValue}
                    setWardLabel={setWardLabel}
                    DashboardName={DashboardName}
                />
                {/* <Sidebar active="dashboard" /> */}
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId} />
                    <div className="content1 content">

                        {/* <div id="page-content-wrapper"> */}
                        {/* <div className="pcoded-main-container main-row">
                        <div className="pcoded-content "> */}
                        {/* <!-- [ breadcrumb ] start --> */}
                        <div className="page-header">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12 col-12">
                                        <div className="page-header-title d-flex justify-content-between align-items-center">
                                            <h5 className=" col-6 col-lg-8 ps-2">DASHBOARD</h5>
                                            {/* <div className="col-md-6 col-lg-3 col-6">
                                                <WardNameDataDDL
                                                    WardName={WardName}
                                                    setWardName={setWardName}
                                                    isClearable={false}
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- [ Main Content ] start -->
            <!--  --> */}

                        <div className="mb-4 m-1 py-2 px-3 master_border">
                            <GardenMaintainanceCardManage />
                        </div>

                        <div className="m-1 py-2 pb-4 master_border">
                            <WaterMasterCardManage
                                Countdata={Countdata}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName}
                            />
                        </div>

                        <div className="row mt-4 m-1 py-2 pb-0 master_border">
                            <div className="col-12">
                                <h5 className="ps-2">Preventive Water Quality</h5>
                            </div>
                            <PreventiveWaterQualityCardManage
                                Countdata={Countdata}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName}
                            />

                        </div>

                        <div className="row mt-4 m-1 py-2 pb-2 master_border d-flex align-items-center">
                            <div className="col-12">
                                <h5 className="ps-2">Preventive Work</h5>
                            </div>
                            <WaterPreventiveWorkCardManage
                                Countdata={Countdata}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName} />
                        </div>
                        <div className="row mt-4 m-1 py-2 pb-2 master_border">
                            <div className="col-12 mb-3">
                                <h5 className="ps-2">Preventive Maintainance Work</h5>
                            </div>
                            <WaterPreMainWorkCardManage
                                Countdata={Countdata}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName} />

                        </div>
                        <div className="row mt-4 m-1 py-2 pb-2 master_border">
                            <div className="col-12">
                                <h5 className="ps-2 py-2 ">Assigned Maintainance Work</h5>
                            </div>
                            <WaterAssignMainWorkCardManage
                                Countdata={Countdata}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName} />

                        </div>
                    </div>
                    {/* <!-- [ Main Content ] end --> */}
                </div>
            </div>
        </>


    )
}

{/* <Doughnut
                                            data={data}
                                            options={{
                                                plugins: {
                                                    legend: {
                                                        display: false
                                                    },
                                                    tooltip: {
                                                        enabled: false
                                                    }
                                                },
                                                rotation: -90,
                                                circumference: 180,
                                                cutout: "60%",
                                                maintainAspectRatio: true,
                                                responsive: true
                                            }}
                                        /> */}
{/* <div
                                            style={{
                                                position: "absolute",
                                                top: "55%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                textAlign: "center"
                                            }}
                                        >
                                            <div>Text Here</div>
                                        </div> */}