
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { ContractorWiseAssetDDLAPI, WorkTypeDDLAPI } from '../../Redux/DDLSlice'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'

export const ContractorWiseAssetDDL = (props) => {
    const { WorkType, setWorkType, WorkPeriodID, ContractorWiseAsset,Flag2, setContractorWiseAsset, WardWiseAssetName, setWardWiseAssetName, RoleID, rowData, Flag, IsPost, ZoneNameDDLID, AssetType, IsDisabled, Language, Flag1 } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()


    useEffect(() => {
        const data = {
            M_WorkTypeID: Flag2 == 'Play' ? 4 : WorkType.ID,
            M_AssetID: WardWiseAssetName.ID,
            M_AssetTypeID: AssetType.ID,
            M_ZoneID: ZoneNameDDLID.ID,
            M_GD_WorkPeriodID: WorkPeriodID,
            M_RoleID: RoleID,
            M_UserID: UserID,
            Flag: Flag,
            token: token,
            Language: Language.Label
        }
        if (WorkType.ID != 0) {
            { Language.Label != '' && dispatch(ContractorWiseAssetDDLAPI({ data })) }
        }
    }, [Language.Label, IsPost, WorkType.ID, WardWiseAssetName.ID, ZoneNameDDLID.ID, AssetType.ID])

    const { DDLData } = useSelector(state => state.ContractorWiseAssetDDLData)



    useEffect(() => {
        handleContractorWiseAssetDDL()
    }, [DDLData])

    const handleContractorWiseAssetDDL = () => {
        // console.log(DeptDDLDataa)
        if (DDLData && DDLData && DDLData.length > 0) {
            let list = DDLData.map((item, index) => ({
                value: item.m_ContractorID,
                label: item.contractorName,
            }))

            setContractorWiseAsset({
                DDL: list,
                // ID: Flag === 'Update' ? rowData.m_ContractorID : Flag1 == 'ContractorWiseAsset' ? list[0].value : 0,
                // Label: Flag === 'Update' ? rowData.contractorName : Flag1 == 'ContractorWiseAsset' ? list[0].label : 0,
                ID: Flag === 'Update' ? rowData.m_ContractorID : 0,
                Label: Flag === 'Update' ? rowData.contractorName : "Select...",
            })
        }
        else {
            setContractorWiseAsset({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofContractorWiseAsset"><b>{Flag1 == 'ContractorName' ? t('Dashboard.Work_Assigned_by_Officials.Contractor_Name') : 'Supplier Name'}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                isDisabled={Flag === 'Update' && IsDisabled ? true : false}
                className=""
                isSearchable
                maxMenuHeight={150}
                value={{ value: ContractorWiseAsset.ID, label: ContractorWiseAsset.Label }}
                onChange={(e) => {
                    e ?
                        setContractorWiseAsset({ ...ContractorWiseAsset, ID: e.value, Label: e.label })
                        :
                        setContractorWiseAsset({ ...ContractorWiseAsset, ID: 0, Label: "Select..." })
                }}

                options={ContractorWiseAsset.DDL}
            />
        </div>
    )
}