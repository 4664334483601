import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const DesignationTableDataAPI = createAsyncThunk("DesignationTableData", async ({ data }) => {
    const {
        M_DesignationID,
        DesignationName,
        M_UsersID,
        token,
        From,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_M_Designation_Select?M_DesignationID=${M_DesignationID ? M_DesignationID : '0'}&DesignationName=${DesignationName}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DesignationTableDataSlice = createSlice({
    name: "DesignationTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DesignationTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DesignationTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(DesignationTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DesignationTableDataReducer = DesignationTableDataSlice.reducer


export const DesignationPostDataAPI = createAsyncThunk("DesignationPostData", async ({ data }) => {
    const {
        M_DesignationID,
        DesignationName,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_DesignationID", M_DesignationID);
    formdata.append("DesignationName", DesignationName);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/Master/Post_M_Designation_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const DesignationPostDataSlice = createSlice({
    name: "DesignationPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DesignationPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DesignationPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(DesignationPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DesignationPostDataReducer = DesignationPostDataSlice.reducer

// -----------------Delete API ---------------

export const DesignationDeleteAPI = createAsyncThunk("DesignationDelete", async ({ data }) => {
    const {
        M_DesignationID,        
        M_UserID,
        token,
        Flag,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_DesignationID", M_DesignationID);
    formdata.append("M_UserID", M_UserID);
    
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/Master/Post_M_Designation_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
                
            }
            return result

        })
})

const DesignationDeleteSlice = createSlice({
    name: "DesignationDelete",
    initialState: {
        isDeleteLoading: false,
       DesignationtableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DesignationDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(DesignationDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.DesignationtableData = action.payload;

        });
        builder.addCase(DesignationDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.DesignationtableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DesignationDeleteReducer = DesignationDeleteSlice.reducer