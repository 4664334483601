import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { WardNameDataDDL } from '../../../../Components/CommonDDL/WardNameDataDDL';
import { AssetDataDDL } from '../../../../Components/CommonDDL/AssetDataDDL';
import { YesNoDataDDL } from '../../../../Components/CommonDDL/YesNoDataDDL';
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import { AssetTypeDataDDL } from '../../../../Components/CommonDDL/AssetTypeDataDDL';
import { GardenMasterPostDataAPI } from '../../../../Redux/MasterSlice/GardenMasterSlice';
import { StatusNameDataDDL } from '../../../../Components/CommonDDL/StatusNameDataDDL';
import { AstricSign } from '../../../../Helper/AstricSign';
import { MaintainByDataDDL } from '../../../../Components/CommonDDL/MaintainByDDL';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { WardnameHeaderDDL } from '../../../../Components/CommonDDL/WardnameHeaderDDL';
import { DashBoardScreenWardDDL } from '../../../../Components/CommonDDL/DashboardScreenWardDDL';
import { AssetNameDataDDL } from '../../../../Components/CommonDDL/AssetNameDDL';
import { PlayEquipmentMasterPostDataAPI } from '../../../../Redux/MasterSlice/PlayEquipmentSlice';
import moment from "moment";
import { ContractorNameDDL } from '../../../../Components/CommonDDL/ContractorNameDDL';
import { ContractorWiseAssetDDL } from '../../../../Components/CommonDDL/WorkTypeWiseContractorDDL';
import { WardWiseAssetNameDataDDL } from '../../../../Components/CommonDDL/WardWiseAssetDDL';
import CommonDashboardScreenWardDDL from '../../../../Components/CommonDDL/CommonDashboardScreenWardDDL';
import { ZoneNameDDL } from '../../../../Components/CommonDDL/DashboardDDL/ZoneNameDDL';

export default function PlayEquipmentPopup(props) {

    const { handleOnCloseClick, open, EditIcon, Flag, ZLable, ZWard, GardenMasterFlag, popupHeading, M_AssetTypeID, handleIsPost, apiFlag, rowData, AreaMeter } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams()
    let Lang = searchParams.get("Lang")

    // const [rowData, setRowData] = useState(rowData)

    const [EquipmentName, setEquipmentName] = useState(Flag === 'Update' ? rowData.equipmentName : '');
    const [EquipmentNo, setEquipmentNo] = useState(Flag === 'Update' ? rowData.equipmentNumber : '');
    const [Warranty, setWarranty] = useState(Flag === 'Update' ? rowData.warrantyPeriod : '');
    const [CMCPeriod, setCMCPeriod] = useState(Flag === 'Update' ? rowData.cmcPeriod : '');
    const [Date, setDate] = useState(Flag === 'Update' ? moment(rowData.equipmentInstallationDate).format('YYYY-MM-DD') : "")
    const [IsOpen, setIsOpen] = useState(false);

    // fieldDate: apiFlag === "Insert" ? '' : rowData?.fieldDate.split("/").reverse().join("-"),

    // const [SectorName, setSectorName] = useState({
    //   DDL: [],
    //   ID: Flag === 'Update' ? rowData.m_gD_SectorID : 0,
    //   Label: Flag === 'Update' ? rowData.sectorName : "Select...",
    // })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_WardID : 0,
        Label: Flag === 'Update' ? rowData.wardName : "Select...",
    })

    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: M_AssetTypeID,
        Label: Flag === 'Update' ? rowData.assetTypeName : "Select...",
    })

    const [AssetName, setAssetName] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_AssetID : 0,
        Label: Flag === 'Update' ? rowData.assetName : "Select...",
    })

    const [Status, setStatus] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_StatusID : 0,
        Label: Flag === 'Update' ? rowData.statusName : "Select...",

    })

    const [SupplierName, setSupplierName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WardWiseAssetName, setWardWiseAssetName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    const [ContractorName, setContractorName] = useState({
        DDL: [],
        // ID: ContractorID && ContractorID ? ContractorID : 0,
        // Label: ContractorLabel && ContractorLabel ? ContractorLabel : "Select...",
        ID: Flag === 'Update' ? rowData.m_ContractorID : 0,
        Label: Flag === 'Update' ? rowData.contractorName : "Select...",

    })

    const [ContractorWiseAsset, setContractorWiseAsset] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })



    useEffect(() => {
        setEquipmentName(Flag === 'Update' ? rowData.equipmentName : '')
        setEquipmentNo(Flag === 'Update' ? rowData.equipmentNumber : '')
        setWarranty(Flag === 'Update' ? rowData.warrantyPeriod : '')
        setCMCPeriod(Flag === 'Update' ? rowData.cmcPeriod : '')
        setDate(Flag === 'Update' ? moment(rowData.equipmentInstallationDate).format('YYYY-MM-DD') : '')
    }, [rowData, IsOpen])

    const handleClearField = () => {

        if (Flag != 'Update') {
            setEquipmentName('')
            setEquipmentNo('')
            setWarranty('')
            setCMCPeriod('')
            setDate('')
        }
    }

    const [Submit, setSubmit] = useState(false)
    const handlePost = () => {
        if (Submit) {
            return;
        }
        setSubmit(true)
        const data = {
            M_AssetID: WardWiseAssetName.ID,
            M_AssetWiseEquipmentID: Flag === 'Update' ? rowData.m_AssetWiseEquipmentID : '0',
            EquipmentName: EquipmentName,
            EquipmentNumber: EquipmentNo,
            EquipmentInstallationDate: Date,
            M_ContractorID: ContractorWiseAsset.ID,
            WarrantyPeriod: Warranty,
            CMCPeriod: CMCPeriod,
            // M_WardID: WardName.ID,
            M_StatusID: Status.ID,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleClearField: handleClearField,
            handleOnCloseClick: handleOnCloseClick,
            // Submit:Submit
        }
        dispatch(PlayEquipmentMasterPostDataAPI({ data }))
    }

    // const { isLoading } = useSelector(state => state.PlayEquipmentMasterPostData)

    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_ZoneID : 0,
        Label: Flag === 'Update' ? rowData.zoneName : "Select...",

    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    // const handleDeleteApi = (rowData) => {
    //   const data = {
    //     M_EmployeeID: rowData.m_EmployeeID,
    //     M_UserID: UserID,
    //     token: token,
    //     handleIsPost: handleIsPost
    //   }
    //   // dispatch(EmployeeDeleteAPI({ data }))
    // }

    // const { isDeleteLoading } = useSelector(state => state.EmployeeDeleteData)

    return (
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick} contentStyle={{ width: "70vw" }}
        >
            {/* {
        close => ( */}
            {/* // n_popup-content  */}
            {/* <div className="modal-dialog  modal-xl"> */}
            <div className='area_pop modal-dialog modal-dialog-scrollable modal-xl'>
                <div className="modal-content">
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">{popupHeading}</h4>
                        <button className="btn-close m-1 mr-3" type="button" onClick={handleOnCloseClick} />
                    </div>
                    <div className="modal-body">
                        <div className='inner-modal-body'>
                            <div className="row details-row">
                                <div className="col-md-6 col-12 col-lg-4">
                                    <ZoneNameDDL
                                        ZoneNameDDLID={ZoneNameDDLID}
                                        setZoneNameDDLID={setZoneNameDDLID}
                                        ZLable={ZLable}
                                        ZWard={ZWard}
                                        Language={Language}
                                        Flag={Flag}
                                        rowData={rowData}
                                    // ZoneDDL='DashboardZone'
                                    />
                                </div>
                                <div className="col-md-6 col-12 col-lg-4">
                                    <WardnameHeaderDDL
                                        WardName={WardName}
                                        setWardName={setWardName}
                                        Flag={Flag}
                                        rowData={rowData}
                                        Language={Language}
                                        M_ZoneID={ZoneNameDDLID.ID}
                                    // WardDDL='DashboardWard'
                                    />
                                </div>
                                {/* <div className="col-md-6 col-12 col-lg-8"> */}
                                {/* <DashBoardScreenWardDDL
                                        WardName={WardName}
                                        setWardName={setWardName}
                                        // WLable={WLable}
                                        // HWard={HWard}
                                        // flag={flag}
                                        Language={Language}
                                        M_ZoneID={ZoneNameDDLID.ID}
                                        Flag='ward'
                                    /> */}
                                {/* <CommonDashboardScreenWardDDL
                                        WardName={WardName}
                                        setWardName={setWardName}
                                        // WLable={WLable}
                                        // HWard={HWard}
                                        // ZLable={ZLable}
                                        // ZWard={ZWard}
                                        Language={Language}
                                        ZoneNameDDLID={ZoneNameDDLID}
                                        setZoneNameDDLID={setZoneNameDDLID}
                                    /> */}
                                {/* </div> */}
                                <div className="col-12 col-md-6 col-lg-4">
                                    <AssetTypeDataDDL
                                        AssetType={AssetType}
                                        setAssetType={setAssetType}
                                        Language={Language}
                                        M_AssetTypeID={M_AssetTypeID}
                                        IsDisabled={true}
                                    />
                                </div>

                                {/* <div className="col-12 col-md-6 col-lg-4">
                                    <AssetNameDataDDL
                                        AssetName={AssetName}
                                        setAssetName={setAssetName}
                                        AssetType={AssetType}
                                        Language={Language}
                                        Flag1='Garden Name'
                                        rowData={rowData}
                                        Flag={Flag}

                                    />
                                </div> */}
                                <div className="col-12 col-md-6 col-lg-4">
                                    {/* <WardWiseAssetNameDataDDL
                                        WardWiseAssetName={WardWiseAssetName}
                                        setWardWiseAssetName={setWardWiseAssetName}
                                        WardName={WardName}
                                        AssetType={AssetType}
                                        // M_AssetTypeID={1}
                                        Language={Language}
                                    // Flag='Garden Name'
                                    /> */}
                                    <WardWiseAssetNameDataDDL
                                        WardWiseAssetName={WardWiseAssetName}
                                        setWardWiseAssetName={setWardWiseAssetName}
                                        WardName={WardName}
                                        AssetType={AssetType}
                                        M_WorkTypeID='5'
                                        M_ZoneID={ZoneNameDDLID}
                                        M_RoleID={RoleID}
                                        M_GD_WorkPeriodID='0'
                                        // M_AssetTypeID={1}
                                        Language={Language}
                                        Flag='Master'
                                    />
                                </div>
                                <div className="col-md-6 col-12 col-lg-4">
                                    <div className="form-group">
                                        <label className="d-block fw-bold" htmlFor="NameofDepartment">Equipment Name <AstricSign /> :</label>
                                        <input type="text"
                                            className="form-control"
                                            id="EquipmentName"
                                            value={EquipmentName}
                                            onChange={(e) => setEquipmentName(e.target.value)}

                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 col-lg-4">
                                    <div className="form-group">
                                        <label className="d-block fw-bold" htmlFor="NameofDepartment">Equipment No <AstricSign /> :</label>
                                        <input type="text"
                                            className="form-control"
                                            id="EquipmentNo"
                                            value={EquipmentNo}
                                            onChange={(e) => setEquipmentNo(e.target.value)}

                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <div className="form-group">
                                        <label className="d-block fw-bold" htmlFor="NameofDepartment">Installation Date</label>
                                        <input type="date"
                                            className="form-control"
                                            id="Date"
                                            value={Date}
                                            onChange={(e) => setDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-12 col-md-6 col-lg-4">
                                    <ContractorNameDDL
                                        ContractorName={ContractorName}
                                        setContractorName={setContractorName}
                                        Language={Language}
                                        rowData={rowData}
                                        Flag={Flag}
                                        Flag1='SupplierName'
                                    />
                                </div> */}

                                <div className="col-12 col-md-6 col-lg-4">
                                    <ContractorWiseAssetDDL
                                        ContractorWiseAsset={ContractorWiseAsset}
                                        setContractorWiseAsset={setContractorWiseAsset}
                                        Language={Language}
                                        rowData={rowData}
                                        WardWiseAssetName={WardWiseAssetName}
                                        RoleID={RoleID}
                                        Flag='Master'
                                        // Flag={Flag}
                                        // M_WorkTypeID={4}
                                        WorkType={4}
                                        // Flag1='ContractorWiseAsset'
                                        ZoneNameDDLID={ZoneNameDDLID}
                                        AssetType={AssetType}
                                        Flag2='Play'
                                    />
                                </div>
                                <div className="col-md-6 col-12 col-lg-4">
                                    <div className="form-group">
                                        <label className="d-block fw-bold" htmlFor="NameofDepartment">Warranty <AstricSign /> :</label>
                                        <input type="text"
                                            className="form-control"
                                            id="Warranty"
                                            value={Warranty}
                                            onChange={(e) => setWarranty(e.target.value)}

                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 col-lg-4">
                                    <div className="form-group">
                                        <label className="d-block fw-bold" htmlFor="NameofDepartment">CMC Period <AstricSign /> :</label>
                                        <input type="text"
                                            className="form-control"
                                            id="CMCPeriod"
                                            value={CMCPeriod}
                                            onChange={(e) => setCMCPeriod(e.target.value)}

                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 col-lg-4">
                                    <StatusNameDataDDL
                                        Status={Status}
                                        setStatus={setStatus}
                                        rowData={rowData}
                                        Flag={Flag}
                                    // Language={Lang}
                                    />
                                </div>
                                {/* <div className="col-md-6 col-12 col-lg-4">
                                    <StatusNameDataDDL
                                        Status={Status}
                                        setStatus={setStatus}
                                        rowData={rowData}
                                        Flag={Flag}
                                    // Language={Lang}
                                    />
                                </div> */}
                                {/* <div className="col-md-6 col-12 col-lg-4">
                                    <MaintainByDataDDL
                                        MaintainBy={MaintainBy}
                                        setMaintainBy={setMaintainBy}
                                        rowData={rowData}
                                        Flag={Flag}
                                        Language={Language}
                                    />
                                </div> */}
                            </div>
                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                        disabled={
                                            // isLoading ||
                                            WardName.ID == 0 || AssetType.ID == 0 || WardWiseAssetName.ID == 0 ||
                                            EquipmentName == '' || EquipmentNo == '' || Date == '' || Status.ID == 0 ||
                                            Warranty == '' || CMCPeriod == '' || Status.ID == 0
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* )} */}


        </Popup>
    )

}