import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { WardNameDataDDL } from '../../../../../Components/CommonDDL/WardNameDataDDL';
import { LocationNameMasterPostDataAPI } from '../../../../../Redux/MasterSlice/LocationNameMaster';
import { useAuthState } from '../../../../../Helper/Context';
import { useDispatch } from 'react-redux';


export default function RoadMasterSectorPopup(props) {

    const { handleOnCloseClick, open, EditIcon, Flag, GardenMasterFlag, popupHeading, M_AssetTypeID, handleIsPost, apiFlag, rowData, HWard, WLable } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()
    const [IsOpen, setIsOpen] = useState(false)
    // console.log(item)
    const [LocationName, setLocationName] = useState(Flag === 'Update' ? rowData.locationName : '');

    // useEffect(() => {
    //     setLocationName((Flag === 'Update' ? rowData.locationName : ''))
    //     // setRowData(item)
    // }, [rowData, IsOpen])


    const [WardName, setWardName] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_WardID : 0,
        Label: Flag === 'Update' ? rowData.wardName : "Select...",

    })

    // console.log(rowData)

    const handlePost = () => {
        const data = {
            M_RM_LocationId: Flag === 'Update' ? rowData.m_rM_LocationID : '0',
            M_WardID: WardName.ID,
            LocationName: LocationName,
            M_UserID: UserID,
            token: token,
            Flag: Flag,
            handleIsPost: handleIsPost,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(LocationNameMasterPostDataAPI({ data }))
    }

    return (

        // <Popup
        //     className=''
        //     onOpen={() => setIsOpen(!IsOpen)}
        //     contentStyle={{ width: '55%', height: '92%', top: '5%' }}
        //     trigger={
        //         Flag === 'Update' ?
        //             <i className="fa-solid fa-pen-to-square" style={{ cursor: 'pointer' }} title='Edit'></i>
        //             :
        //             <i className="fa-solid fa-plus float-end btn btn-secondary mb-1"
        //     style={{ color: "white" }}
        //   >
        //     <text className="ml-2 poppins">Add</text>
        //   </i>

        //     } modal>
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="location_mob_pop modal-dialog modal-dialog-scrollable modal-xl" style={{ alignItems: "center", justifyContent: 'center' }}>
                <div className="modal-content road_ward_modal">
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Location Name</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>
                    <div className="modal-body">
                        <div className='inner-modal-body'>
                            <div className="row details-row">
                                <div className="col-md-6 col-12 col-lg-6">
                                    <div className="form-group">
                                        <WardNameDataDDL
                                            WardName={WardName}
                                            setWardName={setWardName}
                                            rowData={rowData}
                                            HWard={HWard}
                                            WLable={WLable}
                                            // item={item}
                                            Flag={Flag}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block"><b>Location Name :</b></label>
                                        <input type="text" className="form-control mt-1"
                                            value={LocationName}
                                            onChange={(e) => setLocationName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="mb-4 pt-4">
                                    <h6 className="float-end">
                                        <span className="submit_btn"
                                            onClick={handlePost}
                                        >{Flag === 'Update' ? 'Update' : 'Submit'}</span>
                                        <span className=" cancel_btn ms-2" onClick={handleOnCloseClick} >Cancel</span>
                                    </h6>
                                </div> */}

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                        disabled={
                                            WardName.ID == 0 || LocationName == ''                                         }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </Popup>
    )

}