import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const WardTableDataAPI = createAsyncThunk("WardTableData", async ({ data }) => {
    const {
        M_ZoneID,
        M_WardID,
        WardName,
        M_UsersID,
        token,
        From,
        To,
        Language,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_M_Ward_Select?M_WardID=${M_WardID}&M_ZoneID=${M_ZoneID}&WardName=${WardName}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WardTableDataSlice = createSlice({
    name: "WardTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WardTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WardTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WardTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WardTableDataReducer = WardTableDataSlice.reducer


export const WardPostDataAPI = createAsyncThunk("WardPostData", async ({ data }) => {
    const {
        M_WardID,
        M_ZoneID,
        WardName,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WardID", M_WardID);
    formdata.append("M_ZoneID", M_ZoneID);
    formdata.append("WardName", WardName);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/Master/Post_M_Ward_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const WardPostDataSlice = createSlice({
    name: "WardPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WardPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WardPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WardPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const WardPostDataReducer = WardPostDataSlice.reducer

// -----------------Delete API ---------------

export const WardDeleteAPI = createAsyncThunk("WardDelete", async ({ data }) => {
    const {
        M_WardID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    var formdata = new FormData();
    formdata.append("M_WardID", M_WardID);
    formdata.append("M_UserID", M_UserID);
    
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/Master/Post_M_Ward_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const WardDeleteSlice = createSlice({
    name: "WardDelete",
    initialState: {
        isDeleteLoading: false,
        WardData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WardDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(WardDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.WardData = action.payload;

        });
        builder.addCase(WardDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.WardData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const WardDeleteReducer = WardDeleteSlice.reducer