import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useSearchParams } from 'react-router-dom';
import { WardNameDataDDL } from '../../../../../../Components/CommonDDL/WardNameDataDDL';
import { useAuthState } from '../../../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import { WaterSupplyPumpStationPostDataAPI } from '../../../../../../Redux/WaterSupplyDashBoardSlice/WaterSupplyMasterSlice/WaterSupplyPumpStationSlice';
import { Loading } from '../../../../../../Helper/Loading';
import { AstricSign } from '../../../../../../Helper/AstricSign';

export default function PumpStationMasterPagePopup(props) {

    const { handleOnCloseClick, open, EditIcon, Flag, GardenMasterFlag, popupHeading, handleIsPost, apiFlag, rowData } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [PumpingStation, setPumpingStation] = useState(Flag === 'Update' ? rowData.pumpStation : '')
    const [IsOpen, setIsOpen] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [WardName, setWardName] = useState({
        ID: Flag === 'Update' ? rowData.m_WardID : 0,
        Label: Flag === 'Update' ? rowData.wardName : "Select...",
    })

    const handleClear = () => {
        setIsClear(!IsClear)
        setPumpingStation('')
        setWardName({
            ...WardName,
            ID: 0,
            Label: 'Select...',
        })
    }

    const handlePost = () => {
        const data = {
            M_WS_PumpStationID: Flag === 'Update' ? rowData.m_wS_PumpStationID : '0',
            M_WardID: WardName.ID,
            PumpStation: PumpingStation,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(WaterSupplyPumpStationPostDataAPI({ data }))

    }
    const { isPostLoading } = useSelector(state => state.WaterSupplyPumpStationPostData)
    return (
        <>

            <Popup
                open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
            >
                {isPostLoading && <Loading />}
                <div className="water_pop modal-dialog  modal-xl">
                    <div className="modal-content">
                        <div className="modal-header pl-4 p-1">
                            <h4 className="modal-title" id="staticBackdropLabel">Pump Station</h4>
                            <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                        </div>

                        <div className="modal-body">
                            <div className='inner-modal-body'>

                                <div className="row details-row">

                                    <div className="col-12 col-md-6 col-lg-6" >
                                        <WardNameDataDDL
                                            WardName={WardName}
                                            setWardName={setWardName}
                                            rowData={rowData}
                                            Flag={Flag}
                                        />

                                    </div>
                                    <div className=" col-12 col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label className="d-block" htmlFor="NameofDepartment"><b>Pumping Station <AstricSign/> :</b></label>
                                            <input type="text"
                                                className="form-control"
                                                name="PumpingStation"
                                                value={PumpingStation}
                                                onChange={(e) => setPumpingStation(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-lg-12">
                                    <div className="btn-action d-flex justify-content-end mt-lg-3">
                                        <button type='button'
                                            className="btn allBtn  float-right submit_btn"
                                            onClick={() => (handlePost(), handleClear())}
                                            disabled={
                                                WardName.ID == 0 || PumpingStation == ''
                                            }
                                        >{Flag === "Update" ? "Update" : "Save"} </button>
                                        <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* )} */}
            </Popup>
        </>
    )

}