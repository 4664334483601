
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { WardNameDDLAPI } from '../../../Redux/DDLSlice'
import { useAuthState } from '../../../Helper/Context'

export const WardUserDDL = (props) => {
    const { WardName, setWardName, WLable, HWard, flag, Language, ZoneNameDDLID } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const d = new Date();
    let month = d.getMonth();

    const [MonthID, setMonthID] = useState(month + 1)

    useEffect(() => {
        const data = { UserID, token, Language: Language.Label, M_ZoneID: ZoneNameDDLID.ID }
        // if (M_ZoneID != 0) {
        { Language.Label != '' && dispatch(WardNameDDLAPI({ data })) }
        // }
    }, [Language.Label, ZoneNameDDLID.ID])

    const { WardDDLData } = useSelector(state => state.WardNameDDLData)


    useEffect(() => {
        handleWardNameDDL()
    }, [WardDDLData])

    const handleWardNameDDL = () => {
        if (WardDDLData && WardDDLData.table && WardDDLData.table.length > 0) {
            let list = WardDDLData.table.map((item, index) => ({
                value: item.id,
                label: item.wardName,
            }))
            if (flag == 'Screen') {
                setWardName({
                    DDL: list,
                    ID: HWard,
                    Label: WLable,
                })
            } else {
                setWardName({
                    DDL: list,
                    ID: 0,
                    Label: 'Select...',
                })
            }
        }
        else {
            setWardName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group" >
            <label className="d-block" htmlFor="NameofDepartment"><b>{t('Dashboard.Titles.Ward_Name')}</b></label>

            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width"
                isSearchable
                maxMenuHeight={150}
                value={{ value: WardName.ID, label: WardName.Label }}
                onChange={(e) => {
                    e ?
                        setWardName({ ...WardName, ID: e.value, Label: e.label })
                        :
                        setWardName({ ...WardName, ID: 0, Label: "Select..." })

                }}
                options={WardName.DDL}
            />

        </div>
    )
}