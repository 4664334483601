import React from 'react'
import PcmcLoginPage from './PcmcLoginPage'
import Login from './login'
import { BaseUrl } from '../../Helper/BaseUrl'

export default function LoginPageComp() {
    return (
        <>
            {/* {
                BaseUrl === "http://pcmcapi.cdatinfotech.com" ? */}
                    <PcmcLoginPage />
                    {/* :
                    <Login />
            } */}
        </>
    )
}
