import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useAuthState } from '../../../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { EnergyConsumptionPostDataAPI } from '../../../../../../Redux/WaterSupplyDashBoardSlice/PreventiveWorkWaterSupply/EnergyConsumptionSlice';
import moment from 'moment'

export default function WaterEnergyConsumptionPreventiveWorkPopup(props) {

    const { handleOnCloseClick, open, Flag, handleIsPost, apiFlag, rowData } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [IsOpen, setIsOpen] = useState(false)

    const [WaterEnergyConsumptionTextField, setWaterEnergyConsumptionTextField] = useState({
        readingDate: Flag === 'Update' ? moment(rowData.readingDate).format("YYYY-MM-DD") : '',
        wardName: Flag === 'Update' ? rowData.wardName : '',
        pumpStation: Flag === 'Update' ? rowData.pumpStation : '',
        electricConsumption: Flag === 'Update' ? rowData.electricConsumption : '',
        consumptionKWH: Flag === 'Update' ? rowData.consumptionKWH : '',
        powerFactor:Flag === 'Update' ? rowData.powerFactor : '',
        powerFactorIncentive:Flag === 'Update' ? rowData.powerFactorIncentive : '',
    })

    const handleWaterEnergyConsumptionTextField = (e) => {
        setWaterEnergyConsumptionTextField({ ...WaterEnergyConsumptionTextField, [e.target.name]: e.target.value })
    }


    const handleClear = () => {
        // setIsClear(!IsClear)
        
        setWaterEnergyConsumptionTextField({
            wardName:'',
            pumpStation:'',
            electricConsumption:'',
            consumptionKWH:'',
            powerFactor:'',
            powerFactorIncentive:''
        })
    }

    const handlePost = () => {
        const data = {
            T_WS_EnergyConsumptionID: Flag === 'Update' ? rowData.t_wS_EnergyConsumptionID : '0',
            ElectricConsumption:WaterEnergyConsumptionTextField.electricConsumption,
            ConsumptionKWH: WaterEnergyConsumptionTextField.consumptionKWH,
            PowerFactor: WaterEnergyConsumptionTextField.powerFactor,
            PowerFactorIncentive: WaterEnergyConsumptionTextField.powerFactorIncentive,
            DemandPenalty:'',
            M_UserID: UserID,
            token: token,
            // Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(EnergyConsumptionPostDataAPI({ data }))
    }
    
    
    
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [LocationName, setLocationName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [PipeLine, setPipeLine] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    return (
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="water_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Energy Consumption</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">

                            <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Date :</b></label>
                                        <input type="date" className="form-control"
                                            id=""
                                            name='readingDate'
                                            value={WaterEnergyConsumptionTextField.readingDate}
                                            onChange={(e) => handleWaterEnergyConsumptionTextField(e)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6" >
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Prabhag No :</b></label>
                                        <input type="text" className="form-control"
                                            id="exampleInputEmail1"
                                            name='wardName'
                                            value={WaterEnergyConsumptionTextField.wardName}
                                            onChange={(e) => handleWaterEnergyConsumptionTextField(e)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6" >
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Location Name :</b></label>
                                        <input type="text" className="form-control"
                                            id="exampleInputEmail1"
                                            name='pumpStation'
                                            value={WaterEnergyConsumptionTextField.pumpStation}
                                            onChange={(e) => handleWaterEnergyConsumptionTextField(e)}
                                        />
                                    </div>
                                </div>

                                <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Electric Consumption :</b></label>
                                        <input type="text" className="form-control"
                                            id=""
                                            name='electricConsumption'
                                            value={WaterEnergyConsumptionTextField.electricConsumption}
                                            onChange={(e) => handleWaterEnergyConsumptionTextField(e)}
                                        />
                                    </div>
                                </div>

                                <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Consumption KWH :</b></label>
                                        <input type="text" className="form-control"
                                            id=""
                                            name='consumptionKWH'
                                            value={WaterEnergyConsumptionTextField.consumptionKWH}
                                            onChange={(e) => handleWaterEnergyConsumptionTextField(e)}
                                        />
                                    </div>
                                </div>

                                <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Power Factor :</b></label>
                                        <input type="text" className="form-control"
                                            id=""
                                            name='powerFactor'
                                            value={WaterEnergyConsumptionTextField.powerFactor}
                                            onChange={(e) => handleWaterEnergyConsumptionTextField(e)}
                                        />
                                    </div>
                                </div>

                                <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Power Factor Incentive :</b></label>
                                        <input type="text" className="form-control"
                                            id=""
                                            name='powerFactorIncentive'
                                            value={WaterEnergyConsumptionTextField.powerFactorIncentive}
                                            onChange={(e) => handleWaterEnergyConsumptionTextField(e)}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">
                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => (handlePost(),handleClear())}
                                    >{Flag === "Update" ? "Update" : "Save"} </button>
                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </Popup>
    )

}