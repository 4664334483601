
import React from 'react'
import WorkuploadCard from './WorkuploadCard'
import { useNavigate } from 'react-router-dom'

export default function WorkUploadCardManage(props) {
 
    const { Countdata,HeaderMonthID,HeaderFinancialYear , MonthValue, YearValue , YearLabel,MonthLabel,WardValue,WardLabel,ProjectTypeId,DashboardName} = props
    const workUploadNavigate = (name, screenFlag) => {

        if (name === "Agency") {
            navigate(`/roadworkupload?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Junior / Deputy Engineer") {
            navigate(`/roadworkupload?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }
    const navigate = useNavigate()

    return (
        <div className='row road_row p-2 mt-3' style={{height:'100%'}}>
            <div className="col-lg-6 col-md-12 " style={{height:'100%'}}>
                <WorkuploadCard
                    heading='Agency'
                    count={Countdata.AgencyTotalCount}
                    imgPath='./assets/img/public-relations.png'
                    cardName='wwork_img'
                    navLink='/roadworkupload'
                    workUploadNavigate={workUploadNavigate}
                />
            </div>

            <div className="col-lg-6 col-md-12" >
                <WorkuploadCard
                    headstyle='head_j_d_e'
                    heading='Junior / Deputy Engineer'
                    count={Countdata.JE_DETotalCount}
                    imgPath='./assets/img/manager.png'
                    cardName='wwork_img'
                    navLink='/roadworkupload'
                    countText='count_head'
                    workUploadNavigate={workUploadNavigate}
                />
            </div>

        </div>

    )
}






