
import { useEffect } from 'react'
import Select from 'react-select'
import { WardNameDDLAPI, WorkAssignByDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { useTranslation } from 'react-i18next'

export const WorkAssignByDDL = (props) => {

    const { t } = useTranslation()

    const { WorkAssignedBy, setWorkAssignedBy, CropNameDashboardData, PopUpField, Flag, rowData, item, IsDisabled } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { Flag, UserID, token, }
        dispatch(WorkAssignByDDLAPI({ data }))
    }, [])

    const { WorkAssignByData } = useSelector(state => state.WorkAssignByDDLData)

    useEffect(() => {
        handleWorkAssignByDDL()
    }, [WorkAssignByData])

    // console.log("dfsdfsd",Flag)

    const handleWorkAssignByDDL = () => {
        // console.log(DeptDDLDataa)
        if (WorkAssignByData && WorkAssignByData.table && WorkAssignByData.table.length > 0) {
            let list = WorkAssignByData.table.map((item, index) => ({
                value: item.m_EmployeeID,
                label: item.employeeName,
            }))

            setWorkAssignedBy({
                DDL: list,
                ID: 0,
                Label: "Select...",
                // ID: Flag === 'Update' ? rowData.m_WardID : Flag === 'ward' ? 0 : list[0].value,
                // Label: Flag === 'Update' ? rowData.wardName : Flag === 'ward' ? "Select..." : list[0].label,
            })
        }
        else {
            setWorkAssignedBy({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label for=""><b>{t('Dashboard.Work_Assigned_by_Officials.Assigned_By')}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className='ddl_width3'
                isDisabled={IsDisabled}
                isSearchable
                maxMenuHeight={150}
                value={{ value: WorkAssignedBy.ID, label: WorkAssignedBy.Label }}
                onChange={(e) => {
                    e ?
                        setWorkAssignedBy({ ...WorkAssignedBy, ID: e.value, Label: e.label })
                        :
                        setWorkAssignedBy({ ...WorkAssignedBy, ID: 0, Label: "Select..." })

                }}
                options={WorkAssignedBy.DDL}
            />
        </div>
    )
}