
import Select from 'react-select'
import React, { useEffect, useState } from 'react'

import PumpStationMasterPagePopup from './PumpStationMasterPagePopup'
import Header from '../../../../../../Components/Header/Header'
import Sidebar from '../../../../../../Components/Sidebar/Sidebar'
import DeletePopup from '../../../GardenPopup/DeletePopup'
import { WardNameDataDDL } from '../../../../../../Components/CommonDDL/WardNameDataDDL'
import { useSearchParams } from 'react-router-dom'
import { useAuthState } from '../../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { WaterSupplyPumpStationMasterDeleteAPI, WaterSupplyPumpStationTableDataAPI } from '../../../../../../Redux/WaterSupplyDashBoardSlice/WaterSupplyMasterSlice/WaterSupplyPumpStationSlice'
import { Pegination } from '../../../../../../Components/Pegination/Pegination'
import { Loading } from '../../../../../../Helper/Loading'
import { WaterSupplyPumpingStationDataDDL } from '../../../../../../Components/CommonDDL/WaterSupplyPumpingStationDDl'

export default function PumpingStationMasterPage() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [PumpStationMasterPopupShow, setPumpStationMasterPopupShow] = useState()
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')
    const [CurrentPage, setCurrentPage] = useState(0)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [rowNo, setrowNo] = useState(1)
    const [FromTop, setFromTop] = useState(1)
    const [ToTop, setToTop] = useState(10)

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WaterSupplyPumpingStation, setWaterSupplyPumpingStation] = useState({
        DDL: [],
        ID: '0',
        Label: "Select...",
    })

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setPumpStationMasterPopupShow(true)
    }

    const handleEditOnClick = (item) => {
        setPumpStationMasterPopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }
    const handleOnCloseClick = () => {
        setPumpStationMasterPopupShow(false)
    }

    useEffect(() => {
        setCurrentPage(0)
        setFromTop(1)
        setToTop(10)
    }, [WardName.ID, WaterSupplyPumpingStation.ID])

    const handlerClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setWaterSupplyPumpingStation({
            ...WaterSupplyPumpingStation,
            ID: 0,
            Label: 'Select...',
        })
        setWardName({
            ...WardName,
            ID: HWard,
            Label: WLable,
        })
    }

    useEffect(() => {
        const data = {
            M_WS_PumpStationID: WaterSupplyPumpingStation.ID,
            token: token,
            FromTop: FromTop,
            ToTop: ToTop,
            M_WardID: WardName.ID,
            PumpStation: '',
            M_UserID: 1,
            Flag: ApiFlag,
        }
        if (WardName.ID !== 0) {
        dispatch(WaterSupplyPumpStationTableDataAPI({ data }))
        }
    }, [ToTop, WardName.ID, IsPost, WaterSupplyPumpingStation.ID, FromTop])

    const { tableData, isLoading } = useSelector(state => state.WaterSupplyPumpStationTableData)

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    const handleDeleteApi = (item) => {
        const data = {
            M_WS_PumpStationID: item.m_wS_PumpStationID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost
        }
        dispatch(WaterSupplyPumpStationMasterDeleteAPI({ data }))
    }
    const { isDeleteLoading } = useSelector(state => state.WaterSupplyPumpStationMasterDelete)

    return (
        <div>
            {isLoading && <Loading />}
            {isDeleteLoading && <Loading />}
            <Header
                DashboardName={DashboardName}
                MLable={MLable}
                HMonth={HMonth}
                YLable={YLable}
                HYear={HYear}
                HWard={HWard}
                WLable={WLable}
                flag='Screen'
            />
            <div className="wrapper">
                <Sidebar ProjectTypeId={ProjectTypeId} />
                <div className="content">
                    {/* <!-- [ breadcrumb ] start --> */}
                    <div className="page-header ">
                        <div className="page-block">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    {/* <div className="page-header-title">
                                        <h5 className="ps-2 data_mob">MASTER */}

                                    {/* <span className="float-end fs-6">
                                                <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                        :
                                                        setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                }}
                                                options={SelectWard.DDL}
                                            />

                                            </span> */}
                                    {/* </h5>

                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- [ Main Content ] start --> */}
                    <div className='data_mob'>
                        <div className="row m-1 pb-4 master_border">
                            <div className='col-5'>
                                <h5 className="mt-2">Pumping Station</h5>
                            </div>
                            <div className='col-7 mar_add'>
                                <div className="float-end butt">
                                    <button type="button" className="btn btn-primary ml-1 float-end">Export</button>
                                    {/* <RoadMasterSectorPopup
                                            open={RoasMasterSectorPopupShow}
                                            handleIsPost={handleIsPost}
                                            Flag='Insert'
                                        /> */}
                                    {/* <div> */}
                                    <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                                        style={{ color: "white" }}
                                        onClick={handleAddOnClick}
                                    > Add</i>
                                </div>
                            </div>
                            <div className="row m-0 ">
                                <div className="col-12 py-0 rounded rounded-2 shadow"
                                    style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                    <div className="col-12">

                                        <div className="row py-1 rounded2">
                                            <div className="col-lg-3 col-md-6">
                                                {/* <label for=""><b>Ward Name :</b></label>
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    // className="ddl_width"
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: WardName.ID, label: WardName.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setWardName({ ...WardName, ID: e.value, Label: e.label })
                                                            :
                                                            setWardName({ ...WardName, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={WardName.DDL}
                                                /> */}

                                                <WardNameDataDDL
                                                    WardName={WardName}
                                                    setWardName={setWardName}
                                                    HWard={HWard}
                                                    WLable={WLable}

                                                // Flag='ward'
                                                />

                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <WaterSupplyPumpingStationDataDDL
                                                    WaterSupplyPumpingStation={WaterSupplyPumpingStation}
                                                    setWaterSupplyPumpingStation={setWaterSupplyPumpingStation}
                                                    WardName={WardName}
                                                    rowData={rowData}
                                                />

                                            </div>
                                            <div className="col-12 col-lg-2 mt-lg-4" >
                                                <button type="button" className="btn-clear btn btn-primary"
                                                    onClick={handlerClear} >
                                                    Clear</button>
                                            </div>

                                        </div>
                                        <div className="row mob_table table-responsive pt-1">

                                            <table id="totalordergenerated"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                className="table table-bordered">

                                                <thead>
                                                    <tr>
                                                        <th className="sr_no_col">Sr No.</th>
                                                        <th>Prabhag No</th>
                                                        <th>Pumping Station</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) =>
                                                            <tr>
                                                                <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                <td>{item.wardName ? item.wardName : '-'}</td>
                                                                <td>{item.pumpStation ? item.pumpStation : '-'}</td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <span>
                                                                        <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => handleEditOnClick(item)}
                                                                        >
                                                                        </i>
                                                                    </span>
                                                                    <span>
                                                                        <DeletePopup
                                                                            open={DeletePopupShow}
                                                                            handleDeleteApi={handleDeleteApi}
                                                                            item={item}
                                                                        />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ) :
                                                            <>No Found data</>
                                                    }

                                                </tbody>
                                            </table>

                                        </div>
                                        {tableData && tableData.table && tableData.table.length > 0 &&
                                            <Pegination
                                                PerPageCount={PerPageCount}
                                                TotalCount={tableData.table[0].totalCount}
                                                setFrom={setFromTop}
                                                setTo={setToTop}
                                                setrowNo={setrowNo}
                                                CurrentPage={CurrentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    PumpStationMasterPopupShow ?
                        <PumpStationMasterPagePopup
                            open={PumpStationMasterPopupShow}
                            // popupHeading="Pipe Line"
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                        />
                        : <></>
                }
            </div>
        </div>



    )
}