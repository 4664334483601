import { useState } from "react"
import Header from "../../../../Components/Header/Header"
import Sidebar from "../../../../Components/Sidebar/Sidebar"
import Select from 'react-select'



export default function GardenReopenComplete() {

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [WorkAssignedBy, setWorkAssignedBy] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })


    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select Ward",
    // })

    return (
        <div>
            <Header
                DashboardHeading="Garden Maintainance" />
            <div className="wrapper">
                <Sidebar />
                <div id="content">
                    <div className="page-header ">
                        <div className="page-block">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className="page-header-title">
                                        <h5 className="ps-2 ">REOPEN WORK PROGRESS
                                            <button type="button" className="btn btn-primary float-end mr-1">Export</button>

                                            {/* <span className="float-end fs-6">
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                            :
                                                            setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={SelectWard.DDL}
                                                />

                                            </span> */}
                                        </h5>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="data_mob">
                        <div className="row  m-1 py-2 pb-4 master_border">
                            <h5 className="ps-3 py-2 ">Reopen Completed</h5>
                            <div className="row m-0 ">
                                <div className="col-12 py-3  rounded rounded-2 shadow"
                                    style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                    <div className="col-12  ">

                                        <div className="row py-4 rounded2">


                                            <div className="col-md-12 col-lg-3">
                                                <div className="form-group">
                                                    <label className="d-block" htmlFor="NameofDepartment"><b>Financial Year :</b></label>

                                                    <Select
                                                        // isClearable
                                                        // isRtl={isRtl}
                                                        isSearchable
                                                        maxMenuHeight={150}
                                                        value={{ value: FinancialYear.ID, label: FinancialYear.Label }}
                                                        onChange={(e) => {
                                                            e ?
                                                                setFinancialYear({ ...FinancialYear, ID: e.value, Label: e.label })
                                                                :
                                                                setFinancialYear({ ...FinancialYear, ID: 0, Label: "Select..." })

                                                        }}
                                                        options={FinancialYear.DDL}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-3 ">
                                                <div className="form-group">
                                                    <label className="d-block" htmlFor="NameofDepartment"><b>Month :</b></label>

                                                    <Select
                                                        // isClearable
                                                        // isRtl={isRtl}
                                                        isSearchable
                                                        maxMenuHeight={150}
                                                        value={{ value: Month.ID, label: Month.Label }}
                                                        onChange={(e) => {
                                                            e ?
                                                                setMonth({ ...Month, ID: e.value, Label: e.label })
                                                                :
                                                                setMonth({ ...Month, ID: 0, Label: "Select..." })

                                                        }}
                                                        options={Month.DDL}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-12 col-lg-3 ">
                                                <div className="form-group">
                                                    <label className="d-block" htmlFor="NameofDepartment"><b>Prabhag No :</b></label>

                                                    <Select
                                                        // isClearable
                                                        // isRtl={isRtl}
                                                        isSearchable
                                                        maxMenuHeight={150}
                                                        value={{ value: WardName.ID, label: WardName.Label }}
                                                        onChange={(e) => {
                                                            e ?
                                                                setWardName({ ...WardName, ID: e.value, Label: e.label })
                                                                :
                                                                setWardName({ ...WardName, ID: 0, Label: "Select..." })

                                                        }}
                                                        options={WardName.DDL}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-3 " >
                                                <div className="form-group">
                                                    <label className="d-block" htmlFor="NameofDepartment"><b>Work Assigned By :</b></label>

                                                    <Select
                                                        // isClearable
                                                        // isRtl={isRtl}
                                                        isSearchable
                                                        maxMenuHeight={150}
                                                        value={{ value: WorkAssignedBy.ID, label: WorkAssignedBy.Label }}
                                                        onChange={(e) => {
                                                            e ?
                                                                setWorkAssignedBy({ ...WorkAssignedBy, ID: e.value, Label: e.label })
                                                                :
                                                                setWorkAssignedBy({ ...WorkAssignedBy, ID: 0, Label: "Select..." })

                                                        }}
                                                        options={WorkAssignedBy.DDL}
                                                    />

                                                </div>

                                            </div>
                                            <div className="col-12 col-lg-3 ">
                                                <div className="form-group">
                                                    <label className="d-block" htmlFor="NameofDepartment"><b>Asset Type :</b></label>

                                                    <Select
                                                        // isClearable
                                                        // isRtl={isRtl}
                                                        isSearchable
                                                        maxMenuHeight={150}
                                                        value={{ value: AssetType.ID, label: AssetType.Label }}
                                                        onChange={(e) => {
                                                            e ?
                                                                setAssetType({ ...AssetType, ID: e.value, Label: e.label })
                                                                :
                                                                setAssetType({ ...AssetType, ID: 0, Label: "Select..." })

                                                        }}
                                                        options={AssetType.DDL}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-12  col-lg-1">
                                                <button type="button" className="btn-clear btn btn-primary">Clear</button>
                                                {/* <button type="button"  className="btn-search btn btn-primary ml-5">Search</button> */}
                                            </div>
                                        </div>
                                        <div className="row table-responsive pt-3">

                                            <table id="totalordergenerated"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                className="table table-bordered">
                                                <thead>
                                                    <tr>

                                                        <th>Sr No.</th>
                                                        <th>Work Reopen Date</th>
                                                        <th>Reopen Completed Work Date</th>
                                                        <th>Asset Name</th>
                                                        {/* <th>Sector Name	 </th> */}
                                                        <th>Area</th>
                                                        <th>Work Name </th>
                                                        <th>Reopen Work  Photo</th>
                                                        <th>Reopen Completed Work Photo</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>1</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td style={{ textAlign: "center" }}><i className="fa-solid fa-eye"></i></td>
                                                        <td style={{ textAlign: "center" }}><i className="fa-solid fa-eye"></i></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>

                                                    <tr>
                                                        <td>3</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>

                                                    <tr>
                                                        <td>4</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>


                                                    <tr>
                                                        <td>5</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>

                                            </table>


                                        </div>
                                    </div>
                                    <div className="row mob_table table-responsive pt-3">

                                        <table id="totalordergenerated"
                                            cellPadding="0"
                                            cellSpacing="0"
                                            border="0"
                                            className="table table-bordered">
                                            <thead>
                                                <tr>

                                                    <th>Sr No.</th>
                                                    <th>Work Reopen Date</th>
                                                    <th>Reopen Completed Work Date</th>
                                                    <th>Asset Name</th>
                                                    {/* <th>Sector Name	 </th> */}
                                                    <th>Area</th>
                                                    <th>Work Name </th>
                                                    <th>Reopen Work  Photo</th>
                                                    <th>Reopen Completed Work Photo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td style={{ textAlign: "center" }}><i className="fa-solid fa-eye"></i></td>
                                                    <td style={{ textAlign: "center" }}><i className="fa-solid fa-eye"></i></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr>
                                                    <td>3</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr>
                                                    <td>4</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>


                                                <tr>
                                                    <td>5</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>

                                        </table>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}