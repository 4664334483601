
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useAuthState } from '../../../Helper/Context'
import { ZoneNameDDLAPI } from '../../../Redux/DDLSlice'

export const ZoneUserNameDDL = (props) => {
    const { ZoneNameDDLID, setZoneNameDDLID, Language,Flag } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token, Language: Language.Label }
        { Language.Label != '' && dispatch(ZoneNameDDLAPI({ data })) }
    }, [Language.Label])

    const { DDLData } = useSelector(state => state.ZoneNameDDLData)

    useEffect(() => {
        handleAssetDDL()
    }, [DDLData])

    const handleAssetDDL = () => {
        if (DDLData && DDLData.table && DDLData.table.length > 0) {
            let list = DDLData.table.map((item, index) => ({
                value: item.m_ZoneID,
                label: item.zoneName,
            }))


            setZoneNameDDLID({
                DDL: list,
                ID: 0,
                Label: 'Select...',
            })

            if (Flag == 'EmployeeAndContractorFlag') {
                setZoneNameDDLID({
                    DDL: list,
                    ID: list[0].value,
                    Label: list[0].label,
                })
            }
        }
        else {
            setZoneNameDDLID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>{t('AdminDashboard.Admin_Sidebar_Table_Heading.Zone_Name')}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width"
                isSearchable
                maxMenuHeight={150}
                value={{ value: ZoneNameDDLID.ID, label: ZoneNameDDLID.Label }}
                onChange={(e) => {
                    e ?
                        setZoneNameDDLID({ ...ZoneNameDDLID, ID: e.value, Label: e.label })
                        :
                        setZoneNameDDLID({ ...ZoneNameDDLID, ID: 0, Label: "Select..." })

                }}
                options={ZoneNameDDLID.DDL}
            />
        </div>
    )
}