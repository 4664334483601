
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";

// Table Data
export const SportWorkReopenByContractorTableDataAPI = createAsyncThunk("SportWorkReopenByContractorTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_AssetID,
        M_AssetTypeID,
        M_GD_WorkPeriodID,
        M_ContractorID,
        M_ZoneID,
        M_UsersID,
        Flag,
        ShowBy,
        From,
        To,
        token,
        Language
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_UploadReopenProgressWorkByContractorsDetails_Select?FromDate=${FromDate}&ToDate=${ToDate}&M_WardID=${M_WardID}&M_ZoneID=${M_ZoneID}&M_AssetID=${M_AssetID}&M_AssetTypeID=${M_AssetTypeID}&M_GD_WorkPeriodID=${M_GD_WorkPeriodID}&M_ContractorID=${M_ContractorID}&M_UsersID=${M_UsersID}&Flag=${Flag}&ShowBy=${ShowBy}&From=${From}&To=${To}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data.table
               
            } else {
                return result.data
            }
        })
})

const SportWorkReopenByContractorTableDataAPISlice = createSlice({
    name: "SportWorkReopenByContractorTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SportWorkReopenByContractorTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SportWorkReopenByContractorTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(SportWorkReopenByContractorTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const SportWorkReopenByContractorTableDataAPIReducer = SportWorkReopenByContractorTableDataAPISlice.reducer


