
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { ServiceTypeDDLAPI } from '../../Redux/DDLSlice'
import { AstricSign } from '../../Helper/AstricSign'

export const ServiceTypeDataDDL = (props) => {
    const { ServiceType, setServiceType, rowData, Flag, IsPost, IsDisabled } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token }
        dispatch(ServiceTypeDDLAPI({ data }))
    }, [IsPost])

    const { ServiceTypeData } = useSelector(state => state.ServiceTypeDDLData)


    useEffect(() => {
        handleServiceTypeDDL()
    }, [ServiceTypeData])

    const handleServiceTypeDDL = () => {
        // console.log(DeptDDLDataa)
        if (ServiceTypeData && ServiceTypeData.table && ServiceTypeData.table.length > 0) {
            let list = ServiceTypeData.table.map((item, index) => ({
                value: item.m_sW_ServiceTypeID,
                label: item.serviceTypeName,
            }))

            setServiceType({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_WorkTypeID : 0,
                Label: Flag === 'Update' ? rowData.workTypeName : "Select...",
            })
        }
        else {
            setServiceType({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofWorkType"><b>Service Type {Flag && <AstricSign />}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                isDisabled={Flag === 'Update' && IsDisabled ? true : false}
                className=""
                isSearchable
                maxMenuHeight={150}
                value={{ value: ServiceType.ID, label: ServiceType.Label }}
                onChange={(e) => {
                    e ?
                        setServiceType({ ...ServiceType, ID: e.value, Label: e.label })
                        :
                        setServiceType({ ...ServiceType, ID: 0, Label: "Select..." })
                }}
                options={ServiceType.DDL}
            />
        </div>
    )
}