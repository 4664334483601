import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'


export default function STPUnitTypeMasterDataPopup(props) {

    const { handleOnCloseClick, open, EditIcon, Flag, GardenMasterFlag, popupHeading, M_AssetTypeID, handleIsPost, apiFlag, rowData, HWard, WLable } = props

    // const userDetails = useAuthState();
    // const { UserID, token, } = userDetails
    // const dispatch = useDispatch()
    // const [IsOpen, setIsOpen] = useState(false)

    // const [WorkNameTextField, setWorkNameTextField] = useState({
    //     workName: Flag === 'Update' ? rowData.workName : '',
    //     benchMark: Flag === 'Update' ? rowData.benchMark : '',
    //     penalty_Description: Flag === 'Update' ? rowData.penalty_Description : '',
    //     penalty_Values: Flag === 'Update' ? rowData.penalty_Values : '',
    // });

    // const handleInputChange = (e) => {
    //     setWorkNameTextField({ ...WorkNameTextField, [e.target.name]: e.target.value })
    // }


    // useEffect(() => {
    //     // setLocationName((Flag === 'Update' ? item.locationName : ''))
    //     // setRowData(item)
    // }, [item, IsOpen])


    // const [WardName, setWardName] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select...",
    // })

    // useEffect(() => {
    //     setWorkNameTextField({
    //         workName: Flag === 'Update' ? rowData.workName : '',
    //         benchMark: Flag === 'Update' ? rowData.benchMark : '',
    //         penalty_Description: Flag === 'Update' ? rowData.penalty_Description : '',
    //         penalty_Values: Flag === 'Update' ? rowData.penalty_Values : '',
    //     })
    // }, [rowData, IsOpen])

    // console.log(rowData)

    return (

        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="location_mob_pop modal-dialog modal-dialog-scrollable modal-xl" style={{ alignItems: "center", justifyContent: 'center' }}>
                <div className="modal-content road_ward_modal">
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Unit Type Master</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>
                    <div className="modal-body">
                        <div className='inner-modal-body'>
                            <div className="row details-row">
                                {/* <div className="col-12 col-lg-6">
                                    <label for=""><b>Prabhag No :</b></label>
                                    <Select
                                        // isClearable
                                        // isRtl={isRtl}
                                        // className="ddl_width"
                                        isSearchable
                                        maxMenuHeight={150}
                                        value={{ value: WardName.ID, label: WardName.Label }}
                                        onChange={(e) => {
                                            e ?
                                                setWardName({ ...WardName, ID: e.value, Label: e.label })
                                                :
                                                setWardName({ ...WardName, ID: 0, Label: "Select..." })

                                        }}
                                        options={WardName.DDL}
                                    />

                                </div> */}
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Unit Type Master :</b></label>
                                        <input type="text"
                                            className="form-control"
                                            id="workName"
                                            aria-describedby="emailHelp"
                                            name='workName'
                                        />

                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <h6 className="float-end">
                                    <span className="submit_btn"
                                    >{Flag === 'Update' ? 'Update' : 'Submit'}</span>
                                    <span className="cancel_btn ms-2" onClick={handleOnCloseClick} >Cancel</span>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </Popup>
    )

}