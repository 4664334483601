import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const UserCreationTableDataAPI = createAsyncThunk("UserCreationTableData", async ({ data }) => {
    const {
        M_Table_UserID,
        M_RoleID,
        M_DesignationID,
        M_EmployeeID,
        M_UsersID,
        token,
        From,
        To,
        M_DepartmentID,
        M_ZoneID,
        M_WardID,
        M_ContractorID
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    // return fetch(`${BaseUrl}/Master/Get_M_User_Select?M_Table_UserID=${M_Table_UserID ? M_Table_UserID : '0'}&M_RoleID=${M_RoleID ? M_RoleID : '0'}&M_DesignationID=${M_DesignationID ? M_DesignationID : '0'}&M_EmployeeID=${M_EmployeeID ? M_EmployeeID : '0'}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
    return fetch(`${BaseUrl}/Master/Get_M_User_Select?M_Table_UserID=${M_Table_UserID ? M_Table_UserID : '0'}&M_RoleID=${M_RoleID ? M_RoleID : '0'}&M_DesignationID=${M_DesignationID ? M_DesignationID : '0'}&M_EmployeeID=${M_EmployeeID ? M_EmployeeID : '0'}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const UserCreationTableDataSlice = createSlice({
    name: "UserCreationTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserCreationTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserCreationTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(UserCreationTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const UserCreationTableDataReducer = UserCreationTableDataSlice.reducer


export const UserCreationExportTableDataAPI = createAsyncThunk("UserCreationExportTableData", async ({ data }) => {
    const {
        M_Table_UserID,
        M_RoleID,
        M_DesignationID,
        M_EmployeeID,
        M_UsersID,
        token,
        From,
        To,
        M_DepartmentID,
        M_ZoneID,
        M_WardID,
        M_ContractorID
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    // return fetch(`${BaseUrl}/Master/Get_M_User_Select?M_Table_UserID=${M_Table_UserID ? M_Table_UserID : '0'}&M_RoleID=${M_RoleID ? M_RoleID : '0'}&M_DesignationID=${M_DesignationID ? M_DesignationID : '0'}&M_EmployeeID=${M_EmployeeID ? M_EmployeeID : '0'}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
    return fetch(`${BaseUrl}/Master/Get_M_User_Select?M_Table_UserID=${M_Table_UserID ? M_Table_UserID : '0'}&M_RoleID=${M_RoleID ? M_RoleID : '0'}&M_DesignationID=${M_DesignationID ? M_DesignationID : '0'}&M_EmployeeID=${M_EmployeeID ? M_EmployeeID : '0'}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const UserCreationExportTableDataSlice = createSlice({
    name: "UserCreationExportTableData",
    initialState: {
        isExportLoading: false,
        ExportTableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserCreationExportTableDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(UserCreationExportTableDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExportTableData = action.payload;
        });
        builder.addCase(UserCreationExportTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExportTableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const UserCreationExportTableDataReducer = UserCreationExportTableDataSlice.reducer



export const UserCreationPostDataAPI = createAsyncThunk("UserCreationPostData", async ({ data }) => {
    const {
        M_Table_UserID,
        M_RoleID,
        M_DesignationID,
        M_EmployeeID,
        TableUserName,
        UserName,
        Password,
        IsActiveStatusID,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_Table_UserID", M_Table_UserID);
    formdata.append("M_RoleID", M_RoleID);
    formdata.append("M_DesignationID", M_DesignationID);
    formdata.append("M_EmployeeID", M_EmployeeID);
    formdata.append("TableUserName", TableUserName);
    formdata.append("UserName", UserName);
    formdata.append("Password", Password);
    formdata.append("IsActiveStatusID", IsActiveStatusID);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Post_M_User_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const UserCreationPostDataSlice = createSlice({
    name: "UserCreationPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserCreationPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserCreationPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(UserCreationPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const UserCreationPostDataReducer = UserCreationPostDataSlice.reducer

// -----------------Delete API ---------------

export const UserCreationDeleteAPI = createAsyncThunk("UserCreationDelete", async ({ data }) => {
    const {
        M_Table_UserID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_Table_UserID", M_Table_UserID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_User_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const UserCreationDeleteSlice = createSlice({
    name: "UserCreationDelete",
    initialState: {
        isDeleteLoading: false,
        UserCreationtableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserCreationDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(UserCreationDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.UserCreationtableData = action.payload;

        });
        builder.addCase(UserCreationDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.UserCreationtableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const UserCreationDeleteReducer = UserCreationDeleteSlice.reducer