
import { useEffect, useState } from 'react'
import Select from 'react-select'
import i18n from '../../Lang'

export const LanguageDataDDL = (props) => {
    const { Language, setLanguage } = props
   let LanguageChange =  localStorage.getItem('LanguageChange')

    const [LanguageID, setLanguageID] = useState(LanguageChange == 'English' ? true : false)

    const LanguageData = [
        {
            id: 1,
            lang: "Marathi",
        },
        {
            id: 2,
            lang: "English",
        },
    ]

    useEffect(() => {
        i18n.changeLanguage(Language.Label)
    }, [Language.Label])

    useEffect(() => {
        handleI18Next()
    }, [LanguageID])


    // useEffect(() => {
    //     localStorage.setItem("LanguageChange", Language.Label)
    // }, [Language.Label])


    const handleI18Next = (e) => {
        // const { options, selectedIndex } = e.target

        // let selectedOption = options[selectedIndex];
        // let value = selectedOption.getAttribute('value');
        // let name = selectedOption.getAttribute('name');

        setLanguage({ ...Language, ID: 0, Label: LanguageID ? 'English' : 'Marathi' })
        localStorage.setItem("LanguageChange", LanguageID ? 'English' : 'Marathi')
    }

    // useEffect(() => {
    //     handleLanguageDDL()
    // }, [])

    const handleLanguageDDL = () => {
        if (LanguageData && LanguageData.length > 0) {
            let list = LanguageData.map((item, index) => ({
                value: item.id,
                label: item.lang,
            }))

            setLanguage({
                DDL: list,
                ID: list[0].value,
                Label: list[0].label,
            })
        }
        else {
            setLanguage({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group mr-3">
            {/* <select
                value={Language.ID}
                onChange={(e) => (
                    handleI18Next(e)
                )}
            >
                {
                    Language.DDL.map((item) => (
                        <option value={item.value} name={item.label}>{item.label}</option>
                    ))
                }
            </select> */}
            <span className='cursor-pointer'>
                <i class="fa-solid fa-globe text-blue"></i>
                <span className='text-blue mx-2' onClick={() => setLanguageID(!LanguageID)} >{LanguageID ? 'मराठी' : 'English'}</span>
            </span>
            {/* <Select
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: 0,
                        borderRadius:0,
                        // borderBottomColor: 'black',
                        boxShadow: 'none',
                        backgroundColor: "transparent",
                        color: '#000000',
                        borderBottom: '1px solid black'
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        color: 'black'
                    }),
                    // singleValue: provided => ({
                    //     ...provided,
                    //     color: 'white'
                    // })
                }}
                components={{
                    IndicatorSeparator: () => null
                }}
                isSearchable
                maxMenuHeight={150}
                value={{ value: Language.ID, label: Language.Label }}
                onChange={(e) => {
                    e ?
                        handleI18Next(e)

                        :
                        setLanguage({ ...Language, ID: 0, Label: "Select..." })

                }}
                options={Language.DDL}
            /> */}

        </div>
    )
}