import React, { useEffect, useState } from 'react'
import Header from '../../../Components/Header/Header'
import Sidebar from '../../../Components/Sidebar/Sidebar'
import GardenMaintainanceCardManage from './GarderDashboardComponent/GardenMaintainanceCard/GardenMaintainanceCardManage'
import STPMasterCardManage from './STPDashboardComponent/STPMasterCard/STPMasterCardManage'
import { Pie } from 'react-chartjs-2'
import { Pieoptions } from '../../../Helper/Data'
import STPPreventiveCardManage from './STPDashboardComponent/STPPreventiveWorkCard/STPPreventiveCardManage'
import { useAuthState } from '../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Loading } from '../../../Helper/Loading'


export const STPDashboard = () => {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 1,
        Label: "Select Ward",
    })
    const [Countdata, setCountdata] = useState({})
    // const [FinancialYearID, setFinancialYear] = useState('')
    const [HeaderFinancialYear, setHeaderFinancialYear] = useState('')
    const [HeaderMonthID, setHeaderMonthID] = useState('')
    const [MonthID, setMonthID] = useState('')
    const [YearValue, setYearValue] = useState(null)
    const [YearLabel, setYearLabel] = useState(null)
    const [MonthValue, setMonthValue] = useState(null)
    const [MonthLabel, setMonthLabel] = useState(null)
    const [WardValue, setWardValue] = useState(null)
    const [WardLabel, setWardLabel] = useState(null)

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    const handleGetFinancialYearID = (FinancialYear, Month) => {
        setHeaderMonthID(Month)
        setHeaderFinancialYear(FinancialYear)
    }

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_WardID: WardValue,
            M_MonthID: MonthValue,
            M_UserID: UserID,
            Flag: "WEB",
            token: token,
            // handleGetCount: handleGetCount
        }
        if (YearValue && MonthValue && WardValue) {
            // dispatch(GardenDashMainCountDataAPI({ data }))
        }
    }, [YearValue, MonthValue, WardValue])

    const { GardenDashCountData, isLoading } = useSelector(state => state.GardenDashMainCountData)

    // const handleGetCount = (data) => {
    //     // console.log("data", data)
    //     let tempData = {}
    //     data.forEach((item) => {
    //         tempData[item.paramName] = item.totalCount
    //     })
    //     setCountdata(tempData)
    // }

    const PreventivePiedata = {
        labels: ["Pumping Operation", "Security", "Energy Audit", "House Keeping"],
        datasets: [
            {
                label: '# of Votes',
                data: [80, 40, 20, 70],
                backgroundColor: [
                    "#ffc33d",
                    "#eeb259",
                    "#df2f32",
                    "#ff8430",

                ],
                borderColor: [
                    // '#28c8c1',
                    // '#172b4d',
                    // '#993366',
                    // '#593e89',
                    // '#94408e',
                    // '#c74382',
                    // '#fa5d00',
                    // '#529ad2',
                    // '#ffc137',
                ],
                borderWidth: 1,
            },
        ],
    };


    return (
        <>
            {isLoading && <Loading />}  
            {/* <div className="main-content side_shrink position-relative border-radius-lg "></div> */}
            <div>
                <Header
                DashboardHeading="Water Supply Maintenance"
                setYearValue={setYearValue}
                setMonthValue={setMonthValue}
                FinancialYear={FinancialYear}
                setFinancialYear={setFinancialYear}
                Month={Month}
                setMonth={setMonth}
                setYearLabel={setYearLabel}
                setMonthLabel={setMonthLabel}
                setWardValue={setWardValue}
                setWardLabel={setWardLabel}
                DashboardName={DashboardName}
                />
                {/* <Sidebar active="dashboard" /> */}
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId} />
                    <div className="content1 content">

                        {/* <div id="page-content-wrapper"> */}
                        {/* <div className="pcoded-main-container main-row">
                        <div className="pcoded-content "> */}
                        {/* <!-- [ breadcrumb ] start --> */}
                        <div className="page-header">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12 col-12">
                                        <div className="page-header-title d-flex justify-content-between align-items-center">
                                            <h5 className=" col-6 col-lg-8 ps-2">DASHBOARD</h5>
                                            {/* <div className="col-md-6 col-lg-3 col-6">
                                                <WardNameDataDDL
                                                    WardName={WardName}
                                                    setWardName={setWardName}
                                                    isClearable={false}
                                                />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- [ Main Content ] start -->
            <!--  --> */}

                        <div className="mb-4 m-1 py-2 px-3 master_border">
                            <GardenMaintainanceCardManage />
                        </div>

                        <div className="m-1 py-2 pb-4 master_border">
                            {/*  */}
                            <STPMasterCardManage
                            Countdata={Countdata}
                            YearValue={YearValue}
                            YearLabel={YearLabel}
                            MonthValue={MonthValue}
                            MonthLabel={MonthLabel}
                            WardValue={WardValue}
                            WardLabel={WardLabel}
                            ProjectTypeId={ProjectTypeId}
                            DashboardName={DashboardName}
                            />
                        </div>

                        <div className="row mt-4 m-1 py-2 pb-0 master_border">
                            <div className="col-12">
                                <h5 className="ps-2 pb-2">Preventive Water</h5>
                            </div>
                            <STPPreventiveCardManage
                            Countdata={Countdata}
                            YearValue={YearValue}
                            YearLabel={YearLabel}
                            MonthValue={MonthValue}
                            MonthLabel={MonthLabel}
                            WardValue={WardValue}
                            WardLabel={WardLabel}
                            ProjectTypeId={ProjectTypeId}
                            DashboardName={DashboardName}
                            />

                            <div className="col-12 col-lg-4">
                                <div className="row">
                                    <div className="col-12">
                                        <div className=" px-3 mt-3 pb-2 bg-white rounded rounded-3">
                                            <div className="row d-flex justify-content-center align-items-center">
                                                <Pie
                                                    height={210}
                                                    // width={300}
                                                    options={Pieoptions}
                                                    data={PreventivePiedata}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='row mt-5'>
                                <div className="col-12 col-lg-6 mb-2 ">
                                    <STPPreventiveCard
                                        title="House Keeping"
                                        cardName='py-2 wwork_img cursor'
                                        imgPath='./assets/img/house-cleaning.png'
                                        count='100'
                                    // navLink='/preventivehousekeeping'
                                    // PreventiveWorkNavigate={PreventiveWorkNavigate}
                                    />

                                </div>

                                <div className="col-12 col-lg-6 mb-2">
                                    <STPPreventiveCard
                                        title="House Keeping"
                                        cardName='py-2 wwork_img cursor'
                                        imgPath='./assets/img/house-cleaning.png'
                                        count='100'
                                    // navLink='/preventivehousekeeping'
                                    // PreventiveWorkNavigate={PreventiveWorkNavigate}
                                    />

                                </div>
                            </div> */}
                            {/*  */}

                        </div>

                        {/* <div className="row mt-4 m-1 py-2 pb-2 master_border d-flex align-items-center">
                            <div className="col-12">
                                <h5 className="ps-2">Preventive Work</h5>
                            </div>
                        </div>
                        <div className="row mt-4 m-1 py-2 pb-2 master_border">
                            <div className="col-12 mb-3">
                                <h5 className="ps-2">Preventive Maintainance Work</h5>
                            </div>

                        </div>
                        <div className="row mt-4 m-1 py-2 pb-2 master_border">
                            <div className="col-12">
                                <h5 className="ps-2 py-2 ">Assigned Maintainance Work</h5>
                            </div>
                        </div> */}
                    </div>
                    {/* <!-- [ Main Content ] end --> */}
                </div>
            </div>
        </>


    )
}
