import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const WorkMasterTableDataAPI = createAsyncThunk("WorkMasterTableData", async ({ data }) => {
    const {
        M_WorkID,
        M_WorkTypeID,
        M_WorkSubTypeID,
        M_WorkName,
        M_UsersID,
        token,
        From,
        To,
        Language
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_Work_Select?M_WorkID=${M_WorkID ? M_WorkID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_WorkSubTypeID=${M_WorkSubTypeID ? M_WorkSubTypeID : '0'}&M_WorkName=${M_WorkName ? M_WorkName : ''}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WorkMasterTableDataSlice = createSlice({
    name: "DepartmentTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkMasterTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkMasterTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WorkMasterTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WorkMasterTableDataReducer = WorkMasterTableDataSlice.reducer


export const WorkMasterExportTableDataAPI = createAsyncThunk("WorkMasterExportTableData", async ({ data }) => {
    const {
        M_WorkID,
        M_WorkTypeID,
        M_WorkSubTypeID,
        M_WorkName,
        M_UsersID,
        token,
        From,
        To,
        Language
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_Work_Select?M_WorkID=${M_WorkID ? M_WorkID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_WorkSubTypeID=${M_WorkSubTypeID ? M_WorkSubTypeID : '0'}&M_WorkName=${M_WorkName ? M_WorkName : ''}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WorkMasterExportTableDataSlice = createSlice({
    name: "WorkMasterExportTableDataSlice",
    initialState: {
        isExportLoading: false,
        ExportTableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkMasterExportTableDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(WorkMasterExportTableDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExportTableData = action.payload;
        });
        builder.addCase(WorkMasterExportTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExportTableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WorkMasterExportTableDataReducer = WorkMasterExportTableDataSlice.reducer


export const WorkMasterPostDataAPI = createAsyncThunk("WorkMasterPostData", async ({ data }) => {
    const {
        M_WorkId,
        M_WorkTypeID,
        M_WorkSubTypeID,
        Workname,
        PenaltyPeriod,
        BenchMark,
        BenchMarkinDays,
        PenaltyPeriodinhours,
        PenaltyAmount,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WorkId", M_WorkId);
    formdata.append("M_WorkTypeID", M_WorkTypeID);
    formdata.append("M_WorkSubTypeID", M_WorkSubTypeID);
    formdata.append("Workname", Workname);
    formdata.append("PenaltyPeriod", PenaltyPeriod);
    formdata.append("BenchMark", BenchMark);
    formdata.append("BenchMarkinDays", BenchMarkinDays);
    formdata.append("PenaltyPeriodinhours", PenaltyPeriodinhours);
    formdata.append("PenaltyAmount", PenaltyAmount);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Post_M_Work_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const WorkMasterPostDataSlice = createSlice({
    name: "WorkMasterPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkMasterPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkMasterPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WorkMasterPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const WorkMasterPostDataReducer = WorkMasterPostDataSlice.reducer

// -----------------Delete API ---------------

export const WorkMasterDeleteAPI = createAsyncThunk("WorkMasterDelete", async ({ data }) => {
    const {
        M_WorkId,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    // formdata.append("M_CropTypeID", rowData?.m_CropTypeID);
    var formdata = new FormData();
    formdata.append("M_WorkId", M_WorkId);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_Work_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const WorkMasterDeleteSlice = createSlice({
    name: "WorkMasterDelete",
    initialState: {
        isDeleteLoading: false,
        WorkMasterDeleteData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(WorkMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.WorkMasterDeleteData = action.payload;

        });
        builder.addCase(WorkMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.WorkMasterDeleteData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const WorkMasterDeleteReducer = WorkMasterDeleteSlice.reducer