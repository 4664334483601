

import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from 'moment'
import { useState } from "react"
import { useTranslation } from "react-i18next"

export const UserCreationExportData = (props) => {
    const { ExcelData, name, WardName, FromDate, ToDate } = props

    const totalCount = ExcelData && ExcelData.table && ExcelData.table.reduce((total, item) => {
        return total + +(item.totalPenalty)
    }, 0)

    const { t } = useTranslation()


    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-ExportPreventiveWorkReport"
                className="download-table-xls-button btn btn btn-primary float-end mr-1 mb-1 ml-1"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black', }}
            />


            <table id="table-to-ExportPreventiveWorkReport" style={{ display: 'none', width: '100%' }}>

                <h5><span>&emsp;</span></h5>


                <h4 style={{ textAlign: 'center', fontSize: 16 }}>PCMC-Garden</h4>
                <h4 style={{ textAlign: 'center', fontSize: 16 }}>Report Name : {t('AdminDashboard.Admin_Sidebar_Name.User_Creation')}</h4>

                <tr><td></td></tr>

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#495057', color: "#fff", }}>

                        <th className="sr_no_col">Sr No.</th>
                        <th>Role</th>
                        {/* <th>Department Name</th> */}
                        <th>Designation</th>
                        <th>Employee Name</th>
                        <th>Table User Name</th>
                        <th>User Name</th>
                        <th>Password</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item, i) => (
                            <tr style={{ textAlign: "center" }}>
                                <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                <td>{item.m_RoleName ? item.m_RoleName : '-'}</td>
                                {/* <td></td> */}
                                <td>{item.designationName ? item.designationName : '-'}</td>
                                <td>{item.employeeName ? item.employeeName : '-'}</td>
                                <td>{item.tableUserName ? item.tableUserName : '-'}</td>
                                <td>{item.userName ? item.userName : '-'}</td>
                                <td>{item.password ? item.password : '-'}</td>
                                <td>{item.statusName ? item.statusName : '-'}</td>
                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}