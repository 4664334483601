import React from 'react'
import STPPreventiveCard from './STPPreventiveCard'
import { useNavigate } from 'react-router-dom'


export default function STPPreventiveCardManage(props) {

    const { Countdata, HeaderMonthID, HeaderFinancialYear, MonthValue, YearValue, YearLabel, MonthLabel, WardValue, WardLabel, ProjectTypeId, DashboardName } = props

    const STPPreventiveCardNavigate = (name, screenFlag) => {

        if (name === "Sewerage Quality") {
            navigate(`/stppreventiveseweragequalitydata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Machinery Operation") {
            navigate(`/stppreventivemachineryoperationdata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Energy Consumption") {
            navigate(`/stppreventiveenergyconsumptiondata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Machinery Checklist") {
            navigate(`/stppreventivemachinerychecklistdata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }else if (name === "MPCB Test") {
            navigate(`/stppreventivempcbtestdata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }else if (name === "House Keeping") {
            navigate(`/stppreventivehousekeepingdata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }else if (name === "Energy Audit") {
            navigate(`/stppreventiveenergyauditdata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }else if (name === "Breakdown Maintenance") {
            navigate(`/stpbreakdownmaintainancedata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }else if (name === "Preventive Maintenance") {
            navigate(`/stppreventivemaintainancedata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }
    const navigate = useNavigate()

    return (
        <div className="col-12 col-lg-8">
            <div className="row">

                <div className="col-12 col-lg-6 mb-2">
                    <STPPreventiveCard
                        title="Sewerage Quality"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/high-quality.png'
                        count='100'
                    // navLink='/preventivepumping'
                    STPPreventiveCardNavigate={STPPreventiveCardNavigate}
                    />
                </div>

                <div className="col-12 col-lg-6 mb-2">
                    <STPPreventiveCard
                        title="Machinery Operation"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/engineering.png'
                        count='100'
                        // navLink='/preventivecustomer'
                        STPPreventiveCardNavigate={STPPreventiveCardNavigate}
                    />

                </div>
                <div className="col-12 col-lg-6 mb-2">
                    <STPPreventiveCard
                        title="Energy Consumption"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/energy-consumption.png'
                        count='100'
                    // navLink='/preventivesecurity'
                    STPPreventiveCardNavigate={STPPreventiveCardNavigate}
                    />

                </div>

                <div className="col-12 col-lg-6 mb-2">
                    <STPPreventiveCard
                        title="Machinery Checklist"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/testing.png'
                        count='100'
                    // navLink='/preventiveenergyaudit'
                    STPPreventiveCardNavigate={STPPreventiveCardNavigate}
                    />

                </div>

                <div className="col-12 col-lg-6 mb-2">
                    <STPPreventiveCard
                        title="MPCB Test"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/test.png'
                        count='100'
                    // navLink='/preventivehousekeeping'
                    STPPreventiveCardNavigate={STPPreventiveCardNavigate}
                    />

                </div>

                <div className="col-12 col-lg-6 mb-2">
                    <STPPreventiveCard
                        title="House Keeping"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/house-cleaning.png'
                        count='100'
                    // navLink='/preventivehousekeeping'
                    STPPreventiveCardNavigate={STPPreventiveCardNavigate}
                    />

                </div>

                <div className="col-12 col-lg-6 mb-2">
                    <STPPreventiveCard
                        title="Energy Audit"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/audit.png'
                        count='100'
                    // navLink='/preventivehousekeeping'
                    STPPreventiveCardNavigate={STPPreventiveCardNavigate}
                    />

                </div>

            </div>

            <div className='row mt-5'>
                <div className="col-12">
                    <h5 className="ps-2 pb-2">Maintainance</h5>
                </div>
                <div className="col-12 col-lg-6 mb-2 ">
                    <STPPreventiveCard
                        title="Breakdown Maintenance"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/tool-box.png'
                        count='100'
                    // navLink='/preventivehousekeeping'
                    STPPreventiveCardNavigate={STPPreventiveCardNavigate}
                    />

                </div>

                <div className="col-12 col-lg-6 mb-2">
                    <STPPreventiveCard
                        title="Preventive Maintenance"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/medical-insurance.png'
                        count='100'
                    // navLink='/preventivehousekeeping'
                    STPPreventiveCardNavigate={STPPreventiveCardNavigate}
                    />

                </div>
            </div>
        </div>

    )

}

