import React from 'react'
import { useNavigate } from 'react-router-dom';
import { loginUser, logout, useAuthDispatch, useAuthState } from '../../Helper/Context';
import { useState } from 'react';
import { AppHeaderName, LogoHeader, MainImageSection, MainLogoWithName } from '../../Helper/Logo/Logo';
import { BaseUrl } from '../../Helper/BaseUrl';

export default function PcmcLoginPage() {

    const navigate = useNavigate();
    const dispatch = useAuthDispatch()
    const userDetails = useAuthState()
    const { RoleID, UserID } = userDetails

    const [userName, setuserName] = useState("")
    const [password, setpassword] = useState("")
    const [Validationerror, setValidationerror] = useState("")
    const [loading, setloading] = useState(false)

    React.useEffect(() => {
        logout(dispatch)
    }, [])

    const handleLogin = async (e) => {
        e.preventDefault()
        if (userName !== '' && password !== '') {
            try {
                let response = await loginUser(dispatch, { userName, password, setloading, handleRollIdWiseDashboard });
                if (!response) return;
                // navigate('/dashboard');

            } catch (error) {
                console.log(error);
            }
            setValidationerror("")
        } else {
            setValidationerror("Error")
        }

    }

    const handleRollIdWiseDashboard = (data) => {
        if (data.RoleID == '2' || data.RoleID == '3' || data.RoleID == '16' || data.RoleID == '20'
            || data.RoleID == '22' || data.RoleID == '21' || data.RoleID == '6' || data.RoleID == '15') {
            navigate('/officialdashboard?DashboardName=NewDashboard')
        }
        else if (data.RoleID == '1') {
            navigate('/garden?ProjectTypeId=5&DashboardName=Garden');
        }
        // else if(data.RoleID == '3'){
        //     navigate('/garden?DashboardName=Garden');
        // }
        // if (data.M_ProjectTypeID == '0') {
        //     navigate('/garden?ProjectTypeId=5&DashboardName=Garden');
        // }
        // if (data.M_ProjectTypeID == '1') {
        //     // navigate('/garden');
        // } else if (data.M_ProjectTypeID == '2') {
        //     navigate('/sewerage?ProjectTypeId=2&DashboardName=Sewerage');
        // } else if (data.M_ProjectTypeID == '3') {
        //     // navigate('/garden');
        // } else if (data.M_ProjectTypeID == '4') {
        //     navigate('/dashboard?ProjectTypeId=4&DashboardName=Road');
        // }
        else if (data.M_ProjectTypeID == '5') {
            navigate('/garden?ProjectTypeId=5&DashboardName=Garden');
        }
    }

    const showPass = () => {
        var x = document.getElementById("pass");
        var y = document.getElementById("eye");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
        if (y.className === "fa fa-eye") {
            y.className = "fa fa-eye-slash";
        } else {
            y.className = "fa fa-eye";
        }
    }
    return (
        <>
            <div className="pcmc_login h-100">
                {/* <div className="row  pcmc_blue_bg ">
                    <div className='col-11 d-flex align-item-center pt-1  mx-auto'>

                    <div className='py-1 py-2'>



                        <span className=''>
                            <img src="../assets/img/PCMC_Logos-01.png" alt="" srcset="" className="" style={{ width: "72px" }} />

                        </span>
                        

                        <span className='fw-bold text-center header-text  fs-3 text-white  ps-5'>
                            उद्यान व वृक्षसंवर्धन कामांचे सनियंत्रण संगणक प्रणाली
                        </span>

                        <span className=' text-center header-text text-white ps-3'>
                            वेब व मोबाईल आधारित
                        </span>

                        </div>

                    </div>


                   

                </div> */}

                <div className="row py-2 pcmc_login  pcmc_blue_bg d-flex align-items-center">
                    <div className="col-2 col-md-1">
                        <LogoHeader />
                    </div>

                    <div className="col-8 col-md-10 pc_none ps-3 pe-5">
                        <h2 className="text-white pt-5 py-2 text-center heading_text text-font fw-bold ps-5" style={{ lineHeight: "20px" }} >
                            उद्यान व
                            वृक्षसंवर्धन कामांचे
                            सनियंत्रण संगणक प्रणाली
                            <br />
                            <span className="">   वेब व मोबाईल आधारित</span>
                        </h2>


                    </div>

                    <div className="col-10 col-md-9 mobile_none">
                        <AppHeaderName />
                    </div>
                    {
                        BaseUrl != "https://apiamravatimc.cdat.work" &&
                        <div className="col-2 col-md-2 mobile_logo_vasu">
                            <div className=" text-end pe-lg-5">
                                <img src="../assets/img/mazi_vasundhara_change.png" alt="" srcset="" className="pe-2 " style={{ width: "68px" }} />
                                <p className='text-white fw-bold ps-lg-5 mazi_wasu'>माझी वसुंधरा</p>
                            </div>
                        </div>

                    }

                </div>

                <div className="row mx-auto height-76" >
                    <MainImageSection />
                    <div className="col-12 col-md-4 d-flex justify-content-center border-0 align-items-center pcmc_shadow mx-auto pcmc_bg_blue" >
                        <div className="col-12">
                            <MainLogoWithName />
                            {/* <form className="mx-auto form_auto"> */}
                            <form className="mx-3 mx-lg-5  px-3 mb-3 login_form">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1"> <i className="fa-solid fa-user pcmc_blue"></i> </span>
                                    <input type="text"
                                        className={`form-control pmc_Userpsw ${Validationerror === "" ? "login_input" : "login_input_error"}`}
                                        placeholder="Username"
                                        id="username"
                                        autoComplete="off"
                                        value={userName}
                                        onChange={(e) => setuserName(e.target.value)}
                                    />
                                </div>



                                {/* <div className="input-group mb-2">
                                    <span className="input-group-text" id="basic-addon1"> <i className="fa-solid fa-lock pcmc_blue"></i> </span>
                                    <input type="password"
                                        name="logpass"
                                        className={`form-control pmc_Userpsw ${Validationerror === "" ? "login_input" : "login_input_error"}`}
                                        placeholder="Password"
                                        id="pass"
                                        autoComplete="new-password"
                                        value={password}
                                        onChange={(e) => setpassword(e.target.value)}
                                    />
                                    <span
                                        className="input-group-password cursor-pointer"
                                        id="addon-wrapping"
                                        onClick={showPass}
                                    >
                                        <i id='eye' className="fa-solid fa-eye"></i>
                                    </span>
                                </div> */}


                                <div class="input-group mb-3 rounded">
                                    <span class="input-group-text"><i className="fa-solid fa-lock pcmc_blue"></i></span>
                                    <input
                                        type="password"
                                        className={`form-control ${Validationerror === "" ? "login_input" : "login_input_error"}`}
                                        placeholder="Password"
                                        id="pass"
                                        autoComplete="new-password"
                                        value={password}
                                        onChange={(e) => setpassword(e.target.value)}
                                    />
                                    <span class="input-group-text "
                                        onClick={showPass}>
                                        <i id='eye' className="fa-solid fa-eye"></i>
                                    </span>
                                </div>


                                {/* <a type='button' className="border d-block text-center rounded text-decoration-none py-2 bg-danger text-white"
                                    style={{ padding: "10px" }}
                                    onClick={handleLogin}
                                // disabled={loading}
                                >Submit</a> */}

                                <div className="pt-1">
                                    <button className="login_buttton rounded text-center text-decoration-none py-2 text-white fw-bolder mainColor"
                                        onClick={handleLogin}
                                        disabled={loading}

                                    >
                                        {loading && (
                                            <i
                                                className="fa fa-spinner fa-spin"
                                                style={{ marginRight: "5px" }}
                                            />
                                        )}
                                        {/* {loading && <span>Login ... </span>} */}
                                        {!loading && <span>Login</span>}
                                    </button>
                                </div>

                            </form>

                            <div className="col-12 text-center pt-lg-0">
                                <a className="bg-danger fw-bold">
                                    <h5 className=''><i className="fa-solid fa-circle-info text-danger"></i> Help Desk</h5>
                                </a>
                                <a className="text-black">
                                    <h5>Customer Support</h5>
                                </a>
                                {/* <span className='d-flex' style={{whiteSpace:'nowrap'}}> */}
                                <h5 className="text-black"><i className="fa-solid fa-phone text-danger pe-1"></i> Contact No: <a
                                    href="tel:91726 50917" /><span className="pcmc_blue">+91 91726 50917</span> </h5>
                                <h5 className="text-black"><i className="fa-solid fa-envelope text-danger pe-1"></i> Email ID: <a
                                    href="mailto:info@cdat.co.in" /><span className="pcmc_blue">info@cdat.co.in</span> </h5>
                                {/* </span> */}
                                <h5 className="text-black cursor-pointer"><img src="../assets/img/android.png" alt="" srcset="" className="text-danger me-1" style={{ width: '20px' }} />Download Android App</h5>

                                {
                                    BaseUrl != "https://apiamravatimc.cdat.work" &&
                                    <h5 className="text-black cursor-pointer"><img src="../assets/img/apple.png" alt="" srcset="" className="text-danger me-1" style={{ width: '20px', marginTop: '-0.2em' }} />Download IOS App</h5>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row pcmc_blue_bgs mt-1 mt-lg-0">
                    <div className="col-12 col-md-6 text-center mx-auto ">
                        <span className="text-center text-white" style={{ fontFamily: 'Poppins, sans-serif' }}>
                            &copy;
                            <script type="text/javascript">document.write(new Date().getFullYear());</script> Copyright <span className="silver">PCMC</span> Pune.
                            All
                            Rights Reserved.
                        </span>
                    </div>

                </div>
            </div>
        </>
    )
}
