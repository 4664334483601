import React from 'react'
import { useNavigate } from 'react-router-dom'
import WaterAssignMainWorkCard from './WaterAssignMainWorkCard'


export default function WaterAssignMainWorkCardManage() {
    const navigate = useNavigate()

    const WaterAssignMainWorkCardNavigate1=(name,screenFlag,assignName)=>{
            if (name === "Operation Pending") {
                navigate(`/commonforallassignworkmaintainancepagedata?name=${name}&ApiFlag=OperationPending`)
            }else if (name === "Operation Completed") {
                navigate(`/commonforallassignworkmaintainancepagedata?name=${name}&ApiFlag=OperationCompleted`)
            }
            else if (name === "Electric Pending") {
                navigate(`/commonforallassignworkmaintainancepagedata?name=${name}&ApiFlag=ElectricPending`)
            }
            else if (name === "Electric Completed") {
                navigate(`/commonforallassignworkmaintainancepagedata?name=${name}&ApiFlag=ElectricCompleted`)
            }
            else if (name === "Mechanical Pending") {
                navigate(`/commonforallassignworkmaintainancepagedata?name=${name}&ApiFlag=MechanicalPending`)
            }
            else if (name === "Mechanical Completed") {
                navigate(`/commonforallassignworkmaintainancepagedata?name=${name}&ApiFlag=MechanicalCompleted`)
            }
            else if (name === "Civil Pending") {
                navigate(`/commonforallassignworkmaintainancepagedata?name=${name}&ApiFlag=CivilPending`)
            }
            else if (name === "Civil Completed") {
                navigate(`/commonforallassignworkmaintainancepagedata?name=${name}&ApiFlag=CivilCompleted`)
            }
        }

    return (
        <div className="row pb-3 ml-lg-0 ml-0 mr-0">
            <div className="col-12 col-lg-3 mb-2">
                <WaterAssignMainWorkCard
                    title="Operation Works"
                    title1="Operation Pending"
                    cardName='py-1 wwork_img cursor'
                    imgPath='./assets/img/completed1.png'
                    imgPathsecond='./assets/img/pending.png'
                    count='100'
                    WaterAssignMainWorkCardNavigate1={WaterAssignMainWorkCardNavigate1}
                    cardHeading='Operation Works'
                    title2="Operation Completed"
                    searchNametitle2="OperationCompleted"
                />

            </div>

            <div className="col-12 col-lg-3 mb-2">
                <WaterAssignMainWorkCard
                    title="Electrical Work Maintenance"
                    title1="Electric Pending"
                    cardName='py-1 wwork_img cursor'
                    imgPath='./assets/img/completed1.png'
                    imgPathsecond='./assets/img/pending.png'
                    count='2'
                    // navLink='/preventivecommondata'
                    WaterAssignMainWorkCardNavigate1={WaterAssignMainWorkCardNavigate1}
                    cardHeading='Electrical Work Maintenance'
                    title2="Electric Completed"
                    searchNametitle2="ElectricCompleted"
                />
            </div>

            <div className="col-12 col-lg-3 mb-2">
                <WaterAssignMainWorkCard
                    title="Mechanical Work Maintainance"
                    title1="Mechanical Pending"
                    cardName='py-1 wwork_img cursor'
                    imgPath='./assets/img/completed1.png'
                    imgPathsecond='./assets/img/pending.png'
                    count='98'
                    // navLink='/preventivecommondata'
                    WaterAssignMainWorkCardNavigate1={WaterAssignMainWorkCardNavigate1}
                    cardHeading='Mechanical Work Maintainance'
                    title2="Mechanical Completed"
                    searchNametitle2="CivilCompleted"
                />
            </div>

            <div className="col-12 col-lg-3 mb-2">
                <WaterAssignMainWorkCard
                    title="Civil Work Maintenance"
                    title1="Civil Pending"
                    cardName='py-1 wwork_img cursor'
                    imgPath='./assets/img/completed1.png'
                    imgPathsecond='./assets/img/pending.png'
                    count='98'
                    // navLink='/preventivecommondata'
                    WaterAssignMainWorkCardNavigate1={WaterAssignMainWorkCardNavigate1}
                    cardHeading='Civil Work Maintenance'
                    title2="Civil Completed"
                    searchNametitle2="CivilCompleted"
                />
            </div>

        </div>

    )

}
