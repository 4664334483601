import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useAuthState } from '../../../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { PHPostDataAPI } from '../../../../../../Redux/WaterSupplyDashBoardSlice/PreventiveWaterQualitySlice/PHSlice';

export default function WaterQualityPhPagePopup(props) {

    const { handleOnCloseClick, open, Flag, handleIsPost, item, EditIcon, apiFlag, rowData, } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()
    const [PHField, setPHField] = useState({
        wardName: Flag === 'Update' ? rowData.wardName : '',
        locationDetails: Flag === 'Update' ? rowData.locationDetails : '',
        ph: Flag === 'Update' ? rowData.ph : ''
    })

    const handlePHField = (e) => {
        setPHField({ ...PHField, [e.target.name]: e.target.value })
    }

    const handlePost = () => {
        const data = {     
            T_WS_WaterQualityPHID: Flag === 'Update' ? rowData.t_wS_WaterQualityPHID : '0',
            PH: PHField.ph,         
            M_UserID: UserID,
            token: token,        
            handleIsPost: handleIsPost,
            handleOnCloseClick:handleOnCloseClick
        }
        dispatch(PHPostDataAPI({ data }))

    }
    
    return (
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="water_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Water Quality-PH</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">

                                <div className="col-12 col-md-6 col-lg-6" >                                
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Prabhag No :</b></label>
                                        <input type="text" className="form-control"
                                            name='wardName'
                                            aria-describedby="emailHelp"
                                            disabled
                                            value={PHField.wardName}
                                            onChange={(e) => handlePHField(e)}
                                        />
                                       
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6" >
                                    <div className="form-group">
                                    <label className="d-block" htmlFor="NameofDepartment"><b>Location Name :</b></label>
                                        <input type="text" className="form-control"
                                            name='locationDetails'
                                            aria-describedby="emailHelp"
                                            disabled
                                            value={PHField.locationDetails}
                                            onChange={(e) => handlePHField(e)}
                                        />
                                    </div>

                                </div>

                                <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>PH :</b></label>
                                        <input type="text" className="form-control"                                            
                                            name='ph'
                                            aria-describedby="emailHelp"
                                            value={PHField.ph}
                                            onChange={(e) => handlePHField(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">
                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost() }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>
                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </Popup>
    )

}