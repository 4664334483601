
import { useEffect } from 'react'
import Select from 'react-select'
import { AssetNameDDLAPI, WardWiseAssetNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { useTranslation } from 'react-i18next'

export const WardWiseAssetNameDataDDL = (props) => {
    const { WardWiseAssetName, M_RoleID, setWardWiseAssetName,Flag2, AssetType, M_GD_WorkPeriodID, setAssetType, M_AssetTypeID, WardName, rowData, Flag, Language, Flag1, M_WorkTypeID, M_ZoneID } = props
    const { t } = useTranslation()
    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_WardID: WardName.ID,
            M_AssetTypeID: Flag2 == 'Play' ? 1 : AssetType.ID,
            M_WorkTypeID: M_WorkTypeID,
            M_ZoneID: M_ZoneID.ID,
            M_RoleID: M_RoleID,
            M_GD_WorkPeriodID: M_GD_WorkPeriodID,
            Flag: Flag,
            M_UserID: UserID,
            token: token,
            Language: Language.Label
        }
        // if (AssetType.ID != 0) {
        { Language.Label != '' && M_WorkTypeID && AssetType.ID != '0' && M_ZoneID.ID && WardName.ID && dispatch(WardWiseAssetNameDDLAPI({ data })) }
        // }
    }, [M_ZoneID.ID, AssetType.ID, M_WorkTypeID, WardName.ID, Language.Label])

    const { WardWiseAssetNameDDL } = useSelector(state => state.WardWiseAssetNameDDLData)

    useEffect(() => {
        handleWardWiseAssetNameDDL()
    }, [WardWiseAssetNameDDL])

    const handleWardWiseAssetNameDDL = () => {
        // console.log(DeptDDLDataa)
        if (WardWiseAssetNameDDL && WardWiseAssetNameDDL.table && WardWiseAssetNameDDL.table.length > 0) {
            let list = WardWiseAssetNameDDL.table.map((item, index) => ({
                value: item.m_AssetID,
                label: item.assetName,
            }))

            setWardWiseAssetName({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_AssetID : 0,
                Label: Flag === 'Update' ? rowData.assetName : "Select...",
            })
        }
        else {
            setWardWiseAssetName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofEmployee"><b>{(Flag1 == 'Garden Name' ? 'Garden Name' : t('Dashboard.Pcmc_Assets.Asset_Name'))}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width3"
                isSearchable
                maxMenuHeight={150}
                value={{ value: WardWiseAssetName.ID, label: WardWiseAssetName.Label }}
                onChange={(e) => {
                    e ?
                        setWardWiseAssetName({ ...WardWiseAssetName, ID: e.value, Label: e.label })
                        :
                        setWardWiseAssetName({ ...WardWiseAssetName, ID: 0, Label: "Select..." })

                }}
                options={WardWiseAssetName.DDL}
            />
        </div>
    )
}