import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { RoleNameDataDDL } from '../../../../Components/CommonDDL/RoleNameDataDDL';
import { DesignationDDL } from '../../../../Components/CommonDDL/DesignationDDL';
import { EmployeeDataDDL } from '../../../../Components/CommonDDL/EmployeeDataDDL';
import { StatusNameDataDDL } from '../../../../Components/CommonDDL/StatusNameDataDDL';
import { UserCreationPostDataAPI } from '../../../../Redux/SidebarMasterSlice/UserCreationSlice';
import { AstricSign } from '../../../../Helper/AstricSign';
import { NewDepartmentDDL } from '../../../../Components/CommonDDL/NewDepartmentDDL';
import { NewRoleDataDDL } from '../../../../Components/CommonDDL/NewRoleDDL';


export default function UserCreationPopup(props) {

    const { handleOnCloseClick, open, item, Flag, handleIsPost, apiFlag, rowData } = props


    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();

    // const [rowData, setRowData] = useState(item)

    const [Username, setUsername] = useState(Flag === 'Update' ? rowData.userName : '')
    const [Password, setPassword] = useState(Flag === 'Update' ? rowData.password : '')
    const [TableUsername, setTableUsername] = useState(Flag === 'Update' ? rowData.tableUserName : '')
    const [IsOpen, setIsOpen] = useState(false)

    const [NewDepartment, setNewDepartment] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [NewRoleDDL, setNewRoleDDL] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_RoleID : 0,
        Label: Flag === 'Update' ? rowData.m_RoleName : "Select...",
    })

    const [Role, setRole] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_RoleID : 0,
        Label: Flag === 'Update' ? rowData.m_RoleName : "Select...",
    })

    const [EmployeeDDL, setEmployeeDDL] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_EmployeeID : 0,
        Label: Flag === 'Update' ? rowData.employeeName : "Select...",
    })
    const [DepartmentName, setDepartmentName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    const [Designation, setDesignation] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_DesignationID : 0,
        Label: Flag === 'Update' ? rowData.designationName : "Select...",

    })
    const [Status, setStatus] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.isActiveStatusID : 0,
        Label: Flag === 'Update' ? rowData.statusName : "Select...",

    })

    // useEffect(() => {
    //     setUsername(Flag === 'Update' ? rowData.userName : '')
    //     setPassword(Flag === 'Update' ? rowData.password : '')
    //     setTableUsername(Flag === 'Update' ? rowData.tableUserName : '')
    // }, [item, IsOpen])

    const handlePost = () => {
        // console.log(Flag)
        const data = {
            M_Table_UserID: Flag === 'Update' ? rowData.m_UserID : '0',
            M_RoleID: NewRoleDDL.ID,
            M_DesignationID: Designation.ID,
            M_EmployeeID: EmployeeDDL.ID,
            TableUserName: TableUsername,
            UserName: Username,
            Password: Password,
            IsActiveStatusID: Status.ID,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(UserCreationPostDataAPI({ data }))
    }

    return (

        // <Popup
        //     onOpen={() => setIsOpen(!IsOpen)}
        //     contentStyle={{ top: '5%' }}
        //     trigger={
        //         Flag === 'Update' ?

        //             <i className="fa-solid fa-pen-to-square"
        //             style={{ cursor: "pointer" }} title='Edit'
        //             ></i>
        //             :
        //             <i className="fa-solid fa-plus btn btn-secondary float-end mb-2" style={{ color: "white", marginTop: '-2.5em' }}
        //             >
        //                 <text className="ml-2 poppins">Add</text>
        //             </i>

        //     }
        //     modal>

        //     {
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="area_pop modal-dialog modal-dialog-scrollable modal-xl" >
                <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">User Creation</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">
                                {/* <div className="col-12 col-md-6 col-lg-6">
                                    <NewDepartmentDDL
                                        NewDepartment={NewDepartment}
                                        setNewDepartment={setNewDepartment}
                                    />
                                </div>

                                <div className="col-md-6 col-lg-6">
                                    <RoleNameDataDDL
                                        Role={Role}
                                        setRole={setRole}
                                        // rowData={item}
                                        rowData={rowData}
                                        Flag={Flag}
                                        Designation={Designation}
                                        setDesignation={setDesignation}
                                        EmployeeDDL={EmployeeDDL}
                                        setEmployeeDDL={setEmployeeDDL}
                                        NewDepartment={NewDepartment}
                                    />
                                </div> */}
                                {/* <div className="col-12 col-md-6 col-lg-6">
                                    <NewDepartmentDDL
                                        NewDepartment={NewDepartment}
                                        setNewDepartment={setNewDepartment}
                                    />
                                </div> */}

                                <div className="col-12 col-md-6 col-lg-6">
                                    <NewRoleDataDDL
                                        NewRoleDDL={NewRoleDDL}
                                        setNewRoleDDL={setNewRoleDDL}
                                        NewDepartment={NewDepartment}
                                        rowData={rowData}
                                        Flag={Flag}
                                    />
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <DesignationDDL
                                        Designation={Designation}
                                        setDesignation={setDesignation}
                                        // rowData={item}
                                        rowData={rowData}
                                        Flag={Flag}
                                    />
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <EmployeeDataDDL
                                        EmployeeDDL={EmployeeDDL}
                                        setEmployeeDDL={setEmployeeDDL}
                                        Designation={Designation}
                                        // rowData={item}
                                        rowData={rowData}
                                        Flag={Flag}
                                    />
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b> Table Username <AstricSign /> :</b></label>
                                        <input type="text"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            name='TableUsername'
                                            value={TableUsername}
                                            onChange={(e) => setTableUsername(e.target.value)} />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>User Name <AstricSign /> :</b></label>
                                        <input type="text"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            name='Username'
                                            value={Username}
                                            onChange={(e) => setUsername(e.target.value)}
                                        // disabled={Flag === 'Update' ? 'disabled' : ''}

                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Password <AstricSign /> :</b></label>
                                        <input type="text"
                                            className="form-control"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            name='Password'
                                            value={Password}
                                            onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6">
                                    <StatusNameDataDDL
                                        Status={Status}
                                        setStatus={setStatus}
                                        // rowData={item}
                                        rowData={rowData}
                                        Flag={Flag}
                                    />
                                </div>
                            </div>

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                        disabled={
                                            NewRoleDDL.ID == 0 || Designation.ID == 0 || EmployeeDDL.ID == 0 || TableUsername == '' || Username == '' || Password == '' || Status.ID == 0
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* )} */}


        </Popup>
    )

}