import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import { NewScreenDailyPreventiveWorkCountPopupDataAPI } from '../../../../../Redux/GardenDashboardSlice/DailyPreventiveWorkSlice';
import ViewURL from '../../../../../Components/ViewURL/ViewURL';


export default function DailyWorkPopup(props) {

    const { handleOnCloseClick, cancelbtn, DailyWorkPopupHeading, GridData, PopUpApiFlag, open } = props

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    const { t } = useTranslation()

    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [ImageHeading, setImageHeading] = useState('')

    const handleShowImage = (Imageurl, heading) => {
        setshowImagePopup(true)
        setImagePopUpData(Imageurl)
        setImageHeading(heading)
    }

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    const [To, setTo] = useState(999)

    useEffect(() => {
        const data = {
            T_KPIUploadDailyWorkID: GridData.t_kpiUploadDailyWorkID,
            M_WorkTypeID: 0,
            M_UserID: UserID,
            To: To,
            token: token,
            Language: Language.Label,
            Flag: PopUpApiFlag == 'Total' ? 'Total' : PopUpApiFlag == 'Pending' ? 'Pending' : 'Completed'
        }
        { Language.Label != '' && dispatch(NewScreenDailyPreventiveWorkCountPopupDataAPI({ data })) }
    }, [Language.Label])

    const { tableData, isLoading } = useSelector(state => state.NewScreenDailyPreventiveWorkCountPopupData)
    return (
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick} contentStyle={{ width: "70vw" }}
        >
            <div className='area_pop modal-dialog modal-dialog-scrollable modal-xl'>
                <div className="modal-content p-4" style={{ width: "80vw" }}>
                    <div className="modal-header pt-0 pb-0">
                        <h5 className="card-title text-dark fw-bold" id="exampleModalLongTitle">
                            {DailyWorkPopupHeading}
                        </h5>
                        <button onClick={() => cancelbtn()} type="button" className="close border_cancel_btn" style={{ marginLeft: "auto" }}>
                            <span aria-hidden="true" className=" py-0" style={{ color: "red" }}>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body mx-0">
                        <div className="row">
                            <div className="table-responsive">

                                <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                    <thead>
                                        <tr style={{ textAlign: "center" }}>
                                            <th width="5%">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                            {
                                                DailyWorkPopupHeading != t('AdminDashboard.Admin_Titles.Total_works_Completed') ?
                                                    <th>{t('Dashboard.Work_Assigned_by_Officials.Work_Name')}</th>
                                                    :
                                                    <>
                                                        <th style={{ width: '40%' }}>{t('Dashboard.Work_Assigned_by_Officials.Work_Name')}</th>
                                                        <th style={{ width: '5%' }}>{t('EmployeeAndContractor.Yes')}</th>
                                                        <th style={{ width: '5%' }}>{t('EmployeeAndContractor.No')}</th>
                                                        <th style={{ width: '7%' }}>{t('EmployeeAndContractor.Count')}</th>
                                                        <th style={{ width: '12%' }}>{t('EmployeeAndContractor.ChecklistPhoto')}</th>
                                                        <th>{t('AdminDashboard.Admin_Titles.Remark')}</th>
                                                    </>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {
                                            filteredWork && filteredWork.length > 0 ? filteredWork.map((item, i) => ( */}
                                        {
                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) =>
                                                <tr>
                                                    <td className="text-center">{i + 1}</td>
                                                    {
                                                        DailyWorkPopupHeading != t('AdminDashboard.Admin_Titles.Total_works_Completed') ?
                                                            <td>{item.m_Daily_Work_CheckList ? item.m_Daily_Work_CheckList : '-'}</td>
                                                            :
                                                            <>
                                                                <td>{item.m_Daily_Work_CheckList ? item.m_Daily_Work_CheckList : '-'}</td>
                                                                <td className='fw-bold text-center' style={{ color: 'darkgreen' }}>{item?.m_Daily_Work_CheckList_YesID == 1 ? 'Yes' : '-'}</td>
                                                                <td className='fw-bold text-center' style={{ color: 'orangered' }}>{item?.m_Daily_Work_CheckList_NoID == 1 ? 'No' : '-'}</td>
                                                                <td className='text-center'>{item?.checkListCount ? item.checkListCount : '-'}</td>
                                                                <td className='text-center'>
                                                                    {
                                                                        (item.checkListPhoto == null) ?
                                                                            <span>-</span>
                                                                            :
                                                                            <>
                                                                                <i className="fa-solid fa-eye" title='View'
                                                                                    style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                    onClick={() => (
                                                                                        handleShowImage(item.checkListPhoto),
                                                                                        setImageHeading(t('EmployeeAndContractor.ChecklistPhoto'))
                                                                                    )}
                                                                                ></i>
                                                                            </>
                                                                    }
                                                                </td>
                                                                <td>{item.remark ? item.remark : '-'}</td>
                                                            </>
                                                    }
                                                </tr>
                                            ) :
                                                <>No Found data</>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
                {
                    showImagePopup ?
                        <ViewURL
                            Heading={ImageHeading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''


                }
            </div>
        </Popup>
    )

}