import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Loading } from '../../../../../Helper/Loading';
import Header from '../../../../../Components/Header/Header';
import Sidebar from '../../../../../Components/Sidebar/Sidebar';
import { useAuthState } from '../../../../../Helper/Context';
import { Pegination } from '../../../../../Components/Pegination/Pegination';
import { WardNameDataDDL } from '../../../../../Components/CommonDDL/WardNameDataDDL';
import { WorkNameDDL } from '../../../../../Components/CommonDDL/WorkNameDDL';
import { RoadWorkReportCompleteTableDataAPI } from '../../../../../Redux/ReportsSlice/RoadWorkReportCompleteSlice';
import ViewURL from '../../../../../Components/ViewURL/ViewURL';
import { usePDF } from 'react-to-pdf';
import { toastErrorr } from '../../../../../Helper/ToastMessage';

export default function RoadReportCompelete() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")

    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    console.log(ApiFlag)

    const d = new Date();
    let month = d.getMonth();

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
        ID: 0,
        Label: "Select...",
    })
    const [WorkName, setWorkName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [FromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [ImageHeading, setImageHeading] = useState('')
    const [rowData, setrowData] = useState('')
    const [apiFlag, setapiFlag] = useState('')
    const [PDFUrl, setPDFUrl] = useState('')

    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })

    const handleSearch = () => {
        setIsSearch(!IsSearch)
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setFromDate(moment().startOf('month').format('YYYY-MM-DD'))
        setToDate(moment().format('YYYY-MM-DD'))
        setWardName({
            ...WardName,
            ID: 0,
            Label: 'Select...',
        })

        // console.log("call")
    }

    const handlePost = () => {
        setIsPost(!IsPost)
    }

    const handleShowImage = (ImageUrl) => {
        setshowImagePopup(true)
        setImagePopUpData(ImageUrl)
    }

    const handleGetPdfUrl = (pdfUrl) => {
        if (pdfUrl && pdfUrl != '') {
            setPDFUrl(pdfUrl)
        } else {
            toastErrorr("PDF not found")
        }
    }

    const handleOpenPDF = () => {
        window.open(PDFUrl)
    }

    useEffect(() => {
        const data = {
            M_WardID: WardName.ID,
            FromDate: FromDate,
            ToDate: ToDate,
            UserID: UserID,
            token: token,
            Flag: 'Complete',
            From: From,
            To: To,
            handleGetPdfUrl: handleGetPdfUrl
        }
        dispatch(RoadWorkReportCompleteTableDataAPI({ data }))
    }, [To, WardName.ID, FromDate, ToDate])

    const { tableData, isLoading } = useSelector(state => state.RoadWorkReportCompleteTableData)


    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [WardName.ID, FromDate, ToDate])


    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select Ward",
    // })

    const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    // DashboardHeading="Road Maintainance"
                    setYearValue={setYearValue}
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}
                />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId} />
                    <div id="" className="content1 content">

                        {/* <!-- [ Main Content ] start --> */}

                        <div className="data_mob">
                            <div className="row m-1 pb-4 master_border">
                                <div className="col-8">
                                    <h5 className="ps-1 py-2">Road Work Complete Report</h5>
                                </div>
                                <div className="col-4 mt-2">
                                    <button type="button" className="btn btn-outline-primary float-end mr-1 mb-2 pt-1 pb-1 pl-3 pr-3"
                                        onClick={handleOpenPDF}
                                    >PDF</button>
                                </div>
                                <div className="row m-0">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                        <div className="col-12">
                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"> <b>From Date :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="FromDate"
                                                            value={FromDate}
                                                            onChange={(e) => (setFromDate(e.target.value), setToDate(''))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>To Date :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="ToDate"
                                                            value={ToDate}
                                                            onChange={(e) => setToDate(e.target.value)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <WardNameDataDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        HWard={HWard}
                                                        WLable={WLable}
                                                    />
                                                </div>
                                                {/* <div className="col-12 col-md-6 col-lg-2">
                                                    <WorkNameDDL
                                                        // WorkName={WorkName}
                                                        // setWorkName={setWorkName}
                                                    />
                                                </div> */}
                                                <div className="col-12 col-md-6 col-lg-2 mt-lg-4 mt-md-4" >
                                                    {/* <button type="button" className="btn-search btn btn btn-secondary mr-1"
                                                    onClick={() => handleSearch()}
                                                    >
                                                        Search
                                                    </button> */}
                                                    <button type="button" className="btn-clear btn btn-primary"
                                                        onClick={handleClear}
                                                    >
                                                        Clear</button>
                                                </div>
                                            </div>

                                            <div className="row mob_table table-responsive pt-1">
                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            <th>Location</th>
                                                            <th>Work Name</th>
                                                            <th>Work Number</th>
                                                            <th>Upload Date</th>
                                                            <th>Upload By</th>
                                                            <th>Approved Date</th>
                                                            <th>Approved By</th>
                                                            <th>Approved By JE/DE</th>
                                                            <th>Estimated Amount</th>
                                                            <th>Before Photo</th>
                                                            <th>Before Photo Latitude</th>
                                                            <th>Before Photo Longitude</th>
                                                            <th>After Photo</th>
                                                            <th>After Photo Latitude</th>
                                                            <th>After Photo Longitude</th>
                                                            <th>Work Done Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, j) => {
                                                                return (
                                                                    <tr style={{ width: '100%' }}>
                                                                        <td>{item.rowNum ? item.rowNum : '-'}</td>
                                                                        <td>{item.locationName ? item.locationName : '-'}</td>
                                                                        <td>{item.workName ? item.workName : '-'}</td>
                                                                        <td>{item.workNumber ? item.workNumber : '-'}</td>
                                                                        <td>{item.workUploadDate ? item.workUploadDate : '-'}</td>
                                                                        <td>{item.uploadByM_EmployeeID ? item.uploadByM_EmployeeID : '-'}</td>
                                                                        <td>{item.doneDate ? item.doneDate : '-'}</td>
                                                                        <td>{item.approved_By ? item.approved_By : '-'}</td>
                                                                        <td>{item.jE_DE_ByName ? item.jE_DE_ByName : '-'}</td>
                                                                        <td>{item.estimatedAmount ? item.estimatedAmount : '-'}</td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            <i className="fa-solid fa-eye"
                                                                                style={{ cursor: 'pointer' }} title='View'
                                                                                onClick={() => (handleShowImage(item.beforeWork), setImageHeading("Before Photo"))}
                                                                            ></i>
                                                                        </td>
                                                                        <td>{item.beforeWorkLatitude ? item.beforeWorkLatitude : '-'}</td>
                                                                        <td>{item.beforeWorkLongitude ? item.beforeWorkLongitude : '-'}</td>
                                                                        <td style={{ textAlign: 'center' }}>
                                                                            <i className="fa-solid fa-eye"
                                                                                style={{ cursor: 'pointer' }} title='View'
                                                                                onClick={() => (handleShowImage(item.afterWork), setImageHeading("After Photo"))}
                                                                            ></i>
                                                                        </td>
                                                                        <td>{item.afterWorkLatitude ? item.afterWorkLatitude : '-'}</td>
                                                                        <td>{item.afterWorkLongitude ? item.afterWorkLongitude : '-'}</td>
                                                                        <td>{item.doneDate ? moment(item.doneDate).format("DD-MM-YYYY") : '-'}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                                :
                                                                <></>
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {
                    showImagePopup ?
                        <ViewURL
                            Heading={ImageHeading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''


                }

            </div>

        </>

    )

}