import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";

// Get Table Data
export const ContractorWiseAssetTableDataAPI = createAsyncThunk("ContractorWiseAssetTableData", async ({ data }) => {
    const {
        M_ZoneID,
        M_WardID,
        M_AssetTypeID,
        M_AssetID,
        M_ContractorID,
        M_WorkTypeID,
        M_UsersID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_ContractorWiseM_AssetGrid_Select?M_ZoneID=${M_ZoneID ? M_ZoneID :"0"}&M_WardID=${M_WardID ? M_WardID : '0'}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_UsersID=${M_UsersID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ContractorWiseAssetTableDataSlice = createSlice({
    name: "ContractorWiseAssetTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorWiseAssetTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ContractorWiseAssetTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ContractorWiseAssetTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ContractorWiseAssetTableDataReducer = ContractorWiseAssetTableDataSlice.reducer

// Export Data

export const ContractorWiseAssetTableExportDataAPI = createAsyncThunk("ContractorWiseAssetTableExportData", async ({ data }) => {
    const {
        M_ZoneID,
        M_WardID,
        M_AssetTypeID,
        M_AssetID,
        M_ContractorID,
        M_WorkTypeID,
        M_UsersID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_ContractorWiseM_AssetGrid_Select?M_ZoneID=${M_ZoneID ? M_ZoneID :"0"}&M_WardID=${M_WardID ? M_WardID : '0'}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_UsersID=${M_UsersID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ContractorWiseAssetTableExportDataSlice = createSlice({
    name: "ContractorWiseAssetTableData",
    initialState: {
        isExportLoading: false,
        ExportTableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorWiseAssetTableExportDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(ContractorWiseAssetTableExportDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExportTableData = action.payload;
        });
        builder.addCase(ContractorWiseAssetTableExportDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExportTableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ContractorWiseAssetTableExportDataReducer = ContractorWiseAssetTableExportDataSlice.reducer


// Post API
export const ContractorWiseAssetPostAPI = createAsyncThunk("ContractorWiseAssetPostData", async ({ data }) => {
    const {
        M_AssetID_Data,
        M_ContractorID,
        M_ContractorID_Data,
        M_AssetID,
        M_WorkTypeID,
        // M_EmployeeWiseM_UserID,
        // M_EmployeeID,
        M_UsersID,
        token,
        Flag,
        handleOnCloseClick,
        // handleClear
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    // formdata.append("M_AssetID_Data", M_AssetID_Data);
    // formdata.append("M_ContractorID", M_ContractorID);
    // formdata.append("M_EmployeeWiseM_UserID", M_EmployeeWiseM_UserID);
    // formdata.append("M_EmployeeID", M_EmployeeID);
    // formdata.append("M_UsersID", M_UsersID);
    // formdata.append("Flag", Flag);

    formdata.append("M_ContractorID_Data", M_ContractorID_Data);
    formdata.append("M_WorkTypeID", M_WorkTypeID);
    formdata.append("M_AssetID", M_AssetID);
    formdata.append("M_UsersID", M_UsersID);
    // formdata.append("M_EmployeeID", M_EmployeeID);
    // formdata.append("M_EmployeeWiseM_UserID", M_EmployeeWiseM_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Post_M_ContractorWiseM_Asset_InsertUpdate`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleOnCloseClick()
                // handleClear()
                toastSuccesss(result.message)
                return result.data
            } else {
                return result
            }
        })
})

const ContractorWiseAssetPostDataSlice = createSlice({
    name: "ContractorWiseAssetPostData",
    initialState: {
        isPostLoading: false,
        PostData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorWiseAssetPostAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(ContractorWiseAssetPostAPI.fulfilled, (state, action) => {
            state.isPostLoading = false;
            state.PostData = action.payload;
        });
        builder.addCase(ContractorWiseAssetPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.PostData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ContractorWiseAssetPostDataReducer = ContractorWiseAssetPostDataSlice.reducer