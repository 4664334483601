import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const SeverageWorkTypeTableDataAPI = createAsyncThunk("SeverageWorkTypeTableData", async ({ data }) => {
    const {
        M_SW_WorkTypeID,
        WorkType,
        M_UserID,
        token,
        From,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_WEB_M_SW_WorkType_Select?M_SW_WorkTypeID=${M_SW_WorkTypeID ? M_SW_WorkTypeID : '0'}&WorkType=${WorkType ? WorkType : ''}&M_UserID=${M_UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const SeverageWorkTypeTableDataSlice = createSlice({
    name: "SeverageWorkTypeTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SeverageWorkTypeTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SeverageWorkTypeTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(SeverageWorkTypeTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const SeverageWorkTypeTableDataReducer = SeverageWorkTypeTableDataSlice.reducer


export const SeverageWorkTypePostDataAPI = createAsyncThunk("SeverageWorkTypePostData", async ({ data }) => {
    const {
        M_SW_WorkTypeID,
        WorkType,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_SW_WorkTypeID", M_SW_WorkTypeID);
    formdata.append("WorkType", WorkType);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_WEB_M_SW_WorkType_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const SeverageWorkTypePostDataSlice = createSlice({
    name: "SeverageWorkTypePostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SeverageWorkTypePostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SeverageWorkTypePostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(SeverageWorkTypePostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const SeverageWorkTypePostDataReducer = SeverageWorkTypePostDataSlice.reducer

// -----------------Delete API ---------------

export const SeverageWorkTypeDeleteAPI = createAsyncThunk("SeverageWorkTypeDelete", async ({ data }) => {
    const {
        M_SW_WorkTypeID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    var formdata = new FormData();
    formdata.append("M_SW_WorkTypeID", M_SW_WorkTypeID);
    formdata.append("M_UserID", M_UserID);
    
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/Master/WEB_M_SW_WorkType_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const SeverageWorkTypeDeleteSlice = createSlice({
    name: "SeverageWorkTypeDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SeverageWorkTypeDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(SeverageWorkTypeDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(SeverageWorkTypeDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const SeverageWorkTypeDeleteReducer = SeverageWorkTypeDeleteSlice.reducer