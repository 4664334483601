
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import moment from "moment";
import Header from '../../../../../Components/Header/Header'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { AssetTypeDataDDL } from '../../../../../Components/CommonDDL/AssetTypeDataDDL';
import { useAuthState } from '../../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from '../../../../../Helper/Loading';
import { Pegination } from '../../../../../Components/Pegination/Pegination';
import ViewURL from '../../../../../Components/ViewURL/ViewURL';
import { useTranslation } from 'react-i18next';
import CommonDashboardScreenWardDDL from '../../../../../Components/CommonDDL/CommonDashboardScreenWardDDL';
import { AssetWiseEquipmentNameDataDDL } from '../../../../../Components/CommonDDL/AssetWiseEquipmentDDL';
import { WorkTypeDDL } from '../../../../../Components/CommonDDL/WorkTypeDDL';
import { SportWorkProgressTableDataAPI } from '../../../../../Redux/CommDashbaord/WorkProgressSlice/SportWorkProgressSlice';
import { ContractorNameDDLNew } from '../../../../../Components/CommonDDL/ContractorNameDDLNew';
import { WardWiseAssetNameDataDDL } from '../../../../../Components/CommonDDL/WardWiseAssetDDL';

export default function SportsWorkProgressByContractorTable() {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let benchmark = searchParams.get("benchmark")
    let ApiFlag = searchParams.get("ApiFlag")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ZWard = searchParams.get("ZoneName")
    let ZLable = searchParams.get("ZLable")
    let DDLFlag = searchParams.get("DDLFlag")
    let FlagName = searchParams.get("Flag")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let RoleID1 = searchParams.get("RoleID")
    let Lang = searchParams.get("Lang")
    let WorkPeriodID = searchParams.get("WorkPeriodID")
    let ZoneValue = searchParams.get("ZoneName")
    let m_gD_WorkPeriodID = searchParams.get("m_gD_WorkPeriodID")
    let ShowBy = searchParams.get("ShowBy")
    let SportsContractorHeaderDDL = searchParams.get("SportsContractorHeaderDDL")


    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: HYear && HYear ? HYear : '0',
        Label: YLable && YLable ? YLable : "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: HMonth && HMonth ? HMonth : '0',
        Label: MLable && MLable ? MLable : "Select...",

    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })

    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        ID: ZWard && ZWard ? ZWard : '0',
        Label: ZLable && ZLable ? ZLable : "Select...",
        // ID: 0,
        // Label: "Select...",

    })
    const [WorkAssignedBy, setWorkAssignedBy] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [AssetName, setAssetName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [EquipmentName, setEquipmentName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    const [AssetWiseEquipmentName, setAssetWiseEquipmentName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    const [ContractorName, setContractorName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WardWiseAssetName, setWardWiseAssetName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [ContractorWiseAsset, setContractorWiseAsset] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [isSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [Heading, setHeading] = React.useState(false)

    const [FromDate, setFromDate] = useState('')
    // moment().startOf('month').format('YYYY-MM-DD')
    const [ToDate, setToDate] = useState('')
    // moment().format('YYYY-MM-DD')

    const YearMonth = () => {

        if (((moment().startOf('month').format('M')) == HMonth) && (moment().format('YYYY') == HYear)) {
            // console.log("ifdfgdfg")
            setFromDate(moment().startOf('month').format('YYYY-MM-DD'))
            setToDate(moment().format('YYYY-MM-DD'))
        } else {
            // console.log("else" )
            setFromDate(moment(HMonth, 'M').year(HYear, 'YYYY').startOf('month').format('YYYY-MM-DD'))
            setToDate(moment(HMonth, 'M').year(HYear, 'YYYY').endOf('month').format('YYYY-MM-DD'))

        }
    }

    useEffect(() => {
        if (FromDate != moment().startOf('month').format('YYYY-MM-DD')) {
            setToDate(moment(HMonth, 'MM').year(HYear, 'YYYY').endOf('month').format('YYYY-MM-DD'))
        }
    }, [FromDate])

    useEffect(() => {
        YearMonth()
    }, [])

    const handleFromToDate = (fromdate) => {
        setFromDate(fromdate)
        setToDate('')
    }


    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setFromDate('')
        YearMonth()
        setToDate('')
        setWardName({
            ...WardName,
            ID: HWard,
            Label: WLable,
        })
        setZoneNameDDLID({
            ...ZoneNameDDLID,
            ID: ZWard && ZWard ? ZWard : '0',
            Label: ZLable && ZLable ? ZLable : "Select...",
        })
        setAssetName({
            ...AssetName,
            ID: 0,
            Label: 'Select...',
        })
        setAssetType({
            ...AssetType,
            ID: 0,
            Label: 'Select...',
        })
        setWorkAssignedBy({
            ...WorkAssignedBy,
            ID: 0,
            Label: 'Select...',
        })
        setWorkType({
            ...WorkType,
            ID: WorkType.DDL[3].value,
            Label: WorkType.DDL[3].label,
        })
        setWardWiseAssetName({
            ...WardWiseAssetName,
            ID: 0,
            Label: 'Select...'
        })
        setContractorWiseAsset({
            ...ContractorWiseAsset,
            ID: 0,
            Label: 'Select...'
        })
        setAssetWiseEquipmentName({
            ...AssetWiseEquipmentName,
            ID: 0,
            Label: 'Select...'
        })
    }

    const handleSearch = () => {
        setCurrentPage(0)
        setTo(10)
        setFrom(1)
        setIsSearch(!isSearch)

    }

    const handleShowImage = (Imageurl, heading) => {
        setshowImagePopup(true)
        setImagePopUpData(Imageurl)
        setHeading(heading)
    }


    useEffect(() => {
        const data = {
            FromDate: FromDate != 'Invalid date' ? FromDate : '',
            ToDate: ToDate != 'Invalid date' ? ToDate : '',
            M_WardID: WardName.ID,
            M_AssetID: WardWiseAssetName.ID,
            M_AssetTypeID: AssetType.ID,
            M_GD_WorkPeriodID: m_gD_WorkPeriodID ? m_gD_WorkPeriodID : '0',
            M_ContractorID: ContractorName.ID == 0 ? SportsContractorHeaderDDL : ContractorName.ID,
            M_UsersID: UserID,
            Flag: 'SportWork',
            ShowBy: ShowBy,
            Language: Language.Label,
            From: From,
            To: To,
            token: token,
            // M_ZoneID: ZoneNameDDLID.ID,
        }
        { Language.Label != '' && WardName.ID && dispatch(SportWorkProgressTableDataAPI({ data })) }
    }, [FromDate, ToDate, IsPost, To, WardName.ID, AssetType.ID, AssetName.ID, ContractorName.ID, WardWiseAssetName.ID, IsClear, Language.Label])


    const { tableData, isLoading } = useSelector(state => state.SportWorkProgressTableData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [ZoneNameDDLID.ID, WardName.ID, AssetType.ID, WardWiseAssetName.ID, ContractorName.ID, SportsContractorHeaderDDL])

    // console.log('WardWiseAssetName',WardWiseAssetName.ID)

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    DashboardHeading="Garden Maintainance"
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar Language={Language}
                        ProjectTypeId={ProjectTypeId} />
                    <div id="" className='content1'>
                        <div className='data_mob'>
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className='col-8'>
                                    <h5 className="ps-1 pt-1">Sports Work Progress By Officials :- {benchmark} {searchName}</h5>
                                </div>
                                <div className='col-4'>
                                    {/* <button type="button" className="btn btn-primary float-end mr-1">Export</button> */}
                                </div>

                                <div className="row m-0 mt-1">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12 ">
                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"> <b>{t('Dashboard.Work_Assigned_by_Officials.From_Date')} :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="FromDate"
                                                            value={FromDate}
                                                            max={moment().format('YYYY-MM-DD')}
                                                            // onChange={(e) => (setFromDate(e.target.value), setToDate(''))}
                                                            onChange={(e) => handleFromToDate(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>{t('Dashboard.Work_Assigned_by_Officials.To_Date')} :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="ToDate"
                                                            value={ToDate}
                                                            min={FromDate}
                                                            max={moment().format('YYYY-MM-DD')}
                                                            onChange={(e) => setToDate(e.target.value)}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-5">
                                                    <CommonDashboardScreenWardDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        WLable={WLable}
                                                        HWard={HWard}
                                                        ZLable={ZLable}
                                                        ZWard={ZWard}
                                                        Language={Language}
                                                        ZoneNameDDLID={ZoneNameDDLID}
                                                        setZoneNameDDLID={setZoneNameDDLID}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <AssetTypeDataDDL
                                                        AssetType={AssetType}
                                                        setAssetType={setAssetType}
                                                        Language={Language}
                                                    // Flag='SportScreenOnly'
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WardWiseAssetNameDataDDL
                                                        WardWiseAssetName={WardWiseAssetName}
                                                        setWardWiseAssetName={setWardWiseAssetName}
                                                        WardName={WardName}
                                                        AssetType={AssetType}
                                                        M_WorkTypeID='4'
                                                        M_ZoneID={ZoneNameDDLID}
                                                        M_RoleID={RoleID}
                                                        M_GD_WorkPeriodID={m_gD_WorkPeriodID ? m_gD_WorkPeriodID : '0'}
                                                        // M_AssetTypeID={1}
                                                        Language={Language}
                                                        Flag={
                                                            ApiFlag == 'Assigned' ? 'SportWork_Assigned' :
                                                                ApiFlag == 'Pending' ? 'SportWork_Pending' :
                                                                    'SportWork_Completed'
                                                        }
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">

                                                    <AssetWiseEquipmentNameDataDDL
                                                        AssetWiseEquipmentName={AssetWiseEquipmentName}
                                                        setAssetWiseEquipmentName={setAssetWiseEquipmentName}
                                                        AssetName={AssetName}
                                                        Language={Language}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-5 col-lg-2">
                                                    <WorkTypeDDL
                                                        WorkType={WorkType}
                                                        setWorkType={setWorkType}
                                                        IsPost={IsPost}
                                                        Language={Language}
                                                        Flag1='Sport'
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <ContractorNameDDLNew
                                                        ContractorName={ContractorName}
                                                        setContractorName={setContractorName}
                                                        M_WorkTypeID='4'
                                                        M_AssetID={WardWiseAssetName.ID}
                                                        AssetType={AssetType}
                                                        M_ZoneID={ZoneNameDDLID.ID}
                                                        Language={Language}
                                                        WorkPeriodID={m_gD_WorkPeriodID ? m_gD_WorkPeriodID : '0'}
                                                        Flag={
                                                            ApiFlag == 'Assigned' ? 'SportWork_Assigned' :
                                                                ApiFlag == 'Pending' ? 'SportWork_Pending' :
                                                                    'SportWork_Completed'
                                                        }
                                                    />
                                                </div>

                                                <div className="col-12 col-lg-1 col-md-4 mt-lg-4 " >
                                                    {/* <button type="button"
                                                        className="btn-clear btn btn-primary mx-2"
                                                        onClick={handleSearch}
                                                    >
                                                        Search</button> */}
                                                    <button type="button"
                                                        className="btn btn-outline-danger"
                                                        onClick={handleClear}
                                                    >
                                                        Clear</button>
                                                </div>
                                            </div>

                                            <div className="row mob_table table-responsive pt-1">
                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <th>Sr.No</th>
                                                        <th>Assign Date</th>
                                                        <th>Asset Name</th>
                                                        <th>Prabhag No.</th>
                                                        <th>Equipment Name</th>
                                                        <th>Equipment No.</th>
                                                        <th>Installation Date</th>
                                                        <th>Work Name</th>
                                                        <th>Work Photo</th>
                                                        <th>Assigned By</th>
                                                        <th>Supplier Name</th>
                                                        {
                                                            searchName == (t('Dashboard.Titles.Completed')) ?
                                                                <>
                                                                    <th>Work Date</th>
                                                                    <th>After Work Photo</th>
                                                                    <th>Status</th>
                                                                </>
                                                                :
                                                                ''
                                                        }

                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.length > 0 ? tableData.map((item) =>
                                                                <tr>
                                                                    <td style={{ textAlign: "center", verticalAlign: 'middle' }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                    <td style={{ verticalAlign: 'middle' }}>{item.workAssignDate ? moment(item.workAssignDate).format('DD-MM-YYYY') : '-'}</td>
                                                                    <td style={{ verticalAlign: 'middle' }}>{item.assetName ? item.assetName : '-'}</td>
                                                                    <td style={{ verticalAlign: 'middle' }}>{item.wardName ? item.wardName : '-'}</td>
                                                                    <td style={{ verticalAlign: 'middle' }}>{item.equipmentName ? item.equipmentName : '-'}</td>
                                                                    <td style={{ verticalAlign: 'middle' }}>{item.equipmentNo ? item.equipmentNo : '-'}</td>
                                                                    <td style={{ verticalAlign: 'middle' }}>{item.equipmentInstallationDate ? moment(item.equipmentInstallationDate).format('DD-MM-YYYY') : '-'}</td>
                                                                    <td style={{ verticalAlign: 'middle' }}>{item.workname ? item.workname : '0'}</td>
                                                                    <td style={{ textAlign: "center", verticalAlign: 'middle' }}>
                                                                        {
                                                                            (item.workPhoto == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye" title='View'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => (
                                                                                            handleShowImage(item.workPhoto),
                                                                                            setHeading("Assign Work Photo")
                                                                                        )}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    <td style={{ verticalAlign: 'middle' }}>{item.assingBy ? item.assingBy : '0'}</td>
                                                                    <td style={{ verticalAlign: 'middle' }}>{item.contractorName ? item.contractorName : '-'}</td>
                                                                    {
                                                                        searchName == (t('Dashboard.Titles.Completed')) ?
                                                                            <>
                                                                                <td style={{ verticalAlign: 'middle' }}>{item.workDate ? moment(item.workDate).format('DD-MM-YYYY') : '-'}</td>
                                                                                <td style={{ textAlign: "center", verticalAlign: 'middle' }}>
                                                                                    {
                                                                                        (item.afterWorkPhoto == null) ?
                                                                                            <span>-</span>
                                                                                            :
                                                                                            <>
                                                                                                <i className="fa-solid fa-eye" title='View'
                                                                                                    style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                                    onClick={() => (
                                                                                                        handleShowImage(item.afterWorkPhoto),
                                                                                                        setHeading("Completed Work Photo")
                                                                                                    )}
                                                                                                ></i>
                                                                                            </>
                                                                                    }
                                                                                </td>
                                                                                <td style={{ padding: '10px', verticalAlign: 'middle' }}>
                                                                                    <span className={item.statusName === "Pending" || item.statusName === "प्रलंबित" ? "Pendingstatus" : "Completedstatus"}>{item.statusName ? item.statusName : '-'}</span>
                                                                                </td>
                                                                            </>
                                                                            :
                                                                            ''

                                                                    }
                                                                </tr>
                                                            ) :
                                                                <td colSpan={15}>No Found data</ td>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!--  --> */}


                    </div>
                </div>
                {
                    showImagePopup ?
                        <ViewURL
                            Heading={Heading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''
                }
            </div>
        </>

    )
}