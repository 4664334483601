
import React from 'react'
import { useNavigate } from 'react-router-dom'
import WaterPreMainWorkCard from './WaterPreMainWorkCard'

export default function WaterPreMainWorkCardManage(props) {
    const { Countdata,HeaderMonthID,HeaderFinancialYear , MonthValue, YearValue , YearLabel,MonthLabel,WardValue,WardLabel  } = props

    const WaterPreMainWorkCardNavigate = (name, screenFlag) => {
        if (name === "Operation Works") {
            navigate(`/commonwatersupplypreventivemaintainanceworkpage?name=${name}&ApiFlag=Operation Works`)
        }else if (name === "Electrical Work Maintainance") {
            navigate(`/commonwatersupplypreventivemaintainanceworkpage?name=${name}&ApiFlag=Electrical Work Maintainance`)
        }
        else if (name === "Mechanical Work Maintainance") {
            navigate(`/commonwatersupplypreventivemaintainanceworkpage?name=${name}&ApiFlag=Mechanical Work Maintainance`)
        }
    }
    const navigate = useNavigate()


    return (

        <div className='row ml-0 mr-0 pb-2'>
            <div className="col-lg-4 col-md-12 mb-2" >
                <WaterPreMainWorkCard
                    title='Operation Works'
                    count='100'
                    imgPath='./assets/img/engineering.png'
                    cardName=' '
                    navLink='/commonwatersupplypreventivemaintainanceworkpage'
                    countText='green_color'
                    WaterPreMainWorkCardNavigate={WaterPreMainWorkCardNavigate}
                />
            </div>

            <div className="col-lg-4 col-md-12 mb-2">
                <WaterPreMainWorkCard
                    title='Electrical Work Maintainance'
                    count='100'
                    imgPath='./assets/img/renewable-energy.png'
                    cardName=''
                    navLink='/roadworkapproval'
                    countText='green_color'
                    WaterPreMainWorkCardNavigate={WaterPreMainWorkCardNavigate}

                />
            </div>

            <div className="col-lg-4 col-md-12 mb-2" >
                <WaterPreMainWorkCard
                    title='Mechanical Work Maintainance'
                    count='100'
                    imgPath='./assets/img/mechanics.png'
                    cardName=''
                    navLink='/roadworkapproval'
                    countText='green_color'
                    WaterPreMainWorkCardNavigate={WaterPreMainWorkCardNavigate}

                />
            </div>
        </div>

    )
}






