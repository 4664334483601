import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const PumpingStationTableDataAPI = createAsyncThunk("PumpingStationTableData", async ({ data }) => {
    const {
        M_SW_PumpingStationID,
        M_WardID,
        PumpingStation,
        M_UsersID,
        token,
        From,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_WEB_M_SW_PumpingStation_Select?M_SW_PumpingStationID=${M_SW_PumpingStationID}&M_WardID=${M_WardID ? M_WardID : '0'}&PumpingStation=${PumpingStation ? PumpingStation : ''}&M_UserID=${M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const PumpingStationTableDataSlice = createSlice({
    name: "PumpingStationTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PumpingStationTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PumpingStationTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PumpingStationTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const PumpingStationTableDataReducer = PumpingStationTableDataSlice.reducer


export const PumpingStationPostDataAPI = createAsyncThunk("PumpingStationPostData", async ({ data }) => {
    const {
        M_SW_PumpingStationID,
        M_WardID,
        PumpingStation,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_SW_PumpingStationID", M_SW_PumpingStationID);
    formdata.append("M_WardID", M_WardID);
    formdata.append("PumpingStation", PumpingStation);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_WEB_M_SW_PumpingStation_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const PumpingStationPostDataSlice = createSlice({
    name: "PumpingStationPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PumpingStationPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PumpingStationPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PumpingStationPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PumpingStationPostDataReducer = PumpingStationPostDataSlice.reducer

// -----------------Delete API ---------------

export const PumpingStationDeleteAPI = createAsyncThunk("PumpingStationDelete", async ({ data }) => {
    const {
        M_SW_PumpingStationID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    var formdata = new FormData();
    formdata.append("M_SW_PumpingStationID",M_SW_PumpingStationID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/WEB_M_SW_PumpingStation_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const PumpingStationDeleteSlice = createSlice({
    name: "PumpingStationDelete",
    initialState: {
        isDeleteLoading: false,
        PumpingData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PumpingStationDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(PumpingStationDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.PumpingData = action.payload;

        });
        builder.addCase(PumpingStationDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.PumpingData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PumpingStationDeleteReducer = PumpingStationDeleteSlice.reducer