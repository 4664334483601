import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import Header from '../../../../../Components/Header/Header'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import RoadMasterSectorPopup from './RoadMasterSectorNamePopup'
import DeletePopup from '../../GardenPopup/DeletePopup'
import { LocationNameDataDDL } from '../../../../../Components/CommonDDL/LocationNameDataDDL'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { LocationNameMasterDeleteAPI, LocationNameMasterTableDataAPI } from '../../../../../Redux/MasterSlice/LocationNameMaster'
import { Loading } from '../../../../../Helper/Loading'
import { Pegination } from '../../../../../Components/Pegination/Pegination'
import { WardNameDataDDL } from '../../../../../Components/CommonDDL/WardNameDataDDL'
import { useSearchParams } from 'react-router-dom'

export default function RoadMasterSectorData() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [IsClear, setIsClear] = useState(false)
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')
    const [DeletePopupShow, setDeletePopupShow] = useState()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    const [RoasMasterSectorPopupShow, setRoasMasterSectorPopupShow] = useState()

    const [LocationNameDDL, setLocationNameDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })
    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select...",
    // })

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setRoasMasterSectorPopupShow(true)
    }
    const handleEditOnClick = (item) => {
        setRoasMasterSectorPopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }

    const handleOnCloseClick = () => {
        setRoasMasterSectorPopupShow(false)
    }

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setLocationNameDDL({
            ...LocationNameDDL,
            ID: 0,
            Label: 'Select...'
        })
        setWardName({
            ...WardName,
            ID: HWard,
            Label: WLable,
        })
    }

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [LocationNameDDL.ID])

    const handleDeleteApi = (item) => {
        const data = {
            M_RM_LocationID: item.m_rM_LocationID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            // handleDeleteCloseClick
        }
        dispatch(LocationNameMasterDeleteAPI({ data }))
    }

    useEffect(() => {
        const data = {
            M_WardID: WardName.ID,
            M_RM_LocationID: LocationNameDDL.ID,
            M_UserID: UserID,
            token: token,
            From: From,
            To: To,
        }
        dispatch(LocationNameMasterTableDataAPI({ data }))
    }, [IsPost, IsClear, To, LocationNameDDL.ID, WardName.ID])

    const { tableData, isLoading } = useSelector(state => state.LocationNameMasterTableData)
    
    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    DashboardHeading="Road Maintainance"
                    setYearValue={setYearValue}
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName} 
                />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId}/>
                    <div className="content">
                        {/* <!-- [ breadcrumb ] start --> */}
                        <div className="page-header ">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        {/* <div className="page-header-title">
                                            <h5 className="ps-2 data_mob">MASTER */}

                                        {/* <!-- <span className="float-end">namita</span> --> */}
                                        {/* <span className="float-end fs-6">
                                                <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                        :
                                                        setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })
                                                }}
                                                options={SelectWard.DDL}
                                            />

                                            </span> */}
                                        {/* </h5>

                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- [ Main Content ] start --> */}
                        <div className='data_mob'>
                            <div className="row col-12 m-1 py-1 pb-4 master_border">
                                <div className='col-5'>
                                    <h5 className="">Location Name Master</h5>
                                </div>
                                <div className='col-7 mar_add'>
                                    <div className="float-end butt">
                                        <button type="button" className="btn btn-primary ml-1 float-end">Export</button>
                                        {/* <RoadMasterSectorPopup
                                            open={RoasMasterSectorPopupShow}
                                            handleIsPost={handleIsPost}
                                            Flag='Insert'
                                        /> */}
                                        {/* <div> */}
                                        <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                                            style={{ color: "white" }}
                                            onClick={handleAddOnClick}
                                        > Add</i>                                   
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-12 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                        <div className="col-12">

                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-4 col-lg-3 ">
                                                    <WardNameDataDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        HWard={HWard}
                                                        WLable={WLable}
                                                        Flag='ward'
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-3">
                                                    <LocationNameDataDDL
                                                        LocationNameDDL={LocationNameDDL}
                                                        setLocationNameDDL={setLocationNameDDL}
                                                        WardName={WardName}
                                                        IsPost={IsPost}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-2 col-lg-1 mt-md-4 mt-lg-4" >
                                                    <button type="button" className="btn-clear btn btn-primary"
                                                        onClick={handleClear}
                                                    >Clear</button>
                                                </div>
                                            </div>
                                            <div className="row mob_table table-responsive pt-1">
                                                <table id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            <th>Prabhag No</th>
                                                            <th>Location Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => (
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum}</td>
                                                                    <td>{item.wardName ? item.wardName : '-'}</td>
                                                                    <td>{item.locationName ? item.locationName : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {/* <RoadMasterSectorPopup
                                                                            open={RoasMasterSectorPopupShow}
                                                                            // item={
                                                                            //     {
                                                                            //         locationName: item.locationName,
                                                                            //         m_WardID: item.m_WardID,
                                                                            //         wardName: item.wardName

                                                                            //     }
                                                                            // }
                                                                            handleIsPost={handleIsPost}
                                                                            item={item}
                                                                            Flag='Update'
                                                                        /> */}
                                                                        <span>
                                                                            <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => handleEditOnClick(item)}
                                                                            >
                                                                            </i>
                                                                        </span>
                                                                        <span>
                                                                            <DeletePopup
                                                                                open={DeletePopupShow}
                                                                                item={item}
                                                                                handleDeleteApi={handleDeleteApi}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                                :
                                                                <>No Data found</>
                                                      }
                                                    </tbody>
                                                </table>

                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    RoasMasterSectorPopupShow ?
                        <RoadMasterSectorPopup
                            open={RoasMasterSectorPopupShow}
                            popupHeading="Location Name"
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                        />
                        : <></>
                }
            </div>
        </>

    )
}