import Header from "../../../../Components/Header/Header"
import Sidebar from "../../../../Components/Sidebar/Sidebar"
import React, { useEffect, useState } from 'react'
// import Select from 'react-select'
import DepartmentPopup from "./DepartmentPopup"
import DeletePopup from "../GardenPopup/DeletePopup"
import { DepartmentDataDDL } from "../../../../Components/CommonDDL/DepartmentDataDDL"
import { useAuthState } from "../../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { DepDeleteAPI, DepartmentTableDataAPI } from "../../../../Redux/SidebarMasterSlice/DepartmentSlice"
import { Pegination } from "../../../../Components/Pegination/Pegination"
import { Loading } from "../../../../Helper/Loading"


export const Department = () => {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [DepartmentPopupShow, setDepartmentPopupShow] = useState(false)
    const [DeletePopupShow, setDeletePopupShow] = useState(false)
    const [rowData, setRowData] = useState('')


    const handleDeleteOnClick = () => {
        console.log("delete")
        setDeletePopupShow(true)
    }

    const handleAddOnClick = () => {
        console.log("add")
        setDepartmentPopupShow(true)
    }

    const handleEditOnClick = () => {
        console.log("edit")
        setDepartmentPopupShow(true)
    }

    const [Department, setDepartment] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })
    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setDepartment({
            ...Department,
            ID: 0,
            Label: 'Select...',
        })

    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }


    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }


    useEffect(() => {
        const data = {
            // FinancialYearID: YearValue,
            M_DepartmentID: Department.ID,
            DepartmentCode: '',
            DepartmentName: '',
            M_UsersID: UserID,
            token: token,
            From: From,
            To: To,
        }
        // if (YearValue !== 0) {
        dispatch(DepartmentTableDataAPI({ data }))
        // }

    }, [IsPost, To, IsClear, Department.ID])


    const { tableData, isLoading } = useSelector(state => state.DepartmentTableData)


    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [Department.ID])

    const handleDeleteApi = (item) => {

        const data = {
            M_DepartmentID:item.id,
            M_UserID: UserID,
            token: token,
            Flag: 'Delete',
            handleIsPost: handleIsPost
        }
        dispatch(DepDeleteAPI({ data }))
    }
    const { isDeleteLoading } = useSelector(state => state.DepDeleteData)

    
    return (
        <>
        {isLoading && <Loading />}   
        {isDeleteLoading && <Loading />}    
        <div>
            <Header
                DashboardHeading="Garden Maintainance"
            />
            <Sidebar />
            <div className="pcoded-main-container ">
                <div className="pcoded-content ">
                    <div className="row  m-1 py-2 pb-4 master_border">
                        <div>
                            <h5 className="ps-3 py-2 ">Department</h5>
                            <div className="col-12 col-lg-7 float-end ">
                                <DepartmentPopup
                                    open={DepartmentPopupShow} 
                                    handleIsPost={handleIsPost}/>

                            </div>
                        </div>
                        <div className="row m-0 ">
                            <div className="col-12 py-1  rounded rounded-2 shadow"
                                style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                <div className="col-12  ">
                                    <div className="row py-3 rounded2">
                                        <div className="col-12 col-lg-3">
                                            <DepartmentDataDDL
                                                Department={Department}
                                                setDepartment={setDepartment}
                                            />
                                        </div>
                                        <div className="col-12 col-lg-3 mt-lg-4" >
                                            <button type="button"
                                                className="btn-clear btn btn-primary"
                                                onClick={handleClear}>Clear</button>
                                        </div>
                                    </div>
                                    <div className="row mob_table table-responsive pt-3">
                                        <table
                                            id="totalordergenerated"
                                            cellPadding="0"
                                            cellSpacing="0"
                                            border="0"
                                            className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Sr No.</th>
                                                    <th>Department Name</th>
                                                    <th>Department Code</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) =>
                                                        <tr>
                                                            <td>{item.rowNum ? item.rowNum : '-'}</td>
                                                            <td>{item.departmentName ? item.departmentName : '-'}</td>
                                                            <td>{item.departmentCode ? item.departmentCode : '-'}</td>
                                                            <td style={{ textAlign: "center" }}>
                                                                <span >
                                                                    <DepartmentPopup
                                                                        open={DepartmentPopupShow}
                                                                        item={item}
                                                                        EditIcon='Update'
                                                                        Flag='Update'
                                                                        handleIsPost={handleIsPost}
                                                                   />
                                                                </span>
                                                                <span>
                                                                    <DeletePopup
                                                                        open={DeletePopupShow}                                                                      
                                                                        handleDeleteApi={handleDeleteApi}
                                                                        item={item}
                                                                    />
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ) :
                                                        <>No Found data</>
                                                }
                                            </tbody>
                                        </table>

                                    </div>
                                    {tableData && tableData.table && tableData.table.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={tableData.table[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            setrowNo={setrowNo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                            IsClear={IsClear}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}