

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";

// Table Data
export const SportWorkProgressTableDataAPI = createAsyncThunk("SportWorkProgressTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_AssetID,
        M_AssetTypeID,
        M_GD_WorkPeriodID,
        M_ContractorID,
        M_UsersID,
        Flag,
        ShowBy,
        From,
        To,
        token,
        Language
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_UploadAssignEquipmentWorkByContractorsDetails_Select?FromDate=${FromDate}&ToDate=${ToDate}&M_WardID=${M_WardID ? M_WardID : '0'}&M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_GD_WorkPeriodID=${M_GD_WorkPeriodID ? M_GD_WorkPeriodID : '0'}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&M_UsersID=${M_UsersID}&Flag=${Flag}&ShowBy=${ShowBy}&Language=${Language}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data.table
            } else {
                return result.data
            }
        })
})

const SportWorkProgressTableDataAPISlice = createSlice({
    name: "SportWorkProgressTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SportWorkProgressTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SportWorkProgressTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(SportWorkProgressTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const SportWorkProgressTableDataAPIReducer = SportWorkProgressTableDataAPISlice.reducer


