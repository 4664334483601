
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import Header from '../../../../Components/Header/Header'
import Sidebar from '../../../../Components/Sidebar/Sidebar'
import { ProjectTypeDataDDL } from '../../../../Components/CommonDDL/ProjectTypeDataDDL'
import { WaterSupplyUserWiseRoleDDL } from '../../../../Components/CommonDDL/WaterSupplyUserWiseRoleDDL'
import { WaterSupplyUserDDL } from '../../../../Components/CommonDDL/WaterSupplyUserDDL'
import { useAuthState } from '../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { UserWiseWardMappingPostAPI, WaterSupplyUserWiseWardMappingTableDataAPI } from '../../../../Redux/SidebarMasterSlice/WaterSupplyUserWiseWardMappingSlice'
import { Loading } from '../../../../Helper/Loading'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


export default function UserWiseWardMapping() {
    // const { Flag, rowData } = props

    const { t } = useTranslation()

    // const [DeletePopupShow, setDeletePopupShow] = useState()
    // const [PipelineMasterPopupShow, setPipelineMasterPopupShow] = useState()
    // const [apiFlag, setapiFlag] = useState('')
    // const [rowData, setrowData] = useState('')

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    const [ProjectType, setProjectType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Role, setRole] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [User, setUser] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    const [IsPost, setIsPost] = useState(false)

    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')
    const [IsClear, setIsClear] = useState(false)
    const [Flag, setFlag] = useState('')
    const [gridData, setgridData] = useState([])

    const handlePost = () => {
        setIsPost(!IsPost)
        setgridData([])
    }

    const handleAllSelectChecklist = (event) => {
        // setcheckedAll(true)
        const { checked } = event.target
        gridData.forEach((item) => {
            return item.isChecked = checked ? 1 : 0
        })
        setgridData([...gridData])
    }

    useEffect(() => {
        const data = {
            LoginM_UsersID: UserID,
            M_UsersID: User.ID,
            token: token,
            Language: Language.Label
        }
        if (ProjectType.ID != 0 && Role.ID != 0 && User.ID != 0) {
            {Language.Label != '' && dispatch(WaterSupplyUserWiseWardMappingTableDataAPI({ data }))}
        }

    }, [IsPost, IsClear, ProjectType.ID, Role.ID, User.ID,Language.Label])

    const { tableData, isLoading } = useSelector(state => state.WaterSupplyUserWiseWardMappingTableData)

    useEffect(() => {
        let roleData = tableData?.table.map((item) => ({ ...item }))
        if (ProjectType.ID != 0 && Role.ID != 0 && User.ID != 0) {
            setgridData(roleData)
        }
    }, [tableData])

    const handleOnCheckClick = (item, index, check) => {
        if (gridData) {
            let tempGridData = [...gridData]
            tempGridData[index].isChecked = check
            // console.log(tempGridData)
            setgridData(tempGridData)
        }
    }

    const handleClear = () => {
        setgridData([])
        // setCurrentPage(0)
        setIsClear(!IsClear)
        setProjectType({
            ...ProjectType,
            ID: 0,
            Label: "Select...",
        })
        setRole({
            ...Role,
            ID: 0,
            Label: "Select...",
        })
        setUser({
            ...User,
            ID: 0,
            Label: "Select...",
        })
    }

    const handelAssignClicked = () => {
        let allCheckData = ""
        gridData.forEach((item) => {
            if (item.isChecked) {
                allCheckData = allCheckData + item.paramID + ","
            }
        })
        // console.log(allCheckData)
        const data = {
            M_WardID_Data: allCheckData,
            M_UsersID: User.ID,
            LoginM_UsersID: UserID,
            token: token,
            handlePost: handlePost,
            handleClear: handleClear,
        }
        dispatch(UserWiseWardMappingPostAPI({ data }))
    }

    return (
        <>
            {isLoading && <Loading />}

            <Header
                DashboardName={DashboardName}
                Language={Language}
                setLanguage={setLanguage}
            />
            <div className="wrapper">
                <Sidebar
                    Language={Language}
                    ProjectTypeId={ProjectTypeId} />
                <div className="content">
                    <div className='data_mob'>
                        <div className="row m-1 pb-4 master_border">
                            <div className='col-5'>
                                <h5 className="mt-2">{t('AdminDashboard.Admin_Sidebar_Name.User_Wise_Ward_Mapping')}</h5>
                            </div>
                            <div className="row m-0 ">
                                <div className="col-12 py-0 rounded rounded-2 shadow"
                                    style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                    <div className="col-12">
                                        <div className="row py-1 rounded2">
                                            <div className="col-lg-3 col-md-6">
                                                <ProjectTypeDataDDL
                                                    ProjectType={ProjectType}
                                                    setProjectType={setProjectType}
                                                    Flag={Flag}
                                                    rowData={rowData}
                                                />

                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <WaterSupplyUserWiseRoleDDL
                                                    Role={Role}
                                                    setRole={setRole}
                                                    // Flag={Flag}
                                                    // rowData={rowData}
                                                    M_ProjectTypeID={ProjectType.ID}
                                                />
                                            </div>

                                            <div className="col-lg-3 col-md-6">
                                                <WaterSupplyUserDDL
                                                    User={User}
                                                    setUser={setUser}
                                                    M_RoleID={Role.ID}
                                                    M_ProjectTypeID={ProjectType.ID}
                                                    setgridData={setgridData}
                                                />
                                            </div>

                                        </div>
                                        <div className="row mob_table table-responsive pt-1">
                                            <table id="totalordergenerated"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                className="table table-bordered">

                                                <thead>
                                                    <tr>
                                                        {/* <th className="sr_no_col">
                                                            <label>
                                                                <input type="checkbox" />
                                                            </label>
                                                        </th> */}
                                                        <th width="5% text-center">
                                                            <div className="text-center">
                                                                <input
                                                                    className="text-center"
                                                                    type="checkbox"
                                                                    // checked={checkedAll}
                                                                    // checked={gridData.filter((item) => item?.isChecked !== 1).length < 1}
                                                                    disabled={ProjectType.ID === 0}
                                                                    onChange={(e) => handleAllSelectChecklist(e)}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th>{t('AdminDashboard.Admin_Sidebar_Table_Heading.Zone_Name')}</th>
                                                        <th>{t('Dashboard.Pcmc_Assets.Ward_no')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        // tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => (
                                                        gridData && gridData && gridData.length > 0 ? gridData.map((item, i) => (
                                                            <tr key={i}>
                                                                <td className="text_center">
                                                                    <div className="text-center">
                                                                        <input
                                                                            // className="text-center"
                                                                            type="checkbox"
                                                                            name={item.paramName}
                                                                            value={item.paramID}
                                                                            checked={item.isChecked}
                                                                            // checked={item.isChecked === 1 ? true : false}
                                                                            onChange={(e) => {
                                                                                handleOnCheckClick(e, i, !item.isChecked)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>{item.zoneName ? item.zoneName : '-'}</td>
                                                                <td>{item.paramName ? item.paramName : '-'}</td>

                                                            </tr>
                                                        ))
                                                            :
                                                            <>No data found</>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="mt-lg-3 mb-0">
                                    <button type="button"
                                        className="btn-clear btn btn-primary float-right d-flex"
                                        onClick={() => handleClear()}
                                    >
                                        <img src={'assets/img/close.png'} style={{ width: "10px", marginTop: "0.4em", marginRight: "0.5em" }} />
                                        Clear</button>
                                    <button type="button" className="btn btn-primary float-right mr-lg-2 d-flex mx-auto"
                                        style={{ width: "5.5em", height: "2.75em" }} onClick={handelAssignClicked}
                                    >
                                        <img src={'assets/img/diskette.png'} style={{ width: "13px", marginRight: "0.5em", marginTop: "0.3em" }} />
                                        Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>



    )
}