
import { useEffect } from 'react'
import Select from 'react-select'
import { DepartmentDDLAPI, EmployeeDDLAPI, LocationNameDDLAPI, SectorDDLAPI, WardNameDDLAPI, WaterSupplyLocationDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'

export const WaterSupplyLocationDataDDL = (props) => {
    const { WaterSupplyLocationDDL, setWaterSupplyLocationDDL, CropNameDashboardData, PopUpField,IsPost,WardName } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            // M_WardID: '1',
            M_UserID: UserID,
            token: token,
            WardName:WardName
        }
        dispatch(WaterSupplyLocationDDLAPI({ data }))
    }, [IsPost,WardName])

    const { WaterSupplyLocationData } = useSelector(state => state.WaterSupplyLocationDDLData)

    useEffect(() => {
        handleLocationNameDDL()
    }, [WaterSupplyLocationData])

    const handleLocationNameDDL = () => {
        // console.log(DeptDDLDataa)
        if (WaterSupplyLocationData && WaterSupplyLocationData.table && WaterSupplyLocationData.table.length > 0) {
            let list = WaterSupplyLocationData.table.map((item, index) => ({
                value: item.m_wS_LocationID,
                label: item.locationDetails,
            }))

            setWaterSupplyLocationDDL({
                DDL: list,
                ID: 0,
                Label: "Select...",
            })
        }
        else {
            setWaterSupplyLocationDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofEmployee"><b>Junction : </b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width3"
                isSearchable
                maxMenuHeight={150}
                value={{ value: WaterSupplyLocationDDL.ID, label: WaterSupplyLocationDDL.Label }}
                onChange={(e) => {
                    e ?
                        setWaterSupplyLocationDDL({ ...WaterSupplyLocationDDL, ID: e.value, Label: e.label })
                        :
                        setWaterSupplyLocationDDL({ ...WaterSupplyLocationDDL, ID: 0, Label: "Select..." })

                }}
                options={WaterSupplyLocationDDL.DDL}
            />
        </div>
    )
}