import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function EstimatedCard({ count, heading, cardName, imgPath, navLink,EstimateAmountNavigate }) {
    const navigate = useNavigate()

    const handleEstimatedCardClick = () => {
        navigate(navLink)
    }
    return (


        // <div className='road_work mb-1  road_work_row' onClick={handleEstimatedCardClick}>
        //     <div className="col-12">
        //         <div className=" p-3 shadow cursor blue_borders bg-white">
        //             <div className="d-flex justify-content-center align-items-center ">
        //                 <div className="col-6  text-center" style={{ borderRight: "2px solid #cfc7c7" }}>
        //                     <h6 className="text-black"><b>{heading}</b></h6>
        //                     <h4 className="green_color">{count}</h4>
        //                 </div>
        //                 <div className="col-6  text-center">
        //                     <img className={cardName} src={imgPath} />
        //                 </div>
        //             </div>

        //         </div>
        //     </div>

        // </div>

        <div style={{ height: '100%', cursor: 'pointer' }} className="mb-1 p-3 shadow blue_borders bg-white mb-md-2 daily-card-width" onClick={() => EstimateAmountNavigate(heading)}>
            <div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                <div className="text-center" >
                    <div className="d-flex justify-content-between align-items-center">
                        <h4>{count}</h4>
                        <img className={cardName} style={{ height: '35px', width: '35px' }} src={imgPath} alt="" />
                    </div>

                </div>
                <div style={{ borderLeft: '2px solid #d6bea7' }}>
                    <h6 className="text-black ml-3"><b>{heading}</b></h6>
                </div>
            </div>
        </div>

    )
}


