
import { useEffect } from 'react'
import Select from 'react-select'
import { WardNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'

export const WardNameDataDDL = (props) => {
    const { WardName, setWardName, Flag, rowData, item, IsDisabled, isClearable, HWard, WLable,ZoneName, SectorName, setSectorName, Language } = props
    // console.log(rowData)
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const { t } = useTranslation()

    // let ZoneNameDDLID = localStorage.getItem('ZoneNameDDLID')

    useEffect(() => {
        const data = {
            UserID,
            token,
            Language: Language.Label,
            M_ZoneID: ZoneName.ID,
            handleWardNameDDL
        }

        { Language.Label != '' && dispatch(WardNameDDLAPI({ data })) }
    }, [Language.Label, ZoneName.ID])

    const { WardDDLData } = useSelector(state => state.WardNameDDLData)

    const handleWardNameDDL = (WardDDLData) => {
        if (WardDDLData && WardDDLData.table && WardDDLData.table.length > 0) {
            let list = WardDDLData.table.map((item, index) => ({
                value: item.id,
                label: item.wardName,
            }))
            setWardName({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_WardID : Flag === 'Ward' ? list[0].value : HWard ? HWard : 0,
                Label: Flag === 'Update' ? rowData.wardName : Flag === 'Ward' ? list[0].label : WLable ? WLable : 'Select...'
                // Id: HWard,
                // Label: WLable,
            })
        }
        else {
            setWardName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>{t('Dashboard.Pcmc_Assets.Ward_no')} {Flag && <AstricSign />}</b></label>
            <Select
                // isClearable={isClearable === false ? false : true}
                // isRtl={isRtl}
                // className='ddl_width3'
                isDisabled={Flag === 'Update' && IsDisabled ? true : false}
                isSearchable
                maxMenuHeight={150}
                value={{ value: WardName.ID, label: WardName.Label }}
                onChange={(e) => {
                    e ?
                        setWardName({ ...WardName, ID: e.value, Label: e.label })
                        :
                        setWardName({ ...WardName, ID: 0, Label: "Select..." })
                    { setSectorName && setSectorName({ ...SectorName, ID: 0, Label: "Select ..." }) }

                }}
                options={WardName.DDL}
            />
        </div>
    )
}