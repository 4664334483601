import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";


// Get Table Data
export const ChlorineTableDataAPI = createAsyncThunk("ChlorineTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_WS_JunctionID,
        M_UserID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_WS_T_WS_WaterQualityChlorine_Select?FromDate=${FromDate ? FromDate :''}&ToDate=${ToDate ? ToDate : ''}&M_WardID=${M_WardID ? M_WardID : '0'}&M_WS_JunctionID=${M_WS_JunctionID ? M_WS_JunctionID : '0'}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ChlorineTableDataSlice = createSlice({
    name: "ChlorineTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ChlorineTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ChlorineTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ChlorineTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ChlorineTableDataReducer = ChlorineTableDataSlice.reducer


export const ChlorinePostDataAPI = createAsyncThunk("PumpingStationPostData", async ({ data }) => {
    const {
        T_WS_WaterQualityChlorineID,
        Chlorine,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_WaterQualityChlorineID", "4");
    formdata.append("InletChlorine", "0.10");
    formdata.append("OutChlorine", "0.20");
    formdata.append("M_UserID", "1");

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/Post_WEB_WS_T_WS_WaterQualityChlorine_Update`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const ChlorinePostDataSlice = createSlice({
    name: "ChlorinePostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ChlorinePostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ChlorinePostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ChlorinePostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ChlorinePostDataReducer = ChlorinePostDataSlice.reducer

// -----------------Delete API ---------------

export const ChlorineDeleteAPI = createAsyncThunk("ChlorineDelete", async ({ data }) => {
    const {
        T_WS_WaterQualityChlorineID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_WaterQualityChlorineID", T_WS_WaterQualityChlorineID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/WEB_WS_T_WS_WaterQualityChlorine_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const ChlorineDeleteSlice = createSlice({
    name: "ChlorineDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ChlorineDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ChlorineDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(ChlorineDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ChlorineDeleteReducer = ChlorineDeleteSlice.reducer