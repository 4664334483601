

import { useEffect } from 'react'
import Select from 'react-select'
import { ContractorNameDDLNewAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { useTranslation } from 'react-i18next'

export const ContractorNameDDLNew = (props) => {
    const { ContractorName, setContractorName, WorkPeriodID, M_WorkTypeID, M_AssetID, AssetType, M_ZoneID, rowData, Flag, IsPost, Language, IsClear, Flag1 } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_WorkTypeID: M_WorkTypeID,
            M_AssetID: M_AssetID,
            M_AssetTypeID: AssetType.ID,
            M_RoleID: RoleID,
            M_ZoneID: M_ZoneID,
            M_GD_WorkPeriodID: WorkPeriodID,
            M_UserID: UserID,
            token: token,
            Language: Language.Label,
            Flag: Flag
        }
        { Language.Label != '' && M_WorkTypeID && M_AssetID && AssetType.ID != '0' && dispatch(ContractorNameDDLNewAPI({ data })) }
    }, [IsPost, IsClear, Language.Label, M_WorkTypeID, M_AssetID, AssetType.ID, M_ZoneID, WorkPeriodID])

    const { ContractorNameDDLNewData } = useSelector(state => state.ContractorNameDDLNewData)

    useEffect(() => {
        handleContractorNameDDL()
    }, [ContractorNameDDLNewData])

    const handleContractorNameDDL = () => {
        if (ContractorNameDDLNewData && ContractorNameDDLNewData.table && ContractorNameDDLNewData.table.length > 0) {
            let list = ContractorNameDDLNewData.table.map((item, index) => ({
                value: item.m_ContractorID,
                label: item.contractorName,
            }))

            setContractorName({
                DDL: list,
                ID: Flag === 'Update' ? rowData.contractorNameID : Flag === 'Contractor' ? list[0].value : 0,
                Label: Flag === 'Update' ? rowData.contractorName : Flag === 'Contractor' ? list[0].label : 'Select...',
            })
        }
        else {
            setContractorName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (


        <div className="form-group ">
            <label className="d-block" htmlFor="NameofEmployee"><b>{t('Dashboard.Work_Assigned_by_Officials.Contractor_Name')}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width"
                isSearchable
                maxMenuHeight={150}
                value={{ value: ContractorName.ID, label: ContractorName.Label }}
                onChange={(e) => {
                    e ?
                        setContractorName({ ...ContractorName, ID: e.value, Label: e.label })
                        :
                        setContractorName({ ...ContractorName, ID: 0, Label: "Select..." })

                }}
                options={ContractorName.DDL}
            />
        </div>
    )
}