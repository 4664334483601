
import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useAuthState } from "../../Helper/Context";
import { useDispatch, useSelector } from "react-redux";
import { ContractorWiseWardSelectDDLAPI } from "../../Redux/DDLSlice";

var options = [];

export const WardNameMultiCheckBox = (props) => {
    const { IsPost,WardIDData, setWardIDData, setSelected, selected,ZoneName,WorkType,AssetType, ContractorName, Flag, APIChangeFlag,Language } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const handleData = (val) => {
        val.map(i =>
            options.push({ label: i.wardName, value: i.m_WardID })
        )
    }

    useEffect(() => {
        options = []
        const data = {
            M_ZoneID: ZoneName.ID,
            M_ContractorID: ContractorName.ID,
            M_WorkTypeID: WorkType.ID,
            M_AssetTypeID: AssetType.ID,
            M_UserID: UserID,
            Language: Language.Label,
            token: token,
            Flag: Flag,
            handleData: handleData,
            APIChangeFlag: APIChangeFlag,
        }
        // {
            // ContractorName.ID != 0 ?
                dispatch(ContractorWiseWardSelectDDLAPI({ data }))
                // :
                // options = []
        // }
    }, [ZoneName.ID,ContractorName.ID,WorkType.ID,AssetType.ID,Language.Label])

    const { WardSelectDDLData, isLoading } = useSelector(state => state.ContractorWiseWardSelectDDLData)


    const handleChange = (e) => {
        if (e && e.length > 0) {
            setSelected(e)
            setWardIDData(e.map(i => i.value))
        } else {
            setSelected([])
            setWardIDData([])
        }
    }


    return (
        <div>
            {/* <pre>{JSON.stringify(selected.value)}</pre> */}
            <MultiSelect
                options={options}
                value={selected}
                onChange={(e) => handleChange(e)}
                labelledBy="Select..."
                isLoading={isLoading}
            />
        </div>
    );
};
