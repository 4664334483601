import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useAuthState } from '../../../../../../Helper/Context';
import { DesignationPostDataAPI } from '../../../../../../Redux/SidebarMasterSlice/DesignationSlice';
import { ContractorNameDDL } from '../../../../../../Components/CommonDDL/ContractorNameDDL';
import { WardNameDataDDL } from '../../../../../../Components/CommonDDL/WardNameDataDDL';
import { AreaMasterPostDataAPI } from '../../../../../../Redux/SidebarMasterSlice/AreaMasterSlice';
import { AstricSign } from '../../../../../../Helper/AstricSign';


export default function AreaMasterPopUp(props) {

    const { handleOnCloseClick, open, apiFlag, rowData, EditIcon, Flag, handleIsPost,handleClear } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();

    // const [rowData, setRowData] = useState(Flag === 'Update' ? rowData : '')

    const [ContractorName, setContractorName] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_WardID : 0,
        Label: Flag === 'Update' ? rowData.wardName : "Select...",
    })

    const [AreaDetails, setAreaDetails] = useState(Flag === 'Update' ? rowData.areaDetails : '')

    const [IsOpen, setIsOpen] = useState(false)

    // useEffect(() => {
    //     setAreaDetails(Flag === 'Update' ? rowData.areaDetails : '')
    // }, [rowData, IsOpen])

    const handlePost = () => {
        const data = {
            M_SW_AreaDetailsID: Flag === 'Update' ? rowData.m_sW_AreaDetailsID : '0',
            M_WardID: WardName.ID,
            AreaDetails: AreaDetails,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleOnCloseClick:handleOnCloseClick
        }
        dispatch(AreaMasterPostDataAPI({ data }))
    }

    return (

        // <Popup
        //     onOpen={() => setIsOpen(!IsOpen)}
        //     contentStyle={{ top: '5%' }}
        //     className='master_pop_width '
        //     trigger={
        //         Flag === 'Update' ?
        //             <i className="fa-solid fa-pen-to-square"
        //             style={{ cursor: "pointer" }} title='Edit'
        //             >
        //             </i> :
        //             <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
        //                 style={{ color: "white", marginTop: '-2.5em' }}
        //             >
        //                 <text className="ml-2 poppins">Add</text>
        //             </i>
        //     }
        //     modal
        // >

        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="area_pop modal-dialog modal-xl">
                <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Area Master</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">

                                {/* <div className="col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <ContractorNameDDL
                                                    ContractorName={ContractorName}
                                                    setContractorName={setContractorName}
                                                />
                                            </div>

                                        </div> */}
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <WardNameDataDDL
                                            WardName={WardName}
                                            setWardName={setWardName}
                                            rowData={rowData}
                                            Flag={Flag}
                                        />
                                    </div>

                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Area <AstricSign />:</b></label>
                                        <input type="text"
                                            className="form-control"
                                            id="AreaDetails"
                                            value={AreaDetails}
                                            onChange={(e) => setAreaDetails(e.target.value)}

                                        />
                                    </div>

                                </div>

                            </div>

                            {/* <div className="mb-4 pt-4">
                                        <div className="float-end">
                                            <button className="submit_btn"
                                                onClick={() => (handlePost("Insert"))}

                                            >{Flag === "Update" ? "Update" : "Save"} </button>
                                            <button className=" cancel_btn ms-2" onClick={handleOnCloseClick} >Cancel</button>
                                        </div>
                                    </div> */}

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">
                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => (handlePost(),handleClear())}
                                        disabled={
                                            WardName.ID == 0 || AreaDetails == ''
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* )} */}
        </Popup>
    )

}