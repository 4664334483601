

import { useEffect } from 'react'
import Select from 'react-select'
import { ContractorNameReportDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { useTranslation } from 'react-i18next'

export const ContractorNameReportDDL = (props) => {
    const { ContractorName,
        setContractorName,
        ZoneName,
        WorkType,
        AssetType, setSelected,IsPost } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_UserID: UserID,
            token: token,
            M_ZoneID: ZoneName.ID,
            M_WorkTypeID: WorkType.ID,
            M_AssetTypeID: AssetType.ID
        }
        dispatch(ContractorNameReportDDLAPI({ data }))
    }, [ZoneName.ID, WorkType.ID, AssetType.ID,IsPost])

    const { ContractorNameReportDDLData } = useSelector(state => state.ContractorNameReportDDLData)

    useEffect(() => {
        handleContractorNameDDL()
    }, [ContractorNameReportDDLData])

    const handleContractorNameDDL = () => {
        if (ContractorNameReportDDLData && ContractorNameReportDDLData.length > 0) {
            let list = ContractorNameReportDDLData.map((item, index) => ({
                value: item.m_ContractorID,
                label: item.contractorName,
            }))

            setContractorName({
                DDL: list,
                ID: 0,
                Label: 'Select...',
            })
        }
        else {
            setContractorName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    const handleClearContractor = () => {
        setSelected([])
        setContractorName({ ...ContractorName, ID: 0, Label: "Select..." })
    }

    const handleSetContractor = (e) => {
        setSelected([])
        setContractorName({ ...ContractorName, ID: e.value, Label: e.label })
    }

    return (
        <div className="form-group">
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width"
                isSearchable
                maxMenuHeight={150}
                value={{ value: ContractorName.ID, label: ContractorName.Label }}
                onChange={(e) => {
                    e ?
                        handleSetContractor(e)
                        :
                        handleClearContractor()
                }}
                options={ContractorName.DDL}
            />
        </div>
    )
}