import Header from "../../../../Components/Header/Header";
import Sidebar from "../../../../Components/Sidebar/Sidebar";
import Select from 'react-select'
import React, { useEffect, useState } from 'react'



export default function GardenDailyPreWorkComplete() {

    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select Ward",
    // })

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    return (
        <div>
            <Header
                DashboardHeading="Garden Maintainance" />
            <div className="wrapper">
                <Sidebar />
                <div id="content">
                    <div className="page-header ">
                        <div className="page-block">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className="page-header-title">
                                        <h5 className="ps-2">DAILY PREVENTIVE WORK
                                        <button type="button" className="btn btn-primary mb-1 float-end mr-1">Export</button>

                                            {/* <span className="float-end fs-6">
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                            :
                                                            setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={SelectWard.DDL}
                                                />

                                            </span> */}
                                        </h5>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- [ Main Content ] start --> */}

                    <div className="row  m-1 py-2 pb-4 master_border">
                        <h5 className="ps-3 py-2 ">Completed</h5>
                        <div className="row m-0 ">
                            <div className="col-12 py-3  rounded rounded-2 shadow"
                                style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                <div className="col-12  ">

                                    <div className="row py-4 rounded2">


                                        <div className="col-12 col-lg-3">
                                            <label for=""><b>From Date :</b></label>
                                            <input type="date" className="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" />

                                        </div>
                                        <div className="col-12 col-lg-3">
                                            <label for=""><b>To Date :</b></label>
                                            <input type="date" className="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" />

                                        </div>
                                        <div className="col-12 col-lg-3">
                                            <label for=""><b>Prabhag No :</b></label>
                                            {/* <select className="form-select select2" aria-label="Default select example">
                                                <option selected></option>
                                                <option value="1"></option>
                                            </select> */}
                                            <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: WardName.ID, label: WardName.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setWardName({ ...WardName, ID: e.value, Label: e.label })
                                                        :
                                                        setWardName({ ...WardName, ID: 0, Label: "Select..." })

                                                }}
                                                options={WardName.DDL}
                                            />

                                        </div>

                                        <div className="col-12 col-lg-3" style={{ marginTop: "2.1em" }} >
                                            <button type="button" className="btn-clear btn btn-primary">Clear</button>
                                            <button type="button" className="btn-search btn btn-primary ml-2">Search</button>
                                        </div>
                                    </div>
                                    <div className="row mob_table table-responsive pt-3">
                                        <table  id="totalordergenerated"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="sr_no_col">Sr No.</th>
                                                    <th>Date</th>
                                                    {/* <th>Sector Name	 </th> */}
                                                    <th>Garden Name</th>
                                                    <th>Work Sub Type</th>
                                                    <th>Work Name</th>
                                                    <th>Before Work<br />Photo</th>
                                                    <th>After Work<br /> Photo</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>1</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="ps-5"><i className="fa-solid fa-eye"></i></td>
                                                    <td className="ps-5"><i className="fa-solid fa-eye"></i></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr>
                                                    <td>3</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr>
                                                    <td>4</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr>
                                                    <td>5</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>

                                        </table>

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



        </div>
    )
}