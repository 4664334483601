import MarkerClusterGroup from 'react-leaflet-cluster'
import { MapContainer, TileLayer, Marker, GeoJSON, Popup as Pop, FeatureGroup, Polyline, Tooltip, LayersControl, LayerGroup } from 'react-leaflet'
import "leaflet/dist/leaflet.css"
import L from 'leaflet';
import { useEffect, useState, useMemo, useRef } from 'react';
import Popup from 'reactjs-popup'

// const pin = "https://w7.pngwing.com/pngs/409/413/png-transparent-map-drawing-pin-map-marker-thumbnail.png";
const pin = "assets/img/marker.png"

const AllGardenMapView = (props) => {

    const { ShowAllGardenonMap, handleCloseAllMap, AllGardenonDataMap } = props

    const pinMB = L.icon({
        iconUrl: pin,
        iconSize: [27, 42],
        iconAnchor: [16, 40],
        popupAnchor: [0, -32],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });


    const markerData = [
        [18.50, 73.11],
        [18.709314735041126, 73.24093007633333],
        [18.326834494919822, 73.01220830472941],
        [18.746092403058576, 72.89410528292431],
        [18.57695068266547, 73.19897587409558]
    ]

    // const markerRef = useRef();

    // const eventHandlers = useMemo(
    //     () => ({
    //         mouseover() {
    //             console.log("over");
    //             if (markerRef) markerRef.current.openPopup();
    //         },
    //         mouseout() {
    //             console.log("out");
    //             if (markerRef) markerRef.current.closePopup();
    //         }
    //     }),
    //     []
    // );

    // console.log('ShowAllGardenonMap', ShowAllGardenonMap)

    return (
        <>
            <Popup className='assigns' open={ShowAllGardenonMap.show} closeOnDocumentClick={false}
                onClose={() => handleCloseAllMap()}
                contentStyle={{ overflowY: 'auto', overflowX: 'auto', height: '98vh' }}
            >

                <div className="call d-flex justify-content-between mb-2">
                    <h5> Garden Location</h5>

                    <span className="close" onClick={() => handleCloseAllMap()}
                        style={{ cursor: 'pointer' }}
                    >
                        &times;
                    </span>
                </div>
                <div className="modal-body" >

                    <MapContainer
                        // center={markerData && markerData[0]}
                        center={[ShowAllGardenonMap && ShowAllGardenonMap.data[0].assetLatitude, ShowAllGardenonMap && ShowAllGardenonMap.data[0].assetLongitude]}
                        zoom={12}
                        scrollWheelZoom={true}
                        style={{ height: "80vh", zIndex: 0 }}
                    >
                        {/* <TileLayer
                            url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
                            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                        /> */}


                        <LayersControl>
                            <LayersControl.BaseLayer name="Open Street Map">
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                            </LayersControl.BaseLayer>

                            {/* <LayersControl.BaseLayer name="Mapbox Map">
                                <TileLayer
                                    attribution='&copy; <a href="https://www.mapbox.com">Mapbox</a> '
                                    url="https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token={accessToken}"
                                    accessToken={"your token here"}
                                />
                            </LayersControl.BaseLayer> */}

                            {/* <LayersControl.BaseLayer name="Mapbox Map Satellite">
                                <TileLayer
                                    attribution='&copy; <a href="https://www.mapbox.com">Mapbox</a> '
                                    url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token={accessToken}"
                                    accessToken={"your token here"}
                                />
                            </LayersControl.BaseLayer> */}

                            <LayersControl.BaseLayer name="Google Map">
                                <TileLayer
                                    attribution="Google Maps"
                                    url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                                />
                            </LayersControl.BaseLayer>

                            <LayersControl.BaseLayer checked name="Google Map Satellite">
                                <LayerGroup>
                                    <TileLayer
                                        attribution="Google Maps Satellite"
                                        url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                                    />
                                    <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
                                </LayerGroup>
                            </LayersControl.BaseLayer>
                        </LayersControl>


                        <FeatureGroup>
                            {/* <MarkerClusterGroup
                                chunkedLoading
                            > */}
                                {ShowAllGardenonMap.data && ShowAllGardenonMap.data.length > 0 && ShowAllGardenonMap.data.map((item, i) => {
                                    return (
                                        <Marker
                                            key={i}
                                            position={[item.assetLatitude, item.assetLongitude]}
                                            icon={pinMB}
                                        // permanent={true}

                                        // ref={markerRef}
                                        // eventHandlers={eventHandlers}
                                        >
                                            <Tooltip direction="top" offset={[0, -41]} opacity={1} permanent>
                                                {item.assetName ? item.assetName : "-"}

                                                <Pop>

                                                    <div className='marker-popup py-2'>

                                                        <b style={{ fontSize: '15px' }}>{item.assetName ? item.assetName : "-"}</b>
                                                        <hr style={{ marginTop: '5px' }} />
                                                        {/* <div className='row m-1 my-2'>
                                                    <div className='col-5'>Prabhag No</div>
                                                    <div className='col-1'>:</div>
                                                    <div className='col-6'> <b>{item && item.wardName ? item.wardName : "-"}</b></div>
                                                </div> */}
                                                        {/* <div className='row m-1 my-2'>
                                                    <div className='col-5'>Sector Name</div>
                                                    <div className='col-1'>:</div>
                                                    <div className='col-6'> <b>{item && item.sectorName ? item.sectorName : "-"}</b></div>
                                                </div> */}
                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5'>Area Name</div>
                                                            <div className='col-1'>:</div>
                                                            <div className='col-6'> <b>{item && item.areaName ? item.areaName : "-"}</b></div>
                                                        </div>
                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5'>Graden Name</div>
                                                            <div className='col-1'>:</div>
                                                            <div className='col-6'> <b>{item && item.assetName ? item.assetName : "-"}</b></div>
                                                        </div>
                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5'>Lattitude</div>
                                                            <div className='col-1'>:</div>
                                                            <div className='col-6'> <b>{item && item.assetLatitude ? item.assetLatitude : "-"}</b></div>
                                                        </div>
                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5'>Longitude</div>
                                                            <div className='col-1'>:</div>
                                                            <div className='col-6'> <b>{item && item.assetLongitude ? item.assetLongitude : "-"}</b></div>
                                                        </div>
                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5'>Agency Name</div>
                                                            <div className='col-1'>:</div>
                                                            <div className='col-6' style={{ fontSize: '12px' }}> <b>{item && item.agencyName ? item.agencyName : "-"}</b></div>
                                                        </div>
                                                        <div className='row m-1 my-2'>
                                                            <div className='col-5'>Contract Period</div>
                                                            <div className='col-1'>:</div>
                                                            <div className='col-6'> <b>{item && item.fromYear ? item.fromYear : "-"}</b></div>
                                                        </div>
                                                        {/* <div className='row m-1 my-2'>
                                                    <div className='col-5'>Contractor Name</div>
                                                    <div className='col-1'>:</div>
                                                    <div className='col-6'> <b>{item && item.contractorName ? item.contractorName : "-"}</b></div>
                                                </div> */}
                                                        {
                                                            item.assetPhoto && item.assetPhoto != '' &&
                                                            <div className='row m-1'>
                                                                <div className='col-5'>Asset Image</div>
                                                                <div className='col-1'>:</div>
                                                                <div className='col-6'>
                                                                    {/* <img className='marker-popup-image' src={item.assetPhoto} onClick={() => window.open(item.assetPhoto)} /> */}
                                                                    <span className='cursor-pointer' onClick={() => window.open(item.assetPhoto)}>
                                                                        <i className="fa-solid fa-image fs-6 "></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* <p className="m-1">	Sector Name : <b>{item.sectorName ? item.sectorName : "-"}</b></p> 
                                                 <p className="m-1">	Area Name : <b>{item.areaName ? item.areaName : "-"}</b></p> 
                                                 <p className="m-1">	Graden Name : <b>{item.assetName ? item.assetName : "-"}</b></p> 
                                                 <p className="m-1"> Lattitude : <b>{item.assetLatitude ? item.assetLatitude : "-"}</b></p> 
                                                 <p className="m-1">	Longitude: <b>{item && item.assetLongitude ? item.assetLongitude : "-"}</b></p> 
                                                 <p className="m-1">	Contractor Name: <b>{item && item.contractorName ? item.contractorName : "-"}</b></p> 
                                                 {
                                                    item.assetPhoto && item.assetPhoto != '' &&
                                                    <p className="m-1">	Asset Image: <p><img className='marker-popup-image' src={item.assetPhoto} onClick={() => window.open(item.assetPhoto)} /></p></p>
                                                }  */}

                                                    </div>
                                                </Pop>
                                            </Tooltip>

                                        </Marker>
                                    )
                                })}
                            {/* </MarkerClusterGroup> */}
                        </FeatureGroup>
                    </MapContainer>

                </div>

            </Popup >
        </>
    )
}

export default AllGardenMapView