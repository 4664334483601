import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { WardPostDataAPI } from '../../../../Redux/SidebarMasterSlice/WardSlice';
import { ZoneDataDDLS } from '../../../../Components/CommonDDL/ZoneDataDDLS';
import { RegExOnlyText } from '../../../../Helper/regEx/RegExOnlyText';
import { AstricSign } from '../../../../Helper/AstricSign';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';


export default function WardMasterPopup(props) {

    const { handleOnCloseClick, open, apiFlag, rowData, handleIsPost, item, Flag,Language } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    // const [rowData, setRowData] = useState(item)

    const [searchParams] = useSearchParams()
    let Lang = searchParams.get("Lang")

    const [ZoneName, setZoneName] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_ZoneID : 0,
        Label: Flag === 'Update' ? rowData.zoneName : "Select...",

    })

    const [WardName, setWardName] = useState(Flag === 'Update' ? rowData.wardName : '')
    const [IsOpen, setIsOpen] = useState(false)

    // useEffect(() => {
    //     setWardName(Flag === 'Update' ? rowData.wardName : '')
    // }, [item, IsOpen])

    const handleClearField = () => {

        if (Flag != 'Update') {
            setWardName('')
        }
    }

    const [IsValidText, setIsValidText] = useState(false)
    const handleCheckText = (e) => {
        setWardName(e.target.value)
        const IsValid = RegExOnlyText(e.target.value)
        setIsValidText(IsValid)
        return IsValid
    }

    const handlePost = () => {
        const data = {
            M_WardID: Flag === 'Update' ? rowData.m_WardID : '0',
            M_ZoneID: ZoneName.ID,
            WardName: WardName,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleClearField: handleClearField,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(WardPostDataAPI({ data }))

    }
    return (

        // <Popup
        //     onOpen={() => setIsOpen(!IsOpen)}
        //     contentStyle={{ top: '5%' }}
        //     trigger={
        //         Flag === 'Update' ?
        //             <i className="fa-solid fa-pen-to-square" style={{ cursor: "pointer" }} title='Edit'></i>
        //             :
        //             <i className="fa-solid fa-plus btn btn-secondary float-end mb-2" style={{ color: "white", marginTop: '-2.5em' }}>
        //                 <text className="ml-2 poppins">Add</text>
        //             </i>
        //     }
        //     modal>
        //     {
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="">
                <div className="area_pop modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">{t('AdminDashboard.Admin_Sidebar_Name.Ward_Master')}</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">

                                <div className="col-lg-6">
                                    <ZoneDataDDLS
                                        ZoneName={ZoneName}
                                        setZoneName={setZoneName}
                                        rowData={rowData}
                                        Flag={Flag}
                                        Language={Language}
                                    />
                                </div>

                                <div className="col-12 col-lg-6">
                                    <label for=""><b>Prabhag No <AstricSign /> :</b></label>
                                    <input type="text" className="form-control"
                                        id="exampleInputEmail1" name='EmployeeName'
                                        aria-describedby="emailHelp"
                                        value={WardName}
                                        onChange={(e) => setWardName(e.target.value)}
                                    // onChange={(e) => handleCheckText(e)}
                                    />
                                    {/* {
                                        IsValidText && <text style={{ color: 'red' }}>Please enter Alphabets characters only</text>
                                    } */}

                                </div>
                            </div>

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                        disabled={
                                            ZoneName.ID == 0 || WardName == ''
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* )} */}


        </Popup>
    )

}