import { BaseUrl } from "../BaseUrl"

export const LogoHeader = () => {

    // const imagePath = "../assets/img/PCMC_Logos-01.png" 
    const imagePath = BaseUrl == "https://apiamravatimc.cdat.work" ? "../assets/img/amravati logo-01.png" : "../assets/img/PCMC_Logos-01.png"
    const style = BaseUrl == "https://apiamravatimc.cdat.work" ? "px-5 mt-5 mt-lg-3 pt-1" : "px-5 mt-5 mt-md-0"


    return (
        <div className={style}>
            <img src={imagePath} alt="" srcset="" className="login_logo mt-lg-n4 ml-sm-n2" />
        </div>
    )
}

export const MainLogoWithName = () => {

    const imagePath = BaseUrl == "https://apiamravatimc.cdat.work" ? "../assets/img/amravati.png" : "../assets/img/PCMC_Logo-01.png"
    const namePath = BaseUrl == "https://apiamravatimc.cdat.work" ? "अमरावती महानगरपालिका" : "पिंपरी चिंचवड महानगरपालिका"
    const style = BaseUrl == "https://apiamravatimc.cdat.work" ? "logo_width_Amravati mobile_logo" : "logo_width mobile_logo"

    return (
        <div className="text-center pt-lg-0">
            <img src={imagePath} alt="" srcset="" className={style} />
            <h4 className="pt-2 pb-1 fw-bold py-2 py-md-2" style={{ color: 'black' }}>{namePath}</h4>
        </div>
    )
}

export const AppHeaderName = () => {

    const namePath = BaseUrl == "https://apiamravatimc.cdat.work" ? "AMRAVATI DAKSHA" : "PCMC DAKSHA"
    const style = BaseUrl == "https://apiamravatimc.cdat.work" ? "text-white py-2 text-center text-font fw-bold text-size-xl pt-4 mt-3 pt-md-0" : "text-white py-2 text-center text-font fw-bold text-size-xl pt-3 pt-md-0"

    return (
        <h2 className={style}  >
            <span>{namePath} - </span>
            <span style={{ fontSize: '24px', color: 'white' }}>
                उद्यान व
                वृक्षसंवर्धन कामांचे
                सनियंत्रण संगणक प्रणाली
            </span>
            <span className="ps-4" style={{ fontSize: '16px', color: 'white' }}>   वेब व मोबाईल आधारित</span>
        </h2>



    )
}

export const MainImageSection = () => {

    const style = BaseUrl == "https://apiamravatimc.cdat.work" ? "col-12 col-md-8 amaravti_bg2 mx-auto pcmc_shadow fixed-height" : "col-12 col-md-8 pcmc_bg2 mx-auto pcmc_shadow fixed-height"


    return (
        <div className={style} style={{ objectFit: 'contain' }}>
        </div>
    )
}