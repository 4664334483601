
import React, { useEffect, useState } from 'react'
import Header from "../../../../Components/Header/Header"
import Sidebar from "../../../../Components/Sidebar/Sidebar"
import Select from 'react-select'
import { useAuthState } from "../../../../Helper/Context";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AssetTypeDataDDL } from '../../../../Components/CommonDDL/AssetTypeDataDDL';
import { WardNameDataDDL } from '../../../../Components/CommonDDL/WardNameDataDDL';
import { MonthDataDDL } from '../../../../Components/CommonDDL/MonthDataDDL';
import { FinancialYearDataDDL } from '../../../../Components/CommonDDL/FinancialYearDataDDL';
import { ReopenWorkProgressTableDataAPI } from '../../../../Redux/GardenDashboardSlice/ReopenWorkProgressSlice';
import moment from "moment";
import ViewURL from '../../../../Components/ViewURL/ViewURL';
import { Loading } from '../../../../Helper/Loading';
import { Pegination } from '../../../../Components/Pegination/Pegination';
import { WorkAssignByDDL } from '../../../../Components/CommonDDL/WorkAssignByDDL';
import { DashBoardScreenWardDDL } from '../../../../Components/CommonDDL/DashboardScreenWardDDL';


export default function GardenReopenPending() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let FlagName = searchParams.get("Flag")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    // console.log(FlagName)

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: HYear && HYear ? HYear : '0',
        Label: YLable && YLable ? YLable : "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: HMonth && HMonth ? HMonth : '0',
        Label: MLable && MLable ? MLable : "Select...",

    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })
    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [WorkAssignedBy, setWorkAssignedBy] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [Language])

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [ImageHeading, setImageHeading] = useState('')
    const [FromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))


    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setFromDate(moment().startOf('month').format('YYYY-MM-DD'))
        setToDate(moment().format('YYYY-MM-DD'))
        setWardName({
            ...WardName,
            ID: WardName.DDL[0].value,
            Label: WardName.DDL[0].label,
        })
        setAssetType({
            ...AssetType,
            ID: 0,
            Label: 'Select...',
        })
        setWorkAssignedBy({
            ...WorkAssignedBy,
            ID: 0,
            Label: 'Select...',
        })
        // console.log("call")
    }

    const handlePost = () => {
        setIsPost(!IsPost)
    }


    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }


    useEffect(() => {
        const data = {
            FromDate: FromDate,
            ToDate: ToDate,
            M_WardID: WardName.ID,
            WorkAssignedByID: WorkAssignedBy.ID,
            M_AssetTypeID: AssetType.ID,
            M_UsersID: UserID,
            token: token,
            From: From,
            To: To,
            Flag: ApiFlag,

        }
        if (WardName.ID) {
            dispatch(ReopenWorkProgressTableDataAPI({ data }))
        }

    }, [IsPost, To, IsClear, WardName.ID, FromDate, ToDate, AssetType.ID, WorkAssignedBy.ID])

    const { tableData, isLoading } = useSelector(state => state.ReopenWorkProgressTableData)

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    DashboardHeading="Garden Maintainance"
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId} Language={Language} />
                    <div id="" className='content1 content'>
                        <div className="page-header ">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        {/* <div className="page-header-title">
                                            <h5 className="ps-2 data_mob"> */}

                                        {/* <span className="float-end fs-6">
                                                    <select id="cars" name="carlist" form="carform" className="ward_select">
                                                        <option value="volvo">Select Ward</option>
                                                        <option value="saab">Airoli</option>
                                                        <option value="opel">Airoli</option>
                                                        <option value="audi">Airoli</option>
                                                    </select>

                                                </span> */}
                                        {/* </h5>

                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='data_mob'>
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className='col-8'>
                                    <h5 className="ps-1 pt-1 ">Reopen Work Progress {searchName}</h5>
                                </div>
                                <div className='col-4'>
                                    <button type="button" className="btn btn-primary mb-1 float-end">Export</button>

                                </div>
                                <div className="row m-0 mt-1">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                        <div className="col-12  ">

                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block fw-bold" htmlFor="NameofDepartment">From Date :</label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="FromDate"
                                                            value={FromDate}
                                                            onChange={(e) => (setFromDate(e.target.value), setToDate(''))} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block fw-bold" htmlFor="NameofDepartment">To Date :</label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="ToDate"
                                                            value={ToDate}
                                                            onChange={(e) => setToDate(e.target.value)} />

                                                    </div>
                                                </div>

                                                {/* <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"> <b>From Date :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="FromDate"
                                                            value={FromDate}
                                                            onChange={(e) => (setFromDate(e.target.value), setToDate(''))} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>To Date :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="ToDate"
                                                            value={ToDate}
                                                            onChange={(e) => setToDate(e.target.value)} />

                                                    </div>
                                                </div> */}

                                                <div className="col-12 col-md-6 col-lg-3 ">
                                                    {/* <WardNameDataDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        HWard={HWard}
                                                        WLable={WLable}
                                                        Language={Lang}
                                                    /> */}

                                                    <DashBoardScreenWardDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        WLable={WLable}
                                                        HWard={HWard}
                                                        // flag={flag}
                                                        Language={Language}
                                                        M_ZoneID={1}
                                                        Flag='ward'
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3 " >
                                                    <WorkAssignByDDL
                                                        WorkAssignedBy={WorkAssignedBy}
                                                        setWorkAssignedBy={setWorkAssignedBy}
                                                        Flag={FlagName}
                                                        Language={Language}
                                                    />

                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3 ">
                                                    <AssetTypeDataDDL
                                                        AssetType={AssetType}
                                                        setAssetType={setAssetType}
                                                        Language={Language}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2 mt-lg-4 mt-md-4" >
                                                    {/* <button type="button" className="btn-search btn btn btn-secondary mr-1"
                                                        onClick={() => handleSearch()}
                                                    >
                                                        Search
                                                    </button> */}
                                                    <button type="button" className="btn-clear btn btn-primary"
                                                        onClick={handleClear}
                                                    >
                                                        Clear</button>
                                                </div>
                                            </div>

                                            <div className="row mob_table table-responsive pt-1">
                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            {
                                                                searchName == 'Reopen Pending' ?
                                                                    <>
                                                                        <th>Work Done Date</th>
                                                                        <th>Reopen Date</th>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <th>Work Reopen Date</th>
                                                                        <th>Reopen Completed Work Date</th>
                                                                    </>

                                                            }

                                                            <th>Asset Name</th>
                                                            {/* <th>Sector Name	 </th> */}
                                                            <th>Area</th>
                                                            <th>Work Name </th>
                                                            {
                                                                searchName == 'Reopen Pending' ?
                                                                    <>
                                                                        <th>After Work Photo</th>
                                                                        <th>Reopen Work Photo</th>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <th>Reopen Work Photo</th>
                                                                        <th>Reopen Completed Work Photo</th>
                                                                    </>

                                                            }

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) =>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                    {
                                                                        searchName == 'Reopen Pending' ?
                                                                            <>
                                                                                <td>{item.workDate ? moment(item.workDate).format("DD-MM-YYYY") : '-'}</td>
                                                                                <td>{item.reopenWorkDate ? moment(item.reopenWorkDate).format("DD-MM-YYYY") : '-'}</td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td>{item.reopenWorkDate ? moment(item.reopenWorkDate).format("DD-MM-YYYY") : '-'}</td>
                                                                                <td>{item.reopenWorkCompletedDate ? moment(item.reopenWorkCompletedDate).format("DD-MM-YYYY") : '-'}</td>
                                                                            </>

                                                                    }

                                                                    <td>{item.assetName ? item.assetName : '-'}</td>
                                                                    {/* <td>{item.sectorName ? item.sectorName : '-'}</td> */}
                                                                    <td>{item.areaName ? item.areaName : '-'}</td>
                                                                    <td>{item.workname ? item.workname : '-'}</td>
                                                                    {
                                                                        searchName == 'Reopen Pending' ?
                                                                            <>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                    {
                                                                                        (item.afterAssignWorkPhoto == null) ?
                                                                                            <span>-</span>
                                                                                            :
                                                                                            <>
                                                                                                <i className="fa-solid fa-eye" title='View'
                                                                                                    style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                                    onClick={() => (
                                                                                                        handleShowImage(item.afterAssignWorkPhoto),
                                                                                                        setImageHeading('After Work Photo')
                                                                                                    )}
                                                                                                ></i>
                                                                                            </>
                                                                                    }
                                                                                </td>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                    {
                                                                                        (item.reopenWorkPhoto == null) ?
                                                                                            <span>-</span>
                                                                                            :
                                                                                            <>
                                                                                                <i className="fa-solid fa-eye" title='View'
                                                                                                    style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                                    onClick={() => (
                                                                                                        handleShowImage(item.reopenWorkPhoto),
                                                                                                        setImageHeading('Reopen Work Photo')
                                                                                                    )}
                                                                                                ></i>
                                                                                            </>
                                                                                    }

                                                                                </td>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                    {
                                                                                        (item.reopenWorkPhoto == null) ?
                                                                                            <span>-</span>
                                                                                            :
                                                                                            <>
                                                                                                <i className="fa-solid fa-eye"
                                                                                                    style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                                    onClick={() => (
                                                                                                        handleShowImage(item.reopenWorkPhoto),
                                                                                                        setImageHeading('Reopen Work Photo')
                                                                                                    )}
                                                                                                ></i>
                                                                                            </>
                                                                                    }
                                                                                </td>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                    {
                                                                                        (item.reopenWorkCompletedPhoto == null) ?
                                                                                            <span>-</span>
                                                                                            :
                                                                                            <>
                                                                                                <i className="fa-solid fa-eye"
                                                                                                    style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                                    onClick={() => (
                                                                                                        handleShowImage(item.reopenWorkCompletedPhoto),
                                                                                                        setImageHeading('Reopen Completed Work Photo')
                                                                                                    )}
                                                                                                ></i>
                                                                                            </>
                                                                                    }

                                                                                </td>
                                                                            </>

                                                                    }

                                                                </tr>
                                                            ) :
                                                                <>No Found data</>
                                                        }
                                                    </tbody>

                                                </table>

                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {
                    showImagePopup ?
                        <ViewURL
                            Heading={ImageHeading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''


                }
            </div>
        </>
    )
}