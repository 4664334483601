import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import { DepartmentDeleteAPI } from '../../../../Redux/MasterSlice/GardenMasterSlice';
import { DepDeleteAPI } from '../../../../Redux/SidebarMasterSlice/DepartmentSlice';


export default function DeletePopup({ handleCloseOnClick, open, item, handleIsPost, handleDeleteApi }) {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();
    const [rowData, setRowData] = useState(item)
    return (

        <Popup
            onOpen={() => setRowData(item)}
            contentStyle={{ width: '30%', top: '5%' }}
            trigger={
                <i style={{ cursor: "pointer" }} title='Delete' className="fa-solid fa-trash-can ml-3"></i>
            }
            modal>

            {
                close => (
                    // n_popup-content 
                    <div className="modal-dialog modal-dialog-scrollable modal-xl delete_mob">
                        <div className="modal-content">
                            {/* <div id="delete_popup" className="delete_popup-container" style={{ overflow: "auto" }}>
                                <div className="delete_popup-content "> */}
                            <div  >
                                <div className='mr-3 mt-2'>
                                    <button onClick={() => close()} className="btn-close cross_btn" type="button" />
                                </div>
                                <h5 className=" fw-bold blue_color text-center pt-5 pb-1"> Do you want to delete?</h5>

                                <div className="row  my-3  px-2">
                                    <div className="pt-3">
                                        <h6 className="text-center">
                                            <button className="submit_btn"
                                                onClick={() => (handleDeleteApi(item), close())}
                                            >Yes</button>
                                            <button className="cancel_btn ms-2" onClick={() => close()}>No</button>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            {/* </div>

                            </div> */}

                        </div>
                    </div>

                )}
        </Popup>
    )

}