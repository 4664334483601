import React from "react";
import { useNavigate } from "react-router-dom";



export default function SecurityWorksCard({ count, title, navLink, cardName, imgPath, SecurityWorkAssignedByOficialsNavigate, RoleID }) {
    const navigate = useNavigate()

    const handleSecurityWorkAssignedByOficialsNavigate = () => {
        navigate(navLink)
    }
    return (
        <div style={{ cursor: 'pointer' }} className="mb-2 p-3 shadow blue_borders bg-white mb-md-3" onClick={() => SecurityWorkAssignedByOficialsNavigate(title, RoleID)}>
            <div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                <div className="text-center" >
                    <div className="d-flex justify-content-between align-items-center">
                        <h4>{count}</h4>
                        <img className={cardName} style={{ height: '50px', width: '35px' }} src={imgPath} alt="" />
                    </div>

                </div>
                <div style={{ borderLeft: '2px solid #164778' }}>
                    <h6 className="text-black ml-3"><b>{title}</b></h6>
                </div>
            </div>
        </div>

    )
}