
import { useEffect } from 'react'
import Select from 'react-select'
import { DesignationDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'

export const DesignationDDL = (props) => {
    const { Designation, setDesignation, CropNameDashboardData, rowData, Flag, IsPost } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token }
        dispatch(DesignationDDLAPI({ data }))
    }, [IsPost])

    const { DesignationData } = useSelector(state => state.DesignationDDLData)

    useEffect(() => {
        handleDesignationDDL()
    }, [DesignationData])

    const handleDesignationDDL = () => {
        if (DesignationData && DesignationData.table && DesignationData.table.length > 0) {
            let list = DesignationData.table.map((item, index) => ({
                value: item.m_DesignationID,
                label: item.designationName,
            }))

            setDesignation({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_DesignationID : 0,
                Label: Flag === 'Update' ? rowData.designationName : "Select...",
            })
            // setDesignation({ ...Designation, DDL: list })
        }
        else {
            setDesignation({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }
    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b> {t('AdminDashboard.Admin_Sidebar_Name.Designation')} { Flag && <AstricSign />}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width"
                isSearchable
                maxMenuHeight={150}
                value={{ value: Designation.ID, label: Designation.Label }}
                onChange={(e) => {
                    e ?
                        setDesignation({ ...Designation, ID: e.value, Label: e.label })
                        :
                        setDesignation({ ...Designation, ID: 0, Label: "Select..." })

                }}
                options={Designation.DDL}
            />
        </div>
    )
}