import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { WorkTypePostDataAPI } from '../../../../Redux/SidebarMasterSlice/WorkTypeSlice';
import { AstricSign } from '../../../../Helper/AstricSign';


export default function WorkTypePopup(props) {

    const { handleOnCloseClick, open, item, EditIcon, Flag, handleIsPost, apiFlag, rowData, } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();


    const [WorkTypeName, setWorkTypeName] = useState(Flag === 'Update' ? rowData.workTypeName : '')
    const [IsOpen, setIsOpen] = useState(false)

    // useEffect(() => {
    //     setWorkTypeName(Flag === 'Update' ? item.workTypeName : '')
    // }, [item, IsOpen])

    const handlePost = () => {
        const data = {
            M_WorkTypeID: Flag === 'Update' ? rowData?.m_WorkTypeID : '0',
            WorkTypeName: WorkTypeName,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleOnCloseClick:handleOnCloseClick
        }
        dispatch(WorkTypePostDataAPI({ data }))
    }


    return (

        // <Popup
        //     onOpen={() => setIsOpen(!IsOpen)}
        //     contentStyle={{ top: '5%' }}
        //     trigger={

        //         // <i className="fa-solid fa-plus btn btn-secondary mb-2 float-end" style={{ color: "white", marginTop: '-2.5em' }}>
        //         //     <text className="ml-2 poppins">Add</text>
        //         // </i>
        //         EditIcon === 'Update' ?
        //             <i className="fa-solid fa-pen-to-square"
        //             style={{ cursor: "pointer" }} title='Edit'
        //             // onClick={() => console.log(rowData)}
        //             >
        //             </i> :
        //             <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
        //                 style={{ color: "white", marginTop: '-2.5em' }}
        //             >
        //                 <text className="ml-2 poppins">Add</text>
        //             </i>
        //     }
        //     modal>
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="area_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Work Type</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>
                            <div className="row details-row">
                                <div className="col-12 col-lg-6 ">
                                    <label htmlFor="WorkTypeName"><b>Work Type <AstricSign /> :</b></label>
                                    <input type="text"
                                        className="form-control"
                                        id="WorkTypeName"
                                        value={WorkTypeName}
                                        onChange={(e) => setWorkTypeName(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">
                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                        disabled={
                                            WorkTypeName === ''
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* )} */}


        </Popup>
    )

}