import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function WorkuploadCard({ count, heading, headstyle, cardName, imgPath, navLink, countText, workUploadNavigate }) {
    const navigate = useNavigate()

    const handleWorkUploadCardClick = () => {
        navigate(navLink)
    }
    return (
        <div style={{cursor: 'pointer' }} className="mb-1 p-3 shadow blue_borders bg-white mb-md-2 daily-card-width" onClick={() => workUploadNavigate(heading)}>
            <div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                <div className="text-center pb-4" >
                    <div className="d-flex justify-content-between align-items-center">
                        <h4>{count}</h4>
                        <img className={cardName} style={{ height: '40px', width: '40px' }} src={imgPath} alt="" />
                    </div>

                </div>
                <div style={{ borderLeft: '2px solid #d6bea7' }}>
                    <h6 className="text-black ml-3"><b>{heading}</b></h6>
                </div>
            </div>
        </div>


    )
}


