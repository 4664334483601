import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useAuthState } from '../../../../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { WardNameDataDDL } from '../../../../../../../Components/CommonDDL/WardNameDataDDL';
import { SeveragePumpingStationDataDDL } from '../../../../../../../Components/CommonDDL/SeveragePumpingStationDataDDL';
import { PumpingStationMasterPostDataAPI } from '../../../../../../../Redux/SeverageDashboardSlice/SeveragePumpingStationMasterSlice';
import { AstricSign } from '../../../../../../../Helper/AstricSign';


export default function SeweragePumpingStationPopup(props) {

    const { handleOnCloseClick, open, EditIcon, Flag, GardenMasterFlag, popupHeading, M_AssetTypeID, handleIsPost, apiFlag, rowData } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();

    // const [rowData, setRowData] = useState(Flag === 'Update' ? rowData : '')
    const [IsOpen, setIsOpen] = useState(false)
    const [IsClear, setIsClear] = useState(false)

    const [SeverageWorkType, setSeverageWorkType] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_sW_WorkTypeID : 0,
        Label: Flag === 'Update' ? rowData.workType : "Select...",
    })

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_WardID : 0,
        Label: Flag === 'Update' ? rowData.wardName : "Select...",

    })

    const [PumpingStation, setPumpingStation] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_sW_PumpingStationID : 0,
        Label: Flag === 'Update' ? rowData.pumpingStation : "Select...",

    })

    const [PumpingStationTextField, setPumpingStationTextField] = useState({
        // pumpingStation: Flag === 'Update' ? rowData.pumpingStation : '',
        pumpSetDetails: Flag === 'Update' ? rowData.pumpSetDetails : '',
    })

    const handlePumpingStationTextField = (e) => {
        setPumpingStationTextField({ ...PumpingStationTextField, [e.target.name]: e.target.value })
    }

    // useEffect(() => {
    //     setPumpingStationTextField({
    //         // pumpingStation: Flag === 'Update' ? rowData.pumpingStation : '',
    //         pumpSetDetails: Flag === 'Update' ? rowData.pumpSetDetails : '',
    //     })
    // }, [rowData, IsOpen])

    const handleClear = () => {
        setIsClear(!IsClear)
        setWardName({
            ...WardName,
            ID: 0,
            Label: 'Select...',
        })
        setPumpingStation({
            ...PumpingStation,
            ID: 0,
            Label: 'Select...',
        })
        setPumpingStationTextField({
            pumpSetDetails:''
        })
    }

    const handlePost = () => {
        const data = {
            M_SW_PumpingStationWisePumpSetDetailsID: Flag === 'Update' ? rowData.m_sW_PumpingStationWisePumpSetDetailsID : '0',
            M_SW_PumpingStationID: PumpingStation.ID,
            PumpSetDetails: PumpingStationTextField.pumpSetDetails,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(PumpingStationMasterPostDataAPI({ data }))
    }

    // const [showPopup, setShowPopup] = useState(false);
    return (

        // <Popup
        //     className=''
        //     onOpen={() => setIsOpen(!IsOpen)}
        //     contentStyle={{ width: '55%', height: '60%', top: '5%' }}
        //     trigger={
        //         Flag === 'Update' ?
        //             <i className="fa-solid fa-pen-to-square" style={{ cursor: 'pointer' }} title='Edit'></i>
        //             :
        //             <i className="fa-solid fa-plus float-end btn btn-secondary mb-1"
        //                 style={{ color: "white" }}
        //             >
        //                 <text className="ml-2 poppins">Add</text>
        //             </i>

        //     } modal>

        //     {
        //         close => (
        // n_popup-content 
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="severage_mob_workname_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Pumping Set</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>
                            <div className="row details-row">
                                <div className="col-12 col-lg-6 col-md-6">
                                    <WardNameDataDDL
                                        WardName={WardName}
                                        setWardName={setWardName}
                                        rowData={rowData}
                                        Flag={Flag}
                                        IsDisabled={true}
                                    />

                                </div>
                                <div className="col-md-6  col-md-6  col-lg-6">
                                    <SeveragePumpingStationDataDDL
                                        PumpingStation={PumpingStation}
                                        setPumpingStation={setPumpingStation}
                                        WardName={WardName.ID}
                                        rowData={rowData}
                                        Flag={Flag}

                                    />
                                </div>
                                <div className="col-md-6 col-md-12 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Pump set Details <AstricSign /> :</b></label>
                                        <input type="text" className="form-control"
                                            id="exampleInputEmail1"
                                            name='pumpSetDetails'
                                            aria-describedby="emailHelp"
                                            value={PumpingStationTextField.pumpSetDetails}
                                            onChange={(e) => handlePumpingStationTextField(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                    // disabled={
                                    //     Designation.ID == 0 || EmployeeField.EmployeeName == ''
                                    // }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div> */}

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">
                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => (handlePost(),handleClear())}
                                        disabled={
                                            WardName.ID == 0 || PumpingStation.ID == 0 || PumpingStationTextField.pumpSetDetails == ''
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {/* )} */}
        </Popup>
    )

}