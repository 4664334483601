import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";

// Get Table Data
export const TurbidityTableDataAPI = createAsyncThunk("TurbidityTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_WS_LocationID,
        M_UserID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_WS_T_WS_WaterQualityTurbidity_Select?FromDate=${FromDate ? FromDate : ''}&ToDate=${ToDate ? ToDate : ''}&M_WardID=${M_WardID ? M_WardID :'0'}&M_WS_LocationID=${M_WS_LocationID ? M_WS_LocationID : '0'}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const TurbidityTableDataSlice = createSlice({
    name: "TurbidityTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(TurbidityTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(TurbidityTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(TurbidityTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const TurbidityTableDataReducer = TurbidityTableDataSlice.reducer


export const TurbidityPostDataAPI = createAsyncThunk("PumpingStationPostData", async ({ data }) => {
    const {
        T_WS_WaterQualityTurbidityID,
        Turibidity,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_WaterQualityTurbidityID",T_WS_WaterQualityTurbidityID);
    formdata.append("Turibidity", Turibidity);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/Post_WEB_WS_T_WS_WaterQualityTurbidity_Update`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const TurbidityPostDataSlice = createSlice({
    name: "TurbidityPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(TurbidityPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(TurbidityPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(TurbidityPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const TurbidityPostDataReducer = TurbidityPostDataSlice.reducer

// -----------------Delete API ---------------

export const TurbidityDeleteAPI = createAsyncThunk("TurbidityDelete", async ({ data }) => {
    const {
        T_WS_WaterQualityTurbidityID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_WaterQualityTurbidityID", T_WS_WaterQualityTurbidityID);
    formdata.append("M_UserID", M_UserID);
    
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/APKDashboard/WEB_WS_T_WS_WaterQualityTurbidity_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const TurbidityDeleteSlice = createSlice({
    name: "TurbidityDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(TurbidityDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(TurbidityDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(TurbidityDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const TurbidityDeleteReducer = TurbidityDeleteSlice.reducer