import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { DepartmentPostDataAPI } from '../../../../Redux/SidebarMasterSlice/DepartmentSlice';
import { useAuthState } from '../../../../Helper/Context';


export default function DepartmentPopup({ handleCloseOnClick, item, EditIcon, Flag, handleIsPost }) {
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();

    const [rowData, setRowData] = useState(item)

    const [DepartmentName, setDepartmentName] = useState(Flag === 'Update' ? rowData.departmentName : '')
    const [DepartmentCode, setDepartmentCode] = useState(Flag === 'Update' ? rowData.departmentCode : '')

    // console.log(Flag === 'Update' ? rowData.departmentName : '')
    // console.log(rowData)

    const handlePost = () => {
        const data = {
            M_DepartmentID: Flag === 'Update' ? rowData?.id : '0',
            DepartmentCode: DepartmentCode,
            DepartmentName: DepartmentName,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost
        }
        dispatch(DepartmentPostDataAPI({ data }))
    }

    return (

        <Popup
            onOpen={() => setRowData(item)}
            contentStyle={{ top: '5%' }}
            className='master_pop_width'
            trigger={
                EditIcon === 'Update' ?
                    <i className="fa-solid fa-pen-to-square"
                    title='Delete'   style={{ cursor: 'pointer' }}
                    // onClick={() => console.log(rowData)}
                    >
                    </i> :
                    <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                        style={{ color: "white", marginTop: '-2.5em' }}
                    >
                        <text className="ml-2 poppins">Add</text>
                    </i>
            }
            modal
        >

            {
                close => (
                    // n_popup-content 
                    <div className="modal-dialog modal-dialog-scrollable modal-xl  " >
                        <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                            <div className="modal-header pl-4 p-1">
                                <h4 className="modal-title" id="staticBackdropLabel">Department</h4>
                                <button
                                    onClick={() => close()}
                                    className="btn-close m-1 mr-3" type="button" />
                            </div>

                            <div className="modal-body">
                                <div className='inner-modal-body'>

                                    <div className="row details-row">

                                        <div className="col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment"><b>Department Name :</b></label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="DepartmentName"
                                                    value={DepartmentName}
                                                    onChange={(e) => setDepartmentName(e.target.value)}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment"><b>Department Code :</b></label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="DepartmentCode"
                                                    value={DepartmentCode}
                                                    onChange={(e) => setDepartmentCode(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="mb-4 pt-4">
                                        <div className="float-end">
                                            <button className="submit_btn"
                                                onClick={() => (handlePost("Insert"), close())}

                                            >{EditIcon === "Update" ? "Update" : "Save"} </button>
                                            <button className=" cancel_btn ms-2" onClick={() => close()} >Cancel</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                )}


        </Popup>
    )

}