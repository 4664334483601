

export const WorkUploadChart = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} Nos`;
            }
        }
    },

    layout: {
        padding: {
            top: 10,
            bottom: 10
        }
    },
    legend: {
        display: false
    },
    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black',

        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            barThickness: 40,

            ticks: {
                autoSkip: false,

            }
        }],

        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    }
}



export const WorkAssign = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} Nos`;
            }
        }
    },

    layout: {
        padding: {
            top: 0,
            bottom: 0,
        },
    },
    legend: {
        display: false
    },

    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black',

        },
        legend: {
            display: false
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            barThickness: 30,


            ticks: {
                autoSkip: false,

            }
        }],

        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    }
}

export const WorkAssignNew = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} Nos`;
            }
        }
    },

    layout: {
        padding: {
            top: 0,
            bottom: 0,
        },
    },
    legend: {
        display: false
    },

    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black',

        },
        legend: {
            display: false
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false,
                color: '#1b4b7b',
                zeroLineColors: '#1b4b7b',
                // borderDash : [5,5]
            },
            barThickness: 20,
            ticks: {
                autoSkip: false,
                fontStyle: 'bold',
                fontSize: '12',
                fontColor: '#1b4b7b',
            }
        }],

        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false,
                color: '#1b4b7b',
                zeroLineColors: '#1b4b7b',
                // borderDash : [5,5]
            },
            ticks: {
                beginAtZero: true,
                fontStyle: 'bold',
                fontSize: '12',
                fontColor: '#1b4b7b',
            }
        }]
    }
}

export const WorkAssignProgress = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} Nos`;
            }
        }
    },

    layout: {
        padding: {
            top: 0,
            bottom: 0,
        },
    },
    legend: {
        display: false
    },

    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black',

        },
        legend: {
            display: false
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false,
                color: 'purple',
                zeroLineColors: 'purple',
                borderDash: [5, 5]
            },
            barThickness: 15,
            ticks: {
                autoSkip: false,
                fontStyle: 'bold',
                fontSize: '12',
                fontColor: 'purple',
            }
        }],

        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false,
                color: 'purple',
                zeroLineColors: 'purple',
                borderDash: [5, 5]
            },
            ticks: {
                beginAtZero: true,
                fontStyle: 'bold',
                fontSize: '12',
                fontColor: 'purple',
            }
        }]
    }
}

// export const WorkAssignProgress2 = {
//     tooltips: {
//         callbacks: {
//             label: function (tooltipItem, data) {
//                 const label = data.datasets[tooltipItem.datasetIndex].label || '';
//                 const value = tooltipItem.yLabel;
//                 return `${label}: ${value} Nos`;
//             }
//         }
//     },

//     layout: {
//         padding: {
//             top: 0,
//             bottom: 0,
//         },
//     },
//     legend: {
//         display:false
//     },

//     plugins: {
//         labels: {
//             render: 'value',
//             fontColor: 'black',

//         },
//         legend: {
//             display: false
//         },
//         outlabels: {
//             color: "black",
//             // text: "%l\n%v (%p)",
//             stretch: 15,
//             font: {
//                 resizable: true,
//                 minSize: 12,
//                 maxSize: 16
//             }
//         },
//     },

//     scales: {

//         xAxes: [{
//             stacked: false,
//             gridLines: {
//                 drawOnChartArea: false,
//                 color : '#830a1f',
//                 zeroLineColors : '#830a1f',
//                 borderDash : [5,5]
//             },
//             barThickness: 15,
//             ticks: {
//                 autoSkip: false,
//                 fontStyle: 'bold',
//                 fontSize : '12',
//                 fontColor : '#830a1f',
//             }
//         }],

//         yAxes: [{
//             stacked: false,
//             gridLines: {
//                 drawOnChartArea: false,
//                 color : '#830a1f',
//                 zeroLineColors : '#830a1f',
//                 borderDash : [5,5]
//             },
//             ticks: {
//                 beginAtZero: true,
//                 fontStyle: 'bold',
//                 fontSize : '12',
//                 fontColor : '#830a1f',
//             }
//         }]
//     }
// }

// export const WorkAssignProgress3 = {
//     tooltips: {
//         callbacks: {
//             label: function (tooltipItem, data) {
//                 const label = data.datasets[tooltipItem.datasetIndex].label || '';
//                 const value = tooltipItem.yLabel;
//                 return `${label}: ${value} Nos`;
//             }
//         }
//     },

//     layout: {
//         padding: {
//             top: 0,
//             bottom: 0,
//         },
//     },
//     legend: {
//         display:false
//     },

//     plugins: {
//         labels: {
//             render: 'value',
//             fontColor: 'black',

//         },
//         legend: {
//             display: false
//         },
//         outlabels: {
//             color: "black",
//             // text: "%l\n%v (%p)",
//             stretch: 15,
//             font: {
//                 resizable: true,
//                 minSize: 12,
//                 maxSize: 16
//             }
//         },
//     },

//     scales: {

//         xAxes: [{
//             stacked: false,
//             gridLines: {
//                 drawOnChartArea: false,
//                 color : '#830a1f',
//                 zeroLineColors : '#830a1f',
//                 borderDash : [5,5]
//             },
//             barThickness: 15,
//             ticks: {
//                 autoSkip: false,
//                 fontStyle: 'bold',
//                 fontSize : '12',
//                 fontColor : '#830a1f',
//             }
//         }],

//         yAxes: [{
//             stacked: false,
//             gridLines: {
//                 drawOnChartArea: false,
//                 color : '#830a1f',
//                 zeroLineColors : '#830a1f',
//                 borderDash : [5,5]
//             },
//             ticks: {
//                 beginAtZero: true,
//                 fontStyle: 'bold',
//                 fontSize : '12',
//                 fontColor : '#830a1f',
//             }
//         }]
//     }
// }

export const WorkReopenAssignProgress = {
    tooltips: {
        callbacks: {
            label: function (tooltipItem, data) {
                const label = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = tooltipItem.yLabel;
                return `${label}: ${value} Nos`;
            }
        }
    },

    layout: {
        padding: {
            top: 0,
            bottom: 0,
        },
    },
    legend: {
        display: false
    },

    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black',

        },
        legend: {
            display: false
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false,
                color: 'green',
                zeroLineColors: 'green',
                borderDash: [5, 5]
            },
            barThickness: 15,
            ticks: {
                autoSkip: false,
                fontStyle: 'bold',
                fontSize: '12',
                fontColor: '#036303',
            }
        }],

        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false,
                color: 'green',
                zeroLineColors: 'green',
                borderDash: [5, 5]
            },
            ticks: {
                beginAtZero: true,
                fontStyle: 'bold',
                fontSize: '12',
                fontColor: '#036303',
            }
        }]
    }
}

// export const WorkReopenAssignProgress2 = {
//     tooltips: {
//         callbacks: {
//             label: function (tooltipItem, data) {
//                 const label = data.datasets[tooltipItem.datasetIndex].label || '';
//                 const value = tooltipItem.yLabel;
//                 return `${label}: ${value} Nos`;
//             }
//         }
//     },

//     layout: {
//         padding: {
//             top: 0,
//             bottom: 0,
//         },
//     },
//     legend: {
//         display:false
//     },

//     plugins: {
//         labels: {
//             render: 'value',
//             fontColor: 'black',

//         },
//         legend: {
//             display: false
//         },
//         outlabels: {
//             color: "black",
//             // text: "%l\n%v (%p)",
//             stretch: 15,
//             font: {
//                 resizable: true,
//                 minSize: 12,
//                 maxSize: 16
//             }
//         },
//     },

//     scales: {

//         xAxes: [{
//             stacked: false,
//             gridLines: {
//                 drawOnChartArea: false,
//                 color : 'green',
//                 zeroLineColors : 'green',
//                 borderDash : [5,5]
//             },
//             barThickness: 15,
//             ticks: {
//                 autoSkip: false,
//                 fontStyle: 'bold',
//                 fontSize : '12',
//                 fontColor : '#036303',
//             }
//         }],

//         yAxes: [{
//             stacked: false,
//             gridLines: {
//                 drawOnChartArea: false,
//                 color : 'green',
//                 zeroLineColors : 'green',
//                 borderDash : [5,5]
//             },
//             ticks: {
//                 beginAtZero: true,
//                 fontStyle: 'bold',
//                 fontSize : '12',
//                 fontColor : '#036303',
//             }
//         }]
//     }
// }

// export const WorReopenAssignProgress3 = {
//     tooltips: {
//         callbacks: {
//             label: function (tooltipItem, data) {
//                 const label = data.datasets[tooltipItem.datasetIndex].label || '';
//                 const value = tooltipItem.yLabel;
//                 return `${label}: ${value} Nos`;
//             }
//         }
//     },

//     layout: {
//         padding: {
//             top: 0,
//             bottom: 0,
//         },
//     },
//     legend: {
//         display:false
//     },

//     plugins: {
//         labels: {
//             render: 'value',
//             fontColor: 'black',
//             // fontWeight:'bold',
//             // fontSize : '12'
//         },
//         legend: {
//             display: false
//         },
//         outlabels: {
//             color: "black",
//             // text: "%l\n%v (%p)",
//             stretch: 15,
//             font: {
//                 resizable: true,
//                 minSize: 12,
//                 maxSize: 16,
//                 // weight: 'bold',
//                 // size : '12'
//             }
//         },
//     },

//     scales: {

//         xAxes: [{
//             stacked: false,
//             gridLines: {
//                 drawOnChartArea: false,
//                 color : 'green',
//                 zeroLineColors : 'green',
//                 borderDash : [5,5]
//             },
//             barThickness: 15,


//             ticks: {
//                 autoSkip: false,
//                 fontStyle: 'bold',
//                 fontSize : '12',
//                 fontColor : '#036303',
//             }
//         }],

//         yAxes: [{
//             stacked: false,
//             gridLines: {
//                 drawOnChartArea: false,
//                 color : 'green',
//                 zeroLineColors : 'green',
//                 borderDash : [5,5]
//             },
//             ticks: {
//                 beginAtZero: true,
//                 fontStyle: 'bold',
//                 fontSize : '12',
//                 fontColor : '#036303',
//             }
//         }]
//     }
// }




export const Dcoptions = {

    layout: {
        padding: {
            // top: 70,
            // bottom: 10
        }
    },
    legend: {
        // position: 'chartArea',
        // align: 'start',
        display: false
    },
    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black'
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 15,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },

    scales: {

        xAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            barThickness: 30,
            ticks: {
                autoSkip: false,

            }
        }],
        yAxes: [{
            stacked: false,
            gridLines: {
                drawOnChartArea: false
            },
            ticks: {
                beginAtZero: true
            }
        }]
    }
}



export const Pieoptions = {
    layout: {
        padding: {
            top: 10,
            bottom: 0
        }
    },
    legend: {
        display: false
    },
    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black'
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 12,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },
}

export const NewPieoptions = {
    layout: {
        padding: {
            top: 10,
            bottom: 0
        }
    },
    legend: {
        display: false
    },
    plugins: {
        labels: {
            render: function (args) {
                // Render label, value, and percentage
                return `(${args.percentage}%)`;
            },
            fontColor: 'black',
            fontSize: 15,
        },
        outlabels: {
            color: "black",
            text: "%l\n%v (%p)",
            stretch: 12,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },
};

// scales: {
//     xAxes: [{
//         gridLines: {
//             drawOnChartArea: false
//         },
//         ticks: {
//             autoSkip: false,
//             maxRotation: 90,
//             minRotation: 90
//         }
//     }],
//     yAxes: [{
//         gridLines: {
//             drawOnChartArea: false
//         }
//     }]
// }


export const ServicePieoptions = {
    layout: {

    },
    legend: {
        display: false
    },
    plugins: {
        labels: {
            render: 'value',
            fontColor: 'black'
        },
        outlabels: {
            color: "black",
            // text: "%l\n%v (%p)",
            stretch: 0,
            font: {
                resizable: true,
                minSize: 12,
                maxSize: 16
            }
        },
    },
}
