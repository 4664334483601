

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { Loading } from '../../../../../../Helper/Loading'
import { DesignationDDL } from '../../../../../../Components/CommonDDL/DesignationDDL'
import { useAuthState } from '../../../../../../Helper/Context'
import Header from '../../../../../../Components/Header/Header'
import Sidebar from '../../../../../../Components/Sidebar/Sidebar'
import DeletePopup from '../../../GardenPopup/DeletePopup'
import { Pegination } from '../../../../../../Components/Pegination/Pegination'
import { WardNameDataDDL } from '../../../../../../Components/CommonDDL/WardNameDataDDL'
import PumpingStationPopUp from './PumpingStationPopUp'
import { PumpingStationDeleteAPI, PumpingStationTableDataAPI } from '../../../../../../Redux/SidebarMasterSlice/PumpingStationSlice'


export const PumpingStation = () => {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    // console.log(ApiFlag)

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [DeletePopupShow, setDeletePopupShow] = useState(false)
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')
    const [LocationMasterPopupShow, setLocationMasterPopupShow] = useState()

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setLocationMasterPopupShow(true)
    }
    const handleEditOnClick = (item) => {
        setLocationMasterPopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }

    const handleOnCloseClick = () => {
        setLocationMasterPopupShow(false)
        setapiFlag('')
        setIsClear(!IsClear)
    }
    const [Designation, setDesignation] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [PumpingStation, setPumpingStation] = useState('')

    const handleSearch = () => {
        setIsSearch(!IsSearch)
        setCurrentPage(0)
    }

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setPumpingStation('')
        setWardName({
            ...WardName,
            ID: 0,
            Label: 'Select...',
        })
        // console.log("call")
    }
    const handleIsPost = () => {
        setIsPost(!IsPost)
        // if (tableData && tableData.table && tableData.table.length > 0) {
        //     setCurrentPage(0)
        //     setFrom(1)
        //     setTo(10)
        // }

    }
    const handleShowImage = (img) => {
        setLocationMasterPopupShow(true)
        setImagePopUpData(img)
    }
    useEffect(() => {
        const data = {
            // FinancialYearID: YearValue,
            M_SW_PumpingStationID: 0,
            M_WardID: WardName.ID,
            PumpingStation: PumpingStation,
            M_UsersID: UserID,
            token: token,
            From: From,
            To: To,
            Flag: ApiFlag,
        }
        if (apiFlag !== 'Update') {
            dispatch(PumpingStationTableDataAPI({ data }))
        }

    }, [IsPost, To, IsClear, IsSearch, WardName.ID])

    const { tableData, isLoading } = useSelector(state => state.PumpingStationTableData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
        handleSearch()
    }, [WardName.ID])

    const handleDeleteApi = (item) => {
        // console.log(item.m_sW_PumpingStationID)
        const data = {
            M_SW_PumpingStationID: item.m_sW_PumpingStationID,
            M_UserID: UserID,
            token: token,
            // Flag: 'Delete',
            handleIsPost: handleIsPost
        }
        dispatch(PumpingStationDeleteAPI({ data }))
    }
    const { isDeleteLoading } = useSelector(state => state.PumpingStationDeleteData)

    return (
        <>
            {isLoading && <Loading />}
            {isDeleteLoading && <Loading />}
            <div>
                <Header
                    // DashboardHeading="Sewerage Maintainance"
                    DashboardName={DashboardName}
                />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId}  />
                    <div id="" className='content1'>
                        {/* <!-- [ breadcrumb ] start -->  */}
                        <div className="page-header ">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        {/* <div className="page-header-title">
                                            <h5 className="ps-2 data_mob">MASTER */}
                                        {/* <!-- <span className="float-end">namita</span> --> */}
                                        {/* <span className="float-end fs-6">
                                                    <Select
                                                        // isClearable
                                                        // isRtl={isRtl}
                                                        isSearchable
                                                        maxMenuHeight={150}
                                                        value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                        onChange={(e) => {
                                                            e ?
                                                                setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                                :
                                                                setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                        }}
                                                        options={SelectWard.DDL}
                                                    />

                                                </span> */}
                                        {/* </h5>

                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- [ Main Content ] start --> */}
                        <div className="data_mob">
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div>
                                    <h5 className="ps-1 py-1">Pumping Station</h5>
                                    <div className="col-12 col-lg-7 float-end">
                                        {/* <PumpingStationPopUp
                                            open={LocationMasterPopupShow}
                                            handleIsPost={handleIsPost}
                                        /> */}
                                        <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                                            onClick={handleAddOnClick} style={{ color: "white", marginTop: '-2.5em' }}
                                        >
                                            <text className="ml-2 poppins">Add</text>
                                        </i>
                                    </div>
                                </div>
                                <div className="row m-0 ">
                                    <div className="col-12 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12">

                                            <div className="row p-2 rounded2 ">
                                                <div className="col-12 col-md-4 col-lg-3">
                                                    <WardNameDataDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                    // Flag='ward'
                                                    />
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>Pumping Station</b></label>
                                                        <input type="text"
                                                            className="form-control"
                                                            id="PumpingStation"
                                                            value={PumpingStation}
                                                            onChange={(e) => setPumpingStation(e.target.value)}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-2 col-lg-3 mt-lg-4 mt-md-4" >
                                                    <button type="button" className="btn-search btn btn btn-secondary mr-1"
                                                        onClick={() => handleSearch()}>
                                                        Search
                                                    </button>
                                                    <button type="button"
                                                        className="btn-clear btn btn-primary"
                                                        onClick={handleClear}>Clear</button>
                                                </div>
                                            </div>
                                            <div className="row mob_table table-responsive pt-1">

                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            {/* <th>Contractor Name</th> */}
                                                            <th>Prabhag No</th>
                                                            <th>Pumping Station</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) =>
                                                                <tr style={{ width: '100%' }}>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                    {/* <td>{item.designationName ? item.designationName : '-'}</td> */}
                                                                    <td>{item.wardName ? item.wardName : '-'}</td>
                                                                    <td>{item.pumpingStation ? item.pumpingStation : '-'}</td>
                                                                    <td style={{ textAlign: "center", width: '7%' }}>
                                                                        {/* <span >
                                                                            <PumpingStationPopUp
                                                                                open={LocationMasterPopupShow}
                                                                                handleIsPost={handleIsPost}
                                                                                item={item}
                                                                                Flag='Update'
                                                                            />
                                                                        </span> */}
                                                                        <span>
                                                                            <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => handleEditOnClick(item)}
                                                                            >
                                                                            </i>
                                                                        </span>
                                                                        <span>
                                                                            <DeletePopup
                                                                                open={DeletePopupShow}
                                                                                handleDeleteApi={handleDeleteApi}
                                                                                item={item}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ) :
                                                                <>No data found</>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {
                    LocationMasterPopupShow ?
                        <PumpingStationPopUp
                            open={LocationMasterPopupShow}
                            popupHeading="Location Name"
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                            handleClear={handleClear}
                        />
                        : <></>
                }
            </div>

        </>
    )
}