
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import moment from "moment";
import Header from '../../../../../Components/Header/Header'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { WardNameDataDDL } from '../../../../../Components/CommonDDL/WardNameDataDDL';
import { AssetTypeDataDDL } from '../../../../../Components/CommonDDL/AssetTypeDataDDL';
import { useAuthState } from '../../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import { CommWorkReopenGetDataAPI } from '../../../../../Redux/CommDashbaord/WorkReopenSlice/WorkReopenSlice';
import { Loading } from '../../../../../Helper/Loading';
import { useTranslation } from 'react-i18next';

export default function CivilWorkReopenByContractor() {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let FlagName = searchParams.get("Flag")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let ScreenName = searchParams.get("ScreenName")
    let M_gD_WorkPeriodID = searchParams.get("M_gD_WorkPeriodID")
    let ContractorID = searchParams.get("ContractorID")
    let ShowBy = searchParams.get("ShowBy")
    let Lang = searchParams.get("Lang")
    let ZoneValue = searchParams.get("ZoneName")

    // })
    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: HYear && HYear ? HYear : '0',
        Label: YLable && YLable ? YLable : "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: HMonth && HMonth ? HMonth : '0',
        Label: MLable && MLable ? MLable : "Select...",

    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })
    const [WorkAssignedBy, setWorkAssignedBy] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [AssetName, setAssetName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Contractor, setContractor] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: "Marathi",
    })

    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [Heading, setHeading] = React.useState(false)
    const [FromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))

    useEffect(() => {
        const data = {
            FromDate: FromDate,
            ToDate: ToDate,
            M_AssetID: AssetName.ID,
            M_AssetTypeID: AssetType.ID,
            M_GD_WorkPeriodID: M_gD_WorkPeriodID ? M_gD_WorkPeriodID : '0',
            ShowBy: ShowBy,
            From: From,
            To: To,
            M_WardID: WardName.ID,
            M_UsersID: UserID,
            Flag: 'CivilWork',
            M_ContractorID: ContractorID ? ContractorID : '0',
            token: token,
            M_ZoneID : ZoneValue,
            Language: Lang
        }
        {ZoneValue && dispatch(CommWorkReopenGetDataAPI({ data }))}
    }, [ZoneValue,FromDate,ToDate,AssetName.ID,AssetType.ID,WardName.ID])

    const { tableData, isLoading } = useSelector(state => state.CommWorkReopenGetAPIData)


    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    DashboardHeading="Garden Maintainance"
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar Language={Language}
                        ProjectTypeId={ProjectTypeId} />
                    <div id="" className='content1'>
                        <div className='data_mob'>
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className='col-8'>
                                    <h5 className="ps-1 pt-1">CIVIL WORK REOPEN PROGRESS / {searchName}</h5>
                                </div>
                                <div className='col-4'>
                                    <button type="button" className="btn btn-primary float-end mr-1">Export</button>
                                </div>

                                <div className="row m-0 mt-1">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12 ">
                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"> <b>From Date :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="FromDate"
                                                            value={FromDate}
                                                            onChange={(e) => (setFromDate(e.target.value), setToDate(''))}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>To Date :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="ToDate"
                                                            value={ToDate}
                                                            onChange={(e) => setToDate(e.target.value)}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <WardNameDataDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        HWard={HWard}
                                                        WLable={WLable}
                                                        Language={Lang}
                                                    />
                                                </div>


                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <AssetTypeDataDDL
                                                        AssetType={AssetType}
                                                        setAssetType={setAssetType}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>Asset Name :</b></label>
                                                        <Select
                                                            isClearable
                                                            // isDisabled={IsDisabled}
                                                            // isRtl={isRtl}
                                                            isSearchable
                                                            maxMenuHeight={150}
                                                            value={{ value: AssetType.ID, label: AssetType.Label }}
                                                            onChange={(e) => {
                                                                e ?
                                                                    setAssetType({ ...AssetType, ID: e.value, Label: e.label })
                                                                    :
                                                                    setAssetType({ ...AssetType, ID: 0, Label: "Select..." })

                                                            }}
                                                        // options={AssetType.DDL}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>Reopened By :</b></label>
                                                        <Select
                                                            isClearable
                                                            // isDisabled={IsDisabled}
                                                            // isRtl={isRtl}
                                                            isSearchable
                                                            maxMenuHeight={150}
                                                            value={{ value: AssetType.ID, label: AssetType.Label }}
                                                            onChange={(e) => {
                                                                e ?
                                                                    setAssetType({ ...AssetType, ID: e.value, Label: e.label })
                                                                    :
                                                                    setAssetType({ ...AssetType, ID: 0, Label: "Select..." })

                                                            }}
                                                        // options={AssetType.DDL}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>Contractor Name :</b></label>
                                                        <Select
                                                            isClearable
                                                            // isDisabled={IsDisabled}
                                                            // isRtl={isRtl}
                                                            isSearchable
                                                            maxMenuHeight={150}
                                                            value={{ value: AssetType.ID, label: AssetType.Label }}
                                                            onChange={(e) => {
                                                                e ?
                                                                    setAssetType({ ...AssetType, ID: e.value, Label: e.label })
                                                                    :
                                                                    setAssetType({ ...AssetType, ID: 0, Label: "Select..." })

                                                            }}
                                                        // options={AssetType.DDL}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-3 col-md-4 mt-lg-4 d-md-flex" >
                                                    <button type="button"
                                                        className="btn-clear btn btn-primary mx-2"
                                                    // onClick={handleSearch}
                                                    >
                                                        Search</button>
                                                    <button type="button"
                                                        className="btn btn-outline-danger"
                                                    // onClick={handleClear}
                                                    >
                                                        Clear</button>
                                                </div>
                                            </div>

                                            <div className="row mob_table table-responsive pt-1">
                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            <th style={{ width: "7em" }}>Reopened Date</th>
                                                            {/* <th>Work Type</th> */}
                                                            <th>Asset Name</th>
                                                            {/* <th>Sector Name	 </th> */}
                                                            <th style={{ width: "17em" }}>Area</th>
                                                            <th>Work Name </th>
                                                            <th>Completed Photo</th>
                                                            <th>Reopened Photo</th>
                                                            {
                                                                ScreenName == 'Completed' &&
                                                                <>
                                                                    <th>Reopen Completed Photo</th>
                                                                    <th>Completed Date</th>
                                                                </>
                                                            }
                                                            <th>Officer Name</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => */}
                                                        <tr>
                                                            <td style={{ textAlign: "center" }}>1</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {
                                                                ScreenName == 'Completed' &&
                                                                <>
                                                                    <td></td>
                                                                    <td></td>
                                                                </>
                                                            }
                                                            <td></td>
                                                        </tr>
                                                        {/* //     ) :
                                                        //         <>No Found data</>
                                                        // } */}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            } */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!--  --> */}


                    </div>
                </div>
                {/* {
                    showImagePopup ?
                        <ViewURL
                            Heading={Heading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''
                } */}
            </div>
        </>

    )
}