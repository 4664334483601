
import React from "react";
import { useNavigate } from "react-router-dom";

export default function STPPreventiveCard({  count, title, navLink, cardName, imgPath,STPPreventiveCardNavigate }) {
    const navigate = useNavigate()

    const handleSeweragePreventiveCardClick = () => {
        navigate(navLink)
    }
    return (

        <div style={{ height: '100%', cursor:'pointer' }} className="mb-1 p-3 shadow blue_borders bg-white mb-md-2 daily-card-width" onClick={()=>STPPreventiveCardNavigate(title)}>
            {/* <div className=" p-3 shadow blue_borders bg-white">
                        <div className="row d-flex justify-content-center align-items-center ">
                            <div className="col-6 col-lg-6 col-md-6  text-center" style={{ borderRight: "2px solid #cfc7c7" }}>
                                <h6 className="text-black"><b>{cardTitle}</b></h6>
                                <h4 className="green_color">{count}</h4>
                            </div>
                            <div className="col-6 col-lg-6 col-md-6 text-center">
                                <img className={cardName} src={imgPath} alt="" />
                            </div>
                        </div>
                </div> */}

            <div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                <div className="text-center" >
                    <div className="d-flex justify-content-between align-items-center">
                        <h4>{count}</h4>
                        <img className={cardName} style={{ height: '50px', width: '35px' }} src={imgPath} alt="" />
                    </div>

                </div>
                <div style={{ borderLeft: '2px solid #d6bea7' }}>
                    <h6 className="text-black ml-3"><b>{title}</b></h6>
                </div>
            </div>
        </div>

    )
}


