
import { useEffect } from 'react'
import Select from 'react-select'
import { FinancialYearDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'

export const FinancialYearDataDDL = (props) => {
    const { FinancialYear, setFinancialYear, HYear, YLable} = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token }
        dispatch(FinancialYearDDLAPI({ data }))
    }, [])

    const { FinancialYearData } = useSelector(state => state.FinancialYearDDLData)

    useEffect(() => {
        handleFinancialYearDDL()
    }, [FinancialYearData])

    const handleFinancialYearDDL = () => {
        if (FinancialYearData && FinancialYearData.table && FinancialYearData.table.length > 0) {
            let list = FinancialYearData.table.map((item, index) => ({
                value: item.id,
                label: item.financialYear,
            }))
                setFinancialYear({
                    DDL: list,
                    ID: HYear,
                    Label: YLable,
                })
        }
        else {
            setFinancialYear({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Financial Year :</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                styles={{
                    option: (provided, state) => ({
                        ...provided,
                        color: 'black'
                    })
                }}
                isSearchable
                maxMenuHeight={150}
                value={{ value: FinancialYear.ID, label: FinancialYear.Label }}
                onChange={(e) => {
                    e ?
                        setFinancialYear({ ...FinancialYear, ID: e.value, Label: e.label })
                        :
                        setFinancialYear({ ...FinancialYear, ID: 0, Label: "Select..." })

                }}
                options={FinancialYear.DDL}
            />
        </div>
    )
}