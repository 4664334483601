import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React from 'react'
import { useState } from 'react';

export default function ChangePassword(props) {

    const { handleOnCloseClick, open, EditIcon, Flag, GardenMasterFlag, popupHeading, M_AssetTypeID, handleIsPost, apiFlag, rowData } = props
   
    // const handleClear = () => {
    //     setCurrentPage(0)
    //     setIsClear(!IsClear)
    //     setSeverageWorkType({
    //         ...SeverageWorkType,
    //         ID: 0,
    //         Label: 'Select...',
    //     })

    //     // console.log("call")
    // }

    return (

        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="severage_mob_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Change Password</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>
                        <div className=" row">
                                <label for="inputPassword" class="col-sm-3 col-form-label">Old Password : </label>
                                <div className="col-md-6 col-12 col-lg-6">
                                    <input type="password" class="form-control" />
                                </div>
                            </div>
                            <div className=" row">
                                <label for="inputPassword" class="col-sm-3 col-form-label">New Password : </label>
                                <div className="col-md-6 col-12 col-lg-6">
                                    <input type="password" class="form-control" />
                                </div>
                            </div>
                            <div className=" row">
                                <label for="inputPassword" class="col-sm-3 col-form-label">Conform New Password : </label>
                                <div className="col-md-6 col-12 col-lg-6">
                                    <input type="password" class="form-control" />
                                </div>
                            </div>


                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                    // onClick={() => handlePost()}
                                    // disabled={
                                    //   SeverageWorkTypeName == '' 
                                    // }
                                    >Submit </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right">Clear</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {/* )} */}
        </Popup>
    )

}