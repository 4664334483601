import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import Header from '../../../../../../../Components/Header/Header'
import Sidebar from '../../../../../../../Components/Sidebar/Sidebar'
import DeletePopup from '../../../../GardenPopup/DeletePopup'
import SewerageWorkTypePopup from './SewerageWorkTypePopup'
import { SeverageWorkTypeDataDDL } from '../../../../../../../Components/CommonDDL/SeverageWorkTypeDataDDL'
import { useAuthState } from '../../../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { SeverageWorkTypeDeleteAPI, SeverageWorkTypeTableDataAPI } from '../../../../../../../Redux/SeverageDashboardSlice/SeverageWorkTypeMasterSlice'
import { Pegination } from '../../../../../../../Components/Pegination/Pegination'
import { Loading } from '../../../../../../../Helper/Loading'

export default function SewerageWorkTypeData() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    // console.log(ApiFlag)

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [LocationMasterPopupShow, setLocationMasterPopupShow] = useState(false)
    const [DeletePopupShow, setDeletePopupShow] = useState(false)
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')

    const [SewerageMasterPopupShow, setSewerageMasterPopupShow] = useState()

    const [SeverageWorkType, setSeverageWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    // console.log(searchParams)

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setSewerageMasterPopupShow(true)
    }

    const handleEditOnClick = (item) => {
        setSewerageMasterPopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }
    const handleOnCloseClick = () => {
        setSewerageMasterPopupShow(false)
    }

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setSeverageWorkType({
            ...SeverageWorkType,
            ID: 0,
            Label: 'Select...',
        })

        // console.log("call")
    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
        // if (tableData && tableData.table && tableData.table.length > 0) {
        //     setCurrentPage(0)
        //     setFrom(1)
        //     setTo(10)
        // }

    }

    const handleShowImage = (img) => {
        setLocationMasterPopupShow(true)
        setImagePopUpData(img)
    }

    useEffect(() => {
        const data = {
            // FinancialYearID: YearValue,
            M_SW_WorkTypeID: SeverageWorkType.ID,
            WorkType: '',
            M_UserID: UserID,
            token: token,
            From: From,
            To: To,
        }
        // if (YearValue !== 0) {
        dispatch(SeverageWorkTypeTableDataAPI({ data }))
        // }

    }, [IsPost, To, IsClear, SeverageWorkType.ID])

    const { tableData, isLoading } = useSelector(state => state.SeverageWorkTypeTableData)

    const handleDeleteApi = (item) => {

        const data = {
            M_SW_WorkTypeID: item.m_sW_WorkTypeID,
            M_UserID: UserID,
            token: token,
            // Flag: 'Delete',
            handleIsPost: handleIsPost
        }
        dispatch(SeverageWorkTypeDeleteAPI({ data }))
    }
    const { isDeleteLoading } = useSelector(state => state.SeverageWorkTypeDeleteData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [SeverageWorkType.ID])

    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select...",
    // })

    return (
        <>
            {isLoading && <Loading />}
            {isDeleteLoading && <Loading />}
            <div>
                <Header
                    // DashboardHeading="Sewerage Maintainance"
                    DashboardName={DashboardName} 
                    setYearValue={setYearValue}
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId} />
                    <div className="content">
                        <div className="page-header ">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        <div className="page-header-title">
                                            {/* <h5 className="ps-2 data_mob"> */}

                                            {/* <span className="float-end fs-6">
                                            <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                        :
                                                        setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                }}
                                                options={SelectWard.DDL}
                                            />
                                            </span> */}
                                            {/* </h5> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='data_mob'>
                            <div className="row m-1 py-1 master_border">
                                <div>
                                    <h5 className="mt-1">Work Type Master</h5>
                                    <div className="float-end butt">
                                        {/* <i class="fa-solid fa-plus add_btn" style={{ color: "#010813", fontSize: '17px' }}><span className="ml-2">Add</span></i> */}
                                        <button type="button" className="btn btn-primary float-end ml-lg-1 ml-1">Export</button>
                                        {/* <SewerageWorkTypePopup
                                            open={SewerageMasterPopupShow}
                                            handleIsPost={handleIsPost}
                                        /> */}
                                        {/* <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                                            style={{ color: "white" }}
                                            onClick={handleAddOnClick}
                                        >
                                            <text className="ml-2 poppins">Add</text>
                                        </i> */}
                                    </div>
                                    <div className="row mt-4 mb-2 m-0">
                                        <div className="col-12 rounded rounded-2 shadow"
                                            style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                            <div className="col-12">

                                                <div className="row mt-1 rounded2">
                                                    <div className="col-12 col-lg-4 col-md-6">
                                                        <SeverageWorkTypeDataDDL
                                                            SeverageWorkType={SeverageWorkType}
                                                            setSeverageWorkType={setSeverageWorkType}
                                                            IsPost={IsPost}
                                                        />

                                                    </div>

                                                    <div className="col-12 col-lg-3 mt-lg-4" >
                                                        <button type="button"
                                                            className="btn-clear btn btn-primary"
                                                            onClick={handleClear}>Clear</button>
                                                    </div>
                                                </div>
                                                <div className="row table-responsive mob_table mt-2">
                                                    <table id="totalordergenerated"
                                                        cellPadding="0"
                                                        cellSpacing="0"
                                                        border="0"
                                                        className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th className="sr_no_col">Sr No.</th>
                                                                <th>Work Type</th>
                                                                {/* {
                                                                    searchName != "Work Type" ? */}
                                                                        <th>Action</th>
                                                                        {/* : <></>
                                                                } */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => (
                                                                    <tr style={{ width: '100%' }}>
                                                                        <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                        <td>{item.workType ? item.workType : '-'}</td>
                                                                        {/* {
                                                                            searchName != "Work Type" ? */}

                                                                                <td style={{ textAlign: "center", width: '7%' }}>
                                                                                    {/* <span >
                                                                                        <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => handleEditOnClick(item)}
                                                                                >
                                                                                </i>
                                                                                    </span> */}
                                                                                    {/* <span>
                                                                                        <DeletePopup
                                                                                            open={DeletePopupShow}
                                                                                            handleDeleteApi={handleDeleteApi}
                                                                                            item={item}
                                                                                        />
                                                                                    </span> */}
                                                                                </td>
                                                                                {/* : <></>
                                                                        } */}
                                                                    </tr>
                                                                )) :
                                                                    <>No data found</>
                                                            }
                                                        </tbody>
                                                    </table>


                                                </div>

                                                {tableData && tableData.table && tableData.table.length > 0 &&
                                                    <Pegination
                                                        PerPageCount={PerPageCount}
                                                        TotalCount={tableData.table[0].totalCount}
                                                        setFrom={setFrom}
                                                        setTo={setTo}
                                                        setrowNo={setrowNo}
                                                        CurrentPage={CurrentPage}
                                                        setCurrentPage={setCurrentPage}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        SewerageMasterPopupShow ?
                            <SewerageWorkTypePopup
                                open={SewerageMasterPopupShow}
                                popupHeading="Work Type Data"
                                handleIsPost={handleIsPost}
                                handleOnCloseClick={handleOnCloseClick}
                                Flag={apiFlag}
                                rowData={rowData}
                            // IsPost={IsPost}
                            />
                            : <></>
                    }
                </div >
            </div >
        </>
    )
}