import React from 'react'
import { useNavigate } from 'react-router-dom'
import SewerageMasterCard from './SewerageMasterCard'


export default function SewerageMasterCardManage(props) {

    const { Countdata,HeaderMonthID,HeaderFinancialYear , MonthValue, YearValue , YearLabel,MonthLabel,WardValue,WardLabel,ProjectTypeId,DashboardName } = props
   
    const MasterNavigate = (name, screenFlag) => {
        if (name === "Work Type") {
            navigate(`/sewerageworktypedata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Work Name") {
            navigate(`/sewerageworknamedata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Pumping Set") {
            navigate(`/seweragepumpingdata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Pipeline Details") {
            navigate(`/seweragepipelinedata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }
    const navigate = useNavigate()
    return (
        <div>
            <div className="row">
                <h5 className="ml-4 py-2">Master</h5>
            </div>

            <div className='row pr-3 pl-3'>
                <div className='col-12 col-md-6 col-lg-3'>
                    <SewerageMasterCard
                        title="Work Type"
                        cardName='py-2 green cursor'
                        border='green_border'
                        count={Countdata.WorkTypeTotalCount}
                        imgPath="./assets/img/ward (2).png"
                        // navLink='/sewerageworktypedata'
                        MasterNavigate={MasterNavigate}
                        color='red'
                    />
                </div>
                <div className='col-12 col-md-6 col-lg-3'>
                    <SewerageMasterCard
                        title="Work Name"
                        cardName='py-2 blue cursor'
                        border='blue_border'
                        count={Countdata.WorkNameTotalCount}
                        imgPath="./assets/img/system (2).png"
                        // navLink='/sewerageworknamedata'
                        MasterNavigate={MasterNavigate}
                        color='blue'
                    />
                </div>

                <div className='col-12 col-md-6 col-lg-3'>
                    <SewerageMasterCard
                        title="Pumping Set"
                        cardName='py-2 green cursor'
                        border='green_border'
                        count={Countdata.PumpSetDetailsTotalCount}
                        imgPath="./assets/img/water-pump.png"
                        // navLink='/seweragepumpingdata'
                        MasterNavigate={MasterNavigate}
                        color='red'
                    />
                </div>

                <div className='col-12 col-md-6 col-lg-3'>
                    <SewerageMasterCard
                        title="Pipeline Details"
                        cardName='py-2 blue cursor'
                        border='blue_border'
                        count={Countdata.PipelineDetailsTotalCount}
                        imgPath="./assets/img/data-processing.png"
                        // navLink='/seweragepipelinedata'
                        MasterNavigate={MasterNavigate}
                        color='blue'
                    />
                </div>

            </div>

        </div>


    )

}

