import logo from './logo.svg';
import './App.css';
import AllRoutes from './AllRoutes';
import { Provider } from 'react-redux';
import { AuthProvider } from './Helper/Context';
import { ToastContainer } from 'react-toastify';
import store from "./Redux/Store";
import { I18nextProvider } from 'react-i18next';
import i18n from './Lang';

function App() {
  return (
    <>
    <I18nextProvider i18n={i18n}>

     <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <AuthProvider>
        <AllRoutes />
        </AuthProvider>
      </Provider>
      </I18nextProvider>
    </>
  );
}

export default App;
