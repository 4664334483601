import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import moment from 'moment'
import { useAuthState } from '../../../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { TurbidityPostDataAPI } from '../../../../../../Redux/WaterSupplyDashBoardSlice/PreventiveWaterQualitySlice/TurbiditySlice';

export default function WaterQualityTurbidityPagePopup(props) {

    const { handleOnCloseClick, open, Flag, handleIsPost, item, EditIcon, apiFlag, rowData, } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [TurbidityField, setTurbidityField] = useState({
        readingDate: Flag === 'Update' ? moment(rowData.readingDate).format("YYYY-MM-DD")  : '',
        wardName: Flag === 'Update' ? rowData.wardName : '',
        locationDetails: Flag === 'Update' ? rowData.locationDetails : '',
        turibidity: Flag === 'Update' ? rowData.turibidity : '',

    })

    const handleTurbidityField = (e) => {
        setTurbidityField({ ...TurbidityField, [e.target.name]: e.target.value })
    }

    const handlePost = () => {
        const data = {
            T_WS_WaterQualityTurbidityID: Flag === 'Update' ? rowData.t_wS_WaterQualityTurbidityID : '0',
            Turibidity: TurbidityField.turibidity,         
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(TurbidityPostDataAPI({ data }))

    }

    return (
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="water_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Water Quality-Turbidity</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>
                            <div className="row details-row">
                            <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Date :</b></label>
                                        <input type="date" className="form-control"
                                            name='readingDate'
                                            aria-describedby="emailHelp"
                                            disabled
                                            value={TurbidityField.readingDate}
                                            onChange={(e) => handleTurbidityField(e)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6" >                                
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Prabhag No :</b></label>
                                        <input type="text" className="form-control"
                                            name='wardName'
                                            aria-describedby="emailHelp"
                                            disabled
                                            value={TurbidityField.wardName}
                                            onChange={(e) => handleTurbidityField(e)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6" >
                                    <div className="form-group">
                                    <label className="d-block" htmlFor="NameofDepartment"><b>Location Name :</b></label>
                                        <input type="text" className="form-control"
                                            name='locationDetails'
                                            aria-describedby="emailHelp"
                                            disabled
                                            value={TurbidityField.locationDetails}
                                            onChange={(e) => handleTurbidityField(e)}
                                        />
                                    </div>
                                </div>

                                <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Turbidity :</b></label>
                                        <input type="text" className="form-control"
                                            name='turibidity'
                                            aria-describedby="emailHelp"
                                            value={TurbidityField.turibidity}
                                            onChange={(e) => handleTurbidityField(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">
                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                    >{Flag === "Update" ? "Update" : "Save"} </button>
                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </Popup>
    )

}