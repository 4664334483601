
import { useEffect } from 'react'
import Select from 'react-select'
import { EmployeeDDLAPI, } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'

export const EmployeeDataDDL = (props) => {
    const { EmployeeDDL, setEmployeeDDL, CropNameDashboardData, PopUpField, rowData, Flag, IsPost, Designation, Department, DailyWorkSheetDesignation } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            UserID,
            M_DesignationID: Designation?.ID ? Designation?.ID : DailyWorkSheetDesignation?.ID,
            M_DepartmentID: Department?.ID,
            token: token,
        }
        {dispatch(EmployeeDDLAPI({ data }))}
    }, [IsPost, Designation?.ID, Department?.ID, DailyWorkSheetDesignation?.ID])

    const { EmpDDLData } = useSelector(state => state.EmployeeDDLData)

    useEffect(() => {
        handleEmployeeDDL()
    }, [EmpDDLData])

    const handleEmployeeDDL = () => {
        if (EmpDDLData && EmpDDLData.table && EmpDDLData.table.length > 0) {
            let list = EmpDDLData.table.map((item, index) => ({
                value: item.id,
                label: item.employeeName,
            }))

            // setEmployeeDDL({
            //     DDL: list,
            //     ID: Flag === 'Update' ? rowData.m_EmployeeID : 0,
            //     Label: Flag === 'Update' ? rowData.employeeName : "Select...",
            // })
            setEmployeeDDL({ ...EmployeeDDL, DDL: list })
        }
        else {
            setEmployeeDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block " htmlFor="NameofEmployee"><b>{t('AdminDashboard.Admin_Sidebar_Name.Employee')} {Flag && <AstricSign />}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width2"
                isSearchable
                maxMenuHeight={150}
                value={{ value: EmployeeDDL.ID, label: EmployeeDDL.Label }}
                onChange={(e) => {
                    e ?
                        setEmployeeDDL({ ...EmployeeDDL, ID: e.value, Label: e.label })
                        :
                        setEmployeeDDL({ ...EmployeeDDL, ID: 0, Label: "Select..." })

                }}
                options={EmployeeDDL.DDL}
            />
        </div>
    )
}