import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useAuthState } from '../../../../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { WardNameDataDDL } from '../../../../../../../Components/CommonDDL/WardNameDataDDL';
import { SeverageLocationDataDDL } from '../../../../../../../Components/CommonDDL/SeverageLocationDataDDL';
import { SeveragePipelineDetailsMasterPostDataAPI } from '../../../../../../../Redux/SeverageDashboardSlice/SeveragePipelineDetailsMaster';
import { AstricSign } from '../../../../../../../Helper/AstricSign';


export default function SeweragePipelinePopup(props) {

    const { handleOnCloseClick, open, EditIcon, Flag, GardenMasterFlag, popupHeading, M_AssetTypeID, handleIsPost, apiFlag, rowData } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();

    // const [rowData, setRowData] = useState(rowData)
    const [IsOpen, setIsOpen] = useState(false);

    const [PipelineDetail, setPipelineDetail] = useState(Flag === 'Update' ? rowData.pipelineDetail : '');
    const [IsClear, setIsClear] = useState(false)
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_WardID : 0,
        Label: Flag === 'Update' ? rowData.wardName : "Select...",
    })
    const [SeverageLocation, setSeverageLocation] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_sW_LocationID : 0,
        Label: Flag === 'Update' ? rowData.swLocation : "Select...",
    })

    // const [Status, setStatus] = useState({
    //   DDL: [],
    //   ID: Flag === 'Update' ? rowData.m_StatusID : 0,
    //   Label: Flag === 'Update' ? rowData.statusName : "Select...",
    // })

    const handleClear = () => {
        setIsClear(!IsClear)
        setPipelineDetail()
        setSeverageLocation({
            ...SeverageLocation,
            ID: 0,
            Label: 'Select...',
        })
        setWardName({
            ...WardName,
            ID: 0,
            Label:'Select...' ,
        })
       
    }

    const handlePost = () => {
        const data = {

            M_SW_PipelineDetailsID: Flag === 'Update' ? rowData?.m_sW_PipelineDetailsID : '0',
            M_SW_LocationID: SeverageLocation.ID,
            PipelineDetail: PipelineDetail,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,          
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(SeveragePipelineDetailsMasterPostDataAPI({ data }))
    }

    // const [showPopup, setShowPopup] = useState(false);
    return (

        // <Popup
        //     className=''
        //     onOpen={() => setIsOpen(!IsOpen)}
        //     contentStyle={{ width: '55%', height: '60%', top: '5%' }}
        //     trigger={
        //         Flag === 'Update' ?
        //             <i className="fa-solid fa-pen-to-square" style={{ cursor: 'pointer' }} title='EDit'></i>
        //             :
        //             <i className="fa-solid fa-plus float-end btn btn-secondary mb-1"
        //                 style={{ color: "white" }}
        //             >
        //                 <text className="ml-2 poppins">Add</text>
        //             </i>

        //     } modal>
        // {
        //     close => (
        // n_popup-content 
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="severage_mob_workname_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Pipelines Details</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">

                                <div className="col-12 col-md-6 col-lg-6">
                                    <WardNameDataDDL
                                        WardName={WardName}
                                        setWardName={setWardName}
                                        rowData={rowData}
                                        Flag={Flag}
                                    // HWard={HWard}
                                    // WLable={WLable}
                                    />

                                </div>

                                <div className="col-md-6 col-lg-6">
                                    <SeverageLocationDataDDL
                                        SeverageLocation={SeverageLocation}
                                        setSeverageLocation={setSeverageLocation}
                                        WardName={WardName}
                                        rowData={rowData}
                                        Flag={Flag}

                                    />
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Pipeline Details <AstricSign />:</b></label>
                                        <input type="text"
                                            className="form-control"
                                            id="PipelineDetail"
                                            value={PipelineDetail}
                                            onChange={(e) => setPipelineDetail(e.target.value)}

                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() =>(handlePost(),handleClear())}
                                        disabled={
                                            WardName.ID == 0 || SeverageLocation.ID == 0 || PipelineDetail == '' 
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* )} */}
        </Popup>
    )

}