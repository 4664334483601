import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Marathi } from './Helper/Language/Marathi';
import { English } from './Helper/Language/English';


const resources = {
    Marathi: { translation: Marathi  },
    English: { translation: English  }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'Marathi',
        fallbackLng: 'Marathi',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;