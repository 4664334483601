import Header from "../../../Components/Header/Header";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import SewerageAssignCardManage from "./SewerageDashboardComponent/SewerageAssign/SewerageAssignCardManage";
import SewerageMaintainanceCardManage from "./SewerageDashboardComponent/SewerageMaintainance/SewerageMaintainanceCardManage";
import SewerageMasterCardManage from "./SewerageDashboardComponent/SewerageMaster/SewerageMasterCardManage";
import SeweragePreventiveCardManage from "./SewerageDashboardComponent/SeweragePreventive/SeweragePreventiveManage";
import SewerageServiceCardManage from "./SewerageDashboardComponent/SewerageService/SewerageServiceCardManage";
import { Pie } from 'react-chartjs-2';
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import MaintainanceManageCard from "./DashboardComponent/MaintainanceCard/MaintainanceManageCard";
import { WardNameDataDDL } from "../../../Components/CommonDDL/WardNameDataDDL";
import GardenMaintainanceCardManage from "./GarderDashboardComponent/GardenMaintainanceCard/GardenMaintainanceCardManage";
import { Pieoptions, ServicePieoptions } from "../../../Helper/Data";
import { useAuthState } from "../../../Helper/Context";
import { useDispatch, useSelector } from "react-redux";
import { SewerageDashMainCountDataAPI } from "../../../Redux/MainCountSlice/SewerageCount";
import { useSearchParams } from "react-router-dom";
import { Loading } from "../../../Helper/Loading";


export const SewerageDashboard = () => {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    const [YearValue, setYearValue] = useState(null)
    const [YearLabel, setYearLabel] = useState(null)
    const [MonthValue, setMonthValue] = useState(null)
    const [MonthLabel, setMonthLabel] = useState(null)
    const [WardValue, setWardValue] = useState(null)
    const [WardLabel, setWardLabel] = useState(null)

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 1,
        Label: "Select",
    })

    const PreventivePiedata = {
        labels: ["Pumping Operation", "Security", "Energy Audit", "House Keeping"],
        datasets: [
            {
                label: '# of Votes',
                data: [80, 40, 20, 70],
                backgroundColor: [
                    "#ffc33d",
                    "#eeb259",
                    "#df2f32",
                    "#ff8430",

                ],
                borderColor: [
                    // '#28c8c1',
                    // '#172b4d',
                    // '#993366',
                    // '#593e89',
                    // '#94408e',
                    // '#c74382',
                    // '#fa5d00',
                    // '#529ad2',
                    // '#ffc137',
                ],
                borderWidth: 1,
            },
        ],
    };


    const ServicePiedata = {
        labels: ["Operation", "Electrical Maintainance"],
        datasets: [
            {
                label: '# of Votes',
                data: [80, 40],
                backgroundColor: [
                    "#f48242",
                    "#ffc33d",

                ],
                borderColor: [
                    // '#28c8c1',
                    // '#172b4d',
                    // '#993366',
                    // '#593e89',
                    // '#94408e',
                    // '#c74382',
                    // '#fa5d00',
                    // '#529ad2',
                    // '#ffc137',
                ],
                borderWidth: 1,
            },
        ],
    };

    const [Countdata, setCountdata] = useState('')
    const [From, set] = useState('')

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_WardID: WardValue,
            M_MonthID: MonthValue,
            M_UserID: UserID,
            Flag: 'Web',
            token: token,
            handleGetCount: handleGetCount
        }
        if (YearValue && MonthValue && WardValue) {
            dispatch(SewerageDashMainCountDataAPI({ data }))
        }
    }, [WardValue, YearValue, MonthValue])

    const { SewerageDashCountData, isLoading } = useSelector(state => state.SewerageDashMainCountData)

    const handleGetCount = (data) => {
        let tempData = {}
        data.forEach((item) => {
            tempData[item.paramName] = item.totalCount
        })
        setCountdata(tempData)
    }
    return (
        <div>
            {isLoading && <Loading />}
            <Header
                DashboardHeading="Sewerage Maintenance"
                setYearValue={setYearValue}
                setMonthValue={setMonthValue}
                FinancialYear={FinancialYear}
                setFinancialYear={setFinancialYear}
                Month={Month}
                setMonth={setMonth}
                setYearLabel={setYearLabel}
                setMonthLabel={setMonthLabel}
                setWardValue={setWardValue}
                setWardLabel={setWardLabel}
                DashboardName={DashboardName}
            />
            <div className="wrapper">
                <Sidebar
                    ProjectTypeId={ProjectTypeId} />
                <div className="content1 content">

                    <div className="page-header ">
                        <div className="page-block">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className="page-header-title d-flex justify-content-between align-items-center">
                                        <h5 className="ps-2">DASHBOARD</h5>
                                        {/* <div className="col-md-6 col-7 col-lg-3">
                                            <WardNameDataDDL
                                                WardName={WardName}
                                                setWardName={setWardName}
                                            />
                                        </div> */}

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-4 m-1 py-2 px-3 master_border">
                        <GardenMaintainanceCardManage />
                    </div>


                    <div className="m-1 py-2 pb-4 master_border">
                        <SewerageMasterCardManage
                            Countdata={Countdata}
                            YearValue={YearValue}
                            YearLabel={YearLabel}
                            MonthValue={MonthValue}
                            MonthLabel={MonthLabel}
                            WardValue={WardValue}
                            WardLabel={WardLabel}
                            ProjectTypeId={ProjectTypeId}
                            DashboardName={DashboardName} />
                    </div>

                    <div className="row mt-4 m-1 py-2 pb-2 master_border">
                        <SeweragePreventiveCardManage
                            Countdata={Countdata}
                            YearValue={YearValue}
                            YearLabel={YearLabel}
                            MonthValue={MonthValue}
                            MonthLabel={MonthLabel}
                            WardValue={WardValue}
                            WardLabel={WardLabel}
                            ProjectTypeId={ProjectTypeId}
                            DashboardName={DashboardName} />


                        <div className="col-12 col-lg-4">
                            <div className="row">
                                <div className="col-12">
                                    <div className=" px-3 mt-3 pb-2 bg-white rounded rounded-3">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <Pie
                                                height={210}
                                                // width={300}
                                                options={Pieoptions}
                                                data={PreventivePiedata}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!--  --> */}

                    <div className="row mt-4 m-1 py-2 pb-2 master_border">
                        <SewerageServiceCardManage
                            Countdata={Countdata}
                            YearValue={YearValue}
                            YearLabel={YearLabel}
                            MonthValue={MonthValue}
                            MonthLabel={MonthLabel}
                            WardValue={WardValue}
                            WardLabel={WardLabel}
                            ProjectTypeId={ProjectTypeId}
                            DashboardName={DashboardName} />

                        <div className="col-12 col-lg-4">
                            <div className="row">
                                <div className="col-12">
                                    <div className=" px-3 mt-3 pb-2 bg-white rounded rounded-3">
                                        <div className="row d-flex justify-content-center align-items-center">
                                            <Pie
                                                height={130}
                                                // width={00}
                                                options={ServicePieoptions}
                                                data={ServicePiedata}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="mt-4 m-1 py-2 pb-2 master_border">
                        
                            
                    </div> */}

                    {/* <!--  --> */}

                    <div className="row mt-4 mb-4 m-1 py-2 pb-2 master_border">

                        <div className="col-12 col-lg-12 ">
                            <SewerageAssignCardManage
                                Countdata={Countdata}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName} />
                        </div>

                    </div>
                    {/* <!--  --> */}
                </div>
            </div>
            {/* <!-- [ Main Content ] end --> */}

        </div>
    )
}