
import { useEffect } from 'react'
import Select from 'react-select'
import { JunctionNameDDLAPI, } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'

export const JunctionNameDataDDL = (props) => {
    const { JunctionNameDDL, setJunctionNameDDL, CropNameDashboardData, PopUpField,IsPost,WardName } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_UserID: UserID,
            token: token,
            WardName:WardName.ID
        }
        dispatch(JunctionNameDDLAPI({ data }))
    }, [IsPost,WardName.ID])

    const { JunctionNameData } = useSelector(state => state.JunctionNameDDLData)

    useEffect(() => {
        handleJunctionNameDDL()
    }, [JunctionNameData])

    const handleJunctionNameDDL = () => {
        if (JunctionNameData && JunctionNameData.table && JunctionNameData.table.length > 0) {
            let list = JunctionNameData.table.map((item, index) => ({
                value: item.m_wS_JunctionID,
                label: item.junctionDetails,
            }))

            setJunctionNameDDL({
                DDL: list,
                ID: 0,
                Label: "Select...",
            })
        }
        else {
            setJunctionNameDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofEmployee"><b>Junction Name </b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width3"
                isSearchable
                maxMenuHeight={150}
                value={{ value: JunctionNameDDL.ID, label: JunctionNameDDL.Label }}
                onChange={(e) => {
                    e ?
                        setJunctionNameDDL({ ...JunctionNameDDL, ID: e.value, Label: e.label })
                        :
                        setJunctionNameDDL({ ...JunctionNameDDL, ID: 0, Label: "Select..." })

                }}
                options={JunctionNameDDL.DDL}
            />
        </div>
    )
}