
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { RoleNameDDLAPI } from '../../Redux/DDLSlice'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'

export const RoleNameDataDDL = (props) => {
    const { Role, setRole, rowData, Flag, setDesignation,Designation,setEmployeeDDL,EmployeeDDL } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token }
        dispatch(RoleNameDDLAPI({ data }))
    }, [])

    const { RoleNameData } = useSelector(state => state.RoleNameDDLData)


    useEffect(() => {
        handleRoleDDL()
    }, [RoleNameData])

    const handleRoleDDL = () => {
        // console.log(DeptDDLDataa)
        if (RoleNameData && RoleNameData.table && RoleNameData.table.length > 0) {
            let list = RoleNameData.table.map((item, index) => ({
                value: item.id,
                label: item.role,
            }))

            setRole({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_RoleID : 0,
                Label: Flag === 'Update' ? rowData.m_RoleName : "Select...",
            })
        }
        else {
            setRole({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofWorkType"><b>{t('Sidebar.Role')} { Flag && <AstricSign />}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: Role.ID, label: Role.Label }}
                onChange={(e) => {
                    e ?
                        setRole({ ...Role, ID: e.value, Label: e.label })
                        :
                        setRole({ ...Role, ID: 0, Label: "Select..." })
                        setDesignation({ ...Designation, ID: 0, Label: "Select..." })
                        setEmployeeDDL({ ...EmployeeDDL, ID: 0, Label: "Select..." })

                }}
                options={Role.DDL}
            />

        </div>
    )
}