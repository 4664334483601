import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const LocationMasterTableDataAPI = createAsyncThunk("LocationMasterTableData", async ({ data }) => {
    const {
        M_SW_LocationID,
        M_WardID,
        SWLocation,
        M_UsersID,
        token,
        From,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_WEB_M_SW_Location_Select?M_SW_LocationID=${M_SW_LocationID}&M_WardID=${M_WardID ? M_WardID : '0'}&SWLocation=${SWLocation ? SWLocation : ''}&M_UserID=${M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const LocationMasterTableDataSlice = createSlice({
    name: "LocationMasterTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(LocationMasterTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(LocationMasterTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(LocationMasterTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const LocationMasterTableDataReducer = LocationMasterTableDataSlice.reducer


export const LocationMasterPostDataAPI = createAsyncThunk("LocationMasterPostData", async ({ data }) => {
    const {
        M_SW_LocationID,
        M_WardID,
        SWLocation,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_SW_LocationID", M_SW_LocationID);
    formdata.append("M_WardID", M_WardID);
    formdata.append("SWLocation", SWLocation);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_WEB_M_SW_Location_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const LocationMasterPostDataSlice = createSlice({
    name: "LocationMasterPostData",
    initialState: {
        isPostLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(LocationMasterPostDataAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(LocationMasterPostDataAPI.fulfilled, (state, action) => {
            state.isPostLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(LocationMasterPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const LocationMasterPostDataReducer = LocationMasterPostDataSlice.reducer

// -----------------Delete API ---------------

export const LocationMasterDeleteAPI = createAsyncThunk("LocationMasterDelete", async ({ data }) => {
    const {
        M_SW_LocationID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    var formdata = new FormData();
    formdata.append("M_SW_LocationID", M_SW_LocationID);
    formdata.append("M_UserID", M_UserID);
    
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/Master/WEB_M_SW_Location_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const LocationMasterDeleteSlice = createSlice({
    name: "LocationMasterDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(LocationMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(LocationMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(LocationMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const LocationMasterDeleteReducer = LocationMasterDeleteSlice.reducer