
import React, { useState, useEffect, useMemo } from "react";
import ReactPaginate from "react-paginate";
import './Pegination.css'

export const Pegination = ({ PerPageCount, TotalCount, setTo, setFrom, setrowNo, setCurrentPage, CurrentPage, IsClear }) => {


    // We start with an empty list of items.

    // first
    // const [pageCount, setPageCount] = useState(0);
    
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    // const [itemOffset, setItemOffset] = useState(CurrentPage);

    // useEffect(()=>{
    //     setItemOffset(CurrentPage)
    // },[CurrentPage])
    // console.log(CurrentPage)


    // useEffect(() => {
        // Fetch items from another resources.

        // first
        // const endOffset = CurrentPage + PerPageCount;


        // console.log(`Loading items from ${CurrentPage} to ${endOffset}`);

        // first
        // setPageCount(Math.ceil(TotalCount / PerPageCount));
        // setTo(endOffset)
        // setFrom(CurrentPage + 1)


        // setrowNo(itemOffset + 1)

        // first
    // }, [CurrentPage, TotalCount]);

    // Invoke when user click to request another page.

    // first
    // const handlePageClick = (event) => {
    //     var newOffset = event.selected * PerPageCount % TotalCount;


        // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);

        // first
        // setCurrentPage(newOffset)


        // setItemOffset(newOffset);
        // setFrom(newOffset+1)

        // first
        // setrowNo(event.selected + 1)
    // };



    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        let paglink = document.querySelectorAll('.page-item');
        paglink[1].firstChild.click();
    }, [IsClear])


    useMemo(() => {
        const endOffset = CurrentPage + PerPageCount;
        setPageCount(Math.ceil(TotalCount / PerPageCount));
        setTo(endOffset)
        setFrom(CurrentPage + 1)
    }, [CurrentPage, TotalCount]);

    const handlePageClick = (event) => {
        var newOffset = event.selected * PerPageCount % TotalCount;
        // console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setCurrentPage(newOffset)
    };

    return (
        <div className="mt-3 d-flex justify-content-end pb-2">
            <ReactPaginate
                // nextLabel="next >"
                // onPageChange={handlePageClick}

                // // forcePage={CurrentPage}

                // pageRangeDisplayed={5}
                // marginPagesDisplayed={4}

                // // pageRangeDisplayed={3}
                // // marginPagesDisplayed={1}
                // // initialPage={CurrentPage}

                // pageCount={pageCount}
                // previousLabel="< previous"
                // pageClassName="page-item"
                // pageLinkClassName="page-link"
                // previousClassName="page-item"
                // previousLinkClassName="page-link"
                // nextClassName="page-item"
                // nextLinkClassName="page-link"
                // breakLabel="..."
                // breakClassName="page-item"
                // breakLinkClassName="page-link"
                // containerClassName="pagination"
                // activeClassName="active"
                
                // // renderOnZeroPageCount={null}


                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                marginPagesDisplayed={4}
                pageCount={pageCount}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
                forcePage={itemOffset}
            />
        </div>
    )
}