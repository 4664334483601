
import { useEffect } from 'react'
import Select from 'react-select'
import {WaterSupplyPumpingStationDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AstricSign } from '../../Helper/AstricSign'
import { useAuthState } from '../../Helper/Context'


export const WaterSupplyPumpingStationDataDDL = (props) => {
    const { WaterSupplyPumpingStation, setWaterSupplyPumpingStation, Flag, rowData, IsDisabled, WardName } = props
    // console.log(rowData)
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token,M_WardID:WardName.ID }
        dispatch(WaterSupplyPumpingStationDDLAPI({ data }))
    }, [WardName.ID])


    const { WaterSupplyPumpingStationData } = useSelector(state => state.WaterSupplyPumpingStationDDLData)

    useEffect(() => {
        handleCropNameDDL()
    }, [WaterSupplyPumpingStationData])

    const handleCropNameDDL = () => {
        if (WaterSupplyPumpingStationData && WaterSupplyPumpingStationData.table && WaterSupplyPumpingStationData.table.length > 0) {
            let list = WaterSupplyPumpingStationData.table.map((item, index) => ({
                value: item.m_wS_PumpStationID,
                label: item.pumpStation,
            }))
            setWaterSupplyPumpingStation({
                DDL: list,
                // ID: rowData.m_wS_PumpStationID ,
                // Label: rowData.pumpStation 
                Id: 0,
                Label:  "Select...",
            })
        }
        else {
            setWaterSupplyPumpingStation({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }


    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Pumping Station {Flag && <AstricSign />} :</b></label>
            <Select
                // isClearable={isClearable === false ? false : true}
                // isRtl={isRtl}
                // className='ddl_width3'
                isDisabled={Flag === 'Update' && IsDisabled ? true : false}
                isSearchable
                maxMenuHeight={150}
                value={{ value: WaterSupplyPumpingStation.ID, label: WaterSupplyPumpingStation.Label }}
                onChange={(e) => {
                    e ?
                        setWaterSupplyPumpingStation({ ...WaterSupplyPumpingStation, ID: e.value, Label: e.label })
                        :
                        setWaterSupplyPumpingStation({ ...WaterSupplyPumpingStation, ID: 1, Label: "Select..." })

                }}
                options={WaterSupplyPumpingStation.DDL}
            />
        </div>
    )
}