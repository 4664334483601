import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const PlayEquipmentMasterTableDataAPI = createAsyncThunk("PlayEquipmentMasterTableData", async ({ data }) => {
    const {
        M_AssetWiseEquipmentID,
        M_Contractor_ID,
        UserID,
        token,
        From,
        To,
        M_WardID,
        M_ZoneID,
        M_AssetID,
        Language,
        setQRPDF,
        handleGetExport
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_AssetWiseEquipment_Select?M_AssetWiseEquipmentID=${M_AssetWiseEquipmentID ? M_AssetWiseEquipmentID : '0'}&M_Contractor_ID=${M_Contractor_ID ? M_Contractor_ID : '0'}&M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&M_UsersID=${UserID}&Language=${Language}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setQRPDF(result.url)
                handleGetExport && handleGetExport()
                return result.data
            } else {
                return result
            }
        })
})

const PlayEquipmentMasterTableDataSlice = createSlice({
    name: "PlayEquipmentMasterTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PlayEquipmentMasterTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PlayEquipmentMasterTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PlayEquipmentMasterTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const PlayEquipmentMasterTableDataReducer = PlayEquipmentMasterTableDataSlice.reducer


// Play Equipment Export Table Data

export const PlayEquipmentMasterTableExportDataAPI = createAsyncThunk("PlayEquipmentMasterTableExportData", async ({ data }) => {
    const {
        M_AssetWiseEquipmentID,
        M_Contractor_ID,
        UserID,
        token,
        From,
        To,
        M_WardID,
        M_ZoneID,
        M_AssetID,
        Language,
        setQRPDF
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_AssetWiseEquipment_Select?M_AssetWiseEquipmentID=${M_AssetWiseEquipmentID ? M_AssetWiseEquipmentID : '0'}&M_Contractor_ID=${M_Contractor_ID ? M_Contractor_ID : '0'}&M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&M_UsersID=${UserID}&Language=${Language}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setQRPDF(result.url)
                return result.data
            } else {
                return result
            }
        })
})

const PlayEquipmentMasterTableExportDataSlice = createSlice({
    name: "PlayEquipmentMasterTableExportData",
    initialState: {
        isExportLoading: false,
        ExportTableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PlayEquipmentMasterTableExportDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(PlayEquipmentMasterTableExportDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExportTableData = action.payload;
        });
        builder.addCase(PlayEquipmentMasterTableExportDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExportTableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const PlayEquipmentMasterTableExportDataReducer = PlayEquipmentMasterTableExportDataSlice.reducer


// InsertUpdate Table Data

export const PlayEquipmentMasterPostDataAPI = createAsyncThunk("PlayEquipmentMasterPostData", async ({ data }) => {
    const {
        M_AssetID,
        M_AssetWiseEquipmentID,
        EquipmentName,
        // M_GD_SectorID,
        EquipmentNumber,
        EquipmentInstallationDate,
        M_ContractorID,
        WarrantyPeriod,
        CMCPeriod,
        M_StatusID,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_AssetWiseEquipmentID", M_AssetWiseEquipmentID);
    formdata.append("M_AssetID", M_AssetID);
    formdata.append("EquipmentName", EquipmentName);
    formdata.append("EquipmentNumber", EquipmentNumber);
    formdata.append("EquipmentInstallationDate", EquipmentInstallationDate);
    formdata.append("M_ContractorID", M_ContractorID);
    formdata.append("WarrantyPeriod", WarrantyPeriod);
    formdata.append("CMCPeriod", CMCPeriod);
    formdata.append("Flag", Flag);
    formdata.append("M_StatusID", M_StatusID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_AssetWiseEquipment_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                // handleOnCloseClick()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                handleOnCloseClick()
                toastErrorr(result.message)
            }

            return result
        })
})

const PlayEquipmentMasterPostDataSlice = createSlice({
    name: "PlayEquipmentMasterPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PlayEquipmentMasterPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PlayEquipmentMasterPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PlayEquipmentMasterPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PlayEquipmentMasterPostDataReducer = PlayEquipmentMasterPostDataSlice.reducer



// Delete Table Data

export const PlayEquipmentMasterDeleteAPI = createAsyncThunk("PlayEquipmentMasterDelete", async ({ data }) => {

    const {
        M_AssetWiseEquipmentID,
        M_UserID,
        handleIsPost,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_AssetWiseEquipmentID", M_AssetWiseEquipmentID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Post_M_AssetWiseEquipment_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                // handleDeleteCloseClick()
            } else {
                toastErrorr(result.message)
                // handleDeleteCloseClick()
            }
            return result

        })
})

const PlayEquipmentMasterDeleteSlice = createSlice({
    name: "PlayEquipmentMasterDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PlayEquipmentMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(PlayEquipmentMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            toastSuccesss(action.payload.message)
        });
        builder.addCase(PlayEquipmentMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const PlayEquipmentMasterDeleteReducer = PlayEquipmentMasterDeleteSlice.reducer

// Qr Download Report 

export const PlayEquipmentMasterQrReportDataAPI = createAsyncThunk("PlayEquipmentMasterQrReportData", async ({ data }) => {
    const {
        M_AssetWiseEquipmentID,
        M_Contractor_ID,
        UserID,
        token,
        From,
        To,
        M_WardID,
        M_ZoneID,
        M_AssetID,
        Language,
        handleGetQrReportUrl
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_RM_M_AssetWiseEquipment_Select?M_AssetWiseEquipmentID=${M_AssetWiseEquipmentID ? M_AssetWiseEquipmentID : '0'}&M_Contractor_ID=${M_Contractor_ID ? M_Contractor_ID : '0'}&M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&M_UsersID=${UserID}&Language=${Language}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                handleGetQrReportUrl(result.url)
                return result.data
            } else {
                handleGetQrReportUrl('')
                return result
            }
        })
})

const PlayEquipmentMasterQrReportDataSlice = createSlice({
    name: "PlayEquipmentMasterQrReportData",
    initialState: {
        isQrReportLoading: false,
        QrReportData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PlayEquipmentMasterQrReportDataAPI.pending, (state, action) => {
            state.isQrReportLoading = true;
        });
        builder.addCase(PlayEquipmentMasterQrReportDataAPI.fulfilled, (state, action) => {
            state.isQrReportLoading = false;
            state.QrReportData = action.payload;
        });
        builder.addCase(PlayEquipmentMasterQrReportDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isQrReportLoading = false;
            state.isError = true;
            state.QrReportData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const PlayEquipmentMasterQrReportDataReducer = PlayEquipmentMasterQrReportDataSlice.reducer
