import React from 'react'
import SeweragePreventiveCard from './SeweragePreventive'
import { useNavigate } from 'react-router-dom'


export default function SeweragePreventiveCardManage(props) {

    const { Countdata,HeaderMonthID,HeaderFinancialYear , MonthValue, YearValue , YearLabel,MonthLabel,WardValue,WardLabel,ProjectTypeId,DashboardName } = props
   
    const PreventiveWorkNavigate = (name, screenFlag) => {

        if (name === "Pumping Operation") {
            navigate(`/preventivepumping?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Security") {
            navigate(`/preventivesecurity?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Energy Audit") {
            navigate(`/preventiveenergyaudit?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "House Keeping") {
            navigate(`/preventivehousekeeping?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }
    const navigate = useNavigate()

    return (
        <div className="col-12 col-lg-8">
            <div className="row">
                <div className="col-12">
                    <h5 className="ps-2 py-2 pt-3">Preventive Work</h5>
                </div>
                <div className="col-12 col-lg-6 mb-2">
                    <SeweragePreventiveCard
                        title="Pumping Operation"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/pump.png'
                        count={Countdata.PumpingOperationTotalCount}
                        // navLink='/preventivepumping'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    />
                </div>

                {/* <div className="col-12 col-lg-6 mb-2">
                    <SeweragePreventiveCard
                        title="Customer Services"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/customer-servic.png'
                        count='100'
                        navLink='/preventivecustomer'
                    />

                </div> */}
                <div className="col-12 col-lg-6 mb-2">
                    <SeweragePreventiveCard
                        title="Security"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/insurance.png'
                        count={Countdata.SecurityTotalCount}
                        // navLink='/preventivesecurity'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    />

                </div>

                <div className="col-12 col-lg-6 mb-2">
                    <SeweragePreventiveCard
                        title="Energy Audit"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/renewable-energy1.png'
                        count={Countdata.EnergyAuditTotalCount}
                        // navLink='/preventiveenergyaudit'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    />

                </div>

                <div className="col-12 col-lg-6 mb-2">
                    <SeweragePreventiveCard
                        title="House Keeping"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/house-cleaning.png'
                        count={Countdata.HouseKeepingTotalCount}
                        // navLink='/preventivehousekeeping'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    />

                </div>

            </div>
        </div>

    )

}

