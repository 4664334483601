
import React from 'react'
import { Loader } from 'react-overlay-loader';
import 'react-overlay-loader/styles.css';
import Pie_Chart from './Pie_Chart';


export function EmployeeAndContractPieChart(props) {
const {
    tableData,
    ShowGraph,
    SpLoader
} = props


    var PendingCount1 = []
    var PendingLabel1 = []
    var PendingColor1 = []

    const [PendingCount, setPendingCount] = React.useState()
    const [PendingLabel, setPendingLabel] = React.useState()
    const [PendingColor, setPendingColor] = React.useState()
    const [DaysFilter, setDaysFilter] = React.useState('All-Day')
    const [PieLoader, setPieLoader] = React.useState(false)
    const [PieFlag, setPieFlag] = React.useState('All')

    React.useEffect(() => {
        PieData()
    }, [tableData, PieFlag])

    const PieData = () => {

        // console.log('tableData', tableData)
     
        setPieLoader(true)
        var dynamicColors = function () {
            var r = Math.floor(Math.random() * 255);
            var g = Math.floor(Math.random() * 255);
            var b = Math.floor(Math.random() * 255);
            // console.log("rgb(" + r + "," + g + "," + b + ")")
            PendingColor1.push("rgb(" + r + "," + g + "," + b + ")")
            return "rgb(" + r + "," + g + "," + b + ")";
        };
        setPendingColor(PendingColor1)

        for (var i = 0; i < tableData.table?.length; i++) {
            dynamicColors()
        }

        for (var i = 0; i < tableData.table?.length; i++) {
            PendingLabel1.push(tableData.table[i].employeeName)
            console.log('tableData', tableData.table)
            if (PieFlag == 'All') {
                PendingCount1.push(tableData.table[i].pendingCount)
            }
            else if (PieFlag == '0_7DaysCount') {
                PendingCount1.push(tableData.table[i].pending_0_7DaysCount)
            }
            else if (PieFlag == '7_15DaysCount') {
                PendingCount1.push(tableData.table[i].pending_7_15DaysCount)
            }
            else if (PieFlag === '15_30DaysCount') {
                PendingCount1.push(tableData.table[i].pending_15_30DaysCount)
            }
            else if (PieFlag === '30_90DaysCount') {
                PendingCount1.push(tableData.table[i].pending_30_90DaysCount)
            }
            else if (PieFlag === '90_180DaysCount') {
                PendingCount1.push(tableData.table[i].pending_90_180DaysCount)
            }
            else {
                PendingCount1.push(tableData.table[i].pending_365DaysCount)
            }
        }
        setPendingCount(PendingCount1)
        setPendingLabel(PendingLabel1)
        setTimeout(() => {
            setPieLoader(false)
        }, 100);

    }


    function zeroTest(element) {
        return element === 0;
    }

    var allZeros

    if (PendingCount) {
        allZeros = PendingCount.every(zeroTest);
    }

    if (PendingCount) {
        allZeros = PendingCount.every(zeroTest);
    }



    const chart2 = () => {
        return {
            labels: PendingLabel,
            datasets: [
                {
                    label: "Team points 2",
                    data: PendingCount,
                    backgroundColor: PendingColor
                }
            ]
        };
    };


    return (
        <div className="col-12 col-md-6 col-xxl-6 d-flex order-2 order-xxl-3 pl-2 pr-0" >
            <div className="spcard flex-fill w-100">

                <div className="spcard-header">
                    {/* <h5 className="spcard-title mb-0">{headingsData && headingsData.sec2Heading ? headingsData.sec2Heading : ""} कार्यालया नुसार प्रलंबित पत्राचा गोषवारा</h5> */}
                    <div className='row mr-1'>
                        {/* <div className="col-12 col-lg-2 col-xl-2 col-md-11 col-sm-11 mt-2">
                            <h5 className="spcard-title mb-0">प्रलंबित पत्र</h5>
                        </div> */}

                        <div className="btn-group col-12 col-lg-12 col-xl-12 col-md-12 col-sm-12">
                            <button type="button"
                                onClick={() => setPieFlag('All')}
                                className={PieFlag == 'All' ? "btn btn-outline-custom-primary active" : "btn btn-outline-custom-primary"}>
                                एकूण
                            </button>

                            <button type="button"
                                onClick={() => setPieFlag('0_7DaysCount')}
                                className={PieFlag == '0_7DaysCount' ? "btn btn-outline-custom-primary active" : "btn btn-outline-custom-primary"}>

                                0 ते 7 दिवस
                            </button>

                            <button type="button"
                                onClick={() => setPieFlag('7_15DaysCount')}
                                className={PieFlag == '7_15DaysCount' ? "btn btn-outline-custom-primary active" : "btn btn-outline-custom-primary"}>

                                7 ते 15 दिवस
                            </button>

                            <button type="button"
                                onClick={() => setPieFlag('15_30DaysCount')}
                                className={PieFlag == '15_30DaysCount' ? "btn btn-outline-custom-primary active" : "btn btn-outline-custom-primary"}>

                                15 ते 30 दिवस
                            </button>

                            <button type="button"
                                onClick={() => setPieFlag('30_90DaysCount')}
                                className={PieFlag == '30_90DaysCount' ? "btn btn-outline-custom-primary active" : "btn btn-outline-custom-primary"}>

                                30 ते 90 दिवस
                            </button>

                            <button type="button"
                                onClick={() => setPieFlag('90_180DaysCount')}
                                className={PieFlag == '90_180DaysCount' ? "btn btn-outline-custom-primary active" : "btn btn-outline-custom-primary"}>

                                90 ते 180 दिवस
                            </button>

                            <button type="button"
                                onClick={() => setPieFlag('365DaysCount')}
                                className={PieFlag == '365DaysCount' ? "btn btn-outline-custom-primary active" : "btn btn-outline-custom-primary"}>

                                1 वर्षा पुढील
                            </button>
                        </div>


                    </div>
                </div>

                <div className="spcard-body d-flex" style={{ opacity: !ShowGraph ? "0" : '1', transition: 'all 2s', visibility: !ShowGraph ? "hidden" : "visible" }}>
                    <div className="align-self-center w-100">
                        <div className="py-3">
                            {
                                PieLoader || SpLoader ?
                                    <div className="Chart" style={{ maxHeight: '45vh' }}>
                                        <Loader loading />
                                    </div>
                                    :

                                    PendingCount && allZeros === false ?

                                        <Pie_Chart
                                            chart2={chart2()}
                                        />
                                        :


                                        <div className='norecordlabel' style={{ height: '51vh', marginTop: '-3%' }}>
                                            {/* <img
                                                src="assets/img/folder.png"
                                                style={{ height: "40px", marginLeft: "10px" }}
                                            /> */}
                                            <text className=''>&nbsp;&nbsp;No Record Found ...</text>
                                        </div>

                            }


                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}