
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastInfo, toastSuccesss } from "../../Helper/ToastMessage";

// Get Table Data
export const GetMapViewDataAPI = createAsyncThunk("GetMapViewData", async ({ data }) => {
    const {
        token,
        M_FinancialYearID,
        M_MonthID,
        M_WardID,
        M_RM_LocationID,
        M_RM_WorkID,
        M_UsersID,
        Flag,
        From,
        To,
        handleShowMap
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_RM_T_KPI_WorkProgress_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID ? M_MonthID : '0'}&M_WardID=${M_WardID}&M_RM_LocationID=${M_RM_LocationID}&M_RM_WorkID=${M_RM_WorkID}&M_UsersID=${M_UsersID}&Flag=${Flag}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                handleShowMap()
                return result.data.table
            } else {
                toastInfo(result.message)
                return result
            }
        })
})

const GetMapViewDataSlice = createSlice({
    name: "GetMapViewData",
    initialState: {
        isMapLoading: false,
        MapViewData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GetMapViewDataAPI.pending, (state, action) => {
            state.isMapLoading = true;
        });
        builder.addCase(GetMapViewDataAPI.fulfilled, (state, action) => {
            state.isMapLoading = false;
            state.MapViewData = action.payload;
        });
        builder.addCase(GetMapViewDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isMapLoading = false;
            state.isError = true;
            state.MapViewData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GetMapViewDataReducer = GetMapViewDataSlice.reducer