
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import Header from '../../../../../Components/Header/Header'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { useAuthState } from '../../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { WorkProgressTableDataAPI } from '../../../../../Redux/RoadDashboardSlice/WorkProgressSlice';
import { MonthDataDDL } from '../../../../../Components/CommonDDL/MonthDataDDL';
import { WardNameDataDDL } from '../../../../../Components/CommonDDL/WardNameDataDDL';
import moment from 'moment'
import { Loading } from '../../../../../Helper/Loading';
import ViewURL from '../../../../../Components/ViewURL/ViewURL';
import { Pegination } from '../../../../../Components/Pegination/Pegination';



export default function RoadWorkeEstimated() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")


    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)

    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [Heading, setHeading] = useState('')

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: HYear && HYear ? HYear : '0',
        Label: YLable && YLable ? YLable : "Select...",
    })

    const [Month, setMonth] = useState({
        DDL: [],
        ID: HMonth && HMonth ? HMonth : '0',
        Label: MLable && MLable ? MLable : "Select...",
    });

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })

    const [RoadWorkName, setRoadWorkName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)

        setMonth({
            ...Month,
            ID: HMonth,
            Label: MLable,
        })
        setWardName({
            ...WardName,
            ID: HWard,
            Label: WLable,
        })
        // setAssetType({
        //     ...AssetType,
        //     ID: 0,
        //     Label: 'Select...',
        // })

        // console.log("call")
    }

    const handlePost = () => {
        setIsPost(!IsPost)
    }
    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }

    useEffect(() => {
        const data = {
            M_WardID: WardName.ID,
            M_FinancialYearID: HYear,
            M_MonthID: Month.ID,
            M_RM_LocationID: '0',
            M_RM_WorkID: '0',
            M_UsersID: UserID,
            token: token,
            From: From,
            To: To,
            Flag: 'Estimated_Amt',
        }
        if (HYear && Month.ID && WardName.ID) {
            dispatch(WorkProgressTableDataAPI({ data }))
        }
    }, [IsPost, To, IsClear, Month.ID, WardName.ID, RoadWorkName.ID, HYear])

    const { tableData, isLoading } = useSelector(state => state.WorkProgressTableData)

    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select...",
    // })

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    DashboardHeading="Road Maintainance"
                    setYearValue={setYearValue}
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}
                />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId} />
                    <div className="content">
                        <div className="page-header ">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        {/* <div className="page-header-title">
                                            <h5 className="ps-2 data_mob">ESTIMATED AMOUNT */}

                                        {/* <!-- <span className="float-end">namita</span> --> */}
                                        {/* <span className="float-end fs-6">
                                                <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                        :
                                                        setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                }}
                                                options={SelectWard.DDL}
                                            />

                                            </span> */}
                                        {/* </h5>

                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- [ Main Content ] start --> */}
                        <div className='data_mob'>
                            <div className="row m-1 py-1 pb-4 master_border">
                                <h5 className="ps-3">Estimated Amount
                                    <button type="button" className="btn btn-primary float-end mr-1">Export</button>
                                </h5>
                                <div className="row m-0 ">
                                    <div className="col-12 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                        <div className="col-12">

                                            <div className="row rounded2">

                                                <div className="col-12 col-md-5 col-lg-3 ">
                                                    <MonthDataDDL
                                                        Month={Month}
                                                        setMonth={setMonth}
                                                        MLable={MLable}
                                                        HMonth={HMonth}
                                                        Flag='Month'
                                                    />
                                                </div>
                                                <div className="col-12 col-md-5 col-lg-3 ">
                                                    <WardNameDataDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        HWard={HWard}
                                                        WLable={WLable}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-2 col-lg-1 mt-lg-4 mt-md-4 ">
                                                    <button type="button"

                                                        className="btn-clear btn btn-primary"
                                                        onClick={handleClear}>Clear</button>

                                                </div>
                                            </div>
                                            <div className="row mob_table table-responsive pt-1">
                                                <table id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">

                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            <th>Location Name</th>
                                                            <th>Work Area</th>
                                                            <th>Work Name</th>
                                                            <th>Work Number</th>
                                                            <th>Before Photo Date</th>
                                                            <th>Before Photo</th>
                                                            <th>After Photo Date</th>
                                                            <th>After Photo</th>
                                                            <th>Estimated Amount (Rs.)</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => (
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum}</td>
                                                                    <td>{item.locationName ? item.locationName : '-'}</td>
                                                                    <td>{item.workArea ? item.workArea : '-'}</td>
                                                                    <td>{item.workName ? item.workName : '-'}</td>
                                                                    <td>{item.workNumber ? item.workNumber : '-'}</td>
                                                                    <td>{item.workUploadDate ? moment(item.workUploadDate).format("DD-MM-YYYY") : '-'}</td>
                                                                    {/* <td>
                                                                        <span >
                                                                            <i class="fa-solid fa-eye ms-3" style={{ cursor: 'pointer' }} title='View'
                                                                                onClick={() => (handleShowImage(item.beforeWork), setHeading('Before Photo'))}
                                                                            ></i>
                                                                        </span>
                                                                    </td> */}
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.beforeWork == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye" title='View'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => handleShowImage(item.beforeWork, 'Start Photo')}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    <td>{item.doneDate ? moment(item.doneDate).format("DD-MM-YYYY") : '-'}</td>
                                                                    {/* <td>
                                                                        <span >
                                                                            <i class="fa-solid fa-eye ms-3" style={{ cursor: 'pointer' }} title='View'
                                                                                onClick={() => (handleShowImage(item.afterWork), setHeading('After Photo'))}
                                                                            ></i>
                                                                        </span>
                                                                    </td> */}
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.afterWork == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye" title='View'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => handleShowImage(item.afterWork, 'Start Photo')}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    <td>{item.estimatedAmount ? item.estimatedAmount.toFixed(2) : '-'}</td>
                                                                </tr>
                                                            ))
                                                                :
                                                                <></>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showImagePopup ?
                        <ViewURL
                            Heading={Heading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''


                }
            </div>
        </>
    )
}