import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";


// Get Table Data
export const WaterSupplyPumpStationTableDataAPI = createAsyncThunk("WaterSupplyPumpStationTableData", async ({ data }) => {
    const {
        M_WS_PumpStationID,
        token,
        FromTop,
        ToTop,
        M_WardID,
        PumpStation,
        M_UserID
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_WEB_M_WS_PumpStation_Select?M_WS_PumpStationID=${M_WS_PumpStationID ? M_WS_PumpStationID : '0'}&M_WardID=${M_WardID}&PumpStation=${PumpStation}&M_UserID=${M_UserID}&FromTop=${FromTop}&ToTop=${ToTop}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WaterSupplyPumpStationTableDataSlice = createSlice({
    name: "WaterSupplyPumpStationTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WaterSupplyPumpStationTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WaterSupplyPumpStationTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WaterSupplyPumpStationTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WaterSupplyPumpStationTableDataReducer = WaterSupplyPumpStationTableDataSlice.reducer


// InsertUpdate Table Data

export const WaterSupplyPumpStationPostDataAPI = createAsyncThunk("WaterSupplyPumpStationPostData", async ({ data }) => {
    const {
        M_WS_PumpStationID,
        M_WardID,
        PumpStation,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        // handleClearField,
        handleOnCloseClick

    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WS_PumpStationID", M_WS_PumpStationID);
    formdata.append("M_WardID", M_WardID);
    formdata.append("PumpStation", PumpStation);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_WEB_M_WS_PumpStation_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                handleOnCloseClick()
                toastErrorr(result.message)
            }

            return result
        })
})

const WaterSupplyPumpStationPostDataSlice = createSlice({
    name: "WaterSupplyPumpStationPostData",
    initialState: {
        isPostLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WaterSupplyPumpStationPostDataAPI.pending, (state, action) => {
            state.isPostLoading = true;
        });
        builder.addCase(WaterSupplyPumpStationPostDataAPI.fulfilled, (state, action) => {
            state.isPostLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WaterSupplyPumpStationPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPostLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const WaterSupplyPumpStationPostDataReducer = WaterSupplyPumpStationPostDataSlice.reducer



// // Delete Table Data

export const WaterSupplyPumpStationMasterDeleteAPI = createAsyncThunk("WaterSupplyPumpStationMasterDelete", async ({ data }) => {

    const {
        M_WS_PumpStationID,
        M_UserID,
        handleIsPost,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WS_PumpStationID", M_WS_PumpStationID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/WEB_M_WS_PumpStation_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                // handleDeleteCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
                // handleDeleteCloseClick()
            }
            return result

        })
})

const WaterSupplyPumpStationMasterDeleteSlice = createSlice({
    name: "WaterSupplyPumpStationMasterDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WaterSupplyPumpStationMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(WaterSupplyPumpStationMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(WaterSupplyPumpStationMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WaterSupplyPumpStationMasterDeleteReducer = WaterSupplyPumpStationMasterDeleteSlice.reducer