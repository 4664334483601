import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const WorkTypeTableDataAPI = createAsyncThunk("WorkTypeTableData", async ({ data }) => {
    const {
        M_WorkTypeID,
        workTypeName,
        M_UserID,
        token,
        From,
        To,
        Language
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_M_WorkType_Select?M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&WorkTypeName=${workTypeName}&M_UserID=${M_UserID}&From=${From}&To=${To}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WorkTypeTableDataSlice = createSlice({
    name: "WorkTypeTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkTypeTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkTypeTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WorkTypeTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WorkTypeTableDataReducer = WorkTypeTableDataSlice.reducer


export const WorkTypePostDataAPI = createAsyncThunk("WorkTypePostData", async ({ data }) => {
    const {
        M_WorkTypeID,
        WorkTypeName,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WorkTypeID", M_WorkTypeID);
    formdata.append("WorkTypeName", WorkTypeName);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_WorkType_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const WorkTypePostDataSlice = createSlice({
    name: "WorkTypePostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkTypePostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkTypePostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WorkTypePostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const WorkTypePostDataReducer = WorkTypePostDataSlice.reducer

// // -----------------Delete API ---------------

export const WorkTypeDeleteAPI = createAsyncThunk("WorkTypeDelete", async ({ data }) => {
    const {
        M_WorkTypeID,
        M_UserID,
        token,
        Flag,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WorkTypeID", M_WorkTypeID);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_WorkType_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const WorkTypeDeleteSlice = createSlice({
    name: "WorkTypeDelete",
    initialState: {
        isDeleteLoading: false,
        WorkTypetableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkTypeDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(WorkTypeDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.WorkTypetableData = action.payload;

        });
        builder.addCase(WorkTypeDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.WorkTypetableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const WorkTypeDeleteReducer = WorkTypeDeleteSlice.reducer