import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const SewerageDashMainCountDataAPI = createAsyncThunk("SewerageDashMainCountData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_WardID,
        M_MonthID,
        M_UserID,
        Flag,
        token,
        handleGetCount
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_Web_SW_MainCount_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : '0'}&M_MonthID=${M_MonthID ? M_MonthID :'0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_UserID=${M_UserID}&Flag=${Flag}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleGetCount(result.data.table)
                return result.data
            } else {
                return result
            }
        })
})

const SewerageDashMainCountDataSlice = createSlice({
    name: "SewerageDashMainCountData",
    initialState: {
        isLoading: false,
        SewerageDashCountData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SewerageDashMainCountDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SewerageDashMainCountDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.SewerageDashCountData = action.payload;
        });
        builder.addCase(SewerageDashMainCountDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.SewerageDashCountData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const SewerageDashMainCountDataReducer = SewerageDashMainCountDataSlice.reducer