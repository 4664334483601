
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

// Get Table Data
export const WorkAssignedTableDataAPI = createAsyncThunk("WorkAssignedTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_AssetTypeID,
        WorkAssignByM_UserID,
        M_UsersID,
        token,
        From,
        To,
        Flag,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_UploadAssignWork_Select?FromDate=${FromDate ? FromDate : ''}&ToDate=${ToDate ? ToDate : ''}&M_WardID=${M_WardID ? M_WardID : '0'}&WorkAssignByM_UserID=${WorkAssignByM_UserID ? WorkAssignByM_UserID : '0'}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_UsersID=${M_UsersID}&Flag=${Flag}&From=${From}&To=${To}`, requestOptions)
    // return fetch(`Get_WEB_GD_T_UploadAssignWork_Select?FromDate=&ToDate=&M_WardID=0&WorkAssignByM_UserID=0&M_AssetTypeID=0&M_UsersID=0&Flag=DMC&From=1&To=20`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WorkAssignedTableDataSlice = createSlice({
    name: "WorkAssignedTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkAssignedTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkAssignedTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WorkAssignedTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WorkAssignedTableDataReducer = WorkAssignedTableDataSlice.reducer


