import React from "react";
import { useNavigate } from "react-router-dom";

export default function GardenMaintainanceCard({ title, imgcss, cardName, navLink, imgPath, imgPathw, Active, Pathname, GardenMaintainanceNavigate }) {
    const navigate = useNavigate()

    const handleGardenMaintainanceCardClick = () => {
        navigate(navLink)
    }
    return (
        // <div className={cardName} onClick={handleGardenMaintainanceCardClick}>
            <div className={cardName} onClick={()=>GardenMaintainanceNavigate(title)}>
                <div className={Active}>
                    <img className="pt-1" style={{ width: "31px" }} src={Pathname == Pathname ? imgPath : imgPathw} alt="" />
                    <span className=" pt-1 pb-0 text-center">{title}</span>
                </div>
            </div>
            )
}