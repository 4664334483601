
import { useEffect } from 'react'
import Select from 'react-select'
import { AssetDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { useTranslation } from 'react-i18next'

export const AssetDataDDL = (props) => {
    const { AssetName, setAssetName, PopUpField,M_AssetTypeID } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token, M_AssetTypeID }
        dispatch(AssetDDLAPI({ data }))
    }, [])

    const { AssetData } = useSelector(state => state.AssetDDLData)

    useEffect(() => {
        handleAssetDDL()
    }, [AssetData])

    const handleAssetDDL = () => {
        if (AssetData && AssetData.table && AssetData.table.length > 0) {
            let list = AssetData.table.map((item, index) => ({
                value: item.m_AssetID,
                label: item.assetName,
            }))

            setAssetName({
                DDL: list,
                ID: 0,
                Label: "Select...",
            })
        }
        else {
            setAssetName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
        <label className="d-block" htmlFor="NameofDepartment">{t('Dashboard.Pcmc_Assets.Asset_Name')} </label>
        <Select
          // isClearable
          // isRtl={isRtl}
          isSearchable
          maxMenuHeight={150}
          value={{ value: AssetName.ID, label: AssetName.Label }}
          onChange={(e) => {
            e ?
              setAssetName({ ...AssetName, ID: e.value, Label: e.label })
              :
              setAssetName({ ...AssetName, ID: 0, Label: "Select..." })

          }}
          options={AssetName.DDL}
        />
      </div>
    )
}