
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { WardNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { useTranslation } from 'react-i18next'

export const DashBoardScreenWardDDL = (props) => {
    const { WardName, setWardName, WLable, HWard, flag, flag1, Language, M_ZoneID, Flag ,rowData} = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const d = new Date();
    let month = d.getMonth();

    const [MonthID, setMonthID] = useState(month + 1)

    useEffect(() => {
        const data = { UserID, token, Language: Language.Label, M_ZoneID: M_ZoneID }
        if (M_ZoneID != 0) {
            { Language.Label != '' && dispatch(WardNameDDLAPI({ data })) }
        }
        if (flag1 == 'GardenPopup') {
            { Language.Label != '' && dispatch(WardNameDDLAPI({ data })) }
        }
    }, [Language.Label, M_ZoneID])

    const { WardDDLData } = useSelector(state => state.WardNameDDLData)


    useEffect(() => {
        handleWardNameDDL()
    }, [WardDDLData])

    const handleWardNameDDL = () => {
        if (WardDDLData && WardDDLData.table && WardDDLData.table.length > 0) {
            let list = WardDDLData.table.map((item, index) => ({
                value: item.id,
                label: item.wardName,
            }))
            // if (flag == 'Screen') {
            //     setWardName({
            //         DDL: list,
            //         ID: HWard,
            //         Label: WLable,
            //     })
            // } else {
            setWardName({
                DDL: list,
                ID: Flag === 'WardDDL' ? list[0].value : 0,
                Label: Flag === 'WardDDL' ? list[0].label : 'Select',
                // ID: Flag === 'Update' ? rowData.m_WardID : Flag === 'Ward' && 'WardDDL' ? list[0].value : HWard ? HWard : 0,
                // Label: Flag === 'Update' ? rowData.wardName : Flag === 'Ward' && 'WardDDL' ? list[0].label : WLable ? WLable : 'Select...'
            })
            // }
        }
        else {
            setWardName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group mr-2" >
            <label className="d-block" htmlFor="NameofDepartment"><b>{t('Dashboard.Titles.Ward_Name')}</b></label>

            <Select
                // isClearable={isClearable === false ? false : true}
                // isRtl={isRtl}
                // className='ddl_width3'
                // isDisabled={Flag === 'Update' && IsDisabled ? true : false}
                isSearchable
                maxMenuHeight={150}
                value={{ value: WardName.ID, label: WardName.Label }}
                onChange={(e) => {
                    e ?
                        setWardName({ ...WardName, ID: e.value, Label: e.label })
                        :
                        setWardName({ ...WardName, ID: 0, Label: "Select..." })

                }}
                options={WardName.DDL}
            />

        </div>
        //     <div className="form-group">
        //     <label className="d-block" htmlFor="NameofDepartment"><b>{t('Dashboard.Pcmc_Assets.Ward_no')} {Flag && <AstricSign />} :</b></label>
        //     <Select
        //         // isClearable={isClearable === false ? false : true}
        //         // isRtl={isRtl}
        //         // className='ddl_width3'
        //         isDisabled={Flag === 'Update' && IsDisabled ? true : false}
        //         isSearchable
        //         maxMenuHeight={150}
        //         value={{ value: WardName.ID, label: WardName.Label }}
        //         onChange={(e) => {
        //             e ?
        //                 setWardName({ ...WardName, ID: e.value, Label: e.label })
        //                 :
        //                 setWardName({ ...WardName, ID: 0, Label: "Select..." })
        //             { setSectorName && setSectorName({ ...SectorName, ID: 0, Label: "Select ..." }) }

        //         }}
        //         options={WardName.DDL}
        //     />
        // </div>
    )
}