
import { useEffect } from 'react'
import Select from 'react-select'
import { AssetWiseEquipmentNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { useTranslation } from 'react-i18next'

export const AssetWiseEquipmentNameDataDDL = (props) => {
    const { AssetWiseEquipmentName, setAssetWiseEquipmentName, AssetName, setAssetType, WardName, rowData, Flag, Language, Flag1 } = props
    const { t } = useTranslation()
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_AssetID: AssetName.ID,
            // M_AssetTypeID: AssetType.ID,
            M_UserID: UserID,
            token: token,
            Language: Language.Label,
        }
        if(AssetName != 0){
            { Language.Label != '' && dispatch(AssetWiseEquipmentNameDDLAPI({ data })) }
        }
    }, [AssetName.ID, Language.Label])

    const { AssetWiseEquipmentNameDDL } = useSelector(state => state.AssetWiseEquipmentNameDDLData)

    useEffect(() => {
        handleAssetWiseEquipmentNameDDL()
    }, [AssetWiseEquipmentNameDDL])

    const handleAssetWiseEquipmentNameDDL = () => {
        if (AssetWiseEquipmentNameDDL && AssetWiseEquipmentNameDDL.table && AssetWiseEquipmentNameDDL.table.length > 0) {
            let list = AssetWiseEquipmentNameDDL.table.map((item, index) => ({
                value: item.m_AssetWiseEquipmentID,
                label: item.equipmentName,
            }))

            setAssetWiseEquipmentName({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_AssetWiseEquipmentID : 0,
                Label: Flag === 'Update' ? rowData.equipmentName : "Select...",
            })
        }
        else {
            setAssetWiseEquipmentName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofEmployee"><b>Equipment Name</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width3"
                isSearchable
                maxMenuHeight={150}
                value={{ value: AssetWiseEquipmentName.ID, label: AssetWiseEquipmentName.Label }}
                onChange={(e) => {
                    e ?
                        setAssetWiseEquipmentName({ ...AssetWiseEquipmentName, ID: e.value, Label: e.label })
                        :
                        setAssetWiseEquipmentName({ ...AssetWiseEquipmentName, ID: 0, Label: "Select..." })

                }}
                options={AssetWiseEquipmentName.DDL}
            />
        </div>
    )
}