import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useAuthState } from '../../../../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { SeverageWorkTypeDataDDL } from '../../../../../../../Components/CommonDDL/SeverageWorkTypeDataDDL';
import { SeverageWorkNamePostDataAPI } from '../../../../../../../Redux/SeverageDashboardSlice/SeverageWorkNameMasterSlice';
import { SeverageWorkPeriodDataDDL } from '../../../../../../../Components/CommonDDL/SeverageWorkPeriodDataDDL';
import { AstricSign } from '../../../../../../../Helper/AstricSign';


export default function SewerageWorkNamePopup(props) {

    const { handleOnCloseClick, open, EditIcon, Flag, GardenMasterFlag, popupHeading, handleIsPost, apiFlag, rowData } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();

    // const [rowData, setRowData] = useState(Flag === 'Update' ? rowData : '')
    const [IsOpen, setIsOpen] = useState(false)
    const [IsValidNumbers, setIsValidNumbers] = useState(false)
    const [ValidNumbers, setValidNumbers] = useState(false)
    const [IsClear, setIsClear] = useState(false)

    const [SeverageWorkType, setSeverageWorkType] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_sW_WorkTypeID : 0,
        Label: Flag === 'Update' ? rowData.workType : "Select...",
    })

    const [WorkPeriod, setWorkPeriod] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WorkNameTextField, setWorkNameTextField] = useState({
        workName: Flag === 'Update' ? rowData.workName : '',
        benchMarks: Flag === 'Update' ? rowData.benchMarks : '',
        penaltyDescription: Flag === 'Update' ? rowData.penaltyDescription : '',
        penaltyAmount: Flag === 'Update' ? rowData.penaltyAmount : ''
    })

    const handleWorkNameTextField = (e) => {
        setWorkNameTextField({ ...WorkNameTextField, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        setWorkNameTextField({
            workName: Flag === 'Update' ? rowData.workName : '',
            benchMarks: Flag === 'Update' ? rowData.benchMarks : '',
            penaltyDescription: Flag === 'Update' ? rowData.penaltyDescription : '',
            penaltyAmount: Flag === 'Update' ? rowData.penaltyAmount : ''
        })
    }, [rowData, IsOpen])

    const handleCheckMobileNumber = (e) => {
        handleWorkNameTextField(e)
        let regex = new RegExp(/^[0-9]*$/)
        if (regex.test(e.target.value) || e.target.value === "") {
            setIsValidNumbers(false)
        } else {
            setIsValidNumbers(true)
        }
    }
    const handleCheckNumber = (e) => {
        handleWorkNameTextField(e)
        let regex = new RegExp(/^[0-9]*$/)
        if (regex.test(e.target.value) || e.target.value === "") {
            setValidNumbers(false)
        } else {
            setValidNumbers(true)
        }
    }

    const handleClear = () => {
        setIsClear(!IsClear)
        setSeverageWorkType({
            ...SeverageWorkType,
            ID: 0,
            Label: 'Select...',
        })
        setWorkPeriod({
            ...WorkPeriod,
            ID: 0,
            Label: 'Select...',
        })
        setWorkNameTextField({
            workName:'',
            benchMarks:'',
            penaltyDescription:'',
            penaltyAmount:''
        })
    }

    const handlePost = () => {
        const data = {
            M_SW_WorkNameID: Flag === 'Update' ? rowData.m_sW_WorkNameID : '0',
            M_SW_WorkTypeID: SeverageWorkType.ID,
            M_SW_WorkPeriodID: WorkPeriod.ID,
            WorkName: WorkNameTextField.workName,
            BenchMarks: WorkNameTextField.benchMarks,
            PenaltyDescription: WorkNameTextField.penaltyDescription,
            PenaltyAmount: WorkNameTextField.penaltyAmount,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(SeverageWorkNamePostDataAPI({ data }))
    }

    // const [showPopup, setShowPopup] = useState(false);
    return (

        // <Popup
        //     className=''
        //     onOpen={() => setIsOpen(!IsOpen)}
        //     contentStyle={{ width: '55%', height: '70%', top: '5%' }}
        //     trigger={
        //         Flag === 'Update' ?
        //             <i className="fa-solid fa-pen-to-square" style={{ cursor: 'pointer' }} title='Edit'></i>
        //             :
        //             <i className="fa-solid fa-plus float-end btn btn-secondary mb-1"
        //                 style={{ color: "white" }}
        //             >
        //                 <text className="ml-2 poppins">Add</text>
        //             </i>

        //     } modal>

        //     {
        //         close => (
        // n_popup-content 
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="severage_mob_workname_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Work Name</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">

                                <div className="col-12 col-md-6 col-lg-6">
                                    <SeverageWorkTypeDataDDL
                                        SeverageWorkType={SeverageWorkType}
                                        setSeverageWorkType={setSeverageWorkType}
                                        rowData={rowData}
                                        Flag={Flag}

                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                    <SeverageWorkPeriodDataDDL
                                        WorkPeriod={WorkPeriod}
                                        setWorkPeriod={setWorkPeriod}
                                        rowData={rowData}
                                        Flag={Flag}


                                    />
                                </div>

                                <div className=" col-12 col-md-6 col-lg-6 ">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Work Name <AstricSign /> :</b></label>
                                        <textarea className="form-control" style={{ height: "2.8em" }}
                                            id="exampleInputEmail1"
                                            name='workName'
                                            aria-describedby="emailHelp"
                                            value={WorkNameTextField.workName}
                                            onChange={(e) => handleWorkNameTextField(e)}
                                        />
                                    </div>
                                </div>
                                <div className=" col-12 col-md-6 col-lg-6 ">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Bench Mark (Days)<AstricSign /> :</b></label>
                                        <input type="text" className="form-control"
                                            name='benchMarks'
                                            aria-describedby="emailHelp"
                                            value={WorkNameTextField.benchMarks}
                                            onChange={handleCheckMobileNumber}
                                        />
                                        {
                                            IsValidNumbers && <text style={{ color: 'red' }}>Please Enter Valid input</text>
                                        }
                                    </div>
                                </div>

                                <div className=" col-12 col-md-6 col-lg-6 ">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Penalty Description <AstricSign /> :</b></label>
                                        <input type="text" className="form-control"
                                            name='penaltyDescription'
                                            aria-describedby="emailHelp"
                                            value={WorkNameTextField.penaltyDescription}
                                            onChange={(e) => handleWorkNameTextField(e)}
                                        />
                                    </div>
                                </div>
                                <div className=" col-12 col-md-6 col-lg-6 ">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Penalty Amount (Rs) <AstricSign /> :</b></label>
                                        <input type="text" className="form-control"
                                            aria-describedby="emailHelp"
                                            name='penaltyAmount'
                                            value={WorkNameTextField.penaltyAmount}
                                            // onChange={(e) => handleWorkNameTextField(e)}
                                            onChange={handleCheckNumber}
                                        />
                                        {
                                            ValidNumbers && <text style={{ color: 'red' }}>Please Enter Valid input</text>
                                        }
                                    </div>
                                </div>


                            </div>

                            {/* <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                    // disabled={
                                    //     Designation.ID == 0 || EmployeeField.EmployeeName == ''
                                    // }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div> */}

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">
                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => (handlePost(),handleClear())}
                                        disabled={
                                            SeverageWorkType.ID == 0 || WorkPeriod.ID == 0 || WorkNameTextField.workName == '' || WorkNameTextField.benchMarks == '' || WorkNameTextField.penaltyDescription == '' || WorkNameTextField.penaltyAmount == ''
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {/* )} */}
        </Popup>
    )

}