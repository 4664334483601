import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import Select from 'react-select'


export default function WaterSupplyLocationMasterPopup(props) {

    const { handleOnCloseClick, open, EditIcon, Flag, GardenMasterFlag, popupHeading, handleIsPost, apiFlag, rowData } = props

    // const [rowData, setRowData] = useState(Flag === 'Update' ? rowData : '')
    const [IsOpen, setIsOpen] = useState(false)

    // const [LocationName, setLocationName] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select...",
    // })

    return (
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="modal-dialog modal-dialog-scrollable water_pop modal-xl">
                <div className="modal-content">
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Location Master</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">

                                {/* <div className="col-12 col-md-6 col-lg-6" >
                                    <div className="form-group">
                                        <label for=""><b>Location Name :</b></label>
                                        <Select
                                            // isClearable
                                            // isRtl={isRtl}
                                            // className="ddl_width"
                                            isSearchable
                                            maxMenuHeight={150}
                                            value={{ value: LocationName.ID, label: LocationName.Label }}
                                            onChange={(e) => {
                                                e ?
                                                    setLocationName({ ...LocationName, ID: e.value, Label: e.label })
                                                    :
                                                    setLocationName({ ...LocationName, ID: 0, Label: "Select..." })

                                            }}
                                            options={LocationName.DDL}
                                        />
                                    </div>

                                </div> */}
                                <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Location Name :</b></label>
                                        <input type="text" className="form-control"
                                            id=""
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                    // disabled={
                                    //     Designation.ID == 0 || EmployeeField.EmployeeName == ''
                                    // }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div> */}

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">
                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                    >{Flag === "Update" ? "Update" : "Save"} </button>
                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </Popup>
    )

}