import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const DepartmentTableDataAPI = createAsyncThunk("DepartmentTableData", async ({ data }) => {
    const {
        M_DepartmentID,
        DepartmentCode,
        DepartmentName,
        M_UsersID,
        token,
        From,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_Department_Select?M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&DepartmentCode=${DepartmentCode}&DepartmentName=${DepartmentName}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DepartmentTableDataSlice = createSlice({
    name: "DepartmentTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DepartmentTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DepartmentTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(DepartmentTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DepartmentTableDataReducer = DepartmentTableDataSlice.reducer


export const DepartmentPostDataAPI = createAsyncThunk("DepartmentPostData", async ({ data }) => {
    const {
        M_DepartmentID,
        DepartmentCode,
        DepartmentName,
        M_UserID,
        token,
        Flag,
        handleIsPost
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_DepartmentID", M_DepartmentID);
    formdata.append("DepartmentCode", DepartmentCode);
    formdata.append("DepartmentName", DepartmentName);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Post_M_Department_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const DepartmentPostDataSlice = createSlice({
    name: "DepartmentPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DepartmentPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DepartmentPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(DepartmentPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DepartmentPostDataReducer = DepartmentPostDataSlice.reducer

// -----------------Delete API ---------------

export const DepDeleteAPI = createAsyncThunk("DepDelete", async ({ data }) => {
    const {
        M_DepartmentID,        
        M_UserID,
        token,
        Flag,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    // formdata.append("M_CropTypeID", rowData?.m_CropTypeID);
    var formdata = new FormData();
    formdata.append("M_DepartmentID", M_DepartmentID);
    formdata.append("M_UserID",M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_Department_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
                
            }
            return result

        })
})

const DepDeleteSlice = createSlice({
    name: "DepDelete",
    initialState: {
        isDeleteLoading: false,
        DepartmentableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DepDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(DepDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.DepartmentableData = action.payload;

        });
        builder.addCase(DepDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.DepartmentableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DepDeleteReducer = DepDeleteSlice.reducer