
import { useEffect } from 'react'
import Select from 'react-select'
import { ProjectNameDDLAPI, ProjectTypeNameDDLAPI, SectorDDLAPI, WardNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { AstricSign } from '../../Helper/AstricSign'

export const ProjectTypeDataDDL = (props) => {
    const { ProjectType, setProjectType, WardName, PopUpField, rowData, Flag } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token }
        dispatch(ProjectTypeNameDDLAPI({ data }))
    }, [])

    const { ProjectTypeNameData } = useSelector(state => state.ProjectTypeNameDDLData)

    useEffect(() => {
        handleProjectDDL()
    }, [ProjectTypeNameData])

    const handleProjectDDL = () => {
        if (ProjectTypeNameData && ProjectTypeNameData.table && ProjectTypeNameData.table.length > 0) {
            let list = ProjectTypeNameData.table.map((item, index) => ({
                value: item.m_ProjectTypeID,
                label: item.projectType,
            }))

            setProjectType({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_ProjectTypeID : 0,
                Label: Flag === 'Update' ? rowData.projectType : "Select...",
            })
        }
        else {
            setProjectType({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Project Type { Flag && <AstricSign />}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: ProjectType.ID, label: ProjectType.Label }}
                onChange={(e) => {
                    e ?
                        setProjectType({ ...ProjectType, ID: e.value, Label: e.label })
                        :
                        setProjectType({ ...ProjectType, ID: 0, Label: "Select..." })

                }}
                options={ProjectType.DDL}
            />
        </div>
    )
}