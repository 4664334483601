import React, { useState, useReducer } from 'react';
import CryptoJS from "crypto-js";


if (localStorage.getItem("Garden")) {
	const storedData = localStorage.getItem("Garden");
	var bytes = CryptoJS.AES.decrypt(storedData, 'RangaFulan09');
	var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	// console.log(decryptedData);
}

let token = decryptedData ? decryptedData.access_token : '';
let UserFullName = decryptedData ? decryptedData.UserFullName : '';
let RoleID = decryptedData ? decryptedData.RoleID : '';
let UserID = decryptedData ? decryptedData.UserID : '';
let DepartmentID = decryptedData ? decryptedData.DepartmentID : '';
let DesignationID = decryptedData ? decryptedData.DesignationID : '';
let EmployeeID = decryptedData ? decryptedData.EmployeeID : '';
let ClientID = decryptedData ? decryptedData.ClientID : '';
let M_ProjectTypeID = decryptedData ? decryptedData.M_ProjectTypeID : '';
export const initialState = {
	UserFullName: '' || UserFullName,
	RoleID: '' || RoleID,
	UserID: '' || UserID,
	token: '' || token,
	DepartmentID: '' || DepartmentID,
	DesignationID: '' || DesignationID,
	EmployeeID: '' || EmployeeID,
	ClientID: '' || ClientID,
	M_ProjectTypeID: '' || M_ProjectTypeID,
	loading: false,
	errorMessage: null,
};
export const AuthReducer = (initialState, action) => {
	// console.log(action.payload)
	switch (action.type) {
		case 'REQUEST_LOGIN':
			return {
				...initialState,
				loading: true,
			};
		case 'LOGIN_SUCCESS':
			return {
				...initialState,
				UserFullName: action.payload.UserFullName,
				RoleID: action.payload.RoleID,
				UserID: action.payload.UserID,
				token: action.payload.access_token,
				DepartmentID: action.payload.DepartmentID,
				DesignationID: action.payload.DesignationID,
				EmployeeID: action.payload.EmployeeID,
				ClientID: action.payload.ClientID,
				M_ProjectTypeID: action.payload.M_ProjectTypeID,
				loading: false,
			};
		case 'LOGOUT':
			return {
				...initialState,
				UserFullName: '',
				RoleID: '',
				UserID: '',
				DepartmentID: '',
				DesignationID: '',
				EmployeeID: '',
				ClientID: '',
				M_ProjectTypeID: '',
				token: '',
			};

		case 'LOGIN_ERROR':
			return {
				...initialState,
				loading: false,
				errorMessage: action.error,
			};

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};
