
import { useEffect } from 'react'
import Select from 'react-select'
import { DepartmentDDLAPI, EmployeeDDLAPI, LocationNameDDLAPI, SectorDDLAPI, SeverageLocationDDLAPI, WardNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { AstricSign } from '../../Helper/AstricSign'

export const SeverageLocationDataDDL = (props) => {
    const { SeverageLocation, setSeverageLocation, CropNameDashboardData, PopUpField, IsPost, WardName,rowData,Flag } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            // M_WardID: ,
            M_UserID: UserID,
            token: token,
            WardName: WardName
        }
        dispatch(SeverageLocationDDLAPI({ data }))
    }, [IsPost, WardName.ID])

    const { SeverageLocationData } = useSelector(state => state.SeverageLocationDDLData)

    useEffect(() => {
        handleLocationNameDDL()
    }, [SeverageLocationData])

    const handleLocationNameDDL = () => {
        // console.log(DeptDDLDataa)
        if (SeverageLocationData && SeverageLocationData.table && SeverageLocationData.table.length > 0) {
            let list = SeverageLocationData.table.map((item, index) => ({
                value: item.m_sW_LocationID,
                label: item.swLocation,
            }))

            setSeverageLocation({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_sW_LocationID : 0,
                Label: Flag === 'Update' ? rowData.swLocation : "Select...",
            })
        }
        else {
            setSeverageLocation({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Location <AstricSign /></b></label>
            <Select
                isClearable
                // isRtl={isRtl}

                isSearchable
                maxMenuHeight={150}
                value={{ value: SeverageLocation.ID, label: SeverageLocation.Label }}
                onChange={(e) => {
                    e ?
                        setSeverageLocation({ ...SeverageLocation, ID: e.value, Label: e.label })
                        :
                        setSeverageLocation({ ...SeverageLocation, ID: 0, Label: "Select..." })

                }}
                options={SeverageLocation.DDL}
            />
        </div>
    )
}