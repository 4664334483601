import Header from "../../../Components/Header/Header"
import Sidebar from "../../../Components/Sidebar/Sidebar"
import EstimatedCardManage from "./DashboardComponent/EstimatedCard/EstimatedCardManage"
import MaintainanceManageCard from "./DashboardComponent/MaintainanceCard/MaintainanceManageCard"
import MasterCardManage from "./DashboardComponent/MasterCard/MasterCardManage"
import WorkApprovalCardManage from "./DashboardComponent/WorkApprovalCard/WorkApprovalCardManage"
import WorkProgressCardManage from "./DashboardComponent/WorkProgressCard/WorkProgressCardManage"
import { Bar, Pie } from 'react-chartjs-2';
import WorkUploadCardManage from "./DashboardComponent/WorkUpload/WorkUploadCardManage"
import { Pieoptions, WorkUploadChart } from "../../../Helper/Data"
import { useNavigate, useSearchParams } from 'react-router-dom'
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import { useAuthState } from "../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { RoadDashMainCountDataAPI } from "../../../Redux/MainCountSlice/RoadMaintenceMainCount"
import { WardNameDataDDL } from "../../../Components/CommonDDL/WardNameDataDDL"
import { Loading } from "../../../Helper/Loading"
import GardenMaintainanceCardManage from "./GarderDashboardComponent/GardenMaintainanceCard/GardenMaintainanceCardManage"

export const DashboardNew = () => {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    // console.log('ProjectTypeId', ProjectTypeId)

    const [YearValue, setYearValue] = useState(null)
    const [YearLabel, setYearLabel] = useState(null)
    const [MonthValue, setMonthValue] = useState(null)
    const [MonthLabel, setMonthLabel] = useState(null)
    const [WardValue, setWardValue] = useState(null)
    const [WardLabel, setWardLabel] = useState(null)

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 1,
        Label: "Select",
    })

    // console.log(userDetails)

    // const RoadSidebarHide = (name, screenFlag) => {

    //     if (name === "Garden Sidebar") {
    //         navigate(`/dashboard?name=${name}`)
    //     } 
    // }
    // const navigate = useNavigate()
    const Targetdata = {

        labels: ['Agency ', 'Jee', 'De'],
        datasets: [
            {
                label: '',
                data: [55, 49, 44],
                backgroundColor: [
                    "#cc2836", '#FFB300', "#253150"
                ],
                options: {
                    scales: {
                        xAxes: [{
                            barPercentage: 0.1
                        }]
                    }
                }
            },

        ],
    };

    const Receiveddata = {
        labels: ["Completed", "Pending"],
        datasets: [
            {
                label: '# of Votes',
                data: [80, 20],
                backgroundColor: [
                    "#ffc33d",
                    "#f48242"
                ],
                borderColor: [
                    // '#28c8c1',
                    // '#172b4d',
                    // '#993366',
                    // '#593e89',
                    // '#94408e',
                    // '#c74382',
                    // '#fa5d00',
                    // '#529ad2',
                    // '#ffc137',
                ],
                borderWidth: 1,
            },
        ],
    };


    const [Countdata, setCountdata] = useState('')
    const [From, set] = useState('')

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_WardID: WardValue,
            M_MonthID: MonthValue,
            M_UserID: UserID,
            Flag: 'Web',
            token: token,
            handleGetCount: handleGetCount
        }
        if (YearValue && MonthValue && WardValue) {
            dispatch(RoadDashMainCountDataAPI({ data }))
        }
    }, [WardValue, YearValue, MonthValue])

    const { RoadDashCountData, isLoading } = useSelector(state => state.RoadDashMainCountData)


    const handleGetCount = (data) => {
        let tempData = {}
        data.forEach((item) => {
            tempData[item.paramName] = item.totalCount
        })
        setCountdata(tempData)
    }

    // console.log(WardValue)

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    DashboardHeading="Road Maintainance"
                    setYearValue={setYearValue}
                    setMonthValue={setMonthValue}
                    FinancialYear={FinancialYear}
                    setFinancialYear={setFinancialYear}
                    Month={Month}
                    setMonth={setMonth}
                    setYearLabel={setYearLabel}
                    setMonthLabel={setMonthLabel}
                    setWardValue={setWardValue}
                    setWardLabel={setWardLabel}
                    DashboardName={DashboardName}
                    // ProjectTypeId={ProjectTypeId}
                />
                <div className="wrapper">
                    <Sidebar
                        ProjectTypeId={ProjectTypeId}
                        DashboardName={DashboardName} />
                    <div className="content1 content">
                        {/* <div className="pcoded-main-container main-row">
                                <div className="pcoded-content "> */}
                        {/* <!-- [ breadcrumb ] start --> */}
                        <div className="page-header ">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        <div className="page-header-title d-flex justify-content-between align-items-center">
                                            <h5 className="ps-2">DASHBOARD</h5>
                                            {/* <div className="col-md-6 col-7 col-lg-3 ">
                                                <WardNameDataDDL
                                                    WardName={WardName}
                                                    setWardName={setWardName}
                                                />
                                            </div> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- [ Main Content ] start --> */}
                        <div className="mb-4 m-1 py-2 px-3 master_border">
                            <GardenMaintainanceCardManage />
                        </div>
                        {/* <!--  --> */}

                        <div className=" m-1 py-2 pb-4 master_border">
                            <div className="row ">
                                <h5 className="ps-4 py-2 ml-2">Master</h5>
                            </div>
                            <MasterCardManage
                                Countdata={Countdata}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName}
                            />
                        </div>

                        <div className="row mt-4 m-1 pt-2 pb-2 master_border ">
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="ps-2 pt-2">Work Upload</h5>
                                </div>
                            </div>
                            <div className="row d-flex justify-content-between align-items-center ">
                                <div className="col-12 col-lg-7" style={{ height: '100%' }}>
                                    <WorkUploadCardManage
                                        Countdata={Countdata}
                                        YearValue={YearValue}
                                        YearLabel={YearLabel}
                                        MonthValue={MonthValue}
                                        MonthLabel={MonthLabel}
                                        WardValue={WardValue}
                                        WardLabel={WardLabel}
                                        ProjectTypeId={ProjectTypeId}
                                        DashboardName={DashboardName}
                                    />
                                </div>
                                <div className="col-12 col-lg-4">
                                    <div className="upload_chart bg-white">
                                        <Bar
                                            // height={150}
                                            options={WorkUploadChart}
                                            data={Targetdata}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 m-1 py-2 pb-2 master_border">
                            <div className="row pb-3">
                                <div className="col-12">
                                    <h5 className="ps-4 pt-2 ">Work Approval</h5>
                                </div>
                            </div>
                            <WorkApprovalCardManage
                                Countdata={Countdata}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName}
                            />
                        </div>

                        <div className="row mt-4 m-1 py-2 pb-2 master_border">
                            <WorkProgressCardManage
                                Countdata={Countdata}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName}
                            />
                            <div className="col-12 col-lg-5 ">
                                <div className="row ">
                                    <div className="col-12 ">
                                        <div className=" px-3 pb-2 upload_chart bg-white">
                                            <div className="row d-flex justify-content-center align-items-center ">
                                                <Pie
                                                    height={120}
                                                    options={Pieoptions}
                                                    data={Receiveddata}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4 m-1 py-2 pb-2 master_border">
                            <div className="col-12 ">
                                <div className="row pb-3">
                                    <div className="col-12">
                                        <h5 className="ps-3 py-2 ml-1">Estimated Amount</h5>
                                    </div>
                                    <EstimatedCardManage
                                        Countdata={Countdata}
                                        YearValue={YearValue}
                                        YearLabel={YearLabel}
                                        MonthValue={MonthValue}
                                        MonthLabel={MonthLabel}
                                        WardValue={WardValue}
                                        WardLabel={WardLabel}
                                        ProjectTypeId={ProjectTypeId}
                                        DashboardName={DashboardName}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}