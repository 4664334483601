import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const ProjectWiseWorkNameTableDataAPI = createAsyncThunk("ProjectWiseWorkNameTableData", async ({ data }) => {
    const {
        M_UsersID,
        token,
        From,
        To,
        M_ProjectTypeID,
        M_ProjectWiseWorkID,
        M_ProjectID,
        M_WorkID,
        WorkName,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_ProjectWiseWork_Select?M_ProjectWiseWorkID=${M_ProjectWiseWorkID ? M_ProjectWiseWorkID : '0'}&M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : '0'}&M_ProjectID=${M_ProjectID ? M_ProjectID : '0'}&M_WorkID=${M_WorkID ? M_WorkID : '0'}&WorkName=${WorkName}&M_UserID=${M_UsersID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ProjectWiseWorkNameTableDataSlice = createSlice({
    name: "ProjectWiseWorkNameTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectWiseWorkNameTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectWiseWorkNameTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ProjectWiseWorkNameTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectWiseWorkNameTableDataReducer = ProjectWiseWorkNameTableDataSlice.reducer


// InsertUpdate Table Data

export const ProjectWiseWorkNamePostDataAPI = createAsyncThunk("ProjectWiseWorkNamePostData", async ({ data }) => {
    const {
        M_ProjectWiseWorkID,
        M_ProjectID,
        M_WorkID,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ProjectWiseWorkID", M_ProjectWiseWorkID);
    formdata.append("M_ProjectID", M_ProjectID);
    formdata.append("M_WorkID", M_WorkID);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_ProjectWiseWork_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const ProjectWiseWorkNamePostDataSlice = createSlice({
    name: "ProjectWiseWorkNamePostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectWiseWorkNamePostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectWiseWorkNamePostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ProjectWiseWorkNamePostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectWiseWorkNamePostDataReducer = ProjectWiseWorkNamePostDataSlice.reducer



// Delete Table Data

export const ProjectWiseWorkNameDeleteAPI = createAsyncThunk("ProjectWiseWorkNameDelete", async ({ data }) => {
    const {
        M_ProjectWiseWorkID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ProjectWiseWorkID", M_ProjectWiseWorkID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_ProjectWiseWork_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const ProjectWiseWorkNameDeleteSlice = createSlice({
    name: "ProjectWiseWorkNameDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectWiseWorkNameDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ProjectWiseWorkNameDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;

        });
        builder.addCase(ProjectWiseWorkNameDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectWiseWorkNameDeleteReducer = ProjectWiseWorkNameDeleteSlice.reducer