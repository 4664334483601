import React from "react";
import { useNavigate } from "react-router-dom";

export default function WaterAssignMainWorkCard({ title, navLink, count, imgPath, cardName, SewerageWorkNavigate, cardHeading,title1,title2,WaterAssignMainWorkCardNavigate1, imgCompPath, imgPendingPath,imgPathsecond,cardHeight }) {
    const navigate = useNavigate()

    const handleWaterAssignMainWorkCardClick = () => {
        navigate(navLink)
    }
    return (
        <div style={{ cursor:'pointer' }} className="mb-1 p-3 shadow blue_borders bg-white mb-md-2 daily-card-width">
            <div className="row">
            <h6 className="text-black mb-3"><b>{cardHeading}</b></h6>
                <div className="col-6" style={{ borderRight: "2px solid #cfc7c7" }} onClick={() => WaterAssignMainWorkCardNavigate1(title1)} >
                    <div class={cardHeight} className="d-flex flex-column justify-content-between">
                        <div className="text-center" >
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{count}</h4>
                                <img style={{ height: '35px', width: '35px' }} src={imgPathsecond} alt="" />
                            </div>
                        </div>
                        <div>
                            <h6 className="text-black"><b>Pending</b></h6>
                        </div>
                    </div>
                </div>
                <div className="col-6" onClick={() => WaterAssignMainWorkCardNavigate1(title2)} >
                    <div className="d-flex flex-column justify-content-between">
                        <div className="text-center" >
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{count}</h4>
                                <img style={{ height: '30px', width: '30px' }} src={imgPath} alt="" />
                            </div>

                        </div>
                        <div>
                            <h6 className="text-black"><b>Completed</b></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
