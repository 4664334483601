import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { WardNameDataDDL } from '../../../../Components/CommonDDL/WardNameDataDDL';
import { AssetDataDDL } from '../../../../Components/CommonDDL/AssetDataDDL';
import { YesNoDataDDL } from '../../../../Components/CommonDDL/YesNoDataDDL';
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { AssetTypeDataDDL } from '../../../../Components/CommonDDL/AssetTypeDataDDL';
import { GardenMasterPostDataAPI } from '../../../../Redux/MasterSlice/GardenMasterSlice';
import { StatusNameDataDDL } from '../../../../Components/CommonDDL/StatusNameDataDDL';
import { AstricSign } from '../../../../Helper/AstricSign';
import { MaintainByDataDDL } from '../../../../Components/CommonDDL/MaintainByDDL';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { WardnameHeaderDDL } from '../../../../Components/CommonDDL/WardnameHeaderDDL';
import { DashBoardScreenWardDDL } from '../../../../Components/CommonDDL/DashboardScreenWardDDL';
import CommonDashboardScreenWardDDL from '../../../../Components/CommonDDL/CommonDashboardScreenWardDDL';
import { ZoneNameDDL } from '../../../../Components/CommonDDL/DashboardDDL/ZoneNameDDL';
import { MaintainByDDLDataForPopup } from '../../../../Components/CommonDDL/MaintainByDDLForPopup';


export default function GardenPopup(props) {

  const { handleOnCloseClick, open, WLable, HWard, ZLable, ZWard, flag, Flag, GardenMasterFlag, popupHeading, M_AssetTypeID, handleIsPost, apiFlag, rowData, AreaMeter } = props

  const { t } = useTranslation()

  const userDetails = useAuthState();
  const { UserID, token, RoleID } = userDetails
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams()
  let Lang = searchParams.get("Lang")

  // const [rowData, setRowData] = useState(rowData)

  const [AreaNameMarathi, setAreaNameMarathi] = useState(Flag === 'Update' ? rowData.areaName : '');
  const [AreaName, setAreaName] = useState(Flag === 'Update' ? rowData.areaName : '');
  const [Area, setArea] = useState(Flag === 'Update' ? rowData.areaInSquareMetre : '');
  const [AssetNameMarathi, setAssetNameMarathi] = useState(Flag === 'Update' ? rowData.assetName : '');
  const [AssetName, setAssetName] = useState(Flag === 'Update' ? rowData.assetName : '');
  const [IsOpen, setIsOpen] = useState(false);


  // const [SectorName, setSectorName] = useState({
  //   DDL: [],
  //   ID: Flag === 'Update' ? rowData.m_gD_SectorID : 0,
  //   Label: Flag === 'Update' ? rowData.sectorName : "Select...",
  // })

  const [ZoneName, setZoneName] = useState({
    DDL: [],
    ID: 0,
    Label: "Select...",

  })

  const [WardName, setWardName] = useState({
    DDL: [],
    ID: Flag === 'Update' ? rowData.id : 0,
    Label: Flag === 'Update' ? rowData.wardName : "Select...",

  })

  const [ZoneNameDDLID, setZoneNameDDLID] = useState({
    DDL: [],
    // ID: ZWard && ZWard ? ZWard : '0',
    // Label: ZLable && ZLable ? ZLable : "Select...",
    ID: Flag === 'Update' ? rowData.m_ZoneID : 0,
    Label: Flag === 'Update' ? rowData.zoneName : "Select...",

  })

  const [AssetType, setAssetType] = useState({
    DDL: [],
    ID: M_AssetTypeID,
    Label: Flag === 'Update' ? rowData.assetTypeName : "Select...",
  })
  const [GetPresent, setGetPresent] = useState({
    DDL: [],
    ID: Flag === 'Update' ? rowData.isGetPresent : 0,
    Label: Flag === 'Update' ? rowData.isGetPresentName : "Select...",

  })

  const [WateringEdgesPresent, setWateringEdgesPresent] = useState({
    DDL: [],
    ID: Flag === 'Update' ? rowData.isWateringOfEdgesPresent : 0,
    Label: Flag === 'Update' ? rowData.isWateringOfEdgesPresent : "Select...",
  })
  const [WateringTreePresent, setWateringTreePresent] = useState({
    DDL: [],
    ID: Flag === 'Update' ? rowData.isWateringOfTreesPresent : 0,
    Label: Flag === 'Update' ? rowData.isWateringOfTreesPresent : "Select...",
  })

  const [WateringLawnPresent, setWateringLawnPresent] = useState({
    DDL: [],
    ID: Flag === 'Update' ? rowData.isWateringOfLawnsPresent : 0,
    Label: Flag === 'Update' ? rowData.isWateringOfLawnsPresent : "Select...",

  })

  // const [Status, setStatus] = useState({
  //   DDL: [],
  //   ID: Flag === 'Update' ? rowData.m_StatusID : 0,
  //   Label: Flag === 'Update' ? rowData.statusName : "Select...",
  // })

  const [Status, setStatus] = useState({
    DDL: [],
    ID: Flag === 'Update' ? rowData.m_StatusID : 0,
    Label: Flag === 'Update' ? rowData.statusName : "Select...",

  })

  const [MaintainByForPopup, setMaintainByForPopup] = useState({
    DDL: [],
    ID: Flag === 'Update' ? rowData.maintainByIndicatorAgencyID : 0,
    Label: Flag === 'Update' ? rowData.indicatorName : "Select...",

  })
  // console.log('row', rowData)

  useEffect(() => {
    setAreaNameMarathi(Flag === 'Update' ? rowData.areaName : '')
    setAreaName(Flag === 'Update' ? rowData.areaName : '')
    setArea(Flag === 'Update' ? rowData.areaInSquareMetre : '')
    setAssetNameMarathi(Flag === 'Update' ? rowData.assetName : '')
    setAssetName(Flag === 'Update' ? rowData.assetName : '')
  }, [rowData, IsOpen])

  const handleClearField = () => {

    if (Flag != 'Update') {
      setAreaName('')
      setArea('')
      setAssetName('')
    }
  }

  const handlePost = () => {
    const data = {
      M_AssetID: Flag === 'Update' ? rowData?.m_AssetID : '0',
      M_AssetTypeIDS: AssetType.ID,
      M_WardID: WardName.ID,
      // M_GD_SectorID: SectorName.ID,
      AssetName: AssetName,
      AssetName_Marathi: AssetNameMarathi,
      AreaName: AreaName,
      AreaName_Marathi: AreaNameMarathi,
      AreaInSquareMetre: Area,
      IsGetPresent: GetPresent.ID,
      IsWateringOfEdgesPresent: WateringEdgesPresent.ID,
      IsWateringOfTreesPresent: WateringTreePresent.ID,
      IsWateringOfLawnsPresent: WateringLawnPresent.ID,
      MaintainByIndicatorAgencyID: MaintainByForPopup.ID,
      IsMaintingYou: WateringLawnPresent.ID,
      M_StatusID: Status.ID,
      M_UserID: UserID,
      token: token,
      Flag: Flag === 'Update' ? 'Update' : 'Insert',
      handleIsPost: handleIsPost,
      handleClearField: handleClearField,
      handleOnCloseClick: handleOnCloseClick
    }
    dispatch(GardenMasterPostDataAPI({ data }))
  }

  const [Language, setLanguage] = useState({
    DDL: [],
    ID: 0,
    Label: ''
  })

  useEffect(() => {
    setLanguage({
      ...Language,
      DDL: [],
      ID: 0,
      Label: localStorage.getItem('LanguageChange'),
    })
  }, [])

  console.log('ZoneNameDDLID.ID', ZoneNameDDLID)

  // const handleDeleteApi = (rowData) => {
  //   const data = {
  //     M_EmployeeID: rowData.m_EmployeeID,
  //     M_UserID: UserID,
  //     token: token,
  //     handleIsPost: handleIsPost
  //   }
  //   // dispatch(EmployeeDeleteAPI({ data }))
  // }

  // const { isDeleteLoading } = useSelector(state => state.EmployeeDeleteData)

  return (
    <Popup
      open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick} contentStyle={{ width: "70vw" }}
    >
      {/* {
        close => ( */}
      {/* // n_popup-content  */}
      {/* <div className="modal-dialog  modal-xl"> */}
      <div className='area_pop modal-dialog modal-dialog-scrollable modal-xl'>
        <div className="modal-content">
          <div className="modal-header pl-4 p-1">
            <h4 className="modal-title" id="staticBackdropLabel">{popupHeading}</h4>
            <button className="btn-close m-1 mr-3" type="button" onClick={handleOnCloseClick} />
          </div>
          <div className="modal-body">
            <div className='inner-modal-body'>
              <div className="row details-row">
                <div className="col-md-6 col-12 col-lg-4">
                  <ZoneNameDDL
                    ZoneNameDDLID={ZoneNameDDLID}
                    setZoneNameDDLID={setZoneNameDDLID}
                    ZLable={ZLable}
                    ZWard={ZWard}
                    Language={Language}
                    Flag={Flag}
                    rowData={rowData}
                  // ZoneDDL='DashboardZone'
                  />
                </div>
                <div className="col-md-6 col-12 col-lg-4">
                  <WardnameHeaderDDL
                    WardName={WardName}
                    setWardName={setWardName}
                    Flag={Flag}
                    rowData={rowData}
                    Language={Language}
                    M_ZoneID={ZoneNameDDLID.ID}
                  // WardDDL='DashboardWard'
                  />
                </div>

                <div className="col-md-6 col-12 col-lg-4">
                  <AssetTypeDataDDL
                    AssetType={AssetType}
                    setAssetType={setAssetType}
                    Flag={Flag}
                    rowData={rowData}
                    M_AssetTypeID={M_AssetTypeID}
                    IsDisabled={true}
                    Language={Language}
                  />
                </div>

                <div className="col-md-6 col-12 col-lg-4">
                  <div className="form-group">
                    <label className="d-block fw-bold" htmlFor="AssetNameMarathi">{t('Dashboard.Pcmc_Assets.Asset_Name')} (Marathi) <AstricSign /> :</label>
                    <textarea type="text"
                      className="form-control"
                      id="AssetNameMarathi"
                      value={AssetNameMarathi}
                      onChange={(e) => setAssetNameMarathi(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12 col-lg-4">
                  <div className="form-group">
                    <label className="d-block fw-bold" htmlFor="AssetName">{t('Dashboard.Pcmc_Assets.Asset_Name')}(English) <AstricSign /> :</label>
                    <textarea type="text"
                      className="form-control"
                      id="AssetName"
                      value={AssetName}
                      onChange={(e) => setAssetName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12 col-lg-4">
                  <div className="form-group">
                    <label className="d-block fw-bold" htmlFor="AreaNameMarathi">{t('Dashboard.Pcmc_Assets.Area_Name')}(Marathi) <AstricSign /> :</label>
                    <textarea type="text"
                      className="form-control"
                      id="AreaNameMarathi"
                      value={AreaNameMarathi}
                      onChange={(e) => setAreaNameMarathi(e.target.value)}

                    />
                  </div>
                </div>
                <div className="col-md-6 col-12 col-lg-4">
                  <div className="form-group">
                    <label className="d-block fw-bold" htmlFor="AreaName">{t('Dashboard.Pcmc_Assets.Area_Name')}(English) <AstricSign /> :</label>
                    <textarea type="text"
                      className="form-control"
                      id="AreaName"
                      value={AreaName}
                      onChange={(e) => setAreaName(e.target.value)}

                    />
                  </div>
                </div>
                {
                  GardenMasterFlag == 'GardenMaster' ?
                    <div className="col-md-6 col-12 col-lg-4">
                      <div className="form-group">
                        <label className="d-block fw-bold" htmlFor="Area">{AreaMeter} <AstricSign /> :</label>
                        <input type="text"
                          className="form-control"
                          id="Area"
                          value={Area}
                          onChange={(e) => setArea(e.target.value)}

                        />
                      </div>
                    </div>
                    :
                    <></>
                }
                {/* {
                      GardenMasterFlag == 'GardenMaster' ?
                        <>
                          <div className="col-md-6 col-lg-4">
                            <YesNoDataDDL
                              GetPresent={GetPresent}
                              setGetPresent={setGetPresent}
                              DDLHeading='Get Present'
                              Flag={Flag}
                              rowData={rowData}
                            />

                          </div>
                          <div className="col-md-6 col-lg-4">
                            <YesNoDataDDL
                              GetPresent={WateringEdgesPresent}
                              setGetPresent={setWateringEdgesPresent}
                              DDLHeading='Watering of Edges Present'
                              Flag={Flag}
                              rowData={rowData}
                            />
                          </div>
                          <div className="col-md-6 col-lg-4">
                            <YesNoDataDDL
                              GetPresent={WateringTreePresent}
                              setGetPresent={setWateringTreePresent}
                              DDLHeading='Watering of Tree Present'
                              Flag={Flag}
                              rowData={rowData}
                            />
                          </div>
                          <div className="col-md-6 col-lg-4">
                            <YesNoDataDDL
                              GetPresent={WateringLawnPresent}
                              setGetPresent={setWateringLawnPresent}
                              DDLHeading='Watering of Lawn Present'
                              Flag={Flag}
                              rowData={rowData}
                            />
                          </div>
                        </>


                        :
                        <></>
                    } */}

                <div className="col-md-6 col-12 col-lg-4">
                  <StatusNameDataDDL
                    Status={Status}
                    setStatus={setStatus}
                    rowData={rowData}
                    Flag={Flag}
                  // Language={Lang}
                  />
                </div>
                <div className="col-md-6 col-12 col-lg-4">
                  <MaintainByDDLDataForPopup
                    MaintainByForPopup={MaintainByForPopup}
                    setMaintainByForPopup={setMaintainByForPopup}
                    rowData={rowData}
                    Flag={Flag}
                    Language={Language}
                    M_AssetTypeID={M_AssetTypeID}
                  />
                </div>
              </div>
              {/* <div className="mb-4 pt-4">
                <h6 className="float-end d-flex text-center">
                  <button className="submit_btn"
                   disabled={
                    WardName.ID === 0 && SectorName.ID === 0 && AssetType.ID === 0 && AssetName === '' && AreaName === '' && Area === '' && Status.ID === 0
                }
                    onClick={() => handlePost()}
                  >
                    {Flag === "Update" ? "Update" : "Save"}
                  </button>
                  <button className=" cancel_btn ms-2" onClick={handleOnCloseClick}>Cancel</button>
                </h6>
              </div> */}

              <div className="col-12 col-lg-12">
                <div className="btn-action d-flex justify-content-end mt-lg-3">

                  <button type='button'
                    className="btn allBtn  float-right submit_btn"
                    onClick={() => handlePost()}
                    disabled={
                      ZoneNameDDLID.ID == undefined || WardName.ID == 0 || AssetType.ID == 0 || AssetName == '' || AssetNameMarathi == '' || AreaNameMarathi == ''
                      || AreaName == '' || Status.ID == 0 || MaintainByForPopup.ID == 0
                    }
                  >{Flag === "Update" ? "Update" : "Save"} </button>

                  <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* )} */}


    </Popup>
  )

}