import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr } from "../../Helper/ToastMessage";

export const PenaltyWorkReportTableDataAPI = createAsyncThunk("PenaltyWorkReportTableData", async ({ data }) => {
    const {
        M_UserID,
        token,
        M_ZoneID,
        M_ContractorID,
        M_WorkTypeID,
        M_AssetTypeID,
        M_AssetID,
        FromDate,
        M_WardData,
        Flag,
        ToDate,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_RM_DB_GD_PENALTY_SELECT?FromDate=${FromDate}&ToDate=${ToDate}&M_ZoneID=${M_ZoneID}&M_ContractorID=${M_ContractorID}&M_WorkTypeID=${M_WorkTypeID}&M_AssetTypeID=${M_AssetTypeID}&M_AssetID=${M_AssetID ? M_AssetID : 0}&M_WardData=${M_WardData}&Flag=${Flag}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                return result.data
            } else {

                return result
            }
        })
})

const PenaltyWorkReportTableDataSlice = createSlice({
    name: "PenaltyWorkReportTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PenaltyWorkReportTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PenaltyWorkReportTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PenaltyWorkReportTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const PenaltyWorkReportTableDataReducer = PenaltyWorkReportTableDataSlice.reducer


export const PenaltyWorkReportPDFTableDataAPI = createAsyncThunk("PenaltyWorkReportPDFTableData", async ({ data }) => {
    const {
        M_UserID,
        token,
        M_ZoneID,
        M_ContractorID,
        M_WorkTypeID,
        M_AssetTypeID,
        M_AssetID,
        FromDate,
        M_WardData,
        Flag,
        ToDate,
        handleGetPDFUrl
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_RM_DB_GD_PENALTY_SELECT?FromDate=${FromDate}&ToDate=${ToDate}&M_ZoneID=${M_ZoneID}&M_ContractorID=${M_ContractorID}&M_WorkTypeID=${M_WorkTypeID}&M_AssetTypeID=${M_AssetTypeID}&M_AssetID=${M_AssetID ? M_AssetID : 0}&M_WardData=${M_WardData}&Flag=${Flag}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                handleGetPDFUrl && handleGetPDFUrl(result.url)
                return result.data
            } else {
                return result
            }
        })
})

const PenaltyWorkReportPDFTableDataSlice = createSlice({
    name: "Penalty WorkReportTableData",
    initialState: {
        isPDFLoading: false,
        PDFtableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PenaltyWorkReportPDFTableDataAPI.pending, (state, action) => {
            state.isPDFLoading = true;
        });
        builder.addCase(PenaltyWorkReportPDFTableDataAPI.fulfilled, (state, action) => {
            state.isPDFLoading = false;
            state.PDFtableData = action.payload;
        });
        builder.addCase(PenaltyWorkReportPDFTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPDFLoading = false;
            state.isError = true;
            state.PDFtableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const PenaltyWorkReportPDFTableDataReducer = PenaltyWorkReportPDFTableDataSlice.reducer