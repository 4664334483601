
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ZoneNameDDLAPI } from '../../../Redux/DDLSlice'
import { useAuthState } from '../../../Helper/Context'

export const ZoneNameDDL = (props) => {
    const { ZoneNameDDLID, setZoneNameDDLID, Language, ZoneDDL, ZWard, ZLable, flag, Flag, rowData } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            UserID,
            token: token,
            Language: Language.Label
        }
        { Language.Label != '' && dispatch(ZoneNameDDLAPI({ data })) }
    }, [Language.Label])

    const { DDLData } = useSelector(state => state.ZoneNameDDLData)

    useEffect(() => {
        handleAssetDDL()
    }, [DDLData])

    const handleAssetDDL = () => {
        if (DDLData && DDLData.table && DDLData.table.length > 0) {
            let list = DDLData.table.map((item, index) => ({
                value: item.m_ZoneID,
                label: item.zoneName,
            }))

            // setZoneNameDDLID({
            //     DDL: list,
            //     ID: list[0].value,
            //     Label: list[0].label,
            // })
            // if (flag == 'ZoneScreen') {
            //     setZoneNameDDLID({
            //         DDL: list,
            //         ID: ZWard,
            //         Label: ZLable,
            //     })
            // }
            if (flag == 'ZoneScreen' || ZoneDDL == 'DashboardZone' || localStorage.getItem('ZoneID')) {
                setZoneNameDDLID({
                    DDL: list,
                    ID: localStorage.getItem('ZoneID') ? localStorage.getItem('ZoneID') : ZWard ? ZWard : list[0].value,
                    Label: localStorage.getItem('ZoneLabel') ? localStorage.getItem('ZoneLabel') : ZLable ? ZLable : list[0].label,
                })
            }
            else {
                setZoneNameDDLID({
                    DDL: list,
                    ID: Flag === 'Update' ? rowData.m_ZoneID : ZWard ? ZWard : list[0].value,
                    Label: Flag === 'Update' ? rowData.zoneName : ZLable ? ZLable : list[0].label
                })
            }

        }
        else {
            setZoneNameDDLID({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }
    }

    const handleChange = (e) => {
        setZoneNameDDLID({ ...ZoneNameDDLID, ID: e.value, Label: e.label })
        if (ZoneDDL == 'DashboardZone') {
            localStorage.setItem('ZoneID', JSON.stringify(e.value))
            localStorage.setItem('ZoneLabel', e.label)
        }
    }
    const handleChangeClear = () => {
        setZoneNameDDLID({ ...ZoneNameDDLID, ID: 0, Label: "Select..." })
        localStorage.setItem('ZoneID', '0')
        localStorage.setItem('ZoneLabel', "Select...")
    }

    return (
        <div className={ZoneDDL == 'DashboardZone' ? "form-group d-flex align-items-center mr-3" : 'form-group'}>
            <label className="d-block fw-bold mr-2" htmlFor="NameofDepartment">{t('AdminDashboard.Admin_Sidebar_Table_Heading.Zone_Name')}</label>
            <Select
                // isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: ZoneNameDDLID.ID, label: ZoneNameDDLID.Label }}
                onChange={(e) => {
                    e ?
                        // setZoneNameDDLID({ ...ZoneNameDDLID, ID: e.value, Label: e.label })
                        // :
                        // setZoneNameDDLID({ ...ZoneNameDDLID, ID: 0, Label: "Select..." })
                        handleChange(e)
                        :
                        handleChangeClear()

                }}
                options={ZoneNameDDLID.DDL}
            />
        </div>
    )
}