
import { useEffect } from 'react'
import Select from 'react-select'
import { MaintainByDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { useTranslation } from 'react-i18next'

export const MaintainByDDLDataForPopup = (props) => {
    const { MaintainByForPopup, setMaintainByForPopup, WardName, rowData, Flag, Language, M_AssetTypeID } = props
    const { t } = useTranslation()
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_AssetTypeID: M_AssetTypeID,
            M_UserID: UserID,
            token: token,
            Language: Language.Label,
        }
        { Language.Label != '' && dispatch(MaintainByDDLAPI({ data })) }
    }, [Language.Label])

    const { MaintainByDDL } = useSelector(state => state.MaintainByDDLData)

    useEffect(() => {
        handleMaintainByDDL()
    }, [MaintainByDDL])

    const handleMaintainByDDL = () => {
        if (MaintainByDDL && MaintainByDDL.table && MaintainByDDL.table.length > 0) {
            let list = MaintainByDDL.table.map((item, index) => ({
                value: item.m_IndicatorID,
                label: item.indicatorName,
            }))

            setMaintainByForPopup({
                DDL: list,
                ID: Flag === 'Update' ? rowData.maintainByIndicatorAgencyID : 0,
                Label: Flag === 'Update' ? rowData.indicatorName : "Select...",
            })
        }
        else {
            setMaintainByForPopup({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofEmployee"><b>{t('Dashboard.Pcmc_Assets.Maintain_By')}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width3"
                isSearchable
                maxMenuHeight={150}
                value={{ value: MaintainByForPopup.ID, label: MaintainByForPopup.Label }}
                onChange={(e) => {
                    e ?
                        setMaintainByForPopup({ ...MaintainByForPopup, ID: e.value, Label: e.label })
                        :
                        setMaintainByForPopup({ ...MaintainByForPopup, ID: 0, Label: "Select..." })

                }}
                options={MaintainByForPopup.DDL}
            />
        </div>
    )
}