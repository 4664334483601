import React, { Component, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { FinancialYearDataDDL } from "../CommonDDL/FinancialYearDataDDL";
import { MonthDataDDL } from "../CommonDDL/MonthDataDDL";
import { FinancialYearHeaderDDL } from "../CommonDDL/FinancialYearHeaderDDL";
import { MonthHeaderDDL } from "../CommonDDL/MonthHeaderDDL";
import { WardnameHeaderDDL } from "../CommonDDL/WardnameHeaderDDL";
import ChangePassword from "../../Pages/Login/ChangePassword";
import { BaseUrl } from "../../Helper/BaseUrl";
import HeaderLogo from "./HeaderLogo";
import { useAuthState } from "../../Helper/Context";
import { useDispatch } from "react-redux";
import { LanguageDataDDL } from "../CommonDDL/LanguageDataDDL";

export default function Header(props) {

    const { DashboardHeading, handleGetFinancialYearID, flag, DashboardName,
        setYearValue, setMonthValue, setMonthLabel, setYearLabel, HYear, YLable, HMonth, MLable, LanLable, Lang, setWardValue, setWardLabel, setLangValue, setLangLabel, ProjectTypeId, HideYearMonthDDL, Language, setLanguage, Flag } = props

    const userDetails = useAuthState();
    const { UserID, token, UserFullName } = userDetails
    const dispatch = useDispatch()

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ChangePasswordPopUp, setChangePasswordPopUp] = useState()

    const handleChangePassword = () => {
        setChangePasswordPopUp(true)
    }

    const handleOnCloseClick = () => {
        setChangePasswordPopUp(false)
    }

    const navigate = useNavigate()

    useEffect(() => {
        handleGetFinancialYearID && handleGetFinancialYearID(FinancialYear, Month)
    }, [FinancialYear.ID, Month.ID])

    useEffect(() => {
        if (setYearValue !== undefined) {
            setYearValue(FinancialYear.ID)
        }
        if (setYearLabel !== undefined) {
            setYearLabel(FinancialYear.Label)
        }
    }, [FinancialYear.ID])

    useEffect(() => {
        if (setMonthValue !== undefined) {
            setMonthValue(Month.ID)
        }
        if (setMonthLabel !== undefined) {
            setMonthLabel(Month.Label)
        }
    }, [Month.ID])

    useEffect(() => {
        if (setWardValue !== undefined) {
            setWardValue(WardName.ID)
        }
        if (setWardLabel !== undefined) {
            setWardLabel(WardName.Label)
        }
    }, [WardName.ID])

    const logOutClick = () => {
        navigate("/")
    }

    const toggle = () => {
        const sidebar = document.getElementById('SidebarToggle')
        sidebar.classList.toggle('active')
        sidebar.classList.toggle('sidebaractive')
    }


    const handleLogoClick = () => {
        navigate("/dashboard")
    }


    return (
        <>
            <div className="pb-1 header " >
                <header className="navbar pcoded-header navbar-expand-lg navbar-expand-md navbar-light header-dark navbar_new">
                    <HeaderLogo />
                    <div className="m-header">
                        <span onClick={toggle} >
                            <i className="fa-solid fa-bars ham-burger mt-lg-3 ml-lg-2"></i>
                        </span>
                        <a className="mobile-menu toggler_md_icon" id="mobile-collapse"
                        >
                            {
                                BaseUrl == "https://apiamravatimc.cdat.work" ?
                                    <img src="../assets/img/amravati.png" alt="" className="ps-3 pc_none mob_logo" style={{ width: '60px' }} />
                                    :
                                    <img src="../assets/img/PCMC_Logo-01.png" alt="" className="ps-3 pc_none mob_logo" style={{ width: '60px' }} />

                            }
                            {/* <span className="px-2 ms-5"></span> */}
                        </a>

                    </div>
                    <div className="navbar-collapse py-2">
                        <div className="navbar-nav mr-auto">
                            {/* <h2 className="mob_head head_md pt-2 ps-5 text-dark" >उद्यान व वृक्षसंवर्धन कामांचे सनियंत्रण संगणक प्रणाली<br className="d-lg-none d-md-none" />
                                <span className="ps-3 mob_head_span tab_head_span" style={{ color: "#01836D" }}>वेब व मोबाईल आधारित</span>
                            </h2> */}
                            <h4 className="text-black py-2 text-center fw-bold ml-lg-3 dash_head" >उद्यान व
                                वृक्षसंवर्धन कामांचे
                                सनियंत्रण संगणक प्रणाली
                                <span className="ps-2" style={{ fontSize: '14px', color: 'green' }}>   वेब व मोबाईल आधारित</span>
                            </h4>
                        </div>
                        <div className="d-flex mob_year_month_ddl mt-lg-2 align-items-end">
                            {
                                HideYearMonthDDL == 'Dashboard' &&
                                <>
                                    <FinancialYearHeaderDDL
                                        FinancialYear={FinancialYear}
                                        setFinancialYear={setFinancialYear}
                                        YLable={YLable}
                                        HYear={HYear}
                                        flag={flag}
                                    />
                                    <MonthHeaderDDL
                                        Month={Month}
                                        setMonth={setMonth}
                                        MLable={MLable}
                                        HMonth={HMonth}
                                        flag={flag}
                                    />

                                </>
                            }
                            <LanguageDataDDL
                                Language={Language}
                                setLanguage={setLanguage}
                            />

                        </div>
                        <div className="logout-container">
                            <button className="btn" data-bs-toggle="dropdown" aria-expanded="false">
                                <img className="avatar_size" src="../assets/img/avatar-2.png" alt="" style={{ width: "2em" }} />
                            </button>
                            <ul className="dropdown-menu logout-dropdown " aria-labelledby="dropdownMenuButton1">
                                <li className="lineheight p-2"
                                    onClick={handleChangePassword}
                                >
                                    <a class="dropdown-item text-start" href="#">
                                        <i class="fa-solid fa-lock"></i> <b>Change Password </b>
                                    </a>
                                </li>
                                <li className="lineheight p-2"
                                    onClick={logOutClick}
                                >
                                    <a class="dropdown-item text-start text-danger" href="#">
                                        <i class="fa-solid fa-power-off "></i> <b> Log out</b>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </header>
            </div>
            {
                ChangePasswordPopUp ?
                    <ChangePassword
                        open={ChangePasswordPopUp}
                        popupHeading="Change Password"
                        handleOnCloseClick={handleOnCloseClick}
                    />
                    : <></>
            }
        </>
    )
}