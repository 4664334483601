import React from 'react'
import SewerageServiceCard from './SewerageServiceCard'
import { useNavigate } from 'react-router-dom'


export default function SewerageServiceCardManage(props) {

    const { Countdata,HeaderMonthID,HeaderFinancialYear , MonthValue, YearValue , YearLabel,MonthLabel,WardValue,WardLabel,ProjectTypeId,DashboardName } = props

    const navigate = useNavigate()
    const SewerageWorkNavigate = (name, screenFlag) => {
        if (name === "Operation") {
            navigate(`/preventiveservicecommondata?name=${name}&ApiFlag=Operation&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&M_SW_WorkTypeID=2&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Electrical Maintenance") {
            navigate(`/preventiveservicecommondata?name=${name}&ApiFlag=Electrical&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&M_SW_WorkTypeID=4&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Mechanical Maintenance") {
            navigate(`/preventiveservicecommondata?name=${name}&ApiFlag=Mechanical&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&M_SW_WorkTypeID=3&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Civil Maintainance ") {
            navigate(`/preventiveservicecommondata?name=${name}&ApiFlag=Civil&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&M_SW_WorkTypeID=1&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }

    return (
        <div className='col-12 col-lg-8'>
        <div className="row pb-3 p-3">
            <div className="col-12">
                <h5 className=" py-1">Services</h5>
            </div>

            <div className="col-12 col-lg-6 mb-2">
                <SewerageServiceCard
                    title="Operation"
                    imgPath='./assets/img/engineering.png'
                    count={Countdata.OperationServicesTotalCount}
                    SewerageWorkNavigate={SewerageWorkNavigate}
                    />
            </div>

            <div className="col-12 col-lg-6 mb-2">
                <SewerageServiceCard
                    title="Electrical Maintenance"
                    imgPath='./assets/img/renewable-energy.png'
                    count={Countdata.ElectricalServicesTotalCount}
                    SewerageWorkNavigate={SewerageWorkNavigate}
                    
                    />
            </div>

            {/* <div className="col-12 col-lg-3 mb-2">
                <SewerageServiceCard
                    title="Mechanical Maintenance"
                    imgPath='./assets/img/mechanics.png'
                    count={Countdata.MechanicalServicesTotalCount}
                    SewerageWorkNavigate={SewerageWorkNavigate}
                     />
            </div>

            <div className="col-12 col-lg-3 mb-2">
                <SewerageServiceCard
                    title="Civil Maintainance "
                    imgPath='./assets/img/engineers (1).png'
                    count={Countdata.CivilServicesTotalCount}
                    SewerageWorkNavigate={SewerageWorkNavigate}
                     />
            </div> */}


        </div>
        </div>
    )

}
