
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";

// Get Table Data
export const SportsWorkAssignedTableDataAPI = createAsyncThunk("SportsWorkAssignedTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        T_UploadAssignEquipmentID,
        M_WardID,
        M_ZoneID,
        M_AssetTypeID,
        M_AssetID,
        M_ContractorID,
        M_UsersID,
        Flag,
        M_WorkTypeID,
        M_RoleID,
        Language,
        From,
        To,
        token,

    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_Web_GD_T_UploadAssignEquipment_Select?FromDate=${FromDate}&ToDate=${ToDate}&T_UploadAssignEquipmentID=${T_UploadAssignEquipmentID ? T_UploadAssignEquipmentID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&M_UsersID=${M_UsersID}&Flag=${Flag}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_RoleID=${M_RoleID ? M_RoleID : '0'}&Language=${Language}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const SportsWorkAssignedTableDataSlice = createSlice({
    name: "SportsWorkAssignedTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SportsWorkAssignedTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SportsWorkAssignedTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(SportsWorkAssignedTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const SportsWorkAssignedTableDataReducer = SportsWorkAssignedTableDataSlice.reducer


