
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { WorkNameDDLAPI } from '../../Redux/DDLSlice'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'

export const WorkNameDDL = (props) => {
    const { WorkName, setWorkName, M_WorkTypeID, Flag, rowData, WorkType, WorkSubType, IsPost,Language } = props

    const {t} = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()
    const [workTypeID, setworkTypeID] = useState('')
    useEffect(() => {
        const data = {
            M_WorkTypeID: WorkType.ID,
            M_WorkSubTypeID: WorkSubType.ID,
            M_UserID: UserID,
            token: token,
            Language:Language.Label
        }
        if(WorkType.ID != 0 && WorkSubType.ID != 0){
            {Language.Label != '' && dispatch(WorkNameDDLAPI({ data }))}
        }
        
    }, [WorkType.ID, WorkSubType.ID, IsPost,Language.Label])

    const { WorkNameData } = useSelector(state => state.WorkNameDDLData)

    useEffect(() => {
        handleWorkNameDDL()
    }, [WorkNameData])

    const handleWorkNameDDL = () => {
        // console.log(DeptDDLDataa)
        if (WorkNameData && WorkNameData.table && WorkNameData.table.length > 0) {
            let list = WorkNameData.table.map((item, index) => ({
                value: item.m_WorkID,
                label: item.workname,
            }))
            setWorkName({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_WorkID : 0,
                Label: Flag === 'Update' ? rowData.projectWorkname : "Select...",
            })
        }
        else {
            setWorkName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label for="" className=""><b>{t('Dashboard.Work_Assigned_by_Officials.Work_Name')} {Flag && <AstricSign />}</b></label>

            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width2"
                isSearchable
                maxMenuHeight={130}
                value={{ value: WorkName.ID, label: WorkName.Label }}
                onChange={(e) => {
                    e ?
                        setWorkName({ ...WorkName, ID: e.value, Label: e.label })
                        :
                        setWorkName({ ...WorkName, ID: 0, Label: "Select..." })

                }}
                options={WorkName.DDL}
            />
        </div>
    )
}