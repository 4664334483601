import Header from "../../../../Components/Header/Header";
import Sidebar from "../../../../Components/Sidebar/Sidebar";
import Select from 'react-select'
import React, { useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useAuthState } from "../../../../Helper/Context";
import { GardenMasterTableDataAPI } from "../../../../Redux/MasterSlice/GardenMasterSlice";
import { useSearchParams } from "react-router-dom";
import ViewURL from "../../../../Components/ViewURL/ViewURL";
import GardenPopup from "../GardenPopup/GardenPopup";
import DeletePopup from "../GardenPopup/DeletePopup";
import { Pegination } from "../../../../Components/Pegination/Pegination";
import { WardNameDataDDL } from "../../../../Components/CommonDDL/WardNameDataDDL";
import GardenWisemaintenanceStatusPopUp from "./GardenWisemaintenanceStatusPopUp";
import { useTranslation } from "react-i18next";


export default function GardenWisemaintenanceStatus() {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    const [GardenMasterPopupShow, setGardenMasterPopupShow] = useState('')
    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [rowNo, setrowNo] = useState(1)


    const handleAddOnClick = () => {
        console.log("add")
        setGardenMasterPopupShow(true)
    }
    const handleOnCloseClick = () => {
        setGardenMasterPopupShow(false)
    }

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ZoneName, setZoneName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    // const [SectorName, setSectorName] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select...",

    // })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [Language])

    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }

    const handlerClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        // setSectorName({
        //     ...SectorName,
        //     ID: 0,
        //     Label: 'Select...',
        // })

        setWardName({
            ...WardName,
            ID: 0,
            Label: 'Select...',
        })
    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    useEffect(() => {
        const data = {
            M_AssetID: 0,
            UserID: UserID,
            token: token,
            From: From,
            To: To,
            M_AssetTypeID: '1',
            M_WardID: WardName.ID,
            Language:Language.Label
            // M_GD_SectorID: SectorName.ID,
        }
        {Language.Label != '' && dispatch(GardenMasterTableDataAPI({ data }))}
    }, [IsPost, To, IsClear, WardName.ID,Language.Label])

    const { tableData, isloading } = useSelector(state => state.GardenMasterTableData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [WardName.ID])

    return (
        <div>
            <Header
                // DashboardHeading="Garden Maintainance"
                DashboardName={DashboardName}
                Language={Language}
                setLanguage={setLanguage}
            />
            <div className="wrapper">
                <Sidebar
                    Language={Language}
                    ProjectTypeId={ProjectTypeId} />
                <div className="content">
                    <div className="row pb-4 master_border">
                        <div>
                            <h5 className="ps-1 pt-2"> {t('AdminDashboard.Admin_Sidebar_Name.Garden_wise_maintenance_status')}</h5>
                        </div>
                        <div className="row m-0 ">
                            <div className="col-12 py-1  rounded rounded-2 shadow"
                                style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                <div className="col-12  ">
                                    <div className="row py-1 rounded2">
                                        <div className="col-12 col-md-5 col-lg-3">
                                            <WardNameDataDDL
                                                WardName={WardName}
                                                setWardName={setWardName}
                                                Language={Language}
                                                ZoneName={ZoneName}
                                            />
                                        </div>
                                        {/* <div className="col-12 col-md-5 col-lg-3">
                                            <SectorDataDDL
                                                SectorName={SectorName}
                                                setSectorName={setSectorName}
                                                WardName={WardName}
                                            />
                                        </div> */}
                                        <div className="col-12 col-md-2 col-lg-2 mt-lg-4 mt-md-4" >
                                            <button type="button" className="btn-clear btn btn-primary"
                                                onClick={handlerClear} >
                                                Clear</button>
                                        </div>
                                    </div>
                                    <div className="row mob_table table-responsive pt-1">
                                        <table id="totalordergenerated"
                                            cellPadding="0"
                                            cellSpacing="0"
                                            border="0"
                                            className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="sr_no_col">Sr No.</th>
                                                    <th>Prabhag No</th>
                                                    {/* <th>Sector Name</th> */}
                                                    <th>Area Name</th>
                                                    <th>Garden Name</th>
                                                    <th>Image</th>
                                                    <th>Status</th>
                                                    {/* <th>Action</th> */}

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) =>
                                                        <tr style={{ width: '100%' }}>
                                                            <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                            <td>{item.wardName ? item.wardName : '-'}</td>
                                                            {/* <td>{item.sectorName ? item.sectorName : '-'}</td> */}
                                                            <td>{item.areaName ? item.areaName : '-'}</td>
                                                            <td>{item.assetName ? item.assetName : '-'}</td>

                                                            <td style={{ textAlign: "center" }}>
                                                                {
                                                                    (item.assetPhoto == null) ?
                                                                        <span>-</span>
                                                                        :
                                                                        <>
                                                                            <i className="fa-solid fa-eye"
                                                                                style={{ cursor: 'pointer', alignItems: "center" }} title='View'
                                                                                onClick={() => handleShowImage(item.assetPhoto)}
                                                                            ></i>
                                                                        </>
                                                                }
                                                            </td>
                                                            {/* <td style={{ textAlign: "center" }}>{ <i style={{ cursor: 'pointer', fontSize: '1rem' }} className="fa-solid fa-location-dot"></i>}</td> */}
                                                            <td>{item.statusName ? item.statusName : '-'}</td>
                                                            {/* <td style={{ textAlign: "center", width: '7%' }}>
                                                                <span>
                                                                    <GardenWisemaintenanceStatusPopUp
                                                                        open={GardenMasterPopupShow}
                                                                        // GardenMasterFlag='GardenMaster'
                                                                        popupHeading="Garden Wise Maintainance Status"
                                                                        item={item}
                                                                        Flag='Update'
                                                                        M_AssetTypeID='1'
                                                                        handleIsPost={handleIsPost}
                                                                    />
                                                                </span>
                                                                <span>
                                                                    <DeletePopup
                                                                        open={DeletePopupShow}
                                                                    />
                                                                </span>
                                                            </td> */}
                                                        </tr>
                                                    ) :
                                                        <>No Found data</>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {tableData && tableData.table && tableData.table.length > 0 &&
                                        <Pegination
                                            PerPageCount={PerPageCount}
                                            TotalCount={tableData.table[0].totalCount}
                                            setFrom={setFrom}
                                            setTo={setTo}
                                            setrowNo={setrowNo}
                                            CurrentPage={CurrentPage}
                                            setCurrentPage={setCurrentPage}
                                            IsClear={IsClear}
                                        />
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {
                showImagePopup ?
                    <ViewURL
                        setshowImagePopup={setshowImagePopup}
                        ImagePopUpData={ImagePopUpData}
                    />
                    : ''
            }

        </div >
    )
}