import React from "react";
import { useNavigate } from "react-router-dom";



export default function GardenMasterCard({ count, title, navLink, cardName, border, color, imgPath,MasterNavigate }) {
    const navigate = useNavigate()

    const handleGardenMasterCard = () => {
        navigate(navLink)
    }
    return (
        <div className="card shadow mb-1 br-10" style={{ cursor: 'pointer' }} onClick={() => MasterNavigate(title)} >

            <div className="card-body bg-light-green">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <h4 className={color === 'red' ? "fw-normal text-red" : "fw-normal text-blue"}>{count}</h4>
                    </div>
                    <h4 className={color === 'red' ? "fw-normal text-red ImgBg mt-0 bg-red-light" : "fw-normal text-blue ImgBg mt-0 bg-blue-light"}>
                        <img style={{ width: "25px", height: '25px' }} src={imgPath} alt="" />
                    </h4>
                </div>
            </div>

            <div className={color === 'red' ? "card-footer py-3 bg-red-light" : "card-footer py-3 bg-blue-light"}>
                <div className={color === 'red' ? "row align-items-center text-red" : "row align-items-center text-blue"}>
                    <div className="col-10">
                        <p className="mb-0" style={{whiteSpace:"nowrap"}}><b>{title}</b></p>
                    </div>
                    <div className="col-2 text-end"><i className="fas fa-caret-right"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}