import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import { WardNameDataDDL } from '../../../../Components/CommonDDL/WardNameDataDDL';
import { AssetTypeDataDDL } from '../../../../Components/CommonDDL/AssetTypeDataDDL';
import { ContractorWiseAssetDDLAPI } from '../../../../Redux/DDLSlice';
import { Loading } from '../../../../Helper/Loading';
import { ContractorWiseAssetPostAPI } from '../../../../Redux/GardenDashboardSlice/ContractorWiseAssetSlice.js/ContractorWiseAssetSlice';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ZoneDataDDLS } from '../../../../Components/CommonDDL/ZoneDataDDLS';
import { WorkTypeDDL } from '../../../../Components/CommonDDL/WorkTypeDDL';
import { WardWiseAssetNameDataDDL } from '../../../../Components/CommonDDL/WardWiseAssetDDL';


export default function ContractorWiseAssetsPopup(props) {

    const { handleOnCloseClick, open, item, Flag, handleIsPost, apiFlag, rowData, HWard, WLable } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let Lang = searchParams.get("Lang")

    const [IsClear, setIsClear] = useState(false)

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [ZoneName, setZoneName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ContractorName, setContractorName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ContactPersonName, setContactPersonName] = useState({
        DDL: [],
        ID: 0,
        m_UserID: 0,
        Label: "Select...",
    })
    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WardWiseAssetName, setWardWiseAssetName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    const [AssetName, setAssetName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [Language])

    const [gridData, setgridData] = useState([])

    useEffect(() => {
        const data = {
            M_WorkTypeID: WorkType.ID,
            M_AssetID: WardWiseAssetName.ID,
            Flag1: 'ContractorName',
            M_ZoneID: ZoneName.ID,
            M_AssetTypeID: AssetType.ID,
            M_RoleID: RoleID,
            M_UserID: UserID,
            token: token,
            Language: Language.Label,
            Flag: 'Master'
        }
        if (WorkType.ID != 0) {
            { Language.Label != '' && dispatch(ContractorWiseAssetDDLAPI({ data })) }
        }
    }, [WorkType.ID, WardWiseAssetName.ID, Language.Label, ZoneName.ID, AssetType.ID])

    const { DDLData, isDDLLoading } = useSelector(state => state.ContractorWiseAssetDDLData)

    // useEffect(() => {
    //     if (DDLData) {
    //         let roleData = DDLData.map((item) => ({ ...item }))
    //         setgridData(roleData)
    //     }
    // }, [DDLData])

    useEffect(() => {
        // if (WardName.ID != 0 && AssetType.ID != 0 && ContractorName.ID != 0 && DDLData) {
        if (WorkType.ID != 0 && DDLData) {
            let roleData = DDLData?.map((item) => ({ ...item }))

            setgridData(roleData)
        }
    }, [DDLData])

    const handleOnCheckClick = (item, index, check) => {
        if (gridData) {
            let tempGridData = [...gridData]
            tempGridData[index].isCheck = check
            // console.log(tempGridData)
            setgridData(tempGridData)
        }
    }

    const handlePost = () => {

        let AllCheckData = ''
        gridData.map((i) => {
            if (i.isCheck) {
                AllCheckData += i.m_ContractorID + ','
            }
        })

        const data = {
            M_ContractorID_Data: AllCheckData,
            M_AssetID: WardWiseAssetName.ID,
            M_UsersID: UserID,
            M_WorkTypeID: WorkType.ID,
            Flag: 'Insert',
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(ContractorWiseAssetPostAPI({ data }))
    }

    return (
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick} contentStyle={{ width: "70vw" }}
        >
            <div className="area_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">{t('AdminDashboard.Admin_Sidebar_Name.Contractor_Wise_Assets')}</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">
                                <div className="col-12 col-md-5 col-lg-4">
                                    <ZoneDataDDLS
                                        ZoneName={ZoneName}
                                        setZoneName={setZoneName}
                                        Language={Language}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <WardNameDataDDL
                                        WardName={WardName}
                                        setWardName={setWardName}
                                        HWard={HWard}
                                        WLable={WLable}
                                        Language={Language}
                                        ZoneName={ZoneName}
                                    />
                                </div>
                                <div className="col-12 col-md-6 col-lg-4">
                                    <AssetTypeDataDDL
                                        AssetType={AssetType}
                                        setAssetType={setAssetType}
                                        Language={Language}
                                    />
                                </div>
                               
                                <div className="col-12 col-md-6 col-lg-4">
                                    
                                    <WardWiseAssetNameDataDDL
                                        WardWiseAssetName={WardWiseAssetName}
                                        setWardWiseAssetName={setWardWiseAssetName}
                                        WardName={WardName}
                                        AssetType={AssetType}
                                        M_WorkTypeID='0'
                                        M_ZoneID={ZoneName}
                                        M_RoleID={RoleID}
                                        M_GD_WorkPeriodID='0'
                                        // M_AssetTypeID={1}
                                        Language={Language}
                                        Flag='Master'
                                    />
                                </div>
                                <div className="col-12 col-md-4 col-lg-4">
                                    <WorkTypeDDL
                                        WorkType={WorkType}
                                        setWorkType={setWorkType}
                                        // IsPost={IsPost}
                                        Language={Language}
                                    />

                                </div>

                                <div className="row mob_table table-responsive pt-1">
                                    <table id="totalordergenerated"
                                        cellPadding="0"
                                        cellSpacing="0"
                                        border="0"
                                        className="table table-bordered">

                                        <thead>
                                            <tr>
                                                <th width="5% text-center">
                                                </th>
                                                <th>Contractor Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isDDLLoading && <Loading />}
                                            {
                                                gridData && gridData.length > 0 ?
                                                    gridData.map((i, index) => {
                                                        return (
                                                            <tr >
                                                                <td className="text_center">
                                                                    <div className="text-center">
                                                                        <input
                                                                            className="text-center"
                                                                            type="checkbox"
                                                                            name={i.contractorName}
                                                                            value={i.m_ContractorID}
                                                                            checked={i.isCheck}
                                                                            onChange={(e) => {
                                                                                handleOnCheckClick(e, index, !i.isCheck)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>{i.contractorName != null ? i.contractorName : '-'}</td>

                                                            </tr>
                                                        )
                                                    })
                                                    :
                                                    <>No data found</>
                                            }
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                        disabled={
                                            WorkType.ID == '0'
                                        }
                                    >Save</button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </Popup>
    )

}