import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const EmployeeTableDataAPI = createAsyncThunk("EmployeeTableData", async ({ data }) => {
    const {
        // M_DepartmentID,
        M_EmployeeID,
        M_DesignationID,
        EmployeeName,
        M_UsersID,
        token,
        From,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_M_Employee_Select?M_EmployeeID=${M_EmployeeID ? M_EmployeeID : '0'}&M_DesignationID=${M_DesignationID ? M_DesignationID : '0'}&EmployeeName=${EmployeeName ? EmployeeName : ''}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const EmployeeTableDataSlice = createSlice({
    name: "EmployeeTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeeTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EmployeeTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(EmployeeTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const EmployeeTableDataReducer = EmployeeTableDataSlice.reducer


export const EmployeePostDataAPI = createAsyncThunk("EmployeePostData", async ({ data }) => {
    const {
        M_DepartmentID,
        M_EmployeeID,
        M_DesignationID,
        EmployeeName,
        MobileNumber,
        Email_ID,
        JoiningDate,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_EmployeeID", M_EmployeeID);
    formdata.append("M_DepartmentID", M_DepartmentID);
    formdata.append("M_DesignationID", M_DesignationID);
    formdata.append("EmployeeName", EmployeeName);
    formdata.append("MobileNumber", MobileNumber);
    formdata.append("Email_ID", Email_ID);
    formdata.append("JoiningDate", JoiningDate);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Post_M_Employee_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const EmployeePostDataSlice = createSlice({
    name: "EmployeePostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeePostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EmployeePostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(EmployeePostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const EmployeePostDataReducer = EmployeePostDataSlice.reducer

// -----------------Delete API ---------------

export const EmployeeDeleteAPI = createAsyncThunk("EmployeeDelete", async ({ data }) => {
    const {
        M_EmployeeID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    // formdata.append("M_CropTypeID", rowData?.m_CropTypeID);
    var formdata = new FormData();
    formdata.append("M_EmployeeID", M_EmployeeID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_Employee_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const EmployeeDeleteSlice = createSlice({
    name: "EmployeeDelete",
    initialState: {
        isDeleteLoading: false,
        EmployeeData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeeDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(EmployeeDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.EmployeeData = action.payload;

        });
        builder.addCase(EmployeeDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.EmployeeData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const EmployeeDeleteReducer = EmployeeDeleteSlice.reducer