

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";

// Count
export const CommWorkProgressCountDataAPI = createAsyncThunk("CommWorkProgressCountData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_WardID,
        M_UsersID,
        Flag,
        M_ContractorID,
        token,
        setCountData,
        setGraphLable,
        M_ZoneID,
        Language,
       
    } = data
    var lables = []
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_UploadAssignWorkByContractorsCount_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_WardID=${M_WardID}&M_ZoneID=${M_ZoneID ?M_ZoneID :'0'}&M_UsersID=${M_UsersID}&M_ContractorID=${M_ContractorID}&Flag=${Flag}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setCountData(result.data.table)
                for (var i = 0; i < result.data.table.length; i++) {
                    lables.push(result.data.table[i].benchMark)
                    // console.log("name", lables)
                    setGraphLable(lables)
                }
                return result.data.table
            } else {
                return result
            }
        })
})

const CommWorkProgressCountDataAPISlice = createSlice({
    name: "CommWorkProgressCountData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommWorkProgressCountDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CommWorkProgressCountDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(CommWorkProgressCountDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CommWorkProgressCountDataAPIReducer = CommWorkProgressCountDataAPISlice.reducer





// Table Data
export const CommWorkProgressTableDataAPI = createAsyncThunk("CommWorkProgressTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_AssetID,
        M_AssetTypeID,
        M_GD_WorkPeriodID,
        M_ContractorID,
        M_UsersID,
        Flag,
        ShowBy,
        From,
        To,
        token,
        M_ZoneID ,
        Language
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_UploadAssignWorkByContractorsDetails_Select?FromDate=${FromDate}&ToDate=${ToDate}&M_WardID=${M_WardID}&M_ZoneID=${M_ZoneID?M_ZoneID :'0'}&M_AssetID=${M_AssetID}&M_AssetTypeID=${M_AssetTypeID}&M_GD_WorkPeriodID=${M_GD_WorkPeriodID}&M_ContractorID=${M_ContractorID ? M_ContractorID : 0}&M_UsersID=${M_UsersID}&Flag=${Flag}&ShowBy=${ShowBy}&From=${From}&To=${To}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data.table
            } else {
                return result.data
            }
        })
})

const CommWorkProgressTableDataAPISlice = createSlice({
    name: "CommWorkProgressTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommWorkProgressTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CommWorkProgressTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(CommWorkProgressTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CommWorkProgressTableDataAPIReducer = CommWorkProgressTableDataAPISlice.reducer


