import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import GardenWorkAssignCard from './GardenWorkAssign'
import { t } from 'i18next'

export default function WorkProgress(props) {

    const { Countdata,HeaderMonthID,Language , MonthValue, YearValue , YearLabel,MonthLabel,WardValue,WardLabel,ProjectTypeId,DashboardName } = props

    // console.log(FinancialYearID)
    const [searchParams] = useSearchParams()
    let Lang = searchParams.get("Lang")


    const navigate = useNavigate()
    const workAssignedNavigate = (name,ApiFlag,Flag) => {
            navigate(`/WorkAssignedDMCTable?name=${name}&Flag=${Flag}&ApiFlag=${ApiFlag}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)
    }
    return (
        <div className="col-12 col-lg-7 ">
            <div className='row'>
                <h5 className="ps-4 pb-2">{t('AdminDashboard.Admin_Titles.Work_Progress_by_Contractors')}</h5>
                <div className="col-12 col-lg-6">
                    <GardenWorkAssignCard
                        title="Pending"
                        Flag="Pending"
                        ApiFlag="WorkAssingPending"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/expired.png'
                        count={Countdata.UploadAssignWork_Pending_TotalCount ? Countdata.UploadAssignWork_Pending_TotalCount : '0'}
                        // navLink='/'
                        workAssignedNavigate={workAssignedNavigate}
                    />

                </div>

                <div className="col-12 col-lg-6">
                    <GardenWorkAssignCard
                        title="Completed"
                        Flag="Completed"
                        ApiFlag="WorkAssingCompleted"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/approve.png'
                        count={Countdata.UploadAssignWork_Completed_TotalCount ? Countdata.UploadAssignWork_Completed_TotalCount : '0'}
                        // navLink='/'
                        workAssignedNavigate={workAssignedNavigate}
                    />

                </div>
            </div>
        </div>
    )

}

