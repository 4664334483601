
import React from "react";
import { useNavigate } from "react-router-dom";

export default function WaterPreventiveWorkCard({ title, navLink, count, imgPath, cardName, PreventiveWorkNavigate }) {
    const navigate = useNavigate()

    const handleWaterPreventiveWorkCardClick = () => {
        navigate(navLink)
    }
    return (

        <div style={{ height: '100%', cursor: 'pointer' }} className="mb-1 p-3 shadow blue_borders bg-white mb-md-2 daily-card-width" onClick={() => PreventiveWorkNavigate(title)}>
            <div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                <div className="text-center" >
                    <div className="d-flex justify-content-between align-items-center">
                        <h4>{count}</h4>
                        <img className={cardName} style={{ height: '35px', width: '35px' }} src={imgPath} alt="" />
                    </div>
                </div>
                <div style={{ borderLeft: '2px solid #d6bea7' }}>
                    <h6 className="text-black ml-3"><b>{title}</b></h6>
                </div>
            </div>

        </div>

    )
}


