var url = window.location.origin
export const BaseUrl =
    // url === "http://gayatriagroerp.gkmarket.in" ? "http://gayatriagroerpapi.gkmarket.in"

    url === "http://garden.nmmcsmartservices.com" ? "http://gardenapi.nmmcsmartservices.com"
        : url === "http://pcmc.cdatinfotech.com" ? "http://pcmcapi.cdatinfotech.com" :
            url === "http://pcmcdemo.cdatinfotech.com" ? "http://pcmcdemoapi.cdatinfotech.com" :
                url === "https://pcmcdaksha.in" ? "https://api.pcmcdaksha.in" :
                    url === "http://pcmcdaksha.in" ? "http://api.pcmcdaksha.in" :
                        url === "http://demo.pcmcdaksha.in" ? "http://apidemo.pcmcdaksha.in" :
                            url === "https://pcmcdaksha.cdat.work" ? "https://apipcmcdaksha.cdat.work" :
                                url === "https://amravatimc.cdat.work" ? "https://apiamravatimc.cdat.work" :
                                    // "http://apidemo.pcmcdaksha.in"
                                    "https://apipcmcdaksha.cdat.work"
// "https://apiamravatimc.cdat.work"
// "http://api.pcmcdaksha.in"
// "http://pcmcapi.cdatinfotech.com"
// "http://pcmcdemoapi.cdatinfotech.com"
// "https://api.pcmcdaksha.in"
// "http://gardenapi.nmmcsmartservices.com"




