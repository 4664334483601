
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import WaterEnergyConsumptionPreventiveWorkPopup from './WaterEnergyConsumptionPreventiveWorkPopup'
import Header from '../../../../../../Components/Header/Header'
import Sidebar from '../../../../../../Components/Sidebar/Sidebar'
import DeletePopup from '../../../GardenPopup/DeletePopup'
import { useAuthState } from '../../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { WardNameDataDDL } from '../../../../../../Components/CommonDDL/WardNameDataDDL'
import { EnergyConsumptionDeleteAPI, EnergyConsumptionTableDataAPI } from '../../../../../../Redux/WaterSupplyDashBoardSlice/PreventiveWorkWaterSupply/EnergyConsumptionSlice'
import { WaterSupplyPumpingStationDataDDL } from '../../../../../../Components/CommonDDL/WaterSupplyPumpingStationDDl'
import { Loading } from '../../../../../../Helper/Loading'
import { Pegination } from '../../../../../../Components/Pegination/Pegination'

export default function EnergyConsumptionPreventiveWorkPage() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)

    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [WaterEnergyConsumptionPreventiveWorkPopupShow, setWaterEnergyConsumptionPreventiveWorkPopupShow] = useState()
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')

    const [Countdata, setCountdata] = useState({}) 
    const [HeaderFinancialYear, setHeaderFinancialYear] = useState('')
    const [HeaderMonthID, setHeaderMonthID] = useState('')
    const [MonthID, setMonthID] = useState('')
    const [YearValue, setYearValue] = useState(null)
    const [YearLabel, setYearLabel] = useState(null)
    const [MonthValue, setMonthValue] = useState(null)
    const [MonthLabel, setMonthLabel] = useState(null)
    const [WardValue, setWardValue] = useState(null)
    const [WardLabel, setWardLabel] = useState(null)

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")

    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    const [FromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))
    const [Date, setDate] = useState(moment().format('YYYY-MM-DD'))

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WaterSupplyPumpingStation, setWaterSupplyPumpingStation] = useState({
        DDL: [],
        ID: '0',
        Label: "Select...",
    })

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    useEffect(() => {
        const data = {          
            FromDate: FromDate,
            ToDate: ToDate,
            M_WardID: WardName.ID,
            M_WS_PumpStationID: '0',
            M_UserID: UserID,
            token: token,
            From: From,
            To: To,
        }
        if (WardName.ID !== 0) {
            dispatch(EnergyConsumptionTableDataAPI({ data }))
        }

    }, [IsPost, To, IsClear, WardName.ID,WaterSupplyPumpingStation.ID,FromDate,ToDate])
    const { tableData, isLoading } = useSelector(state => state.EnergyConsumptionTableData)

    const handleEditOnClick = (item) => {
        setWaterEnergyConsumptionPreventiveWorkPopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }
    const handleOnCloseClick = () => {
        setWaterEnergyConsumptionPreventiveWorkPopupShow(false)
    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
        // if (tableData && tableData.table && tableData.table.length > 0) {
        //     setCurrentPage(0)
        //     setFrom(1)
        //     setTo(10)
        // }

    }

    const handleDeleteApi = (item) => {
        const data = {
            T_WS_EnergyConsumptionID: item.t_wS_EnergyConsumptionID,
            M_UserID: UserID,
            token: token,       
            handleIsPost: handleIsPost
        }

        dispatch(EnergyConsumptionDeleteAPI({ data }))
    }
    const { isDeleteLoading } = useSelector(state => state.EnergyConsumptionDelete)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [WardName.ID,WaterSupplyPumpingStation.ID])

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setWardName({
            ...WardName,
            ID: HWard,
            Label: WLable,
        })
        setWaterSupplyPumpingStation({
            ...WaterSupplyPumpingStation,
            ID: 0,
            Label: 'Select...',
        })
        setFromDate(moment().startOf('month').format('YYYY-MM-DD'))
        setToDate(moment().format('YYYY-MM-DD'))

        // console.log("call")
    }

    return (
        <div>
            {isLoading && <Loading />}
            {isDeleteLoading && <Loading />}
            <Header
                MLable={MLable}
                HMonth={HMonth}
                YLable={YLable}
                HYear={HYear}
                HWard={HWard}
                WLable={WLable}
                flag='Screen'
                DashboardName={DashboardName}
            />
            <div className="wrapper">
                <Sidebar ProjectTypeId={ProjectTypeId} />
                <div className="content">
                    {/* <!-- [ Main Content ] start --> */}
                    <div className='data_mob'>
                        <div className="row m-1 pb-4 master_border">
                            <div className='col-9'>
                                <h5 className="mt-2">Energy Consumption</h5>
                            </div>
                            <div className='col-3 mar_add'>
                                <div className="float-end butt">
                                    <button type="button" className="btn btn-primary ml-1 float-end">Export</button>
                                </div>
                            </div>
                            <div className="row m-0 ">
                                <div className="col-12 py-0 rounded rounded-2 shadow"
                                    style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                    <div className="col-12">

                                        <div className="row py-1 rounded2">
                                            <div className="col-lg-2 col-md-6">
                                                <div className="form-group">
                                                    <label className="d-block" htmlFor="NameofDepartment"><b>From Date :</b></label>
                                                    <input type="date"
                                                        className="form-control"
                                                        id="FromDate"
                                                        value={FromDate}
                                                        onChange={(e) => (setFromDate(e.target.value), setToDate(''))}
                                                    />
                                                </div>

                                            </div>
                                            <div className="col-lg-2 col-md-6">
                                                <div className="form-group">
                                                    <label className="d-block" htmlFor="NameofDepartment"><b>To Date :</b></label>
                                                    <input type="date"
                                                        className="form-control"
                                                        id="ToDate"
                                                        value={ToDate}
                                                        onChange={(e) => setToDate(e.target.value)}
                                                        min={FromDate}
                                                    />
                                                </div>

                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <WardNameDataDDL
                                                    WardName={WardName}
                                                    setWardName={setWardName}
                                                    HWard={HWard}
                                                    WLable={WLable}
                                                // Flag='ward'
                                                />


                                            </div>
                                            <div className="col-lg-3 col-md-6">                               
                                                <WaterSupplyPumpingStationDataDDL
                                                    WaterSupplyPumpingStation={WaterSupplyPumpingStation}
                                                    setWaterSupplyPumpingStation={setWaterSupplyPumpingStation}
                                                    WardName={WardName}
                                                    rowData={rowData}
                                               />
                                            </div>
                                            <div className="col-12 col-md-6 col-lg-1 mt-lg-4 mt-md-4 mt-1" >
                                                <button type="button"
                                                    className="btn-clear btn btn-primary"
                                                onClick={handleClear}
                                                >
                                                    Clear</button>

                                            </div>

                                        </div>
                                        <div className="row mob_table table-responsive pt-1">

                                            <table id="totalordergenerated"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                className="table table-bordered">

                                                <thead>
                                                    <tr>
                                                        <th className="sr_no_col">Sr No.</th>
                                                        <th>Pumping Station</th>
                                                        <th>Electric Consumption</th>
                                                        <th>Consumption KWH</th>
                                                        <th>Power Factor</th>
                                                        <th>Power Factor Incentive</th>
                                                        <th>Demand Penalty</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => (
                                                            <tr style={{ width: '100%' }}>
                                                                <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>                             
                                                                <td>{item.pumpStation ? item.pumpStation : '-'}</td>
                                                                <td>{item.electricConsumption ? item.electricConsumption : '-'}</td>
                                                                <td>{item.consumptionKWH ? item.consumptionKWH : '-'}</td>
                                                                <td>{item.powerFactor ? item.powerFactor : '-'}</td>
                                                                <td>{item.powerFactorIncentive ? item.powerFactorIncentive : '-'}</td>
                                                                <td>{item.demandPenalty ? item.demandPenalty : '-'}</td>


                                                                <td style={{ textAlign: "center", width: '7%' }}>
                                                                    {
                                                                        (moment(item.readingDate).format('YYYY-MM-DD')) == (moment().format('YYYY-MM-DD')) ?
                                                                            <>
                                                                                <span >

                                                                                    <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                        style={{ cursor: 'pointer' }}
                                                                                        onClick={() => handleEditOnClick(item)}
                                                                                    >
                                                                                    </i>
                                                                                </span>
                                                                                <span>
                                                                                    <DeletePopup
                                                                                        open={DeletePopupShow}
                                                                                        handleDeleteApi={handleDeleteApi}
                                                                                        item={item}
                                                                                    />
                                                                                </span>
                                                                            </>
                                                                            :
                                                                            '-'
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )) :
                                                            <>No data found</>
                                                    }
                                                </tbody>
                                            </table>

                                        </div>
                                        {tableData && tableData.table && tableData.table.length > 0 &&
                                            <Pegination
                                                PerPageCount={PerPageCount}
                                                TotalCount={tableData.table[0].totalCount}
                                                setFrom={setFrom}
                                                setTo={setTo}
                                                setrowNo={setrowNo}
                                                CurrentPage={CurrentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    WaterEnergyConsumptionPreventiveWorkPopupShow ?
                        <WaterEnergyConsumptionPreventiveWorkPopup
                            open={WaterEnergyConsumptionPreventiveWorkPopupShow}
                            // popupHeading="Water Quality-Chlorine"                        
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                        />
                        : <></>
                }
            </div>
        </div>



    )
}