
import { useEffect } from 'react'
import Select from 'react-select'
import { GDProjectNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { AstricSign } from '../../Helper/AstricSign'

export const GDProjectNameDDL = (props) => {
    const { ProjectName, setProjectName, WardName, PopUpField, rowData, Flag, IsPost, M_ProjectTypeID } = props


    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_ProjectTypeID: M_ProjectTypeID,
            Flag: 'ProjectWiseContractor',
            M_UserID: UserID,
            token: token,
           
        }
        dispatch(GDProjectNameDDLAPI({ data }))
    }, [M_ProjectTypeID])

    const { GDProjectData } = useSelector(state => state.GDProjectNameDDLData)

    useEffect(() => {
        handleProjectDDL()
    }, [GDProjectData])

    const handleProjectDDL = () => {
        if (GDProjectData && GDProjectData.table && GDProjectData.table.length > 0) {
            let list = GDProjectData.table.map((item, index) => ({
                value: item.projectID,
                label: item.projectName,
            }))

            setProjectName({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_ProjectID : 0,
                Label: Flag === 'Update' ? rowData.projectName : "Select...",
            })
        }
        else {
            setProjectName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Project Name {Flag && <AstricSign />}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width"
                // className="ddl_width"
                isSearchable
                maxMenuHeight={150}
                value={{ value: ProjectName.ID, label: ProjectName.Label }}
                onChange={(e) => {
                    e ?
                        setProjectName({ ...ProjectName, ID: e.value, Label: e.label })
                        :
                        setProjectName({ ...ProjectName, ID: 0, Label: "Select..." })

                }}
                options={ProjectName.DDL}
            />
        </div>
    )
}