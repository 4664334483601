
import { useEffect } from 'react'
import Select from 'react-select'
import { AssetNameDDLAPI, PreventiveWorkAssetNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { useTranslation } from 'react-i18next'

export const PreventiveWorkAssetNameDataDDL = (props) => {
    const { PreventiveWorkAssetNameDDL, setPreventiveWorkAssetNameDDL, AssetType, ZoneName, WorkType, ContractorName, WardIDData, rowData, Flag, Language, Flag1, DDLFlag } = props
    const { t } = useTranslation()
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_ZoneID: ZoneName.ID,
            M_WorkTypeID: WorkType.ID,
            M_ContractorID: ContractorName.ID,
            M_AssetTypeID: AssetType.ID,
            M_WardID: WardIDData && WardIDData.length > 0 ? WardIDData : '0',
            M_UserID: UserID,
            token: token,
            Flag: DDLFlag,
            Language: Language.Label,
        }
        { Language.Label != '' && dispatch(PreventiveWorkAssetNameDDLAPI({ data })) }
    }, [ZoneName.ID, WorkType.ID, ContractorName.ID, AssetType.ID, WardIDData.length, Language.Label])

    const { PreventiveWorkAssetNameDDLData } = useSelector(state => state.PreventiveWorkAssetNameDDLData)

    useEffect(() => {
        handlePreventiveWorkAssetNameDDL()
    }, [PreventiveWorkAssetNameDDLData])

    const handlePreventiveWorkAssetNameDDL = () => {
        if (PreventiveWorkAssetNameDDLData && PreventiveWorkAssetNameDDLData.table && PreventiveWorkAssetNameDDLData.table.length > 0) {
            let list = PreventiveWorkAssetNameDDLData.table.map((item, index) => ({
                value: item.m_AssetID,
                label: item.assetName,
            }))

            setPreventiveWorkAssetNameDDL({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_AssetID : 0,
                Label: Flag === 'Update' ? rowData.assetName : "Select...",
            })
        }
        else {
            setPreventiveWorkAssetNameDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width3"
                isSearchable
                maxMenuHeight={150}
                value={{ value: PreventiveWorkAssetNameDDL.ID, label: PreventiveWorkAssetNameDDL.Label }}
                onChange={(e) => {
                    e ?
                        setPreventiveWorkAssetNameDDL({ ...PreventiveWorkAssetNameDDL, ID: e.value, Label: e.label })
                        :
                        setPreventiveWorkAssetNameDDL({ ...PreventiveWorkAssetNameDDL, ID: 0, Label: "Select..." })

                }}
                options={PreventiveWorkAssetNameDDL.DDL}
            />
        </div>
    )
}