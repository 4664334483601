
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from 'moment'
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const HorticultureWorkAssignExportData = (props) => {
    const { ExcelData, name, Language, searchName,FromDate,ToDate } = props

    const [searchParams] = useSearchParams()
    let DashProjectTypeId = searchParams.get('DashProjectTypeId')

    const { t } = useTranslation()

    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-FarmerMasterExport"
                className="btn btn-primary float-end download-table-xls-button float-end btn btn-export btn-md mr-1 mb-1 ml-1"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-FarmerMasterExport" style={{ display: 'none', width: '40%' }}>

                <h5><span>&emsp;</span></h5>

                <tr>
                    <th colSpan={9} style={{ textAlign: 'center', fontSize: 20, border: "1px solid black" }}>Pimpri Chinchwad Municipal Corporation</th>
                </tr>
                <tr>
                    <th colSpan={9} style={{ textAlign: 'center', fontSize: 16, border: "1px solid black" }}>उद्यान व वृक्षसंवर्धन कामांचे सनियंत्रण संगणक प्रणाली वेब व मोबाईल आधारित</th>
                </tr>
                <tr>
                    <th colSpan={9} style={{ textAlign: 'center', fontSize: 16, border: "1px solid black" }}>Report Name: {t('Dashboard.Titles.Horticulture_Work_Assigned_by_Officials')} :- {searchName}</th>
                </tr>
                <tr>
                    <th colSpan={9} style={{ textAlign: 'center', fontSize: 16, border: "1px solid black" }}>From Date:- {FromDate ? FromDate : '-'} -- To Date:- {ToDate ? ToDate : '-'} </th>
                </tr>

                <thead>
                    <tr style={{ backgroundColor: '#254462', color: "#fff", border: '1px solid white', borderTop: "1px solid black" }}>
                        <th className="">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                        <th style={{ width: "5em" }}>{t('Dashboard.Work_Assigned_by_Officials.Assign_Date')}</th>
                        <th style={{ width: "10em" }}>{t('Dashboard.Pcmc_Assets.Asset_Name')}</th>
                        <th style={{ width: "6em" }}>{t('Dashboard.Work_Assigned_by_Officials.Area1')}</th>
                        <th style={{ width: "9em" }}>{t('Dashboard.Work_Assigned_by_Officials.Work_Name')}</th>
                        <th style={{ width: "5em" }}>{t('Dashboard.Work_Assigned_by_Officials.Officer_Name')}</th>
                        <th>{t('Dashboard.Work_Assigned_by_Officials.Assign_Contractor_Name')}</th>
                        <th>Work Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item) => {
                            return (
                                <tr style={{ width: "100%", border: "1px solid black" }}>
                                    <td style={{ textAlign: "center", verticalAlign: 'middle' }}>{item.rowNum ? item.rowNum : '-'}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{item.workAssignDate ? moment(item.workAssignDate).format('DD-MM-YYYY') : '-'}</td>
                                    {/* <td></td> */}
                                    <td style={{ verticalAlign: 'middle' }}>{item.assetName ? item.assetName : '-'}</td>
                                    {/* <td>{item.sectorName ? item.sectorName : '-'}</td> */}
                                    <td style={{ verticalAlign: 'middle' }}>{item.areaName ? item.areaName : '-'}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{item.workname ? item.workname : '-'}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{item.assingBy ? item.assingBy : '0'}</td>
                                    <td style={{ verticalAlign: 'middle' }}>{item.contractorName ? item.contractorName : '-'}</td>
                                    <td style={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}>{item.workDate ? moment(item.workDate).format('DD-MM-YYYY') : '-'}</td>
                                    <td style={{ padding: '10px', verticalAlign: 'middle' }}>
                                        <span className={item.statusName === "Pending" || item.statusName === "प्रलंबित" ? "Pendingstatus" : "Completedstatus"}>{item.statusName ? item.statusName : '-'}</span>
                                    </td>
                                </tr>
                            )
                        })
                            :
                            <td colSpan={15}>No data found</td>
                    }
                </tbody>

            </table>
        </div>
    )
}