import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function NewElectricalReopenWorkProgressCard({ AssignedHeading, PendingHeading, CompletedHeading, navLink, ReopenedCount, CompletedCount, imgPath, cardName, SewerageWorkNavigate, cardHeading, title1, title2, title3, NewElectricalReopenWorkProgressCardnavigate, imgCompPath, imgPendingPath, imgPathsecond, cardHeight, M_gD_WorkPeriodID, Flag, PendingCount }) {
    const navigate = useNavigate()

    const { t } = useTranslation()

    const handleNewElectricalReopenWorkProgressCard = () => {
        navigate(navLink)
    }
    return (
        <div style={{ cursor: 'pointer' }} className="mb-1 p-3 shadow newgreen_borders bg-white mb-md-2 daily-card-width">
            <div className="row">
                <p className="mb-3 text-center" style={{ fontSize: "15px", fontWeight: "bolder", color: "green", marginTop: "-0.8em" }}>{cardHeading}</p>
                <div className="col-4" style={{ borderRight: "2px solid #0e4174f7" }} onClick={() => NewElectricalReopenWorkProgressCardnavigate(title1, M_gD_WorkPeriodID, 'ReopenAssign', Flag, (t('Dashboard.Titles.Horticulture_work_reopen_progress')),'EAssigned')} >
                    <div class={cardHeight} className="d-flex flex-column justify-content-between">
                        <div className="text-center" >
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{ReopenedCount}</h4>
                                {/* <img style={{ height: '35px', width: '35px' }} src={imgPathsecond} alt="" /> */}
                            </div>
                        </div>
                        <div>
                            <p style={{ fontSize: "14px" }} className="text-black mb-0 pb-0"><b>{AssignedHeading}</b></p>
                        </div>
                    </div>
                </div>
                <div className="col-4" style={{ borderRight: "2px solid #0e4174f7" }} onClick={() => NewElectricalReopenWorkProgressCardnavigate(title2, M_gD_WorkPeriodID, 'ReopenAssingPending', Flag, (t('Dashboard.Titles.Civil_Work_Reopen_Progress_By_Contractor')),'EPending')} >
                    <div className="d-flex flex-column justify-content-between">
                        <div className="text-center" >
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{PendingCount}</h4>
                                {/* <img style={{ height: '30px', width: '30px' }} src={imgPath} alt="" /> */}
                            </div>

                        </div>
                        <div>
                            <p style={{ fontSize: "14px" }} className="text-black mb-0 pb-0"><b>{PendingHeading}</b></p>
                        </div>
                    </div>
                </div>
                <div className="col-4" onClick={() => NewElectricalReopenWorkProgressCardnavigate(title3, M_gD_WorkPeriodID, 'ReopenAssingCompleted', Flag, (t('Dashboard.Titles.Electrical_Work_Reopen_Progress_By_Contractor')),'ECompleted')} >
                    <div className="d-flex flex-column justify-content-between">
                        <div className="text-center" >
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{CompletedCount}</h4>
                                {/* <img style={{ height: '30px', width: '30px' }} src={imgPath} alt="" /> */}
                            </div>

                        </div>
                        <div>
                            <p style={{ fontSize: "14px" }} className="text-black mb-0 pb-0"><b>{CompletedHeading}</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
