
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import Header from '../../../../../Components/Header/Header'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { useAuthState } from '../../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { MonthDataDDL } from '../../../../../Components/CommonDDL/MonthDataDDL';
import { WardNameDataDDL } from '../../../../../Components/CommonDDL/WardNameDataDDL';
import { WardWiseWorkTableDataAPI } from '../../../../../Redux/RoadDashboardSlice/WardWiseWorkSlice';
import { Loading } from '../../../../../Helper/Loading';
import { GetMapViewDataAPI } from '../../../../../Redux/RoadDashboardSlice/MapViewSlice';
import MapView from '../../../../../Components/MapView/MapView';
import { FinancialYearDataDDL } from '../../../../../Components/CommonDDL/FinancialYearDataDDL';

export default function WardWiseWork() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)

    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [currEmployeeName, setcurrEmployeeName] = useState('')
    const [ShowMap, setShowMap] = useState(false)

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: HYear && HYear ? HYear : '0',
        Label: YLable && YLable ? YLable : "Select...",
    })

    const [Month, setMonth] = useState({
        DDL: [],
        ID: HMonth && HMonth ? HMonth : '0',
        Label: MLable && MLable ? MLable : "Select...",
    });

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })

    const [RoadWorkName, setRoadWorkName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)

        setFinancialYear({
            ...FinancialYear,
            ID: HYear,
            Label: YLable,
        })
        setMonth({
            ...Month,
            ID: HMonth,
            Label: MLable,
        })
        // setWardName({
        //     ...WardName,
        //     ID: HWard,
        //     Label: WLable,
        // })
    }

    const handlePost = () => {
        setIsPost(!IsPost)
    }
    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }

    useEffect(() => {
        const data = {
            M_FinancialYearID: FinancialYear.ID,
            M_MonthID: Month.ID,
            M_WardID:0,
            M_UsersID: UserID,
            token: token,
        }
        if (FinancialYear.ID && Month.ID && WardName.ID) {
            dispatch(WardWiseWorkTableDataAPI({ data }))
        }
    }, [IsPost, To, FinancialYear.ID, IsClear, Month.ID, WardName.ID])

    const { tableData, isLoading } = useSelector(state => state.WardWiseWorkTableData)

    const handleLocation = (m_WardID, m_rM_WorkID) => {
        console.log(m_WardID, m_rM_WorkID)
        const data = {
            M_FinancialYearID: YearValue,
            M_MonthID: Month.ID,
            M_WardID: m_WardID,
            M_RM_LocationID: 0,
            M_RM_WorkID: m_rM_WorkID,
            M_UsersID: UserID,
            Flag: 'ProgressCompleted',
            From: From,
            To: '99999',
            token: token,
            handleShowMap: handleShowMap
        }
        dispatch(GetMapViewDataAPI({ data }))
    }

    const { isMapLoading, MapViewData } = useSelector(state => state.GetMapViewData)

    const handleShowMap = () => {
        setShowMap(true)
    }
    const handleCloseMap = () => {
        setShowMap(false)
    }

    // console.log(tableData)

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    DashboardHeading="Road Maintainance"
                    setYearValue={setYearValue}
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                />
                <div className="wrapper">
                    <Sidebar />
                    <div className="content">
                        <div className="page-header ">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        {/* <div className="page-header-title">
                                            <h5 className="ps-2 data_mob">ESTIMATED AMOUNT */}

                                        {/* <!-- <span className="float-end">namita</span> --> */}
                                        {/* <span className="float-end fs-6">
                                                <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                        :
                                                        setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                }}
                                                options={SelectWard.DDL}
                                            />

                                            </span> */}
                                        {/* </h5>

                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- [ Main Content ] start --> */}
                        <div className='data_mob'>
                            <div className="row m-1 py-1 pb-4 master_border">
                                <h5 className="ps-3 py-1">Ward Wise Work
                                    <button type="button" className="btn btn-primary float-end mr-1">Export</button>
                                </h5>
                                <div className="row m-0 ">
                                    <div className="col-12 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                        <div className="col-12  ">

                                            <div className="row py-1 rounded2">

                                                <div className="col-12 col-md-5 col-lg-3 ">
                                                    <FinancialYearDataDDL
                                                        FinancialYear={FinancialYear}
                                                        setFinancialYear={setFinancialYear}
                                                        HYear={HYear}
                                                        YLable={YLable}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-5 col-lg-3 ">
                                                    <MonthDataDDL
                                                        Month={Month}
                                                        setMonth={setMonth}
                                                        MLable={MLable}
                                                        HMonth={HMonth}
                                                        Flag='Month'
                                                    />
                                                </div>
                                                {/* <div className="col-12 col-md-5 col-lg-3 ">
                                                    <WardNameDataDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        HWard={HWard}
                                                        WLable={WLable}
                                                    />
                                                </div> */}
                                                <div className="col-12 col-md-1 col-lg-1 mt-lg-4 mt-md-4">
                                                    <button type="button"
                                                        className="btn-clear btn btn-primary"
                                                        onClick={handleClear}>Clear</button>

                                                </div>
                                            </div>
                                            <div className="row mob_table table-responsive pt-1">
                                                <table id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead >
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            <th>Work Name</th>
                                                            {/* <th>Airoli</th>
                                                            <th>Belapur</th>
                                                            <th>Ghansoli</th>
                                                            <th>Koperkhairne</th>
                                                            <th>Turbhe</th>
                                                            <th>Nerul</th> */}
                                                            {/* <th>Total</th> */}


                                                            {
                                                                tableData && tableData.data_Detail[0] && tableData.data_Detail[0].dataList && tableData.data_Detail[0].dataList.length > 0 ? tableData.data_Detail[0].dataList.map((item) => (
                                                                    <>
                                                                        <th>{item.wardName ? item.wardName : '-'}</th>
                                                                    </>
                                                                ))

                                                                    :
                                                                    <></>
                                                            }
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.data_Detail && tableData.data_Detail && tableData.data_Detail.length > 0 ? tableData.data_Detail.map((item, i) => (
                                                                <>
                                                                    <tr>
                                                                        <td style={{ textAlign: "center" }}>{i + 1}</td>
                                                                        <td>{item.workName ? item.workName : '-'}</td>
                                                                        {

                                                                            item && item.dataList && item.dataList.length > 0 ? item.dataList.map((j) => (
                                                                                <>
                                                                                    <td className={(j.totalCount > 0) ? 'tablecountbluecolor' : 'tablecountgreycolor'}
                                                                                        onClick={() => {
                                                                                            if (j.totalCount > 0) {
                                                                                                handleLocation(j.m_WardID, item.m_rM_WorkID)
                                                                                            }

                                                                                        }

                                                                                        }
                                                                                        style={{ textAlign: 'center' }}
                                                                                    >

                                                                                        {j.totalCount ? j.totalCount : '0'}
                                                                                    </td>
                                                                                </>
                                                                            ))
                                                                                :
                                                                                ''
                                                                        }
                                                                        <td>{item.rM_WorkWiseTotalCount ? item.rM_WorkWiseTotalCount : '-'}</td>

                                                                    </tr>
                                                                </>
                                                            ))
                                                                :
                                                                <></>
                                                        }

                                                        {/* <tr>
                                                          
                                                            <td></td>
                                                            <td>Total</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    ShowMap &&
                    <MapView
                        LoactionData={MapViewData}
                        ShowMap={ShowMap}
                        handleCloseMap={handleCloseMap}
                        currEmployeeName={currEmployeeName}
                    />
                }
            </div>
        </>
    )
}