
import { useEffect } from 'react'
import Select from 'react-select'
import { DepartmentDDLAPI, EmployeeDDLAPI, LocationNameDDLAPI, SectorDDLAPI, WardNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'

export const LocationNameDataDDL = (props) => {
    const { LocationNameDDL, setLocationNameDDL, CropNameDashboardData, PopUpField,IsPost,WardName } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_WardID: '1',
            M_UserID: UserID,
            token: token,
            WardName:WardName
        }
        dispatch(LocationNameDDLAPI({ data }))
    }, [IsPost,WardName.ID])

    const { LocationNameData } = useSelector(state => state.LocationNameDDLData)

    useEffect(() => {
        handleLocationNameDDL()
    }, [LocationNameData])

    const handleLocationNameDDL = () => {
        if (LocationNameData && LocationNameData.table && LocationNameData.table.length > 0) {
            let list = LocationNameData.table.map((item, index) => ({
                value: item.m_rM_LocationID,
                label: item.locationName,
            }))

            setLocationNameDDL({
                DDL: list,
                ID: 0,
                Label: "Select...",
            })
        }
        else {
            setLocationNameDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofEmployee"><b>Location Name </b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width3"
                isSearchable
                maxMenuHeight={150}
                value={{ value: LocationNameDDL.ID, label: LocationNameDDL.Label }}
                onChange={(e) => {
                    e ?
                        setLocationNameDDL({ ...LocationNameDDL, ID: e.value, Label: e.label })
                        :
                        setLocationNameDDL({ ...LocationNameDDL, ID: 0, Label: "Select..." })

                }}
                options={LocationNameDDL.DDL}
            />
        </div>
    )
}