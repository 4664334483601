
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import moment from "moment";
import Header from '../../../../../Components/Header/Header'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { AssetTypeDataDDL } from '../../../../../Components/CommonDDL/AssetTypeDataDDL';
import { useAuthState } from '../../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import { CommWorkProgressTableDataAPI } from '../../../../../Redux/CommDashbaord/WorkProgressSlice/WorkProgressSlice';
import { Loading } from '../../../../../Helper/Loading';
import { Pegination } from '../../../../../Components/Pegination/Pegination';
import ViewURL from '../../../../../Components/ViewURL/ViewURL';
import { useTranslation } from 'react-i18next';
import CommonDashboardScreenWardDDL from '../../../../../Components/CommonDDL/CommonDashboardScreenWardDDL';
import { WardWiseAssetNameDataDDL } from '../../../../../Components/CommonDDL/WardWiseAssetDDL';
import { ContractorNameDDLNew } from '../../../../../Components/CommonDDL/ContractorNameDDLNew';

export default function ElectricalWorkProgressByContractorTable() {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let benchmark = searchParams.get("benchmark")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ZWard = searchParams.get("ZoneName")
    let ZLable = searchParams.get("ZLable")
    let FlagName = searchParams.get("Flag")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let m_gD_WorkPeriodID = searchParams.get("m_gD_WorkPeriodID")
    let ApiFlag = searchParams.get("ApiFlag")
    let DDLFlag = searchParams.get("DDLFlag")
    let ShowBy = searchParams.get("ShowBy")
    let ContractorID = searchParams.get("ContractorID")
    let Lang = searchParams.get("Lang")
    let ZoneValue = searchParams.get("ZoneName")
    let ElectricalContractorHeaderDDL = searchParams.get("ElectricalContractorHeaderDDL")

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: HYear && HYear ? HYear : '0',
        Label: YLable && YLable ? YLable : "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: HMonth && HMonth ? HMonth : '0',
        Label: MLable && MLable ? MLable : "Select...",

    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })

    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        ID: ZWard && ZWard ? ZWard : '0',
        Label: ZLable && ZLable ? ZLable : "Select...",
        // ID: 0,
        // Label: "Select...",

    })
    const [WorkAssignedBy, setWorkAssignedBy] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [AssetName, setAssetName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [WardWiseAssetName, setWardWiseAssetName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    const [ContractorName, setContractorName] = useState({
        DDL: [],
        ID: ContractorID ? ContractorID : '0',
        Label: "Select...",
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsClear, setIsClear] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [Heading, setHeading] = React.useState(false)

    const [FromDate, setFromDate] = useState('')
    // moment().startOf('month').format('YYYY-MM-DD')
    const [ToDate, setToDate] = useState('')
    // moment().format('YYYY-MM-DD')

    const YearMonth = () => {

        if (((moment().startOf('month').format('M')) == HMonth) && (moment().format('YYYY') == HYear)) {
            // console.log("ifdfgdfg")
            setFromDate(moment().startOf('month').format('YYYY-MM-DD'))
            setToDate(moment().format('YYYY-MM-DD'))
        } else {
            // console.log("else" )
            setFromDate(moment(HMonth, 'M').year(HYear, 'YYYY').startOf('month').format('YYYY-MM-DD'))
            setToDate(moment(HMonth, 'M').year(HYear, 'YYYY').endOf('month').format('YYYY-MM-DD'))

        }
    }

    useEffect(() => {
        if (FromDate != moment().startOf('month').format('YYYY-MM-DD')) {
            setToDate(moment(HMonth, 'MM').year(HYear, 'YYYY').endOf('month').format('YYYY-MM-DD'))
        }
    }, [FromDate])

    useEffect(() => {
        YearMonth()
    }, [])

    const handleFromToDate = (fromdate) => {
        setFromDate(fromdate)
        setToDate('')
    }

    useEffect(() => {
        const data = {
            FromDate: FromDate != 'Invalid date' ? FromDate : '',
            ToDate: ToDate != 'Invalid date' ? ToDate : '',
            M_WardID: WardName.ID,
            M_AssetID: WardWiseAssetName.ID,
            M_AssetTypeID: AssetType.ID,
            M_GD_WorkPeriodID: m_gD_WorkPeriodID ? m_gD_WorkPeriodID : '0',
            M_ContractorID: ContractorName.ID == 0 ? ElectricalContractorHeaderDDL : ContractorName.ID,
            M_UsersID: UserID,
            Flag: ApiFlag,
            ShowBy: ShowBy,
            From: From,
            To: To,
            token: token,
            Language: Language.Label,
            M_ZoneID: ZoneNameDDLID.ID,
        }
        { Language.Label != '' && ZoneNameDDLID.ID != '0' && WardName.ID && dispatch(CommWorkProgressTableDataAPI({ data })) }
    }, [FromDate, ToDate, To, WardName.ID, ZoneNameDDLID.ID, AssetType.ID, AssetName.ID, WardWiseAssetName.ID, ContractorName.ID, IsClear, Language.Label])

    const { tableData, isLoading } = useSelector(state => state.CommWorkProgressTableDataAPIData)

    const handleShowImage = (Imageurl, heading) => {
        setshowImagePopup(true)
        setImagePopUpData(Imageurl)
        setHeading(heading)
    }

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setFromDate('')
        YearMonth()
        setToDate('')
        setWardName({
            ...WardName,
            ID: HWard,
            Label: WLable,
        })
        setZoneNameDDLID({
            ...ZoneNameDDLID,
            ID: ZWard && ZWard ? ZWard : '0',
            Label: ZLable && ZLable ? ZLable : "Select...",
        })
        setAssetType({
            ...AssetType,
            ID: 0,
            Label: "Select..."
        })
        setWardWiseAssetName({
            ...WardWiseAssetName,
            ID: 0,
            Label: "Select..."
        })
        setContractorName({
            ...ContractorName,
            ID: 0,
            Label: "Select..."
        })
        setWorkAssignedBy({
            ...WorkAssignedBy,
            ID: 0,
            Label: "Select..."
        })
    }
    const handleSearch = () => {
        setCurrentPage(0)
        setTo(10)
        setFrom(1)
        setIsSearch(!isSearch)

    }

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [ZoneNameDDLID.ID, WardName.ID, AssetType.ID, WardWiseAssetName.ID, ContractorName.ID, ElectricalContractorHeaderDDL])

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    DashboardHeading="Garden Maintainance"
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar Language={Language}
                        ProjectTypeId={ProjectTypeId} />
                    <div id="" className='content1'>

                        {/* <!-- [ Main Content ] start --> */}
                        <div className='data_mob'>
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className='col-8'>
                                    <h5 className="ps-1 pt-1">{t('Dashboard.Titles.Electrical_Work_Progress_By_Contractor')} :- {benchmark} {searchName}</h5>
                                </div>
                                <div className='col-4'>
                                    {/* <button type="button" className="btn btn-primary float-end mr-1">Export</button> */}
                                </div>

                                <div className="row m-0 mt-1">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12 ">
                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"> <b>{t('Dashboard.Work_Assigned_by_Officials.From_Date')}:</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="FromDate"
                                                            value={FromDate}
                                                            max={moment().format('YYYY-MM-DD')}
                                                            // onChange={(e) => (setFromDate(e.target.value), setToDate(''))}
                                                            onChange={(e) => handleFromToDate(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>{t('Dashboard.Work_Assigned_by_Officials.To_Date')} :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="ToDate"
                                                            value={ToDate}
                                                            min={FromDate}
                                                            max={moment().format('YYYY-MM-DD')}
                                                            onChange={(e) => setToDate(e.target.value)}
                                                        />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-5">
                                                    <CommonDashboardScreenWardDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        WLable={WLable}
                                                        HWard={HWard}
                                                        ZLable={ZLable}
                                                        ZWard={ZWard}
                                                        Language={Language}
                                                        ZoneNameDDLID={ZoneNameDDLID}
                                                        setZoneNameDDLID={setZoneNameDDLID}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <AssetTypeDataDDL
                                                        AssetType={AssetType}
                                                        setAssetType={setAssetType}
                                                        Language={Language}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WardWiseAssetNameDataDDL
                                                        WardWiseAssetName={WardWiseAssetName}
                                                        setWardWiseAssetName={setWardWiseAssetName}
                                                        WardName={WardName}
                                                        AssetType={AssetType}
                                                        M_WorkTypeID='3'
                                                        M_ZoneID={ZoneNameDDLID}
                                                        M_RoleID={RoleID}
                                                        M_GD_WorkPeriodID={m_gD_WorkPeriodID ? m_gD_WorkPeriodID : '0'}
                                                        // M_AssetTypeID={1}
                                                        Language={Language}
                                                        Flag={
                                                            DDLFlag == 'Assigned' ? 'ElectricalWork_Assigned' :
                                                                DDLFlag == 'Pending' ? 'ElectricalWork_Pending' :
                                                                    'ElectricalWork_Completed'
                                                        }
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <ContractorNameDDLNew
                                                        ContractorName={ContractorName}
                                                        setContractorName={setContractorName}
                                                        M_WorkTypeID='3'
                                                        M_AssetID={WardWiseAssetName.ID}
                                                        AssetType={AssetType}
                                                        M_ZoneID={ZoneNameDDLID.ID}
                                                        Language={Language}
                                                        WorkPeriodID={m_gD_WorkPeriodID ? m_gD_WorkPeriodID : '0'}
                                                        Flag={
                                                            DDLFlag == 'Assigned' ? 'ElectricalWork_Assigned' :
                                                                DDLFlag == 'Pending' ? 'ElectricalWork_Pending' :
                                                                    'ElectricalWork_Completed'
                                                        }
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-3 col-md-4 mt-lg-4 d-md-flex" >
                                                    {/* <button type="button"
                                                        className="btn-clear btn btn-primary mx-2"
                                                        onClick={handleSearch}
                                                    >
                                                        Search</button> */}
                                                    <button type="button"
                                                        className="btn btn-outline-danger"
                                                        onClick={handleClear}
                                                    >
                                                        Clear</button>
                                                </div>
                                            </div>

                                            <div className="row mob_table table-responsive pt-1">
                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                                            <th style={{ width: "7em" }}>{t('Dashboard.Work_Assigned_by_Officials.Assign_Date')}</th>
                                                            <th style={{ width: "20em" }}>{t('Dashboard.Pcmc_Assets.Asset_Name')}</th>
                                                            <th style={{ width: "15em" }}>{t('Dashboard.Work_Assigned_by_Officials.Area1')}</th>
                                                            <th style={{ width: "22em" }}>{t('Dashboard.Work_Assigned_by_Officials.Work_Name')}</th>
                                                            <th style={{ width: "2em" }}>{t('Dashboard.Work_Assigned_by_Officials.Assign_Work_Photo')}</th>
                                                            {
                                                                searchName == (t('Dashboard.Titles.Completed')) ?
                                                                    <>
                                                                        <th style={{ width: "4em" }}>{t('Dashboard.Work_Assigned_by_Officials.Completed_Work_Date')}</th>
                                                                        <th style={{ width: "2em" }}>{t('Dashboard.Work_Assigned_by_Officials.Completed_Work_Photo')}</th>
                                                                    </>
                                                                    :
                                                                    ''
                                                            }
                                                            <th style={{ width: "10em" }}>{t('Dashboard.Work_Assigned_by_Officials.Officer_Name')}</th>
                                                            <th style={{ width: "10em" }}>{t('Dashboard.Work_Assigned_by_Officials.Assign_Contractor_Name')}</th>
                                                            {
                                                                searchName == (t('Dashboard.Titles.Completed')) ?
                                                                    <>
                                                                        <th>{t('Dashboard.Work_Assigned_by_Officials.BenchMark')}</th>
                                                                    </>
                                                                    :
                                                                    ''
                                                            }

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.length > 0 ? tableData.map((i) => {
                                                                return (
                                                                    <tr>
                                                                        <td style={{ textAlign: "center" }}>{i?.rowNum}</td>
                                                                        <td style={{ whiteSpace: "nowrap" }}>{i.workAssignDate ? moment(i.workAssignDate).format('DD-MM-YYYY') : ''}</td>
                                                                        <td>{i?.assetName}</td>
                                                                        <td>{i?.areaName}</td>
                                                                        <td>{i?.workname}</td>
                                                                        <td className="text-center pt-3">
                                                                            {
                                                                                (i.workPhoto == null) ?
                                                                                    <span>-</span>
                                                                                    :
                                                                                    <>
                                                                                        <i className="fa-solid fa-eye" title='View'
                                                                                            style={{ cursor: 'pointer', fontSize: '15px' }}
                                                                                            onClick={() => handleShowImage(i.workPhoto, 'Assign Work Photo')}
                                                                                        ></i>
                                                                                    </>
                                                                            }
                                                                        </td>
                                                                        {
                                                                            searchName == (t('Dashboard.Titles.Completed')) ?
                                                                                <>
                                                                                    <td style={{ whiteSpace: "nowrap" }}>{i?.workDate ? moment(i.workAssignDate).format('DD-MM-YYYY') : ''}</td>
                                                                                    <td className="text-center">
                                                                                        {
                                                                                            (i.afterWorkPhoto == null) ?
                                                                                                <span>-</span>
                                                                                                :
                                                                                                <>
                                                                                                    <i className="fa-solid fa-eye" title='View'
                                                                                                        style={{ cursor: 'pointer', fontSize: '15px' }}
                                                                                                        onClick={() => handleShowImage(i.afterWorkPhoto, 'Completed Work Photo')}
                                                                                                    ></i>
                                                                                                </>
                                                                                        }
                                                                                    </td>
                                                                                </>
                                                                                :
                                                                                ''
                                                                        }
                                                                        <td>{i?.assingBy}</td>
                                                                        <td>{i?.contractorName}</td>
                                                                        {
                                                                            searchName == (t('Dashboard.Titles.Completed')) ?
                                                                                <>
                                                                                    <td>{i?.benchMark}</td>
                                                                                </>
                                                                                :
                                                                                ''
                                                                        }
                                                                    </tr>
                                                                )
                                                            })
                                                                :
                                                                <td colSpan={15}>No Found data ...</td>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!--  --> */}


                    </div>
                </div>
                {
                    showImagePopup ?
                        <ViewURL
                            Heading={Heading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''
                }
            </div>
        </>

    )
}