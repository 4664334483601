import Header from "../../../Components/Header/Header"
import Sidebar from "../../../Components/Sidebar/Sidebar"
import GardenDailyWorkCardManage from "./GarderDashboardComponent/GardenDailyWork/GardenDailyWorkManage"
import GardenMaintainanceCardManage from "./GarderDashboardComponent/GardenMaintainanceCard/GardenMaintainanceCardManage"
import GardenMasterCardManage from "./GarderDashboardComponent/GardenMasterCard/GardenMasterCardManage"
import GardenReopenCardManage from "./GarderDashboardComponent/GardenReopenCard/GardenReopenCardManage"
import GardenWorkAssignManage from "./GarderDashboardComponent/GardenWorkAssign/GardenWorkAssignManage"
import { Pie, Bar, Doughnut } from 'react-chartjs-2';
import { Dcoptions, Pieoptions, WorReopenAssignProgress3, WorkAssign, WorkAssignNew, WorkAssignProgress, WorkAssignProgress2, WorkAssignProgress3, WorkReopenAssignProgress, WorkReopenAssignProgress2 } from "../../../Helper/Data"
import React, { useEffect, useState } from 'react'
import { useAuthState } from "../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { GardenDashMainCountDataAPI } from "../../../Redux/MainCountSlice/GardenMainCount"
import { WardNameDataDDL } from "../../../Components/CommonDDL/WardNameDataDDL"
import { Loading } from "../../../Helper/Loading"
import WorkProgress from "./GarderDashboardComponent/GardenWorkAssign/WorkProgress"
import { useSearchParams } from "react-router-dom"
import { BaseUrl } from "../../../Helper/BaseUrl"
import GardenMaintainanceComp from "./GarderDashboardComponent/GardenMaintainanceCard/GardenMaintainanceComp"
import CommonDashboardWardNameDDL from "./CommonDashboardWardNameDDL"
import NewGardenWorkProgressByContractorCardManage from "./NewDashBoardComponents/HorticultureWorkProgress/NewGardenWorkProgressByContractorCardManage"
import NewCivilWorkProgressByContractorCardManage from "./NewDashBoardComponents/CivilWorkProgress/NewCivilWorkProgressByContractorCardManage"
import NewElectricalWorkProgressByContractorCardManage from "./NewDashBoardComponents/ElectricalWorkProgress/NewElectricalWorkProgressByContractorCardManage"
import NewGardenReopenWorkProgressCardManage from "./NewDashBoardComponents/HorticultureWorkReopen/NewGardenReopenWorkProgressByContractorCardManage"
import NewCivilReopenWorkProgressCardManage from "./NewDashBoardComponents/CivilWorkReopen/NewCivilReopenWorkProgressCardManage"
import NewElectricalReopenWorkProgressCardManage from "./NewDashBoardComponents/ElectricalWorkReopen/NewElectricalReopenWorkProgressCardManage"
import HorticultureWorKAssignedCardManage from "./NewDashBoardComponents/HorticultureWorkCard/HorticultureWorkAssignedByOfficialsCardManage"
import CivilWorkAssignByOfficialsCardManage from "./NewDashBoardComponents/CivilWorkAssignedByOfficials/CivilWorkAssignedByOfficialsCardManage"
import ElectricalWorkAssignedByOficialsCardManage from "./NewDashBoardComponents/ElectricalWorkAssignedByOfficials/ElectricalWorkAssignedByOfficialsCardsManage"
import { CommContractorDDLAPI, WorkAssignByOfficialsCommCountDDLAPI } from "../../../Redux/DDLSlice"
import { Targetdata, Targetdata2, Targetdata3, WorkProgressTargetdata, WorkProgressTargetdata2, WorkProgressTargetdata3, WorkReopenProgressTargetdata, WorkReopenProgressTargetdata2, WorkReopenProgressTargetdata3 } from "../../../Helper/Graph"
import { useTranslation } from "react-i18next"
import { ZoneNameDDL } from "../../../Components/CommonDDL/DashboardDDL/ZoneNameDDL"
import SportsWork from "./NewDashBoardComponents/SportsWork/SportsWork"
import SportsWorkProgressByContractorCardManage from "./NewDashBoardComponents/SportWorkProgressByContractor/SportWorkProgressByContractorCardManage"
import SportsWorkReopenWorkProgressCardManage from "./NewDashBoardComponents/SportsWorkReopen/SportsWorkReopenByContractorCardManage"
import SecurityWorkCards from "./NewDashBoardComponents/SecurityWork/SecurityWorkCardManage"
import { EmployeeAndContractorWisePendingWorkCard } from "./GarderDashboardComponent/EmployeeAndContractorWiseWorkPending/EmployeeAndContractorWiseWorkPendingCard"

export const OfficialDashboard = () => {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let DDLApiFlag = searchParams.get("DDLApiFlag")

    const Receiveddata = {
        labels: ["Completed", "Reopened"],
        datasets: [
            {
                label: '# of Votes',
                data: [80, 20],
                backgroundColor: [
                    "#ffc33d",
                    "#f48242"
                ],
                borderColor: [
                    // '#28c8c1',
                    // '#172b4d',
                    // '#993366',
                    // '#593e89',
                    // '#94408e',
                    // '#c74382',
                    // '#fa5d00',
                    // '#529ad2',
                    // '#ffc137',
                ],
                borderWidth: 1,
            },
        ],
    };

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Countdata, setCountdata] = useState({})
    const [NewCountdata, setNewCountdata] = useState({})

    // const [FinancialYearID, setFinancialYear] = useState('')
    const [HeaderFinancialYear, setHeaderFinancialYear] = useState('')
    const [HeaderMonthID, setHeaderMonthID] = useState('')
    const [MonthID, setMonthID] = useState('')
    const [YearValue, setYearValue] = useState(null)
    const [YearLabel, setYearLabel] = useState(null)
    const [MonthValue, setMonthValue] = useState(null)
    const [MonthLabel, setMonthLabel] = useState(null)
    const [WardValue, setWardValue] = useState(null)
    const [WardLabel, setWardLabel] = useState(null)
    const [ZoneValue, setZoneValue] = useState(null)
    const [ZoneLabel, setZoneLabel] = useState(null)

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: "",
    })

    const handleGetFinancialYearID = (FinancialYear, Month) => {
        setHeaderMonthID(Month)
        setHeaderFinancialYear(FinancialYear)
    }

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_WardID: WardName.ID,
            M_ZoneID: ZoneValue,
            M_MonthID: MonthValue,
            M_UserID: UserID,
            Flag: "WEB",
            token: token,
            handleGetCount: handleGetCount
        }
        if (YearValue && ZoneValue) {
            dispatch(GardenDashMainCountDataAPI({ data }))

        }
        console.log(data)
    }, [YearValue, MonthValue, WardName.ID, ZoneValue])

    const { GardenDashCountData, isLoading } = useSelector(state => state.GardenDashMainCountData)


    const handleGetCount = (data) => {
        // console.log("data", data)
        let tempData = {}
        data.forEach((item) => {
            tempData[item.paramName] = item.totalCount
        })
        setCountdata(tempData)
    }
    const handleDashboardGetCount = (data) => {
        // console.log("data", data)
        let tempData = {}
        data.forEach((item) => {
            tempData[item.assignTotalCount] = item.totalCount
        })
        setNewCountdata(tempData)
    }

    // const [HorticultureDDlData, setHorticultureDDlData] = useState()
    // const [CivilDDlData, setCivilDDlData] = useState()
    // const [ElectricalDDlData, setElectricalDDlData] = useState()
    // const [SportDDlData, setSportDDlData] = useState()

    // useEffect(() => {
    //     HeaderDDL('Horticulture')
    //     HeaderDDL('Civil')
    //     HeaderDDL('Electrical')
    //     HeaderDDL('Sport')
    // }, [YearValue, MonthValue, WardName.ID, Language.Label])

    // const HeaderDDL = (Flag) => {
    //     const data = {
    //         M_FinancialYearID: YearValue,
    //         M_MonthID: MonthValue,
    //         M_WardID: WardName.ID,
    //         M_UserID: UserID,
    //         Flag: Flag,
    //         token: token,
    //         Language: Language.Label,
    //         setHorticultureDDlData: setHorticultureDDlData,
    //         setCivilDDlData: setCivilDDlData,
    //         setElectricalDDlData: setElectricalDDlData,
    //         setSportDDlData: setSportDDlData,
    //     }
    //     if (YearValue && MonthValue && WardName.ID) {
    //         dispatch(CommContractorDDLAPI({ data }))
    //     }
    // }
    // const { DDLData, isDDLLoading } = useSelector(state => state.CommContractorDDLData)

    // console.log("dsuhidf",Language)

    // useEffect(() => {
    //     const data = {
    //         // M_FinancialYearID: 2023,
    //         // M_MonthID: 1,
    //         // M_WardID: WardValue,
    //         M_UserID: UserID,
    //         Flag: 'ALL',
    //         token: token,
    //     }
    //     // if (YearValue && MonthValue && WardValue) {
    //         dispatch(WorkAssignByOfficialsCommCountDDLAPI({ data }))
    //     // }
    // }, [])

    // const { WorkAssignDDLData, isWorkAssignDDLLoading } = useSelector(state => state.WorkAssignByOfficialsCommCountDDL)

    // console.log('gyuguy',DDLData)
    return (
        <>
            {isLoading && <Loading />}
            {/* <div className="main-content side_shrink position-relative border-radius-lg "></div> */}
            <div>
                <Header
                    // DashboardHeading="Garden Maintenance"
                    setYearValue={setYearValue}
                    setMonthValue={setMonthValue}
                    FinancialYear={FinancialYear}
                    setFinancialYear={setFinancialYear}
                    Month={Month}
                    setMonth={setMonth}
                    setYearLabel={setYearLabel}
                    setMonthLabel={setMonthLabel}
                    setWardValue={setWardValue}
                    setWardLabel={setWardLabel}
                    DashboardName={DashboardName}
                    HideYearMonthDDL='Dashboard'
                    Language={Language}
                    setLanguage={setLanguage}

                />
                {/* <Sidebar active="dashboard" /> */}
                <div className="wrapper bg-change">
                    <Sidebar
                        ProjectTypeId={ProjectTypeId}
                        Language={Language}
                    />
                    <div className="content1 content">


                        <CommonDashboardWardNameDDL
                            Countdata={Countdata}
                            setWardValue={setWardValue}
                            setWardLabel={setWardLabel}
                            setZoneValue={setZoneValue}
                            setZoneLabel={setZoneLabel}
                            Language={Language}
                            ZoneNameDDLID={ZoneNameDDLID}
                            setZoneNameDDLID={setZoneNameDDLID}
                            WardName={WardName}
                            setWardName={setWardName}
                        />





                        <div className="py-2 pb-4 master_border_master_card ml-lg-n2 me-lg-2">
                            <GardenMasterCardManage
                                Countdata={Countdata}
                                YearValue={YearValue}
                                YearLabel={YearLabel}
                                MonthValue={MonthValue}
                                MonthLabel={MonthLabel}
                                WardValue={WardValue}
                                WardLabel={WardLabel}
                                ZoneValue={ZoneValue}
                                ZoneLabel={ZoneLabel}
                                ProjectTypeId={ProjectTypeId}
                                DashboardName={DashboardName}
                                Language={Language}
                            />
                        </div>
                        {
                            RoleID == 2 || RoleID == 3 || RoleID == 15 ?
                                <div className="row mt-4 ms-lg-0 me-3 pb-0 master_border">
                                    <GardenDailyWorkCardManage
                                        Countdata={Countdata}
                                        YearValue={YearValue}
                                        YearLabel={YearLabel}
                                        MonthValue={MonthValue}
                                        MonthLabel={MonthLabel}
                                        WardValue={WardValue}
                                        WardLabel={WardLabel}
                                        ZoneValue={ZoneValue}
                                        ZoneLabel={ZoneLabel}
                                        ProjectTypeId={ProjectTypeId}
                                        DashboardName={DashboardName}
                                        Language={Language}
                                    />
                                    <div className="col-12 col-lg-5 ">
                                        <div className="row ">
                                            <div className="col-12 ">
                                                <div className=" px-3 pb-0 upload_chart bg-white ">
                                                    <div className="row d-flex justify-content-center align-items-center ">
                                                        <Pie
                                                            height={100}
                                                            // width={100}
                                                            options={Pieoptions}
                                                            data={Receiveddata}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <></>
                        }

                        {/* <div className="row">
                            <div className="col-12 col-lg-6  pt-2 ps-2 ps-lg-4" style={{ color: "red" }}>
                                <div className="fw-bold" style={{ fontSize: "22px" }}>{t('Dashboard.Titles.Work_Assigned_by_Officials')}</div>
                            </div>
                            <div className="col-12 col-lg-6 pt-2 ps-2 text-center" style={{ color: "red" }}>
                                <div className="fw-bold" style={{ fontSize: "16px" }}>कामाचे दिवस </div>
                            </div>
                        </div> */}
                        <div className="pt-2 ps-2">
                            <span className="fw-bold" style={{ fontSize: "20px" }}>{t('Dashboard.Titles.Work_Assigned_by_Officials')}</span>
                        </div>

                        <div className="border mx-lg-2 my-1 rounded bg-white border-0 sec_sec sec_sec_375 p-2">

                            <div className="row py-2 pb-2 work_assign_bg d-flex align-items-center mx-auto ml-lg-1">
                                <HorticultureWorKAssignedCardManage
                                    HeaderFinancialYear={HeaderFinancialYear}
                                    Countdata={Countdata}
                                    HeaderMonthID={HeaderMonthID}
                                    NewCountdata={NewCountdata}
                                    handleGetFinancialYearID={handleGetFinancialYearID}
                                    YearValue={YearValue}
                                    YearLabel={YearLabel}
                                    MonthValue={MonthValue}
                                    MonthLabel={MonthLabel}
                                    WardValue={WardValue}
                                    WardLabel={WardLabel}
                                    ZoneValue={ZoneValue}
                                    ZoneLabel={ZoneLabel}
                                    ProjectTypeId={ProjectTypeId}
                                    DashboardName={DashboardName}
                                    Language={Language}
                                    setLanguage={setLanguage}
                                    WardName={WardName}
                                    setWardName={setWardName}
                                // Flag={Flag}

                                // DDLData={DDLData}
                                // WorkAssignDDLData={WorkAssignDDLData}
                                />
                            </div>

                            <div className="row py-2 pb-2 work_assign_bg d-flex align-items-center mx-auto ml-lg-1 mt-4">
                                <CivilWorkAssignByOfficialsCardManage
                                    HeaderFinancialYear={HeaderFinancialYear}
                                    HeaderMonthID={HeaderMonthID}
                                    Countdata={Countdata}
                                    handleGetFinancialYearID={handleGetFinancialYearID}
                                    YearValue={YearValue}
                                    YearLabel={YearLabel}
                                    MonthValue={MonthValue}
                                    MonthLabel={MonthLabel}
                                    WardValue={WardValue}
                                    WardLabel={WardLabel}
                                    ZoneValue={ZoneValue}
                                    ZoneLabel={ZoneLabel}
                                    ProjectTypeId={ProjectTypeId}
                                    DashboardName={DashboardName}
                                    Language={Language}
                                    WardName={WardName}
                                    setWardName={setWardName}
                                />

                            </div>

                            <div className="row py-2 pb-2 work_assign_bg d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                <ElectricalWorkAssignedByOficialsCardManage
                                    HeaderFinancialYear={HeaderFinancialYear}
                                    HeaderMonthID={HeaderMonthID}
                                    Countdata={Countdata}
                                    handleGetFinancialYearID={handleGetFinancialYearID}
                                    YearValue={YearValue}
                                    YearLabel={YearLabel}
                                    MonthValue={MonthValue}
                                    MonthLabel={MonthLabel}
                                    WardValue={WardValue}
                                    WardLabel={WardLabel}
                                    ZoneValue={ZoneValue}
                                    ZoneLabel={ZoneLabel}
                                    ProjectTypeId={ProjectTypeId}
                                    DashboardName={DashboardName}
                                    Language={Language}
                                    WardName={WardName}
                                    setWardName={setWardName}
                                />


                                {/* </div> */}
                                {/* </div> */}
                            </div>
                            <div className="row py-2 pb-2 work_assign_bg d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                <SportsWork
                                    HeaderFinancialYear={HeaderFinancialYear}
                                    HeaderMonthID={HeaderMonthID}
                                    Countdata={Countdata}
                                    handleGetFinancialYearID={handleGetFinancialYearID}
                                    YearValue={YearValue}
                                    YearLabel={YearLabel}
                                    MonthValue={MonthValue}
                                    MonthLabel={MonthLabel}
                                    WardValue={WardValue}
                                    WardLabel={WardLabel}
                                    ZoneValue={ZoneValue}
                                    ZoneLabel={ZoneLabel}
                                    ProjectTypeId={ProjectTypeId}
                                    DashboardName={DashboardName}
                                    Language={Language}
                                    WardName={WardName}
                                    setWardName={setWardName}
                                />


                                {/* </div> */}
                                {/* </div> */}
                            </div>


                            <div className="row py-2 pb-2 work_assign_bg d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                <SecurityWorkCards
                                    HeaderFinancialYear={HeaderFinancialYear}
                                    HeaderMonthID={HeaderMonthID}
                                    Countdata={Countdata}
                                    handleGetFinancialYearID={handleGetFinancialYearID}
                                    YearValue={YearValue}
                                    YearLabel={YearLabel}
                                    MonthValue={MonthValue}
                                    MonthLabel={MonthLabel}
                                    WardValue={WardValue}
                                    WardLabel={WardLabel}
                                    ZoneValue={ZoneValue}
                                    ZoneLabel={ZoneLabel}
                                    ProjectTypeId={ProjectTypeId}
                                    DashboardName={DashboardName}
                                    Language={Language}
                                    WardName={WardName}
                                    setWardName={setWardName}
                                />


                                {/* </div> */}
                                {/* </div> */}
                            </div>


                        </div>

                        {/* Work Progress Card Start */}

                        {/* Work Progress By Contractor */}


                        <div className="pt-2 ps-2">
                            <span className="fw-bold" style={{ fontSize: "20px" }}>{t('Dashboard.Titles.Work_Progress_By_Contractor')}</span>
                        </div>

                        <div className="border mx-lg-2 my-1 rounded border-0 p-2 sec_sec bg-white">
                            <div className="row m-1 py-2 pb-2 master_border_red d-flex align-items-center mx-auto ml-lg-1" >
                                <NewGardenWorkProgressByContractorCardManage
                                    HeaderFinancialYear={HeaderFinancialYear}
                                    HeaderMonthID={HeaderMonthID}
                                    Countdata={Countdata}
                                    handleGetFinancialYearID={handleGetFinancialYearID}
                                    YearValue={YearValue}
                                    YearLabel={YearLabel}
                                    MonthValue={MonthValue}
                                    MonthLabel={MonthLabel}
                                    WardValue={WardValue}
                                    WardLabel={WardLabel}
                                    ZoneValue={ZoneValue}
                                    ZoneLabel={ZoneLabel}
                                    ProjectTypeId={ProjectTypeId}
                                    DashboardName={DashboardName}
                                    // DDLData={HorticultureDDlData}
                                    Language={Language}
                                    // HorticultureDDlData={HorticultureDDlData}
                                    WardName={WardName}
                                    setWardName={setWardName}
                                    ZoneNameDDLID={ZoneNameDDLID}
                                    setZoneNameDDLID={setZoneNameDDLID}

                                />

                            </div>

                            <div className="row m-1 py-2 pb-2 master_border_red d-flex align-items-center mx-auto ml-lg-1 mt-4">
                                <NewCivilWorkProgressByContractorCardManage
                                    HeaderFinancialYear={HeaderFinancialYear}
                                    HeaderMonthID={HeaderMonthID}
                                    Countdata={Countdata}
                                    handleGetFinancialYearID={handleGetFinancialYearID}
                                    YearValue={YearValue}
                                    YearLabel={YearLabel}
                                    MonthValue={MonthValue}
                                    MonthLabel={MonthLabel}
                                    WardValue={WardValue}
                                    WardLabel={WardLabel}
                                    ZoneValue={ZoneValue}
                                    ZoneLabel={ZoneLabel}
                                    ProjectTypeId={ProjectTypeId}
                                    DashboardName={DashboardName}
                                    // DDLData={CivilDDlData}
                                    Language={Language}
                                    // CivilDDlData={CivilDDlData}
                                    WardName={WardName}
                                    setWardName={setWardName}
                                    ZoneNameDDLID={ZoneNameDDLID}
                                    setZoneNameDDLID={setZoneNameDDLID}
                                />


                            </div>

                            <div className="row m-1 py-2 pb-2 master_border_red d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                <NewElectricalWorkProgressByContractorCardManage
                                    HeaderFinancialYear={HeaderFinancialYear}
                                    HeaderMonthID={HeaderMonthID}
                                    Countdata={Countdata}
                                    handleGetFinancialYearID={handleGetFinancialYearID}
                                    YearValue={YearValue}
                                    YearLabel={YearLabel}
                                    MonthValue={MonthValue}
                                    MonthLabel={MonthLabel}
                                    WardValue={WardValue}
                                    WardLabel={WardLabel}
                                    ZoneValue={ZoneValue}
                                    ZoneLabel={ZoneLabel}
                                    ProjectTypeId={ProjectTypeId}
                                    DashboardName={DashboardName}
                                    // DDLData={ElectricalDDlData}
                                    Language={Language}
                                    // ElectricalDDlData={ElectricalDDlData}
                                    WardName={WardName}
                                    setWardName={setWardName}
                                    ZoneNameDDLID={ZoneNameDDLID}
                                    setZoneNameDDLID={setZoneNameDDLID}
                                />


                            </div>

                            <div className="row m-1 py-2 pb-2 master_border_red d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                <SportsWorkProgressByContractorCardManage
                                    HeaderFinancialYear={HeaderFinancialYear}
                                    HeaderMonthID={HeaderMonthID}
                                    Countdata={Countdata}
                                    handleGetFinancialYearID={handleGetFinancialYearID}
                                    YearValue={YearValue}
                                    YearLabel={YearLabel}
                                    MonthValue={MonthValue}
                                    MonthLabel={MonthLabel}
                                    WardValue={WardValue}
                                    WardLabel={WardLabel}
                                    ZoneValue={ZoneValue}
                                    ZoneLabel={ZoneLabel}
                                    ProjectTypeId={ProjectTypeId}
                                    DashboardName={DashboardName}
                                    // DDLData={SportDDlData}
                                    Language={Language}
                                    // SportDDlData={SportDDlData}
                                    WardName={WardName}
                                    setWardName={setWardName}
                                    ZoneNameDDLID={ZoneNameDDLID}
                                    setZoneNameDDLID={setZoneNameDDLID}
                                />


                            </div>

                        </div>


                        {/* Work Progress Card End */}


                        {/* Reopen Work Progress Card Start */}

                        <div className="pt-2 ps-2">
                            <span className="fw-bold" style={{ fontSize: "22px" }}>{t('Dashboard.Titles.Work_Reopen_Progress_By_Contractor')}</span>
                        </div>

                        <div className="border mx-lg-2 my-1 rounded bg-white border-0 p-2 pb-2 sec_sec">

                            <div className="row m-1 py-2 pb-2 master_border_grey d-flex align-items-center mx-auto ml-lg-1">
                                <NewGardenReopenWorkProgressCardManage
                                    HeaderFinancialYear={HeaderFinancialYear}
                                    HeaderMonthID={HeaderMonthID}
                                    // Countdata={Countdata}
                                    handleGetFinancialYearID={handleGetFinancialYearID}
                                    YearValue={YearValue}
                                    YearLabel={YearLabel}
                                    MonthValue={MonthValue}
                                    MonthLabel={MonthLabel}
                                    WardValue={WardValue}
                                    WardLabel={WardLabel}
                                    ZoneValue={ZoneValue}
                                    ZoneLabel={ZoneLabel}
                                    ProjectTypeId={ProjectTypeId}
                                    DashboardName={DashboardName}
                                    // DDLData={HorticultureDDlData}?
                                    Language={Language}
                                    WardName={WardName}
                                    setWardName={setWardName}
                                    ZoneNameDDLID={ZoneNameDDLID}
                                    setZoneNameDDLID={setZoneNameDDLID}
                                />


                            </div>

                            <div className="row m-1 py-2 pb-2 master_border_grey d-flex align-items-center mx-auto ml-lg-1 mt-4">
                                <NewCivilReopenWorkProgressCardManage
                                    HeaderFinancialYear={HeaderFinancialYear}
                                    HeaderMonthID={HeaderMonthID}
                                    Countdata={Countdata}
                                    handleGetFinancialYearID={handleGetFinancialYearID}
                                    YearValue={YearValue}
                                    YearLabel={YearLabel}
                                    MonthValue={MonthValue}
                                    MonthLabel={MonthLabel}
                                    WardValue={WardValue}
                                    WardLabel={WardLabel}
                                    ZoneValue={ZoneValue}
                                    ZoneLabel={ZoneLabel}
                                    ProjectTypeId={ProjectTypeId}
                                    DashboardName={DashboardName}
                                    // DDLData={CivilDDlData}
                                    Language={Language}
                                    WardName={WardName}
                                    setWardName={setWardName}
                                    ZoneNameDDLID={ZoneNameDDLID}
                                    setZoneNameDDLID={setZoneNameDDLID}
                                />

                            </div>

                            <div className="row m-1 py-2 pb-2 master_border_grey d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                <NewElectricalReopenWorkProgressCardManage
                                    HeaderFinancialYear={HeaderFinancialYear}
                                    HeaderMonthID={HeaderMonthID}
                                    Countdata={Countdata}
                                    handleGetFinancialYearID={handleGetFinancialYearID}
                                    YearValue={YearValue}
                                    YearLabel={YearLabel}
                                    MonthValue={MonthValue}
                                    MonthLabel={MonthLabel}
                                    WardValue={WardValue}
                                    WardLabel={WardLabel}
                                    ZoneValue={ZoneValue}
                                    ZoneLabel={ZoneLabel}
                                    ProjectTypeId={ProjectTypeId}
                                    DashboardName={DashboardName}
                                    // DDLData={ElectricalDDlData}
                                    Language={Language}
                                    WardName={WardName}
                                    setWardName={setWardName}
                                    ZoneNameDDLID={ZoneNameDDLID}
                                    setZoneNameDDLID={setZoneNameDDLID}
                                />


                                {/* </div> */}
                                {/* </div> */}
                            </div>

                            <div className="row m-1 py-2 pb-2 master_border_grey d-flex align-items-center mx-auto ml-lg-1 mt-4">

                                <SportsWorkReopenWorkProgressCardManage
                                    HeaderFinancialYear={HeaderFinancialYear}
                                    HeaderMonthID={HeaderMonthID}
                                    Countdata={Countdata}
                                    handleGetFinancialYearID={handleGetFinancialYearID}
                                    YearValue={YearValue}
                                    YearLabel={YearLabel}
                                    MonthValue={MonthValue}
                                    MonthLabel={MonthLabel}
                                    WardValue={WardValue}
                                    WardLabel={WardLabel}
                                    ZoneValue={ZoneValue}
                                    ZoneLabel={ZoneLabel}
                                    ProjectTypeId={ProjectTypeId}
                                    DashboardName={DashboardName}
                                    Language={Language}
                                    WardName={WardName}
                                    setWardName={setWardName}
                                    ZoneNameDDLID={ZoneNameDDLID}
                                    setZoneNameDDLID={setZoneNameDDLID}
                                />
                            </div>
                            
                            {
                                RoleID == 2 || RoleID == 3 || RoleID == 15 ?

                                    <div className="row mt-4 m-1 master_border last_row">
                                        <EmployeeAndContractorWisePendingWorkCard />
                                    </div>
                                    : ''
                            }


                        </div>



                        {/* REopen Work Progress Card End */}

                    </div>
                    {/* <!-- [ Main Content ] end --> */}
                </div>
            </div>
        </>


    )
}