
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

// Get Table Data
export const WorkNameTableDataAPI = createAsyncThunk("WorkNameTableData", async ({ data }) => {
    const {
        M_RM_WorkID,
        WorkName,
        M_UserID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_WEB_M_RM_Work_Select?M_RM_WorkID=${M_RM_WorkID ? M_RM_WorkID : '0'}&WorkName=${WorkName ? WorkName : ''}&M_UserID=${M_UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WorkNameTableDataSlice = createSlice({
    name: "WorkNameTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkNameTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkNameTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WorkNameTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})


export const WorkNameTableDataReducer = WorkNameTableDataSlice.reducer

// InsertUpdate Table Data

export const WorkNameMasterPostDataAPI = createAsyncThunk("WorkNameMasterPostData", async ({ data }) => {
    const {
        M_RM_WorkID,
        WorkName,
        IsOtherWork,
        PenaltyPeriodHours,
        BenchMark,
        Penalty_Description,
        Penalty_Values,
        Heading,
        DisplayOrder,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_RM_WorkID", M_RM_WorkID);
    formdata.append("WorkName", WorkName);
    formdata.append("IsOtherWork", IsOtherWork);
    formdata.append("PenaltyPeriodHours", PenaltyPeriodHours);
    formdata.append("BenchMark", BenchMark);
    formdata.append("Penalty_Description", Penalty_Description);
    formdata.append("Penalty_Values", Penalty_Values);
    formdata.append("Heading", Heading);
    formdata.append("DisplayOrder", DisplayOrder);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_WEB_M_RM_Work_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const WorkNameMasterPostDataSlice = createSlice({
    name: "WorkNameMasterPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkNameMasterPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkNameMasterPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WorkNameMasterPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const WorkNameMasterPostDataReducer = WorkNameMasterPostDataSlice.reducer

// Delete Table Data

export const WorkNameMasterDeleteAPI = createAsyncThunk("WorkNameMasterDelete", async ({ data }) => {
    const {
        M_RM_WorkID,
        M_UserID,
        handleIsPost,
        token,
        handleDeleteCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_RM_WorkID",M_RM_WorkID);
    formdata.append("M_UserID", M_UserID);
    
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/Master/WEB_M_RM_Work_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
              
            } else {
                toastErrorr(result.message)
               
            }
            return result

        })
})

const WorkNameMasterDeleteSlice = createSlice({
    name: "WorkNameMasterDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkNameMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(WorkNameMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;

        });
        builder.addCase(WorkNameMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const WorkNameMasterDeleteReducer = WorkNameMasterDeleteSlice.reducer


