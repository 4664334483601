import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { ProjectPostDataAPI } from '../../../../Redux/SidebarMasterSlice/ProjectNameSlice';
import { WardNameDataDDL } from '../../../../Components/CommonDDL/WardNameDataDDL';
import { ProjectTypeDataDDL } from '../../../../Components/CommonDDL/ProjectTypeDataDDL';
import { RegExOnlyText } from '../../../../Helper/regEx/RegExOnlyText';
import { AstricSign } from '../../../../Helper/AstricSign';
import { WorkTypeDDL } from '../../../../Components/CommonDDL/WorkTypeDDL';


export default function ProjectNamePopup({ handleOnCloseClick, open, handleIsPost, rowData, Flag, ProjectTypeFlag }) {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()


    // const [rowData, setRowData] = useState(item)

    // const [WardName, setWardName] = useState({
    //     DDL: [],
    //     ID: Flag === 'Update' ? rowData.m_WardID : 1,
    //     Label: Flag === 'Update' ? rowData.wardName : "Select...",
    // })

    const [ProjectType, setProjectType] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_ProjectTypeID : 0,
        Label: Flag === 'Update' ? rowData.projectType : "Select...",
    })

    const [ProjectName, setProjectName] = useState(Flag === 'Update' ? rowData.projectName : '')

    // console.log(Flag, EmployeeField)
    // const handleClearField = () => {

    //     if (Flag != 'Update') {
    //         setProjectName('')
    //     }
    // }

    const [IsOpen, setIsOpen] = useState(false)

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_WorkTypeID : 0,
        Label: Flag === 'Update' ? rowData.workTypeName : "Select...",
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [Language])

    useEffect(() => {
        setProjectName(Flag === 'Update' ? rowData.projectName : '')
    }, [rowData, IsOpen])


    const [IsValidText, setIsValidText] = useState(false)
    const handleCheckText = (e) => {
        setProjectName(e.target.value)
        const IsValid = RegExOnlyText(e.target.value)
        setIsValidText(IsValid)
        return IsValid
    }

    const handlePost = () => {
        const data = {

            M_ProjectID: Flag === 'Update' ? rowData.m_ProjectID : '0',
            M_WorkTypeID: WorkType.ID,
            M_ProjectType: ProjectTypeFlag,
            ProjectName: ProjectName,
            // M_WardID: WardName.ID,
            // M_ProjectTypeID: ProjectType.ID,
            M_ProjectTypeID: 0,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            // handleClearField: handleClearField,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(ProjectPostDataAPI({ data }))

    }

    return (
        <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
            <div className="area_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Project Name</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">
                                <div className="col-12 col-md-6 col-lg-6">
                                    <WorkTypeDDL
                                        WorkType={WorkType}
                                        setWorkType={setWorkType}
                                        Language={Language}
                                        Flag={Flag}
                                        rowData={rowData}
                                        Flag1='ProjectType'
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-lg-6 ">
                                    <label for=""><b>Project Name <AstricSign /> :</b></label>
                                    <input type="text" className="form-control"
                                        id="exampleInputEmail1" name='EmployeeName'
                                        aria-describedby="emailHelp"
                                        value={ProjectName}
                                        // onChange={(e) => handleCheckText(e)}
                                        onChange={(e) => setProjectName(e.target.value)}
                                    />
                                    {/* {
                                        IsValidText && <text style={{ color: 'red' }}>Please enter Alphabets characters only</text>
                                    } */}
                                </div>
                            </div>

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                        disabled={
                                            ProjectName == ''
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </Popup >
    )

}