import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { SectorPostDataAPI } from '../../../../Redux/SidebarMasterSlice/SectorMasterSlice';
import { WardNameDataDDL } from '../../../../Components/CommonDDL/WardNameDataDDL';
import { RegExOnlyText } from '../../../../Helper/regEx/RegExOnlyText';
import { AstricSign } from '../../../../Helper/AstricSign';


export default function SectorMasterPopup({ handleOnCloseClick, open, handleIsPost, rowData, Flag }) {
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    // const [SectorName, setSectorName] = useState(Flag === 'Update' ? rowData.sectorName : '')

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_WardID : 0,
        Label: Flag === 'Update' ? rowData.wardName : "Select...",
    })

    // console.log(Flag === 'Update' ? rowData.sectorName : '')

    // useEffect(() => {
    //     setSectorName(Flag === 'Update' ? rowData.sectorName : '')
    // }, [rowData])

    const handleClearField = () => {
        if (Flag != 'Update') {
            // setSectorName('')
        }
    }

    const [IsValidText, setIsValidText] = useState(false)
    const handleCheckText = (e) => {
        // setSectorName(e.target.value)
        const IsValid = RegExOnlyText(e.target.value)
        setIsValidText(IsValid)
        return IsValid
    }

    const handlePost = () => {
        const data = {
            // M_GD_SectorID: Flag === 'Update' ? rowData.m_SectorID : '0',
            M_WardID: WardName.ID,
            // SectorName: SectorName,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleClearField: handleClearField,
            handleOnCloseClick:handleOnCloseClick
        }
        dispatch(SectorPostDataAPI({ data }))
    }

    return (

        <Popup open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}>
            <div className="area_pop modal-dialog  modal-xl">
                <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Sector Master</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">
                                <div className="col-12 col-lg-6">
                                    <WardNameDataDDL
                                        WardName={WardName}
                                        setWardName={setWardName}
                                        Flag={Flag}
                                        rowData={rowData}
                                        IsDisabled={true}
                                    />
                                </div>

                                {/* <div className="col-12 col-lg-6">
                                    <label for=""><b>Sector Name <AstricSign /> :</b></label>
                                    <input type="text" className="form-control"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        value={SectorName}
                                        onChange={(e) => setSectorName(e.target.value)}
                                    />
                                    {
                                        IsValidText && <text style={{ color: 'red' }}>Please enter Alphabets characters only</text>
                                    }
                                </div> */}
                            </div>
s                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                        disabled={
                                            WardName.ID == 0
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </Popup>
    )

}