

import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from 'moment'
import { useState } from "react"
import { useTranslation } from "react-i18next"

export const WorkExportData = (props) => {
    const { ExcelData, name, WardName, FromDate, ToDate } = props

    const totalCount = ExcelData && ExcelData.table && ExcelData.table.reduce((total, item) => {
        return total + +(item.totalPenalty)
    }, 0)

    const { t } = useTranslation()


    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-ExportPreventiveWorkReport"
                className="download-table-xls-button float-end btn btn-primary float-end mr-1 mb-1 ml-1"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black', }}
            />


            <table id="table-to-ExportPreventiveWorkReport" style={{ display: 'none', width: '100%' }}>

                <h5><span>&emsp;</span></h5>


                <h4 style={{ textAlign: 'center', fontSize: 16 }}>PCMC-Garden</h4>
                <h4 style={{ textAlign: 'center', fontSize: 16 }}>Report Name : {t('AdminDashboard.Admin_Sidebar_Name.Work')}</h4>

                <tr><td></td></tr>

                <thead className="thead-dark" >
                    <tr style={{ backgroundColor: '#495057', color: "#fff", }}>

                        <th className="sr_no_col">Sr No.</th>
                        <th>Work Type</th>
                        <th>Work Sub Type</th>
                        <th>Work Name</th>
                        <th>Benchmark</th>
                        <th>Benchmark in <br /> Days</th>
                        <th>Penalty Amount <br /> (Rs.)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item, i) => (
                            <tr style={{ textAlign: "center" }}>
                                <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                <td>{item.workTypeName ? item.workTypeName : '-'}</td>
                                <td>{item.workSubTypeName ? item.workSubTypeName : '-'}</td>
                                <td>{item.workname ? item.workname : '-'}</td>
                                <td style={{ textAlign: "center" }}>{item.benchMark ? item.benchMark : '-'}</td>
                                <td style={{ textAlign: "center" }}>{item.benchMarkinDays ? item.benchMarkinDays : '-'}</td>
                                <td style={{ textAlign: "right" }}>{item.penaltyAmount ? (item.penaltyAmount).toFixed(2) : '-'}</td>
                            </tr>
                        )) : <tr>No data</tr>
                    }
                </tbody>
            </table>
        </div>
    )
}