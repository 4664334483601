
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";

// Get Table Data
export const HorticultureWorkAssignedTableDataAPI = createAsyncThunk("HorticultureWorkAssignedTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_AssetTypeID,
        WorkAssignByM_UserID,
        M_AssetID,
        M_GD_WorkPeriodID,
        M_WorkTypeID,
        ShowBy,
        M_ContractorID,
        M_UsersID,
        token,
        From,
        To,
        // Flag,
        M_RoleID,
        M_ZoneID,
        Language,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_UploadAssignWorkOfficerWisePeriod_Select?FromDate=${FromDate}&ToDate=${ToDate}&M_WardID=${M_WardID ? M_WardID : '0'}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&WorkAssignByM_UserID=${WorkAssignByM_UserID ? WorkAssignByM_UserID : '0'}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_GD_WorkPeriodID=${M_GD_WorkPeriodID ? M_GD_WorkPeriodID : '0'}&M_UsersID=${M_UsersID}&M_RoleID=${M_RoleID}&ShowBy=${ShowBy}&From=${From}&To=${To}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const HorticultureWorkAssignedTableDataSlice = createSlice({
    name: "HorticultureWorkAssignedTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(HorticultureWorkAssignedTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(HorticultureWorkAssignedTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(HorticultureWorkAssignedTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const HorticultureWorkAssignedTableDataReducer = HorticultureWorkAssignedTableDataSlice.reducer


export const HorticultureWorkAssignedTableExportDataAPI = createAsyncThunk("HorticultureWorkAssignedTableExportData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_AssetTypeID,
        WorkAssignByM_UserID,
        M_AssetID,
        M_GD_WorkPeriodID,
        M_WorkTypeID,
        ShowBy,
        M_ContractorID,
        M_UsersID,
        token,
        From,
        To,
        // Flag,
        M_RoleID,
        M_ZoneID,
        Language,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_UploadAssignWorkOfficerWisePeriod_Select?FromDate=${FromDate}&ToDate=${ToDate}&M_WardID=${M_WardID ? M_WardID : '0'}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&WorkAssignByM_UserID=${WorkAssignByM_UserID ? WorkAssignByM_UserID : '0'}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_GD_WorkPeriodID=${M_GD_WorkPeriodID ? M_GD_WorkPeriodID : '0'}&M_UsersID=${M_UsersID}&M_RoleID=${M_RoleID}&ShowBy=${ShowBy}&From=${From}&To=${To}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const HorticultureWorkAssignedTableExportDataSlice = createSlice({
    name: "HorticultureWorkAssignedTableExportData",
    initialState: {
        isExportLoading: false,
        ExportTableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(HorticultureWorkAssignedTableExportDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(HorticultureWorkAssignedTableExportDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExportTableData = action.payload;
        });
        builder.addCase(HorticultureWorkAssignedTableExportDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExportTableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const HorticultureWorkAssignedTableExportDataReducer = HorticultureWorkAssignedTableExportDataSlice.reducer

