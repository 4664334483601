import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import GardenMasterCard from './GardenMasterCard'
import { useTranslation } from 'react-i18next'


export default function GardenMasterCardManage(props) {

    const { Countdata, Language, HeaderFinancialYear, MonthValue, YearValue, YearLabel, MonthLabel, WardValue, WardLabel, ProjectTypeId, DashboardName, ZoneValue, ZoneLabel } = props

    const { t } = useTranslation()

    const [searchParams] = useSearchParams()
    let Lang = searchParams.get("Lang")


    const MasterNavigate = (name, screenFlag) => {
        if (name === t('Dashboard.Pcmc_Assets.Garden_Master')) {
            // navigate(`/gardenmaster?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
            window.open(`/gardenmaster?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === t('Dashboard.Pcmc_Assets.Road_Divider')) {
            // navigate(`/roaddivider?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
            window.open(`/roaddivider?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === t('Dashboard.Pcmc_Assets.Open_Space_master')) {
            // navigate(`/openspace?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
            window.open(`/openspace?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === t('Dashboard.Pcmc_Assets.Tree_Belt’s_master')) {
            // navigate(`/treebelt?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
            window.open(`/treebelt?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === t('Dashboard.Pcmc_Assets.chowks_master')) {
            // navigate(`/chowks?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
            window.open(`/chowks?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === t('Dashboard.Pcmc_Assets.Play_Equipments')) {
            // navigate(`/playequipment?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
            window.open(`/playequipment?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }
    const navigate = useNavigate()

    return (
        <div>
            <div className="row">
                {/* <h5 className="ml-4 py-2">{t('Dashboard.Pcmc_Assets.Pcmc_Assets')}</h5> */}
                <h5 className="ml-4 py-2">{t('Dashboard.Titles.Master_Data')}</h5>
            </div>
            <div className='row mast_row gar_new_mas'>

                {/* <div className='col-12 col-lg-2 col-md-6 text-center mast_card_width'> */}
                <div class="mb-0 col-sm-6 col-lg-2 mastercard ml-lg-2">
                    <GardenMasterCard
                        title={t('Dashboard.Pcmc_Assets.Garden_Master')}
                        cardName='py-2 green cursor '
                        border='green_border'
                        count={Countdata.GardenTotalCount ? Countdata.GardenTotalCount : '0'}
                        navLink='/gardenmaster'
                        imgPath="./assets/img/pine-tree.png"
                        color='red'
                        MasterNavigate={MasterNavigate}

                    />
                </div>
                <div class="mb-0 col-sm-6 col-lg-2 mastercard">
                    <GardenMasterCard
                        title={t('Dashboard.Pcmc_Assets.Road_Divider')}
                        cardName='py-2 blue cursor'
                        border='blue_border'
                        count={Countdata.RoadDividerTotalCount ? Countdata.RoadDividerTotalCount : '0'}
                        navLink='/roaddivider'
                        imgPath="./assets/img/diversion.png"
                        color='blue'
                        MasterNavigate={MasterNavigate}
                    />
                </div>

                <div class="mb-0 col-sm-6 col-lg-2 mastercard">
                    <GardenMasterCard
                        title={t('Dashboard.Pcmc_Assets.Open_Space_master')}
                        cardName='py-2 green cursor'
                        border='green_border'
                        count={Countdata.OpenSpaceTotalCount ? Countdata.OpenSpaceTotalCount : '0'}
                        navLink='/openspace'
                        imgPath="./assets/img/tree.png"
                        color='red'
                        MasterNavigate={MasterNavigate}
                    />
                </div>

                <div class="mb-0 col-sm-6 col-lg-2 mastercard">
                    <GardenMasterCard
                        title={t('Dashboard.Pcmc_Assets.Tree_Belt’s_master')}
                        cardName='py-2 blue cursor'
                        border='blue_border'
                        count={Countdata.TreeBeltTotalCount ? Countdata.TreeBeltTotalCount : '0'}
                        navLink='/treebelt'
                        imgPath="./assets/img/chainsaw.png"
                        color='blue'
                        MasterNavigate={MasterNavigate}
                    />
                </div>
                <div class="mb-0 col-sm-6 col-lg-2 mastercard">
                    <GardenMasterCard
                        title={t('Dashboard.Pcmc_Assets.chowks_master')}
                        cardName='py-2 green cursor'
                        border='green_border'
                        count={Countdata.ChowksTotalCount ? Countdata.ChowksTotalCount : '0'}
                        navLink='/chowks'
                        imgPath="./assets/img/intersection.png"
                        color='red'
                        MasterNavigate={MasterNavigate}
                    />
                </div>

                <div class="mb-0 col-sm-6 col-lg-2 mastercard">
                    <GardenMasterCard
                        title={t('Dashboard.Pcmc_Assets.Play_Equipments')}
                        cardName='py-2 blue cursor'
                        border='blue_border'
                        count={Countdata.PlayEquipmentsTotalCount ? Countdata.PlayEquipmentsTotalCount : '0'}
                        navLink='/playequipments'
                        imgPath="./assets/img/chainsaw.png"
                        color='blue'
                        MasterNavigate={MasterNavigate}
                    />
                </div>


            </div>


        </div>



    )

}

