
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { AstricSign } from '../../Helper/AstricSign'
import { WaterSupplyUserDataDDLAPI } from '../../Redux/DDLSlice'

export const WaterSupplyUserDDL = (props) => {
    const { User, setUser, rowData, Flag, M_ProjectTypeID,M_RoleID,setgridData } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { M_UserID:UserID, token, M_ProjectTypeID,M_RoleID }
        dispatch(WaterSupplyUserDataDDLAPI({ data }))
    }, [M_ProjectTypeID,M_RoleID])

    const { WaterSupplyUserData } = useSelector(state => state.WaterSupplyUserDataDDL)


    useEffect(() => {
        handleRoleDDL()
    }, [WaterSupplyUserData])

    const handleRoleDDL = () => {
        // console.log(DeptDDLDataa)
        if (WaterSupplyUserData && WaterSupplyUserData.table && WaterSupplyUserData.table.length > 0) {
            let list = WaterSupplyUserData.table.map((item, index) => ({
                value: item.m_UserID,
                label: item.userDetails,
            }))

            setUser({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_RoleID : 0,
                Label: Flag === 'Update' ? rowData.m_RoleName : "Select...",
            })
        }
        else {
            setUser({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofWorkType"><b>User { Flag && <AstricSign />} :</b></label>
            <Select
                isClearable
               
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: User.ID, label: User.Label }}
                onChange={(e) => {
                    e ?
                        setUser({ ...User, ID: e.value, Label: e.label })
                        :
                        setUser({ ...User, ID: 0, Label: "Select..." })
                        setgridData([])

                }}
                options={User.DDL}
            />

        </div>
    )
}