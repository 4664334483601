
import React from 'react'
import WorkApprovalCard from './WorkApprovalCard'
import { useNavigate } from 'react-router-dom'

export default function WorkApprovalCardManage(props) {
    const { Countdata,HeaderMonthID,HeaderFinancialYear , MonthValue, YearValue , YearLabel,MonthLabel,WardValue,WardLabel,ProjectTypeId,DashboardName} = props

    const workApprovalNavigate = (name, screenFlag) => {

        if (name === "Pending") {
            navigate(`/roadworkapproval?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Approved") {
            navigate(`/roadworkapproval?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Rejected") {
            navigate(`/roadworkapproval?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }
    const navigate = useNavigate()
    return (

        <div className='row ml-0 mr-0 pb-2'>
            <div className="col-lg-4 col-md-12" >
                <WorkApprovalCard
                    heading='Pending'
                    count={Countdata.WorkApproval_PendingTotalCount}
                    imgPath='./assets/img/pending.png'
                    cardName='wwork_img '
                    navLink='/roadworkapproval'
                    countText='green_color'
                    workApprovalNavigate={workApprovalNavigate}
                />
            </div>

            <div className="col-lg-4 col-md-12">
                <WorkApprovalCard
                    heading='Approved'
                    count={Countdata.WorkApproval_ApprovedTotalCount}
                    imgPath='./assets/img/approve.png'
                    cardName='wwork_img'
                    navLink='/roadworkapproval'
                    countText='green_color'
                    workApprovalNavigate={workApprovalNavigate}

                />
            </div>

            <div className="col-lg-4 col-md-12" >
                <WorkApprovalCard
                    heading='Rejected'
                    count={Countdata.WorkApproval_RejectedTotalCount}
                    imgPath='./assets/img/decline.png'
                    cardName='wwork_img'
                    navLink='/roadworkapproval'
                    countText='green_color'
                    workApprovalNavigate={workApprovalNavigate}

                />
            </div>
        </div>

    )
}






