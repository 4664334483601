import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const ProjectTableDataAPI = createAsyncThunk("ProjectTableData", async ({ data }) => {
    const {
        M_ProjectID,
        ProjectName,
        M_WorkTypeID,
        M_UsersID,
        token,
        From,
        To,
        // M_WardID,
        M_ProjectType
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_M_Project_Select?M_ProjectType=${M_ProjectType}&M_ProjectID=${M_ProjectID ? M_ProjectID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&ProjectName=${ProjectName}&M_UserID=${M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ProjectTableDataSlice = createSlice({
    name: "ProjectTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ProjectTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectTableDataReducer = ProjectTableDataSlice.reducer


export const ProjectPostDataAPI = createAsyncThunk("ProjectPostData", async ({ data }) => {
    const {
        M_ProjectID,
        M_WorkTypeID,
        ProjectName,
        // M_WardID,
        M_ProjectType,
        SectorName,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleClear,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();

    formdata.append("M_ProjectID", M_ProjectID);
    formdata.append("M_WorkTypeID", M_WorkTypeID);
    formdata.append("M_ProjectType", M_ProjectType);
    // formdata.append("M_WardID", M_WardID);
    formdata.append("ProjectName", ProjectName);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_Project_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClear()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const ProjectPostDataSlice = createSlice({
    name: "ProjectPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ProjectPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectPostDataReducer = ProjectPostDataSlice.reducer

// -----------------Delete API ---------------

export const ProjectDeleteAPI = createAsyncThunk("ProjectDelete", async ({ data }) => {
    const {
        M_ProjectID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    var formdata = new FormData();
    formdata.append("M_ProjectID", M_ProjectID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_Project_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const ProjectDeleteSlice = createSlice({
    name: "ProjectDelete",
    initialState: {
        isDeleteLoading: false,
        ProjectData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ProjectDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.ProjectData = action.payload;

        });
        builder.addCase(ProjectDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.ProjectData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ProjectDeleteReducer = ProjectDeleteSlice.reducer