
import { useEffect } from 'react'
import Select from 'react-select'
import { MonthDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'

export const MonthDataDDL = (props) => {
    const { Month, setMonth, HMonth, MLable,Flag } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const d = new Date();
    let month = d.getMonth();

    useEffect(() => {
        const data = { UserID, token }
        dispatch(MonthDDLAPI({ data }))
    }, [])

    const { MonthData } = useSelector(state => state.MonthDDLData)

    useEffect(() => {
        handleMonthDDL()
    }, [MonthData])

    const handleMonthDDL = () => {
        if (MonthData && MonthData.table && MonthData.table.length > 0) {
            let list = MonthData.table.map((item, index) => ({
                value: item.id,
                label: item.month_Name,
            }))

            setMonth({
                DDL: list,
                // ID: list[month].value,
                // Label: list[month].label,
                ID: HMonth,
                Label: MLable,
            })
        }
        else {
            setMonth({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }


    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Month</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                styles={{
                    option: (provided, state) => ({
                        ...provided,
                        color: 'black'
                    })
                }}
                isSearchable
                maxMenuHeight={150}
                value={{ value: Month.ID, label: Month.Label }}
                onChange={(e) => {
                    e ?
                        setMonth({ ...Month, ID: e.value, Label: e.label })
                        :
                        setMonth({ ...Month, ID: 0, Label: "Select..." })

                }}
                options={Month.DDL}
            />
        </div>
    )
}