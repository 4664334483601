import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { DesignationDDL } from '../../../../Components/CommonDDL/DesignationDDL';
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { EmployeePostDataAPI } from '../../../../Redux/SidebarMasterSlice/EmployeeSlice';
import { AstricSign } from '../../../../Helper/AstricSign';


export default function EmployeePopup(props) {

    const { handleOnCloseClick, open, Flag, handleIsPost, item, EditIcon, apiFlag, rowData, } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [IsValidNumbers, setIsValidNumbers] = useState(false)
    const [IsValidEmail, setIsValidEmail] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [Designation, setDesignation] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_DesignationID : 0,
        Label: Flag === 'Update' ? rowData.designationName : "Select...",
    })

    const [EmployeeField, setEmployeeField] = useState({
        EmployeeName: Flag === 'Update' ? rowData.employeeName : '',
        MobileNumber: Flag === 'Update' ? rowData.mobileNumber : '',
        EmailID: Flag === 'Update' ? rowData.email_ID : ''
    })

    const handleEmployeeField = (e) => {
        setEmployeeField({ ...EmployeeField, [e.target.name]: e.target.value })
    }

    const handleClearField = () => {

        if (Flag != 'Update') {
            setEmployeeField({
                EmployeeName: '',
                MobileNumber: '',
                EmailID: ''
            })
        }
    }

    const handleCheckMobileNumber = (e) => {
        handleEmployeeField(e)
        let regex = new RegExp(/^[0-9]*$/)
        if (regex.test(e.target.value) || e.target.value === "") {
            setIsValidNumbers(false)
        } else {
            setIsValidNumbers(true)
        }
    }

    const handleCheckEmail = (e) => {
        handleEmployeeField(e)
        let regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
        if (regex.test(e.target.value) || e.target.value === "") {
            setIsValidEmail(false)
        } else {
            setIsValidEmail(true)
        }
    }

    const handlePost = () => {
        const data = {
            M_DepartmentID: 0,
            M_EmployeeID: Flag === 'Update' ? rowData.m_EmployeeID : '0',
            M_DesignationID: Designation.ID,
            EmployeeName: EmployeeField.EmployeeName,
            MobileNumber: EmployeeField.MobileNumber,
            Email_ID: EmployeeField.EmailID,
            JoiningDate: '',
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleClearField: handleClearField,
            handleOnCloseClick:handleOnCloseClick
        }
        dispatch(EmployeePostDataAPI({ data }))

    }

    return (
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="area_pop modal-dialog modal-dialog-scrollable modal-xl  " >
                <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Employee</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">

                                <div className="col-12 col-md-6 col-lg-6">
                                    <DesignationDDL
                                        Designation={Designation}
                                        setDesignation={setDesignation}
                                        rowData={rowData}
                                        Flag={Flag}
                                        IsPost={IsPost}
                                    />
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Employee Name <AstricSign /> :</b></label>
                                        <input type="text" className="form-control" id="exampleInputEmail1" name='EmployeeName'
                                            aria-describedby="emailHelp"
                                            value={EmployeeField.EmployeeName}
                                            onChange={(e) => handleEmployeeField(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Mobile Number :</b></label>
                                        <input type="text" className="form-control" id="exampleInputEmail1" name='MobileNumber'
                                            aria-describedby="emailHelp"
                                            value={EmployeeField.MobileNumber}
                                            onChange={handleCheckMobileNumber}
                                            maxLength={10}
                                        />
                                        {
                                            IsValidNumbers && <text style={{ color: 'red' }}>Please Enter valid Mobile Number</text>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Email Id :</b></label>
                                        <input type="text" className="form-control" id="exampleInputEmail1" name='EmailID'
                                            aria-describedby="emailHelp"
                                            value={EmployeeField.EmailID}
                                            onChange={handleCheckEmail}
                                        />
                                        {
                                            IsValidEmail && <text style={{ color: 'red' }}>Please Enter valid Email</text>
                                        }
                                    </div>
                                </div>

                            </div>

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"                                    
                                        onClick={() => handlePost() }
                                        disabled={
                                            Designation.ID == 0 || EmployeeField.EmployeeName == ''
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                           
                        </div>

                    </div>
                </div>
            </div>
        </Popup>
    )

}