
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment";
import { useAuthState } from '../../../../Helper/Context';
import { Loading } from '../../../../Helper/Loading';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import Header from '../../../../Components/Header/Header';
import { AssetTypeDataDDL } from '../../../../Components/CommonDDL/AssetTypeDataDDL';
import ViewURL from '../../../../Components/ViewURL/ViewURL';
import { WorkTypeDDL } from '../../../../Components/CommonDDL/WorkTypeDDL';
import { useTranslation } from 'react-i18next';
import { ZoneDataDDLS } from '../../../../Components/CommonDDL/ZoneDataDDLS';
import { GardenCompletedWorkPDFReportTableDataAPI, GardenCompletedWorkReportTableDataAPI } from '../../../../Redux/GardenDashboardSlice/GardenReportSlice/CompletedWorkReportSlice';
import { toastErrorr } from '../../../../Helper/ToastMessage';
import { WardNameMultiCheckBox } from '../../../../Components/CommonDDL/WardNameMultiCheckBox';
import { ContractorNameReportDDL } from '../../../../Components/CommonDDL/ContractorNameReportDDL';

export default function CompletedWorkReport() {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let FlagName = searchParams.get("Flag")
    let Lang = searchParams.get("Lang")
    // let ProjectTypeFlag = searchParams.get("ProjectTypeFlag")

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: HYear && HYear ? HYear : '0',
        Label: YLable && YLable ? YLable : "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: HMonth && HMonth ? HMonth : '0',
        Label: MLable && MLable ? MLable : "Select...",

    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })

    const [ZoneName, setZoneName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ContractorName, setContractorName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [checkboxes, setCheckboxes] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [Heading, setHeading] = React.useState(false)
    const [FromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))
    const [PDFUrl, setPDFUrl] = useState('')

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    const [WardIDData, setWardIDData] = useState([]);
    const [selected, setSelected] = useState([]);


    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })

    const handleSearch = () => {
        setIsSearch(!IsSearch)
    }

    const handleClear = () => {
        setWardIDData([])
        setSelected([])

        setCurrentPage(0)
        setIsClear(!IsClear)
        setFromDate(moment().startOf('month').format('YYYY-MM-DD'))
        setToDate(moment().format('YYYY-MM-DD'))
        setWardName({
            ...WardName,
            ID: HWard,
            Label: WLable,
        })
        setAssetType({
            ...AssetType,
            ID: 0,
            Label: 'Select...',
        })
        setZoneName({
            ...ZoneName,
            ID: 0,
            Label: 'Select...',
        })
        setWorkType({
            ...WorkType,
            ID: 0,
            Label: 'Select...',
        })
        setContractorName({
            ...ContractorName,
            ID: 0,
            Label: 'Select...',
        })
    }

    const handlePost = () => {
        setIsPost(!IsPost)
    }

    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }

    useEffect(() => {
        const data = {
            // FinancialYearID: YearValue,
            FromDate: FromDate,
            ToDate: ToDate,
            M_ZoneID: ZoneName.ID,
            M_ContractorID: ContractorName.ID,
            M_WorkTypeID: WorkType.ID,
            M_AssetTypeID: AssetType.ID,
            M_WardData: WardIDData && WardIDData.length > 0 ? WardIDData : '0',
            Flag: 'Grid',
            M_UserID: UserID,
            token: token,
            // Language: Language.Label,
        }
        // if (WardName.ID) {
        dispatch(GardenCompletedWorkReportTableDataAPI({ data }))
        // }

    }, [IsPost, IsSearch, IsClear])
    // }, [IsPost, To, IsClear, ZoneName.ID, WardName.ID, WorkType.ID, AssetType.ID, FromDate, ToDate, Language.Label])

    const { tableData, isLoading } = useSelector(state => state.GardenCompletedWorkReportTableData)

    const handleGetPDF = () => {

        const data = {
            FromDate: FromDate,
            ToDate: ToDate,
            M_ZoneID: ZoneName.ID,
            M_ContractorID: ContractorName.ID,
            M_WorkTypeID: WorkType.ID,
            M_AssetTypeID: AssetType.ID,
            M_WardData: WardIDData && WardIDData.length > 0 ? WardIDData : '0',
            Flag: 'PDF_Report',
            M_UserID: UserID,
            token: token,
            handleGetPDFUrl: handleGetPDFUrl
        }

        dispatch(GardenCompletedWorkPDFReportTableDataAPI({ data }))
    }

    const { PDFtableData, isPDFLoading } = useSelector(state => state.GardenCompletedWorkPDFReportTableData)


    const handleGetPDFUrl = (pdfUrl) => {
        if (pdfUrl && pdfUrl != '') {
            window.open(pdfUrl)
        } else {
            toastErrorr("PDF not found")
        }
    }

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [WardName.ID, FromDate, ToDate, AssetType.ID])

    useEffect(() => {
        setSelected([])
        setWardIDData([])
    }, [ZoneName.ID])

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    // DashboardHeading="Garden Maintainance"
                    DashboardName={DashboardName}
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar
                        Language={Language}
                        ProjectTypeId={ProjectTypeId} />
                    <div id="" className='content1'>
                        <div className='data_mob'>
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className='col-8'>
                                    <h5 className="ps-1 pt-1">{t('Sidebar.Completed_Work_Report')}</h5>
                                </div>
                                {
                                    (tableData && tableData.table && tableData.table.length > 0 && WardIDData.length > 0) &&
                                    <div className='col-4'>
                                        {
                                            isPDFLoading ?
                                                <button type="button" style={{ width: '7em' }} className="btn btn-danger float-end d-flex" disabled>
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px", marginTop: '3px' }}
                                                    />Loading</button>
                                                :
                                                // PDFtableData && PDFtableData.table && PDFtableData.table.length > 0 ?
                                                <>
                                                    {/* <button type="button" className="btn btn-primary float-end mr-1">Export</button> */}
                                                    <button type="button" className="btn btn-outline-danger float-end mr-1 mb-2 px-2"
                                                        // disabled={WardIDData == 0 ? "disabled" : ''}
                                                        onClick={() => handleGetPDF()}
                                                    >PDF</button>
                                                </>
                                            // :
                                            // ''
                                        }
                                    </div>
                                }

                                <div className="row m-0 mt-1">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12 ">
                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"> <b>{t('Dashboard.Work_Assigned_by_Officials.From_Date')} </b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="FromDate"
                                                            value={FromDate}
                                                            onChange={(e) => (setFromDate(e.target.value), setToDate(''))} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>{t('Dashboard.Work_Assigned_by_Officials.To_Date')} </b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="ToDate"
                                                            value={ToDate}
                                                            onChange={(e) => setToDate(e.target.value)} 
                                                            min={FromDate}/>

                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <ZoneDataDDLS
                                                        ZoneName={ZoneName}
                                                        setZoneName={setZoneName}
                                                        IsPost={IsPost}
                                                        Language={Language}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WorkTypeDDL
                                                        WorkType={WorkType}
                                                        setWorkType={setWorkType}
                                                        Language={Language}
                                                    />
                                                </div>


                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <AssetTypeDataDDL
                                                        AssetType={AssetType}
                                                        setAssetType={setAssetType}
                                                        Language={Language}
                                                    />
                                                </div>
                                                <div className="col-lg-3 col-md-6">
                                                    <label for=""><b>Contractor Name </b></label>

                                                    <ContractorNameReportDDL
                                                        ContractorName={ContractorName}
                                                        setContractorName={setContractorName}
                                                        ZoneName={ZoneName}
                                                        WorkType={WorkType}
                                                        AssetType={AssetType}
                                                        setSelected={setSelected}
                                                    />

                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <label for=""><b>Ward Name </b></label>
                                                    {/* <WardNameMultiCheckBox
                                                        ContractorName={ContractorName}
                                                        WardIDData={WardIDData}
                                                        setWardIDData={setWardIDData}
                                                        selected={selected}
                                                        setSelected={setSelected}
                                                        Flag={WorkType.ID == 4 ? 'ContractorWiseWardSportWork' : 'ContractorWiseWard'}
                                                    /> */}

                                                    <WardNameMultiCheckBox
                                                        ContractorName={ContractorName}
                                                        WardIDData={WardIDData}
                                                        setWardIDData={setWardIDData}
                                                        selected={selected}
                                                        setSelected={setSelected}
                                                        APIChangeFlag="APIChangeFlag"
                                                        ZoneName={ZoneName}
                                                        WorkType={WorkType}
                                                        AssetType={AssetType}
                                                        Language={Language}
                                                        // Flag='ContractorWiseWardSportWork'

                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3 mt-lg-4 mt-md-4" >
                                                    <button type="button" className="btn-search btn btn-primary mx-2"
                                                        onClick={() => handleSearch()}
                                                        disabled={WardIDData == 0}
                                                    >
                                                        Search
                                                    </button>
                                                    <button type="button" className="btn-clear btn btn-primary" style={{ backgroundColor: 'orange' }}
                                                        onClick={handleClear}
                                                    >
                                                        Clear</button>
                                                </div>
                                            </div>

                                            <div className="row mob_table table-responsive pt-1 tableFixHead" style={{ height: '28em' }}>
                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                                            <th style={{ width: "10em" }}>{t('Dashboard.Work_Assigned_by_Officials.Assign_Date')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Asset_Type')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Asset_Name')}</th>
                                                            <th>{t('Dashboard.Work_Assigned_by_Officials.Work_Name')}</th>
                                                            <th>{t('Sidebar.Before_Work_Photo')}</th>
                                                            <th>{t('Sidebar.Work_Done_Date')}</th>
                                                            <th>{t('Sidebar.After_Work_Photo')}</th>
                                                            {/* <th>{t('Sidebar.Work_Assign_By')}</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item, i) =>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>{i + 1}</td>
                                                                    <td>{item.uploadDate ? item.uploadDate : '-'}</td>
                                                                    <td>{item.assetTypeName ? item.assetTypeName : '-'}</td>
                                                                    <td>{item.assetName ? item.assetName : '-'}</td>
                                                                    {/* <td>{item.sectorName ? item.sectorName : '-'}</td> */}
                                                                    <td>{item.workName ? item.workName : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.beforePhotoPath == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye"
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => (
                                                                                            handleShowImage(item.beforePhotoPath),
                                                                                            setHeading("Before Work Photo")
                                                                                        )}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    <td>{item.workDoneDate ? item.workDoneDate : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.afterPhotoPath == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye"
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => (
                                                                                            handleShowImage(item.afterPhotoPath),
                                                                                            setHeading("After Work Photo")
                                                                                        )}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ) :
                                                                <td colSpan={15}>No Found data</ td>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            } */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!--  --> */}


                    </div>
                </div>
                {
                    showImagePopup ?
                        <ViewURL
                            Heading={Heading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''
                }
            </div>
        </>

    )
}