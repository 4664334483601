import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const ZoneMasterTableDataAPI = createAsyncThunk("ZoneMasterTableData", async ({ data }) => {
    const {
        M_ZoneID,
        ZoneName,
        M_UsersID,
        token,
        From,
        To,
        Language
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_Zone_Select?M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&ZoneName=${ZoneName ? ZoneName : ''}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ZoneMasterTableDataSlice = createSlice({
    name: "ZoneMasterTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ZoneMasterTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ZoneMasterTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ZoneMasterTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ZoneMasterTableDataReducer = ZoneMasterTableDataSlice.reducer


export const ZoneMasterPostDataAPI = createAsyncThunk("ZoneMasterPostData", async ({ data }) => {
    const {
        M_ZoneID,
        ZoneName,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ZoneID", M_ZoneID);
    formdata.append("ZoneName", ZoneName);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Post_M_Zone_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const ZoneMasterPostDataSlice = createSlice({
    name: "ZoneMasterPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ZoneMasterPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ZoneMasterPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ZoneMasterPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ZoneMasterPostDataReducer = ZoneMasterPostDataSlice.reducer

// -----------------Delete API ---------------

export const ZoneMasterDeleteAPI = createAsyncThunk("ZoneMasterDelete", async ({ data }) => {
    const {
        M_ZoneID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    // formdata.append("M_CropTypeID", rowData?.m_CropTypeID);
    var formdata = new FormData();
    formdata.append("M_ZoneID", M_ZoneID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_Zone_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const ZoneMasterDeleteSlice = createSlice({
    name: "ZoneMasterDelete",
    initialState: {
        isDeleteLoading: false,
        ZoneMastertableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ZoneMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ZoneMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.ZoneMastertableData = action.payload;

        });
        builder.addCase(ZoneMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.ZoneMastertableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ZoneMasterDeleteReducer = ZoneMasterDeleteSlice.reducer