import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import NewElectricalWorkProgressByContractorCard from './NewElectricalWorkProgressByContractorCard'
import { useAuthState } from '../../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import { CommWorkProgressCountDataAPI } from '../../../../../Redux/CommDashbaord/WorkProgressSlice/WorkProgressSlice';
import { Loading } from '../../../../../Helper/Loading';
import { Bar } from 'react-chartjs-2';
import { WorkAssignProgress, WorkAssignProgress3 } from '../../../../../Helper/Data';
import { useTranslation } from 'react-i18next';
import { ElectricalContractorNameHeaderDDL } from '../../../../../Components/CommonDDL/ElectricalContractorDDL';

export default function WorkNewElectricalWorkProgressByContractorCardManage(props) {

    const { WardName, setWardName, Language, MonthValue, YearValue, YearLabel, MonthLabel, WardValue, WardLabel, DDLData, ZoneLabel, ZoneValue, ZoneNameDDLID, setZoneNameDDLID } = props
    // console.log(YearValue, YearLabel,MonthValue , MonthLabel)

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ContractorID, setContractorID] = useState('0')
    const [CountData, setCountData] = useState([])

    // useLayoutEffect(() => {
    //     setContractorID(DDLData && DDLData[0].m_ContractorID)
    // }, [DDLData])

    const [ElectricalContractorHeaderDDL, setElectricalContractorHeaderDDL] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ElectricalDDlData, setElectricalDDlData] = useState()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    const [DashProjectTypeId, setDashProjectTypeId] = useState(localStorage.getItem("FJ853K") ? localStorage.getItem("FJ853K") : "6")

    const NewElectricalWorkProgressByContractorCardnavigate = (name, benchmark, m_gD_WorkPeriodID, ShowBy, DDLFlag) => {
        // navigate(`/electricalworkprogressbycontractortable?name=${name}&benchmark=${benchmark}&m_gD_WorkPeriodID=${m_gD_WorkPeriodID}&ApiFlag=ElectricalWork&ShowBy=${ShowBy}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&ContractorID=${ContractorID}&DDLFlag=${DDLFlag}`)
        window.open(`/electricalworkprogressbycontractortable?name=${name}&benchmark=${benchmark}&m_gD_WorkPeriodID=${m_gD_WorkPeriodID}&ApiFlag=ElectricalWork&ShowBy=${ShowBy}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&ElectricalContractorHeaderDDL=${ElectricalContractorHeaderDDL.ID}&DDLFlag=${DDLFlag}`);
    }

    const [GraphLable, setGraphLable] = useState([])

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_MonthID: MonthValue,
            M_WardID: WardName.ID,
            M_UsersID: UserID,
            Flag: 'ElectricalWork',
            M_ContractorID: ElectricalContractorHeaderDDL.ID ? ElectricalContractorHeaderDDL.ID : 0,
            token: token,
            setCountData: setCountData,
            setGraphLable: setGraphLable,
            M_ZoneID: ZoneValue,
            Language: Language.Label,
        }
        if (YearValue && Language.Label && WardName && ZoneValue) {
            dispatch(CommWorkProgressCountDataAPI({ data }))
        }
    }, [YearValue, MonthValue, ElectricalContractorHeaderDDL.ID, Language.Label, WardName.ID, ZoneValue])

    const { tableData, isLoading } = useSelector(state => state.CommWorkProgressCountData)

    const WorkProgressTargetdata3 = {

        labels: GraphLable,
        datasets: [
            {
                label: "Assigned",
                fill: true,
                backgroundColor: [
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    // "#294a65",
                ],
                data: CountData.map(i => i.assignTotalCount)
            },
            {
                label: "Pending",
                fill: true,
                backgroundColor: [
                    "#ff0833b5",
                    "#ff0833b5",
                    "#ff0833b5",
                    "#ff0833b5",
                    "#ff0833b5",
                    // "#800080"

                ],
                data: CountData.map(i => i.pendingTotalCount)
            },
            {
                label: "Completed",
                fill: true,
                backgroundColor: [
                    "green",
                    "green",
                    "green",
                    "green",
                    "green",
                    // "#800080"
                ],
                data: CountData.map(i => i.completed_TotalCount)
            }

        ],
    };

    return (
        <>
            {/* {(isLoading) && <Loading />} */}
            <div className='card_heading1 d-lg-flex justify-content-center align-item-center mb-4 mt-n2'>
                <div className="col-12 col-md-6 col-lg-7 py-2 pt-2 mt-2">
                    <span className="ps-lg-2 text-white fw-bold" style={{ fontFamily: 'poppins', fontSize: '16px' }}>{t('Dashboard.Titles.Electrical_Work_Progress_By_Contractor')}</span>
                </div>

                <div className='col-12 col-md-6 col-lg-5 pt-2 mx-auto'>
                    {/* <span className={DDLData == null ? "card-headerbtnhor font-weight-bold py-3" : "card-headerbtn2 font-weight-bold py-3"}> */}
                    {/* <span className="card-headerbtnhor font-weight-bold py-3">
                        <ul className="nav nav-tabs float-end  me-lg-3 mb-md-2 mb-lg-2 mb-2 second_section">
                            <li onClick={() => setContractorID("0")} className='border'>
                                <a data-toggle="tab"
                                    className={ContractorID === "0" ? "active text-black" : "text-white"}><p className="fonttab" style={{ cursor: "pointer" }}>Total</p></a>
                            </li>
                            {
                                DDLData && DDLData.length > 0 &&
                                DDLData.map((i) => {
                                    return (
                                        <li
                                            className='border'
                                            onClick={() => (setContractorID(i.m_ContractorID))}>
                                            <a data-toggle="tab"
                                                className={ContractorID == i.m_ContractorID ? "active text-black" : "text-white"}>
                                                <p className="fonttab" style={{ cursor: "pointer" }}>{i.contractorName}</p>
                                            </a>
                                        </li>
                                    )
                                })
                            }


                        </ul>
                    </span> */}
                    <ElectricalContractorNameHeaderDDL
                        ElectricalContractorHeaderDDL={ElectricalContractorHeaderDDL}
                        setElectricalContractorHeaderDDL={setElectricalContractorHeaderDDL}
                        YearValue={YearValue}
                        YearLabel={YearLabel}
                        MonthValue={MonthValue}
                        MonthLabel={MonthLabel}
                        WardValue={WardValue}
                        WardLabel={WardLabel}
                        ZoneValue={ZoneValue}
                        ZoneLabel={ZoneLabel}
                        Language={Language}
                        DDLData={ElectricalDDlData}
                        ElectricalDDlData={ElectricalDDlData}
                        setElectricalDDlData={setElectricalDDlData}
                        ZoneNameDDLID={ZoneNameDDLID}
                        setZoneNameDDLID={setZoneNameDDLID}
                        Flag='Electrical'
                    // Flag='Ward'
                    />
                </div>
            </div>


            <div className="col-12 col-lg-7 ">
                <div className="row">
                    {
                        CountData && CountData.length > 0 ?
                            CountData.map((i) => {
                                return (
                                    <div className="col-12 col-lg-6 col-md-6">
                                        <NewElectricalWorkProgressByContractorCard
                                            cardHeading={i.benchMark == '1 Day' ? 'Daily' : i.benchMark}
                                            cardName='py-2 wwork_img cursor'
                                            // imgPath='./assets/img/manager.png'
                                            AssignedCount={i.assignTotalCount ? i.assignTotalCount : '0'}
                                            PendingCount={i.pendingTotalCount ? i.pendingTotalCount : '0'}
                                            CompletedCount={i.completed_TotalCount ? i.completed_TotalCount : '0'}
                                            m_gD_WorkPeriodID={i.m_gD_WorkPeriodID ? i.m_gD_WorkPeriodID : '0'}
                                            AssignedHeading={i.assignTotalCountName}
                                            PendingHeading={i.pendingTotalCountName}
                                            CompletedHeading={i.completed_TotalCountName}
                                            // title1={`${i.benchMark} Work Assigned `}
                                            // title2={`${i.benchMark} Work Pending`}
                                            // title3={`${i.benchMark} Work Completed`}
                                            title1={t('Dashboard.Titles.Assigned_work')}
                                            title2={t('Dashboard.Titles.Pending')}
                                            title3={t('Dashboard.Titles.Completed')}
                                            benchmark={i.benchMark}
                                            // navLink='/'
                                            NewElectricalWorkProgressByContractorCardnavigate={NewElectricalWorkProgressByContractorCardnavigate}
                                        />
                                    </div>
                                )
                            })
                            :

                            <p className='text-center fw-bold'>No Data found</p>
                    }

                </div>
            </div>
            {
                CountData.some(i => i.assignTotalCount !== 0 || i.pendingTotalCount !== 0 || i.completed_TotalCount !== 0) ?
                    <div className="col-12 col-lg-5 " style={{ height: '100%' }}>
                        <div className="row py-2">
                            <div className="col-12 " >
                                <div className=" px-5 pt-4 upload_chart bg-white" style={{ borderRadius: "7px", marginTop: "-0.7em" }}>
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <Bar
                                            options={WorkAssignProgress}
                                            data={WorkProgressTargetdata3}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-12 col-lg-5 col-md-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <text>No Data Found</text>
                    </div>
            }
        </>
    )

}

