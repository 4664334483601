
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { WorkSubTypeDDLAPI } from '../../Redux/DDLSlice'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'

export const WorkSubTypeDDL = (props) => {
    const { WorkSubType, setWorkSubType, Flag, rowData, WorkType, setWorkName, WorkName, IsDisabled, Language } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_WorkTypeID: WorkType.ID ? WorkType.ID : 0,
            M_UserID: UserID,
            token: token,
            Language: Language.Label
        }
        { Language.Label != '' && WorkType.ID != 0 && dispatch(WorkSubTypeDDLAPI({ data })) }
    }, [WorkType.ID, Language.Label])

    const { WorkSubTypeData } = useSelector(state => state.WorkSubTypeDDLData)

    useEffect(() => {
        handleWorkSubTypeDDL()
    }, [WorkSubTypeData])


    const handleWorkSubTypeDDL = () => {
        // console.log(DeptDDLDataa)
        if (WorkSubTypeData && WorkSubTypeData.table && WorkSubTypeData.table.length > 0) {
            let list = WorkSubTypeData.table.map((item, index) => ({
                value: item.m_WorkSubTypeID,
                label: item.workSubTypeName,
            }))

            setWorkSubType({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_WorkSubTypeID : 0,
                Label: Flag === 'Update' ? rowData.workSubTypeName : "Select...",
            })
        }
        else {
            setWorkSubType({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label for=""><b>{t('AdminDashboard.Admin_Sidebar_Name.Work_Sub_Type')} {Flag && <AstricSign />}</b></label>

            <Select
                isClearable
                // isRtl={isRtl}
                isDisabled={Flag === 'Update' && IsDisabled ? true : false}
                isSearchable
                maxMenuHeight={150}
                value={{ value: WorkSubType.ID, label: WorkSubType.Label }}
                onChange={(e) => {
                    e ?
                        setWorkSubType({ ...WorkSubType, ID: e.value, Label: e.label })
                        :
                        setWorkSubType({ ...WorkSubType, ID: 0, Label: "Select..." })

                }}
                options={WorkSubType.DDL}
            />
        </div>
    )
}