
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import STPEquipmentWorkDataPopup from './MasterEquipmentWorkDataPopup'
import Header from '../../../../../../Components/Header/Header'
import Sidebar from '../../../../../../Components/Sidebar/Sidebar'
import DeletePopup from '../../../GardenPopup/DeletePopup'
import { useSearchParams } from 'react-router-dom'



export default function STPEquipmentWorkData() {

    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [STPEquipmentWorkDataPopupShow, setSTPEquipmentWorkDataPopupShow] = useState()
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')

    const [searchParams] = useSearchParams()
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    const [EquipmentName, setEquipmentName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [EquipmentWork, setEquipmentWork] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    

    const handleAddOnClick = () => {
        console.log("add")
        setSTPEquipmentWorkDataPopupShow(true)
    }

    const handleEditOnClick = (item) => {
        setSTPEquipmentWorkDataPopupShow(true)
        setapiFlag('Update')
        setrowData()
    }

    const handleOnCloseClick = () => {
        setSTPEquipmentWorkDataPopupShow(false)
    }

    // const handleIsSearch = () => {
    //     setIsSearch(!IsSearch)
    //     setCurrentPage(0)
    // }

    // const handleClear = () => {
    //     setCurrentPage(0)
    //     setIsClear(!IsClear)
    //     setWorkName('')
    // }

    // const handleClear = () => {
    //     // setCurrentPage(0)
    //     // setIsClear(!IsClear)

    //     setWardName({
    //         ...UnitType,
    //         ID: 0,
    //         Label: 'Select...',
    //     })
    //     // console.log("call")
    // }

    return (
        <div>
            <Header
                MLable={MLable}
                HMonth={HMonth}
                YLable={YLable}
                HYear={HYear}
                HWard={HWard}
                WLable={WLable}
                flag='Screen'
                DashboardName={DashboardName}  />
               <div className="wrapper">
                   <Sidebar ProjectTypeId={ProjectTypeId} />
                <div className="content">
                    {/* <!-- [ breadcrumb ] start --> */}
                    <div className="page-header ">
                        <div className="page-block">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    {/* <div className="page-header-title">
                                        <h5 className="ps-2 data_mob">MASTER */}

                                    {/* <span className="float-end fs-6">
                                                <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                        :
                                                        setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                }}
                                                options={SelectWard.DDL}
                                            />

                                            </span> */}
                                    {/* </h5>

                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- [ Main Content ] start --> */}
                    <div className='data_mob'>
                        <div className="row m-1 pb-4 master_border">
                            <div className='mt-2'>
                            <h5>Equipment Master</h5>
                            <div className="float-end butt">
                                {/* <i class="fa-solid fa-plus add_btn" style={{ color: "#010813", fontSize: '17px' }}><span className="ml-2">Add</span></i> */}
                                <button type="button" className="btn btn-primary float-end ml-1">Export</button>
                                {/* 
                                        <SeweragePipelinePopup
                                            open={SewerageMasterPopupShow}
                                        /> */}
                                <i className="fa-solid fa-plus float-end btn btn-secondary"
                                    style={{ color: "white" }}
                                    onClick={handleAddOnClick}
                                >
                                    <text className="ml-2 poppins">Add</text>
                                </i>
                            </div>
                            </div>
                            <div className="row m-0 pt-1">
                                <div className="col-12 py-0 rounded rounded-2 shadow"
                                    style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                    <div className="col-12">

                                        <div className="row py-1 rounded2">
                                        <div className="col-lg-3 col-md-6">
                                                <label for=""><b>Equipment Name :</b></label>
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    // className="ddl_width"
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: EquipmentName.ID, label: EquipmentName.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setEquipmentName({ ...EquipmentName, ID: e.value, Label: e.label })
                                                            :
                                                            setEquipmentName({ ...EquipmentName, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={EquipmentName.DDL}
                                                />

                                            </div>

                                            <div className="col-lg-3 col-md-6">
                                                <label for=""><b>Equipment Work :</b></label>
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    // className="ddl_width"
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: EquipmentWork.ID, label: EquipmentWork.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setEquipmentWork({ ...EquipmentWork, ID: e.value, Label: e.label })
                                                            :
                                                            setEquipmentWork({ ...EquipmentWork, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={EquipmentWork.DDL}
                                                />

                                            </div>

                                            
                                            <div className="col-12 col-md-6 col-lg-1 mt-lg-4 mt-md-4 mt-1" >
                                                <button type="button"
                                                    className="btn-clear btn btn-primary"
                                                >Clear</button>

                                            </div>

                                        </div>
                                        <div className="row mob_table table-responsive pt-1">

                                            <table id="totalordergenerated"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                className="table table-bordered">

                                                <thead>
                                                    <tr>
                                                        <th className="sr_no_col">Sr No.</th>
                                                        <th>Equipment Name</th>
                                                        <th>Equipment Work</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ textAlign: "center" }}>1</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <span>
                                                                <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => handleEditOnClick()}
                                                                >
                                                                </i>
                                                            </span>
                                                            <span>
                                                                <DeletePopup
                                                                    open={DeletePopupShow}
                                                                // item={item}
                                                                // handleDeleteApi={handleDeleteApi}
                                                                />
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    STPEquipmentWorkDataPopupShow ?
                        <STPEquipmentWorkDataPopup
                            open={STPEquipmentWorkDataPopupShow}
                            popupHeading="Work Name"
                            // handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                        // Flag={apiFlag}
                        // rowData={rowData}
                        />
                        : <></>
                }
            </div>
        </div>



    )
}