import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../Helper/BaseUrl";
import { toastErrorr } from "../Helper/ToastMessage";


// ----------------Ward Name-----------

export const WardNameDDLAPI = createAsyncThunk("WardNameDDL", async ({ data }) => {
    const {
        M_ZoneID,
        UserID,
        token,
        Language,
        handleWardNameDDL } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    // return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Ward_Select?M_UserID=${UserID}&Language=${Language}`, requestOptions)
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ZoneWiseM_Ward_Select?M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&M_UserID=${UserID}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                { handleWardNameDDL && handleWardNameDDL(result.data) }
                return result.data
            } else {
                return result
            }
        })
})

const WardNameDDLSlice = createSlice({
    name: "WardNameDDL",
    initialState: {
        isLoading: false,
        WardDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WardNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WardNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WardDDLData = action.payload;
        });
        builder.addCase(WardNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WardDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WardNameDDLReducer = WardNameDDLSlice.reducer


// ----------------Financial Year-----------

export const FinancialYearDDLAPI = createAsyncThunk("FinancialYearDDL", async ({ data }) => {
    const {
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_FinancialYear_Select?UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const FinancialYearDDLSlice = createSlice({
    name: "FinancialYearDDL",
    initialState: {
        isLoading: false,
        FinancialYearData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(FinancialYearDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(FinancialYearDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.FinancialYearData = action.payload;
        });
        builder.addCase(FinancialYearDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.FinancialYearData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const FinancialYearDDLReducer = FinancialYearDDLSlice.reducer

// ----------------Month DDL-----------

export const MonthDDLAPI = createAsyncThunk("MonthDDL", async ({ data }) => {
    const {
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Month_Select?UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {

            if (result.code >= 200 && result.code <= 300 && result.data) {

                return result.data
            } else {
                return result
            }
        })
})

const MonthDDLSlice = createSlice({
    name: "MonthDDL",
    initialState: {
        isLoading: false,
        MonthData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MonthDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(MonthDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.MonthData = action.payload;
        });
        builder.addCase(MonthDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.MonthData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const MonthDDLReducer = MonthDDLSlice.reducer

// ----------------Asset DDL-----------

export const AssetDDLAPI = createAsyncThunk("AssetDDL", async ({ data }) => {
    const {
        UserID,
        token,
        M_AssetTypeID } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Asset_Select?M_AssetTypeID=${M_AssetTypeID}&M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const AssetDDLSlice = createSlice({
    name: "AssetDDL",
    initialState: {
        isLoading: false,
        AssetData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(AssetDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(AssetDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.AssetData = action.payload;
        });
        builder.addCase(AssetDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.AssetData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const AssetDDLReducer = AssetDDLSlice.reducer

// ----------------Asset Type DDL-----------

export const AssetTypeDDLAPI = createAsyncThunk("AssetTypeDDL", async ({ data }) => {
    const {
        UserID,
        token,
        Language
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_AssetType_Select?M_UserID=${UserID}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const AssetTypeDDLSlice = createSlice({
    name: "AssetTypeDDL",
    initialState: {
        isLoading: false,
        AssetTypeData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(AssetTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(AssetTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.AssetTypeData = action.payload;
        });
        builder.addCase(AssetTypeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.AssetTypeData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const AssetTypeDDLReducer = AssetTypeDDLSlice.reducer

// ---------------Sector DDL-----------

export const SectorDDLAPI = createAsyncThunk("SectorDDL", async ({ data }) => {
    const {
        M_WardID,
        UserID,
        token,
        Language
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_GD_Sector_Select?M_WardID=${M_WardID ? M_WardID : '0'}&M_UserID=${UserID}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const SectorDDLSlice = createSlice({
    name: "SectorDDL",
    initialState: {
        isLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SectorDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SectorDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.SectorData = action.payload;
        });
        builder.addCase(SectorDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.SectorData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const SectorDDLReducer = SectorDDLSlice.reducer

// ---------------Department DDL-----------

export const DepartmentDDLAPI = createAsyncThunk("DepartmentDDL", async ({ data }) => {
    const {
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Department_Select?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DepartmentDDLSlice = createSlice({
    name: "DepartmentDDL",
    initialState: {
        isLoading: false,
        DepDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DepartmentDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DepartmentDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DepDDLData = action.payload;
        });
        builder.addCase(DepartmentDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DepDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DepartmentDDLReducer = DepartmentDDLSlice.reducer

// ---------------Designation DDL-----------

export const DesignationDDLAPI = createAsyncThunk("DesignationDDL", async ({ data }) => {
    const {
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Designation_Select?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DesignationDDLSlice = createSlice({
    name: "DesignationDDL",
    initialState: {
        isLoading: false,
        DesignationData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DesignationDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DesignationDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DesignationData = action.payload;
        });
        builder.addCase(DesignationDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DesignationData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DesignationDDLReducer = DesignationDDLSlice.reducer

// ---------------Yes / NO DDL-----------

export const YesNoDDLAPI = createAsyncThunk("YesNoDDL", async ({ data }) => {
    const {
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_YesNo_Select?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const YesNoDDLSlice = createSlice({
    name: "YesNoDDL",
    initialState: {
        isLoading: false,
        YesNoData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(YesNoDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(YesNoDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.YesNoData = action.payload;
        });
        builder.addCase(YesNoDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.YesNoData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const YesNoDDLReducer = YesNoDDLSlice.reducer

export const EmployeeDDLAPI = createAsyncThunk("EmployeeDDL", async ({ data }) => {
    const {
        M_DepartmentID,
        M_DesignationID,
        Designation,
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Employee_Select?M_DesignationID=${M_DesignationID ? M_DesignationID : '0'}&M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const EmployeeDDLSlice = createSlice({
    name: "EmployeeDDL",
    initialState: {
        isLoading: false,
        EmpDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EmployeeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EmployeeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.EmpDDLData = action.payload;
        });
        builder.addCase(EmployeeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.EmpDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const EmployeeDDLReducer = EmployeeDDLSlice.reducer


export const ContractorNameDDLAPI = createAsyncThunk("ContractorNameDDL", async ({ data }) => {
    const {
        M_UserID,
        token,
        Language,
        Flag
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Contractor_Select?M_UserID=${M_UserID}&Language=${Language}&Flag=${Flag}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ContractorNameDDLSlice = createSlice({
    name: "ContractorNameDDL",
    initialState: {
        isLoading: false,
        ContractorNameDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ContractorNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ContractorNameDDLData = action.payload;
        });
        builder.addCase(ContractorNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ContractorNameDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ContractorNameDDLReducer = ContractorNameDDLSlice.reducer


export const ContractorNameDDLNewAPI = createAsyncThunk("ContractorNameDDLNew", async ({ data }) => {
    const {
        M_WorkTypeID,
        M_AssetID,
        M_AssetTypeID,
        M_RoleID,
        M_ZoneID,
        M_GD_WorkPeriodID,
        M_UserID,
        token,
        Language,
        Flag
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_WorkTypeWiseM_Contractor_Select?M_WorkTypeID=${M_WorkTypeID}&M_AssetID=${M_AssetID}&M_AssetTypeID=${M_AssetTypeID}&M_RoleID=${M_RoleID}&M_ZoneID=${M_ZoneID}&M_GD_WorkPeriodID=${M_GD_WorkPeriodID ? M_GD_WorkPeriodID : '0'}&M_UserID=${M_UserID}&Flag=${Flag}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
                // console.log('result.data.table',result.data)
            } else {
                return result
            }
        })
})

const ContractorNameDDLNewSlice = createSlice({
    name: "ContractorNameDDLNew",
    initialState: {
        isLoading: false,
        ContractorNameDDLNewData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorNameDDLNewAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ContractorNameDDLNewAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ContractorNameDDLNewData = action.payload;
        });
        builder.addCase(ContractorNameDDLNewAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ContractorNameDDLNewData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ContractorNameDDLNewReducer = ContractorNameDDLNewSlice.reducer



export const ContractorNameReportDDLAPI = createAsyncThunk("ContractorNameReportDDL", async ({ data }) => {
    const {
        M_UserID,
        token,
        M_ZoneID,
        M_WorkTypeID,
        M_AssetTypeID
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ZoneWiseM_WardAssetM_Contractor_Select?M_ZoneID=${M_ZoneID}&M_WorkTypeID=${M_WorkTypeID}&M_AssetTypeID=${M_AssetTypeID}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                return result.data.table
            } else {
                return result
            }
        })
})

const ContractorNameReportDDLSlice = createSlice({
    name: "ContractorNameReportDDL",
    initialState: {
        isLoading: false,
        ContractorNameReportDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorNameReportDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ContractorNameReportDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ContractorNameReportDDLData = action.payload;
        });
        builder.addCase(ContractorNameReportDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ContractorNameReportDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ContractorNameReportDDLReducer = ContractorNameReportDDLSlice.reducer


// ----------------Zone Master--------------------

export const ZoneDDLAPI = createAsyncThunk("ZoneDDL", async ({ data }) => {
    const {
        M_DepartmentID,
        M_DesignationID,
        UserID,
        token,
        Language,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Zone_Select?M_UserID=${UserID}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ZoneDDLSlice = createSlice({
    name: "ZoneDDL",
    initialState: {
        isLoading: false,
        ZoneData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ZoneDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ZoneDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ZoneData = action.payload;
        });
        builder.addCase(ZoneDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ZoneData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ZoneDDLReducer = ZoneDDLSlice.reducer


// / ----------------Project Name Master--------------------

export const ProjectNameDDLAPI = createAsyncThunk("ProjectNameDDL", async ({ data }) => {
    const {
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Project_Select?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ProjectNameDDLSlice = createSlice({
    name: "ProjectNameDDL",
    initialState: {
        isLoading: false,
        ProjectData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ProjectData = action.payload;
        });
        builder.addCase(ProjectNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ProjectData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ProjectNameDDLReducer = ProjectNameDDLSlice.reducer

// / ----------------Project Name Master--------------------

export const GDProjectNameDDLAPI = createAsyncThunk("GDProjectNameDDL", async ({ data }) => {
    const {
        M_ProjectTypeID,
        Flag,
        M_UserID,
        token
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ProjectTypeWise_M_ProjectSelect?M_ProjectTypeID=${M_ProjectTypeID ? M_ProjectTypeID : '0'}&Flag=${Flag}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const GDProjectNameDDLSlice = createSlice({
    name: "GDProjectNameDDL",
    initialState: {
        isLoading: false,
        GDProjectData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GDProjectNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(GDProjectNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.GDProjectData = action.payload;
        });
        builder.addCase(GDProjectNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.GDProjectData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GDProjectNameDDLReducer = GDProjectNameDDLSlice.reducer

export const WorkTypeDDLAPI = createAsyncThunk("WorkTypeDDL", async ({ data }) => {
    const {
        UserID,
        token,
        Language
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_WorkType_Select?M_UserID=${UserID}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WorkTypeDDLSlice = createSlice({
    name: "WorkTypeDDL",
    initialState: {
        isLoading: false,
        WorkTypeData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WorkTypeData = action.payload;
        });
        builder.addCase(WorkTypeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WorkTypeData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const WorkTypeDDLReducer = WorkTypeDDLSlice.reducer


export const WorkSubTypeDDLAPI = createAsyncThunk("WorkSubTypeDDL", async ({ data }) => {
    const {
        M_WorkTypeID,
        M_UserID,
        Language,
        token, } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_GD_DDL_M_WorkSubType_Select?M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_UserID=${M_UserID}&Language=${Language}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WorkSubTypeDDLSlice = createSlice({
    name: "WorkSubTypeDDL",
    initialState: {
        isLoading: false,
        WorkSubTypeData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkSubTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkSubTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WorkSubTypeData = action.payload;
        });
        builder.addCase(WorkSubTypeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WorkSubTypeData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const WorkSubTypeDDLReducer = WorkSubTypeDDLSlice.reducer


export const WorkNameDDLAPI = createAsyncThunk("WorkNameDDL", async ({ data }) => {
    const {
        M_WorkTypeID,
        M_WorkSubTypeID,
        M_UserID,
        Language,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_GD_DDL_M_Work_Select?M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_WorkSubTypeID=${M_WorkSubTypeID ? M_WorkSubTypeID : '0'}&M_UserID=${M_UserID}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WorkNameDDLSlice = createSlice({
    name: "WorkNameDDL",
    initialState: {
        isLoading: false,
        WorkNameData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WorkNameData = action.payload;
        });
        builder.addCase(WorkNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WorkNameData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const WorkNameDDLReducer = WorkNameDDLSlice.reducer

//  Update Work Name DDl API

export const UpdateWorkNameDDLAPI = createAsyncThunk("UpdateWorkNameDDL", async ({ data }) => {
    const {
        M_ProjectID,
        M_UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ProjectWiseM_Work_Select?M_ProjectID=${M_ProjectID ? M_ProjectID : '0'}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const UpdateWorkNameDDLSlice = createSlice({
    name: "UpdateWorkNameDDL",
    initialState: {
        isLoading: false,
        UpdateWorkNameData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UpdateWorkNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UpdateWorkNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.UpdateWorkNameData = action.payload;
        });
        builder.addCase(UpdateWorkNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.UpdateWorkNameData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const UpdateWorkNameDDLReducer = UpdateWorkNameDDLSlice.reducer

export const LocationNameDDLAPI = createAsyncThunk("LocationNameDDL", async ({ data }) => {
    const {
        WardName,
        M_UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_APK_RM_DDL_M_RM_Location_Select?M_WardID=${WardName.ID ? WardName.ID : '0'}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const LocationNameDDLSlice = createSlice({
    name: "LocationNameDDL",
    initialState: {
        isLoading: false,
        LocationNameData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(LocationNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(LocationNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.LocationNameData = action.payload;
        });
        builder.addCase(LocationNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.LocationNameData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const LocationNameDDLReducer = LocationNameDDLSlice.reducer

export const RoadWorkNameDDLAPI = createAsyncThunk("RoadWorkNameDDL", async ({ data }) => {
    const {
        Flag,
        M_UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_DDL_M_RM_Work_Select?Flag=${Flag}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const RoadWorkNameDDLSlice = createSlice({
    name: "RoadWorkNameNameDDL",
    initialState: {
        isLoading: false,
        RoadWorkNameData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(RoadWorkNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(RoadWorkNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.RoadWorkNameData = action.payload;
        });
        builder.addCase(RoadWorkNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.RoadWorkNameData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const RoadWorkNameDDLReducer = RoadWorkNameDDLSlice.reducer

// -----------------------Project Type -------------------

export const ProjectTypeNameDDLAPI = createAsyncThunk("ProjectTypeNameDDL", async ({ data }) => {
    const {
        Flag,
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ProjectType_Select?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ProjectTypeNameDDLSlice = createSlice({
    name: "ProjectTypeNameDDL",
    initialState: {
        isLoading: false,
        ProjectTypeNameData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ProjectTypeNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ProjectTypeNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ProjectTypeNameData = action.payload;
        });
        builder.addCase(ProjectTypeNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ProjectTypeNameData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const ProjectTypeNameDDLReducer = ProjectTypeNameDDLSlice.reducer

// -----------------------Project Type -------------------

export const RoleNameDDLAPI = createAsyncThunk("RoleNameDDL", async ({ data }) => {
    const {
        Flag,
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Role_Select?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const RoleNameDDLSlice = createSlice({
    name: "RoleNameDDL",
    initialState: {
        isLoading: false,
        RoleNameData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(RoleNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(RoleNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.RoleNameData = action.payload;
        });
        builder.addCase(RoleNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.RoleNameData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const RoleNameDDLReducer = RoleNameDDLSlice.reducer

// -----------------------Status DDL -------------------

export const StatusDDLAPI = createAsyncThunk("StatusDDL", async ({ data }) => {
    const {
        Flag,
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_DDL_GD_M_Status_Select?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const StatusDDLSlice = createSlice({
    name: "StatusDDL",
    initialState: {
        isLoading: false,
        StatusData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(StatusDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(StatusDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.StatusData = action.payload;
        });
        builder.addCase(StatusDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.StatusData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const StatusDDLReducer = StatusDDLSlice.reducer

// -----------------------Severage Work Type DDL -------------------

export const SeverageWorkTypeDDLAPI = createAsyncThunk("SeverageWorkTypeDDL", async ({ data }) => {
    const {
        Flag,
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_SW_DDL_M_SW_WorkType_Select?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const SeverageWorkTypeSlice = createSlice({
    name: "SeverageWorkTypeDDL",
    initialState: {
        isLoading: false,
        SeverageData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SeverageWorkTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SeverageWorkTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.SeverageData = action.payload;
        });
        builder.addCase(SeverageWorkTypeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.SeverageData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const SeverageWorkTypeReducer = SeverageWorkTypeSlice.reducer

// -----------------------Severage Pumping Station DDL -------------------

export const PumpingStationDDLAPI = createAsyncThunk("PumpingStationDDL", async ({ data }) => {
    const {
        Flag,
        UserID,
        WardName,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_SW_DDL_M_SW_PumpingStation_Select?M_WardID=${WardName ? WardName : '0'}&M_UserID=${UserID}&Flag=Master`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const PumpingStationSlice = createSlice({
    name: "PumpingStationDDL",
    initialState: {
        isLoading: false,
        PumpingData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PumpingStationDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PumpingStationDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.PumpingData = action.payload;
        });
        builder.addCase(PumpingStationDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.PumpingData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const PumpingStationReducer = PumpingStationSlice.reducer

// -----------------------Severage Work Period DDL -------------------

export const WorkPeriodDDLAPI = createAsyncThunk("WorkPeriodDDL", async ({ data }) => {
    const {
        Flag,
        UserID,
        WardName,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_SW_DDL_M_SW_WorkPeriod_Select?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WorkPeriodDDLSlice = createSlice({
    name: "WorkPeriodDDL",
    initialState: {
        isLoading: false,
        WorkPeriodData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkPeriodDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkPeriodDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WorkPeriodData = action.payload;
        });
        builder.addCase(WorkPeriodDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WorkPeriodData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const WorkPeriodDDLReducer = WorkPeriodDDLSlice.reducer

export const WorkAssignByDDLAPI = createAsyncThunk("WorkAssignByDDL", async ({ data }) => {
    const {
        Flag,
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_DDL_GD_WorkAssignBy_Select?Flag=${Flag}&M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WorkAssignByDDLSlice = createSlice({
    name: "WorkAssignByDDL",
    initialState: {
        isLoading: false,
        WorkAssignByData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkAssignByDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkAssignByDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WorkAssignByData = action.payload;
        });
        builder.addCase(WorkAssignByDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WorkAssignByData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const WorkAssignByDDLReducer = WorkAssignByDDLSlice.reducer


// ------------------------Location DDL ------------

export const SeverageLocationDDLAPI = createAsyncThunk("SeverageLocationDDL", async ({ data }) => {
    const {
        WardName,
        Flag,
        M_UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_SW_DDL_M_SW_Location_Select?M_WardID=${WardName.ID ? WardName.ID : '0'}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const SeverageLocationDDLSlice = createSlice({
    name: "SeverageLocationDDL",
    initialState: {
        isLoading: false,
        SeverageLocationData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SeverageLocationDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SeverageLocationDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.SeverageLocationData = action.payload;
        });
        builder.addCase(SeverageLocationDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.SeverageLocationData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const SeverageLocationDDLReducer = SeverageLocationDDLSlice.reducer

// ------------------------Location DDL ------------

export const ServiceTypeDDLAPI = createAsyncThunk("ServiceTypeDDL", async ({ data }) => {
    const {
        WardName,
        Flag,
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_SW_DDL_M_SW_ServiceType_Select?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ServiceTypeDDLSlice = createSlice({
    name: "ServiceTypeDDL",
    initialState: {
        isLoading: false,
        ServiceTypeData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ServiceTypeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ServiceTypeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ServiceTypeData = action.payload;
        });
        builder.addCase(ServiceTypeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ServiceTypeData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const ServiceTypeDDLReducer = ServiceTypeDDLSlice.reducer

// ------------------Water Supply Location DDL ----------------
export const WaterSupplyLocationDDLAPI = createAsyncThunk("WaterSupplyLocationDDL", async ({ data }) => {
    const {
        WardName,
        Flag,
        M_UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_WS_DDL_M_WS_Location_Select?M_WardID=${WardName ? WardName : '0'}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WaterSupplyLocationDDLSlice = createSlice({
    name: "WaterSupplyLocationDDL",
    initialState: {
        isLoading: false,
        WaterSupplyLocationData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WaterSupplyLocationDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WaterSupplyLocationDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WaterSupplyLocationData = action.payload;
        });
        builder.addCase(WaterSupplyLocationDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WaterSupplyLocationData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const WaterSupplyLocationDDLReducer = WaterSupplyLocationDDLSlice.reducer

// Water Supply User Role DDL

export const WaterSupplyUserRoleDataDDLAPI = createAsyncThunk("WaterSupplyUserRoleDataDDL", async ({ data }) => {
    const {
        M_ProjectTypeID,
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ProjectTypeWiseM_Role_Select?M_ProjectTypeID=${M_ProjectTypeID}&M_UserID=${UserID}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WaterSupplyUserRoleDataDDLSlice = createSlice({
    name: "WaterSupplyUserRoleDataDDL",
    initialState: {
        isLoading: false,
        WaterSupplyUserRoleData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WaterSupplyUserRoleDataDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WaterSupplyUserRoleDataDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WaterSupplyUserRoleData = action.payload;
        });
        builder.addCase(WaterSupplyUserRoleDataDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WaterSupplyUserRoleData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const WaterSupplyUserRoleDDLReducer = WaterSupplyUserRoleDataDDLSlice.reducer

// Water Supply User Data DDL

export const WaterSupplyUserDataDDLAPI = createAsyncThunk("WaterSupplyUserDataDDL", async ({ data }) => {
    const {
        M_RoleID,
        M_ProjectTypeID,
        M_UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ProjectTypeWiseM_RoleWiseUserDetails_Select?M_RoleID=${M_RoleID}&M_ProjectTypeID=${M_ProjectTypeID}&M_UserID=${M_UserID}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WaterSupplyUserDataDDLSlice = createSlice({
    name: "WaterSupplyUserDataDDL",
    initialState: {
        isLoading: false,
        WaterSupplyUserData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WaterSupplyUserDataDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WaterSupplyUserDataDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WaterSupplyUserData = action.payload;
        });
        builder.addCase(WaterSupplyUserDataDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WaterSupplyUserData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const WaterSupplyUserDDLReducer = WaterSupplyUserDataDDLSlice.reducer

// ---------------------------Junction Name----------------------------------------

export const JunctionNameDDLAPI = createAsyncThunk("JunctionNameDDL", async ({ data }) => {
    const {
        WardName,
        M_UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_APK_WS_DDL_M_WS_Junction_Select?M_WardID=${WardName ? WardName : '0'}&M_UserID=${M_UserID}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const JunctionNameDDLSlice = createSlice({
    name: "JunctionNameDDL",
    initialState: {
        isLoading: false,
        JunctionNameData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(JunctionNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(JunctionNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.JunctionNameData = action.payload;
        });
        builder.addCase(JunctionNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.JunctionNameData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const JunctionNameDDLReducer = JunctionNameDDLSlice.reducer


// ---------------------------Junction Name----------------------------------------

export const EcoliDDLAPI = createAsyncThunk("EcoliDDL", async ({ data }) => {
    const {
        Flag,
        M_UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_APK_WS_DDL_M_Indicator_Select?Flag=${Flag}&M_UserID=${M_UserID}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const EcoliDDLSlice = createSlice({
    name: "EcoliDDL",
    initialState: {
        isLoading: false,
        EcoliData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EcoliDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EcoliDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.EcoliData = action.payload;
        });
        builder.addCase(EcoliDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.EcoliData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const EcoliDDLReducer = EcoliDDLSlice.reducer

// ---------------------------Pumping Station watersupply Name----------------------------------------


export const WaterSupplyPumpingStationDDLAPI = createAsyncThunk("WaterSupplyPumpingStationDDL", async ({ data }) => {
    const {
        M_WardID,
        UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_WS_DDL_M_WS_PumpStation_Select?M_WardID=${M_WardID ? M_WardID : '0'}&M_UserID=${UserID}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WaterSupplyPumpingStationDDLSlice = createSlice({
    name: "WaterSupplyPumpingStationDDL",
    initialState: {
        isLoading: false,
        WaterSupplyPumpingStationData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WaterSupplyPumpingStationDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WaterSupplyPumpingStationDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WaterSupplyPumpingStationData = action.payload;
        });
        builder.addCase(WaterSupplyPumpingStationDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WaterSupplyPumpingStationData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const WaterSupplyPumpingStationDDLReducer = WaterSupplyPumpingStationDDLSlice.reducer



// Contractor 

export const CommContractorDDLAPI = createAsyncThunk("CommContractorDDLData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_UserID,
        Flag,
        M_WardID,
        M_ZoneID,
        token,
        Language,
        setHorticultureDDlData,
        setCivilDDlData,
        setElectricalDDlData,
        setSportDDlData
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_DDL_DB_M_GD_M_Contractor_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_WardID=${M_WardID}&M_ZoneID=${M_ZoneID}&M_UserID=${M_UserID}&Flag=${Flag}&Language=${Language}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                if (Flag == 'Horticulture') {
                    setHorticultureDDlData(result.data.table)
                }
                if (Flag == 'Civil') {
                    setCivilDDlData(result.data.table)
                }
                if (Flag == 'Electrical') {
                    setElectricalDDlData(result.data.table)
                }
                if (Flag == 'Sport') {
                    setSportDDlData(result.data.table)
                }
                return result.data.table
            } else {
                return result.data
            }
        })
})

const CommContractorDDLSlice = createSlice({
    name: "CommContractorDDLData",
    initialState: {
        isDDLLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommContractorDDLAPI.pending, (state, action) => {
            state.isDDLLoading = true;
        });
        builder.addCase(CommContractorDDLAPI.fulfilled, (state, action) => {
            state.isDDLLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(CommContractorDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDDLLoading = false;
            state.isError = true;
            state.DDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const CommContractorDDLReducer = CommContractorDDLSlice.reducer



export const ContractorWiseAssetDDLAPI = createAsyncThunk("ContractorWiseAssetDDL", async ({ data }) => {
    const {
        M_WorkTypeID,
        M_AssetID,
        M_RoleID,
        M_UserID,
        M_AssetTypeID,
        M_ZoneID,
        M_GD_WorkPeriodID,
        Flag,
        token,
        Language
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    // return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ContractorWiseM_Asset_Select?M_WardID=${M_WardID}&M_AssetTypeID=${M_AssetTypeID}&M_ContractorID=${M_ContractorID}&M_UsersID=${M_UsersID}`, requestOptions)

    // return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_WorkTypeWiseM_Contractor_Select?M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_UserID=${M_UsersID}&Language=${Language}`, requestOptions)
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_WorkTypeWiseM_Contractor_Select?M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_AssetTypeID=${M_AssetTypeID}&M_RoleID=${M_RoleID}&M_ZoneID=${M_ZoneID}&M_GD_WorkPeriodID=${M_GD_WorkPeriodID ? M_GD_WorkPeriodID : '0'}&M_UserID=${M_UserID}&Flag=${Flag}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data.table) {
                return result.data.table
                // console.log("fdgfd",result.data.table)
            } else {
                return result.data
            }
        })
})

const ContractorWiseAssetDDLSlice = createSlice({
    name: "ContractorWiseAssetDDLData",
    initialState: {
        isDDLLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorWiseAssetDDLAPI.pending, (state, action) => {
            state.isDDLLoading = true;
        });
        builder.addCase(ContractorWiseAssetDDLAPI.fulfilled, (state, action) => {
            state.isDDLLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(ContractorWiseAssetDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDDLLoading = false;
            state.isError = true;
            state.DDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const ContractorWiseAssetDDLDataReducer = ContractorWiseAssetDDLSlice.reducer


export const MaintainByDDLAPI = createAsyncThunk("MaintainByDDL", async ({ data }) => {
    const {
        M_AssetTypeID,
        M_UserID,
        token,
        Language,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_MaintainByIndicatorAgency_Select?M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_UserID=${M_UserID}&Language=${Language}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const MaintainByDDLSlice = createSlice({
    name: "MaintainByDDLData",
    initialState: {
        isDDLLoading: false,
        MaintainByDDL: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(MaintainByDDLAPI.pending, (state, action) => {
            state.isDDLLoading = true;
        });
        builder.addCase(MaintainByDDLAPI.fulfilled, (state, action) => {
            state.isDDLLoading = false;
            state.MaintainByDDL = action.payload;
        });
        builder.addCase(MaintainByDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDDLLoading = false;
            state.isError = true;
            state.MaintainByDDL = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const MaintainByDDLDataReducer = MaintainByDDLSlice.reducer


export const AssetNameDDLAPI = createAsyncThunk("AssetNameDDL", async ({ data }) => {
    const {
        M_AssetTypeID,
        M_UserID,
        token,
        Language
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Asset_Select?M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_UserID=${M_UserID}&Language=${Language}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const AssetNameDDLSlice = createSlice({
    name: "AssetNameDDLData",
    initialState: {
        isDDLLoading: false,
        AssetNameDDL: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(AssetNameDDLAPI.pending, (state, action) => {
            state.isDDLLoading = true;
        });
        builder.addCase(AssetNameDDLAPI.fulfilled, (state, action) => {
            state.isDDLLoading = false;
            state.AssetNameDDL = action.payload;
        });
        builder.addCase(AssetNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDDLLoading = false;
            state.isError = true;
            state.AssetNameDDL = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const AssetNameDDLDataReducer = AssetNameDDLSlice.reducer


// Zone Name API
export const ZoneNameDDLAPI = createAsyncThunk("ZoneNameDDL", async ({ data }) => {
    const {
        UserID,
        token,
        Language
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_UserWiseM_Zone_Select?M_UserID=${UserID}&Language=${Language}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ZoneNameDDLSlice = createSlice({
    name: "ZoneNameDDL",
    initialState: {
        isDDLLoading: false,
        DDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ZoneNameDDLAPI.pending, (state, action) => {
            state.isDDLLoading = true;
        });
        builder.addCase(ZoneNameDDLAPI.fulfilled, (state, action) => {
            state.isDDLLoading = false;
            state.DDLData = action.payload;
        });
        builder.addCase(ZoneNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDDLLoading = false;
            state.isError = true;
            state.DDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const ZoneNameDDLReducer = ZoneNameDDLSlice.reducer

///// Contact Person DDL 

export const ContactPersonDDLAPI = createAsyncThunk("ContactPersonDDL", async ({ data }) => {
    const {
        UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_DDL_M_ContractorM_EmployeeID_Select?M_UsersID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ContactPersonDDLSlice = createSlice({
    name: "ContactPersonDDL",
    initialState: {
        isLoading: false,
        ContactPersonData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContactPersonDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ContactPersonDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.ContactPersonData = action.payload;
        });
        builder.addCase(ContactPersonDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.ContactPersonData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ContactPersonDDLReducer = ContactPersonDDLSlice.reducer

// ------------- New Department DDL --------------

export const NewDepartmentDDLAPI = createAsyncThunk("NewDepartmentDDL", async ({ data }) => {
    const {
        UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Department_Select?M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const NewDepartmentDDLSlice = createSlice({
    name: "NewDepartmentDDL",
    initialState: {
        isLoading: false,
        NewDepartmentDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NewDepartmentDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(NewDepartmentDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.NewDepartmentDDLData = action.payload;
        });
        builder.addCase(NewDepartmentDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.NewDepartmentDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const NewDepartmentDDLReducer = NewDepartmentDDLSlice.reducer

// --------- New Role DDL ---------

export const NewRoleDDLAPI = createAsyncThunk("NewRoleDDL", async ({ data }) => {
    const {
        M_DepartmentID,
        UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_DepartmentWiseM_Role_Select?M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&M_UserID=${UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const NewRoleDDLSlice = createSlice({
    name: "NewRoleDDL",
    initialState: {
        isLoading: false,
        NewRoleDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NewRoleDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(NewRoleDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.NewRoleDDLData = action.payload;
        });
        builder.addCase(NewRoleDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.NewRoleDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const NewRoleDDLReducer = NewRoleDDLSlice.reducer

// ------- Equipment Name DDl --------

export const EquipmentNameDDLAPI = createAsyncThunk("EquipmentNameDDL", async ({ data }) => {
    const {
        M_UserID,
        Language,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_AssetWiseEquipment_Select?M_UserID=${M_UserID}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const EquipmentNameDDLSlice = createSlice({
    name: "EquipmentNameDDL",
    initialState: {
        isLoading: false,
        EquipmentNameDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EquipmentNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EquipmentNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.EquipmentNameDDLData = action.payload;
        });
        builder.addCase(EquipmentNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.EquipmentNameDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const EquipmentNameDDLReducer = EquipmentNameDDLSlice.reducer


///  New Work Type Wise Contractor DDL 

export const WorkTypeWiseContractorDDLAPI = createAsyncThunk("WorkTypeWiseContractorDDL", async ({ data }) => {
    const {
        M_WorkTypeID,
        M_UserID,
        Language,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_WorkTypeWiseM_Contractor_Select?M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_UserID=${M_UserID}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WorkTypeWiseContractorDDLSlice = createSlice({
    name: "WorkTypeWiseContractorDDL",
    initialState: {
        isLoading: false,
        WorkTypeWiseContractorDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkTypeWiseContractorDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkTypeWiseContractorDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WorkTypeWiseContractorDDLData = action.payload;
        });
        builder.addCase(WorkTypeWiseContractorDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WorkTypeWiseContractorDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WorkTypeWiseContractorDDLReducer = WorkTypeWiseContractorDDLSlice.reducer

// Ward Wise Asset DDL 

export const WardWiseAssetNameDDLAPI = createAsyncThunk("WardWiseAssetNameDDL", async ({ data }) => {
    const {
        M_WardID,
        M_AssetTypeID,
        M_WorkTypeID,
        M_ZoneID,
        M_RoleID,
        M_GD_WorkPeriodID,
        Flag,
        M_UserID,
        Language,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_WardWiseM_Asset_Select?M_WardID=${M_WardID ? M_WardID : '0'}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&M_RoleID=${M_RoleID}&M_GD_WorkPeriodID=${M_GD_WorkPeriodID}&M_UserID=${M_UserID}&Flag=${Flag}&Language=${Language}`, requestOptions)
        // return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_WardWiseM_Asset_Select?M_WardID=${M_WardID ? M_WardID : '0'}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_UserID=${M_UserID}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WardWiseAssetNameDDLSlice = createSlice({
    name: "WardWiseAssetNameDDL",
    initialState: {
        isLoading: false,
        WardWiseAssetNameDDL: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WardWiseAssetNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WardWiseAssetNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WardWiseAssetNameDDL = action.payload;
        });
        builder.addCase(WardWiseAssetNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WardWiseAssetNameDDL = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WardWiseAssetNameDDLReducer = WardWiseAssetNameDDLSlice.reducer

// Asset Wise Equipment DDL

export const AssetWiseEquipmentNameDDLAPI = createAsyncThunk("AssetWiseEquipmentNameDDL", async ({ data }) => {
    const {
        M_AssetID,
        M_UserID,
        Language,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_DDL_M_AssetWiseEquipment_Select?M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_UserID=${M_UserID}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const AssetWiseEquipmentNameDDLSlice = createSlice({
    name: "AssetWiseEquipmentNameDDL",
    initialState: {
        isLoading: false,
        AssetWiseEquipmentNameDDL: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(AssetWiseEquipmentNameDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(AssetWiseEquipmentNameDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.AssetWiseEquipmentNameDDL = action.payload;
        });
        builder.addCase(AssetWiseEquipmentNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.AssetWiseEquipmentNameDDL = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const AssetWiseEquipmentNameDDLReducer = AssetWiseEquipmentNameDDLSlice.reducer

// Comm Sidebar Master New Department DDL

export const CommNewDepartmentDDLAPI = createAsyncThunk("CommNewDepartmentDDL", async ({ data }) => {
    const {
        M_UserID,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_DDL_M_Department_Select?M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const CommNewDepartmentDDLSlice = createSlice({
    name: "CommNewDepartmentDDL",
    initialState: {
        isLoading: false,
        CommNewDepartmentDDL: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommNewDepartmentDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CommNewDepartmentDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CommNewDepartmentDDL = action.payload;
        });
        builder.addCase(CommNewDepartmentDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.CommNewDepartmentDDL = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CommNewDepartmentDDLReducer = CommNewDepartmentDDLSlice.reducer

// Department Wise Employee DDl

export const DepartmentWiseEmployeeDDLAPI = createAsyncThunk("DepartmentWiseEmployeeDDL", async ({ data }) => {
    const {
        M_DepartmentID,
        M_UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Department_Wise_Employee_Select?M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&M_UserID=${M_UserID}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DepartmentWiseEmployeeDDLSlice = createSlice({
    name: "DepartmentWiseEmployeeDDL",
    initialState: {
        isLoading: false,
        DepartmentWiseEmployeeData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DepartmentWiseEmployeeDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DepartmentWiseEmployeeDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DepartmentWiseEmployeeData = action.payload;
        });
        builder.addCase(DepartmentWiseEmployeeDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DepartmentWiseEmployeeData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const DepartmentWiseEmployeeDDLReducer = DepartmentWiseEmployeeDDLSlice.reducer

// Department Wise Designation DDL

export const DepartmentWiseDesignationDDLAPI = createAsyncThunk("DepartmentWiseDesignationDDL", async ({ data }) => {
    const {
        M_DepartmentID,
        M_UserID,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_Department_Wise_Designation_Select?M_DepartmentID=${M_DepartmentID ? M_DepartmentID : '0'}&M_UserID=${M_UserID}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DepartmentWiseDesignationDDLSlice = createSlice({
    name: "DepartmentWiseDesignationDDL",
    initialState: {
        isLoading: false,
        DepartmentWiseDesignationData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DepartmentWiseDesignationDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DepartmentWiseDesignationDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DepartmentWiseDesignationData = action.payload;
        });
        builder.addCase(DepartmentWiseDesignationDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DepartmentWiseDesignationData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const DepartmentWiseDesignationDDLReducer = DepartmentWiseDesignationDDLSlice.reducer



export const ContractorWiseWardSelectDDLAPI = createAsyncThunk("ContractorWiseWardSelectDDL", async ({ data }) => {
    const {
        M_UserID,
        token,
        M_ContractorID,
        M_WorkTypeID,
        M_AssetTypeID,
        M_ZoneID,
        Flag,
        handleData,
        APIChangeFlag,
        Language,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    let url

    if (APIChangeFlag == "APIChangeFlag") {
        // if (M_WorkTypeID != 4) {
        url = `${BaseUrl}/CommonDLLData/Get_DDL_M_ZoneWiseM_ContractorWise_M_Ward_Select?M_ZoneID=${M_ZoneID}&M_ContractorID=${M_ContractorID}&M_WorkTypeID=${M_WorkTypeID}&M_AssetTypeID=${M_AssetTypeID}&M_UserID=${M_UserID}&Language=${Language}`
    } else {
        url = `${BaseUrl}/CommonDLLData/Get_DDL_M_ContractorWiseM_Ward_Select?M_ContractorID=${M_ContractorID}&Flag=${Flag}&M_UserID=${M_UserID}`
    }

    return fetch(url, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data && result.data.table) {
                handleData(result.data.table)
                return result.data.table
            } else {
                return result
            }
        })
})

const ContractorWiseWardSelectDDLSlice = createSlice({
    name: "ContractorWiseWardSelectDDL",
    initialState: {
        isLoading: false,
        WardSelectDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorWiseWardSelectDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ContractorWiseWardSelectDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.WardSelectDDLData = action.payload;
        });
        builder.addCase(ContractorWiseWardSelectDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.WardSelectDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ContractorWiseWardSelectDDLReducer = ContractorWiseWardSelectDDLSlice.reducer

// Daily Work Sheet Designation DDL

export const DailyWorkSheetDesignationDDLAPI = createAsyncThunk("DailyWorkSheetDesignationDDL", async ({ data }) => {
    const {
        M_UserID,
        Flag,
        token } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_Daily_Schedule_M_Designation_Select?Flag=${Flag}&M_UserID=${M_UserID}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DailyWorkSheetDesignationDDLSlice = createSlice({
    name: "DailyWorkSheetDesignationDDL",
    initialState: {
        isLoading: false,
        DailyWorkSheetDesignationData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DailyWorkSheetDesignationDDLAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DailyWorkSheetDesignationDDLAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.DailyWorkSheetDesignationData = action.payload;
        });
        builder.addCase(DailyWorkSheetDesignationDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.DailyWorkSheetDesignationData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const DailyWorkSheetDesignationDDLReducer = DailyWorkSheetDesignationDDLSlice.reducer

// Preventive Work Asset Name DDL

export const PreventiveWorkAssetNameDDLAPI = createAsyncThunk("PreventiveWorkAssetNameDDL", async ({ data }) => {
    const {
        M_ZoneID,
        M_WorkTypeID,
        M_ContractorID,
        M_AssetTypeID,
        M_WardID,
        M_UserID,
        Flag,
        token,
        Language
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_ContractorWise_M_WardWise_M_Asset_Select?M_ZoneID=${M_ZoneID ? M_ZoneID : 0}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : 0}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : 0}&M_ContractorID=${M_ContractorID ? M_ContractorID : 0}&M_WardID=${M_WardID ? M_WardID : 0}&M_UserID=${M_UserID}&Flag=${Flag}&Language=${Language}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const PreventiveWorkAssetNameDDLSlice = createSlice({
    name: "PreventiveWorkAssetNameDDLData",
    initialState: {
        isDDLLoading: false,
        PreventiveWorkAssetNameDDLData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PreventiveWorkAssetNameDDLAPI.pending, (state, action) => {
            state.isDDLLoading = true;
        });
        builder.addCase(PreventiveWorkAssetNameDDLAPI.fulfilled, (state, action) => {
            state.isDDLLoading = false;
            state.PreventiveWorkAssetNameDDLData = action.payload;
        });
        builder.addCase(PreventiveWorkAssetNameDDLAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDDLLoading = false;
            state.isError = true;
            state.PreventiveWorkAssetNameDDLData = null;
            toastErrorr('Something went wrong')
        });
    },
})
export const PreventiveWorkAssetNameDDLDataReducer = PreventiveWorkAssetNameDDLSlice.reducer

