import { useState } from "react"
import Select from 'react-select'
import { useSearchParams } from 'react-router-dom'
import Header from "../../../../../Components/Header/Header"
import Sidebar from "../../../../../Components/Sidebar/Sidebar"
import DeletePopup from "../../GardenPopup/DeletePopup"





export default function CommonForAllAssignWorkMaintainancePageData() {

    const [searchParams] = useSearchParams()
    const [DeletePopupShow, setDeletePopupShow] = useState()

    // let searchNametitle3 = searchParams.get("name")
    let searchNametitle2 = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")


    const [Ward, setWard] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Location, setLocation] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WorkPeriod, setWorkPeriod] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })


    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select Ward",
    // })

    return (
        <div>
            <Header
                DashboardHeading="Water Supply Maintainance" />
            <div className="wrapper">
                <Sidebar />
                <div className="content">
                    <div className="page-header ">
                        <div className="page-block">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    {/* <div className="page-header-title">
                                        <h5 className="ps-2 data_mob">Assigned Work */}

                                    {/* <!-- <span className="float-end">namita</span> --> */}
                                    {/* <span className="float-end fs-6">
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                            :
                                                            setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={SelectWard.DDL}
                                                />

                                            </span> */}
                                    {/* </h5>

                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="data_mob">
                        <div className="row m-1 py-1 pb-4 master_border">
                        <div className='col-9'>
                        <h5 className="py-1">Assigned Work {searchNametitle2}</h5>
                            </div>
                            <div className='col-3 mar_add'>
                                <div className="float-end butt">
                                    <button type="button" className="btn btn-primary ml-1 float-end">Export</button>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-12 py-1 rounded rounded-2 shadow"
                                    style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                    <div className="row rounded2">
                                        <div className="col-md-6 col-12 col-lg-2">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment"><b>From Date :</b></label>
                                                <input type="date"
                                                    className="form-control"
                                                    id="FromDate"
                                                />

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-2">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment"><b>To Date :</b></label>
                                                <input type="date"
                                                    className="form-control"
                                                    id="ToDate"
                                                />

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-3 ">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment"><b>Ward :</b></label>

                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: Ward.ID, label: Ward.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setWard({ ...Ward, ID: e.value, Label: e.label })
                                                            :
                                                            setWard({ ...Ward, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={Ward.DDL}
                                                />

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-6 col-lg-3 " >
                                            <div className="form-group">
                                                <label for=""><b>Location Name :</b></label>
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    // className="ddl_width"
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: Location.ID, label: Location.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setLocation({ ...Location, ID: e.value, Label: e.label })
                                                            :
                                                            setLocation({ ...Location, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={Location.DDL}
                                                />
                                            </div>

                                        </div>

                                        <div className="col-lg-3 col-md-6">
                                            <label for=""><b>Work Period :</b></label>
                                            <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                // className="ddl_width"
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: WorkPeriod.ID, label: WorkPeriod.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setWorkPeriod({ ...WorkPeriod, ID: e.value, Label: e.label })
                                                        :
                                                        setWorkPeriod({ ...WorkPeriod, ID: 0, Label: "Select..." })

                                                }}
                                                options={WorkPeriod.DDL}
                                            />

                                        </div>

                                        <div className="col-12 col-md-6 col-lg-1">
                                            <button type="button" className="btn-clear btn btn-primary mt-lg-4">Clear</button>
                                            {/* <button type="button"  className="btn-search btn btn-primary ml-5">Search</button> */}
                                        </div>
                                    </div>

                                    <div className="row mob_table table-responsive pt-2">

                                        <table id="totalordergenerated"
                                            cellPadding="0"
                                            cellSpacing="0"
                                            border="0"
                                            className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="sr_no_col">Sr No.</th>
                                                    <th>Date</th>
                                                    <th>Work Name</th>
                                                    <th>Work Assigned By</th>
                                                    <th>Before Image</th>
                                                    {
                                                        searchNametitle2 == "Operation Pending" ?
                                                            <>
                                                                <th>Action</th>
                                                            </>
                                                            : ''
                                                    }
                                                    {
                                                        searchNametitle2 == "Operation Completed" ?
                                                            <>
                                                                <th>After Image</th>
                                                            </>
                                                            : ''
                                                    }
                                                    {
                                                        searchNametitle2 == "Electric Pending" ?
                                                            <>
                                                                <th>Action</th>
                                                            </>
                                                            : ''
                                                    }
                                                    {
                                                        searchNametitle2 == "Electric Completed" ?
                                                            <>
                                                                <th>After Image</th>
                                                            </>
                                                            : ''
                                                    }
                                                    {
                                                        searchNametitle2 == "Mechanical Pending" ?
                                                            <>
                                                                <th>Action</th>
                                                            </>
                                                            : ''
                                                    }
                                                    {
                                                        searchNametitle2 == "Mechanical Completed" ?
                                                            <>
                                                                <th>After Image</th>
                                                            </>
                                                            : ''
                                                    }
                                                    {
                                                        searchNametitle2 == "Civil Pending" ?
                                                            <>
                                                                <th>Action</th>
                                                            </>
                                                            : ''
                                                    }
                                                    {
                                                        searchNametitle2 == "Civil Completed" ?
                                                            <>
                                                                <th>After Image</th>
                                                            </>
                                                            : ''
                                                    }
                                                    {/* <th>Action</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "center" }}>1</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="ps-5"><i class="fa-solid fa-eye" title='View' style={{ cursor: 'pointer' }}></i></td>
                                                    {
                                                        searchNametitle2 == "Operation Completed" ?
                                                            <>
                                                                <td class="ps-5"><i class="fa-solid fa-eye" title='View' style={{ cursor: 'pointer' }}></i></td>
                                                            </>
                                                            : ''
                                                    }
                                                    {
                                                        searchNametitle2 == "Electric Completed" ?
                                                            <>
                                                                <td class="ps-5"><i class="fa-solid fa-eye" title='View' style={{ cursor: 'pointer' }}></i></td>
                                                            </>
                                                            : ''
                                                    }
                                                    {
                                                        searchNametitle2 == "Mechanical Completed" ?
                                                            <>
                                                                <td class="ps-5"><i class="fa-solid fa-eye" title='View' style={{ cursor: 'pointer' }}></i></td>
                                                            </>
                                                            : ''
                                                    }
                                                    {
                                                        searchNametitle2 == "Civil Completed" ?
                                                            <>
                                                                <td class="ps-5"><i class="fa-solid fa-eye" title='View' style={{ cursor: 'pointer' }}></i></td>
                                                            </>
                                                            : ''
                                                    }
                                                    {
                                                                        searchNametitle2 == "Operation Pending" || searchNametitle2 == "Electric Pending" || searchNametitle2 == "Mechanical Pending" || searchNametitle2 == "Civil Pending" ?
                                                                            <>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                    <span>
                                                                                        <DeletePopup
                                                                                            open={DeletePopupShow}
                                                                                            // handleDeleteApi={handleDeleteApi}
                                                                                            // item={item}
                                                                                        />
                                                                                    </span>
                                                                                </td>
                                                                            </>
                                                                            : ''
                                                                    }
                                                    {/* <td>
                                                        <DeletePopup
                                                            open={DeletePopupShow}
                                                        // item={item}
                                                        // handleDeleteApi={handleDeleteApi}
                                                        />
                                                    </td> */}

                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>


    )
}