
import { useEffect } from 'react'
import Select from 'react-select'
import { DepartmentDDLAPI, RoadWorkNameDDLAPI, SectorDDLAPI, WardNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'

export const RoadWorkNameDDL = (props) => {
    const { RoadWorkName, setRoadWorkName, CropNameDashboardData, rowData, Flag } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            Flag: Flag,
            M_UserID: UserID,
            token: token
        }
        dispatch(RoadWorkNameDDLAPI({ data }))
    }, [])

    const { RoadWorkNameData } = useSelector(state => state.RoadWorkNameDDLData)

    useEffect(() => {
        handleDepartmentDDL()
    }, [RoadWorkNameData])

    const handleDepartmentDDL = () => {
        // console.log(DeptDDLDataa)
        if (RoadWorkNameData && RoadWorkNameData.table && RoadWorkNameData.table.length > 0) {
            let list = RoadWorkNameData.table.map((item, index) => ({
                value: item.m_rM_WorkID,
                label: item.workName,
            }))

            setRoadWorkName({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_DepartmentID : 0,
                Label: Flag === 'Update' ? rowData.departmentName : "Select...",
            })
        }
        else {
            setRoadWorkName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Work Name</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width"
                isSearchable
                maxMenuHeight={150}
                value={{ value: RoadWorkName.ID, label: RoadWorkName.Label }}
                onChange={(e) => {
                    e ?
                        setRoadWorkName({ ...RoadWorkName, ID: e.value, Label: e.label })
                        :
                        setRoadWorkName({ ...RoadWorkName, ID: 0, Label: "Select..." })

                }}
                options={RoadWorkName.DDL}
            />
        </div>
    )
}