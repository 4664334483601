import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import GardenWorkAssignCard from './GardenWorkAssign'
import { t } from 'i18next'

export default function GardenWorkAssignManage(props) {

    const { Countdata,HeaderMonthID,Language , MonthValue, YearValue , YearLabel,MonthLabel,WardValue,WardLabel,ProjectTypeId,DashboardName } = props
    // console.log(YearValue, YearLabel,MonthValue , MonthLabel)

    const [searchParams] = useSearchParams()
    let Lang = searchParams.get("Lang")

    const navigate = useNavigate()
    const workAssignedNavigate = (name,ApiFlag, screenFlag) => {
            navigate(`/WorkAssignedDMCTable?name=${name}&ApiFlag=${ApiFlag}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)
    }
    return (
        <div className="col-12 col-lg-7 ">
            <div className="row">
                <div className="col-12">
                    <h5 className="ps-2 py-2 pt-3">{t('Dashboard.Titles.Work_Assigned_by_Officials')}</h5>
                </div>
                <div className="col-12 col-lg-6">
                    <GardenWorkAssignCard
                        title={t('AdminDashboard.Admin_Titles.Deputy_Municipal_Commissioner_(DMC)')}
                        ApiFlag="DMC"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/manager.png'
                        count={Countdata.UploadAssignWork_DMC_TotalCount ? Countdata.UploadAssignWork_DMC_TotalCount : '0'}
                        workAssignedNavigate={workAssignedNavigate}
                    />
                </div>


                <div className="col-12 col-lg-6">
                    <GardenWorkAssignCard
                        title={t('AdminDashboard.Admin_Titles.Garden_Officer_(GO / AGO)')}
                        ApiFlag="GO_AGO"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/gardensuperintendent.png'
                        count={Countdata.UploadAssignWork_GO_AGO_TotalCount ? Countdata.UploadAssignWork_GO_AGO_TotalCount : '0'}
                        workAssignedNavigate={workAssignedNavigate}
                    />

                </div>
                <div className="col-12 col-lg-6">
                    <GardenWorkAssignCard
                        title={t('AdminDashboard.Admin_Titles.Garden_Assistant_(GA)')}
                        ApiFlag="GA"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/go.png'
                        count={Countdata.UploadAssignWork_GA_TotalCount ? Countdata.UploadAssignWork_GA_TotalCount : '0'}
                        workAssignedNavigate={workAssignedNavigate}
                    />

                </div>

                <div className="col-12 col-lg-6">
                    <GardenWorkAssignCard
                        title={t('AdminDashboard.Admin_Titles.Garden_Supervisor_(GS)')}
                        ApiFlag="GS"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/businessman.png'
                        count={Countdata.UploadAssignWork_GS_TotalCount ? Countdata.UploadAssignWork_GS_TotalCount : '0'}
                        workAssignedNavigate={workAssignedNavigate}
                    />

                </div>
            </div>
        </div>
    )

}

