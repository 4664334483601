import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";


// Get Table Data
export const EnergyAuditTableDataAPI = createAsyncThunk("EnergyAuditTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_SW_PumpingStationID,
        M_UserID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_SW_T_SW_EnergyAudit_Select?M_WardID=${M_WardID ? M_WardID : '0'}&FromDate=${FromDate ? FromDate : ''}&ToDate=${ToDate ? ToDate : ''}&M_SW_PumpingStationID=${M_SW_PumpingStationID ? M_SW_PumpingStationID : '0'}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const EnergyAuditTableDataSlice = createSlice({
    name: "EnergyAuditTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EnergyAuditTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EnergyAuditTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(EnergyAuditTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const EnergyAuditTableDataReducer = EnergyAuditTableDataSlice.reducer


export const PumpingStationMasterPostDataAPI = createAsyncThunk("PumpingStationPostData", async ({ data }) => {
    const {
        M_SW_PumpingStationWisePumpSetDetailsID,
        M_SW_PumpingStationID,
        PumpSetDetails,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_SW_PumpingStationWisePumpSetDetailsID", M_SW_PumpingStationWisePumpSetDetailsID);
    formdata.append("M_SW_PumpingStationID", M_SW_PumpingStationID);
    formdata.append("PumpSetDetails", PumpSetDetails);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_WEB_M_SW_PumpingStationWisePumpSetDetails_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const PumpingStationMasterPostDataSlice = createSlice({
    name: "PumpingStationMasterPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PumpingStationMasterPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(PumpingStationMasterPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(PumpingStationMasterPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PumpingStationMasterPostDataReducer = PumpingStationMasterPostDataSlice.reducer

// -----------------Delete API ---------------

export const PumpingStationMasterDeleteAPI = createAsyncThunk("PumpingStationMasterDelete", async ({ data }) => {
    const {
        M_SW_PumpingStationWisePumpSetDetailsID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    var formdata = new FormData();
    formdata.append("M_SW_PumpingStationWisePumpSetDetailsID", M_SW_PumpingStationWisePumpSetDetailsID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/WEB_M_SW_PumpingStationWisePumpSetDetails_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const PumpingStationMasterDeleteSlice = createSlice({
    name: "PumpingStationMasterDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(PumpingStationMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(PumpingStationMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(PumpingStationMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const PumpingStationMasterDeleteReducer = PumpingStationMasterDeleteSlice.reducer



export const DailyWorkEnergyAuditDeleteAPI = createAsyncThunk("DailyWorkEnergyAuditDelete", async ({ data }) => {
    const {
        T_SW_EnergyAuditID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    var formdata = new FormData();
    formdata.append("T_SW_EnergyAuditID", T_SW_EnergyAuditID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/WEB_SW_T_SW_EnergyAudit_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const DailyWorkEnergyAuditDeleteSlice = createSlice({
    name: "DailyWorkEnergyAuditDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DailyWorkEnergyAuditDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(DailyWorkEnergyAuditDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(DailyWorkEnergyAuditDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const DailyWorkEnergyAuditDeleteReducer = DailyWorkEnergyAuditDeleteSlice.reducer