import { useEffect, useState } from "react"
import Select from 'react-select'
import Header from "../../../../../Components/Header/Header"
import Sidebar from "../../../../../Components/Sidebar/Sidebar"
import { useSearchParams } from 'react-router-dom'
import moment from "moment";
import DeletePopup from "../../GardenPopup/DeletePopup"
import { useAuthState } from "../../../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { ServicesMasterDeleteAPI, ServicesTableDataAPI } from "../../../../../Redux/SeverageDashboardSlice/ServicesMasterSlice"
import { Pegination } from "../../../../../Components/Pegination/Pegination"
import ViewURL from "../../../../../Components/ViewURL/ViewURL"
import { Loading } from "../../../../../Helper/Loading"
import { WardNameDataDDL } from "../../../../../Components/CommonDDL/WardNameDataDDL"


export default function CommonServiceData({ DataCardName, MainHeading }) {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let M_SW_WorkTypeID = searchParams.get('M_SW_WorkTypeID')
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [FromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsPost, setIsPost] = useState(false)

    const [Heading, setHeading] = useState('')
    const [ImagePopUpData, setImagePopUpData] = useState('')
    const [ShowImagePopup, setshowImagePopup] = useState(false)
    const [IsClear, setIsClear] = useState(false)

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })
    const [PumpingStation, setPumpingStation] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleShowImage = (Imageurl, heading) => {
        setshowImagePopup(true)
        setImagePopUpData(Imageurl)
        setHeading(heading)
    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
        // if (tableData && tableData.table && tableData.table.length > 0) {
        //     setCurrentPage(0)
        //     setFrom(1)
        //     setTo(10)
        // }

    }

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setWardName({
            ...WardName,
            ID: HWard,
            Label: WLable,
        })
        setPumpingStation({
            ...PumpingStation,
            ID: 0,
            Label: 'Select...'
        })
        setFromDate(moment().startOf('month').format('YYYY-MM-DD'))
        setToDate(moment().format('YYYY-MM-DD'))
    }


    useEffect(() => {
        const data = {
            FromDate: FromDate,
            ToDate: ToDate,
            M_WardID: WardName.ID,
            M_SW_WorkTypeID: M_SW_WorkTypeID,
            M_SW_WorkPeriodID: 0,
            M_UserID: UserID,
            Flag: ApiFlag,
            token: token,
            From: From,
            To: To,
        }
        dispatch(ServicesTableDataAPI({ data }))
    }, [IsPost, IsClear, FromDate, ToDate, WardName.ID, To])

    const { tableData, isLoading } = useSelector(state => state.ServicesTableData)

    const handleDeleteApi = (item) => {
        const data = {
            T_SW_RMServicesID: item.t_sW_RMServicesID,
            M_UserID: UserID,
            token: token,
            // Flag: 'Delete',
            handleIsPost: handleIsPost
        }
        dispatch(ServicesMasterDeleteAPI({ data }))
    }
    const { isDeleteLoading } = useSelector(state => state.ServicesMasterDeleteData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [IsPost, WardName.ID])

    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select Ward",
    // })

    return (
        <>
            {isLoading && <Loading />}
            {isDeleteLoading && <Loading />}
            <div>
                <Header
                    // DashboardHeading="Sewerage Maintainance"
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen' 
                    DashboardName={DashboardName} />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId} />
                    <div className="content">
                        <div className="page-header ">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        {/* <div className="page-header-title">
                                        <h5 className="ps-2 data_mob">SERVICES */}

                                        {/* <!-- <span className="float-end">namita</span> --> */}
                                        {/* <span className="float-end fs-6">
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                            :
                                                            setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={SelectWard.DDL}
                                                />

                                            </span> */}
                                        {/* </h5>

                                    </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="data_mob">
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className="col-8">
                                    <h5 className="ps-1 py-1">Services {searchName}</h5>
                                </div>
                                <div className="col-4">
                                    <button type="button" className="btn btn-primary float-end mr-1 mb-1">Export</button>
                                </div>
                                <div className="row m-0 pt-md-1 pt-lg-1">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                        <div className="row rounded2">
                                            <div className="col-md-6 col-12 col-lg-2">
                                                <div className="form-group">
                                                    <label className="d-block" htmlFor="NameofDepartment"> <b>From Date :</b></label>
                                                    <input type="date"
                                                        className="form-control"
                                                        id="FromDate"
                                                        value={FromDate}
                                                        onChange={(e) => (setFromDate(e.target.value), setToDate(''))} />

                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-2">
                                                <div className="form-group">
                                                    <label className="d-block" htmlFor="NameofDepartment"><b>To Date :</b></label>
                                                    <input type="date"
                                                        className="form-control"
                                                        id="ToDate"
                                                        value={ToDate}
                                                        onChange={(e) => setToDate(e.target.value)}
                                                        min={FromDate}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-3 ">
                                                <WardNameDataDDL
                                                    WardName={WardName}
                                                    setWardName={setWardName}
                                                    HWard={HWard}
                                                    WLable={WLable}
                                                />
                                            </div>

                                            {/* <div className="col-12 col-md-6 col-lg-3 " > */}
                                            {/* <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment"><b>Work Type :</b></label>

                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: WorkType.ID, label: WorkType.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setWorkType({ ...WorkType, ID: e.value, Label: e.label })
                                                            :
                                                            setWorkType({ ...WorkType, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={WorkType.DDL}
                                                />

                                            </div> */}

                                            {/* </div> */}

                                            <div className="col-12 col-lg-2 mt-lg-4" >
                                                <button type="button" className="btn-clear btn btn-primary"
                                                    onClick={handleClear} >
                                                    Clear</button>
                                            </div>
                                        </div>

                                        <div className="row mob_table table-responsive pt-2">

                                            <table id="totalordergenerated"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className="sr_no_col">Sr No.</th>
                                                        <th>Area</th>
                                                        <th>Work Period</th>
                                                        <th>Work Name</th>
                                                        <th>Work Date </th>
                                                        <th>Before Photo </th>
                                                        <th>After Photo</th>
                                                        <th>Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                    <td>{item.areaDetails ? item.areaDetails : '-'}</td>
                                                                    <td>{item.m_sW_WorkPeriod ? item.m_sW_WorkPeriod : '-'}</td>
                                                                    <td>{item.workName ? item.workName : '-'}</td>
                                                                    <td>{item.workAssingDate ? moment(item.workAssingDate).format("DD-MM-YYYY") : '-'}</td>
                                                                    {/* <td className="ps-5">
                                                                        <span onClick={() => handleShowImage(item.beforePhoto, 'Before Photo')}>
                                                                            <i className="fa-solid fa-eye" style={{ cursor: 'pointer' }} title='View'></i>
                                                                        </span>
                                                                    </td> */}

                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.beforePhoto == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye" title='View'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => handleShowImage(item.beforePhoto, 'Before Photo')}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>

                                                                    {/* <td className="ps-5">
                                                                        <span onClick={() => handleShowImage(item.afterPhoto, 'After Photo')}>
                                                                            <i className="fa-solid fa-eye" style={{ cursor: 'pointer' }} title='View'></i>
                                                                        </span></td> */}

                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.afterPhoto == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye" title='View'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => handleShowImage(item.afterPhoto, 'After Photo')}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>

                                                                    <td style={{ textAlign: "center" }}>
                                                                        <span>
                                                                            <DeletePopup
                                                                                open={DeletePopupShow}
                                                                                handleDeleteApi={handleDeleteApi}
                                                                                item={item}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                            :
                                                            <>No data found</>
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        {tableData && tableData.table && tableData.table.length > 0 &&
                                            <Pegination
                                                PerPageCount={PerPageCount}
                                                TotalCount={tableData.table[0].totalCount}
                                                setFrom={setFrom}
                                                setTo={setTo}
                                                setrowNo={setrowNo}
                                                CurrentPage={CurrentPage}
                                                setCurrentPage={setCurrentPage}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {
                    ShowImagePopup &&
                    <ViewURL
                        Heading={Heading}
                        ImagePopUpData={ImagePopUpData}
                        setshowImagePopup={setshowImagePopup}
                    />
                }
            </div>
        </>

    )
}