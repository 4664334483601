import Header from "../../../../Components/Header/Header"
import Sidebar from "../../../../Components/Sidebar/Sidebar"
import React, { useEffect, useState } from 'react'
// import Select from 'react-select'
import WorkPopup from "./WorkPopup"
import DeletePopup from "../GardenPopup/DeletePopup"
import { useAuthState } from "../../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { WorkMasterDeleteAPI, WorkMasterExportTableDataAPI, WorkMasterTableDataAPI } from "../../../../Redux/SidebarMasterSlice/WorkMasterSlice"
import { WorkTypeDDL } from "../../../../Components/CommonDDL/WorkTypeDDL"
import { WorkNameDDL } from "../../../../Components/CommonDDL/WorkNameDDL"
import { Pegination } from "../../../../Components/Pegination/Pegination"
import { Loading } from "../../../../Helper/Loading"
import { WorkSubTypeDDL } from "../../../../Components/CommonDDL/WorkSubTypeDDL"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { WorkExportData } from "./WorkExportData"

export const Work = () => {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    const [WorkPopupShow, setWorkPopupShow] = useState()
    const [DeletePopupShow, setDeletePopupShow] = useState()

    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsPost, setIsPost] = useState(false)
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')
    const [IsClear, setIsClear] = useState(false)
    const [isSearch, setIsSearch] = useState(false)

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setWorkPopupShow(true)
    }
    const handleEditOnClick = (item) => {
        setWorkPopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }

    const handleOnCloseClick = () => {
        setWorkPopupShow(false)
    }

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WorkSubType, setWorkSubType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WorkName, setWorkName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])


    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    const handleClear = () => {
        setTo(10)
        setFrom(1)
        setIsClear(!IsClear)
        setCurrentPage(0)
        setWorkType({
            ...WorkType,
            ID: 0,
            Label: 'Select...'
        })
        setWorkName({
            ...WorkName,
            ID: 0,
            Label: 'Select...'
        })
        setWorkSubType({
            ...WorkSubType,
            ID: 0,
            Label: 'Select...'
        })
    }

    const handleSearch = () => {
        setCurrentPage(0)
        setTo(10)
        setFrom(1)
        setIsSearch(!isSearch)
    }

    // useEffect(() => {
    //     setCurrentPage(0)
    //     setFrom(1)
    //     setTo(10)
    // }, [WorkType.ID, WorkName.ID, WorkSubType.ID])


    useEffect(() => {
        const data = {
            M_WorkID: WorkName.ID,
            M_WorkTypeID: WorkType.ID,
            M_WorkSubTypeID: WorkSubType.ID,
            M_WorkName: '',
            M_UsersID: UserID,
            token: token,
            From: From,
            To: To,
            Language: Language.Label
        }
        { Language.Label != '' && dispatch(WorkMasterTableDataAPI({ data })) }
    }, [To, IsPost, isSearch, IsClear, Language.Label])

    const { tableData, isLoading } = useSelector(state => state.WorkMasterTableData)

    useEffect(() => {
        const data = {
            M_WorkID: WorkName.ID,
            M_WorkTypeID: WorkType.ID,
            M_WorkSubTypeID: WorkSubType.ID,
            M_WorkName: '',
            M_UsersID: UserID,
            token: token,
            From: From,
            To: '99999',
            Language: Language.Label
        }
        { Language.Label != '' && dispatch(WorkMasterExportTableDataAPI({ data })) }
    }, [To, IsPost, isSearch, IsClear, Language.Label])

    const { ExportTableData, isExportLoading } = useSelector(state => state.WorkMasterExportTableData)

    const handleDeleteApi = (item) => {
        const data = {
            M_WorkId: item.m_WorkID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost
        }
        dispatch(WorkMasterDeleteAPI({ data }))
    }

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    // DashboardHeading="Garden Maintainance"
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper p-3">
                    <Sidebar
                        Language={Language}
                        ProjectTypeId={ProjectTypeId} />
                    <div className="content">
                        <div className="data_mob">
                            <div className="row  m-1 py-1 pb-4 master_border">
                                <div className="d-flex">
                                    <h5 className="ps-1 py-2 ">{t('AdminDashboard.Admin_Sidebar_Name.Work')}</h5>
                                    {/* <div className="col-12 col-lg-7 float-end">
                                        <i className="fa-solid fa-plus btn btn-secondary mb-2 float-end"
                                            onClick={handleAddOnClick} style={{ color: "white", fontSize: '15px', marginTop: '-2.5em' }}>
                                            <text className="ml-2 poppins">Add</text>
                                        </i>
                                    </div> */}
                                    <div className="col-12 col-lg-11 ml-lg-5">
                                        <i className="fa-solid fa-plus btn btn-secondary float-end mb-2" onClick={handleAddOnClick} style={{ color: "white" }}
                                        >
                                            <text className="ml-2 poppins">Add</text>
                                        </i>
                                        {
                                            isExportLoading ?
                                                <button type="button" className="btn btn-primary float-end mr-1 mb-1 ml-1" disabled>
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px" }}
                                                    />Loading</button>
                                                :
                                                ExportTableData && ExportTableData.table && ExportTableData.table.length > 0 &&
                                                <WorkExportData
                                                    ExcelData={ExportTableData}
                                                    name={t('AdminDashboard.Admin_Sidebar_Name.Work')}
                                                // searchName={searchName}
                                                />
                                        }
                                    </div>
                                </div>
                                <div className="row m-0 ">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12  ">

                                            <div className="row rounded2">

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WorkTypeDDL
                                                        WorkType={WorkType}
                                                        setWorkType={setWorkType}
                                                        Language={Language}
                                                    />


                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WorkSubTypeDDL
                                                        WorkSubType={WorkSubType}
                                                        setWorkSubType={setWorkSubType}
                                                        WorkType={WorkType}
                                                        Language={Language}
                                                    />

                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WorkNameDDL
                                                        WorkName={WorkName}
                                                        setWorkName={setWorkName}
                                                        WorkType={WorkType}
                                                        WorkSubType={WorkSubType}
                                                        IsPost={IsPost}
                                                        Language={Language}
                                                    />
                                                </div>
                                                {/* <div className="col-12 col-lg-2 col-md-2 mt-lg-4 mt-md-4" >
                                                    <button type="button" style={{ float: 'left' }} className="btn-clear btn btn-primary"
                                                        onClick={handleClear}
                                                    >Clear</button>
                                                </div> */}

                                                <div className="col-12 col-lg-3 col-md-4 mt-md-4 d-md-flex" >
                                                    <button type="button"
                                                        className="btn-clear btn btn-primary mx-2"
                                                        onClick={handleSearch}
                                                    >
                                                        Search</button>
                                                    <button type="button"
                                                        className="btn btn-outline-danger"
                                                        onClick={handleClear}
                                                    >
                                                        Clear</button>
                                                </div>

                                            </div>
                                            <div className="row mob_table table-responsive pt-1">

                                                <table id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            <th>Work Type</th>
                                                            <th>Work Sub Type</th>
                                                            <th>Work Name</th>
                                                            <th>Benchmark</th>
                                                            <th>Benchmark in <br /> Days</th>
                                                            <th>Penalty Amount <br /> (Rs.)</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* style={{width:'10%',whiteSpace:'nowrap',wordBreak:'break-all'}} */}
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => (
                                                                <tr style={{ width: '100%' }}>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum}</td>
                                                                    <td>{item.workTypeName ? item.workTypeName : '-'}</td>
                                                                    <td>{item.workSubTypeName ? item.workSubTypeName : '-'}</td>
                                                                    <td>{item.workname ? item.workname : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>{item.benchMark ? item.benchMark : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>{item.benchMarkinDays ? item.benchMarkinDays : '-'}</td>
                                                                    <td style={{ textAlign: "right" }}>{item.penaltyAmount ? (item.penaltyAmount).toFixed(2) : '0'}</td>
                                                                    <td style={{ textAlign: "center", width: '7%' }}>
                                                                        <span>
                                                                            <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => handleEditOnClick(item)}
                                                                            >
                                                                            </i>
                                                                        </span>

                                                                        <DeletePopup
                                                                            open={DeletePopupShow}
                                                                            item={item}
                                                                            handleDeleteApi={handleDeleteApi}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))
                                                                :
                                                                <></>
                                                        }


                                                    </tbody>
                                                </table>

                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {
                    WorkPopupShow ?
                        <WorkPopup
                            open={WorkPopupShow}
                            // popupHeading="Location Name"                         
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                            Language={Language}
                        />
                        : <></>
                }

            </div>
        </>
    )
}