
import Select from 'react-select'
import React, { useEffect, useState } from 'react'

import PipeLineMasterPagePopup from './PipelineMasterPagePopup'
import Header from '../../../../../../Components/Header/Header'
import Sidebar from '../../../../../../Components/Sidebar/Sidebar'
import DeletePopup from '../../../GardenPopup/DeletePopup'
import { useAuthState } from '../../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { WaterSupplyPipelineMasterDeleteAPI, WaterSupplyPipelineTableDataAPI } from '../../../../../../Redux/WaterSupplyDashBoardSlice/WaterSupplyMasterSlice/WaterSupplyPipelineSlice'
import { Loading } from '../../../../../../Helper/Loading'
import { useSearchParams } from 'react-router-dom'

export default function PipelineMasterPage() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [PipelineMasterPopupShow, setPipelineMasterPopupShow] = useState()
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')
    const [CurrentPage, setCurrentPage] = useState(0)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [rowNo, setrowNo] = useState(1)
    const [FromTop, setFromTop] = useState(1)
    const [ToTop, setToTop] = useState(10)

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")

    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    const [Location, setLocation] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Pipeline, setPipeline] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setPipelineMasterPopupShow(true)
    }

    const handleEditOnClick = () => {
        setPipelineMasterPopupShow(true)
        setapiFlag('Update')
        setrowData()
    }
    const handleOnCloseClick = () => {
        setPipelineMasterPopupShow(false)
    }

    useEffect(() => {
        const data = {
            M_WS_PipeLineID: Pipeline.ID,
            M_WS_JunctionID: Location.ID,
            PipelineDetails: '',
            token: token,
            FromTop: FromTop,
            ToTop: ToTop,
            M_UserID: 1,
            Flag: ApiFlag,
        }
        // if (WardName.ID !== 0) {
        dispatch(WaterSupplyPipelineTableDataAPI({ data }))
        // }
    }, [ToTop, Location.ID, IsPost, Pipeline.ID, FromTop])

    const { tableData, isLoading } = useSelector(state => state.WaterSupplyPipelineTableData)

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    const handleDeleteApi = (item) => {
        const data = {
            M_WS_PipeLineID: item.m_wS_PipeLineID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost
        }
        dispatch(WaterSupplyPipelineMasterDeleteAPI({ data }))
    }
    const { isDeleteLoading } = useSelector(state => state.WaterSupplyPipelineMasterDelete)

    return (
        <div>
            {isLoading && <Loading />}
            {/* {isDeleteLoading && <Loading />} */}
            <Header
               MLable={MLable}
               HMonth={HMonth}
               YLable={YLable}
               HYear={HYear}
               HWard={HWard}
               WLable={WLable}
               flag='Screen'
               DashboardName={DashboardName}
            />
            <div className="wrapper">
                <Sidebar ProjectTypeId={ProjectTypeId} />
                <div className="content">
                    {/* <!-- [ breadcrumb ] start --> */}
                    <div className="page-header ">
                        <div className="page-block">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    {/* <div className="page-header-title">
                                        <h5 className="ps-2 data_mob">MASTER */}

                                    {/* <span className="float-end fs-6">
                                                <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                        :
                                                        setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                }}
                                                options={SelectWard.DDL}
                                            />

                                            </span> */}
                                    {/* </h5>

                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- [ Main Content ] start --> */}
                    <div className='data_mob'>
                        <div className="row m-1 pb-4 master_border">
                            <div className='col-5'>
                                <h5 className="mt-2">Pipeline</h5>
                            </div>
                            <div className='col-7 mar_add'>
                                <div className="float-end butt">
                                    <button type="button" className="btn btn-primary ml-1 float-end">Export</button>
                                    <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                                        style={{ color: "white" }}
                                        onClick={handleAddOnClick}
                                    > Add</i>
                                </div>
                            </div>
                            <div className="row m-0 ">
                                <div className="col-12 py-0 rounded rounded-2 shadow"
                                    style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                    <div className="col-12">

                                        <div className="row py-1 rounded2">
                                            <div className="col-lg-3 col-md-6">
                                                <label for=""><b>Junction :</b></label>
                                                <Select
                                                    isSearchable
                                                    isClearable
                                                    maxMenuHeight={150}
                                                    value={{ value: Location.ID, label: Location.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setLocation({ ...Location, ID: e.value, Label: e.label })
                                                            :
                                                            setLocation({ ...Location, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={Location.DDL}
                                                />

                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <label for=""><b>Pipeline :</b></label>
                                                <Select
                                                    isSearchable
                                                    isClearable
                                                    maxMenuHeight={150}
                                                    value={{ value: Pipeline.ID, label: Pipeline.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setPipeline({ ...Pipeline, ID: e.value, Label: e.label })
                                                            :
                                                            setPipeline({ ...Pipeline, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={Pipeline.DDL}
                                                />

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-1 mt-lg-4 mt-md-4 mt-1" >
                                                <button type="button"
                                                    className="btn-clear btn btn-primary"
                                                // onClick={handleClear}
                                                >
                                                    Clear</button>

                                            </div>

                                        </div>
                                        <div className="row mob_table table-responsive pt-1">

                                            <table id="totalordergenerated"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                className="table table-bordered">

                                                <thead>
                                                    <tr>
                                                        <th className="sr_no_col">Sr No.</th>
                                                        <th>Location Name</th>
                                                        <th>Pipeline Details</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) =>
                                                            <tr>
                                                                <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                <td>{item.junctionDetails ? item.junctionDetails : '-'}</td>
                                                                <td>{item.pipelineDetails ? item.pipelineDetails : '-'}</td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <span>
                                                                        <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => handleEditOnClick()}
                                                                        >
                                                                        </i>
                                                                    </span>
                                                                    <span>
                                                                        <DeletePopup
                                                                            open={DeletePopupShow}
                                                                            item={item}
                                                                            handleDeleteApi={handleDeleteApi}
                                                                        />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        ) :
                                                            <>No Found data</>
                                                    }

                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    PipelineMasterPopupShow ?
                        <PipeLineMasterPagePopup
                            open={PipelineMasterPopupShow}
                            popupHeading="Pipe Line"
                            // handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                        />
                        : <></>
                }
            </div>
        </div>



    )
}