
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { CommContractorDDLAPI } from '../../Redux/DDLSlice'

export const ElectricalContractorNameHeaderDDL = (props) => {
    const { ElectricalContractorHeaderDDL, setElectricalContractorHeaderDDL, ZoneValue, Language, flag, YearValue, MonthValue, WardValue, Flag, ElectricalDDlData, setElectricalDDlData, } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()


    // const d = new Date();
    // let month = d.getMonth();

    // const [MonthID, setMonthID] = useState(month + 1)


    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_MonthID: MonthValue,
            M_UserID: UserID,
            Flag: Flag,
            M_WardID: WardValue,
            M_ZoneID: ZoneValue,
            token,
            Language: Language.Label,
            setElectricalDDlData: setElectricalDDlData,
        }
        { Language.Label != ''  && YearValue && ZoneValue && dispatch(CommContractorDDLAPI({ data })) }
    }, [YearValue, MonthValue, WardValue, ZoneValue, Language.Label])

    const { DDLData } = useSelector(state => state.CommContractorDDLData)

    useEffect(() => {
        handleDDLData()
    }, [DDLData])

    const handleDDLData = () => {
        if (ElectricalDDlData && ElectricalDDlData.length > 0) {
            let list = ElectricalDDlData.map((item, index) => ({
                value: item.m_ContractorID,
                label: item.contractorName,
            }))

            setElectricalContractorHeaderDDL({
                DDL: list,
                ID: ElectricalDDlData ? ElectricalDDlData[0].m_ContractorID : 0,
                Label: ElectricalDDlData ? ElectricalDDlData[0].contractorName : 'Select...',
            })
        }
    }

    return (

        <>
            <div className='row'>
                <div className='col-4 pt-2 text-end'>


                    <label className="d-block" htmlFor="NameofEmployee" style={{ color: "white" }}><b>Contractor Name :</b></label>
                </div>
                <div className='col-8'>
                    <div className="form-group ">
                        <Select
                            isClearable
                            // isRtl={isRtl}
                            // className="ddl_width3"
                            isSearchable
                            maxMenuHeight={150}
                            value={{ value: ElectricalContractorHeaderDDL.ID, label: ElectricalContractorHeaderDDL.Label }}
                            onChange={(e) => {
                                e ?
                                    setElectricalContractorHeaderDDL({ ...ElectricalContractorHeaderDDL, ID: e.value, Label: e.label })
                                    :
                                    setElectricalContractorHeaderDDL({ ...ElectricalContractorHeaderDDL, ID: ElectricalDDlData ? ElectricalDDlData[0].m_ContractorID : 0, Label: ElectricalDDlData ? ElectricalDDlData[0].contractorName : 'Select...' })

                            }}
                            options={ElectricalContractorHeaderDDL.DDL}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}