

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";

// Get Table Data
export const CommWorkReopenCountDataAPI = createAsyncThunk("CommWorkReopenCountData", async ({ data }) => {
    const {
        M_FinancialYearID,
        M_MonthID,
        M_WardID,
        M_UsersID,
        Flag,
        M_ContractorID,
        token,
        setCountData,
        setGraphLable,
        M_ZoneID,
        Language
    } = data

    var lables = []
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_UploadReopenProgressWorkByContractorsCount_Select?M_FinancialYearID=${M_FinancialYearID}&M_MonthID=${M_MonthID}&M_WardID=${M_WardID}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&M_UsersID=${M_UsersID}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&Flag=${Flag}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setCountData(result.data.table)
                for (var i = 0; i < result.data.table.length; i++) {
                    lables.push(result.data.table[i].benchMark)
                    // console.log("name", lables)
                    setGraphLable(lables)
                }
                return result.data.table
            } else {
                return result.data
            }
        })
})

const CommWorkReopenCountDataAPISlice = createSlice({
    name: "CommWorkReopenCountData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommWorkReopenCountDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CommWorkReopenCountDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(CommWorkReopenCountDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CommWorkReopenCountDataAPIReducer = CommWorkReopenCountDataAPISlice.reducer


// Work Reopen Get API
export const CommWorkReopenGetDataAPI = createAsyncThunk("CommWorkReopenGetData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_AssetID,
        M_AssetTypeID,
        M_GD_WorkPeriodID,
        ShowBy,
        From,
        To,
        M_WardID,
        M_UsersID,
        Flag,
        M_ContractorID,
        M_ZoneID,
        token,
        Language
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_UploadReopenProgressWorkByContractorsDetails_Select?FromDate=${FromDate}&ToDate=${ToDate}&M_WardID=${M_WardID}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&M_AssetID=${M_AssetID}&M_AssetTypeID=${M_AssetTypeID}&M_GD_WorkPeriodID=${M_GD_WorkPeriodID}&M_ContractorID=${M_ContractorID}&M_UsersID=${M_UsersID}&Flag=${Flag}&ShowBy=${ShowBy}&From=${From}&To=${To}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const CommWorkReopenGetDataAPISlice = createSlice({
    name: "CommWorkReopenGetData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommWorkReopenGetDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CommWorkReopenGetDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(CommWorkReopenGetDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CommWorkReopenGetDataAPIReducer = CommWorkReopenGetDataAPISlice.reducer