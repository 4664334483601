import Header from "../../../../Components/Header/Header"
import Sidebar from "../../../../Components/Sidebar/Sidebar"
import React, { useEffect, useState } from 'react'
import { useAuthState } from "../../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { Loading } from "../../../../Helper/Loading"
import { Pegination } from "../../../../Components/Pegination/Pegination"
import { useSearchParams } from "react-router-dom"
import { AssetTypeDataDDL } from "../../../../Components/CommonDDL/AssetTypeDataDDL"
import { WardNameDataDDL } from "../../../../Components/CommonDDL/WardNameDataDDL"
import ContractorWiseAssetsPopup from "./ContractorWiseAssetsPopup"
import { ContractorWiseAssetTableDataAPI, ContractorWiseAssetTableExportDataAPI } from "../../../../Redux/GardenDashboardSlice/ContractorWiseAssetSlice.js/ContractorWiseAssetSlice"
import { useTranslation } from "react-i18next"
import { ZoneDataDDLS } from "../../../../Components/CommonDDL/ZoneDataDDLS"
import { WorkTypeDDL } from "../../../../Components/CommonDDL/WorkTypeDDL"
import { ContractorWiseAssetsExportData } from "./ContractorWiseAssetsExportData"
import { WardWiseAssetNameDataDDL } from "../../../../Components/CommonDDL/WardWiseAssetDDL"

export const ContractorWiseAssets = () => {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let Lang = searchParams.get("Lang")

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [isSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [DepartmentPopupShow, setDepartmentPopupShow] = useState(false)
    const [DeletePopupShow, setDeletePopupShow] = useState(false)
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')

    const [ContractorWiseAssetsPopupPopupShow, setContractorWiseAssetsPopupPopupShow] = useState()

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setContractorWiseAssetsPopupPopupShow(true)
    }

    const handleOnCloseClick = () => {
        setContractorWiseAssetsPopupPopupShow(false)
    }
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ZoneName, setZoneName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ProjectName, setProjectName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ContractorName, setContractorName] = useState({
        DDL: [],
        // ID: ContractorID && ContractorID ? ContractorID : 0,
        // Label: ContractorLabel && ContractorLabel ? ContractorLabel : "Select...",
        ID: 0,
        Label: "Select...",

    })

    const [WardWiseAssetName, setWardWiseAssetName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [AssetName, setAssetName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    // const handleDeleteOnClick = () => {
    //     console.log("delete")
    //     setDeletePopupShow(true)
    // }

    const handleIsPost = () => {
        setIsPost(!IsPost)
        // if (tableData && tableData.table && tableData.table.length > 0) {
        //     setCurrentPage(0)
        //     setFrom(1)
        //     setTo(10)
        // }
    }

    // useEffect(() => {
    //     setCurrentPage(0)
    //     setFrom(1)
    //     setTo(10)
    // }, [ContractorName.ID])

    // const handleDeleteApi = (item) => {
    //     const data = {
    //         M_ContractorID: item.m_ContractorID,
    //         M_UserID: UserID,
    //         token: token,
    //         handleIsPost: handleIsPost
    //     }
    //     dispatch(ContractorNameDeleteAPI({ data }))
    // }
    // const { isDeleteLoading } = useSelector(state => state.DepDeleteData)


    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setProjectName({
            ...ProjectName,
            ID: 0,
            Label: 'Select...'
        })
        setAssetType({
            ...AssetType,
            ID: 0,
            Label: 'Select...'
        })
        setWardWiseAssetName({
            ...WardWiseAssetName,
            ID: 0,
            Label: 'Select...'
        })
        setWardName({
            ...WardName,
            ID: 0,
            Label: 'Select...'
        })
        setZoneName({
            ...ZoneName,
            ID: 0,
            Label: 'Select...'
        })
        setWorkType({
            ...WorkType,
            ID: 0,
            Label: 'Select...'
        })
        handleIsPost()
    }

    const handleSearch = () => {
        setCurrentPage(0)
        setTo(10)
        setFrom(1)
        setIsSearch(!isSearch)
    }

    useEffect(() => {
        const data = {
            M_ZoneID: ZoneName.ID,
            M_WardID: WardName.ID,
            M_AssetTypeID: AssetType.ID,
            M_AssetID: WardWiseAssetName.ID,
            M_ContractorID: ContractorName.ID,
            M_WorkTypeID: WorkType.ID,
            M_UsersID: UserID,
            token: token,
            From: From,
            To: To,
            Language: Language.Label
        }
        { Language.Label != '' && dispatch(ContractorWiseAssetTableDataAPI({ data })) }
    }, [IsPost, To, IsClear, isSearch, Language.Label])

    const { tableData, isLoading } = useSelector(state => state.ContractorWiseAssetTableData)

    useEffect(() => {
        const data = {
            M_ZoneID: ZoneName.ID,
            M_WardID: WardName.ID,
            M_AssetTypeID: AssetType.ID,
            M_AssetID: AssetName.ID,
            M_ContractorID: ContractorName.ID,
            M_WorkTypeID: WorkType.ID,
            M_UsersID: UserID,
            token: token,
            From: From,
            To: To,
            Language: Language.Label
        }
        { Language.Label != '' && dispatch(ContractorWiseAssetTableExportDataAPI({ data })) }
    }, [IsPost, To, IsClear, isSearch, Language.Label])

    const { ExportTableData, isExportLoading } = useSelector(state => state.ContractorWiseAssetTableExportData)


    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    // DashboardHeading="Garden Maintainance"
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper p-3">
                    <Sidebar
                        Language={Language}
                        ProjectTypeId={ProjectTypeId} />
                    <div id="" className="content1">
                        <div className="data_mob">
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div>
                                    <h5 className="ps-1 py-1">{t('AdminDashboard.Admin_Sidebar_Name.Contractor_Wise_Assets')}</h5>
                                    <div className="float-end butt">
                                        <button className="fa-solid fa-plus float-end btn btn-secondary"
                                            style={{ color: "white" }}
                                            onClick={handleAddOnClick}
                                        >
                                            <text className="ml-2 poppins">Add</text>
                                        </button>
                                    </div>
                                    <div className="float-end butt">
                                        {
                                            isExportLoading ?
                                                <button type="button" className="btn btn-primary float-end mr-1 mb-1 ml-1" disabled>
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px" }}
                                                    />Loading</button>
                                                :
                                                ExportTableData && ExportTableData.table && ExportTableData.table.length > 0 &&
                                                <ContractorWiseAssetsExportData
                                                    ExcelData={ExportTableData}
                                                    name='Contractor Wise Asset'
                                                // searchName={searchName}
                                                />
                                        }
                                    </div>
                                </div>
                                <div className="row m-0 ">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12">
                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <ZoneDataDDLS
                                                        ZoneName={ZoneName}
                                                        setZoneName={setZoneName}
                                                        Language={Language}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <WardNameDataDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        HWard={HWard}
                                                        WLable={WLable}
                                                        Language={Language}
                                                        ZoneName={ZoneName}
                                                        Flag='ward'
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <AssetTypeDataDDL
                                                        AssetType={AssetType}
                                                        setAssetType={setAssetType}
                                                        Language={Language}
                                                    />
                                                </div>
                                               
                                                <div className="col-12 col-md-6 col-lg-4">
                                                     <WardWiseAssetNameDataDDL
                                                        WardWiseAssetName={WardWiseAssetName}
                                                        setWardWiseAssetName={setWardWiseAssetName}
                                                        WardName={WardName}
                                                        AssetType={AssetType}
                                                        M_WorkTypeID='0'
                                                        M_ZoneID={ZoneName}
                                                        M_RoleID={RoleID}
                                                        M_GD_WorkPeriodID='0'
                                                        // M_AssetTypeID={1}
                                                        Language={Language}
                                                        Flag='Master'
                                                    />
                                                </div>
                                               
                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <WorkTypeDDL
                                                        WorkType={WorkType}
                                                        setWorkType={setWorkType}
                                                        IsPost={IsPost}
                                                        Language={Language}
                                                    />

                                                </div>
                                                <div className="col-12 col-lg-3 col-md-4 mt-md-4 d-md-flex" >
                                                    <button type="button"
                                                        className="btn-clear btn btn-primary mx-2"
                                                        onClick={handleSearch}
                                                    >
                                                        Search</button>
                                                    <button type="button"
                                                        className="btn btn-outline-danger"
                                                        onClick={handleClear}
                                                    >
                                                        Clear</button>
                                                </div>
                                            </div>
                                            <div className="row mob_table table-responsive pt-1">

                                                <table id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            <th>Ward No </th>
                                                            <th>Asset Type</th>
                                                            <th>Asset name </th>
                                                            <th>Work Type</th>
                                                            <th>Contractor Name </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => (
                                                                <tr style={{ width: '100%' }}>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum}</td>
                                                                    <td>{item.wardName ? item.wardName : '-'}</td>
                                                                    <td>{item.assetTypeName ? item.assetTypeName : '-'}</td>
                                                                    <td>{item.assetName ? item.assetName : '-'}</td>
                                                                    <td>{item.workTypeName ? item.workTypeName : '-'}</td>
                                                                    <td>{item.contractorName ? item.contractorName : '-'}</td>
                                                                </tr>
                                                            ))
                                                                :
                                                                <>No data found</>
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    ContractorWiseAssetsPopupPopupShow ?
                        <ContractorWiseAssetsPopup
                            open={ContractorWiseAssetsPopupPopupShow}
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                        />
                        : <></>
                }
            </div>
        </>
    )
}