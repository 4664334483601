
import { useEffect } from 'react'
import Select from 'react-select'
import { DailyWorkSheetDesignationDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'

export const DailyWorkSheetDesignationDDL = (props) => {
    const { DailyWorkSheetDesignation, setDailyWorkSheetDesignation, rowData, Flag, IsPost,DDLFlag } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_UserID: UserID,
            Flag: DDLFlag,
            token: token,
        }
        dispatch(DailyWorkSheetDesignationDDLAPI({ data }))
    }, [IsPost])

    const { DailyWorkSheetDesignationData } = useSelector(state => state.DailyWorkSheetDesignationDDLData)

    useEffect(() => {
        handleDesignationDDL()
    }, [DailyWorkSheetDesignationData])

    const handleDesignationDDL = () => {
        if (DailyWorkSheetDesignationData && DailyWorkSheetDesignationData.table && DailyWorkSheetDesignationData.table.length > 0) {
            let list = DailyWorkSheetDesignationData.table.map((item, index) => ({
                value: item.m_DesignationID,
                label: item.designationName,
            }))

            setDailyWorkSheetDesignation({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_DesignationID : 0,
                Label: Flag === 'Update' ? rowData.designationName : "Select...",
            })
            // setDailyWorkSheetDesignation({ ...Designation, DDL: list })
        }
        else {
            setDailyWorkSheetDesignation({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }
    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b> {t('AdminDashboard.Admin_Sidebar_Name.Designation')} {Flag && <AstricSign />}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width"
                isSearchable
                maxMenuHeight={150}
                value={{ value: DailyWorkSheetDesignation.ID, label: DailyWorkSheetDesignation.Label }}
                onChange={(e) => {
                    e ?
                        setDailyWorkSheetDesignation({ ...DailyWorkSheetDesignation, ID: e.value, Label: e.label })
                        :
                        setDailyWorkSheetDesignation({ ...DailyWorkSheetDesignation, ID: 0, Label: "Select..." })

                }}
                options={DailyWorkSheetDesignation.DDL}
            />
        </div>
    )
}