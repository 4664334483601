
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import Header from '../../../../../Components/Header/Header'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { useSearchParams } from 'react-router-dom'
import { FinancialYearDataDDL } from '../../../../../Components/CommonDDL/FinancialYearDataDDL'
import { MonthDataDDL } from '../../../../../Components/CommonDDL/MonthDataDDL'
import { WardNameDataDDL } from '../../../../../Components/CommonDDL/WardNameDataDDL'
import { AssetTypeDataDDL } from '../../../../../Components/CommonDDL/AssetTypeDataDDL'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { WorkAssignedTableDataAPI } from '../../../../../Redux/GardenDashboardSlice/WorkAssignedSlice'
import moment from "moment";
import { Loading } from '../../../../../Helper/Loading'
import ViewURL from '../../../../../Components/ViewURL/ViewURL'
import { Pegination } from '../../../../../Components/Pegination/Pegination'
import { WorkAssignByDDL } from '../../../../../Components/CommonDDL/WorkAssignByDDL'
import { DashBoardScreenWardDDL } from '../../../../../Components/CommonDDL/DashboardScreenWardDDL'

export default function WorkAssignedDMCTable() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let FlagName = searchParams.get("Flag")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: HYear && HYear ? HYear : '0',
        Label: YLable && YLable ? YLable : "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: HMonth && HMonth ? HMonth : '0',
        Label: MLable && MLable ? MLable : "Select...",

    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })
    const [WorkAssignedBy, setWorkAssignedBy] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })
    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])


    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [YearValue, setYearValue] = useState(0)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [Heading, setHeading] = React.useState(false)
    const [FromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))


    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setFromDate(moment().startOf('month').format('YYYY-MM-DD'))
        setToDate(moment().format('YYYY-MM-DD'))
        setWardName({
            ...WardName,
            ID: WardName.DDL[0].value,
            Label: WardName.DDL[0].label,
        })
        setAssetType({
            ...AssetType,
            ID: 0,
            Label: 'Select...',
        })
        setWorkAssignedBy({
            ...WorkAssignedBy,
            ID: 0,
            Label: 'Select...',
        })

        // console.log("call")
    }

    const handlePost = () => {
        setIsPost(!IsPost)
    }

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [WardName.ID])


    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }
    useEffect(() => {
        // console.log(FinancialYear, FinancialYearID, FinancialYearLabel)
        const data = {
            // FinancialYearID: YearValue,
            FromDate: FromDate,
            ToDate: ToDate,
            M_WardID: WardName.ID,
            M_AssetTypeID: AssetType.ID,
            WorkAssignByM_UserID: WorkAssignedBy.ID,
            M_UsersID: UserID,
            token: token,
            From: From,
            To: To,
            Flag: ApiFlag,

        }
        // if (WardName.ID) {
            dispatch(WorkAssignedTableDataAPI({ data }))
        // }

    }, [IsPost, To, IsClear, WardName.ID, AssetType.ID, WorkAssignedBy.ID, FromDate, ToDate])

    const { tableData, isLoading } = useSelector(state => state.WorkAssignedTableData)

    // useEffect(() => {
    //     setCurrentPage(0)
    //     setFrom(1)
    //     setTo(10)
    // }, [WardName.ID, FromDate, ToDate, AssetType.ID])

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    DashboardHeading="Garden Maintainance"
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId} Language={Language} />
                    <div id="" className='content1'>
                        <div className='data_mob'>
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className='col-8'>
                                    <h5 className="ps-1 pt-1">WORK ASSIGNED / {searchName}</h5>
                                </div>
                                <div className='col-4'>
                                    <button type="button" className="btn btn-primary float-end mr-1">Export</button>
                                </div>

                                <div className="row m-0 mt-1">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12 ">
                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block fw-bold" htmlFor="NameofDepartment">From Date :</label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="FromDate"
                                                            value={FromDate}
                                                            onChange={(e) => (setFromDate(e.target.value), setToDate(''))} />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block fw-bold" htmlFor="NameofDepartment">To Date :</label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="ToDate"
                                                            value={ToDate}
                                                            onChange={(e) => setToDate(e.target.value)} />

                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-3">
                                                    {/* <WardNameDataDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        HWard={HWard}
                                                        WLable={WLable}
                                                        Language={Lang}
                                                    /> */}

                                                    <DashBoardScreenWardDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        WLable={WLable}
                                                        HWard={HWard}
                                                        // flag={flag}
                                                        Language={Language}
                                                        M_ZoneID={1}
                                                        Flag='ward'
                                                    />
                                                </div>
                                                {
                                                    ApiFlag === 'WorkAssingPending' || ApiFlag === 'WorkAssingCompleted' ||
                                                        searchName === 'Reopen Pending' || searchName === 'Reopen Completed'

                                                        ?
                                                        <div className="col-12 col-md-6 col-lg-3">
                                                            <WorkAssignByDDL
                                                                WorkAssignedBy={WorkAssignedBy}
                                                                setWorkAssignedBy={setWorkAssignedBy}
                                                                Flag={FlagName}
                                                                Language={Language}
                                                            />
                                                        </div>
                                                        : <></>
                                                }


                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <AssetTypeDataDDL
                                                        AssetType={AssetType}
                                                        setAssetType={setAssetType}
                                                        Language={Language}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2 mt-lg-4 mt-md-4" >
                                                    {/* <button type="button" className="btn-search btn btn btn-secondary mr-1"
                                                        onClick={() => handleSearch()}
                                                    >
                                                        Search
                                                    </button> */}
                                                    <button type="button" className="btn-clear btn btn-primary"
                                                        onClick={handleClear}
                                                    >
                                                        Clear</button>
                                                </div>
                                            </div>

                                            <div className="row mob_table table-responsive pt-1">
                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            <th style={{ width: "7em" }}>Assign Date</th>
                                                            <th>Work Type</th>
                                                            <th>Asset Name</th>
                                                            {/* <th>Sector Name	 </th> */}
                                                            <th>Area</th>
                                                            <th>Work Name </th>
                                                            <th>Assign Work Photo</th>
                                                            {
                                                                searchName == "Completed" ?
                                                                    <>
                                                                        <th>Completed Work Date</th>
                                                                        <th>Completed Work Photo</th>
                                                                    </>
                                                                    : ''
                                                            }

                                                            <th>Officer Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) =>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                    <td>{item.workAssignDate ? moment(item.workAssignDate).format("DD-MM-YYYY") : '-'}</td>
                                                                    <td>{item.workTypeName ? item.workTypeName : '-'}</td>
                                                                    <td>{item.assetName ? item.assetName : '-'}</td>
                                                                    {/* <td>{item.sectorName ? item.sectorName : '-'}</td> */}
                                                                    <td>{item.areaName ? item.areaName : '-'}</td>
                                                                    <td>{item.workname ? item.workname : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.workPhoto == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye" title='View'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => (
                                                                                            handleShowImage(item.workPhoto),
                                                                                            setHeading("Assign Work Photo")
                                                                                        )}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    {
                                                                        searchName == "Completed" ?
                                                                            <>
                                                                                <td >{item.workDate ? moment(item.workDate).format("DD-MM-YYYY") : '-'}</td>
                                                                                <td style={{ textAlign: "center" }} >
                                                                                    {
                                                                                        (item.afterWorkPhoto == null) ?
                                                                                            <span>-</span>
                                                                                            :
                                                                                            <>
                                                                                                <i className="fa-solid fa-eye" title='View'
                                                                                                    style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                                    onClick={() => (
                                                                                                        handleShowImage(item.afterWorkPhoto),
                                                                                                        setHeading("Completed Work Photo")
                                                                                                    )}
                                                                                                ></i>
                                                                                            </>
                                                                                    }
                                                                                </td>
                                                                            </>
                                                                            : ''
                                                                    }
                                                                    <td>{item.assingBy ? item.assingBy : '-'}</td>

                                                                </tr>
                                                            ) :
                                                                <>No Found data</>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {
                    showImagePopup ?
                        <ViewURL
                            Heading={Heading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''
                }
            </div>
        </>

    )
}