
import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';

export default class Pie_Chart extends Component {

    constructor(props) {
        super(props);
       
    }

    render() {
       
        return (
            <div className="Chart">
                <Pie
                    data={this.props.chart2}
                    width={this.props.width}
                    height={this.props.height}

                    options={{
                        elements: {
                            arc: {
                                borderWidth: 0
                            }
                        },
                        plugins: {
                            labels: {
                                render:'label',
                                fontColor:'transparent',
                                position: 'outside',
                                arc: false,
                                textMargin: 5,
                                // overlap:false
                            },
                            outlabels: {
                                display:false,
                                color: "white",
                                // text: "%l\n%v (%p)",
                                stretch: 15,
                                font: {
                                    resizable: true,
                                    minSize: 12,
                                    maxSize: 16
                                }
                            },
                        },
                        legend: {
                            display: false,
                            position: "right"
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem, data) => {
                                    return tooltipItem?.value;
                                }
                            }
                        }
                    }}
                />

            </div>
        )
    }

}
