import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";

// Get Table Data
export const WaterAuditTableDataAPI = createAsyncThunk("WaterAuditTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_WS_JunctionID,
        M_UserID,
        From,
        To,
        token
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };


    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_WS_T_WS_WaterAudit_Select?FromDate=${FromDate}&ToDate=${ToDate}&M_WardID=${M_WardID}&M_WS_JunctionID=${M_WS_JunctionID}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WaterAuditTableDataSlice = createSlice({
    name: "WaterAuditTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WaterAuditTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WaterAuditTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WaterAuditTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WaterAuditTableDataReducer = WaterAuditTableDataSlice.reducer


export const WaterAuditPostDataAPI = createAsyncThunk("WaterAuditPostData", async ({ data }) => {
    const {
        T_WS_WaterAuditID,
        RecivedWaterMLD,
        SupplyWaterMLD,
        Non_RevenueWaterMLD,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_WaterAuditID", T_WS_WaterAuditID);
    formdata.append("RecivedWaterMLD", RecivedWaterMLD);
    formdata.append("SupplyWaterMLD", SupplyWaterMLD);
    formdata.append("Non_RevenueWaterMLD", Non_RevenueWaterMLD);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/Post_WEB_WS_T_WS_WaterAudit_Update`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const WaterAuditPostDataSlice = createSlice({
    name: "WaterAuditPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WaterAuditPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WaterAuditPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WaterAuditPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const WaterAuditPostDataReducer = WaterAuditPostDataSlice.reducer

// -----------------Delete API ---------------

export const WaterAuditDeleteAPI = createAsyncThunk("WaterAuditDelete", async ({ data }) => {
    const {
        T_WS_WaterAuditID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_WaterAuditID", "1");
    formdata.append("M_UserID", "1");

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/WEB_WS_T_WS_WaterAudit_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const WaterAuditDeleteSlice = createSlice({
    name: "WaterAuditDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WaterAuditDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(WaterAuditDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(WaterAuditDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const WaterAuditDeleteReducer = WaterAuditDeleteSlice.reducer