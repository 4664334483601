import { useEffect } from 'react'
import Select from 'react-select'
import { ZoneDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'

export const ZoneDataDDLS = (props) => {
    const { ZoneName, setZoneName, rowData, Flag, IsPost,Language } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token,Language:Language.Label }
        {Language.Label !='' && dispatch(ZoneDDLAPI({ data }))}
    }, [Language.Label,IsPost])

    const { ZoneData } = useSelector(state => state.ZoneDDLData)

    useEffect(() => {
        handleZoneDDL()
    }, [ZoneData])

    const handleZoneDDL = () => {
        if (ZoneData && ZoneData.table && ZoneData.table.length > 0) {
            let list = ZoneData.table.map((item, index) => ({
                value: item.m_ZoneID,
                label: item.zoneName,
            }))
            setZoneName({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_ZoneID : 0,
                Label: Flag === 'Update' ? rowData.zoneName : "Select...",
            })
        }

        else {
            setZoneName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label for=""><b>{t('AdminDashboard.Admin_Sidebar_Table_Heading.Zone_Name')} {Flag && <AstricSign />} </b></label>
            <Select
                isClearable
                isSearchable
                isDisabled = {Flag === 'Update' ? 'disabled' : 0}
                maxMenuHeight={150}
                value={{ value: ZoneName.ID, label: ZoneName.Label }}
                onChange={(e) => {
                    e ?
                        setZoneName({ ...ZoneName, ID: e.value, Label: e.label })
                        :
                        setZoneName({ ...ZoneName, ID: 0, Label: "Select..." })

                }}
                options={ZoneName.DDL}
            />
        </div>
    )
}