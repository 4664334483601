import React from 'react'
import { useNavigate } from 'react-router-dom'
import MasterCard from './MasterCard'
import { useState } from 'react'

export default function MasterCardManage(props) {
    const { Countdata,HeaderMonthID,HeaderFinancialYear , MonthValue, YearValue , YearLabel,MonthLabel,WardValue,WardLabel,ProjectTypeId,DashboardName } = props
   
    const MasterNavigate = (name, screenFlag) => {

        if (name === "Ward Name") {
            navigate(`/masterwarddata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Location Name") {
            navigate(`/mastersectordata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Work Name") {
            navigate(`/masterworkdata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }
    const navigate = useNavigate()
    return (
        <div className='row px-3'>
            <div className="col-12 col-md-6 col-lg-4">
                <MasterCard
                    title="Ward Name"
                    cardName='py-2 green cursor'
                    border='green_border'
                    count='100'
                    color='red'
                    imgPath='./assets/img/ward (2).png'
                    // navLink='/masterwarddata'
                    MasterNavigate={MasterNavigate}
                />
            </div>
            <div className="col-12 col-md-6 col-lg-4" >
                <MasterCard
                    title="Location Name"
                    cardName='py-2 blue cursor'
                    border='blue_border'
                    imgPath='./assets/img/location.png'
                    count={Countdata.LocationTotalCount}
                    // navLink='/mastersectordata'
                    MasterNavigate={MasterNavigate}
                />
            </div>

            <div className="col-12 col-md-6 col-lg-4">
                <MasterCard
                    title="Work Name"
                    cardName='py-2 green cursor'
                    border='green_border'
                    color='red'
                    imgPath='./assets/img/system (1).png'
                    count={Countdata.WorkTotalCount}
                    // navLink='/masterworkdata'
                    MasterNavigate={MasterNavigate}
                />
            </div>

        </div>

    )

}

