
import { useEffect } from 'react'
import Select from 'react-select'
import { CommNewDepartmentDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { useTranslation } from 'react-i18next'

export const CommMasterDepartmentDDL = (props) => {
    const { Department, setDepartment, CropNameDashboardData, rowData, Flag } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { M_UserID: UserID, token }
        dispatch(CommNewDepartmentDDLAPI({ data }))
    }, [])

    const { CommNewDepartmentDDL } = useSelector(state => state.CommNewDepartmentDDLData)

    useEffect(() => {
        handleCommNewDepartmentDDL()
    }, [CommNewDepartmentDDL])

    const handleCommNewDepartmentDDL = () => {
        if (CommNewDepartmentDDL && CommNewDepartmentDDL.table && CommNewDepartmentDDL.table.length > 0) {
            let list = CommNewDepartmentDDL.table.map((item, index) => ({
                value: item.id,
                label: item.departmentName,
            }))

            setDepartment({
                DDL: list,
                ID: Flag == 'DailyAttendanceSheet' ? list[0].value : Flag === 'Update' ? rowData.id : 0,
                Label: Flag == 'DailyAttendanceSheet' ? list[0].label : Flag === 'Update' ? rowData.departmentName : "Select...",
            })
        }
        else {
            setDepartment({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>{t('AdminDashboard.Admin_Sidebar_Table_Heading.Department')}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width"
                isSearchable
                maxMenuHeight={150}
                value={{ value: Department.ID, label: Department.Label }}
                onChange={(e) => {
                    e ?
                        setDepartment({ ...Department, ID: e.value, Label: e.label })
                        :
                        setDepartment({ ...Department, ID: 0, Label: "Select..." })

                }}
                options={Department.DDL}
            />
        </div>
    )
}