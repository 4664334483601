import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";

// Get Table Data
export const SecurityTableDataAPI = createAsyncThunk("SecurityTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_WS_PumpStationID,
        M_UserID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_WS_T_WS_Security_Select?FromDate=${FromDate ? FromDate : ''}&ToDate=${ToDate ? ToDate : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_WS_PumpStationID=${M_WS_PumpStationID ? M_WS_PumpStationID : '0'}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const SecurityTableDataSlice = createSlice({
    name: "SecurityTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SecurityTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SecurityTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(SecurityTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const SecurityTableDataReducer = SecurityTableDataSlice.reducer

// -----------------Delete API ---------------

export const SecurityDeleteAPI = createAsyncThunk("SecurityDelete", async ({ data }) => {
    const {
        T_WS_SecurityID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_SecurityID", T_WS_SecurityID);
    formdata.append("M_UserID", M_UserID);
    
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/APKDashboard/WEB_WS_T_WS_Security_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const SecurityDeleteSlice = createSlice({
    name: "SecurityDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SecurityDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(SecurityDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(SecurityDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const SecurityDeleteReducer = SecurityDeleteSlice.reducer