import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";
import { BaseUrl } from "../../Helper/BaseUrl";

// Get Table Data
export const CustomerServiceTableDataAPI = createAsyncThunk("CustomerServiceTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_SW_LocationID,
        M_SW_ServiceTypeID,
        M_UserID,
        Flag,
        token,
        From,
        To,
        searchName,
        M_SW_WorkTypeID,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    let url
    if ((searchName == 'Pending' || (searchName == 'Completed'))) {
        url = `${BaseUrl}/APKDashboard/Get_WEB_SW_T_SW_CustomerServices_Select?FromDate=${FromDate ? FromDate : ''}&ToDate=${ToDate ? ToDate : ''}&M_WardID=${M_WardID ? M_WardID : '0'}&M_SW_LocationID=${M_SW_LocationID ? M_SW_LocationID : '0'}&M_SW_ServiceTypeID=${M_SW_ServiceTypeID ? M_SW_ServiceTypeID : '0'}&M_UserID=${M_UserID}&Flag=${Flag}&From=${From}&To=${To}`
    } else {
        url = `${BaseUrl}/APKDashboard/Get_WEB_SW_T_SW_RMServicesAssignWork_Select?FromDate=${FromDate ? FromDate : ''}&ToDate=${ToDate ? ToDate : ''}&M_WardID=${M_WardID ? M_WardID : '0'}&M_SW_WorkTypeID=${M_SW_WorkTypeID ? M_SW_WorkTypeID : '0'}&M_UserID=${M_UserID}&Flag=${Flag}&From=${From}&To=${To}`

    }
    return fetch(url, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const CustomerServiceTableDataSlice = createSlice({
    name: "CustomerServiceTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CustomerServiceTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CustomerServiceTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(CustomerServiceTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CustomerServiceTableDataReducer = CustomerServiceTableDataSlice.reducer

// -----------------Delete API ---------------

export const CustomerServiceMasterDeleteAPI = createAsyncThunk("CustomerServiceMasterDelete", async ({ data }) => {
    const {
        T_SW_CustomerServicesID,
        M_UserID,
        token,
        Flag,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    var formdata = new FormData();
    formdata.append("T_SW_CustomerServicesID", T_SW_CustomerServicesID);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };
    
    fetch(`${BaseUrl}/APKDashboard/WEB_SW_T_SW_CustomerServices_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const CustomerServiceMasterDeleteSlice = createSlice({
    name: "CustomerServiceMasterDelete",
    initialState: {
        isDeleteLoading: false,
        CustomerServiceData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CustomerServiceMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(CustomerServiceMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.CustomerServiceData = action.payload;

        });
        builder.addCase(CustomerServiceMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.CustomerServiceData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const CustomerServiceMasterDeleteReducer = CustomerServiceMasterDeleteSlice.reducer


// ---------------Mech civil delete api ------------------


export const AssignMechCivilPendingDeleteAPI = createAsyncThunk("AssignMechCivilPendingDelete", async ({ data }) => {
    const {
        T_SW_RMServicesAssignWorkID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    var formdata = new FormData();
    formdata.append("T_SW_RMServicesAssignWorkID", T_SW_RMServicesAssignWorkID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/WEB_SW_T_SW_RMServicesAssignWork_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const AssignMechCivilPendingDeleteSlice = createSlice({
    name: "AssignMechCivilPendingDelete",
    initialState: {
        isDeleteLoading: false,
        MechCivilPendingData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(AssignMechCivilPendingDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(AssignMechCivilPendingDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.MechCivilPendingData = action.payload;

        });
        builder.addCase(AssignMechCivilPendingDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.MechCivilPendingData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const AssignMechCivilPendingDeleteReducer = AssignMechCivilPendingDeleteSlice.reducer