import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";
import { BaseUrl } from "../../Helper/BaseUrl";


// Get Table Data
export const WaterSupplyUserWiseWardMappingTableDataAPI = createAsyncThunk("WaterSupplyUserWiseWardMappingTableData", async ({ data }) => {
    const {
        LoginM_UsersID,
        M_UsersID,
        Language,
        token,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_DDL_M_UserWiseM_WardMapping_Select?LoginM_UsersID=${LoginM_UsersID}&M_UsersID=${M_UsersID ? M_UsersID : '0'}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WaterSupplyUserWiseWardMappingTableDataSlice = createSlice({
    name: "WaterSupplyUserWiseWardMappingTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WaterSupplyUserWiseWardMappingTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WaterSupplyUserWiseWardMappingTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WaterSupplyUserWiseWardMappingTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WaterSupplyUserWiseWardMappingTableDataReducer = WaterSupplyUserWiseWardMappingTableDataSlice.reducer

// InsertUpdate Table Data

export const UserWiseWardMappingPostAPI = createAsyncThunk("UserWiseWardMappingPost", async ({ data }) => {
    const {
        M_WardID_Data,
        M_UsersID,
        LoginM_UsersID,
        Flag,
        UserID,
        token,
        handlePost,
        handleAddCloseClick,
        handleClear
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WardID_Data", M_WardID_Data);
    formdata.append("M_UsersID", M_UsersID);
    formdata.append("LoginM_UsersID", LoginM_UsersID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_UserWiseM_WardMapping_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handlePost()
                handleClear()
                // handleAddCloseClick()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)
            }
            return result

        })
})

const UserWiseWardMappingPostSlice = createSlice({
    name: "UserWiseWardMappingPost",
    initialState: {
        isLoading: false,
        SDTData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(UserWiseWardMappingPostAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(UserWiseWardMappingPostAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.SDTData = action.payload;
        });
        builder.addCase(UserWiseWardMappingPostAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.SDTData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const UserWiseWardMappingPostReducer = UserWiseWardMappingPostSlice.reducer

