import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { DesignationPostDataAPI } from '../../../../Redux/SidebarMasterSlice/DesignationSlice';
import { AstricSign } from '../../../../Helper/AstricSign';
import { useSearchParams } from 'react-router-dom';


export default function DesignationPopup(props) {

    const { Flag, handleIsPost, rowData, open, handleOnCloseClick } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams()
    let Lang = searchParams.get("Lang")

    const [DesignationName, setDesignationName] = useState(Flag === 'Update' ? rowData.designationName : '')


    const handlePost = () => {
        const data = {
            M_DesignationID: Flag === 'Update' ? rowData.id : '0',
            DesignationName: DesignationName,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleOnCloseClick:handleOnCloseClick
        }
        dispatch(DesignationPostDataAPI({ data }))
    }

    return (
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="area_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Designation</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">

                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Designation Name <AstricSign /> :</b></label>
                                        <input type="text"
                                            className="form-control"
                                            id="DesignationName"
                                            value={DesignationName}
                                            onChange={(e) =>  setDesignationName(e.target.value)}
                                        />
                                    </div>
                                </div>

                            </div>

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                        disabled={
                                            DesignationName == ''
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </Popup>
    )

}