
import { useEffect } from 'react'
import Select from 'react-select'
import { ContractorNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'

export const ContractorNameDDL = (props) => {
    const { ContractorName, setContractorName, rowData, Flag, IsPost, Language, IsClear, Flag1 } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_UserID: UserID,
            token: token,
            Language: Language.Label,
            Flag: Flag
        }
        { Language.Label != '' && dispatch(ContractorNameDDLAPI({ data })) }
    }, [IsPost, IsClear, Language.Label])

    const { ContractorNameDDLData } = useSelector(state => state.ContractorNameDDLData)

    useEffect(() => {
        handleContractorNameDDL()
    }, [ContractorNameDDLData])

    const handleContractorNameDDL = () => {
        if (ContractorNameDDLData && ContractorNameDDLData.table && ContractorNameDDLData.table.length > 0) {
            let list = ContractorNameDDLData.table.map((item, index) => ({
                value: item.m_ContractorID,
                label: item.contractorName,
            }))

            setContractorName({
                DDL: list,
                ID: Flag === 'Update' ? rowData.contractorNameID : Flag === 'Contractor' ? list[0].value : 0,
                Label: Flag === 'Update' ? rowData.contractorName : Flag === 'Contractor' ? list[0].label : 'Select...',
            })
        }
        else {
            setContractorName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>{(Flag1 == 'SupplierName' ? 'Supplier Name' : t('Dashboard.Work_Assigned_by_Officials.Contractor_Name'))} {Flag && <AstricSign />}</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width"
                isSearchable
                maxMenuHeight={150}
                value={{ value: ContractorName.ID, label: ContractorName.Label }}
                onChange={(e) => {
                    e ?
                        setContractorName({ ...ContractorName, ID: e.value, Label: e.label })
                        :
                        setContractorName({ ...ContractorName, ID: 0, Label: "Select..." })

                }}
                options={ContractorName.DDL}
            />
        </div>
    )
}