export const English = {

    "Dashboard": {
        "Titles": {
            "Ward_Name": "Ward Name",
            "Work_Assigned_by_Officials": "Work Assigned by Officials ",
            "Horticulture_Work_Assigned_by_Officials": "Horticulture Work Assigned by Officials",
            "Civil_Work_Assigned_By_Officials": "Civil Work Assigned By Officials",
            "Electrical_Work_Assigned_By_Officials": "Electrical Work Assigned By Officials",
            "Sports_works": "Sports Work Assigned By Officials",
            "Work_Progress_By_Contractor": "Work Progress By Contractor",
            "Horticulture_Work_Progress_By_Contractor": "Horticulture Work Progress",
            "Civil_Work_Progress_By_Contractor": "Civil Work Progress",
            "Electrical_Work_Progress_By_Contractor": "Electrical Work Progress",
            "Sports_works_Progress": "Sports Work Progress",
            "Assigned_work": "Assigned work",
            "Pending": "Pending",
            "Completed": "Completed",
            "Work_Reopen_Progress_By_Contractor": "Work Reopen Progress By Contractor",
            "Horticulture_work_reopen_progress": "Horticulture Work Reopen Progress By Contractor",
            "Reopened_Work": "Reopened Work",
            "Civil_Work_Reopen_Progress_By_Contractor": "Civil Work Reopen Progress By Contractor",
            "Electrical_Work_Reopen_Progress_By_Contractor": "Electrical Work Reopen Progress By Contractor",
            "Master_Data": "Master Data",
            "Sports_works_Reopen_By_Contractor": "Sports Works Reopen By Contractor",
            "Schedule_Date": "Schedule Date",
            "Attendance": "Attendance"

        },

        "Pcmc_Assets": {
            "Pcmc_Assets": "PCMC Assets",
            "Ward_no": "Ward no",
            "Sector_Name": "Sector Name",
            "Maintain_By": "Maintain By",
            "Sr_No": "Sr.No",
            "Area_Name": "Area Name",
            "Garden_Name": "Garden Name",
            "Image": "Image",
            "Track": "Track",
            "Action": "Action",
            "Asset_Type": "Asset Type",
            "Asset_Name": "Asset Name",
            "Area_Name": "Area Name",
            "Area": "Area (in Hector)",
            "Area_sq": "Area (in Sq.Meter)",
            "Status": "Status ",
            "Road_Divider_Name": "Road Divider Name",
            "Open_Space": "Open Space",
            "Tree_Belt_Name": "Tree Belt's Name",
            "chowks_Name": "Chowks Name",
            "chowks_master": "Chowks ",
            "Tree_Belt’s_master": 'Tree Belt’s',
            "Play_Equipments": 'Play Equipments',
            "Open_Space_master": "Open Space",
            "Road_Divider": "Road Divider",
            "Garden_Master": "Garden Master",
            "Zone_Name": "Zone Name",
            "Equipment_Name": "Equipment Name",
            "Supplier_Name": "Contractor Name",
        },
        "Work_Assigned_by_Officials": {
            "From_Date": "From Date",
            "To_Date": "To Date",
            "Contractor_Name": "Contractor Name",
            "Assign_Date": "Assign Date",
            "Area1": "Area",
            "Work_Name": "Work Name",
            "Assign_Work_Photo": "Assign Work Photo",
            "Officer_Name": "Officer Name",
            "Assign_Contractor_Name": "Assign Contractor Name",
            "Assigned_By": "Assigned By",
            "Completed_Work_Date": "Completed Work Date",
            "Completed_Work_Photo": "Completed Work Photo",
            "BenchMark": "BenchMark",
            "Reopened_By": "Reopened By",
            "Reopened_Photo": "Reopened Work Photo",
            "Work_Date": "Work Date"
        }
    },
    "Sidebar": {
        "Dashboard": "Dashboard",
        "Preventive_Work_Report": "Penalty Report",
        "Completed_Work_Report": "Completed Work Report",
        "Garden_Report": "Garden Reports",
        "Employee_Tracking": "Employee Tracking",
        "Work_Type": "Work Type",
        "Upload_Date": "Upload Date",
        "Work_Assign_By": "Work Assign By",
        "Asset_Type": "Asset Type",
        "Work_Done_Date": "Work Done Date",
        "Before_Work_Photo": "Before Work Photo",
        "After_Work_Photo": "After Work Photo",
        "Role": "Role",
        "Daily_Work_Sheet": 'Daily Attendance Sheet',
    },


    "AdminDashboard": {
        "Admin_Titles": {
            "Daily_Preventive_Work": "Daily Preventive Work",
            "Work_Progress_by_Contractors": "Work Progress by Contractors",
            "Reopen_Work_Progress": "Reopen Work Progress",
            "Deputy_Municipal_Commissioner_(DMC)": "Deputy Municipal Commissioner (DMC)",
            "Garden_Officer_(GO / AGO)": "Garden Officer (GO / AGO)",
            "Garden_Assistant_(GA)": "Garden Assistant (GA)",
            "Garden_Supervisor_(GS)": "Garden Supervisor (GS)",
            "Reopen_Pending": "Reopen Pending",
            "Reopen_Completed": "Reopen Completed",


            "Total_Work": 'Total Work',
            'Total_works_Completed': 'Total Completed Work',
            "Pending_Work": 'Pending Work',
            'Daily_Work_Completed': 'Daily Work Completed',
            'Daily_Work_Pending': 'Daily Work Pending',
            'Remark': 'Remark'
        },

        "Grid_Heading": {
            "Daily_Preventive_Work_Pending": "Daily Preventive Work Pending",
            "Daily_Preventive_Work_Completed": "Daily Preventive Work Completed",
            "WORK_ASSIGNED": "WORK ASSIGNED",
            "WORK_ASSIGNED_Deputy_Municipal_Commissioner_(DMC)": "WORK ASSIGNED / Deputy Municipal Commissioner (DMC)",
            "WORK_ASSIGNED_Garden_Officer_(GO / AGO)": "WORK ASSIGNED / Garden Officer (GO / AGO)",
            "WORK_ASSIGNED_Garden_Assistant_(GA)": "WORK ASSIGNED / Garden Assistant (GA)",
            "WORK_ASSIGNED_Garden_Supervisor_(GS)": "WORK ASSIGNED / Garden Supervisor (GS)",
            "Reopen_Work_Progress_Reopen_Pending": "Reopen Work Progress Reopen Pending",
            "Reopen_Work_Progress_Reopen_Completed": "Reopen Work Progress Reopen Completed",
        },

        "Table_Heading": {
            "Date": "Date",
            "Work_Sub_Type": "Work Sub Type",
            "Reopen_Date": "Reopen Date",
            "Reopen_Work_Photo": "Reopen Work Photo",
            "Work_Reopen_Date": "Work Reopen Date",
            "Reopen_Completed_Work_Date": "Reopen Completed Work Date",
            "Reopen_Completed_Work_Photo": "Reopen Completed Work Photo",
        },

        "Admin_Sidebar_Name": {
            "Master": "Master",
            "Garden_Reports": "Garden Reports",
            "Designation": "Designation",
            "Employee": "Employee",
            "User_Creation": "User Creation",
            "Ward_Master": "Ward Master",
            "Sector_Master": "Sector Master",
            "Work": "Work",
            "Work_Sub_Type": "Work Sub Type",
            "Project_Name": "Project Name",
            "Contractor_Wise_Assets": "Contractor Wise Assets",
            "Project_Wise_Contractor": "Project Wise Contractor",
            "Project_Wise_Work_Name": "Project Wise Work Name",
            "Garden_wise_maintenance_status": "Garden wise maintenance status",
            "User_Wise_Ward_Mapping": "User Wise Ward Mapping"
        },

        "Admin_Sidebar_Grid_Heading": {
            "Designation_Master": "Designation Master",
            "Employee_Master": "Employee Master",
            "User_Creation_Master": "User Creation Master",
            "Contractor_Name_Master": "Contractor Name Master",
            "Zone_Master": "Zone Master",
            "Sector_Garden_Master": "Sector Garden Master",
            "Work_Type_Garden_Master": "Work Type Garden Master",
            "Work_Sub_Type_Garden_Master": "Work Sub Type Garden Master",
            "Work_Garden_Master": "Work Garden Master",
            "Project_Name_Master": "Project Name Master",
            "Project_Wise_Contractor_Master": "Project Wise Contractor Master",
            "Project_Wise_Work_Name_Master": "Project Wise Work Name Master",
        },

        "Admin_Sidebar_Table_Heading": {
            "Designation_Name": "Designation Name",
            "Mobile_Number": "Mobile Number",
            "Email_Id": "Email Id",
            "Table_User_Name": "Table User Name",
            "User_Name": "User Name",
            "Password": "Password",
            "Address": "Address",
            "Contact_Person_Name": "Contact Person Name",
            "Zone_Name": "Zone Name",
            "Prabhag_No": "Prabhag No",
            "Benchmark_in_Days": "Benchmark in Days",
            "Penalty_Amount_(Rs.)": "Penalty Amount (Rs.)",
            "Project_Type": "Project Type",
            "User": "User",
            "Equipment_No.": "Equipment No.",
            "Equipment_Installation_Date": 'Equipment Installation Date',
            "Visited_Date": 'Visited Date',
            "Work_Description": 'Work Description',
            "Department": 'Department',
            "Photo": 'Photo',
            "Work_Photo": "Work Photo"

        },
    },
    "EmployeeAndContractor": {
        "Officer_Name_Pending_Work_Status": "Officers Pending Work Status",
        "Contractor_Name_Pending_Work_Status": 'Contractors Pending Work Status',
        "Total_Assign_Works": "Total Assign Works",
        "Total_works_Completed": "Total works Completed",
        "Total_Pending_Works": "Total pending works",
        "Total": 'Total',
        "0_to_7_days": "0 to 7 days",
        "7_to_15_days": "7 to 15 days",
        "15_to_30_days": "15 to 30 days",
        "30_to_90_days": "30 to 90  days",
        "90_to_180_days": "90 to 180 days",
        "1_year": "1 year",
        "Karmachari": 'Officer',
        'Thekedar': 'Contractor',
        'Karmachariche_Nav': 'Officer Name',
        'Thekedaryache_Nav': 'Contractor Name',
        'Daily_Work': 'Daily Preventive Work',
        'Yes': 'Yes',
        'No': 'No',
        'Count': 'Count',
        'ChecklistPhoto': 'Checklist Photo'
    }
}