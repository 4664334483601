import { useEffect, useState } from "react"
import Select from 'react-select'
import Header from "../../../../../Components/Header/Header"
import Sidebar from "../../../../../Components/Sidebar/Sidebar"
import { useSearchParams } from 'react-router-dom'
import DeletePopup from "../../GardenPopup/DeletePopup"
import moment from "moment";
import { useAuthState } from "../../../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { AssignMechCivilPendingDeleteAPI, CustomerServiceTableDataAPI } from "../../../../../Redux/SeverageDashboardSlice/AssignedWorkCustomerServicesSlice"
import { WardNameDataDDL } from "../../../../../Components/CommonDDL/WardNameDataDDL"
import { SeverageWorkTypeDataDDL } from "../../../../../Components/CommonDDL/SeverageWorkTypeDataDDL"
import ViewURL from "../../../../../Components/ViewURL/ViewURL"
import { Loading } from "../../../../../Helper/Loading"



export default function CommonAssignWorkData() {

    const [DeletePopupShow, setDeletePopupShow] = useState()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let M_SW_WorkTypeID = searchParams.get('M_SW_WorkTypeID')
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    const [FromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsPost, setIsPost] = useState(false)

    const [Heading, setHeading] = useState('')
    const [ImagePopUpData, setImagePopUpData] = useState('')
    const [ShowImagePopup, setshowImagePopup] = useState(false)
    const [IsClear, setIsClear] = useState(false)

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [Month, setMonth] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })
    const [ServiceType, setServiceType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [SeverageWorkType, setSeverageWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleShowImage = (Imageurl, heading) => {
        setshowImagePopup(true)
        setImagePopUpData(Imageurl)
        setHeading(heading)
    }

    const handleIsPost = () => {
        setIsPost(!IsPost)
        // if (tableData && tableData.table && tableData.table.length > 0) {
        //     setCurrentPage(0)
        //     setFrom(1)
        //     setTo(10)
        // }

    }

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setWardName({
            ...WardName,
            ID: HWard,
            Label: WLable,
        })
        setServiceType({
            ...ServiceType,
            ID: 0,
            Label: 'Select...'
        })
        setSeverageWorkType({
            ...SeverageWorkType,
            ID: 0,
            Label: 'Select...',
        })

        setFromDate(moment().startOf('month').format('YYYY-MM-DD'))
        setToDate(moment().format('YYYY-MM-DD'))
    }

    useEffect(() => {
        const data = {
            FromDate: FromDate,
            ToDate: ToDate,
            M_WardID: WardName.ID,
            M_SW_LocationID: 0,
            M_SW_WorkTypeID: SeverageWorkType.ID,
            M_UserID: UserID,
            Flag: ApiFlag,
            token: token,
            From: From,
            To: To,
            searchName: searchName,
        }
        if (WardName.ID != 0) {
            dispatch(CustomerServiceTableDataAPI({ data }))
        }
    }, [IsPost, To, IsClear, FromDate, ToDate, WardName.ID, ServiceType.ID, SeverageWorkType.ID,])

    const { tableData, isLoading } = useSelector(state => state.CustomerServiceTableData)

    const handleDeleteApi = (item) => {
        const data = {
            T_SW_RMServicesAssignWorkID: item.t_sW_RMServicesAssignWorkID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost
        }
        dispatch(AssignMechCivilPendingDeleteAPI({ data }))
    }
    const { isDeleteLoading } = useSelector(state => state.ServicesMasterDeleteData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [IsPost, WardName.ID, SeverageWorkType.ID])

    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select Ward",
    // })

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    // DashboardHeading="Sewerage Maintainance"
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}  />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId} />
                    <div className="content">
                        <div className="page-header ">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        {/* <div className="page-header-title">
                                        <h5 className="ps-2 data_mob">Assigned Work */}

                                        {/* <!-- <span className="float-end">namita</span> --> */}
                                        {/* <span className="float-end fs-6">
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                            :
                                                            setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={SelectWard.DDL}
                                                />

                                            </span> */}
                                        {/* </h5>
                                    </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="data_mob">
                            <div className="row m-1 py-1 pb-4 master_border">
                                <h5 className="ps-3 py-1">Assigned Work {searchName}
                                    <button type="button" className="btn btn-primary float-end mr-1">Export</button>
                                </h5>
                                <div className="row m-0">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                        <div className="row rounded2">
                                            <div className="col-md-6 col-12 col-lg-2">
                                                <div className="form-group">
                                                    <label className="d-block" htmlFor="NameofDepartment"><b>From Date :</b></label>
                                                    <input type="date"
                                                        className="form-control"
                                                        id="FromDate"
                                                        value={FromDate}
                                                        onChange={(e) => (setFromDate(e.target.value), setToDate(''))}
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-2">
                                                <div className="form-group">
                                                    <label className="d-block" htmlFor="NameofDepartment"><b>To Date :</b></label>
                                                    <input type="date"
                                                        className="form-control"
                                                        id="ToDate"
                                                        value={ToDate}
                                                        onChange={(e) => setToDate(e.target.value)}
                                                        min={FromDate}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-3 ">
                                                <WardNameDataDDL
                                                    WardName={WardName}
                                                    setWardName={setWardName}
                                                    HWard={HWard}
                                                    WLable={WLable}
                                                />
                                            </div>

                                            {/* <div className="col-12 col-md-6 col-lg-3 " >
                                                <SeverageWorkTypeDataDDL
                                                    SeverageWorkType={SeverageWorkType}
                                                    setSeverageWorkType={setSeverageWorkType}
                                                    IsPost={IsPost}
                                                />

                                            </div> */}

                                            <div className="col-12 col-lg-1 mt-lg-4" >
                                                <button type="button"
                                                    className="btn-clear btn btn-primary"
                                                    onClick={handleClear}>Clear</button>
                                            </div>
                                        </div>

                                        <div className="row mob_table table-responsive pt-2">
                                            <table id="totalordergenerated"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th className="sr_no_col">Sr No.</th>
                                                        <th>Area</th>
                                                        <th>Work Period</th>
                                                        <th>Work Name</th>
                                                        <th>Work Assigned Date </th>
                                                        <th>Before Photo </th>


                                                        {
                                                            searchName == "Mechanical Completed" ?
                                                                <>
                                                                    <th>Work Completed Date </th>
                                                                    <th>After Photo</th>
                                                                </>
                                                                : ''
                                                        }
                                                        {
                                                            searchName == "Mechanical Pending" ?
                                                                <>
                                                                    <th>Action</th>
                                                                </>
                                                                : ''
                                                        }
                                                        {
                                                            searchName == "Civil Completed" ?
                                                                <>
                                                                    <th>Work Completed Date </th>
                                                                    <th>After Photo</th>
                                                                </>
                                                                : ''
                                                        }

                                                        {
                                                            searchName == "Civil Pending" ?
                                                                <>
                                                                    <th>Action</th>
                                                                </>
                                                                : ''
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                    <td>{item.areaDetails ? item.areaDetails : '-'}</td>
                                                                    <td>{item.m_sW_WorkPeriod ? item.m_sW_WorkPeriod : '-'}</td>
                                                                    <td>{item.workName ? item.workName : '-'}</td>
                                                                    <td>{item.workAssingDate ? moment(item.workAssingDate).format("DD-MM-YYYY") : '-'}</td>

                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.beforePhoto == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye" title='View'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => handleShowImage(item.beforePhoto, 'Start Photo')}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>


                                                                    {
                                                                        searchName == "Mechanical Completed" ?
                                                                            <>
                                                                                <td>{item.workDoneDate ? moment(item.workDoneDate).format("DD-MM-YYYY") : '-'}</td>

                                                                                <td style={{ textAlign: "center" }}>
                                                                                    {
                                                                                        (item.afterPhoto == null) ?
                                                                                            <span>-</span>
                                                                                            :
                                                                                            <>
                                                                                                <i className="fa-solid fa-eye" title='View'
                                                                                                    style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                                    onClick={() => handleShowImage(item.afterPhoto, 'Start Photo')}
                                                                                                ></i>
                                                                                            </>
                                                                                    }
                                                                                </td>
                                                                            </>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        searchName == "Civil Completed" ?
                                                                            <>
                                                                                <td>{item.workDoneDate ? moment(item.workDoneDate).format("DD-MM-YYYY") : '-'}</td>

                                                                                <td style={{ textAlign: "center" }}>
                                                                                    {
                                                                                        (item.afterPhoto == null) ?
                                                                                            <span>-</span>
                                                                                            :
                                                                                            <>
                                                                                                <i className="fa-solid fa-eye" title='View'
                                                                                                    style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                                    onClick={() => handleShowImage(item.afterPhoto, 'Start Photo')}
                                                                                                ></i>
                                                                                            </>
                                                                                    }
                                                                                </td>
                                                                            </>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        searchName == "Civil Pending" || searchName == "Mechanical Pending" ?
                                                                            <>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                    <span>
                                                                                        <DeletePopup
                                                                                            open={DeletePopupShow}
                                                                                            handleDeleteApi={handleDeleteApi}
                                                                                            item={item}
                                                                                        />
                                                                                    </span>
                                                                                </td>
                                                                            </>
                                                                            : ''
                                                                    }

                                                                </tr>
                                                            )
                                                        })
                                                            :
                                                            <>No data found</>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    ShowImagePopup &&
                    <ViewURL
                        Heading={Heading}
                        ImagePopUpData={ImagePopUpData}
                        setshowImagePopup={setshowImagePopup}
                    />
                }
            </div>
        </>
    )
}