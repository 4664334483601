
import ReactHTMLTableToExcel from "react-html-table-to-excel"

export const ContractorWiseAssetsExportData = (props) => {
    const { ExcelData, name, searchName } = props
    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-FarmerMasterExport"
                className="btn btn-primary float-end download-table-xls-button float-end btn btn-export btn-md mr-1 mb-1 ml-1"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-FarmerMasterExport" style={{ display: 'none', width: '100%' }}>

                <h5><span>&emsp;</span></h5>

                <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4>

                <tr><td></td></tr>

                <thead>
                    <tr style={{ backgroundColor: '#254462', color: "#fff", border: '1px solid white' }}>
                        <th className="sr_no_col">Sr No.</th>
                        <th>Ward No </th>
                        {/* <th>Project Name </th> */}
                        <th>Asset Type</th>
                        <th>Asset name </th>
                        <th>Work Type</th>
                        <th>Contractor Name </th>
                    </tr>
                </thead>
                <tbody>

                    {
                        ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item) => (
                            <tr style={{ width: '100%' }}>
                                <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                <td>{item.wardName ? item.wardName : '-'}</td>
                                {/* <td>{item.projectName ? item.projectName : '-'}</td> */}
                                <td>{item.assetTypeName ? item.assetTypeName : '-'}</td>
                                <td>{item.assetName ? item.assetName : '-'}</td>
                                <td>{item.workTypeName ? item.workTypeName : '-'}</td>
                                <td>{item.contractorName ? item.contractorName : '-'}</td>
                            </tr>
                        )) :
                            <td colSpan={15}>No data found</td>
                    }
                </tbody>
            </table>
        </div>
    )
}