
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { CommContractorDDLAPI } from '../../Redux/DDLSlice'

export const ContractorNameHeaderDDL = (props) => {
    const { ContractorHeaderDDL, setContractorHeaderDDL, ZoneNameDDLID, setZoneNameDDLID, ZoneValue, Language, flag, YearValue, MonthValue, WardValue, Flag, HorticultureDDlData, setHorticultureDDlData, } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()


    // const d = new Date();
    // let month = d.getMonth();

    // const [MonthID, setMonthID] = useState(month + 1)


    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_MonthID: MonthValue,
            M_UserID: UserID,
            Flag: Flag,
            M_WardID: WardValue,
            M_ZoneID: ZoneValue,
            token,
            Language: Language.Label,
            setHorticultureDDlData: setHorticultureDDlData,
        }
        { Language.Label != '' && YearValue && ZoneValue && dispatch(CommContractorDDLAPI({ data })) }
    }, [YearValue, MonthValue, WardValue, ZoneValue, Language.Label])

    const { DDLData } = useSelector(state => state.CommContractorDDLData)

    useEffect(() => {
        handleDDLData()
    }, [DDLData])

    const handleDDLData = () => {
        if (HorticultureDDlData && HorticultureDDlData.length > 0) {
            let list = HorticultureDDlData.map((item, index) => ({
                value: item.m_ContractorID,
                label: item.contractorName,
            }))

            setContractorHeaderDDL({
                DDL: list,
                ID: HorticultureDDlData ? HorticultureDDlData[0].m_ContractorID : 0,
                Label: HorticultureDDlData ? HorticultureDDlData[0].contractorName : 'Select...',
            })

        }
    }

    console.log('HorticultureDDlData', ContractorHeaderDDL.ID)
    return (
        <div className='row'>
            <div className='col-4 pt-2 text-end'>

                <label className="d-block" htmlFor="NameofEmployee" style={{ color: "white" }}><b>Contractor Name :</b></label>
            </div>
            <div className='col-8'>
                <div className="form-group ">
                    <Select
                        isClearable
                        // isRtl={isRtl}
                        // className="ddl_width3"
                        isSearchable
                        maxMenuHeight={150}
                        value={{ value: ContractorHeaderDDL.ID, label: ContractorHeaderDDL.Label }}
                        onChange={(e) => {
                            e ?
                                setContractorHeaderDDL({ ...ContractorHeaderDDL, ID: e.value, Label: e.label })
                                :
                                setContractorHeaderDDL({ ...ContractorHeaderDDL, ID: HorticultureDDlData ? HorticultureDDlData[0].m_ContractorID : 0, Label: HorticultureDDlData ? HorticultureDDlData[0].contractorName : 'Select...' })

                        }}
                        options={ContractorHeaderDDL.DDL}
                    />
                </div>
            </div>

        </div>

    )
}