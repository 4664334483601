
import { useEffect, useState } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { CommContractorDDLAPI } from '../../Redux/DDLSlice'

export const SportsContractorNameHeaderDDL = (props) => {
    const { SportsContractorHeaderDDL, setSportsContractorHeaderDDL, ZoneValue, Language, flag, YearValue, MonthValue, WardValue, Flag, SportDDlData, setSportDDlData, } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()


    // const d = new Date();
    // let month = d.getMonth();



    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_MonthID: MonthValue,
            M_UserID: UserID,
            Flag: Flag,
            M_WardID: WardValue,
            M_ZoneID: ZoneValue,
            token,
            Language: Language.Label,
            setSportDDlData: handleDDLData,
        }
        { Language.Label != '' && YearValue && ZoneValue && dispatch(CommContractorDDLAPI({ data })) }
    }, [YearValue, MonthValue, WardValue, ZoneValue, Language.Label])

    const { DDLData } = useSelector(state => state.CommContractorDDLData)

    // useEffect(() => {
    //     handleDDLData()
    // }, [DDLData])
    // console.log('SportDDlData', SportDDlData)

    const handleDDLData = (SportData) => {
        if (SportData && SportData.length > 0) {
            let list = SportData.map((item, index) => ({
                value: item.m_ContractorID,
                label: item.contractorName,
            }))

            setSportsContractorHeaderDDL({
                DDL: list,
                ID: SportData ? SportData[0].m_ContractorID : 0,
                Label: SportData ? SportData[0].contractorName : 'Select...',
            })
        }
    }
    // else {
    //     setMonth({
    //         DDL: [],
    //         ID: 0,
    //         Label: "Select...",
    //     })
    // }

    return (

        <>

            <div className='row'>
                <div className='col-4 pt-2 text-end'>
                    <label className="d-block" htmlFor="NameofEmployee" style={{ color: "white" }}><b>Contractor Name :</b></label>
                </div>

                <div className='col-8'>
                    <div className="form-group ">
                        <Select
                            isClearable
                            // isRtl={isRtl}
                            // className="ddl_width3"
                            isSearchable
                            maxMenuHeight={150}
                            value={{ value: SportsContractorHeaderDDL.ID, label: SportsContractorHeaderDDL.Label }}
                            onChange={(e) => {
                                e ?
                                    setSportsContractorHeaderDDL({ ...SportsContractorHeaderDDL, ID: e.value, Label: e.label })
                                    :
                                    setSportsContractorHeaderDDL({ ...SportsContractorHeaderDDL, ID: SportDDlData ? SportDDlData[0].m_ContractorID : 0, Label: SportDDlData ? SportDDlData[0].contractorName : 'Select...' })

                            }}
                            options={SportsContractorHeaderDDL.DDL}
                        />
                    </div>
                </div>
            </div >
        </>
    )
}