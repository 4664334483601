import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { ZoneMasterPostDataAPI } from '../../../../Redux/SidebarMasterSlice/ZoneMasterSlice';
import { AstricSign } from '../../../../Helper/AstricSign';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


export default function ZoneMasterPopup(props) {

    const { open, handleOnCloseClick, EditIcon, Flag, handleIsPost, apiFlag, rowData } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();

    // const [rowData, setRowData] = useState(item)
    const [ZoneName, setZoneName] = useState(Flag === 'Update' ? rowData.zoneName : '')
    const [IsOpen, setIsOpen] = useState(false)


    // useEffect(() => {
    //     setZoneName(Flag === 'Update' ? rowData.zoneName : '')
    // }, [item, IsOpen])

    const handlePost = () => {
        const data = {
            M_ZoneID: Flag === 'Update' ? rowData.m_ZoneID : '0',
            ZoneName: ZoneName,
            M_UserID: UserID,
            token: token,
            Flag: Flag,
            handleIsPost: handleIsPost,
            handleOnCloseClick:handleOnCloseClick
        }
        dispatch(ZoneMasterPostDataAPI({ data }))
    }
    return (

        // <Popup
        //     onOpen={() => setIsOpen(!IsOpen)}
        //     contentStyle={{ top: '5%' }}

        //     trigger={
        //         Flag === 'Update' ?

        //             <i className="fa-solid fa-pen-to-square"
        //             style={{ cursor: "pointer" }} title='Edit'
        //             ></i>
        //             :
        //             <i className="fa-solid fa-plus btn btn-secondary float-end mb-2" style={{ color: "white", marginTop: '-2.5em' }}
        //             >
        //                 <text className="ml-2 poppins">Add</text>
        //             </i>

        //     }
        //     modal>

        //     {

        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="area_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">{t('AdminDashboard.Admin_Sidebar_Grid_Heading.Zone_Master')}</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">

                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>{t('AdminDashboard.Admin_Sidebar_Table_Heading.Zone_Name')}<AstricSign /> :</b></label>
                                        <input type="text" className="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            value={ZoneName}
                                            onChange={(e) => setZoneName(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                        disabled={
                                            ZoneName == ''
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* )} */}


        </Popup>
    )

}