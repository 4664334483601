import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ZoneNameDDL } from './DashboardDDL/ZoneNameDDL'
import { WardnameHeaderDDL } from './WardnameHeaderDDL'

export default function CommonDashboardScreenWardDDL(props) {

    const { t } = useTranslation()

    const { flag, WLable, HWard, WardName, setWardName,setZoneValue,setZoneLabel, setWardValue, setWardLabel,ZLable,ZWard, Language, ZoneNameDDLID, setZoneNameDDLID } = props
    

    // const [ZoneNameDDLID, setZoneNameDDLID] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select Ward",
    // })

    // useEffect(() => {
    //     localStorage.setItem("ZoneNameDDLID", ZoneNameDDLID.ID)
    // }, [ZoneNameDDLID])

    // useEffect(() => {
    //     if (setWardValue !== undefined) {
    //         setWardValue(WardName.ID)
    //     }
    //     if (setWardLabel !== undefined) {
    //         setWardLabel(WardName.Label)
    //     }
    // }, [WardName.ID])

    // useEffect(() => {
    //     // console.log(WardName)
    //     if (setZoneValue !== undefined) {
    //         setZoneValue(ZoneNameDDLID.ID)
    //     }
    //     if (setZoneLabel !== undefined) {
    //         setZoneLabel(ZoneNameDDLID.Label)
    //     }
    // }, [ZoneNameDDLID.ID])
    return (
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-12'>
                <ZoneNameDDL
                    ZoneNameDDLID={ZoneNameDDLID}
                    setZoneNameDDLID={setZoneNameDDLID}
                    // setZoneValue={setZoneValue}
                    // setZoneLabel={setZoneLabel}
                    ZLable={ZLable}
                    ZWard={ZWard}
                    Language={Language}
                    flag={flag}
                // ZoneDDL='DashboardZone'
                />
            </div>
            <div className='col-lg-6 col-md-6 col-12'>
                <WardnameHeaderDDL
                    WardName={WardName}
                    setWardName={setWardName}
                    WLable={WLable}
                    HWard={HWard}
                    ZLable={ZLable}
                    ZWard={ZWard}
                    flag={flag}
                    Language={Language}
                    ZoneNameDDLID={ZoneNameDDLID.ID}
                    M_ZoneID={ZoneNameDDLID.ID}
                // WardDDL='DashboardWard'
                />
            </div>
        </div>
    )
}
