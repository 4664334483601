
import React from 'react'
import WorkProgressCard from './WorkProgressCard'
import { useNavigate } from 'react-router-dom'
// import { useNavigate } from 'react-router-dom'


export default function WorkProgressCardManage(props) {
   
    const { Countdata,HeaderMonthID,HeaderFinancialYear , MonthValue, YearValue , YearLabel,MonthLabel,WardValue,WardLabel,ProjectTypeId,DashboardName } = props

    const navigate = useNavigate()
    const workProgressNavigate = (name, screenFlag) => {

        if (name === "Pending") {
            navigate(`/roadworkprogresspending?name=${name}&ApiFlag=Pending&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Completed") {
            navigate(`/roadworkprogresspending?name=${name}&ApiFlag=Completed&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }
    return (

        <div className="col-12 col-lg-7 pl-3">
            <div className="row pb-3" style={{ height: '100%' }}>
                <div className="col-12">
                    <h5 className="ps-2 pt-2 ">Work Progress</h5>
                </div>
                <div className="col-lg-6 col-md-12 mt-2" style={{ height: '83%' }}>
                    <WorkProgressCard
                        heading='Pending'
                        count={Countdata.WorkProgress_PendingTotalCount}
                        imgPath='./assets/img/expired.png'
                        cardName='wwork_img'
                        // navLink='/roadworkprogresspending'
                        countText='green_color'
                        workProgressNavigate={workProgressNavigate}
                    />
                </div>

                <div className="col-lg-6 col-md-12 mt-2" >
                    <WorkProgressCard
                        heading='Completed'
                        count={Countdata.WorkProgress_CompletedTotalCount}
                        imgPath='./assets/img/completed1.png'
                        cardName='wwork_img'
                        // navLink='/roadworkprogresscompleted'
                        countText='green_color'
                        workProgressNavigate={workProgressNavigate}
                    />
                </div>
            </div>
        </div>



    )
}






