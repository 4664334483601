import React from 'react'
import { useNavigate } from 'react-router-dom'
import PreventiveWaterQualityCard from './PreventiveWaterQualityCard'

export default function PreventiveWaterQualityCardManage(props) {
    const { Countdata, HeaderMonthID, HeaderFinancialYear, MonthValue, YearValue, YearLabel, MonthLabel, WardValue, WardLabel, ProjectTypeId, DashboardName } = props


    const PreventiveWaterQualityNavigate = (name, screenFlag) => {
        if (name === "PH") {
            navigate(`/waterqualityphservicepage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Chlorine") {
            navigate(`/waterqualitychlorineservicepage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Turbidity") {
            navigate(`/waterqualityturbidityservicepage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "E-coli") {
            navigate(`/waterqualityecoliservicepage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }
    const navigate = useNavigate()
    return (
        <div className="row pb-3 p-3 ml-lg-0 ml-0 mr-0">
            <div className="col-12 col-lg-3 mb-2 ">
                <PreventiveWaterQualityCard
                    title="PH"
                    imgPath='./assets/img/ph.png'
                    count="100"
                    // navLink='/waterqualityphservicepage'
                    PreventiveWaterQualityNavigate={PreventiveWaterQualityNavigate}
                />
            </div>

            <div className="col-12 col-lg-3 mb-2">
                <PreventiveWaterQualityCard
                    title="Chlorine"
                    imgPath='./assets/img/chlorine.png'
                    count="100"
                    PreventiveWaterQualityNavigate={PreventiveWaterQualityNavigate}

                />
            </div>

            <div className="col-12 col-lg-3 mb-2">
                <PreventiveWaterQualityCard
                    title="Turbidity"
                    imgPath='./assets/img/water-pollution.png'
                    count="100"
                    // navLink='/waterqualityturbidityservicepage'
                    PreventiveWaterQualityNavigate={PreventiveWaterQualityNavigate}
                />
            </div>

            <div className="col-12 col-lg-3 mb-2">
                <PreventiveWaterQualityCard
                    title="E-coli"
                    imgPath='./assets/img/e-coli.png'
                    count="100"
                    // navLink='/waterqualityecoliservicepage'
                    PreventiveWaterQualityNavigate={PreventiveWaterQualityNavigate}
                />
            </div>
        </div>

    )

}
