import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const AreaMasterTableDataAPI = createAsyncThunk("AreaMasterTableData", async ({ data }) => {
    const {
        M_SW_AreaDetailsID,
        M_WardID,
        AreaDetails,
        M_UsersID,
        token,
        From,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_WEB_M_SW_AreaDetails_Select?M_SW_AreaDetailsID=${M_SW_AreaDetailsID}&M_WardID=${M_WardID ? M_WardID :'0'}&AreaDetails=${AreaDetails ? AreaDetails :''}&M_UserID=${M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const AreaMasterTableDataSlice = createSlice({
    name: "AreaMasterTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(AreaMasterTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(AreaMasterTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(AreaMasterTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const AreaMasterTableDataReducer = AreaMasterTableDataSlice.reducer


export const AreaMasterPostDataAPI = createAsyncThunk("AreaMasterPostData", async ({ data }) => {
    const {
        M_SW_AreaDetailsID,
        M_WardID,
        AreaDetails,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_SW_AreaDetailsID", M_SW_AreaDetailsID);
    formdata.append("M_WardID", M_WardID);
    formdata.append("AreaDetails", AreaDetails);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_WEB_M_SW_AreaDetails_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const AreaMasterPostDataSlice = createSlice({
    name: "AreaMasterPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(AreaMasterPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(AreaMasterPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(AreaMasterPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const AreaMasterPostDataReducer = AreaMasterPostDataSlice.reducer

// -----------------Delete API ---------------

export const AreaMasterDeleteAPI = createAsyncThunk("AreaMasterDelete", async ({ data }) => {
    const {
        M_SW_AreaDetailsID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    var formdata = new FormData();
    formdata.append("M_SW_AreaDetailsID", M_SW_AreaDetailsID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/WEB_M_SW_AreaDetails_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const AreaMasterDeleteSlice = createSlice({
    name: "AreaMasterDelete",
    initialState: {
        isDeleteLoading: false,
        PumpingData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(AreaMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(AreaMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.PumpingData = action.payload;

        });
        builder.addCase(AreaMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.PumpingData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const AreaMasterDeleteReducer = AreaMasterDeleteSlice.reducer