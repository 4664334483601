
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../../Helper/BaseUrl";
import { toastErrorr } from "../../../../Helper/ToastMessage";


// Get Table Data
export const HorticultureWorkAssignedCountDataAPI = createAsyncThunk("HorticultureWorkAssignedCountData", async ({ data }) => {
    const {
        Flag,
        M_UserID,
        token,
        Language,
        setDays

    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/CommonDLLData/Get_WEB_DDL_M_GD_WorkPeriod_Select?M_UserID=${M_UserID}&Flag=${Flag}&Language=${Language}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setDays(result.data.table)
                return result.data.table
                
            } else {
                return result.data
            }
        })
})

const HorticultureWorkAssignedCountDataSlice = createSlice({
    name: "HorticultureWorkAssignedCountData",
    initialState: {
        isCountLoading: false,
        Data: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(HorticultureWorkAssignedCountDataAPI.pending, (state, action) => {
            state.isCountLoading = true;
        });
        builder.addCase(HorticultureWorkAssignedCountDataAPI.fulfilled, (state, action) => {
            state.isCountLoading = false;
            state.Data = action.payload;
        });
        builder.addCase(HorticultureWorkAssignedCountDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isCountLoading = false;
            state.isError = true;
            state.Data = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const HorticultureWorkAssignedCountDataReducer = HorticultureWorkAssignedCountDataSlice.reducer


