import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr } from "../../../Helper/ToastMessage";

export const GardenCompletedWorkReportTableDataAPI = createAsyncThunk("GardenCompletedWorkReportTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_ZoneID,
        M_ContractorID,
        M_WorkTypeID,
        M_AssetTypeID,
        M_WardData,
        M_UserID,
        token,
        Flag,
        // handleGetPdfUrl
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_RM_T_UploadAssignWorkCompletedWorkSelect?FromDate=${FromDate}&ToDate=${ToDate}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_WardData=${M_WardData ? M_WardData : '0'}&Flag=${Flag}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                // handleGetPdfUrl(result.url)
                return result.data
            } else {
                return result
            }
        })
})

const GardenCompletedWorkReportTableDataSlice = createSlice({
    name: "GardenCompletedWorkReportTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GardenCompletedWorkReportTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(GardenCompletedWorkReportTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(GardenCompletedWorkReportTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GardenCompletedWorkReportTableDataReducer = GardenCompletedWorkReportTableDataSlice.reducer

// PDF Report

export const GardenCompletedWorkPDFReportTableDataAPI = createAsyncThunk("GardenCompletedWorkPDFReportTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_ZoneID,
        M_ContractorID,
        M_WorkTypeID,
        M_AssetTypeID,
        M_WardData,
        M_UserID,
        token,
        Flag,
        handleGetPDFUrl
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_RM_T_UploadAssignWorkCompletedWorkSelect?FromDate=${FromDate}&ToDate=${ToDate}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_WardData=${M_WardData ? M_WardData : '0'}&Flag=${Flag}&M_UserID=${M_UserID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300) {
                handleGetPDFUrl(result.url)
                return result.data
            } else {
                handleGetPDFUrl('')
                return result
            }
        })
})

const GardenCompletedWorkPDFReportTableDataSlice = createSlice({
    name: "GardenCompletedWorkPDFReportTableData",
    initialState: {
        isPDFLoading: false,
        PDFtableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GardenCompletedWorkPDFReportTableDataAPI.pending, (state, action) => {
            state.isPDFLoading = true;
        });
        builder.addCase(GardenCompletedWorkPDFReportTableDataAPI.fulfilled, (state, action) => {
            state.isPDFLoading = false;
            state.PDFtableData = action.payload;
        });
        builder.addCase(GardenCompletedWorkPDFReportTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isPDFLoading = false;
            state.isError = true;
            state.PDFtableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GardenCompletedWorkPDFReportTableDataReducer = GardenCompletedWorkPDFReportTableDataSlice.reducer