import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useAuthState } from '../../../../../../Helper/Context';
import { DesignationPostDataAPI } from '../../../../../../Redux/SidebarMasterSlice/DesignationSlice';
import { ContractorNameDDL } from '../../../../../../Components/CommonDDL/ContractorNameDDL';
import { WardNameDataDDL } from '../../../../../../Components/CommonDDL/WardNameDataDDL';
import { PumpingStationPostDataAPI } from '../../../../../../Redux/SidebarMasterSlice/PumpingStationSlice';
import { AstricSign } from '../../../../../../Helper/AstricSign';


export default function PumpingStationPopUp(props) {

    const { handleOnCloseClick, open, Flag, rowData, handleIsPost, apiFlag,handleClear } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();

    // const [rowData, setRowData] = useState(Flag === 'Update' ? rowData : '')

    const [ContractorName, setContractorName] = useState({
        DDL: [],
        ID: 0,
        Label: 'Select...'
    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_WardID : 0,
        Label: Flag === 'Update' ? rowData.wardName : "Select...",
    })

    const [PumpingStation, setPumpingStation] = useState(Flag === 'Update' ? rowData.pumpingStation : '')

    const [IsOpen, setIsOpen] = useState(false)

    // useEffect(() => {
    //     setPumpingStation(Flag === 'Update' ? rowData.pumpingStation : '')
    // }, [rowData, IsOpen])

    const handlePost = () => {
        const data = {

            M_SW_PumpingStationID: Flag === 'Update' ? rowData.m_sW_PumpingStationID : '0',
            M_WardID: WardName.ID,
            PumpingStation: PumpingStation,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleOnCloseClick:handleOnCloseClick
        }
        dispatch(PumpingStationPostDataAPI({ data }))
    }

    return (

        // <Popup
        //     onOpen={() => setIsOpen(!IsOpen)}
        //     contentStyle={{ top: '5%' }}
        //     className='master_pop_width '
        //     trigger={
        //         Flag === 'Update' ?
        //             <i className="fa-solid fa-pen-to-square"
        //             style={{ cursor: "pointer" }} title='Edit'
        //             >
        //             </i> :
        //             <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
        //                 style={{ color: "white", marginTop: '-2.5em' }}
        //             >
        //                 <text className="ml-2 poppins">Add</text>
        //             </i>
        //     }
        //     modal
        // >

        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="area_pop modal-dialog modal-xl">
                <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Pumping Station</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">

                                {/* <div className="col-md-6 col-lg-6">
                                            <div className="form-group">
                                                <ContractorNameDDL
                                                    ContractorName={ContractorName}
                                                    setContractorName={setContractorName}
                                                />
                                            </div>

                                        </div> */}
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <WardNameDataDDL
                                            WardName={WardName}
                                            setWardName={setWardName}
                                            rowData={rowData}
                                            Flag={Flag}
                                        />
                                    </div>

                                </div>
                                <div className="col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Pumping Station <AstricSign />:</b></label>
                                        <input type="text"
                                            className="form-control"
                                            id="PumpingStation"
                                            value={PumpingStation}
                                            onChange={(e) => setPumpingStation(e.target.value)}

                                        />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="mb-4 pt-4">
                                        <div className="float-end">
                                            <button className="submit_btn"
                                                onClick={() => (handlePost("Insert"))}

                                            >{Flag === "Update" ? "Update" : "Save"} </button>
                                            <button className=" cancel_btn ms-2" onClick={handleOnCloseClick} >Cancel</button>
                                        </div>
                                    </div> */}

                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">
                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => (handlePost(),handleClear())}
                                        disabled={
                                            WardName.ID == 0 ||PumpingStation ==''
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* )} */}
        </Popup>
    )

}