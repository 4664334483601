
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

// Get Table Data
export const DailyPreventiveWorkTableDataAPI = createAsyncThunk("DailyPreventiveWorkTableData", async ({ data }) => {
    const {
        M_WardID,
        FromkDate,
        TokDate,
        M_UsersID,
        token,
        From,
        To,
        Flag,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_KPIUploadPreventiveWork_Select?M_WardID=${M_WardID ? M_WardID : '1'}&FromkDate=${FromkDate}&TokDate=${TokDate}&M_UsersID=${M_UsersID}&Flag=${Flag}&From=${From}&To=${To}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const DailyPreventiveWorkTableDataSlice = createSlice({
    name: "DailyPreventiveWorkTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(DailyPreventiveWorkTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(DailyPreventiveWorkTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(DailyPreventiveWorkTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const DailyPreventiveWorkTableDataReducer = DailyPreventiveWorkTableDataSlice.reducer


// New Daily Preventive Screen GET Api

export const NewScreenDailyPreventiveWorkTableDataAPI = createAsyncThunk("NewScreenDailyPreventiveWorkTableData", async ({ data }) => {
    const {
        T_KPIUploadDailyWorkID,
        FromDate,
        ToDate,
        M_UserID,
        M_ZoneID,
        M_OfficerID,
        M_DesignationID,
        Language,
        token,
        From,
        To,
        Flag,
        setGridData,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_KPIUploadDailyWork_Select?T_KPIUploadDailyWorkID=${T_KPIUploadDailyWorkID ? T_KPIUploadDailyWorkID : 0}&FromDate=${FromDate}&ToDate=${ToDate}&M_ZoneID=${M_ZoneID ? M_ZoneID : 0}&M_OfficerID=${M_OfficerID ? M_OfficerID : 0}&M_DesignationID=${M_DesignationID ? M_DesignationID : 0}&M_UserID=${M_UserID}&Language=${Language}&Flag=${Flag}&From=${From}&To=${To}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                setGridData && setGridData(result.data)
                return result.data
                // console.log('result.data.result',result.data.result)
            } else {
                return result
            }
        })
})

const NewScreenDailyPreventiveWorkTableDataSlice = createSlice({
    name: "NewScreenDailyPreventiveWorkTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NewScreenDailyPreventiveWorkTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(NewScreenDailyPreventiveWorkTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(NewScreenDailyPreventiveWorkTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const NewScreenDailyPreventiveWorkTableDataReducer = NewScreenDailyPreventiveWorkTableDataSlice.reducer

//   PopUp GET Api


export const NewScreenDailyPreventiveWorkCountPopupDataAPI = createAsyncThunk("NewScreenDailyPreventiveWorkCountPopupData", async ({ data }) => {
    const {
        T_KPIUploadDailyWorkID,
        M_WorkTypeID,
        M_UserID,
        Language,
        token,
        To,
        Flag,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_T_KPIUploadDailyWork_CheckList_Select?T_KPIUploadDailyWorkID=${T_KPIUploadDailyWorkID ? T_KPIUploadDailyWorkID : 0}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : 0}&M_UserID=${M_UserID}&Language=${Language}&Flag=${Flag}&To=${To}`, requestOptions)

        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const NewScreenDailyPreventiveWorkCountPopupDataSlice = createSlice({
    name: "NewScreenDailyPreventiveWorkCountPopupData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(NewScreenDailyPreventiveWorkCountPopupDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(NewScreenDailyPreventiveWorkCountPopupDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(NewScreenDailyPreventiveWorkCountPopupDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const NewScreenDailyPreventiveWorkCountPopupDataReducer = NewScreenDailyPreventiveWorkCountPopupDataSlice.reducer

