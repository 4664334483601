
import { useEffect } from 'react'
import Select from 'react-select'
import { ProjectNameDDLAPI, SectorDDLAPI, WardNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'

export const ProjectNameDataDDL = (props) => {
    const { ProjectName, setProjectName, WardName, PopUpField, rowData, Flag, IsPost } = props
    
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token }
        dispatch(ProjectNameDDLAPI({ data }))
    }, [IsPost])

    const { ProjectData } = useSelector(state => state.ProjectNameDDLData)

    useEffect(() => {
        handleProjectDDL()
    }, [ProjectData])

    const handleProjectDDL = () => {
        if (ProjectData && ProjectData.table && ProjectData.table.length > 0) {
            let list = ProjectData.table.map((item, index) => ({
                value: item.projectID,
                label: item.projectName,
            }))

            setProjectName({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_ProjectID : 0,
                Label: Flag === 'Update' ? rowData.projectName : "Select...",
            })
        }
        else {
            setProjectName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Project Name</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                maxMenuHeight={150}
                value={{ value: ProjectName.ID, label: ProjectName.Label }}
                onChange={(e) => {
                    e ?
                        setProjectName({ ...ProjectName, ID: e.value, Label: e.label })
                        :
                        setProjectName({ ...ProjectName, ID: 0, Label: "Select..." })

                }}
                options={ProjectName.DDL}
            />
        </div>
    )
}