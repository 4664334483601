import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";

// Get Table Data
export const ChemicalSupplyTableDataAPI = createAsyncThunk("ChemicalSupplyTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_WS_JunctionID,
        M_UserID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_WS_T_WS_ChemicalSupply_Select?FromDate=${FromDate ? FromDate : ''}&ToDate=${ToDate ? ToDate :''}&M_WardID=${M_WardID ? M_WardID : '0'}&M_WS_JunctionID=${M_WS_JunctionID ? M_WS_JunctionID :'0'}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ChemicalSupplyTableDataSlice = createSlice({
    name: "ChemicalSupplyTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ChemicalSupplyTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ChemicalSupplyTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ChemicalSupplyTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ChemicalSupplyTableDataReducer = ChemicalSupplyTableDataSlice.reducer


export const ChemicalSupplyPostDataAPI = createAsyncThunk("ChemicalSupplyPostData", async ({ data }) => {
    const {
        T_WS_ChemicalSupplyID,
        Chroline,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_ChemicalSupplyID", T_WS_ChemicalSupplyID);
    formdata.append("Chroline", Chroline);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/Post_WEB_WS_T_WS_ChemicalSupply_Update`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const ChemicalSupplyPostDataSlice = createSlice({
    name: "ChemicalSupplyPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ChemicalSupplyPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ChemicalSupplyPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ChemicalSupplyPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ChemicalSupplyPostDataReducer = ChemicalSupplyPostDataSlice.reducer

// -----------------Delete API ---------------

export const ChemicalSupplyDeleteAPI = createAsyncThunk("ChemicalSupplyDelete", async ({ data }) => {
    const {
        T_WS_ChemicalSupplyID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_ChemicalSupplyID", T_WS_ChemicalSupplyID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/WEB_WS_T_WS_ChemicalSupply_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const ChemicalSupplyDeleteSlice = createSlice({
    name: "ChemicalSupplyDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ChemicalSupplyDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ChemicalSupplyDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(ChemicalSupplyDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ChemicalSupplyDeleteReducer = ChemicalSupplyDeleteSlice.reducer