
import Header from "../../../../Components/Header/Header";
import Sidebar from "../../../../Components/Sidebar/Sidebar";
import Select from 'react-select'
import React, { useEffect, useReducer, useState } from 'react'
import GardenPopup from "../GardenPopup/GardenPopup";
import { GardenMasterDeleteAPI, GardenMasterTableDataAPI, GardenMasterTableExportDataAPI } from "../../../../Redux/MasterSlice/GardenMasterSlice";
import { useAuthState } from "../../../../Helper/Context";
import { useDispatch, useSelector } from "react-redux";
import ViewURL from "../../../../Components/ViewURL/ViewURL";
import DeletePopup from "../GardenPopup/DeletePopup";
import { WardNameDataDDL } from "../../../../Components/CommonDDL/WardNameDataDDL";
import { Pegination } from "../../../../Components/Pegination/Pegination";
import { Loading } from "../../../../Helper/Loading";
import MapView2 from "../../../../Components/MapView/MapView2";
import { useSearchParams } from "react-router-dom";
import { MaintainByDataDDL } from "../../../../Components/CommonDDL/MaintainByDDL";
import { useTranslation } from "react-i18next";
import { WardnameHeaderDDL } from "../../../../Components/CommonDDL/WardnameHeaderDDL";
import { DashBoardScreenWardDDL } from "../../../../Components/CommonDDL/DashboardScreenWardDDL";
import CommonDashboardScreenWardDDL from "../../../../Components/CommonDDL/CommonDashboardScreenWardDDL";
import { RoadDividerMasterExportData } from "./RoadDividerExportData";

export default function RoadDividerData() {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch()

    const [GardenMasterPopupShow, setGardenMasterPopupShow] = useState('')
    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [rowNo, setrowNo] = useState(1)
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')
    const [ShowMap, setShowMap] = React.useState(false)
    const [LocationData, setLocationData] = React.useState({})

    const [YearValue, setYearValue] = useState(0)

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ZWard = searchParams.get("ZoneName")
    let ZLable = searchParams.get("ZLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setGardenMasterPopupShow(true)
    }

    const handleEditOnClick = (item) => {
        setGardenMasterPopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }
    const handleOnCloseClick = () => {
        setGardenMasterPopupShow(false)
    }

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })
    const [ZoneName, setZoneName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [MaintainBy, setMaintainBy] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [WardName.ID, ZoneNameDDLID.ID, MaintainBy.ID])

    const handlerClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setFrom(1)
        setTo(10)
        setWardName({
            ...WardName,
            ID: localStorage.getItem('WardID') ? localStorage.getItem('WardID') : 0,
            Label: localStorage.getItem('WardLabel') ? localStorage.getItem('WardLabel') : "Select...",
        })
        setZoneNameDDLID({
            ...ZoneNameDDLID,
            ID: ZWard && ZWard ? ZWard : '0',
            Label: ZLable && ZLable ? ZLable : "Select...",
        })
        setMaintainBy({
            ...MaintainBy,
            ID: 0,
            Label: 'Select...',
        })
    }

    const handleLocation = (item) => {
        if (item.assetLatitude && item.assetLongitude) {
            setShowMap(true)
            setLocationData({
                latitude: item.assetLatitude,
                longitude: item.assetLongitude
            })
        }
    }

    const handleCloseMap = () => {
        setShowMap(false)
    }

    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }

    const handleDeleteApi = (item) => {
        const data = {
            M_AssetID: item.m_AssetID,
            M_UserID: UserID,
            handleIsPost: handleIsPost,
            token: token,
        }
        dispatch(GardenMasterDeleteAPI({ data }))
    }

    useEffect(() => {
        const data = {
            UserID: UserID,
            token: token,
            From: From,
            To: To,
            M_AssetID: 0,
            M_AssetTypeID: '2',
            M_WardID: WardName.ID,
            M_ZoneID: ZoneNameDDLID.ID,
            // M_GD_SectorID: SectorName.ID,
            MaintainByIndicatorAgencyID: MaintainBy.ID,
            Language: Language.Label,
            handleGetExport: handleGetExport
        }
        { Language.Label != '' && ZoneNameDDLID.ID != 0 && dispatch(GardenMasterTableDataAPI({ data })) }
    }, [IsPost, To, WardName.ID, ZoneNameDDLID.ID, MaintainBy.ID, IsClear, Language.Label])

    const { tableData, isloading } = useSelector(state => state.GardenMasterTableData)

    const handleGetExport = () => {
        const data = {
            UserID: UserID,
            token: token,
            From: From,
            To: '99999',
            M_AssetID: 0,
            M_AssetTypeID: '2',
            M_WardID: WardName.ID,
            M_ZoneID: ZoneNameDDLID.ID,
            // M_GD_SectorID: SectorName.ID,
            MaintainByIndicatorAgencyID: MaintainBy.ID,
            Language: Language.Label,
        }
        { Language.Label != '' && ZoneNameDDLID.ID != 0 && dispatch(GardenMasterTableExportDataAPI({ data })) }
    }
    // , [IsPost, To, WardName.ID, ZoneNameDDLID.ID, MaintainBy.ID, IsClear, Language.Label])

    const { ExportTableData, isExportLoading } = useSelector(state => state.GardenMasterTableExportData)

    return (
        <>
            {isloading && <Loading />}

            <div>
                <Header
                    DashboardHeading="Garden Maintainance"
                    setYearValue={setYearValue}
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar
                        ProjectTypeId={ProjectTypeId}
                        Language={Language}
                    />
                    <div className="content1 content" id="">
                        <div className="data_mob">
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div>
                                    <div className="col-6">
                                        <h5 className="ps-1 pt-1">{t('Dashboard.Pcmc_Assets.Road_Divider_Name')}</h5>
                                    </div>
                                    <div className="float-end butt">

                                        <span className="pe-5 fw-bold Total_count text-dark">
                                            Total Road Divider : <span className="fw-bold Total_count">{(tableData && tableData.table) ? tableData.table[0].totalCount : 0}</span>
                                        </span>
                                        {
                                            RoleID === '1' ?
                                                <button className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                                                    style={{ color: "white" }}
                                                    onClick={handleAddOnClick}
                                                >
                                                    <text className="ml-2 poppins">Add</text>
                                                </button>
                                                :
                                                ''
                                        }
                                        {
                                            isExportLoading ?
                                                <button type="button" className="btn btn-primary float-end mr-1 mb-1 ml-1" disabled>
                                                    <i
                                                        className="fa fa-refresh fa-spin"
                                                        style={{ marginRight: "5px" }}
                                                    />Loading</button>
                                                :
                                                ExportTableData && ExportTableData.table && ExportTableData.table.length > 0 &&
                                                <RoadDividerMasterExportData
                                                    ExcelData={ExportTableData}
                                                    name='Road Divider'
                                                // Date={Date}
                                                // searchName={searchName}
                                                />
                                        }
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                        <div className="col-12">

                                            <div className="row rounded2">
                                                <div className="col-12 col-md-12 col-lg-6">
                                                    {/* <WardnameHeaderDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        HWard={HWard}
                                                        WLable={WLable}
                                                        Language={Language}
                                                        ZoneName={ZoneName}

                                                    /> */}

                                                    {/* <DashBoardScreenWardDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        WLable={WLable}
                                                        HWard={HWard}
                                                        // flag={flag}
                                                        Language={Language}
                                                        M_ZoneID={1}
                                                        // Flag='ward'
                                                        Flag='WardDDL'
                                                    /> */}
                                                    <CommonDashboardScreenWardDDL
                                                        // setWardValue={setWardValue}
                                                        // setWardLabel={setWardLabel}
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        WLable={WLable}
                                                        HWard={HWard}
                                                        ZLable={ZLable}
                                                        ZWard={ZWard}
                                                        Language={Language}
                                                        ZoneNameDDLID={ZoneNameDDLID}
                                                        setZoneNameDDLID={setZoneNameDDLID}
                                                    // flag='Screen'
                                                    />
                                                </div>
                                                {/* <div className="col-12 col-md-6 col-lg-3">
                                                    <SectorDataDDL
                                                        SectorName={SectorName}
                                                        setSectorName={setSectorName}
                                                        WardName={WardName}
                                                        Language={Language}
                                                    />
                                                </div> */}
                                                <div className="col-md-6 col-12 col-lg-3">
                                                    <MaintainByDataDDL
                                                        MaintainBy={MaintainBy}
                                                        setMaintainBy={setMaintainBy}
                                                        Language={Language}
                                                        M_AssetTypeID='2'
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-2 mt-lg-4" >
                                                    <button type="button" className="btn-clear btn btn-primary"
                                                        onClick={handlerClear} >
                                                        Clear</button>
                                                </div>
                                            </div>
                                            <div className="row mob_table table-responsive pt-1">

                                                <table id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Ward_no')}</th>
                                                            {/* <th>{t('Dashboard.Pcmc_Assets.Sector_Name')}</th> */}
                                                            <th>{t('Dashboard.Pcmc_Assets.Area_Name')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Road_Divider_Name')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Area_sq')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Maintain_By')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Image')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Track')}</th>
                                                            {
                                                                RoleID == '1' ?
                                                                    <th>{t('Dashboard.Pcmc_Assets.Action')}</th>
                                                                    : ''
                                                            }

                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) =>
                                                                <tr>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                                                    <td>{item.wardName ? item.wardName : '-'}</td>
                                                                    {/* <td>{item.sectorName ? item.sectorName : '-'}</td> */}
                                                                    <td>{item.areaName ? item.areaName : '-'}</td>
                                                                    <td>{item.assetName ? item.assetName : '-'}</td>
                                                                    <td>{item.areaInSquareMetre ? item.areaInSquareMetre : '-'}</td>
                                                                    <td>{item.indicatorName ? item.indicatorName : '-'}</td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.assetPhoto == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye" title='View'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => handleShowImage(item.assetPhoto)}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.assetLatitude == null && item.assetLongitude == null) ?
                                                                                <span>-</span> :
                                                                                <>
                                                                                    <i style={{ cursor: 'pointer', fontSize: '1rem' }}
                                                                                        className="fa-solid fa-location-dot"
                                                                                        onClick={() => handleLocation(item)}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    {
                                                                        RoleID == '1' ?
                                                                            <td style={{ textAlign: "center", }}>
                                                                                <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => handleEditOnClick(item)}
                                                                                >
                                                                                </i>
                                                                                <DeletePopup
                                                                                    open={DeletePopupShow}
                                                                                    item={item}
                                                                                    handleDeleteApi={handleDeleteApi}
                                                                                />
                                                                            </td>
                                                                            : ''
                                                                    }
                                                                </tr>
                                                            ) :
                                                                <>No Found data</>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    ShowMap ?
                        <MapView2
                            LoactionData={LocationData}
                            ShowMap={ShowMap}
                            handleCloseMap={handleCloseMap}
                            currEmployeeName=''
                        />
                        : ''
                }

                {
                    GardenMasterPopupShow ?
                        <GardenPopup
                            open={GardenMasterPopupShow}
                            GardenMasterFlag='GardenMaster'
                            popupHeading={t('Dashboard.Pcmc_Assets.Road_Divider_Name')}
                            M_AssetTypeID='2'
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                            AreaMeter={t('Dashboard.Pcmc_Assets.Area_sq')}
                        />
                        : <></>
                }

                {
                    showImagePopup ?
                        <ViewURL
                            Heading="Asset Photo"
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''
                }
            </div >
        </>
    )
}