
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import Header from '../../../../../../Components/Header/Header'
import Sidebar from '../../../../../../Components/Sidebar/Sidebar'
import RoadMasterWardPopup from './RoadMasterWardPopup'
import DeletePopup from '../../../GardenPopup/DeletePopup'

export default function RoadMasterWardData() {

    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [RoasMasterWardPopupShow, setRoasMasterWardPopupShow] = useState()
    const handleAddOnClick = () => {
        console.log("add")
        setRoasMasterWardPopupShow(true)
    }

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleClear = () => {
        // setCurrentPage(0)
        // setIsClear(!IsClear)

        setWardName({
            ...WardName,
            ID: 0,
            Label: 'Select...',
        })
        // console.log("call")
    }

    return (
        <div>
            <Header
                DashboardHeading="Road Maintainance"
            />
            <div className="wrapper">
                <Sidebar />
                <div className="content">
                    {/* <!-- [ breadcrumb ] start --> */}
                    <div className="page-header ">
                        <div className="page-block">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    {/* <div className="page-header-title">
                                        <h5 className="ps-2 data_mob">MASTER */}

                                            {/* <span className="float-end fs-6">
                                                <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                        :
                                                        setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                }}
                                                options={SelectWard.DDL}
                                            />

                                            </span> */}
                                        {/* </h5>

                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- [ Main Content ] start --> */}
                    <div className='data_mob'>
                        <div className="row m-1 pb-4 master_border">
                            <div>
                                <h5 className="ps-1 py-2">Prabhag No Master
                                <button type="button" className="btn btn-primary float-end mr-1 mb-1">Export</button>
                                </h5>
                                {/* <div className="float-end butt"> */}
                                    {/* <i class="fa-solid fa-plus add_btn" style={{ color: "#010813", fontSize: '17px' }}><span className="ml-2">Add</span></i> */}
                                    {/* <RoadMasterWardPopup
                                    open={RoasMasterWardPopupShow} /> */}
                                {/* </div> */}
                            </div>
                            <div className="row m-0 pt-1">
                                <div className="col-12 py-0 rounded rounded-2 shadow"
                                    style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                    <div className="col-12">

                                        <div className="row py-1 rounded2">
                                            <div className="col-lg-3 col-md-6">
                                                <label for=""><b>Prabhag No :</b></label>
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    // className="ddl_width"
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: WardName.ID, label: WardName.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setWardName({ ...WardName, ID: e.value, Label: e.label })
                                                            :
                                                            setWardName({ ...WardName, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={WardName.DDL}
                                                />

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-1 mt-lg-4 mt-md-4 mt-1" >
                                                <button type="button"                  
                                                    className="btn-clear btn btn-primary"
                                                    onClick={handleClear}>Clear</button>

                                            </div>

                                        </div>
                                        <div className="row mob_table table-responsive pt-1">

                                            <table id="totalordergenerated"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                className="table table-bordered">

                                                <thead>
                                                    <tr>
                                                        <th className="sr_no_col">Sr No.</th>
                                                        <th>Prabhag No</th>
                                                        <th>Contractor Name</th>
                                                        {/* <th>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{textAlign:"center"}}>1</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                        <td></td>
                                                        <td></td>

                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>



    )
}