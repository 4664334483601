import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function WorkProgressCard({ count, heading, cardName, imgPath, navLink, countText, workProgressNavigate }) {
    const navigate = useNavigate()

    const handleWorkProgressCardClick = () => {
        navigate(navLink)
    }
    return (

        <div style={{  cursor: 'pointer' }} className="mt-3 mb-1 p-3 shadow blue_borders bg-white mb-md-2 daily-card-width" onClick={() => workProgressNavigate(heading)}>
            <div className="d-flex flex-column justify-content-between" >
                <div className="text-center pb-3" >
                    <div className="d-flex justify-content-between align-items-center">
                        <h4>{count}</h4>
                        <img className={cardName} style={{ height: '30px', width: '30px' }} src={imgPath} alt="" />
                    </div>

                </div>
                <div style={{ borderLeft: '2px solid #d6bea7' }}>
                    <h6 className="text-black ml-3"><b>{heading}</b></h6>
                </div>
            </div>
        </div>


    )
}


