import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React from 'react'
import { useState } from 'react';
import { useAuthState } from '../../../../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { SeverageWorkTypePostDataAPI } from '../../../../../../../Redux/SeverageDashboardSlice/SeverageWorkTypeMasterSlice';
import { AstricSign } from '../../../../../../../Helper/AstricSign';

export default function SewerageWorkTypePopup(props) {

  const { handleOnCloseClick, open, EditIcon, Flag, GardenMasterFlag, popupHeading, M_AssetTypeID, handleIsPost, apiFlag, rowData } = props

  const userDetails = useAuthState();
  const { UserID, token, } = userDetails
  const dispatch = useDispatch();

  const [SeverageWorkTypeName, setSeverageWorkTypeName] = useState(Flag === 'Update' ? rowData.workType : '')

  const [IsOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setSeverageWorkTypeName(Flag === 'Update' ? rowData.workType : '')
  }, [rowData, IsOpen])

  const handlePost = () => {
    const data = {
      M_SW_WorkTypeID: Flag === 'Update' ? rowData.m_sW_WorkTypeID : '0',
      WorkType: SeverageWorkTypeName,
      M_UserID: UserID,
      token: token,
      Flag: Flag === 'Update' ? 'Update' : 'Insert',
      handleIsPost: handleIsPost,
      handleOnCloseClick: handleOnCloseClick
    }
    dispatch(SeverageWorkTypePostDataAPI({ data }))
  }

  return (
    // <Popup
    //   className=''
    //   onOpen={() => setIsOpen(!IsOpen)}
    //   contentStyle={{ width: '55%', height: '60%', top: '5%' }}
    //   trigger={
    //     Flag === 'Update' ?
    //       <i className="fa-solid fa-pen-to-square" style={{ cursor: 'pointer' }} title='Edit'></i>
    //       :
    //       <i className="fa-solid fa-plus float-end btn btn-secondary mb-1"
    //         style={{ color: "white" }}
    //       >
    //         <text className="poppins">Add</text>
    //       </i>

    //   } modal>

    // {
    //   close => (
    // n_popup-content 
    <Popup
      open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
    >
      <div className="severage_mob_pop modal-dialog modal-dialog-scrollable modal-xl">
        <div className="modal-content">
          <div className="modal-header pl-4 p-1">
            <h4 className="modal-title" id="staticBackdropLabel">Work Type</h4>
            <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
          </div>

          <div className="modal-body">
            <div className='inner-modal-body'>

              <div className="row details-row">

                <div className="col-md-6 col-12 col-lg-6">
                  <div className="form-group">
                    <label className="d-block" htmlFor="NameofDepartment"><b>Work Type <AstricSign /> :</b></label>
                    <input type="text"
                      className="form-control"
                      id="SeverageWorkTypeName"
                      value={SeverageWorkTypeName}
                      onChange={(e) => setSeverageWorkTypeName(e.target.value)}

                    />
                  </div>
                </div>

              </div>

              {/* <div className="col-12 col-lg-12">
                <div className="btn-action d-flex justify-content-end mt-lg-3">

                  <button type='button'
                    className="btn allBtn  float-right submit_btn"
                    onClick={() => handlePost()}
                  // disabled={
                  //     Designation.ID == 0 || EmployeeField.EmployeeName == ''
                  // }
                  >{Flag === "Update" ? "Update" : "Save"} </button>

                  <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                </div>
              </div> */}

              <div className="col-12 col-lg-12">
                <div className="btn-action d-flex justify-content-end mt-lg-3">

                  <button type='button'
                    className="btn allBtn  float-right submit_btn"
                    onClick={() => handlePost()}
                    disabled={
                      SeverageWorkTypeName == '' 
                    }
                  >{Flag === "Update" ? "Update" : "Save"} </button>

                  <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      {/* )} */}
    </Popup>
  )

}