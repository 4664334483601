import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";

// Get Table Data
export const EnergyConsumptionTableDataAPI = createAsyncThunk("EnergyConsumptionTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_WS_PumpStationID,
        M_UserID,
        From,
        To,
        token
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_WS_T_WS_EnergyConsumption_Select?FromDate=${FromDate}&ToDate=${ToDate}&M_WardID=${M_WardID}&M_WS_PumpStationID=${M_WS_PumpStationID}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const EnergyConsumptionTableDataSlice = createSlice({
    name: "EnergyConsumptionTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EnergyConsumptionTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EnergyConsumptionTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(EnergyConsumptionTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const EnergyConsumptionTableDataReducer = EnergyConsumptionTableDataSlice.reducer


export const EnergyConsumptionPostDataAPI = createAsyncThunk("EnergyConsumptionPostData", async ({ data }) => {
    const {
        T_WS_EnergyConsumptionID,
        ElectricConsumption,
        ConsumptionKWH,
        PowerFactor,
        PowerFactorIncentive,
        DemandPenalty,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_EnergyConsumptionID", T_WS_EnergyConsumptionID);
    formdata.append("ElectricConsumption", ElectricConsumption);
    formdata.append("ConsumptionKWH", ConsumptionKWH);
    formdata.append("PowerFactor", PowerFactor);
    formdata.append("PowerFactorIncentive", PowerFactorIncentive);
    formdata.append("DemandPenalty", DemandPenalty);
    formdata.append("M_UserID", M_UserID);


    var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/Post_WEB_WS_T_WS_EnergyConsumption_Update`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const EnergyConsumptionPostDataSlice = createSlice({
    name: "EnergyConsumptionPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EnergyConsumptionPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(EnergyConsumptionPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(EnergyConsumptionPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const EnergyConsumptionPostDataReducer = EnergyConsumptionPostDataSlice.reducer

// -----------------Delete API ---------------

export const EnergyConsumptionDeleteAPI = createAsyncThunk("EnergyConsumptionDelete", async ({ data }) => {
    const {
        T_WS_EnergyConsumptionID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_EnergyConsumptionID", "1");
    formdata.append("M_UserID", "1");

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/WEB_WS_T_WS_EnergyConsumption_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const EnergyConsumptionDeleteSlice = createSlice({
    name: "EnergyConsumptionDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(EnergyConsumptionDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(EnergyConsumptionDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(EnergyConsumptionDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const EnergyConsumptionDeleteReducer = EnergyConsumptionDeleteSlice.reducer