import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../../Helper/ToastMessage";

// Get Table Data
export const ValveOperationTableDataAPI = createAsyncThunk("ValveOperationTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WardID,
        M_WS_LocationID,
        M_UserID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_WS_T_WS_ValveOperation_Select?FromDate=${FromDate ? FromDate : ''}&ToDate=${ToDate ? ToDate : ''}&M_WardID=${M_WardID ? M_WardID : ''}&M_WS_LocationID=${M_WS_LocationID ? M_WS_LocationID : '0'}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ValveOperationTableDataSlice = createSlice({
    name: "ValveOperationTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ValveOperationTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ValveOperationTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ValveOperationTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ValveOperationTableDataReducer = ValveOperationTableDataSlice.reducer

// -----------------Delete API ---------------

export const ValveOperationDeleteAPI = createAsyncThunk("ValveOperationDelete", async ({ data }) => {
    const {
        T_WS_ValveOperationID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("T_WS_ValveOperationID", T_WS_ValveOperationID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/APKDashboard/WEB_WS_T_WS_ValveOperation_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const ValveOperationDeleteSlice = createSlice({
    name: "ValveOperationDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ValveOperationDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ValveOperationDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(ValveOperationDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ValveOperationDeleteReducer = ValveOperationDeleteSlice.reducer