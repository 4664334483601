
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { PumpingStationDDLAPI } from '../../Redux/DDLSlice'
import { AstricSign } from '../../Helper/AstricSign'

export const SeveragePumpingStationDataDDL = (props) => {
    const { PumpingStation, setPumpingStation, rowData, Flag, IsPost, WardName } = props
    // console.log(rowData && rowData.m_sW_PumpingStationID)
    // console.log(WardName)
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token, WardName }
        if (WardName != 0){
            dispatch(PumpingStationDDLAPI({ data }))
        }
    }, [IsPost, WardName])

    const { PumpingData } = useSelector(state => state.PumpingStationData)

    useEffect(() => {
        handlePumpingStationDDL()
    }, [PumpingData])

    const handlePumpingStationDDL = () => {
        // console.log(DeptDDLDataa)
        if (PumpingData && PumpingData.table && PumpingData.table.length > 0) {
            let list = PumpingData.table.map((item, index) => ({
                value: item.m_sW_PumpingStationID,
                label: item.pumpingStation,
            }))

            setPumpingStation({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_sW_PumpingStationID : 0,
                Label: Flag === 'Update' ? rowData.pumpingStation : "Select...",
            })
        }
        else {
            setPumpingStation({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Pumping Station {Flag && <AstricSign />} :</b></label>
            <Select
                isClearable
                // isRtl={isRtl}

                isSearchable
                maxMenuHeight={150}
                value={{ value: PumpingStation.ID, label: PumpingStation.Label }}
                onChange={(e) => {
                    e ?
                        setPumpingStation({ ...PumpingStation, ID: e.value, Label: e.label })
                        :
                        setPumpingStation({ ...PumpingStation, ID: 0, Label: "Select..." })

                }}
                options={PumpingStation.DDL}
            />

        </div>
    )
}