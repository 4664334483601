import { useNavigate, useSearchParams } from 'react-router-dom'
import GardenReopenCard from './GardenReopenCard'

export default function GardenReopenCardManage(props) {

    const { Countdata, HeaderMonthID, Language, MonthValue, YearValue, YearLabel, MonthLabel, WardValue, WardLabel, ProjectTypeId, DashboardName } = props

    const [searchParams] = useSearchParams()
    let Lang = searchParams.get("Lang")

    const navigate = useNavigate()

    const ReopenWorkProgressNavigate = (name, ApiFlag, screenFlag) => {
        navigate(`/gardenreopenpending?name=${name}&ApiFlag=${ApiFlag}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}&Lang=${Language.Label}`)
    }
    return (
        <div className="col-12 col-lg-7 ">
            <div className="row pb-3">
                <div className="col-12">
                    <h5 className="ps-2 py-2 pt-3">Reopen Work Progress</h5>
                </div>
                <div className="col-12 col-lg-6">
                    <GardenReopenCard
                        title="Reopen Pending"
                        ApiFlag="Pending"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/ReopenPending2.png'
                        count={Countdata.ReopenAssignWork_Pending_TotalCount ? Countdata.ReopenAssignWork_Pending_TotalCount : '0'}
                        // navLink='/gardenreopenpending'
                        ReopenWorkProgressNavigate={ReopenWorkProgressNavigate}
                    />
                </div>

                <div className="col-12 col-lg-6">
                    <GardenReopenCard
                        title="Reopen Completed"
                        ApiFlag="Completed"
                        cardName='py-2 wwork_img cursor'
                        imgPath='./assets/img/ReopenCompleted.png'
                        count={Countdata.ReopenAssignWork_Completed_TotalCount ? Countdata.ReopenAssignWork_Completed_TotalCount : '0'}
                        navLink='/gardereopencomplete'
                        ReopenWorkProgressNavigate={ReopenWorkProgressNavigate}
                    />
                </div>
            </div>
        </div>

    )

}
