
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'
import { NewRoleDDLAPI } from '../../Redux/DDLSlice'

export const NewRoleDataDDL = (props) => {
    const { NewRoleDDL, setNewRoleDDL, Flag, IsPost, NewDepartment,rowData } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {UserID, token, M_DepartmentID: NewDepartment.ID }
        dispatch(NewRoleDDLAPI({ data }))
    }, [IsPost, NewDepartment.ID])

    const { NewRoleDDLData } = useSelector(state => state.NewRoleDDLData)

    useEffect(() => {
        handleNewRoleDDL()
    }, [NewRoleDDLData])

    const handleNewRoleDDL = () => {
        if (NewRoleDDLData && NewRoleDDLData.table && NewRoleDDLData.table.length > 0) {
            let list = NewRoleDDLData.table.map((item, index) => ({
                value: item.id,
                label: item.role,
            }))

            setNewRoleDDL({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_RoleID : 0,
                Label: Flag === 'Update' ? rowData.m_RoleName : "Select...",
            })
        }
        else {
            setNewRoleDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block " htmlFor="NameofEmployee"><b>Role</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width2"
                isSearchable
                maxMenuHeight={150}
                value={{ value: NewRoleDDL.ID, label: NewRoleDDL.Label }}
                onChange={(e) => {
                    e ?
                        setNewRoleDDL({ ...NewRoleDDL, ID: e.value, Label: e.label })
                        :
                        setNewRoleDDL({ ...NewRoleDDL, ID: 0, Label: "Select..." })

                }}
                options={NewRoleDDL.DDL}
            />
        </div>
    )
}