
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { useTranslation } from 'react-i18next'
import { EquipmentNameDDLAPI } from '../../Redux/DDLSlice'

export const EquipmentNameDDL = (props) => {
    const { EquipmentName, setEquipmentName, rowData, Flag, IsPost, Language, IsClear } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_UserID: UserID,
            token: token,
            Language: Language.Label,
        }
        { Language.Label != '' && dispatch(EquipmentNameDDLAPI({ data })) }
    }, [IsPost, IsClear, Language.Label])

    const { EquipmentNameDDLData } = useSelector(state => state.EquipmentNameDDLData)

    useEffect(() => {
        handleEquipmentNameDDL()
    }, [EquipmentNameDDLData])


    const handleEquipmentNameDDL = () => {
        if (EquipmentNameDDLData && EquipmentNameDDLData.table && EquipmentNameDDLData.table.length > 0) {
            let list = EquipmentNameDDLData.table.map((item, index) => ({
                value: item.m_AssetWiseEquipmentID,
                label: item.equipmentName,
            }))

            setEquipmentName({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_AssetWiseEquipmentID : 0,
                Label: Flag === 'Update' ? rowData.equipmentName : 'Select...',
            })
        }
        else {
            setEquipmentName({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Equipment Name</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width"
                isSearchable
                maxMenuHeight={150}
                value={{ value: EquipmentName.ID, label: EquipmentName.Label }}
                onChange={(e) => {
                    e ?
                        setEquipmentName({ ...EquipmentName, ID: e.value, Label: e.label })
                        :
                        setEquipmentName({ ...EquipmentName, ID: 0, Label: "Select..." })

                }}
                options={EquipmentName.DDL}
            />
        </div>
    )
}