
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import WaterQuantityPreventiveWorkPopup from './WaterQuantityPreventiveWorkPopup'
import Header from '../../../../../../Components/Header/Header'
import Sidebar from '../../../../../../Components/Sidebar/Sidebar'
import DeletePopup from '../../../GardenPopup/DeletePopup'


export default function WaterQuantityPreventiveWorkPage() {

    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [WaterQuantityPreventiveWorkPopupShow, setWaterQuantityPreventiveWorkPopupShow] = useState()
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')

    const [Ward, setWard] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [LocationName, setLocationName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const handleEditOnClick = () => {
        setWaterQuantityPreventiveWorkPopupShow(true)
        setapiFlag('Update')
        setrowData()
    }
    const handleOnCloseClick = () => {
        setWaterQuantityPreventiveWorkPopupShow(false)
    }

    return (
        <div>
            <Header
                DashboardHeading="Water Supply Maintainance"
            />
            <div className="wrapper">
                <Sidebar />
                <div className="content">
                    {/* <!-- [ breadcrumb ] start --> */}
                    <div className="page-header ">
                        <div className="page-block">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    {/* <div className="page-header-title">
                                        <h5 className="ps-2 data_mob">MASTER */}

                                    {/* <span className="float-end fs-6">
                                                <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                        :
                                                        setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                }}
                                                options={SelectWard.DDL}
                                            />

                                            </span> */}
                                    {/* </h5>

                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- [ Main Content ] start --> */}
                    <div className='data_mob'>
                        <div className="row m-1 pb-4 master_border">
                            <div className='col-8'>
                                <h5 className="mt-2">Water Quantity</h5>
                            </div>
                            <div className='col-4 mar_add'>
                                <div className="float-end butt">
                                    <button type="button" className="btn btn-primary ml-1 float-end">Export</button>
                                    {/* <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                                        style={{ color: "white" }}
                                    // onClick={handleAddOnClick}
                                    > Add</i> */}
                                </div>
                            </div>
                            <div className="row m-0 ">
                                <div className="col-12 py-0 rounded rounded-2 shadow"
                                    style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                    <div className="col-12">

                                        <div className="row py-1 rounded2">
                                            <div className="col-lg-2 col-md-6">
                                            <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>From Date :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id=""
                                                        />
                                                    </div>

                                            </div>
                                            <div className="col-lg-2 col-md-6">
                                            <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>To Date :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id=""
                                                        />
                                                    </div>

                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <label for=""><b>Ward :</b></label>
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    // className="ddl_width"
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: Ward.ID, label: Ward.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setWard({ ...Ward, ID: e.value, Label: e.label })
                                                            :
                                                            setWard({ ...Ward, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={Ward.DDL}
                                                />

                                            </div>
                                            <div className="col-lg-3 col-md-6">
                                                <label for=""><b>Junction Name :</b></label>
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    // className="ddl_width"
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: LocationName.ID, label: LocationName.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setLocationName({ ...LocationName, ID: e.value, Label: e.label })
                                                            :
                                                            setLocationName({ ...LocationName, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={LocationName.DDL}
                                                />

                                            </div>
                                            <div className="col-12 col-md-6 col-lg-1 mt-lg-4 mt-md-4 mt-1" >
                                                <button type="button"
                                                    className="btn-clear btn btn-primary"
                                                    // onClick={handleClear}
                                                    >
                                                        Clear</button>

                                            </div>

                                        </div>
                                        <div className="row mob_table table-responsive pt-1">

                                            <table id="totalordergenerated"
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                className="table table-bordered">

                                                <thead>
                                                    <tr>
                                                        <td colspan="3"></td>
                                                        <td colspan="3">Incoming Flow</td>
                                                        <td colspan="3">Outgoing Flow</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <th className="sr_no_col">Sr No.</th>
                                                        <th>Location</th>
                                                        <th>Pipe Size</th>
                                                        <th>Yesterday Reading</th>
                                                        <th>Today's Reading</th>
                                                        <th>Incoming Water in MLD</th>
                                                        <th>Yesterday Reading</th>
                                                        <th>Today's Reading</th>
                                                        <th>Outgoing Water in MLD</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ textAlign: "center" }}>1</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td style={{ textAlign: "center" }}>
                                                        <span>
                                                                            <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => handleEditOnClick()}
                                                                            >
                                                                            </i>
                                                                        </span>
                                                                        <span>
                                                                            <DeletePopup
                                                                                open={DeletePopupShow}
                                                                                // item={item}
                                                                                // handleDeleteApi={handleDeleteApi}
                                                                            />
                                                                        </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    WaterQuantityPreventiveWorkPopupShow ?
                        <WaterQuantityPreventiveWorkPopup
                            open={WaterQuantityPreventiveWorkPopupShow}                          
                            // popupHeading="Water Quality-Chlorine"                        
                            // handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                        />
                        : <></>
                }
            </div>
        </div>



    )
}