import Header from "../../../../Components/Header/Header"
import Sidebar from "../../../../Components/Sidebar/Sidebar"
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import ProjectWiseWorkNamePopup from "./ProjectWiseWorkNamePopup"
import DeletePopup from "../GardenPopup/DeletePopup"
import { ProjectNameDataDDL } from "../../../../Components/CommonDDL/ProjectNameDataDDL"
import { useAuthState } from "../../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { ProjectWiseWorkNameDeleteAPI, ProjectWiseWorkNameTableDataAPI } from "../../../../Redux/SidebarMasterSlice/ProjectWiseWorkName"
import { Loading } from "../../../../Helper/Loading"
import { Pegination } from "../../../../Components/Pegination/Pegination"
import { WorkNameDDL } from "../../../../Components/CommonDDL/WorkNameDDL"
import { ProjectTypeDataDDL } from "../../../../Components/CommonDDL/ProjectTypeDataDDL"
import { GDProjectNameDDL } from "../../../../Components/CommonDDL/GDProjectNameDDL"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const ProjectWiseWorkName = () => {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsPost, setIsPost] = useState(false)
    const [ProjectWiseWorkNamePopupShow, setProjectWiseWorkNamePopupShow] = useState()
    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')
    const [IsClear, setIsClear] = useState(false)

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setProjectWiseWorkNamePopupShow(true)
    }
    const handleEditOnClick = (item) => {
        setProjectWiseWorkNamePopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }

    const handleOnCloseClick = () => {
        setProjectWiseWorkNamePopupShow(false)
    }

    const [ProjectName, setProjectName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [ProjectType, setProjectType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [WorkSubType, setWorkSubType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WorkName, setWorkName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: Lang,
    })

    const handleIsPost = () => {
        setIsPost(!IsPost)
        // if (tableData && tableData.table && tableData.table.length > 0) {
        //     setCurrentPage(0)
        //     setFrom(1)
        //     setTo(10)
        // }
    }

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setProjectName({
            ...ProjectName,
            ID: 0,
            Label: 'Select...'
        })
        setWorkName({
            ...WorkName,
            ID: 0,
            Label: 'Select...'
        })
        setProjectType({
            ...ProjectType,
            ID: 0,
            Label: 'Select...'
        })
    }

    useEffect(() => {
        const data = {
            M_ProjectWiseWorkID: 0,
            M_ProjectID: ProjectName.ID,
            M_ProjectTypeID: 0,
            M_WorkID: WorkName.ID,
            WorkName: '',
            M_UsersID: UserID,
            token: token,
            From: From,
            To: To,
            Language: Lang
        }
        dispatch(ProjectWiseWorkNameTableDataAPI({ data }))
    }, [IsPost, To, ProjectName.ID, WorkName.ID, WorkName.ID,IsClear])

    const { tableData, isLoading } = useSelector(state => state.ProjectWiseWorkNameTableData)

    const handleDeleteApi = (item) => {
        const data = {
            M_ProjectWiseWorkID: item.m_ProjectWiseWorkID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost
        }
        dispatch(ProjectWiseWorkNameDeleteAPI({ data }))
    }

    const { isDeleteLoading } = useSelector(state => state.ProjectWiseWorkNameDeleteData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [ProjectName.ID, ProjectType.ID, WorkName.ID])

    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select Ward",
    // })

    return (
        <>
            {isLoading && <Loading />}
            {isDeleteLoading && <Loading />}
            <div>
                <Header
                    // DashboardHeading="Garden Maintainance"
                    DashboardName={DashboardName}
                />
                <div className="wrapper p-3">
                    <Sidebar
                        Language={Language}
                        ProjectTypeId={ProjectTypeId} />
                    <div className="content">
                        <div className="data_mob">
                            <div className="row col-12 m-1 py-1 pb-4 master_border">
                                <div>
                                    <h5 className="ps-1 py-1 ">Project Wise Work Name Master</h5>
                                    <div className="col-12 col-lg-7 float-end">
                                        <i className="fa-solid fa-plus btn btn-secondary float-end mb-2"
                                            style={{ color: "white", marginTop: '-2.5em' }}
                                            onClick={handleAddOnClick}
                                        >
                                            <text className="ml-2 poppins">Add</text>
                                        </i>
                                    </div>
                                </div>

                                <div className="row m-0">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12  ">

                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <GDProjectNameDDL
                                                        ProjectName={ProjectName}
                                                        setProjectName={setProjectName}
                                                        M_ProjectTypeID={ProjectType.ID}
                                                        DDLFlag='ProjectWiseWorkName'
                                                    />

                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3">
                                                    <WorkNameDDL
                                                        WorkName={WorkName}
                                                        setWorkName={setWorkName}
                                                        WorkType={WorkType}
                                                        WorkSubType={WorkSubType}
                                                        Language={Lang}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2 mt-md-4 mt-lg-4" >
                                                    <button type="button" className="btn-clear btn btn-primary"
                                                        onClick={handleClear}
                                                    >Clear</button>
                                                </div>
                                            </div>
                                            <div className="row mob_table table-responsive pt-1">

                                                <table id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            <th>Project Name</th>
                                                            <th>Work Type</th>
                                                            <th>Work Sub Type</th>
                                                            <th>Work Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => (
                                                                <tr style={{ width: '100%' }}>
                                                                    <td style={{ textAlign: "center" }}>{item.rowNum}</td>
                                                                    <td>{item.projectName ? item.projectName : '-'}</td>
                                                                    <td>{item.workTypeName ? item.workTypeName : '-'}</td>
                                                                    <td>{item.workSubTypeName ? item.workSubTypeName : '-'}</td>
                                                                    <td>{item.projectWorkname ? item.projectWorkname : '-'}</td>
                                                                    <td style={{ textAlign: "center", width: '7%' }}>
                                                                        <span>
                                                                            <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => handleEditOnClick(item)}
                                                                            >
                                                                            </i>
                                                                        </span>

                                                                        <DeletePopup
                                                                            open={DeletePopupShow}
                                                                            item={item}
                                                                            handleDeleteApi={handleDeleteApi}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            ))
                                                                :
                                                                <>No data found</>
                                                        }

                                                    </tbody>
                                                </table>

                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {
                    ProjectWiseWorkNamePopupShow ?
                        <ProjectWiseWorkNamePopup
                            open={ProjectWiseWorkNamePopupShow}
                            // popupHeading="Location Name"                         
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                            Language={Language}
                        />
                        : <></>
                }
            </div>
        </>
    )
}