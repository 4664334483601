
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { NewDepartmentDDLAPI } from '../../Redux/DDLSlice'

export const NewDepartmentDDL = (props) => {
    const { NewDepartment, setNewDepartment, rowData, Flag } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token }
        dispatch(NewDepartmentDDLAPI({ data }))
    }, [])

    const { NewDepartmentDDLData } = useSelector(state => state.NewDepartmentDDLData)

    useEffect(() => {
        handleNewDepartmentDDL()
    }, [NewDepartmentDDLData])

    const handleNewDepartmentDDL = () => {
        if (NewDepartmentDDLData && NewDepartmentDDLData.table && NewDepartmentDDLData.table.length > 0) {
            let list = NewDepartmentDDLData.table.map((item, index) => ({
                value: item.id,
                label: item.departmentName,
            }))

            setNewDepartment({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_DepartmentID : 0,
                Label: Flag === 'Update' ? rowData.departmentName : "Select...",
            })
        }
        else {
            setNewDepartment({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Department</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width"
                isSearchable
                maxMenuHeight={150}
                value={{ value: NewDepartment.ID, label: NewDepartment.Label }}
                onChange={(e) => {
                    e ?
                        setNewDepartment({ ...NewDepartment, ID: e.value, Label: e.label })
                        :
                        setNewDepartment({ ...NewDepartment, ID: 0, Label: "Select..." })

                }}
                options={NewDepartment.DDL}
            />
        </div>
    )
}