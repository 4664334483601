import React from 'react'
import { BaseUrl } from '../../Helper/BaseUrl'

export default function HeaderLogo() {
    return (
        <>
            <span href="" className="b-brand">
                {/* <!-- ========   change your logo hear   ============ --> */}
                {/* {
                    BaseUrl === "http://pcmcapi.cdatinfotech.com" || BaseUrl === "http://pcmcdemo.cdatinfotech.com" ? */}

                {
                    BaseUrl == "https://apiamravatimc.cdat.work" ?
                        <img src="../assets/img/amravati.png" alt="" className='ms-lg-3 mobile_none' style={{ width: '64px' }} />
                        :
                        <img src="../assets/img/PCMC_Logos-01.png" alt="" className='ms-lg-3 mobile_none' style={{ width: '64px' }} />

                }


                {/* :
                         <></>
                        // <img src="../assets/img/nmmc_logo.png" alt="" className="logo nmmc_md_logo" width="155px" />
                }  */}
            </span>
        </>
    )
}
