import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useAuthState } from '../../../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { WaterAuditPostDataAPI } from '../../../../../../Redux/WaterSupplyDashBoardSlice/PreventiveWorkWaterSupply/WaterAuditSlice';
import moment from 'moment'

export default function WaterAuditPreventiveWorkPopup(props) {

    const { handleOnCloseClick, open, Flag, handleIsPost, apiFlag, rowData } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [IsOpen, setIsOpen] = useState(false)


    const [WaterAuditTextField, setWaterAuditTextField] = useState({
        readingDate: Flag === 'Update' ? moment(rowData.readingDate).format("YYYY-MM-DD") : '',
        wardName: Flag === 'Update' ? rowData.wardName : '',
        junctionDetails: Flag === 'Update' ? rowData.junctionDetails : '',
        pipelineDetails: Flag === 'Update' ? rowData.pipelineDetails : '',
        recivedWaterMLD: Flag === 'Update' ? rowData.recivedWaterMLD : '',
        supplyWaterMLD:Flag === 'Update' ? rowData.supplyWaterMLD : '',
        non_RevenueWaterMLD:Flag === 'Update' ? rowData.non_RevenueWaterMLD : '',
    })

    const handleWaterAuditTextField = (e) => {
        setWaterAuditTextField({ ...WaterAuditTextField, [e.target.name]: e.target.value })
    }


    const handleClear = () => {
        // setIsClear(!IsClear)
        
        setWaterAuditTextField({
            locationName:'',
            pipeLine:'',
            recivedWaterMLD:'',
            supplyWaterMLD:'',
            non_RevenueWaterMLD:''
        })
    }

    const handlePost = () => {
        const data = {
            T_WS_WaterAuditID: Flag === 'Update' ? rowData.t_wS_WaterAuditID : '0',
            junctionDetails:WaterAuditTextField.junctionDetails,         
            RecivedWaterMLD: WaterAuditTextField.recivedWaterMLD,
            SupplyWaterMLD: WaterAuditTextField.supplyWaterMLD,
            Non_RevenueWaterMLD: WaterAuditTextField.non_RevenueWaterMLD,
            M_UserID: UserID,
            token: token,     
            handleIsPost: handleIsPost,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(WaterAuditPostDataAPI({ data }))
    }
       
    return (
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="water_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Water Audit</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">

                            <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Date :</b></label>
                                        <input type="text" className="form-control"
                                            id="exampleInputEmail1"
                                            name='readingDate'
                                            aria-describedby="emailHelp"
                                            value={WaterAuditTextField.readingDate}
                                            onChange={(e) => handleWaterAuditTextField(e)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6" >
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Prabhag No :</b></label>
                                        <input type="text" className="form-control"
                                            name='wardName'
                                            aria-describedby="emailHelp"
                                            value={WaterAuditTextField.wardName}
                                            onChange={(e) => handleWaterAuditTextField(e)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6" >
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Location Name :</b></label>
                                        <input type="text" className="form-control"
                                            name='locationName'
                                            aria-describedby="emailHelp"
                                            value={WaterAuditTextField.junctionDetails}
                                            onChange={(e) => handleWaterAuditTextField(e)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6" >
                                    <div className="form-group">                                     
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Pipe Line (mm) :</b></label>
                                        <input type="text" className="form-control"
                                            name='pipeLine'
                                            aria-describedby="emailHelp"
                                            value={WaterAuditTextField.pipelineDetails}
                                            onChange={(e) => handleWaterAuditTextField(e)}
                                        />
                                    </div>

                                </div>

                                <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Receive Water MLD :</b></label>
                                        <input type="text" className="form-control"
                                            name='recivedWaterMLD'
                                            value={WaterAuditTextField.recivedWaterMLD}
                                            onChange={(e) => handleWaterAuditTextField(e)}
                                        />
                                    </div>
                                </div>

                                <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Supply Water MLD :</b></label>
                                        <input type="text" className="form-control"
                                            name="supplyWaterMLD"
                                            value={WaterAuditTextField.supplyWaterMLD}
                                            onChange={(e) => handleWaterAuditTextField(e)}
                                        />
                                    </div>
                                </div>

                                <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Non-Revenue Water MLD :</b></label>
                                        <input type="text" className="form-control"
                                            name="non_RevenueWaterMLD"
                                            value={WaterAuditTextField.non_RevenueWaterMLD}
                                            onChange={(e) => handleWaterAuditTextField(e)}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">
                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => (handlePost(),handleClear())}
                                    >{Flag === "Update" ? "Update" : "Save"} </button>
                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </Popup>
    )

}