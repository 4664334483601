import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup as Pop, FeatureGroup, Polyline } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css"
import moment from "moment";
import { useAuthState } from "../../Helper/Context";
import { Loading } from "../../Helper/Loading";
import Popup from 'reactjs-popup'
import pin from './pin.png'
import flag from './dot.png'
import ViewURL from "../ViewURL/ViewURL";


const StartMarker = new L.Icon({
    iconUrl: pin,
    iconSize: [60, 60],
    iconAnchor: [30, 58],
    popupAnchor: [2, -50]
});

const customMarker = new L.Icon({
    iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
    iconSize: [35, 53],
    iconAnchor: [17, 50],
    popupAnchor: [2, -40]
});

const logo = "./logo-01.png"
const CurrentLocationMarker = new L.Icon({
    // iconUrl: "https://gayatrierp.in/assets/img/GME%20LOGO.jpg",
    iconUrl: logo,
    iconSize: [41, 41],
    iconAnchor: [20, 41],
    popupAnchor: [2, -40]
});


const FlagLocationMarker = new L.Icon({
    // iconUrl: "https://gayatrierp.in/assets/img/GME%20LOGO.jpg",
    iconUrl: flag,
    iconSize: [22, 22],
    iconAnchor: [7, 15],
    popupAnchor: [2, -40]
});

const PlaySingleMapView = (props) => {
    const { handleCloseMap, ShowMap, LoactionData, currEmployeeName, MapData } = props


    const userDetails = useAuthState();
    const { username } = userDetails

    const [Loader, setLoading] = useState(false)


    const markerData = [
        [18.50, 73.11],
        [18.709314735041126, 73.24093007633333],
        [18.326834494919822, 73.01220830472941],
        [18.746092403058576, 72.89410528292431],
        [18.57695068266547, 73.19897587409558]
    ]


    let geometries = [];

    // LoactionData.forEach(item => {
    //     geometries = [...geometries, [item.beforeWorkLatitude, item.beforeWorkLongitude]];
    // });


    const LoactionDataLength = LoactionData ? LoactionData.length - 1 : 0

    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [ImagePopUpData, setImagePopUpData] = React.useState('')

    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }

    return (
        <>
            <Popup className='assigns' open={ShowMap} closeOnDocumentClick={false}
                onClose={handleCloseMap}
                contentStyle={{ overflowY: 'auto', overflowX: 'auto', height: '95vh' }}
            >

                <div className="call d-flex justify-content-between mb-2">
                    <h5> Track Location</h5>

                    <span className="close" onClick={handleCloseMap}
                        style={{ cursor: 'pointer' }}
                    >
                        &times;
                    </span>
                </div>
                <div className="modal-body" >
                    {
                        Loader ? <Loading />
                            :
                            <MapContainer
                                center={[LoactionData && LoactionData.latitude, LoactionData && LoactionData.longitude]}
                                zoom={13}
                                style={{ height: "80vh" }}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                                />

                                <FeatureGroup>
                                    <Marker position={[LoactionData.latitude, LoactionData.longitude]} icon={customMarker} title={MapData ? MapData.assetName :'-'}>

                                        <Pop>
                                            <div className='marker-popup'>
                                                <p>	Graden Name: <b>{MapData ? MapData.assetName :'-'}</b></p>
                                                <p>	Latitude: <b>{MapData ? MapData.equipmentLatitude :'-'}</b></p>
                                                <p>	Longitude: <b>{MapData ? MapData.equipmentLongitude :'-'}</b></p>
                                                {/* <p>	Equipment Photo: <p><img className='marker-popup-image1' src={MapData ? MapData.equipmentPhoto :'-'} /></p></p> */}
                                            </div>
                                        </Pop>

                                    </Marker>

                                </FeatureGroup>
                            </MapContainer>
                    }

                </div>
                {
                    showImagePopup ?
                        <ViewURL
                            Heading="Image PopUp"
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''


                }
            </Popup>
        </>
    );
}


export default PlaySingleMapView;
