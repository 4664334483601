import React from 'react'
import { useNavigate } from 'react-router-dom'
import STPMasterCard from './STPMasterCard'


export default function STPMasterCardManage(props) {

    const { Countdata,HeaderMonthID,HeaderFinancialYear , MonthValue, YearValue , YearLabel,MonthLabel,WardValue,WardLabel,ProjectTypeId,DashboardName } = props
   
    const MasterNavigate = (name, screenFlag) => {
        if (name === "Tanks Details") {
            navigate(`/stpmastertankdetailsdata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Energy MPC No") {
            navigate(`/masterenergympcnodata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Equipment Master") {
            navigate(`/stpequipmentmasterdata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Equipment Work") {
            navigate(`/stpequipmentworkdata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Quality Parameter") {
            navigate(`/stpqualityparameterdata?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }
    const navigate = useNavigate()

    return (
        <div>
            <div className="row">
                <h5 className="ml-4 py-2">Master</h5>
            </div>
            <div className='row mast_row gar_new_mas'>

                {/* <div className='col-12 col-lg-2 col-md-6 text-center mast_card_width'> */}
                <div class="mb-0 col-sm-6 mastercard ml-lg-2">
                    <STPMasterCard    
                        title="Tanks Details"
                        cardName='py-2 green cursor '
                        border='green_border'
                        count='100'
                        // navLink='/stpmastertankdetailsdata'
                        imgPath="./assets/img/tank.png"
                        color='red'
                        MasterNavigate={MasterNavigate}

                    />
                </div>
                <div class="mb-0 col-sm-6 mastercard">
                    <STPMasterCard
                        title="Energy MPC No"
                        cardName='py-2 blue cursor'
                        border='blue_border'
                        count='100'
                        // navLink='/roaddivider'
                        imgPath="./assets/img/energy-master.png"
                        color='blue'
                        MasterNavigate={MasterNavigate}
                    />
                </div>

                <div class="mb-0 col-sm-6 mastercard">
                    <STPMasterCard
                        title="Equipment Master"
                        cardName='py-2 green cursor'
                        border='green_border'
                        count='100'
                        // navLink='/openspace'
                        imgPath="./assets/img/repair-master.png"
                        color='red'
                        MasterNavigate={MasterNavigate}
                    />
                </div>

                <div class="mb-0 col-sm-6 mastercard">
                    <STPMasterCard
                        title="Equipment Work"
                        cardName='py-2 blue cursor'
                        border='blue_border'
                        count='100'
                        // navLink='/treebelt'
                        imgPath="./assets/img/maintenance-master.png"
                        color='blue'
                        MasterNavigate={MasterNavigate}
                    />
                </div>
                <div class="mb-0 col-sm-6 mastercard">
                    <STPMasterCard
                        title="Quality Parameter"
                        cardName='py-2 green cursor'
                        border='green_border'
                        count='100'
                        // navLink='/chowks'
                        imgPath="./assets/img/high-quality-master.png"
                        color='red'
                        MasterNavigate={MasterNavigate}
                    />
                </div>


            </div>


        </div>



    )

}

