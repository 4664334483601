import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const ContractorNameTableDataAPI = createAsyncThunk("ContractorNameTableData", async ({ data }) => {
    const {
        M_ContractorID,
        ContractorName,
        M_WorkTypeID,
        M_UsersID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_Contractor_Select?M_ContractorID=${M_ContractorID ? M_ContractorID : '0'}&ContractorName=${ContractorName}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&M_UsersID=${M_UsersID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const ContractorNameTableDataSlice = createSlice({
    name: "ContractorNameTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorNameTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ContractorNameTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ContractorNameTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const ContractorNameTableDataReducer = ContractorNameTableDataSlice.reducer


export const ContractorNamePostDataAPI = createAsyncThunk("ContractorNamePostData", async ({ data }) => {
    const {
        M_ContractorID,
        M_WorkTypeID,
        ContractorName,
        ContractorName_Marathi,
        Address,
        MobileNo,
        EmailID,
        ContactPersonName,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_ContractorID", M_ContractorID);
    formdata.append("M_WorkTypeID", M_WorkTypeID);
    formdata.append("ContractorName", ContractorName);
    formdata.append("ContractorName_Marathi", ContractorName_Marathi);
    formdata.append("Address", Address);
    formdata.append("MobileNo", MobileNo);
    formdata.append("EmailID", EmailID);
    formdata.append("ContactPersonName", ContactPersonName);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Post_M_Contractor_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const ContractorNamePostDataSlice = createSlice({
    name: "ContractorNamePostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorNamePostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(ContractorNamePostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(ContractorNamePostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ContractorNamePostDataReducer = ContractorNamePostDataSlice.reducer

// -----------------Delete API ---------------

export const ContractorNameDeleteAPI = createAsyncThunk("ContractorNameDelete", async ({ data }) => {
    const {
        M_ContractorID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    // formdata.append("M_CropTypeID", rowData?.m_CropTypeID);
    var formdata = new FormData();
    formdata.append("M_ContractorID", M_ContractorID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_Contractor_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const ContractorNameDeleteSlice = createSlice({
    name: "ContractorNameDelete",
    initialState: {
        isDeleteLoading: false,
        ContractorNametableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(ContractorNameDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(ContractorNameDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.ContractorNametableData = action.payload;

        });
        builder.addCase(ContractorNameDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.ContractorNametableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const ContractorNameDeleteReducer = ContractorNameDeleteSlice.reducer