import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { WorkTypeDDL } from '../../../../../Components/CommonDDL/WorkTypeDDL';
import { useAuthState } from '../../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { WorkNameMasterPostDataAPI } from '../../../../../Redux/RoadDashboardSlice/WorkNameSlice';

export default function RoadMasterWorkNamePopup(props) {

    const { handleOnCloseClick, open, EditIcon, Flag, GardenMasterFlag, popupHeading, M_AssetTypeID, handleIsPost, apiFlag, rowData, HWard, WLable } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()
    const [IsOpen, setIsOpen] = useState(false)

    const [WorkNameTextField, setWorkNameTextField] = useState({
        workName: Flag === 'Update' ? rowData.workName : '',
        benchMark: Flag === 'Update' ? rowData.benchMark : '',
        penalty_Description: Flag === 'Update' ? rowData.penalty_Description : '',
        penalty_Values: Flag === 'Update' ? rowData.penalty_Values : '',
    });

    const handleInputChange = (e) => {
        setWorkNameTextField({ ...WorkNameTextField, [e.target.name]: e.target.value })
    }


    // useEffect(() => {
    //     // setLocationName((Flag === 'Update' ? item.locationName : ''))
    //     // setRowData(item)
    // }, [item, IsOpen])


    const [WardName, setWardName] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_WardID : 0,
        Label: Flag === 'Update' ? rowData.wardName : "Select...",

    })

    // useEffect(() => {
    //     setWorkNameTextField({
    //         workName: Flag === 'Update' ? rowData.workName : '',
    //         benchMark: Flag === 'Update' ? rowData.benchMark : '',
    //         penalty_Description: Flag === 'Update' ? rowData.penalty_Description : '',
    //         penalty_Values: Flag === 'Update' ? rowData.penalty_Values : '',
    //     })
    // }, [rowData, IsOpen])

    // console.log(rowData)

    const handlePost = () => {
        const data = {

            M_RM_WorkID: Flag === 'Update' ? rowData.m_rM_WorkID : '0',
            WorkName: WorkNameTextField?.workName,
            IsOtherWork: '',
            PenaltyPeriodHours: '',
            BenchMark: WorkNameTextField?.benchMark,
            Penalty_Description: WorkNameTextField?.penalty_Description,
            Penalty_Values: WorkNameTextField?.penalty_Values,
            Heading: '',
            DisplayOrder: '0',
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            // handleClear:handleClear
            handleOnCloseClick:handleOnCloseClick

        }
        dispatch(WorkNameMasterPostDataAPI({ data }))
    }

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    return (
        // <Popup
        //     onOpen={() => setIsOpen(!IsOpen)}
        //     contentStyle={{ width: '55%', height: '92%', top: '5%' }}
        //     trigger={
        //         Flag === 'Update' ?
        //             <i className="fa-solid fa-pen-to-square" style={{ cursor: 'pointer' }} title='Edit'></i>
        //             :
        //             <i className="fa-solid fa-plus float-end btn btn-secondary mb-1"
        //     style={{ color: "white" }}
        //   >
        //     <text className="ml-2 poppins">Add</text>
        //   </i>
        //     } modal>

            // {
            //     close => (
                    // n_popup-content 
                    <Popup
                    open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
                >   
                    <div className="location_mob_pop modal-dialog modal-dialog-scrollable modal-xl" style={{ alignItems: "center", justifyContent: 'center' }}>
                        <div className="modal-content road_ward_modal">
                            <div className="modal-header pl-4 p-1">
                                <h4 className="modal-title" id="staticBackdropLabel">Work Name</h4>
                                <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                            </div>
                            <div className="modal-body">
                                <div className='inner-modal-body'>
                                    <div className="row details-row">
                                        <div className="col-12 col-lg-4">
                                            <WorkTypeDDL
                                                WorkType={WorkType}
                                                setWorkType={setWorkType}
                                            />

                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment"><b>Work Name</b></label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="workName"
                                                    aria-describedby="emailHelp"
                                                    name='workName'
                                                    value={WorkNameTextField.workName}
                                                    onChange={(e) => handleInputChange(e)}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment"><b>BenchMarkBenchMark (Days)</b></label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="benchMark"
                                                    aria-describedby="emailHelp"
                                                    name='benchMark'
                                                    value={WorkNameTextField.benchMark}
                                                    onChange={(e) => handleInputChange(e)}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment"><b>Penalty_Description</b></label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="penalty_Description"
                                                    aria-describedby="emailHelp"
                                                    name='penalty_Description'
                                                    value={WorkNameTextField.penalty_Description}
                                                    onChange={(e) => handleInputChange(e)}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment"><b>Penalty Amount (Rs.)</b></label>
                                                <input type="text"
                                                    className="form-control"
                                                    id="penalty_Values"
                                                    aria-describedby="emailHelp"
                                                    name='penalty_Values'
                                                    value={WorkNameTextField.penalty_Values}
                                                    onChange={(e) => handleInputChange(e)}
                                                />

                                            </div>
                                        </div>
                                        {/* <div className="col-md-6 col-lg-4">
                                            <div className="form-group">
                                                <label className="d-block" htmlFor="NameofDepartment"><b>Heading :</b></label>
                                                <input type="text" className="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" />
                                                
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="mb-4 pt-4">
                                        <h6 className="float-end">
                                            <span className="submit_btn"
                                                onClick={() => handlePost()}
                                            >{Flag === 'Update' ? 'Update' : 'Submit'}</span>
                                            <span className=" cancel_btn ms-2" onClick={handleOnCloseClick} >Cancel</span>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* )} */}
        </Popup>
    )

}