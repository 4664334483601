
import { useEffect } from 'react'
import Select from 'react-select'
import { DepartmentDDLAPI, EcoliDDLAPI, EmployeeDDLAPI, JunctionNameDDLAPI, LocationNameDDLAPI, SectorDDLAPI, WardNameDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'

export const EcoliDataDDL = (props) => {
    const { EcoliDDL, setEcoliDDL, CropNameDashboardData, PopUpField, IsPost, WardName, rowData } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = {
            M_UserID: UserID,
            token: token,
            Flag: 'Ecoli'

        }
        dispatch(EcoliDDLAPI({ data }))
    }, [IsPost])

    const { EcoliData } = useSelector(state => state.EcoliDDLData)

    useEffect(() => {
        handleEcoliDDL()
    }, [EcoliData])

    const handleEcoliDDL = () => {
        // console.log(DeptDDLDataa)
        if (EcoliData && EcoliData.table && EcoliData.table.length > 0) {
            let list = EcoliData.table.map((item, index) => ({
                value: item.m_IndicatorID,
                label: item.indicatorName,
            }))

            setEcoliDDL({
                DDL: list,
                ID: rowData.m_IndicatorEcoliID,
                Label: rowData.indicatorName,
            })
        }
        else {
            setEcoliDDL({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofEmployee"><b>E-coli</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width3"
                isSearchable
                maxMenuHeight={150}
                value={{ value: EcoliDDL.ID, label: EcoliDDL.Label }}
                onChange={(e) => {
                    e ?
                        setEcoliDDL({ ...EcoliDDL, ID: e.value, Label: e.label })
                        :
                        setEcoliDDL({ ...EcoliDDL, ID: 0, Label: "Select..." })

                }}
                options={EcoliDDL.DDL}
            />
        </div>
    )
}