import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const SeveragePipelineDetailsMasterTableDataAPI = createAsyncThunk("SeveragePipelineDetailsMasterTableData", async ({ data }) => {
    const {
        M_SW_PipelineDetailsID,
        M_WardID,
        M_SW_LocationID,
        PipelineDetail,
        M_UserID,
        token,
        From,
        To,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/Master/Get_WEB_M_SW_PipelineDetails_Select?M_SW_PipelineDetailsID=${M_SW_PipelineDetailsID ? M_SW_PipelineDetailsID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_SW_LocationID=${M_SW_LocationID ? M_SW_LocationID : '0'}&PipelineDetail=${PipelineDetail ? PipelineDetail : ''}&M_UserID=${M_UserID}&FromTop=${From}&ToTop=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const SeveragePipelineDetailsMasterTableDataSlice = createSlice({
    name: "SeveragePipelineDetailsMasterTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SeveragePipelineDetailsMasterTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SeveragePipelineDetailsMasterTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(SeveragePipelineDetailsMasterTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const SeveragePipelineDetailsMasterTableDataReducer = SeveragePipelineDetailsMasterTableDataSlice.reducer


export const SeveragePipelineDetailsMasterPostDataAPI = createAsyncThunk("PumpingStationPostData", async ({ data }) => {
    const {
        M_SW_PipelineDetailsID,
        M_SW_LocationID,
        PipelineDetail,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_SW_PipelineDetailsID", M_SW_PipelineDetailsID);
    formdata.append("M_SW_LocationID", M_SW_LocationID);
    formdata.append("PipelineDetail", PipelineDetail);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_WEB_M_SW_PipelineDetails_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const SeveragePipelineDetailsMasterPostDataSlice = createSlice({
    name: "SeveragePipelineDetailsMasterPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SeveragePipelineDetailsMasterPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(SeveragePipelineDetailsMasterPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(SeveragePipelineDetailsMasterPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const SeveragePipelineDetailsMasterPostDataReducer = SeveragePipelineDetailsMasterPostDataSlice.reducer

// -----------------Delete API ---------------

export const SeveragePipelineDetailsMasterDeleteAPI = createAsyncThunk("SeveragePipelineDetailsMasterDelete", async ({ data }) => {
    const {
        M_SW_PipelineDetailsID,
        M_UserID,
        token,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);


    var formdata = new FormData();
    formdata.append("M_SW_PipelineDetailsID", M_SW_PipelineDetailsID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/WEB_M_SW_PipelineDetails_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const SeveragePipelineDetailsMasterDeleteSlice = createSlice({
    name: "SeveragePipelineDetailsMasterDelete",
    initialState: {
        isDeleteLoading: false,
        SectorData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(SeveragePipelineDetailsMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(SeveragePipelineDetailsMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.SectorData = action.payload;

        });
        builder.addCase(SeveragePipelineDetailsMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.SectorData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const SeveragePipelineDetailsMasterDeleteReducer = SeveragePipelineDetailsMasterDeleteSlice.reducer