
import { useEffect } from 'react'
import Select from 'react-select'
import { ContactPersonDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { useTranslation } from 'react-i18next'

export const ContactPersonDDL = (props) => {
    const { ContactPersonName, setContactPersonName, PopUpField, M_AssetTypeID, Flag, rowData } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token }
        dispatch(ContactPersonDDLAPI({ data }))
    }, [])

    const { ContactPersonData } = useSelector(state => state.ContactPersonDDLData)

    useEffect(() => {
        handleAssetDDL()
    }, [ContactPersonData])

    const handleAssetDDL = () => {
        if (ContactPersonData && ContactPersonData.table && ContactPersonData.table.length > 0) {
            let list = ContactPersonData.table.map((item, index) => ({
                value: item.m_EmployeeID,
                m_UserID: item.m_UserID,
                label: item.employeeName,

            }))

            setContactPersonName({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_EmployeeID : 0,
                Label: Flag === 'Update' ? rowData.contactPersonName : "Select...",
                m_UserID:  Flag === 'Update' ? rowData.m_UserID : 0,
            })
        }
        else {
            setContactPersonName({
                DDL: [],
                ID: 0,
                m_UserID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Contact Person Name</b></label>
            <Select
                // isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: ContactPersonName.ID, label: ContactPersonName.Label, m_UserID: ContactPersonName.m_UserID }}
                onChange={(e) => {
                    e ?
                        setContactPersonName({ ...ContactPersonName, ID: e.value, Label: e.label, m_UserID: e.m_UserID })
                        :
                        setContactPersonName({ ...ContactPersonName, ID: 0, Label: "Select...", m_UserID: 0 })

                }}
                options={ContactPersonName.DDL}
            />
        </div>
    )
}