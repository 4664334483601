import { useNavigate, useSearchParams } from 'react-router-dom'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useAuthState } from '../../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import { HorticultureWorkAssignedCountDataAPI } from '../../../../../Redux/GardenDashboardSlice/GardentMasterCountSlice/WorkAssignedByOfficials/HorticultureWorkAssignedSlice';
import { NewDashMainCountDataAPI } from '../../../../../Redux/MainCountSlice/NewDashboardMainCount';
import { Loading } from '../../../../../Helper/Loading';
import { WorkAssignNew } from '../../../../../Helper/Data';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import SportsWorksCard from './SportsWorksCard';

export default function SportsWork(props) {
    const { Language, MonthValue, YearValue, YearLabel, MonthLabel, WardValue, WardLabel, ZoneLabel, ZoneValue, WardName, setWardName, } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails

    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    const [DashProjectTypeId, setDashProjectTypeId] = useState(localStorage.getItem("FJ853K") ? localStorage.getItem("FJ853K") : "6")
    const [CountData, setCountData] = useState([])
    const [WorkPeriodID, setWorkPeriodID] = useState('0')
    const [GraphLable, setGraphLable] = useState([])

    const navigate = useNavigate()

    const SportsWorkAssignedByOficialsNavigate = (name, RoleID) => {
        // navigate(`/sportsworkassignedbyofficialtable?name=${name}&RoleID=${RoleID}&WorkPeriodID=${WorkPeriodID}&ApiFlag=Pending&Flag=ReopenPending&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        window.open(`/sportsworkassignedbyofficialtable?name=${name}&RoleID=${RoleID}&WorkPeriodID=${WorkPeriodID}&ApiFlag=Pending&Flag=ReopenPending&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&Lang=${Language.Label}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`);
    }

    const [Days, setDays] = useState()

    useEffect(() => {
        const data = {
            M_UserID: UserID,
            token: token,
            Flag: "SportWork",
            Language: Language.Label,
            setDays: setDays
        }
        { Language.Label != '' && dispatch(HorticultureWorkAssignedCountDataAPI({ data })) }
    }, [Language.Label])

    const { Data, isCountLoading } = useSelector(state => state.HorticultureWorkAssignedCountData)

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_WardID: WardName.ID,
            M_MonthID: MonthValue,
            M_UserID: UserID,
            Flag: "SportWorkAssigned",
            M_GD_WorkPeriodID: WorkPeriodID,
            token: token,
            setCountData: setCountData,
            setGraphLable: setGraphLable,
            M_ZoneID: ZoneValue,
            Language: Language.Label,
        }
        if (YearValue && Language.Label && WardName && ZoneValue) {
            dispatch(NewDashMainCountDataAPI({ data }))
        }
    }, [YearValue, MonthValue, WorkPeriodID, Language.Label, WardName.ID, ZoneValue])

    const { tableData, isLoading } = useSelector(state => state.NewDashMainCountData)

    // useLayoutEffect(() => {
    //     setWorkPeriodID(Days && Days[0].m_gD_WorkPeriodID)
    // }, [Days])

    const Targetdata3 = {

        labels: GraphLable,
        datasets: [
            {
                label: "Electrical Work Assigned By Officials",
                fill: true,
                backgroundColor: [
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                    "#294a65",
                ],
                data: CountData.map(i => i.totalCount)
            },
        ],
    };

    return (
        <>
            {/* {(isLoading) && <Loading />} */}
            <div className='card_heading d-lg-flex justify-content-center align-item-center mb-4 mt-n2'>
                <div className="col-12 col-md-12 col-lg-4 py-2 pt-3">
                    <span className="ml-lg-3 text-white fw-bold" style={{ fontFamily: 'poppins', fontSize: '16px' }}>{t('Dashboard.Titles.Sports_works')}</span>
                </div>

                <div className='col-12 col-md-12 col-lg-8 mx-auto pt-2'>
                    <span className="card-headerbtnhor font-weight-bold py-3">
                        <ul className="nav nav-tabs float-end  me-lg-3 mb-md-2">
                            <li onClick={() => setWorkPeriodID("0")} className='border'>
                                <a data-toggle="tab"
                                    className={WorkPeriodID === "0" ? "active text-black" : "text-white"}><p className="fonttab" style={{ cursor: "pointer" }}>Total</p></a>
                            </li>
                            {
                                Days && Days.length > 0 &&
                                Days.map((i) => {
                                    return (
                                        <li
                                            className='border'
                                            onClick={() => (setWorkPeriodID(i.m_gD_WorkPeriodID))}>
                                            <a data-toggle="tab"
                                                className={WorkPeriodID == i.m_gD_WorkPeriodID ? "active text-black" : "text-white"}>
                                                <p className="fonttab" style={{ cursor: "pointer" }}>{i.parameterDescription}</p>
                                            </a>
                                        </li>
                                    )
                                })
                            }


                        </ul>
                    </span>
                </div>
            </div>
            <div className="col-12 col-lg-7 ">
                <div className="row pb-2">
                    {
                        CountData && CountData.length > 0 ?
                            CountData.map((i) => {
                                return (
                                    <div className="col-12 col-lg-6 col-md-6 mb-2 mb-lg-0">
                                        <SportsWorksCard
                                            title={i?.roleName}
                                            cardName='py-2 wwork_img cursor'
                                            imgPath='./assets/img/manager.png'
                                            count={i.totalCount ? i.totalCount : '0'}
                                            RoleID={i?.m_RoleID}
                                            SportsWorkAssignedByOficialsNavigate={SportsWorkAssignedByOficialsNavigate}
                                        />
                                    </div>
                                )
                            })
                            : <p className='text-center fw-bold'>No Data found</p>
                    }
                </div>
            </div>
            {
                CountData.some(i => i.totalCount !== 0) ?
                    <div className="col-12 col-lg-5">
                        <div className="row py-2 mt-5">
                            <div className="col-12">
                                <div className="px-5 pt-4 bg-white upload_chart" style={{ borderRadius: "7px", marginTop: "-3.7em" }}>
                                    <div className="row d-flex justify-content-center align-items-center" >
                                        <Bar
                                            options={WorkAssignNew}
                                            data={Targetdata3}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-12 col-lg-5 col-md-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <text>No Data Found</text>
                    </div>
            }

        </>
    )

}
