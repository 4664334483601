import React from "react";
import { useNavigate } from "react-router-dom";

export default function SewerageASsignCard({ title, navLink, pendingcount, completedcount, imgPath, cardName, SewerageWorkNavigate, cardHeading, title1, title2, SewerageAssignCardNavigate1, imgCompPath, imgPendingPath, imgPathsecond, cardHeight }) {
    const navigate = useNavigate()

    const handleSewerageASsignCardClick = () => {
        navigate(navLink)
    }
    return (
        <div style={{ cursor: 'pointer' }} className="mb-1 p-3 shadow blue_borders bg-white mb-md-2 daily-card-width">
            {/* <div className=" p-3 shadow blue_borders bg-white">
                <h6 className="text-black"><b>{cardHeading}</b></h6>
                <div className="row d-flex justify-content-center align-items-center ">
                    <div className="col-6  text-center" style={{ borderRight: "2px solid #cfc7c7" }}>
                         <img className={cardName} src={imgPendingPath} alt="" />
                        <h6 className="text-black"><b>Pending</b></h6>
                        <h4 className="green_color">{count}</h4>
                    </div>
                    <div className="col-6  text-center">
                        <img className={cardName} src={imgCompPath} alt="" />
                        <h6 className="text-black"><b>Completed</b></h6>
                        <h4 className="green_color">{count}</h4>
                    </div>
                </div>
            </div> */}

            <div className="row">
                <h6 className="text-black"><b>{cardHeading}</b></h6>
                <div className="col-6" style={{ borderRight: "2px solid #cfc7c7" }} onClick={() => SewerageAssignCardNavigate1(title1)} >
                    <div class={cardHeight} className="d-flex flex-column justify-content-between">
                        <div className="text-center" >
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{pendingcount}</h4>
                                <img style={{ height: '35px', width: '35px' }} src={imgPathsecond} alt="" />
                            </div>
                        </div>
                        <div>
                            <h6 className="text-black"><b>Pending</b></h6>
                        </div>
                    </div>
                </div>
                <div className="col-6" onClick={() => SewerageAssignCardNavigate1(title2)} >
                    <div className="d-flex flex-column justify-content-between">
                        <div className="text-center" >
                            <div className="d-flex justify-content-between align-items-center">
                                <h4>{completedcount}</h4>
                                <img style={{ height: '30px', width: '30px' }} src={imgPath} alt="" />
                            </div>

                        </div>
                        <div>
                            <h6 className="text-black"><b>Completed</b></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
