import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastErrorr } from "../../../Helper/ToastMessage";
import { BaseUrl } from "../../../Helper/BaseUrl";

// Get Table Data
export const CommonOfficerWorkStatusTableDataAPI = createAsyncThunk("CommonOfficerWorkStatusTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WorkTypeID,
        M_ZoneID,
        M_WardID,
        M_UserID,
        Language,
        Flag,
        token,
        From,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_OfficerWisePendingWorkStatus_Select?FromDate=${FromDate}&ToDate=${ToDate}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : 0}&M_ZoneID=${M_ZoneID ? M_ZoneID : 0}&M_WardID=${M_WardID ? M_WardID : 0}&M_UserID=${M_UserID}&Language=${Language}&Flag=${Flag}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const CommonOfficerWorkStatusTableDataSlice = createSlice({
    name: "CommonOfficerWorkStatusTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommonOfficerWorkStatusTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CommonOfficerWorkStatusTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(CommonOfficerWorkStatusTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CommonOfficerWorkStatusTableDataReducer = CommonOfficerWorkStatusTableDataSlice.reducer

// Count Popup API

export const CommonOfficerWorkStatusCountPopupTableDataAPI = createAsyncThunk("CommonOfficerWorkStatusCountPopupTableData", async ({ data }) => {
    const {
        FromDate,
        ToDate,
        M_WorkTypeID,
        M_ZoneID,
        M_WardID,
        WorkAssignByM_UserID,
        M_ContractorID,
        M_UserID,
        Language,
        Flag,
        ShowBy,
        token,
        From,
        To,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_GD_OfficerWisePendingWorkStatusDetails_Select?FromDate=${FromDate}&ToDate=${ToDate}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : 0}&M_ZoneID=${M_ZoneID ? M_ZoneID : 0}&M_WardID=${M_WardID ? M_WardID : 0}&WorkAssignByM_UserID=${WorkAssignByM_UserID ? WorkAssignByM_UserID : 0}&M_ContractorID=${M_ContractorID ? M_ContractorID : 0}&M_UserID=${M_UserID}&Language=${Language}&Flag=${Flag}&ShowBy=${ShowBy}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const CommonOfficerWorkStatusCountPopupTableDataSlice = createSlice({
    name: "CommonOfficerWorkStatusCountPopupTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(CommonOfficerWorkStatusCountPopupTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(CommonOfficerWorkStatusCountPopupTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(CommonOfficerWorkStatusCountPopupTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const CommonOfficerWorkStatusCountPopupTableDataReducer = CommonOfficerWorkStatusCountPopupTableDataSlice.reducer