import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { WorkTypeDDL } from '../../../../Components/CommonDDL/WorkTypeDDL';
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { WorkSubTypeDDLAPI } from '../../../../Redux/DDLSlice';
import { WorkSubTypeDDL } from '../../../../Components/CommonDDL/WorkSubTypeDDL';
import { WorkMasterPostDataAPI } from '../../../../Redux/SidebarMasterSlice/WorkMasterSlice';
import { AstricSign } from '../../../../Helper/AstricSign';
import { useSearchParams } from 'react-router-dom';


export default function WorkPopup(props) {

    const { handleOnCloseClick, open, Flag, rowData, handleIsPost, apiFlag, Language } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let Lang = searchParams.get("Lang")

    // const [rowData, setRowData] = useState(rowData)
    const [IsOpen, setIsOpen] = useState(false)

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_WorkTypeID : 0,
        Label: Flag === 'Update' ? rowData.workTypeName : "Select...",
    })
    const [WorkSubType, setWorkSubType] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_WorkSubTypeID : 0,
        Label: Flag === 'Update' ? rowData.workSubTypeName : "Select...",
    })

    // console.log(rowData)
    // const [Language, setLanguage] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: ''
    // })

    // useEffect(() => {
    //     setLanguage({
    //         ...Language,
    //         DDL: [],
    //         ID: 0,
    //         Label: localStorage.getItem('LanguageChange'),
    //     })
    // }, [Language])


    const [WorkPopUpField, setWorkPopUpField] = useState({
        WorkName: Flag === 'Update' ? rowData.workname : '',
        Benchmark: Flag === 'Update' ? rowData.benchMark : 0,
        BenchmarkInDays: Flag === 'Update' ? rowData.benchMarkinDays : 0,
        PeneltyAmount: Flag === 'Update' ? rowData.penaltyAmount : 0,
        PeneltyPeriod: Flag === 'Update' ? rowData.PeneltyPeriod : '',
    })

    const handleWorkPopUpField = (e) => {
        setWorkPopUpField({ ...WorkPopUpField, [e.target.name]: e.target.value })
    }


    const handlePost = () => {
        const data = {
            M_WorkId: Flag === 'Update' ? rowData.m_WorkID : '0',
            M_WorkTypeID: WorkType.ID,
            M_WorkSubTypeID: WorkSubType.ID,
            Workname: WorkPopUpField.WorkName,
            PenaltyPeriod: '',
            BenchMark: WorkPopUpField.Benchmark,
            BenchMarkinDays: WorkPopUpField.BenchmarkInDays,
            PenaltyPeriodinhours: '',
            PenaltyAmount: WorkPopUpField.PeneltyAmount,
            M_UserID: UserID,
            token: token,
            Flag: Flag === 'Update' ? 'Update' : 'Insert',
            handleIsPost: handleIsPost,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(WorkMasterPostDataAPI({ data }))
    }

    // useEffect(() => {
    //     setWorkPopUpField({
    //         WorkName: Flag === 'Update' ? rowData.workname : '',
    //         Benchmark: Flag === 'Update' ? rowData.benchMark : '',
    //         BenchmarkInDays: Flag === 'Update' ? rowData.benchMarkinDays : '',
    //         PeneltyAmount: Flag === 'Update' ? rowData.penaltyAmount : '',
    //     })
    // }, [rowData, IsOpen])

    return (

        // <Popup
        //     onOpen={() => setIsOpen(!IsOpen)}
        //     contentStyle={{ top: '5%' }}
        //     trigger={
        //         Flag === 'Update' ?
        //             <i className="fa-solid fa-pen-to-square"
        //             style={{ cursor: "pointer" }} title='Edit'
        //             ></i>
        //             :
        //             <i className="fa-solid fa-plus btn btn-secondary mb-2 float-end" style={{ color: "white", fontSize: '15px', marginTop: '-2.5em' }}>
        //                 <text className="ml-2 poppins">Add</text>
        //             </i>
        //     }
        //     modal>

        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick} contentStyle={{ width: "70vw" }}
        >
            <div className="area_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Work Master</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">
                                <div className="col-12 col-lg-6">
                                    <WorkTypeDDL
                                        WorkType={WorkType}
                                        setWorkType={setWorkType}
                                        rowData={rowData}
                                        Flag={Flag}
                                        IsDisabled={true}
                                        Language={Language}
                                    />
                                </div>
                                <div className="col-12 col-lg-6 ">
                                    <WorkSubTypeDDL
                                        WorkSubType={WorkSubType}
                                        setWorkSubType={setWorkSubType}
                                        WorkType={WorkType}
                                        rowData={rowData}
                                        Flag={Flag}
                                        IsDisabled={true}
                                        Language={Language}
                                    />

                                </div>
                                <div className="col-12 col-lg-6 mt-3">
                                    <label for=""><b>Work Name <AstricSign /> :</b></label>
                                    <textarea type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" name='WorkName'
                                        value={WorkPopUpField.WorkName}
                                        onChange={handleWorkPopUpField}
                                    />
                                </div>
                                <div className="col-12 col-lg-6 mt-3">
                                    <label for=""><b>Benchmark  :</b></label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" name='Benchmark'
                                        value={WorkPopUpField.Benchmark}
                                        onChange={handleWorkPopUpField}
                                    />
                                </div>
                                <div className="col-12 col-lg-6 mt-3">
                                    <label for=""><b>Benchmark in Days  :</b></label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" name='BenchmarkInDays'
                                        value={WorkPopUpField.BenchmarkInDays}
                                        onChange={handleWorkPopUpField}
                                    />
                                </div>
                                {/* <div className="col-12 col-lg-6 mt-3">
                                    <label for=""><b>Penalty Period in Hours <AstricSign /> :</b></label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" name='PeneltyPeriod'
                                        value={WorkPopUpField.PeneltyPeriod}
                                        onChange={handleWorkPopUpField}
                                    />
                                </div> */}
                                <div className="col-12 col-lg-6 mt-3">
                                    <label for=""><b>Penalty Amount  :</b></label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" name='PeneltyAmount'
                                        value={WorkPopUpField.PeneltyAmount}
                                        onChange={handleWorkPopUpField}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">
                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                        disabled={
                                            WorkType.ID == 0
                                            || WorkSubType.ID == 0
                                            || WorkPopUpField.WorkName == ''
                                            // || WorkPopUpField.Benchmark == '' 
                                            // || WorkPopUpField.BenchmarkInDays == '' 
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            {/* 
                )
            } */}


        </Popup >
    )

}