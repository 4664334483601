import React from 'react'
import SewerageASsignCard from './SewerageAssignCard'
import { useNavigate } from 'react-router-dom'


export default function SewerageAssignCardManage(props) {
    const navigate = useNavigate()
    // const SewerageWorkNavigate = (name, screenFlag) => {

    //     if (name === "Electrical Maintenance") {
    //         navigate(`/preventiveassigncommondata?name=${name}&ApiFlag=Electrical Maintenance`)
    //     } else if (name === "Civil") {
    //         navigate(`/preventiveassigncommondata?name=${name}&ApiFlag=Civil`)
    //     }
    // }
    const { Countdata, HeaderMonthID, HeaderFinancialYear, MonthValue, YearValue, YearLabel, MonthLabel, WardValue, WardLabel,ProjectTypeId,DashboardName } = props

    const SewerageAssignCardNavigate1 = (name, screenFlag, assignName) => {
        if (name === "Pending") {
            navigate(`/preventivecustomer?name=${name}&ApiFlag=Pending&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Completed") {
            navigate(`/preventivecustomer?name=${name}&ApiFlag=Completed&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
        else if (name === "Mechanical Pending") {
            navigate(`/preventiveassigncommondata?name=${name}&ApiFlag=MechanicalPending&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
        else if (name === "Mechanical Completed") {
            navigate(`/preventiveassigncommondata?name=${name}&ApiFlag=MechanicalCompleted&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
        else if (name === "Civil Pending") {
            navigate(`/preventiveassigncommondata?name=${name}&ApiFlag=CivilPending&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
        else if (name === "Civil Completed") {
            navigate(`/preventiveassigncommondata?name=${name}&ApiFlag=CivilCompleted&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }

    console.log(Countdata)

    return (
        <div className="row pb-3">
            <div className="col-12">
                <h5 className="ps-2 py-2 ">Assigned Work</h5>
            </div>
            <div className="col-12 col-lg-4 mb-2">
                <SewerageASsignCard
                    title="Customer Services"
                    title1="Pending"
                    cardName='py-1 wwork_img cursor'
                    imgPath='./assets/img/completed1.png'
                    imgPathsecond='./assets/img/pending.png'
                    pendingcount={Countdata.CustomerServicesPendingTotalCount}
                    // SewerageWorkNavigate={SewerageWorkNavigate}
                    cardHeading='Customer Service'
                    title2="Completed"
                    completedcount={Countdata.CustomerServicesCompletedTotalCount}
                    SewerageAssignCardNavigate1={SewerageAssignCardNavigate1}
                />

            </div>

            <div className="col-12 col-lg-4 mb-2">
                <SewerageASsignCard
                    title="Electrical Maintenance"
                    title1="Mechanical Pending"
                    cardName='py-1 wwork_img cursor'
                    imgPath='./assets/img/completed1.png'
                    imgPathsecond='./assets/img/pending.png'
                    pendingcount={Countdata.AssignedWorkMechanicalPendingTotalCount}
                    // navLink='/preventivecommondata'
                    // SewerageWorkNavigate={SewerageWorkNavigate}
                    cardHeading='Mechanical Maintenance'
                    title2="Mechanical Completed"
                    completedcount={Countdata.AssignedWorkMechanicalCompletedTotalCount}
                    searchNametitle2="MechanicalCompleted"
                    SewerageAssignCardNavigate1={SewerageAssignCardNavigate1}
                />
            </div>
            <div className="col-12 col-lg-4 mb-2">
                <SewerageASsignCard
                    title="Civil"
                    title1="Civil Pending"
                    cardName='py-1 wwork_img cursor'
                    imgPath='./assets/img/completed1.png'
                    imgPathsecond='./assets/img/pending.png'
                    pendingcount={Countdata.AssignedWorkCivilPendingTotalCount}
                    // navLink='/preventivecommondata'
                    // SewerageWorkNavigate={SewerageWorkNavigate}
                    cardHeading='Civil Maintenance'
                    completedcount={Countdata.AssignedWorkCivilCompletedTotalCount}
                    SewerageAssignCardNavigate1={SewerageAssignCardNavigate1}
                    title2="Civil Completed"
                    searchNametitle2="CivilCompleted"
                />
            </div>

        </div>

    )

}
