import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { EmployeeDataDDL } from '../../../../../../Components/CommonDDL/EmployeeDataDDL';
import { ZoneUserNameDDL } from '../../../../../../Components/CommonDDL/UserCreationDDL/ZoneUserDDL';
import { WardUserDDL } from '../../../../../../Components/CommonDDL/UserCreationDDL/WardUserDDL';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../../../../../Helper/Context';
import { useDispatch, useSelector } from 'react-redux';
import { CommonOfficerWorkStatusCountPopupTableDataAPI } from '../../../../../../Redux/CommDashbaord/OfficerWorkStatus/CommonOfficerWorkStatusSlice';
import moment from "moment";
import { Pegination } from '../../../../../../Components/Pegination/Pegination';
import { Loading } from '../../../../../../Helper/Loading';

export default function EmployeeAndContractorWorkPopup(props) {

    const { handleOnCloseClick, cancelbtn, ActiveButton, GridData, PopUpApiFlag, open, ShowBy, FromDate, ToDate, WorkType, ZoneNameDDLID, WardName } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch();

    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [PerPageCount, setPerPageCount] = useState(10)
    const [rowNo, setrowNo] = useState(1)
    const [IsClear, setIsClear] = useState(false)

    const handleOpenImage = (data) => {
        window.open(data)
    }

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    useEffect(() => {
        const data = {
            FromDate: FromDate,
            ToDate: ToDate,
            M_WorkTypeID: WorkType.ID,
            // M_ZoneID: ZoneNameDDLID.ID,
            // M_WardID: WardName.ID,
            M_ZoneID: GridData.m_ZoneID ? GridData.m_ZoneID : 0,
            M_WardID: GridData.m_WardID ? GridData.m_WardID : 0,
            WorkAssignByM_UserID: GridData.assingByM_UserID ? GridData.assingByM_UserID : 0,
            M_ContractorID: GridData.m_ContractorID ? GridData.m_ContractorID : 0,
            ShowBy: ShowBy,
            M_UserID: UserID,
            From: From,
            To: To,
            token: token,
            Language: Language.Label,
            Flag: ActiveButton == 'कर्मचारी' ? 'Officer' : 'Contractor',
        }
        { Language.Label != '' && dispatch(CommonOfficerWorkStatusCountPopupTableDataAPI({ data })) }
    }, [From, Language.Label])

    const { tableData, isLoading } = useSelector(state => state.CommonOfficerWorkStatusCountPopupTableData)


    return (
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick} contentStyle={{ width: "90vw" }}
        >
            <div className='area_pop modal-dialog modal-dialog-scrollable modal-xl'>
                <div className="modal-content pt-2">
                    <div className="modal-header pt-0 pb-0">
                        <h5 className="card-title text-dark fw-bold" id="exampleModalLongTitle">
                            {
                                ActiveButton == 'कर्मचारी' ?
                                    <>
                                        {t('EmployeeAndContractor.Officer_Name_Pending_Work_Status')}
                                    </>
                                    :
                                    <>
                                        {t('EmployeeAndContractor.Contractor_Name_Pending_Work_Status')}
                                    </>
                            }
                        </h5>
                        <button onClick={() => cancelbtn()} type="button" className="close border_cancel_btn" style={{ marginLeft: "auto" }}>
                            <span aria-hidden="true" className=" py-0" style={{ color: "red" }}>&times;</span>
                        </button>
                    </div>

                    {isLoading && <Loading />}

                    <div className="row details-row mt-lg-2">
                        <div className="col-12 col-md-6 col-lg-4 ps-5">
                            {
                                ActiveButton == 'कर्मचारी' ?
                                    <label className="d-block" htmlFor="NameofDepartment"><b>{t('EmployeeAndContractor.Karmachariche_Nav')} : {GridData?.employeeName}</b></label>
                                    :
                                    <label className="d-block" htmlFor="NameofDepartment"><b>{t('EmployeeAndContractor.Thekedaryache_Nav')} : {GridData?.employeeName}</b></label>
                            }

                        </div>

                        <div className="col-md-6 col-lg-4">
                            <label className="d-block" htmlFor="NameofDepartment"><b>{t('AdminDashboard.Admin_Sidebar_Table_Heading.Zone_Name')} :- {GridData?.zoneName}</b></label>
                        </div>

                        <div className="col-md-6 col-lg-4">
                            <label className="d-block" htmlFor="NameofDepartment"><b>{t('Dashboard.Titles.Ward_Name')} :- {GridData?.wardName}</b></label>
                        </div>

                    </div>
                    <hr className="bg-dark" />

                    <div className="modal-body mx-0">
                        <div className="row" >
                            <div className="table-responsive" style={{ height: '25em' }}>

                                <table cellPadding="0" cellSpacing="0" border="0" className="table table-bordered">
                                    <thead style={{ position: 'sticky', top: -1 }}>
                                        <tr style={{ textAlign: "center" }}>
                                            <th width="5%">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                            <th>{t('Dashboard.Work_Assigned_by_Officials.Assign_Date')}</th>
                                            {
                                                ActiveButton == 'कर्मचारी' ?
                                                    <th>{t('EmployeeAndContractor.Thekedaryache_Nav')}</th>
                                                    :
                                                    <th>{t('Dashboard.Work_Assigned_by_Officials.Officer_Name')}</th>

                                            }
                                            {/* <th>{t('Dashboard.Titles.Ward_Name')}</th> */}
                                            <th>{t('Dashboard.Pcmc_Assets.Asset_Name')}</th>
                                            <th>{t('Dashboard.Pcmc_Assets.Area_Name')}</th>
                                            <th>{t('Dashboard.Work_Assigned_by_Officials.Work_Name')}</th>
                                            <th>{t('Dashboard.Work_Assigned_by_Officials.Assign_Work_Photo')}</th>

                                            {
                                                (ShowBy == 'All' || ShowBy == 'Completed') &&
                                                <>
                                                    <th>{t('Dashboard.Work_Assigned_by_Officials.Completed_Work_Date')}</th>
                                                    <th>{t('Sidebar.After_Work_Photo')}</th>
                                                </>
                                            }
                                            {
                                                ShowBy == 'All' &&
                                                <th>{t('Dashboard.Pcmc_Assets.Status')}</th>
                                            }

                                            {/* <th>In progress</th>
                                        <th>Completed</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((i) => (
                                                <tr>
                                                    <td className="text_center">{i?.rowNum}</td>
                                                    <td style={{ whiteSpace: 'nowrap' }}>{i.workAssignDate ? moment(i.workAssignDate).format('DD-MM-YYYY') : '-'}</td>
                                                    {
                                                        ActiveButton == 'कर्मचारी' ?
                                                            <td>{i.contractorName ? i.contractorName : '-'}</td>
                                                            :
                                                            <td>{i.assingBy ? i.assingBy : '-'}</td>

                                                    }
                                                    {/* <td style={{ whiteSpace: 'nowrap' }}>{i.wardName ? i.wardName : '-'}</td> */}
                                                    <td>{i.assetName ? i.assetName : '-'}</td>
                                                    <td>{i.areaName ? i.areaName : '-'}</td>
                                                    <td>{i.workname ? i.workname : '-'}</td>
                                                    {
                                                        i.workPhoto == null ?
                                                            <>-</>
                                                            :
                                                            <td className='text-center'><i className="fa-solid fa-eye" onClick={() => handleOpenImage(i.workPhoto)} /></td>
                                                    }

                                                    {
                                                        (ShowBy == 'All' || ShowBy == 'Completed') &&
                                                        <>
                                                            <td>{i.workDate ? moment(i.workDate).format('DD-MM-YYYY') : '-'}</td>
                                                            {
                                                                i.afterWorkPhoto == null ?
                                                                    <>-</>
                                                                    :
                                                                    <td className='text-center'><i className="fa-solid fa-eye" onClick={() => handleOpenImage(i.afterWorkPhoto)} /></td>
                                                            }
                                                        </>
                                                    }
                                                    {
                                                        ShowBy == 'All' &&
                                                        <td>{i.statusName ? i.statusName : '-'}</td>
                                                    }

                                                </tr>
                                            )) : <tr style={{ textAlign: "center" }}>No data found...</tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {tableData && tableData.table && tableData.table.length > 0 &&
                                <Pegination
                                    PerPageCount={PerPageCount}
                                    TotalCount={tableData.table[0].totalCount}
                                    setFrom={setFrom}
                                    setTo={setTo}
                                    setrowNo={setrowNo}
                                    CurrentPage={CurrentPage}
                                    setCurrentPage={setCurrentPage}
                                    IsClear={IsClear}
                                />
                            }
                        </div>

                    </div>
                </div>
            </div>
        </Popup >
    )

}