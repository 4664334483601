import Popup from 'reactjs-popup';
import Select from 'react-select'
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import { useAuthState } from '../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import { ContractorNamePostDataAPI } from '../../../../Redux/SidebarMasterSlice/ContractorNameSlice';
import { RegExOnlyText } from '../../../../Helper/regEx/RegExOnlyText';
import { AstricSign } from '../../../../Helper/AstricSign';
import { WorkTypeDDL } from '../../../../Components/CommonDDL/WorkTypeDDL';
import { ContactPersonDDL } from '../../../../Components/CommonDDL/ContactPersonDDL';


export default function ContractorNamePopup(props) {

    const { handleOnCloseClick, open, item, Flag, handleIsPost, apiFlag, rowData } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [IsValidNumbers, setIsValidNumbers] = useState(false)
    const [IsValidEmail, setIsValidEmail] = useState(false)
    const [ContractorNameField, setContractorNameField] = useState({
        ContractorName: Flag === 'Update' ? rowData.contractorName : '',
        ContractorName_Marathi: Flag === 'Update' ? rowData.contractorName : '',
        Address: Flag === 'Update' ? rowData.address : '',
        MobileNumber: Flag === 'Update' ? rowData.mobileNo : '',
        EmailID: Flag === 'Update' ? rowData.emailID : '',
    });

    const [IsOpen, setIsOpen] = useState(false)

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_WorkTypeID : 0,
        Label: Flag === 'Update' ? rowData.workTypeName : "Select...",
    })

    const [ContactPersonName, setContactPersonName] = useState({
        DDL: [],
        ID: Flag === 'Update' ? rowData.m_UserID : 0,
        Label: Flag === 'Update' ? rowData.contactPersonName : "Select...",
        m_UserID: 0,
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    // useEffect(() => {
    //     setContractorNameField({
    //         ContractorName: Flag === 'Update' ? rowData.contractorName : '',
    //         Address: Flag === 'Update' ? rowData.address : '',
    //         ContactPersonName: Flag === 'Update' ? rowData.contactPersonName : '',
    //         MobileNumber: Flag === 'Update' ? rowData.mobileNo : '',
    //         EmailID: Flag === 'Update' ? rowData.emailID : '',
    //     })
    // }, [item, IsOpen])


    const handleContractorField = (e) => {
        setContractorNameField({ ...ContractorNameField, [e.target.name]: e.target.value })
    }

    const [IsValidText, setIsValidText] = useState(false)
    const handleCheckText = (e) => {
        handleContractorField(e)
        const IsValid = RegExOnlyText(e.target.value)
        setIsValidText(IsValid)
        return IsValid
    }

    const handleCheckMobileNumber = (e) => {
        handleContractorField(e)
        let regex = new RegExp(/^[0-9]*$/)
        if (regex.test(e.target.value) || e.target.value === "") {
            setIsValidNumbers(false)
        } else {
            setIsValidNumbers(true)
        }
    }

    const handleCheckEmail = (e) => {
        handleContractorField(e)
        let regex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
        if (regex.test(e.target.value) || e.target.value === "") {
            setIsValidEmail(false)
        } else {
            setIsValidEmail(true)
        }
    }

    const handlePost = () => {
        const data = {
            M_ContractorID: Flag === 'Update' ? rowData.m_ContractorID : '0',
            M_WorkTypeID: WorkType.ID,
            ContractorName: ContractorNameField.ContractorName,
            ContractorName_Marathi: ContractorNameField.ContractorName_Marathi,
            Address: ContractorNameField.Address,
            MobileNo: ContractorNameField.MobileNumber,
            EmailID: ContractorNameField.EmailID,
            ContactPersonName: ContactPersonName.Label,
            M_UserID: ContactPersonName.m_UserID,
            token: token,
            Flag: Flag,
            handleIsPost: handleIsPost,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(ContractorNamePostDataAPI({ data }))
    }

    return (
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >

            <div className="area_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content master_border" style={{ borderTop: "7px solid black" }}>
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Contractor Name</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">
                                <div className="col-12 col-md-5 col-lg-6">
                                    <WorkTypeDDL
                                        WorkType={WorkType}
                                        setWorkType={setWorkType}
                                        Language={Language}
                                        Flag={Flag}
                                        rowData={rowData}
                                        Flag1='ProjectType'
                                    />
                                </div>

                                <div className="col-12 col-lg-6">
                                    <label for=""><b>Contractor Name <AstricSign /> :</b></label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='ContractorName'
                                        value={ContractorNameField.ContractorName}
                                        onChange={(e) => handleCheckText(e)}
                                    />
                                    {
                                        IsValidText && <text style={{ color: 'red' }}>Please enter Alphabets characters only</text>
                                    }
                                </div>

                                <div className="col-12 col-lg-6 mt-3">
                                    <label for=""><b>Contractor Name (Marathi) <AstricSign /> :</b></label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='ContractorName_Marathi'
                                        value={ContractorNameField.ContractorName_Marathi}
                                        onChange={handleContractorField}
                                    />
                                    {/* {
                                        IsValidText && <text style={{ color: 'red' }}>Please enter Alphabets characters only</text>
                                    } */}
                                </div>

                                <div className="col-12 col-lg-6 mt-3">
                                    <label for=""><b>Address <AstricSign /> :</b></label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='Address'
                                        value={ContractorNameField.Address}
                                        onChange={handleContractorField}
                                    />
                                </div>

                                <div className="col-12 col-md-6 col-lg-6 mt-3">
                                    <ContactPersonDDL
                                        ContactPersonName={ContactPersonName}
                                        setContactPersonName={setContactPersonName}
                                        Flag={Flag}
                                        rowData={rowData}
                                    />
                                </div>

                                <div className="col-12 col-lg-6 mt-3 ">
                                    <label for=""><b>Mobile Number :</b></label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='MobileNumber'
                                        value={ContractorNameField.MobileNumber}
                                        onChange={handleCheckMobileNumber}
                                        maxLength={10}
                                    />
                                    {
                                        IsValidNumbers && <text style={{ color: 'red' }}>Please Enter valid Mobile Number</text>
                                    }
                                </div>

                                <div className="col-12 col-lg-6 mt-3 ">
                                    <label for=""><b>Email ID :</b></label>
                                    <input type="text" className="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        name='EmailID'
                                        value={ContractorNameField.EmailID}
                                        onChange={handleCheckEmail}
                                    />
                                    {
                                        IsValidEmail && <text style={{ color: 'red' }}>Please Enter valid Email</text>
                                    }
                                </div>

                            </div>
                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">

                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                        disabled={
                                            ContractorNameField.ContractorName == '' || ContractorNameField.Address == ''
                                            || ContactPersonName.ID == 0
                                        }
                                    >{Flag === "Update" ? "Update" : "Save"} </button>

                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </Popup>
    )

}