
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { StatusDDLAPI } from '../../Redux/DDLSlice'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'

export const StatusNameDataDDL = (props) => {
    const { Status, setStatus, rowData, Flag } = props

    const {t} = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token }
        dispatch(StatusDDLAPI({ data }))
    }, [])

    const { StatusData } = useSelector(state => state.StatusDDLData)


    useEffect(() => {
        handleStatusDDL()
    }, [StatusData])

    const handleStatusDDL = () => {
        // console.log(DeptDDLDataa)
        if (StatusData && StatusData.table && StatusData.table.length > 0) {
            let list = StatusData.table.map((item, index) => ({
                value: item.m_StatusID,
                label: item.statusName,
            }))

            setStatus({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_StatusID : 0,
                Label: Flag === 'Update' ? rowData.statusName : "Select...",
            })
        }
        else {
            setStatus({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>{t('Dashboard.Pcmc_Assets.Status')} { Flag && <AstricSign />}</b></label>
            <Select
                // isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: Status.ID, label: Status.Label }}
                onChange={(e) => {
                    e ?
                        setStatus({ ...Status, ID: e.value, Label: e.label })
                        :
                        setStatus({ ...Status, ID: 0, Label: "Select..." })

                }}
                options={Status.DDL}
            />

        </div>
    )
}