import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const GardenMasterTableDataAPI = createAsyncThunk("GardenMasterTableData", async ({ data }) => {
    const {
        UserID,
        token,
        From,
        To,
        M_AssetTypeID,
        M_WardID,
        M_ZoneID,
        // M_GD_SectorID,
        M_AssetID,
        Language,
        MaintainByIndicatorAgencyID,
        handleGetExport
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_Asset_Select?M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&MaintainByIndicatorAgencyID=${MaintainByIndicatorAgencyID ? MaintainByIndicatorAgencyID : '0'}&M_UsersID=${UserID}&Language=${Language}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleGetExport && handleGetExport()
                return result.data
            } else {
                return result
            }
        })
})

const GardenMasterTableDataSlice = createSlice({
    name: "GardenMasterTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GardenMasterTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(GardenMasterTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(GardenMasterTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GardenMasterTableDataReducer = GardenMasterTableDataSlice.reducer

// Export Table Data

export const GardenMasterTableExportDataAPI = createAsyncThunk("GardenMasterTableExportData", async ({ data }) => {
    const {
        UserID,
        token,
        From,
        To,
        M_AssetTypeID,
        M_WardID,
        M_ZoneID,
        // M_GD_SectorID,
        M_AssetID,
        Language,
        MaintainByIndicatorAgencyID
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_Asset_Select?M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&MaintainByIndicatorAgencyID=${MaintainByIndicatorAgencyID ? MaintainByIndicatorAgencyID : '0'}&M_UsersID=${UserID}&Language=${Language}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const GardenMasterTableExportDataSlice = createSlice({
    name: "GardenMasterTableExportData",
    initialState: {
        isExportLoading: false,
        ExportTableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GardenMasterTableExportDataAPI.pending, (state, action) => {
            state.isExportLoading = true;
        });
        builder.addCase(GardenMasterTableExportDataAPI.fulfilled, (state, action) => {
            state.isExportLoading = false;
            state.ExportTableData = action.payload;
        });
        builder.addCase(GardenMasterTableExportDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isExportLoading = false;
            state.isError = true;
            state.ExportTableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GardenMasterTableExportDataReducer = GardenMasterTableExportDataSlice.reducer


// InsertUpdate Table Data

export const GardenMasterPostDataAPI = createAsyncThunk("GardenMasterPostData", async ({ data }) => {
    const {
        M_AssetID,
        M_AssetTypeIDS,
        M_WardID,
        // M_GD_SectorID,
        AssetName,
        AssetName_Marathi,
        AreaName,
        AreaName_Marathi,
        AreaInSquareMetre,
        IsGetPresent,
        IsWateringOfEdgesPresent,
        IsWateringOfTreesPresent,
        IsWateringOfLawnsPresent,
        IsMaintingYou,
        M_StatusID,
        M_UserID,
        MaintainByIndicatorAgencyID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_AssetID", M_AssetID);
    formdata.append("M_AssetTypeID", M_AssetTypeIDS);
    formdata.append("M_WardID", M_WardID ? M_WardID : '0');
    // formdata.append("M_GD_SectorID", M_GD_SectorID);
    formdata.append("AssetName", AssetName);
    formdata.append("AssetName_Marathi", AssetName_Marathi);
    formdata.append("AreaName", AreaName);
    formdata.append("AreaName_Marathi", AreaName_Marathi);
    formdata.append("AreaInSquareMetre", AreaInSquareMetre ? AreaInSquareMetre : '0');
    formdata.append("IsGetPresent", IsGetPresent ? IsGetPresent : '0');
    formdata.append("IsWateringOfEdgesPresent", IsWateringOfEdgesPresent ? IsWateringOfEdgesPresent : '0');
    formdata.append("IsWateringOfTreesPresent", IsWateringOfTreesPresent ? IsWateringOfTreesPresent : '0');
    formdata.append("IsWateringOfLawnsPresent", IsWateringOfLawnsPresent ? IsWateringOfLawnsPresent : '0');
    formdata.append("IsMaintingYou", IsMaintingYou ? IsMaintingYou : '0');
    formdata.append("M_StatusID", M_StatusID);
    formdata.append("M_UserID", M_UserID);
    formdata.append("MaintainByIndicatorAgencyID", MaintainByIndicatorAgencyID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_Asset_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                // handleClearField()
                // handleOnCloseClick()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                handleOnCloseClick()
                toastErrorr(result.message)
            }

            return result
        })
})

const GardenMasterPostDataSlice = createSlice({
    name: "GardenMasterPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GardenMasterPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(GardenMasterPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(GardenMasterPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const GardenMasterPostDataReducer = GardenMasterPostDataSlice.reducer



// Delete Table Data

export const GardenMasterDeleteAPI = createAsyncThunk("GardenMasterDelete", async ({ data }) => {

    const {
        M_AssetID,
        M_UserID,
        handleIsPost,
        token,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_AssetID", M_AssetID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Post_M_Asset_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
                // handleDeleteCloseClick()
            } else {
                toastErrorr(result.message)
                // handleDeleteCloseClick()
            }
            return result

        })
})

const GardenMasterDeleteSlice = createSlice({
    name: "GardenMasterDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GardenMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(GardenMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;
            // toastSuccesss(action.payload.message)
        });
        builder.addCase(GardenMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GardenMasterDeleteReducer = GardenMasterDeleteSlice.reducer

/// Location Api

export const GardenMasterGeoLocationDataAPI = createAsyncThunk("GardenMasterGeoLocationData", async ({ data }) => {
    const {
        UserID,
        token,
        From,
        To,
        M_AssetTypeID,
        M_WardID,
        M_ZoneID,
        M_AssetID,
        Language,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_WEB_GD_M_Asset_GeoTagging_Select?M_AssetID=${M_AssetID ? M_AssetID : '0'}&M_AssetTypeID=${M_AssetTypeID ? M_AssetTypeID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_ZoneID=${M_ZoneID ? M_ZoneID : '0'}&M_UsersID=${UserID}&Language=${Language}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const GardenMasterGeoLocationDataSlice = createSlice({
    name: "GardenMasterGeoLocationData",
    initialState: {
        isLoadinglocation: false,
        locationData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(GardenMasterGeoLocationDataAPI.pending, (state, action) => {
            state.isLoadinglocation = true;
        });
        builder.addCase(GardenMasterGeoLocationDataAPI.fulfilled, (state, action) => {
            state.isLoadinglocation = false;
            state.locationData = action.payload;
        });
        builder.addCase(GardenMasterGeoLocationDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoadinglocation = false;
            state.isError = true;
            state.locationData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const GardenMasterGeoLocationDataReducer = GardenMasterGeoLocationDataSlice.reducer