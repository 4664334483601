import Header from "../../../../Components/Header/Header"
import Sidebar from "../../../../Components/Sidebar/Sidebar"
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import ProjectWiseContractorPopup from "./ProjectWiseContractorPopup"
import DeletePopup from "../GardenPopup/DeletePopup"
import { ProjectNameDataDDL } from "../../../../Components/CommonDDL/ProjectNameDataDDL"
import { useAuthState } from "../../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { ContractorNameDDL } from "../../../../Components/CommonDDL/ContractorNameDDL"
import { ProjectWiseContractorDeleteAPI, ProjectWiseContractorTableDataAPI } from "../../../../Redux/SidebarMasterSlice/ProjectWiseContractorSlice"
import { Pegination } from "../../../../Components/Pegination/Pegination"
import { Loading } from "../../../../Helper/Loading"
import moment from "moment";
import { useSearchParams } from "react-router-dom"
import { ProjectTypeDataDDL } from "../../../../Components/CommonDDL/ProjectTypeDataDDL"
import { GDProjectNameDDL } from "../../../../Components/CommonDDL/GDProjectNameDDL"
import { useTranslation } from "react-i18next"
import { WorkTypeDDL } from "../../../../Components/CommonDDL/WorkTypeDDL"
import { ContractorWiseAssetDDL } from "../../../../Components/CommonDDL/WorkTypeWiseContractorDDL"

export const ProjectWiseContractor = () => {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, RoleID } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    const [PerPageCount, setPerPageCount] = useState(10)
    const [From, setFrom] = useState(1)
    const [To, setTo] = useState(10)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsPost, setIsPost] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [SectorMasterPopupShow, setSectorMasterPopupShow] = useState()
    const [ProjectNamePopupShow, setProjectNamePopupShow] = useState()
    const [DeletePopupShow, setDeletePopupShow] = useState()
    const [ProjectWiseContractorPopupShow, setProjectWiseContractorPopupShow] = useState()
    const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))
    const [FromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')

    const [ProjectType, setProjectType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [ProjectName, setProjectName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ContractorWiseAsset, setContractorWiseAsset] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [ZoneNameDDLID, setZoneNameDDLID] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    const [ContractorName, setContractorName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [AssetType, setAssetType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: ''
    })

    useEffect(() => {
        setLanguage({
            ...Language,
            DDL: [],
            ID: 0,
            Label: localStorage.getItem('LanguageChange'),
        })
    }, [])

    const handleIsPost = () => {
        setIsPost(!IsPost)
    }

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setProjectWiseContractorPopupShow(true)
    }
    const handleEditOnClick = (item) => {
        setProjectWiseContractorPopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }

    const handleOnCloseClick = () => {
        setProjectWiseContractorPopupShow(false)
    }

    const handleDeleteApi = (item) => {
        const data = {
            M_ProjectWiseContractorID: item.m_ProjectWiseContractorID,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost
        }
        dispatch(ProjectWiseContractorDeleteAPI({ data }))
    }

    const { isDeleteLoading } = useSelector(state => state.ProjectWiseContractorDeleteData)

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setProjectName({
            ...ProjectName,
            ID: 0,
            Label: 'Select...'
        })
        setContractorWiseAsset({
            ...ContractorWiseAsset,
            ID: 0,
            Label: 'Select...'
        })
        setProjectType({
            ...ProjectType,
            ID: 0,
            Label: 'Select...'
        })
        setWorkType({
            ...WorkType,
            ID: 0,
            Label: 'Select...'
        })
        setContractorName({
            ...ContractorName,
            ID: 0,
            Label: 'Select...'
        })
    }

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [ProjectName.ID, ContractorWiseAsset.ID])

    useEffect(() => {
        // console.log(ProjectName.ID)
        const data = {
            M_ProjectWiseContractorID: '0',
            M_WorkTypeID: WorkType.ID,
            M_ProjectTypeID: 0,
            M_ProjectID: ProjectName.ID,
            // M_ContractorID: ContractorWiseAsset.ID,
            M_ContractorID: ContractorWiseAsset.ID,
            M_UserID: UserID,
            token: token,
            From: From,
            To: To,
        }
        dispatch(ProjectWiseContractorTableDataAPI({ data }))
    }, [IsPost, To, ProjectName.ID, ContractorWiseAsset.ID, IsClear, WorkType.ID])

    const { tableData, isLoading } = useSelector(state => state.ProjectWiseContractorTableData)

    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select Ward.",
    // })

    return (
        <>
            {isLoading && <Loading />}
            {isDeleteLoading && <Loading />}
            <div>
                <Header
                    // DashboardHeading="Garden Maintainance"
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper p-3">
                    <Sidebar
                        Language={Language}
                        ProjectTypeId={ProjectTypeId} />
                    <div className="content">
                        <div className="data_mob">
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className="col-10">
                                    <h5 className="ps-1 py-1">{t('AdminDashboard.Admin_Sidebar_Name.Project_Wise_Contractor')}</h5>
                                </div>
                                <div className="col-2">
                                    <div className="col-12 col-lg-7 float-end ">
                                        <i className="fa-solid fa-plus float-end btn btn-secondary mb-2 "
                                            style={{ color: "white" }}
                                            onClick={handleAddOnClick}
                                        > Add</i>
                                    </div>
                                </div>
                                <div className="row m-0 ">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12">
                                            <div className="row py-1 rounded2 d-flex">
                                                <div className="col-12 col-md-5 col-lg-4">
                                                    <WorkTypeDDL
                                                        WorkType={WorkType}
                                                        setWorkType={setWorkType}
                                                        IsPost={IsPost}
                                                        Language={Language}
                                                        Flag1='ProjectType'
                                                    />
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">
                                                    <ContractorWiseAssetDDL
                                                        ContractorWiseAsset={ContractorWiseAsset}
                                                        setContractorWiseAsset={setContractorWiseAsset}
                                                        rowData={rowData}
                                                        WorkType={WorkType}
                                                        AssetType={AssetType}
                                                        WardWiseAssetName={0}
                                                        RoleID={RoleID}
                                                        Flag='Master'
                                                        Language={Language}
                                                        Flag1='ContractorName'
                                                        ZoneNameDDLID={ZoneNameDDLID}

                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2 mt-md-4" ><button type="button" className="btn-clear btn btn-primary"
                                                    onClick={handleClear}
                                                >Clear</button></div>
                                            </div>
                                            <div className="row mob_table table-responsive pt-1">

                                                <table id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            <th>Project Type</th>
                                                            <th>Project Name</th>
                                                            <th>Contractor Name</th>
                                                            <th>From Date</th>
                                                            <th>To Date</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => (
                                                                <tr style={{ width: '100%' }}>
                                                                    <td style={{ textAlign: 'center' }}>{item.rowNum}</td>
                                                                    <td>{item.workTypeName ? item.workTypeName : '-'}</td>
                                                                    <td>{item.projectName ? item.projectName : '-'}</td>
                                                                    <td>{item.contractorName ? item.contractorName : '-'}</td>
                                                                    <td>{item.fromDate ? moment(item.fromDate).format("DD-MM-YYYY") : '-'}</td>
                                                                    <td>{item.toDate ? moment(item.toDate).format("DD-MM-YYYY") : '-'}</td>
                                                                    <td style={{ textAlign: "center", width: '7%' }}>
                                                                        <span>
                                                                            <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => handleEditOnClick(item)}
                                                                            >
                                                                            </i>
                                                                        </span>
                                                                        <span>
                                                                            <DeletePopup
                                                                                open={DeletePopupShow}
                                                                                handleDeleteApi={handleDeleteApi}
                                                                                item={item}

                                                                            />
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                                :
                                                                <>No data found</>
                                                        }

                                                    </tbody>
                                                </table>

                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {
                    ProjectWiseContractorPopupShow ?
                        <ProjectWiseContractorPopup
                            open={ProjectWiseContractorPopupShow}
                            popupHeading="Project Wise Contractor"
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                        />
                        : <></>
                }
            </div>
        </>

    )
}