import { useEffect } from 'react'
import Select from 'react-select'
import { AssetTypeDDLAPI} from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { AstricSign } from '../../Helper/AstricSign'
import { useTranslation } from 'react-i18next'

export const AssetTypeDataDDL = (props) => {
    const { AssetType, setAssetType, PopUpField, M_AssetTypeID, IsDisabled, Flag, Language } = props

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token, Language: Language.Label }
        { Language.Label != '' && dispatch(AssetTypeDDLAPI({ data })) }
    }, [Language.Label])

    const { AssetTypeData } = useSelector(state => state.AssetTypeDDLData)

    useEffect(() => {
        handleAssetTypeDDL()
    }, [AssetTypeData])

    const handleAssetTypeDDL = () => {
        if (AssetTypeData && AssetTypeData.table && AssetTypeData.table.length > 0) {
            let list = AssetTypeData.table.map((item, index) => ({
                value: item.m_AssetTypeID,
                label: item.assetTypeName,
            }))

            if (M_AssetTypeID) {
                setAssetType({
                    DDL: list,
                    ID: list[M_AssetTypeID - 1].value,
                    Label: list[M_AssetTypeID - 1].label,
                })
            } else {
                setAssetType({
                    DDL: list,
                    ID: Flag == 'SportScreenOnly' ? list[0].value : 0,
                    Label: Flag == 'SportScreenOnly' ? list[0].label : "Select...",
                })
            }
        }
        else {
            setAssetType({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>{t('Dashboard.Pcmc_Assets.Asset_Type')} {Flag && <AstricSign />}</b></label>
            <Select
                isClearable
                isDisabled={IsDisabled}
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: AssetType.ID, label: AssetType.Label }}
                onChange={(e) => {
                    e ?
                        setAssetType({ ...AssetType, ID: e.value, Label: e.label })
                        :
                        setAssetType({ ...AssetType, ID: 0, Label: "Select..." })

                }}
                options={AssetType.DDL}
            />
        </div>
    )
}