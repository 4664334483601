import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useAuthState } from '../../../../../../Helper/Context';
import { useDispatch } from 'react-redux';
import moment from 'moment'
import { ChemicalSupplyPostDataAPI } from '../../../../../../Redux/WaterSupplyDashBoardSlice/PreventiveWorkWaterSupply/ChemicalSupplySlice';


export default function WaterChemicalSupplyPreventiveWorkPopup(props) {

    const { handleOnCloseClick, open, Flag, handleIsPost, item, EditIcon, apiFlag, rowData, } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [WaterChemicalSupplyField, setWaterChemicalSupplyField] = useState({
        readingDate: Flag === 'Update' ? moment(rowData.readingDate).format("YYYY-MM-DD") : '',
        wardName: Flag === 'Update' ? rowData.wardName : '',
        junctionDetails: Flag === 'Update' ? rowData.junctionDetails : '',
        pipelineDetails: Flag === 'Update' ? rowData.pipelineDetails : '',
        chroline: Flag === 'Update' ? rowData.chroline : '',

    })

    const handleChemicalSupplyField = (e) => {
        setWaterChemicalSupplyField({ ...WaterChemicalSupplyField, [e.target.name]: e.target.value })
    }

    const handlePost = () => {
        const data = {
            T_WS_ChemicalSupplyID: Flag === 'Update' ? rowData.t_wS_ChemicalSupplyID : '0',
            Chroline: WaterChemicalSupplyField.chroline,
            M_UserID: UserID,
            token: token,
            handleIsPost: handleIsPost,
            handleOnCloseClick: handleOnCloseClick
        }
        dispatch(ChemicalSupplyPostDataAPI({ data }))
    }

    return (
        <Popup
            open={open} closeOnDocumentClick={false} onClose={handleOnCloseClick}
        >
            <div className="water_pop modal-dialog modal-dialog-scrollable modal-xl">
                <div className="modal-content">
                    <div className="modal-header pl-4 p-1">
                        <h4 className="modal-title" id="staticBackdropLabel">Chemical Supply</h4>
                        <button onClick={handleOnCloseClick} className="btn-close m-1 mr-3" type="button" />
                    </div>

                    <div className="modal-body">
                        <div className='inner-modal-body'>

                            <div className="row details-row">

                                <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Date :</b></label>
                                        <input type="date" className="form-control"
                                            name='readingDate'
                                            aria-describedby="emailHelp"
                                            value={WaterChemicalSupplyField.readingDate}
                                            onChange={(e) => handleChemicalSupplyField(e)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6" >
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Prabhag No :</b></label>
                                        <input type="text" className="form-control"
                                            name='wardName'
                                            aria-describedby="emailHelp"
                                            value={WaterChemicalSupplyField.wardName}
                                            onChange={(e) => handleChemicalSupplyField(e)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6" >
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Location Name :</b></label>
                                        <input type="text" className="form-control"
                                            name='junctionDetails'
                                            aria-describedby="emailHelp"
                                            value={WaterChemicalSupplyField.junctionDetails}
                                            onChange={(e) => handleChemicalSupplyField(e)}
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-6" >
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Pipe Line Size :</b></label>
                                        <input type="text" className="form-control"
                                            id="exampleInputEmail1"
                                            name='pipelineDetails'
                                            value={WaterChemicalSupplyField.pipelineDetails}
                                            onChange={(e) => handleChemicalSupplyField(e)}
                                        />

                                    </div>
                                </div>

                                <div className=" col-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                        <label className="d-block" htmlFor="NameofDepartment"><b>Water Reading :</b></label>
                                        <input type="text" className="form-control"
                                            name='chroline'
                                            aria-describedby="emailHelp"
                                            value={WaterChemicalSupplyField.chroline}
                                            onChange={(e) => handleChemicalSupplyField(e)}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 col-lg-12">
                                <div className="btn-action d-flex justify-content-end mt-lg-3">
                                    <button type='button'
                                        className="btn allBtn  float-right submit_btn"
                                        onClick={() => handlePost()}
                                    >{Flag === "Update" ? "Update" : "Save"} </button>
                                    <button type="button" className="btn addBtns cancel_btn ms-2 mr-2 mx-2  float-right" onClick={handleOnCloseClick} >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* )} */}
        </Popup>
    )

}