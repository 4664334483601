
import React from "react";
const Image_1 = "../assets/img/Loading.png";
const BrokenImage = '../assets/img/not_found.png'

export default function ViewQR({ handleClose, ShowQRPopUp, Heading, handleDownloadQR }) {

    const { data } = ShowQRPopUp

    const imageOnLoad = (event) => {
        console.log(event)
        console.log(`Picture successfully ${event.currentTarget.src} loaded.`);
        if (event.currentTarget.className !== "error") {
            event.currentTarget.className = "success";
        }
    };
    const imageOnError = (event) => {
        event.currentTarget.src = BrokenImage;
        event.currentTarget.className = "error";
    };

    return (
        <>
            <div className=" modal show fade" style={{ display: "block", backgroundColor: "#00000055", overflow: 'auto' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content" id='nested'>
                        <div className="modal-header popheding" id="popupheading">
                            <div className="mt-2 ml-4">
                                <h4>{Heading ? Heading : 'Area Name Photo'}</h4>
                            </div>
                            <div className='mt-1'>
                                {/* <ExportOfficeLetterSecondPopUpCOMM /> */}
                            </div>

                            <button type="button" className="btn-close mr-3 mt-2 " data-dismiss="modal" aria-label="Close" data-backdrop='static'
                                style={{ color: 'white' }}
                                onClick={() => {
                                    handleClose()
                                }}>
                                {/* <span className="x" aria-hidden="true">&times;</span> */}
                            </button>
                        </div>
                        <div className="col-12 modal-body" >
                            {
                                data.qR_CodePath &&
                                <button type="button"
                                    style={{ background: '#254462', borderColor: '#254462' }}
                                    className="btn btn-primary float-end mr-1 ml-1 "
                                    onClick={() => handleDownloadQR(data.qR_CodePath, data.assetName_English, data.equipmentName, data.equipmentNumber)}
                                >
                                    QR Download
                                </button>
                            }
                            <div className="col-12 modal-header justify-content-center" >



                                {
                                    data.qR_CodePath ?
                                        <>
                                            <div className="col-12">
                                                <img className="col-12" style={{ width: "400px", height: "400px" }}
                                                    src={data.qR_CodePath == null ? "ImageNotFound" : data.qR_CodePath}
                                                    onLoad={imageOnLoad} onError={imageOnError} />
                                            </div>
                                            {/* <div className="col-12">
                                                <text>Garden Name : {data?.assetName_English}</text>
                                                <text>Equipment Name : {data?.equipmentName}</text>
                                                <text>Equipment Number : {data?.equipmentNumber}</text>
                                            </div> */}
                                        </>
                                        : <img src="../assets/img/not_found.png" />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


