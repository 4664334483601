import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Header from '../../../../../Components/Header/Header'
import Sidebar from '../../../../../Components/Sidebar/Sidebar'
import { WorkApprovalTableDataAPI } from '../../../../../Redux/RoadDashboardSlice/WorkApprovalSlice'
import { useAuthState } from '../../../../../Helper/Context'
import { useDispatch, useSelector } from 'react-redux'
import { MonthDataDDL } from '../../../../../Components/CommonDDL/MonthDataDDL'
import { WardNameDataDDL } from '../../../../../Components/CommonDDL/WardNameDataDDL'
import moment from "moment"
import { Pegination } from '../../../../../Components/Pegination/Pegination'
import ViewURL from '../../../../../Components/ViewURL/ViewURL'
import { RoadWorkNameDDL } from '../../../../../Components/CommonDDL/RoadWorkNameDDL'
import { Loading } from '../../../../../Helper/Loading'


export default function RoadWorkApproval() {

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    // ----------pagination-------------
    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)

    const [ImagePopUpData, setImagePopUpData] = React.useState('')
    const [showImagePopup, setshowImagePopup] = React.useState(false)
    const [YearValue, setYearValue] = useState(0)

    const [FinancialYear, setFinancialYear] = useState({
        DDL: [],
        ID: HYear && HYear ? HYear : '0',
        Label: YLable && YLable ? YLable : "Select...",
    })

    const [Month, setMonth] = useState({
        DDL: [],
        ID: HMonth && HMonth ? HMonth : '0',
        Label: MLable && MLable ? MLable : "Select...",
    });

    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })
    const [RoadWorkName, setRoadWorkName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select...",
    // })

    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)

        setMonth({
            ...Month,
            ID: HMonth,
            Label: MLable,
        })
        setWardName({
            ...WardName,
            ID: HWard,
            Label: WLable,
        })
        setRoadWorkName({
            ...RoadWorkName,
            ID: 0,
            Label: 'Select...',
        })
        // console.log("call")
    }

    const handlePost = () => {
        setIsPost(!IsPost)
    }

    const handleShowImage = (img) => {
        setshowImagePopup(true)
        setImagePopUpData(img)
    }

    useEffect(() => {
        const data = {
            M_WardID: WardName.ID,
            M_FinancialYearID: HYear,
            M_MonthID: Month.ID,
            M_RM_LocationID: '0',
            M_RM_WorkID: RoadWorkName.ID,
            M_UsersID: UserID,
            token: token,
            From: From,
            To: To,
            Flag: searchName,
        }
        if (HYear && Month.ID && WardName.ID) {
            dispatch(WorkApprovalTableDataAPI({ data }))
        }
    }, [IsPost, From, WardName.ID, Month.ID, RoadWorkName.ID, HYear])

    const { tableData, isLoading } = useSelector(state => state.WorkApprovalTableData)

    return (
        <>
            {isLoading && <Loading />}
            <div>
                <Header
                    DashboardHeading="Road Maintainance"
                    setYearValue={setYearValue}
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName} />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId} />
                    <div className="content">
                        <div className="page-header ">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        {/* <div className="page-header-title">
                                            <h5 className="ps-2 data_mob"> */}

                                        {/* <!-- <span className="float-end">namita</span> --> */}
                                        {/* <span className="float-end fs-6">
                                                <Select
                                                // isClearable
                                                // isRtl={isRtl}
                                                isSearchable
                                                maxMenuHeight={150}
                                                value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                onChange={(e) => {
                                                    e ?
                                                        setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                        :
                                                        setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                }}
                                                options={SelectWard.DDL}
                                            />

                                            </span> */}
                                        {/* </h5>

                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- [ Main Content ] start --> */}
                        <div className='data_mob'>
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className='col-8'>
                                    <h5 className="py-1">Road Work Approval {searchName}</h5>
                                </div>
                                <div className='col-4'>
                                    <button type="button" className="btn btn-primary float-end">Export</button>
                                </div>
                                <div className="row m-0 pt-lg-1 pt-md-1">
                                    <div className="col-12  rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: "#27466317" }}>
                                        <div className="col-12">

                                            <div className="row py-1 rounded2">

                                                <div className="col-12 col-md-6 col-lg-3 ">
                                                    <MonthDataDDL
                                                        Month={Month}
                                                        setMonth={setMonth}
                                                        MLable={MLable}
                                                        HMonth={HMonth}
                                                        Flag='Month'
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3 ">
                                                    <WardNameDataDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        HWard={HWard}
                                                        WLable={WLable}
                                                    />

                                                </div>
                                                <div className="col-12 col-md-6 col-lg-3 ">
                                                    <RoadWorkNameDDL
                                                        RoadWorkName={RoadWorkName}
                                                        setRoadWorkName={setRoadWorkName}
                                                        Flag={searchName === 'Approved' ? 'Approved' : searchName === 'Pending' ? 'ApprovalPending' : 'Rejected'}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-1 mt-lg-4 mt-md-4" >
                                                    <button type="button"
                                                        className="btn-clear btn btn-primary"
                                                        onClick={handleClear}>Clear</button>

                                                </div>
                                            </div>
                                            <div className="row mob_table table-responsive pt-1">

                                                <table id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className='sr_no_col'>Sr No.</th>
                                                            <th>Location Name</th>
                                                            <th>Work Area</th>
                                                            <th>Work Name</th>
                                                            <th>Work Number</th>
                                                            <th>Work Upload Date</th>
                                                            <th>Photo</th>
                                                            {
                                                                searchName == "Pending" ?
                                                                    <>
                                                                        <th>Status</th>
                                                                    </>
                                                                    : ''
                                                            }

                                                            {
                                                                searchName == "Rejected" ?
                                                                    <>
                                                                        <th>Rejected by</th>
                                                                        <th>Rejected Date</th>
                                                                        <th>Remark</th>
                                                                    </>
                                                                    : ''
                                                            }
                                                            {
                                                                searchName == "Approved" ?
                                                                    <>
                                                                        <th>Approved by</th>
                                                                        <th>Approved Date</th>
                                                                        <th>JE / DE Name</th>
                                                                    </>
                                                                    : ''
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) => (
                                                                <tr>
                                                                    <td>{item.rowNum}</td>
                                                                    <td>{item.locationName ? item.locationName : '-'}</td>
                                                                    <td>{item.workArea ? item.workArea : '-'}</td>
                                                                    <td>{item.workName ? item.workName : '-'}</td>
                                                                    <td>{item.workNumber ? item.workNumber : '-'}</td>
                                                                    <td>{item.workUploadDate ? item.workUploadDate : '-'}</td>
                                                                    {/* <td>
                                                                        <span>
                                                                            <i class="fa-solid fa-eye ms-2" style={{ cursor: 'pointer' }} title='View'
                                                                                onClick={() => handleShowImage(item.beforeWork)}
                                                                            ></i>
                                                                        </span>
                                                                    </td> */}
                                                                    <td style={{ textAlign: "center" }}>
                                                                        {
                                                                            (item.beforeWork == null) ?
                                                                                <span>-</span>
                                                                                :
                                                                                <>
                                                                                    <i className="fa-solid fa-eye" title='View'
                                                                                        style={{ cursor: 'pointer', alignItems: "center" }}
                                                                                        onClick={() => handleShowImage(item.beforeWork, 'Start Photo')}
                                                                                    ></i>
                                                                                </>
                                                                        }
                                                                    </td>
                                                                    {
                                                                        searchName == "Pending" ?
                                                                            <>
                                                                                <td>{item.statusName ? item.statusName : '-'}</td>
                                                                            </>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        searchName == "Rejected" ?
                                                                            <>
                                                                                <td>{item.approved_By ? item.approved_By : '-'}</td>
                                                                                <td>{item.approveRejectedActionDate ? moment(item.approveRejectedActionDate).format("DD-MM-YYYY") : '-'}</td>
                                                                                <td>{item.remark ? item.remark : '-'}</td>
                                                                            </>
                                                                            : ''
                                                                    }
                                                                    {
                                                                        searchName == "Approved" ?
                                                                            <>
                                                                                <td>{item.approved_By ? item.approved_By : '-'}</td>
                                                                                <td>{item.approveRejectedActionDate ? moment(item.approveRejectedActionDate).format("DD-MM-YYYY") : '-'}</td>
                                                                                <td>{item.jE_DE_ByName ? item.jE_DE_ByName : '-'}</td>
                                                                            </>
                                                                            : ''
                                                                    }
                                                                </tr>
                                                            ))
                                                                :
                                                                <>No found data</>
                                                        }


                                                    </tbody>
                                                </table>
                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {
                    showImagePopup ?
                        <ViewURL
                            Heading='Upload Photo'
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}
                        />
                        : ''


                }
            </div>
        </>
    )
}