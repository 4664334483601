
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";

// Get Table Data
export const WorkApprovalTableDataAPI = createAsyncThunk("WorkApprovalTableData", async ({ data }) => {
    const {
        M_WardID,
        M_FinancialYearID,
        M_MonthID,
        M_RM_LocationID,
        M_RM_WorkID,
        M_UsersID,
        token,
        From,
        To,
        Flag,
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    return fetch(`${BaseUrl}/APKDashboard/Get_WEB_RM_T_KPI_WorkApproval_Select?M_FinancialYearID=${M_FinancialYearID ? M_FinancialYearID : '0'}&M_MonthID=${M_MonthID ? M_MonthID : '0'}&M_WardID=${M_WardID ? M_WardID : '0'}&M_RM_LocationID=${M_RM_LocationID ? M_RM_LocationID : '0'}&M_RM_WorkID=${M_RM_WorkID ? M_RM_WorkID : '0'}&M_UsersID=${M_UsersID}&Flag=${Flag}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WorkApprovalTableDataSlice = createSlice({
    name: "WorkApprovalTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkApprovalTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkApprovalTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WorkApprovalTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WorkApprovalTableDataReducer = WorkApprovalTableDataSlice.reducer


