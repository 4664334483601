
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { SeverageWorkTypeDDLAPI} from '../../Redux/DDLSlice'
import { AstricSign } from '../../Helper/AstricSign'

export const SeverageWorkTypeDataDDL = (props) => {
    const { SeverageWorkType, setSeverageWorkType, rowData, Flag, IsPost } = props
// console.log(rowData)
    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token }
        dispatch(SeverageWorkTypeDDLAPI({ data }))
    }, [IsPost])

    const { SeverageData } = useSelector(state => state.SeverageWorkTypeData)


    useEffect(() => {
        handleWorkTypeDDL()
    }, [SeverageData])

    const handleWorkTypeDDL = () => {
        // console.log(DeptDDLDataa)
        if (SeverageData && SeverageData.table && SeverageData.table.length > 0) {
            let list = SeverageData.table.map((item, index) => ({
                value: item.m_sW_WorkTypeID,
                label: item.workType,
            }))

            setSeverageWorkType({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_sW_WorkTypeID : 0,
                Label: Flag === 'Update' ? rowData.workType : "Select...",
            })
        }
        else {
            setSeverageWorkType({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Work Type {Flag && <AstricSign /> }:</b></label>
            <Select
                // isClearable
                // isRtl={isRtl}
                // className="ddl_width2"
                isSearchable
                maxMenuHeight={150}
                value={{ value: SeverageWorkType.ID, label: SeverageWorkType.Label }}
                onChange={(e) => {
                    e ?
                        setSeverageWorkType({ ...SeverageWorkType, ID: e.value, Label: e.label })
                        :
                        setSeverageWorkType({ ...SeverageWorkType, ID: 0, Label: "Select..." })

                }}
                options={SeverageWorkType.DDL}
            />
        </div>
    )
}