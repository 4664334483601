
import ReactHTMLTableToExcel from "react-html-table-to-excel"
import moment from 'moment'
import { useTranslation } from "react-i18next"

export const ChowksMasterExportData = (props) => {
    const { ExcelData, name, searchName, FromDate, ToDate } = props

    const { t } = useTranslation()

    return (
        <div style={{ display: 'inline', marginLeft: '0px' }}>
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                table="table-to-FarmerMasterExport"
                className="btn btn-primary float-end download-table-xls-button float-end btn btn-export btn-md mr-1 mb-1 ml-1"
                filename={name}
                sheet={name}
                buttonText="Export"
                style={{ borderColor: 'black' }}
            />


            <table id="table-to-FarmerMasterExport" style={{ display: 'none', width: '47%' }}>

                <h5><span>&emsp;</span></h5>

                {/* <h4 style={{ textAlign: 'center', fontSize: 16 }}>{name}</h4> */}
                {/* <h4 style={{ textAlign: 'center', fontSize: 16 }}>Navi Mumbai Muncipal Corporation</h4>
                <h4 style={{ textAlign: 'center', fontSize: 16 }}>STP Maintenance Department</h4>
                <h4 style={{ textAlign: 'center', fontSize: 16 }}>Project Name - {ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table[0].projectName : '-'}</h4>
                <h4 style={{ textAlign: 'center', fontSize: 16 }}>Report Name - {name}</h4>
                <h4 style={{ textAlign: 'center', fontSize: 16 }}>
                    Report From Date - {FromDate ? moment(FromDate).format('DD-MM-YYYY') : '-'}
                    <span>&emsp;</span>
                    | To Date - {ToDate ? moment(ToDate).format('DD-MM-YYYY') : '-'}
                </h4>

                <tr><td></td></tr> */}

                <thead>
                    <tr style={{ backgroundColor: '#254462', color: "#fff", border: '1px solid white', borderTop: "1px solid black" }}>
                        <th className="sr_no_col">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                        <th>{t('Dashboard.Pcmc_Assets.Ward_no')}</th>
                        {/* <th>{t('Dashboard.Pcmc_Assets.Sector_Name')}</th> */}
                        <th>{t('Dashboard.Pcmc_Assets.Area_Name')}</th>
                        <th>{t('Dashboard.Pcmc_Assets.chowks_Name')}</th>
                        <th>{t('Dashboard.Pcmc_Assets.Area_sq')}</th>
                        <th>{t('Dashboard.Pcmc_Assets.Maintain_By')}</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        ExcelData && ExcelData.table && ExcelData.table.length > 0 ? ExcelData.table.map((item) => {
                            return (
                                <tr style={{ width: '100%', border: "1px solid black" }}>
                                    <td style={{ textAlign: "center" }}>{item.rowNum ? item.rowNum : '-'}</td>
                                    <td>{item.wardName ? item.wardName : '-'}</td>
                                    {/* <td>{item.sectorName ? item.sectorName : '-'}</td> */}
                                    <td>{item.areaName ? item.areaName : '-'}</td>
                                    <td>{item.assetName ? item.assetName : '-'}</td>
                                    <td>{item.areaInSquareMetre ? item.areaInSquareMetre : '-'}</td>
                                    <td>{item.indicatorName ? item.indicatorName : '-'}</td>
                                </tr>
                            )
                        })
                            :
                            <td colSpan={15}>No data found</td>
                    }

                </tbody>

            </table>
        </div>
    )
}