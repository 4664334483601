import Header from "../../../../Components/Header/Header"
import Sidebar from "../../../../Components/Sidebar/Sidebar"
import React, { useEffect, useState } from 'react'
import WorkTypePopup from "./WorkTypePopup"
import DeletePopup from "../GardenPopup/DeletePopup"
import { WorkTypeDDL } from "../../../../Components/CommonDDL/WorkTypeDDL"
import { useAuthState } from "../../../../Helper/Context"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { Loading } from "../../../../Helper/Loading"
import { WorkTypeDeleteAPI, WorkTypeTableDataAPI } from "../../../../Redux/SidebarMasterSlice/WorkTypeSlice"
import { Pegination } from "../../../../Components/Pegination/Pegination"
import RMWorkTypePopup from "./RMWorkTypePopUp"
import { useTranslation } from "react-i18next"


export const RMWorkType = () => {

    const { t } = useTranslation()

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    const [PerPageCount, setPerPageCount] = useState(10)
    const [TotalCount, setTotalCount] = useState(0)
    const [To, setTo] = useState(10)
    const [From, setFrom] = useState(1)
    const [rowNo, setrowNo] = useState(1)
    const [CurrentPage, setCurrentPage] = useState(0)

    const [IsSearch, setIsSearch] = useState(false)
    const [IsClear, setIsClear] = useState(false)
    const [IsPost, setIsPost] = useState(false)
    const [DeletePopupShow, setDeletePopupShow] = useState(false)

    const [WorkTypePopupShow, setWorkTypePopupShow] = useState()

    const [apiFlag, setapiFlag] = useState('')
    const [rowData, setrowData] = useState('')

    const handleAddOnClick = () => {
        setapiFlag('Insert')
        setWorkTypePopupShow(true)
    }
    const handleEditOnClick = (item) => {
        setWorkTypePopupShow(true)
        setapiFlag('Update')
        setrowData(item)
    }

    const handleOnCloseClick = () => {
        setWorkTypePopupShow(false)
    }

    const [WorkType, setWorkType] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })

    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })
    const handleClear = () => {
        setCurrentPage(0)
        setIsClear(!IsClear)
        setWorkType({
            ...WorkType,
            ID: 0,
            Label: 'Select...',
        })

    }
    const handleIsPost = () => {
        setIsPost(!IsPost)
        // if (tableData && tableData.table && tableData.table.length > 0) {
        //     setCurrentPage(0)
        //     setFrom(1)
        //     setTo(10)
        // }
    }


    // const handleShowImage = (img) => {
    //     setshowImagePopup(true)
    //     setImagePopUpData(img)
    // }

    useEffect(() => {
        const data = {
            M_WorkTypeID: WorkType.ID,
            workTypeName: '',
            M_UserID: UserID,
            token: token,
            From: From,
            To: To,
            Flag: ApiFlag,
            Language: Lang
        }
        // if (YearValue !== 0) {
        dispatch(WorkTypeTableDataAPI({ data }))
        // }

    }, [IsPost, To, IsClear, WorkType.ID])

    const { tableData, isLoading } = useSelector(state => state.WorkTypeTableData)

    useEffect(() => {
        setCurrentPage(0)
        setFrom(1)
        setTo(10)
    }, [WorkType.ID])

    const handleDeleteApi = (item) => {

        const data = {
            M_WorkTypeID: item.m_WorkTypeID,
            M_UserID: UserID,
            token: token,
            Flag: 'Delete',
            handleIsPost: handleIsPost
        }
        dispatch(WorkTypeDeleteAPI({ data }))
    }
    const { isDeleteLoading } = useSelector(state => state.WorkTypeDeleteData)

    // const [SelectWard, setSelectWard] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: "Select Ward",
    // })

    // console.log("WorkType", WorkType.ID)

    return (
        <>
            {isLoading && <Loading />}
            {isDeleteLoading && <Loading />}
            <div>
                <Header
                    // DashboardHeading="Garden Maintainance"
                    DashboardName={DashboardName} 
                />
                <div className="wrapper wrapper1">
                    <Sidebar ProjectTypeId={ProjectTypeId} />
                    <div className="content">
                        {/* <!-- [ breadcrumb ] start --> */}
                        <div className="page-header ">
                            <div className="page-block">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        {/* <div className="page-header-title">
                                            <h5 className="ps-2 data_mob">MASTER */}
                                        {/* <!-- <span className="float-end">namita</span> --> */}
                                        {/* <span className="float-end fs-6">
                                                <Select
                                                    // isClearable
                                                    // isRtl={isRtl}
                                                    isSearchable
                                                    maxMenuHeight={150}
                                                    value={{ value: SelectWard.ID, label: SelectWard.Label }}
                                                    onChange={(e) => {
                                                        e ?
                                                            setSelectWard({ ...SelectWard, ID: e.value, Label: e.label })
                                                            :
                                                            setSelectWard({ ...SelectWard, ID: 0, Label: "Select..." })

                                                    }}
                                                    options={SelectWard.DDL}
                                                />

                                            </span> */}
                                        {/* </h5>

                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- [ Main Content ] start --> */}
                        <div className="data_mob">
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className="col-8">
                                    <h5 className="py-2">RM Work Type Road Master</h5>
                                </div>
                                <div className="">
                                    <div className="col-12 mar_add_minus col-lg-7 float-end">
                                        {/* <RMWorkTypePopup
                                            open={WorkTypePopupShow}
                                            handleIsPost={handleIsPost}
                                        /> */}
                                        <i className="fa-solid fa-plus float-end btn btn-secondary mb-2"
                                            onClick={handleAddOnClick} style={{ color: "white", marginTop: '-2.5em' }}
                                        >
                                            <text className="ml-2 poppins">Add</text>
                                        </i>
                                    </div>
                                </div>
                                <div className="row m-0 ">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12  ">

                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-4 col-lg-3">
                                                    <WorkTypeDDL
                                                        WorkType={WorkType}
                                                        setWorkType={setWorkType}
                                                    />

                                                </div>
                                                <div className="col-12 col-md-2 col-lg-4 mt-md-4 mt-lg-4">
                                                    <button type="button"
                                                        className="btn-clear btn btn-primary"
                                                        onClick={handleClear}>Clear</button>
                                                </div>
                                                {/* <div className="col-12 col-lg-6" ><button type="button" style={{ float: 'right', marginTop: "-0.2em" }} className="btn-clear btn btn-primary">Clear</button></div> */}
                                            </div>
                                            <div className="row mob_table table-responsive pt-1">

                                                <table id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">Sr No.</th>
                                                            <th>Work Type</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            tableData && tableData.table && tableData.table.length > 0 ? tableData.table.map((item) =>

                                                            <tr>
                                                                <td style={{ textAlign: "center" }}>{'-'}</td>
                                                                <td>{'-'}</td>
                                                                <td style={{ textAlign: "center" }}> <span >
                                                                    {/* <RMWorkTypePopup
                                                                        open={WorkTypePopupShow}
                                                                        item=''
                                                                        EditIcon='Update'
                                                                        Flag='Update'
                                                                        handleIsPost={handleIsPost}
                                                                    /> */}
                                                                    <span>
                                                                        <i className="fa-solid fa-pen-to-square" title='Edit'
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => handleEditOnClick(item)}
                                                                        >
                                                                        </i>
                                                                    </span>
                                                                </span>
                                                                    <DeletePopup
                                                                        open={DeletePopupShow}
                                                                    // handleDeleteApi={handleDeleteApi}
                                                                    // item={item}
                                                                    />
                                                                </td>

                                                            </tr>
                                                            ) :
                                                                <>No Found data</>
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>
                                            {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                {
                    WorkTypePopupShow ?
                        <RMWorkTypePopup
                            open={WorkTypePopupShow}
                            // popupHeading="Location Name"                         
                            handleIsPost={handleIsPost}
                            handleOnCloseClick={handleOnCloseClick}
                            Flag={apiFlag}
                            rowData={rowData}
                        />
                        : <></>
                }
            </div>
        </>

    )
}