
import React from 'react'
import EstimatedCard from './EstimatedCard'
import { useNavigate } from 'react-router-dom'


export default function EstimatedCardManage(props) {
    const { Countdata,HeaderMonthID,HeaderFinancialYear , MonthValue, YearValue , YearLabel,MonthLabel,WardValue,WardLabel,ProjectTypeId,DashboardName  } = props

    const navigate = useNavigate()
    const EstimateAmountNavigate = (name, screenFlag) => {

        if (name === "Estimated Amount (RS)") {
            navigate(`/roadworkestimated?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Ward Wise Work") {
            navigate(`/roadworkprogresscompleted?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }
    return (

        <div className='row road_work'>
            <div className="col-lg-6 col-12" >
                <EstimatedCard
                    heading='Estimated Amount (RS)'
                    count={Countdata.ESTIMATED_AMOUNT}
                    imgPath='./assets/img/money.png'
                    cardName='estimate_img'
                    // navLink='/roadworkestimated'
                    countText='green_color'
                    EstimateAmountNavigate={EstimateAmountNavigate}
                />
            </div>
            <div className="col-lg-6" >
                 <EstimatedCard
                    heading='Ward Wise Work'
                    count={Countdata.WorkProgress_CompletedTotalCount}
                    imgPath='./assets/img/working.png'
                    cardName='estimate_img'
                    // navLink='/roadworkprogresscompleted'
                    countText='green_color'
                    EstimateAmountNavigate={EstimateAmountNavigate}
                />
            </div>
        </div>

    )
}






