
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import moment from "moment";
import { RoleNameDataDDL } from '../../../../Components/CommonDDL/RoleNameDataDDL';
import Header from '../../../../Components/Header/Header';
import Sidebar from '../../../../Components/Sidebar/Sidebar';
import { WardNameDataDDL } from '../../../../Components/CommonDDL/WardNameDataDDL';
import { useTranslation } from 'react-i18next';

export default function EmployeeTrackingTable() {

    const { t } = useTranslation()

    // const userDetails = useAuthState();
    // const { UserID, token, } = userDetails
    // const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let searchName = searchParams.get("name")
    let ApiFlag = searchParams.get("ApiFlag")
    let HYear = searchParams.get("Year")
    let YLable = searchParams.get("YLable")
    let HMonth = searchParams.get("Month")
    let MLable = searchParams.get("MLable")
    let HWard = searchParams.get("WardName")
    let WLable = searchParams.get("WLable")
    let FlagName = searchParams.get("Flag")
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")
    let Lang = searchParams.get("Lang")

    // console.log(FlagName)

    const [Role, setRole] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",
    })
    const [WardName, setWardName] = useState({
        DDL: [],
        ID: HWard && HWard ? HWard : '0',
        Label: WLable && WLable ? WLable : "Select...",
    })

    // const [Language, setLanguage] = useState({
    //     DDL: [],
    //     ID: 0,
    //     Label: Lang,
    // })

    const [Language, setLanguage] = useState({
        DDL: [],
        ID: 0,
        Label: 'Marathi',
    })


    // ----------pagination-------------
    // const [PerPageCount, setPerPageCount] = useState(10)
    // const [TotalCount, setTotalCount] = useState(0)
    // const [To, setTo] = useState(10)
    // const [From, setFrom] = useState(1)
    // const [rowNo, setrowNo] = useState(1)
    // const [CurrentPage, setCurrentPage] = useState(0)

    // const [IsSearch, setIsSearch] = useState(false)
    // const [IsClear, setIsClear] = useState(false)
    // const [IsPost, setIsPost] = useState(false)
    // const [YearValue, setYearValue] = useState(0)
    // const [ImagePopUpData, setImagePopUpData] = React.useState('')
    // const [showImagePopup, setshowImagePopup] = React.useState(false)
    // const [Heading, setHeading] = React.useState(false)
    // const [FromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    // const [ToDate, setToDate] = useState(moment().format('YYYY-MM-DD'))


    const [PopUpField, setPopUpField] = React.useState({
        addPopUp: false,
        deletePopUp: false,
        popupFlag: '',
        popupBtn: "",
        apiFlag: "",
        rowData: ''
    })

    const [ZoneName, setZoneName] = useState({
        DDL: [],
        ID: 0,
        Label: "Select...",

    })

    // const handleClear = () => {
    //     setCurrentPage(0)
    //     setIsClear(!IsClear)
    //     setFromDate(moment().startOf('month').format('YYYY-MM-DD'))
    //     setToDate(moment().format('YYYY-MM-DD'))
    //     setWardName({
    //         ...WardName,
    //         ID: HWard,
    //         Label: WLable,
    //     })
    //     setAssetType({
    //         ...AssetType,
    //         ID: 0,
    //         Label: 'Select...',
    //     })
    //     setWorkAssignedBy({
    //         ...WorkAssignedBy,
    //         ID: 0,
    //         Label: 'Select...',
    //     })

    //     // console.log("call")
    // }

    // const handlePost = () => {
    //     setIsPost(!IsPost)
    // }


    // const handleShowImage = (img) => {
    //     setshowImagePopup(true)
    //     setImagePopUpData(img)
    // }
    // useEffect(() => {
    //     // console.log(FinancialYear, FinancialYearID, FinancialYearLabel)
    //     const data = {
    //         // FinancialYearID: YearValue,
    //         FromDate: FromDate,
    //         ToDate: ToDate,
    //         M_WardID: WardName.ID,
    //         M_AssetTypeID: AssetType.ID,
    //         WorkAssignByM_UserID:WorkAssignedBy.ID,
    //         M_UsersID: UserID,
    //         token: token,
    //         From: From,
    //         To: To,
    //         Flag: ApiFlag,

    //     }
    //     if (WardName.ID) {
    //         dispatch(WorkAssignedTableDataAPI({ data }))
    //     }

    // }, [IsPost, To, IsClear, WardName.ID, AssetType.ID,WorkAssignedBy.ID, FromDate, ToDate])

    // const { tableData, isLoading } = useSelector(state => state.WorkAssignedTableData)

    // useEffect(() => {
    //     setCurrentPage(0)
    //     setFrom(1)
    //     setTo(10)
    // }, [WardName.ID, FromDate, ToDate, AssetType.ID])

    return (
        <>
            {/* {isLoading && <Loading />} */}
            <div>
                <Header
                    DashboardHeading="Garden Maintainance"
                    MLable={MLable}
                    HMonth={HMonth}
                    YLable={YLable}
                    HYear={HYear}
                    HWard={HWard}
                    WLable={WLable}
                    flag='Screen'
                    DashboardName={DashboardName}
                    Language={Language}
                    setLanguage={setLanguage}
                />
                <div className="wrapper">
                    <Sidebar ProjectTypeId={ProjectTypeId}
                        Language={Language} />
                    <div id="" className='content1'>
                        {/* <!-- [ Main Content ] start --> */}
                        <div className='data_mob'>
                            <div className="row m-1 py-1 pb-4 master_border">
                                <div className='col-8'>
                                    <h5 className="ps-1 pt-1">{t('Sidebar.Employee_Tracking')}</h5>
                                </div>
                                <div className='col-4'>
                                    <button type="button" className="btn btn-primary float-end mr-1">Export</button>
                                </div>

                                <div className="row m-0 mt-1">
                                    <div className="col-12 py-1 rounded rounded-2 shadow"
                                        style={{ border: "1px solid #00000024", background: " #27466317" }}>
                                        <div className="col-12 ">
                                            <div className="row py-1 rounded2">
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"> <b>{t('Dashboard.Work_Assigned_by_Officials.From_Date')} :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="FromDate"
                                                        // value={FromDate}
                                                        // onChange={(e) => (setFromDate(e.target.value), setToDate(''))} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <div className="form-group">
                                                        <label className="d-block" htmlFor="NameofDepartment"><b>{t('Dashboard.Work_Assigned_by_Officials.To_Date')} :</b></label>
                                                        <input type="date"
                                                            className="form-control"
                                                            id="ToDate"
                                                        // value={ToDate}
                                                        // onChange={(e) => setToDate(e.target.value)} 
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    <RoleNameDataDDL
                                                        Role={Role}
                                                        setRole={setRole}
                                                    />
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-2">
                                                    {/* <WardNameDataDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        HWard={HWard}
                                                        WLable={WLable}
                                                        Language={Lang}
                                                    /> */}
                                                    <WardNameDataDDL
                                                        WardName={WardName}
                                                        setWardName={setWardName}
                                                        Language={Language}
                                                        ZoneName={ZoneName}
                                                        Flag='ward'
                                                    />
                                                </div>

                                                {/* {
                                                    ApiFlag === 'WorkAssingPending' || ApiFlag === 'WorkAssingCompleted' ||
                                                        searchName === 'Reopen Pending' || searchName === 'Reopen Completed'

                                                        ?
                                                        <div className="col-12 col-md-6 col-lg-3">
                                                            <WorkAssignByDDL
                                                                WorkAssignedBy={WorkAssignedBy}
                                                                setWorkAssignedBy={setWorkAssignedBy}
                                                                Flag={FlagName}
                                                            />
                                                        </div>
                                                        : <></>
                                                } */}

                                                <div className="col-12 col-lg-3 col-md-4 mt-lg-4 d-md-flex" >
                                                    <button type="button"
                                                        className="btn-clear btn btn-primary mx-2"
                                                    // onClick={handleSearch}
                                                    >
                                                        Search</button>
                                                    <button type="button"
                                                        className="btn btn-outline-danger"
                                                    // onClick={handleClear}
                                                    >
                                                        Clear</button>
                                                </div>
                                            </div>

                                            <div className="row mob_table table-responsive pt-1">
                                                <table
                                                    id="totalordergenerated"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    border="0"
                                                    className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th className="sr_no_col">{t('Dashboard.Pcmc_Assets.Sr_No')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Asset_Name')}</th>
                                                            <th>{t('Dashboard.Work_Assigned_by_Officials.Area1')}</th>
                                                            {/* <th>{t('Dashboard.Pcmc_Assets.Sector_Name')}</th> */}
                                                            <th>{t('Dashboard.Pcmc_Assets.Ward_no')}</th>
                                                            <th>{t('Dashboard.Work_Assigned_by_Officials.Officer_Name')}</th>
                                                            <th>{t('Dashboard.Pcmc_Assets.Status')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ textAlign: "center" }}>1</td>
                                                            <td></td>
                                                            {/* <td></td> */}
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* {tableData && tableData.table && tableData.table.length > 0 &&
                                                <Pegination
                                                    PerPageCount={PerPageCount}
                                                    TotalCount={tableData.table[0].totalCount}
                                                    setFrom={setFrom}
                                                    setTo={setTo}
                                                    setrowNo={setrowNo}
                                                    CurrentPage={CurrentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    IsClear={IsClear}
                                                />
                                            } */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <!--  --> */}


                    </div>
                </div>
                {/* {
                    showImagePopup ?
                        <ViewURL
                            Heading={Heading}
                            setshowImagePopup={setshowImagePopup}
                            ImagePopUpData={ImagePopUpData}

                        />
                        : ''
                } */}
            </div>
        </>

    )
}