import React from 'react'
import { useNavigate } from 'react-router-dom'
import WaterPreventiveWorkCard from './WaterPreventiveWorkCard'


export default function WaterPreventiveWorkCardManage(props) {
    const { Countdata, HeaderMonthID, HeaderFinancialYear, MonthValue, YearValue, YearLabel, MonthLabel, WardValue, WardLabel, ProjectTypeId, DashboardName } = props
 
    const PreventiveWorkNavigate = (name, screenFlag) => {
        if (name === "Water Pressure") {
            navigate(`/waterpressurepreventiveworkpage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Water Flow") {
            navigate(`/waterflowpreventiveworkpage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Water Quantity") {
            navigate(`/waterquantitypreventiveworkpage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Chemical Supply") {
            navigate(`/chemicalsupplypreventiveworkpage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }else if (name === "Water Audit") {
            navigate(`/waterauditpreventiveworkpage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }else if (name === "Energy Consumption") {
            navigate(`/energyconsumptionpreventiveworkpage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }else if (name === "Security") {
            navigate(`/watersecuritypreventiveworkpage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }else if (name === "House Keeping") {
            navigate(`/waterhousekeepingpreventiveworkpage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }else if (name === "Valve  Operation") {
            navigate(`/watervalveoperationpreventiveworkpage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }else if (name === "Distribution Network") {
            navigate(`/watersupplydistributionnetworkpreventiveworkpage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }
    const navigate = useNavigate()

    return (
        <div>
            <div className="row pb-3 p-3">
                <div className="col-12 col-lg-2 mb-2 water_pre_work_width">
                    <WaterPreventiveWorkCard
                        title="Water Pressure"
                        imgPath='./assets/img/waterpressure.png'
                        count="100"
                        // navLink='/waterpressurepreventiveworkpage'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    />
                </div>

                <div className="col-12 col-lg-2 mb-2 water_pre_work_width">
                    <WaterPreventiveWorkCard
                        title="Water Flow"
                        imgPath='./assets/img/water-waves.png'
                        count="100"
                        // navLink='/waterflowpreventiveworkpage'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    
                    />
                </div>

                <div className="col-12 col-lg-2 mb-2 water_pre_work_width">
                    <WaterPreventiveWorkCard
                        title="Water Quantity"
                        imgPath='./assets/img/waterquantity.png'
                        count="100"
                        // navLink='/waterquantitypreventiveworkpage'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    />
                </div>

                <div className="col-12 col-lg-2 mb-2 water_pre_work_width">
                    <WaterPreventiveWorkCard
                        title="Chemical Supply"
                        imgPath='./assets/img/chemistry.png'
                        count="100"
                        // navLink='/chemicalsupplypreventiveworkpage'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    />
                </div>

                <div className="col-12 col-lg-2 mb-2 water_pre_work_width">
                    <WaterPreventiveWorkCard
                        title="Water Audit"
                        imgPath='./assets/img/audit.png'
                        count="100"
                        // navLink='/waterauditpreventiveworkpage'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    />
                </div>
          
                <div className="col-12 col-lg-2 mb-2 water_pre_work_width">
                    <WaterPreventiveWorkCard
                        title="Energy Consumption"
                        imgPath='./assets/img/energy-consumption.png'
                        count="100"
                        // navLink='/energyconsumptionpreventiveworkpage'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    />
                </div>

                <div className="col-12 col-lg-2 mb-2 water_pre_work_width">
                    <WaterPreventiveWorkCard
                        title="Security"
                        imgPath='./assets/img/insurance.png'
                        count="100"
                        // navLink='/watersecuritypreventiveworkpage'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    />
                </div>

                <div className="col-12 col-lg-2 mb-2 water_pre_work_width">
                    <WaterPreventiveWorkCard
                        title="House Keeping"
                        imgPath='./assets/img/house-cleaning.png'
                        count="100"
                        // navLink='/waterhousekeepingpreventiveworkpage'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    />
                </div>

                <div className="col-12 col-lg-2 mb-2 water_pre_work_width">
                    <WaterPreventiveWorkCard
                        title="Valve  Operation"
                        imgPath='./assets/img/valueoperaton.png'
                        count="100"
                        // navLink='/watervalveoperationpreventiveworkpage'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    />
                </div>

                <div className="col-12 col-lg-2 mb-2 water_pre_work_width">
                    <WaterPreventiveWorkCard
                        title="Distribution Network"
                        imgPath='./assets/img/valueoperaton.png'
                        count="100"
                        // navLink='/watervalveoperationpreventiveworkpage'
                        PreventiveWorkNavigate={PreventiveWorkNavigate}
                    />
                </div>
            </div>

        </div>

    )

}
