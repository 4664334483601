
import { useEffect } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'
import { WaterSupplyUserRoleDataDDLAPI } from '../../Redux/DDLSlice'
// import { AstricSign } from '../../Helper/AstricSign'

export const WaterSupplyUserWiseRoleDDL = (props) => {
    const { Role, setRole, rowData, Flag, M_ProjectTypeID } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token, M_ProjectTypeID }
        dispatch(WaterSupplyUserRoleDataDDLAPI({ data }))
    }, [M_ProjectTypeID])

    const { WaterSupplyUserRoleData } = useSelector(state => state.WaterSupplyUserRoleDataDDL)


    useEffect(() => {
        handleRoleDDL()
    }, [WaterSupplyUserRoleData])

    const handleRoleDDL = () => {
        // console.log(DeptDDLDataa)
        if (WaterSupplyUserRoleData && WaterSupplyUserRoleData.table && WaterSupplyUserRoleData.table.length > 0) {
            let list = WaterSupplyUserRoleData.table.map((item, index) => ({
                value: item.id,
                label: item.role,
            }))

            setRole({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_RoleID : 0,
                Label: Flag === 'Update' ? rowData.m_RoleName : "Select...",
            })
        }
        else {
            setRole({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofWorkType"><b>Role :</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                isSearchable
                maxMenuHeight={150}
                value={{ value: Role.ID, label: Role.Label }}
                onChange={(e) => {
                    e ?
                        setRole({ ...Role, ID: e.value, Label: e.label })
                        :
                        setRole({ ...Role, ID: 0, Label: "Select..." })

                }}
                options={Role.DDL}
            />

        </div>
    )
}