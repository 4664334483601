import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import GardenMaintainanceCard from "./GardenMaintainanceCard";
import { useAuthState } from "../../../../../Helper/Context";


export default function GardenMaintainanceCardManage() {
    const Pathname = window.location.pathname
    const navigate = useNavigate()
    const userDetails = useAuthState();
    const { M_ProjectTypeID } = userDetails

    // console.log(Pathname)

    const GardenMaintainanceNavigate = (name, screenFlag) => {
        if (name === "WaterSupply") {
            if (M_ProjectTypeID == 0 || M_ProjectTypeID == 5) {
                navigate(`/watersupply?name=${name}&ProjectTypeId=1&DashboardName=Water Supply`)
            }
        } else if (name === "Sewerage") {
            if (M_ProjectTypeID == 0 || M_ProjectTypeID == 5) {
                navigate(`/sewerage?name=${name}&ProjectTypeId=2&DashboardName=Sewerage`)
            }
        } else if (name === "STP") {
            if (M_ProjectTypeID == 0 || M_ProjectTypeID == 5) {
                navigate(`/stpdashboard?name=${name}&ProjectTypeId=3&DashboardName=STP`)
            }
        } else if (name === "Road Work") {
            if (M_ProjectTypeID == 0 || M_ProjectTypeID == 5) {
                navigate(`/dashboard?name=${name}&ProjectTypeId=4&DashboardName=Road`)
            }
        } else if (name === "Electrical") {
            if (M_ProjectTypeID == 0 || M_ProjectTypeID == 5) {
                // navigate(`/watersupply?name=${name}&ProjectTypeId=1&DashboardName=Electrical`)
            }
        } else if (name === "Garden") {
            if (M_ProjectTypeID == 0 || M_ProjectTypeID == 5) {
                navigate(`/garden?name=${name}&ProjectTypeId=5&DashboardName=Garden`)
            }
        }
    }
    // const GardenMaintainanceNavigate = (name, screenFlag) => {
    //     if (name === "WaterSupply") {
    //         if (M_ProjectTypeID == 0 || M_ProjectTypeID == 5) {
    //             navigate(`/watersupply`)
    //         }
    //     } else if (name === "Sewerage") {
    //         if (M_ProjectTypeID == 0 || M_ProjectTypeID == 5) {
    //             navigate(`/sewerage`)
    //         }
    //     } else if (name === "STP") {
    //         if (M_ProjectTypeID == 0 || M_ProjectTypeID == 5) {
    //             navigate(`/watersupply`)
    //         }
    //     } else if (name === "Road Work") {
    //         if (M_ProjectTypeID == 0 || M_ProjectTypeID == 5) {
    //             navigate(`/dashboard`)
    //         }
    //     } else if (name === "Electrical") {
    //         if (M_ProjectTypeID == 0 || M_ProjectTypeID == 5) {
    //             navigate(`/watersupply`)
    //         }
    //     } else if (name === "Garden") {
    //         if (M_ProjectTypeID == 0 || M_ProjectTypeID == 5) {
    //             navigate(`/garden`)
    //         }
    //     }
    // }

    // console.log(M_ProjectTypeID)

    return (
        <div className="row">
            <div className="col-6 col-md-4 col-lg-2 text-center my-1 my-lg-0 " style={{ borderRight: '1px solid #0000007d' }}>
                <GardenMaintainanceCard
                    imgPath="./assets/img/water-system.png"
                    imgPathw="./assets/img/water-system-w.png"
                    title="WaterSupply"
                    cardName="cursor"
                    Active={Pathname == "/watersupply" ? 'dashCardbgActive pb-2 dashCardbg' : 'dashCardbg pb-2'}
                    // navLink={M_ProjectTypeID == 0 || M_ProjectTypeID == 5 ? "/watersupply" : ''} 
                    GardenMaintainanceNavigate={GardenMaintainanceNavigate}
                    Pathname={Pathname}
                />
            </div>

            <div className="col-6 col-md-4 col-lg-2 text-center my-1 my-lg-0" style={{ borderRight: '1px solid #0000007d' }}>
                <GardenMaintainanceCard
                    imgPath="./assets/img/sewerage.png"
                    imgPathw="./assets/img/sewerage-w.png"
                    title="Sewerage"
                    cardName="cursor"
                    Active={Pathname == '/sewerage' ? 'dashCardbgActive pb-2 dashCardbg' : 'dashCardbg pb-2'}
                    // navLink={M_ProjectTypeID == 0 || M_ProjectTypeID == 5 ? "/sewerage" : ''} 
                    GardenMaintainanceNavigate={GardenMaintainanceNavigate}
                    Pathname={Pathname}
                />

            </div>
            <div className="col-6 col-md-4 col-lg-2 text-center my-1 my-lg-0" style={{ borderRight: '1px solid #0000007d' }}>
                <GardenMaintainanceCard
                    imgPath="./assets/img/water.png"
                    imgPathw="./assets/img/water-w.png"
                    title="STP"
                    cardName="cursor"
                    // Active='dashCardbg pb-2'
                    Active={Pathname == '/stpdashboard' ? 'dashCardbgActive pb-2 dashCardbg' : 'dashCardbg pb-2'}
                    // navLink=""
                    GardenMaintainanceNavigate={GardenMaintainanceNavigate}
                    Pathname={Pathname}
                />
            </div>
            <div className="col-6 col-md-4 col-lg-2 text-center my-1 my-lg-0" style={{ borderRight: '1px solid #0000007d' }}>
                <GardenMaintainanceCard
                    imgPath="./assets/img/work-tools.png"
                    imgPathw="./assets/img/work-tools-w.png"
                    title="Road Work"
                    cardName="cursor"
                    Active={Pathname == '/dashboard' ? 'dashCardbgActive pb-2 dashCardbg' : 'dashCardbg pb-2'}
                    // navLink={M_ProjectTypeID == 0 || M_ProjectTypeID == 5 ? "/dashboard" : ''} 
                    GardenMaintainanceNavigate={GardenMaintainanceNavigate}
                    Pathname={Pathname}
                />
            </div>

            <div className="col-6 col-md-4 col-lg-2 text-center my-1 my-lg-0" style={{ borderRight: '1px solid #0000007d' }}>
                <GardenMaintainanceCard
                    imgPath="./assets/img/sustainable-energy.png"
                    imgPathw="./assets/img/sustainable-energy-w.png"
                    title="Electrical"
                    cardName="cursor"
                    Active='dashCardbg pb-2'
                    // navLink="" 
                    GardenMaintainanceNavigate={GardenMaintainanceNavigate}
                    Pathname={Pathname}
                />
            </div>
            <div className="col-6 col-md-4 col-lg-2 text-center my-1 my-lg-0">
                <GardenMaintainanceCard
                    imgPath="./assets/img/park.png"
                    imgPathw="./assets/img/park-w.png"
                    title="Garden"
                    cardName="cursor"
                    Active={Pathname == "/garden" ? 'dashCardbgActive pb-2 dashCardbg' : 'dashCardbg pb-2'}
                    // navLink={M_ProjectTypeID == 0 || M_ProjectTypeID == 5 ? "/garden" : ''} 
                    GardenMaintainanceNavigate={GardenMaintainanceNavigate}
                    Pathname={Pathname}
                />
            </div>

        </div>
    )
}


