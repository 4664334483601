import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import HorticultureWorKAssignedCard from './HorticultureWorkAssignedByOfficialsCard'
import { DailyCountObj, DailyHorticultureCountObj, TotalDaysHorticultureCountObj, TwoDaysCountObj, TwoDaysHorticultureCountObj } from '../../../../../Helper/DummyCountData'
import { NewDashMainCountDataAPI } from '../../../../../Redux/MainCountSlice/NewDashboardMainCount'
import { useDispatch, useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import { WorkAssignNew } from '../../../../../Helper/Data'
import { Targetdata } from '../../../../../Helper/Graph'
import { HorticultureWorkAssignedCountDataAPI } from '../../../../../Redux/GardenDashboardSlice/GardentMasterCountSlice/WorkAssignedByOfficials/HorticultureWorkAssignedSlice'
import { useAuthState } from '../../../../../Helper/Context'
import { Loading } from '../../../../../Helper/Loading'
import { useTranslation } from 'react-i18next'

export default function HorticultureWorKAssignedCardManage(props) {
    const { Language, MonthValue, YearValue, YearLabel, MonthLabel, WardValue, WardLabel, ZoneValue, ZoneLabel, WardName, setWardName } = props

    const { t } = useTranslation()

    // console.log("Language",Language.Label)

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails

    const dispatch = useDispatch()

    const [searchParams] = useSearchParams()
    let ProjectTypeId = searchParams.get("ProjectTypeId")
    let DashboardName = searchParams.get("DashboardName")

    const [WorkPeriodID, setWorkPeriodID] = useState("0")
    const [DashProjectTypeId, setDashProjectTypeId] = useState(localStorage.getItem("FJ853K") ? localStorage.getItem("FJ853K") : "6")

    const navigate = useNavigate()

    const HorticultureWorKAssignedCardNavigate = (name, RoleID, DDLApiFlag) => {
        // navigate(`/workassignedbyofficialtable?name=${name}&RoleID=${RoleID}&WorkPeriodID=${WorkPeriodID}&DDLApiFlag=${DDLApiFlag}&ApiFlag=DeputyMunicipalCommissioner&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        window.open(`/workassignedbyofficialtable?name=${name}&RoleID=${RoleID}&WorkPeriodID=${WorkPeriodID}&DDLApiFlag=${DDLApiFlag}&ApiFlag=DeputyMunicipalCommissioner&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ZoneName=${ZoneValue}&ZLable=${ZoneLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`);
    }

    const [CountData, setCountData] = useState([])

    const [Days, setDays] = useState()

    useEffect(() => {
        const data = {
            M_UserID: UserID,
            token: token,
            Flag: "HorticultureWork",
            Language: Language.Label,
            setDays: setDays

        }
        // console.log(Language.Label)
        { Language.Label != '' && dispatch(HorticultureWorkAssignedCountDataAPI({ data })) }
    }, [Language.Label])

    const { Data, isCountLoading } = useSelector(state => state.HorticultureWorkAssignedCountData)

    const [GraphLable, setGraphLable] = useState([])

    useEffect(() => {
        const data = {
            M_FinancialYearID: YearValue,
            M_WardID: WardName.ID,
            M_MonthID: MonthValue,
            M_UserID: UserID,
            Flag: "HorticultureWorkAssigned",
            M_GD_WorkPeriodID: WorkPeriodID,
            token: token,
            setCountData: setCountData,
            setGraphLable: setGraphLable,
            M_ZoneID: ZoneValue,
            Language: Language.Label,
            // handleDashboardGetCount: handleDashboardGetCount
        }
        if (YearValue && Language.Label && WardName && ZoneValue) {
            { Language.Label != '' && dispatch(NewDashMainCountDataAPI({ data })) }
        }
    }, [YearValue, MonthValue, WorkPeriodID, Language.Label, WardName.ID, ZoneValue])

    const { tableData, isLoading } = useSelector(state => state.NewDashMainCountData)

    const Targetdata = {

        labels: GraphLable,
        datasets: [
            {
                label: "Horticulture Work Assigned by Officials",
                fill: true,
                backgroundColor: [
                    "#ff526f",
                    "#ff526f",
                    "#ff526f",
                    "#ff526f",
                    "#ff526f",
                    "#ff526f",
                    "#ff526f",
                    "#ff526f",
                    "#ff526f",
                    "#ff526f",
                    "#ff526f",
                    "#ff526f",
                    "#ff526f",
                    "#ff526f",
                    "#ff526f"
                ],
                // data: [WorkAssignedTotalCount.table[0].DeputyMunicipalCommissioner]
                data: CountData.map(i => i.totalCount)
            },
        ],
    };



    // useLayoutEffect(() => {
    //     setWorkPeriodID(Days && Days[0].m_gD_WorkPeriodID)
    // }, [Days])
    return (
        <>
            {/* {(isCountLoading) && <Loading />} */}
            {/* {(isLoading) && <Loading />} */}
            <div className='card_heading d-lg-flex justify-content-center align-item-center mb-4 mt-n2'>
                <div className="col-12 col-md-12 col-lg-4 py-2 pt-3">
                    <span className="text-white ml-lg-3 fw-bold" style={{ fontFamily: 'poppins', fontSize: '16px' }}>{t('Dashboard.Titles.Horticulture_Work_Assigned_by_Officials')} </span>
                </div>

                <div className='col-12 col-md-12 col-lg-8 mx-auto pt-2 '>
                    <span className="card-headerbtnhor font-weight-bold py-3 ">
                        <ul className="nav nav-tabs float-end me-lg-3 mb-md-2 mr-2">
                            <li className='border' onClick={() => setWorkPeriodID("0")}>
                                <a data-toggle="tab"
                                    className={WorkPeriodID === "0" ? "active text-black" : "text-white"}><p className="fonttab" style={{ cursor: "pointer" }}>Total</p></a>
                            </li>
                            {
                                Days && Days.length > 0 &&
                                Days.map((i) => {
                                    return (
                                        <li className='border'
                                            onClick={() => (setWorkPeriodID(i.m_gD_WorkPeriodID))}>
                                            <a data-toggle="tab" className={WorkPeriodID === i.m_gD_WorkPeriodID ? "active text-black" : "text-white"}>
                                                {/* <a data-toggle="tab"> */}
                                                <p className="fonttab" style={{ cursor: "pointer" }}>{i.parameterDescription}</p>
                                            </a>
                                        </li>
                                    )
                                })
                            }


                        </ul>
                    </span>
                </div>
            </div>

            <div className="col-12 col-lg-7 ">
                <div className="row align-items-start">

                    {
                        CountData && CountData.length > 0 ?
                            CountData.map((i) => {
                                return (
                                    <div className="col-12 col-lg-6 col-md-6 mb-md-2 mb-2 mb-lg-0">
                                        <HorticultureWorKAssignedCard
                                            title={i?.roleName}
                                            cardName='py-2 wwork_img cursor'
                                            imgPath='./assets/img/manager.png'
                                            count={i.totalCount ? i.totalCount : '0'}
                                            RoleID={i?.m_RoleID}
                                            HorticultureWorKAssignedCardNavigate={HorticultureWorKAssignedCardNavigate}
                                        />
                                    </div>
                                )
                            })
                            : <p className='text-center fw-bold'>No Data found</p>
                    }

                </div>
            </div>
            {
                CountData.some(i => i.totalCount !== 0) ?
                    <div className="col-12  col-lg-5">
                        <div className="row">
                            <div className="col-12">
                                <div className="px-5 pt-4 upload_chart bg-white" style={{ borderRadius: "7px", marginTop: "-0.6em" }}>
                                    <div className="row d-flex justify-content-center align-items-center">
                                        <Bar
                                            options={WorkAssignNew}
                                            data={Targetdata}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-12 col-lg-5 col-md-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <text>No Data Found</text>
                    </div>
            }


        </>
    )

}

