import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const WorkSubTypeTableDataAPI = createAsyncThunk("WorkSubTypeTableData", async ({ data }) => {
    const {

        WorkSubTypeName,
        M_WorkTypeID,
        M_WorkSubTypeID,
        M_UserID,
        token,
        From,
        To,
        Language,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_WorkSubType_Select?M_WorkSubTypeID=${M_WorkSubTypeID ? M_WorkSubTypeID : '0'}&M_WorkTypeID=${M_WorkTypeID ? M_WorkTypeID : '0'}&WorkSubTypeName=${WorkSubTypeName}&M_UsersID=${M_UserID}&From=${From}&To=${To}&Language=${Language}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const WorkSubTypeTableDataSlice = createSlice({
    name: "WorkSubTypeTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkSubTypeTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkSubTypeTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WorkSubTypeTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WorkSubTypeTableDataReducer = WorkSubTypeTableDataSlice.reducer


export const WorkSubTypePostDataAPI = createAsyncThunk("WorkSubTypePostData", async ({ data }) => {
    const {
        M_WorkSubTypeID,
        M_WorkTypeID,
        WorkSubTypeName,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleClearField,
        handleOnCloseClick
    } = data
    // console.log(M_WorkTypeID)

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WorkSubTypeID", M_WorkSubTypeID);
    formdata.append("M_WorkTypeID", M_WorkTypeID);
    formdata.append("WorkSubTypeName", WorkSubTypeName);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Post_M_WorkSubType_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                handleClearField()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const WorkSubTypePostDataSlice = createSlice({
    name: "WorkSubTypePostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkSubTypePostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(WorkSubTypePostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(WorkSubTypePostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WorkSubTypePostDataReducer = WorkSubTypePostDataSlice.reducer

// // -----------------Delete API ---------------

export const WorkSubTypeDeleteAPI = createAsyncThunk("WorkSubTypeDelete", async ({ data }) => {
    const {
        M_WorkSubTypeID,
        M_UserID,
        token,
        Flag,
        handleIsPost
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_WorkSubTypeID", M_WorkSubTypeID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_WorkSubType_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
            } else {
                toastErrorr(result.message)

            }
            return result

        })
})

const WorkSubTypeDeleteSlice = createSlice({
    name: "WorkSubTypeDelete",
    initialState: {
        isDeleteLoading: false,
        WorkSubTypetableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(WorkSubTypeDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(WorkSubTypeDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.WorkSubTypetableData = action.payload;

        });
        builder.addCase(WorkSubTypeDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.WorkSubTypetableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const WorkSubTypeDeleteReducer = WorkSubTypeDeleteSlice.reducer