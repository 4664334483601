
import { useEffect } from 'react'
import Select from 'react-select'
import { DepartmentDDLAPI } from '../../Redux/DDLSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from '../../Helper/Context'

export const DepartmentDataDDL = (props) => {
    const { Department, setDepartment, CropNameDashboardData, rowData, Flag } = props

    const userDetails = useAuthState();
    const { UserID, token, } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        const data = { UserID, token }
        dispatch(DepartmentDDLAPI({ data }))
    }, [])

    const { DepDDLData } = useSelector(state => state.DepartmentDDLData)

    useEffect(() => {
        handleDepartmentDDL()
    }, [DepDDLData])

    const handleDepartmentDDL = () => {
        if (DepDDLData && DepDDLData.table && DepDDLData.table.length > 0) {
            let list = DepDDLData.table.map((item, index) => ({
                value: item.id,
                label: item.departmentName,
            }))

            setDepartment({
                DDL: list,
                ID: Flag === 'Update' ? rowData.m_DepartmentID : 0,
                Label: Flag === 'Update' ? rowData.departmentName : "Select...",
            })
        }
        else {
            setDepartment({
                DDL: [],
                ID: 0,
                Label: "Select...",
            })
        }

    }

    return (
        <div className="form-group">
            <label className="d-block" htmlFor="NameofDepartment"><b>Department</b></label>
            <Select
                isClearable
                // isRtl={isRtl}
                // className="ddl_width"
                isSearchable
                maxMenuHeight={150}
                value={{ value: Department.ID, label: Department.Label }}
                onChange={(e) => {
                    e ?
                        setDepartment({ ...Department, ID: e.value, Label: e.label })
                        :
                        setDepartment({ ...Department, ID: 0, Label: "Select..." })

                }}
                options={Department.DDL}
            />
        </div>
    )
}