import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Helper/BaseUrl";
import { toastErrorr, toastSuccesss } from "../../Helper/ToastMessage";


// Get Table Data
export const LocationNameMasterTableDataAPI = createAsyncThunk("LocationNameMasterTableData", async ({ data }) => {
    const {
        M_RM_LocationID,
        M_UserID,
        token,
        From,
        To,
        M_WardID,
    } = data


    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/Get_M_RM_Location_Select?M_WardID=${M_WardID ? M_WardID : '0'}&M_RM_LocationID=${M_RM_LocationID ? M_RM_LocationID : '0'}&M_UserID=${M_UserID}&From=${From}&To=${To}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                return result.data
            } else {
                return result
            }
        })
})

const LocationNameMasterTableDataSlice = createSlice({
    name: "LocationNameMasterTableData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(LocationNameMasterTableDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(LocationNameMasterTableDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(LocationNameMasterTableDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            toastErrorr('Something went wrong')
        });
    },
})

export const LocationNameMasterTableDataReducer = LocationNameMasterTableDataSlice.reducer


// InsertUpdate Table Data

export const LocationNameMasterPostDataAPI = createAsyncThunk("LocationNameMasterPostData", async ({ data }) => {
    const {
        M_RM_LocationId,
        M_WardID,
        LocationName,
        M_UserID,
        token,
        Flag,
        handleIsPost,
        handleOnCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_RM_LocationId", M_RM_LocationId);
    formdata.append("M_WardID", M_WardID);
    formdata.append("LocationName", LocationName);
    formdata.append("M_UserID", M_UserID);
    formdata.append("Flag", Flag);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    fetch(`${BaseUrl}/Master/Post_M_RM_Location_InsertUpdate`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.code >= 200 && result.code <= 300 && result.data) {
                handleIsPost()
                handleOnCloseClick()
                toastSuccesss(result.message)
                return result.data
            } else {
                toastErrorr(result.message)
            }
            return result
        })
})

const LocationNameMasterPostDataSlice = createSlice({
    name: "LocationNameMasterPostData",
    initialState: {
        isLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(LocationNameMasterPostDataAPI.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(LocationNameMasterPostDataAPI.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tableData = action.payload;
        });
        builder.addCase(LocationNameMasterPostDataAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const LocationNameMasterPostDataReducer = LocationNameMasterPostDataSlice.reducer



// Delete Table Data

export const LocationNameMasterDeleteAPI = createAsyncThunk(" LocationNameMasterDelete", async ({ data }) => {
    const {
        M_RM_LocationID,
        M_UserID,
        handleIsPost,
        token,
        handleDeleteCloseClick
    } = data

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);

    var formdata = new FormData();
    formdata.append("M_RM_LocationID", M_RM_LocationID);
    formdata.append("M_UserID", M_UserID);

    var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    return fetch(`${BaseUrl}/Master/M_RM_Location_Delete`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
            if (result.status) {
                handleIsPost()
                toastSuccesss(result.message)
                handleDeleteCloseClick()
            } else {
                toastErrorr(result.message)
                handleDeleteCloseClick()
            }
            return result

        })
})

const LocationNameMasterDeleteSlice = createSlice({
    name: "LocationNameMasterDelete",
    initialState: {
        isDeleteLoading: false,
        tableData: null,
        isError: false,
    },
    extraReducers: (builder) => {
        builder.addCase(LocationNameMasterDeleteAPI.pending, (state, action) => {
            state.isDeleteLoading = true;
        });
        builder.addCase(LocationNameMasterDeleteAPI.fulfilled, (state, action) => {
            state.isDeleteLoading = false;
            state.tableData = action.payload;

        });
        builder.addCase(LocationNameMasterDeleteAPI.rejected, (state, action) => {
            console.log("Error", action.payload);
            state.isDeleteLoading = false;
            state.isError = true;
            state.tableData = null;
            // toastErrorr('Something went wrong')
        });
    },
})

export const LocationNameMasterDeleteReducer = LocationNameMasterDeleteSlice.reducer