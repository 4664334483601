import React from 'react'
import { useNavigate } from 'react-router-dom'
import WaterMasterCard from './WaterMasterCard'

export default function WaterMasterCardManage(props) {

    const { Countdata,HeaderMonthID,HeaderFinancialYear , MonthValue, YearValue , YearLabel,MonthLabel,WardValue,WardLabel,ProjectTypeId,DashboardName } = props

    const MasterNavigate = (name, screenFlag) => {
        if (name === "Pipeline") {
            navigate(`/pipelinemasterpage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Pump Station") {
            navigate(`/pumpingstationmasterpage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Work Master") {
            navigate(`/workmasterpage?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        } else if (name === "Contractor Wise Ward Name") {
            navigate(`/contractorwisewardname?name=${name}&Year=${YearValue}&YLable=${YearLabel}&Month=${MonthValue}&MLable=${MonthLabel}&WardName=${WardValue}&WLable=${WardLabel}&ProjectTypeId=${ProjectTypeId}&DashboardName=${DashboardName}`)
        }
    }
    const navigate = useNavigate()
    return (
        <div>
            <div className="row">
                <h5 className="ml-4 py-2">Master</h5>
            </div>

            <div className='row pr-3 pl-3'>
                <div className='col-12 col-md-6 col-lg-3'>
                    <WaterMasterCard
                        title="Pipeline"
                        cardName='py-2 green cursor'
                        border='green_border'
                        // count={Countdata.WorkTypeTotalCount}
                        count="100"
                        imgPath="./assets/img/pipeline.png"
                        // navLink='/pipelinemasterpage'
                        MasterNavigate={MasterNavigate}
                        color='red'
                    />
                </div>
                <div className='col-12 col-md-6 col-lg-3'>
                    <WaterMasterCard
                        title="Pump Station"
                        cardName='py-2 blue cursor'
                        border='blue_border'
                        // count={Countdata.WorkNameTotalCount}
                        count="100"
                        imgPath="./assets/img/watersupply-water-pump.png"
                        // navLink='/pumpingstationmasterpage'
                        MasterNavigate={MasterNavigate}
                        color='blue'
                    />
                </div>

                <div className='col-12 col-md-6 col-lg-3'>
                    <WaterMasterCard
                        title="Work Master"
                        cardName='py-2 green cursor'
                        border='green_border'
                        // count={Countdata.PumpSetDetailsTotalCount}
                        count="100"
                        imgPath="./assets/img/ward (2).png"
                        // navLink='/workmasterpage'
                        MasterNavigate={MasterNavigate}
                        color='red'
                    />
                </div>

                <div className='col-12 col-md-6 col-lg-3'>
                    <WaterMasterCard
                        title="Contractor Wise Ward Name"
                        cardName='py-2 blue cursor'
                        border='blue_border'
                        // count={Countdata.PipelineDetailsTotalCount}
                        count="100"
                        imgPath="./assets/img/data-processing.png"
                        // navLink='/contractorwisewardname'
                        MasterNavigate={MasterNavigate}
                        color='blue'
                    />
                </div>

            </div>

        </div>


    )

}

