import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function WaterPreMainWorkCard({ count, heading, cardName, imgPath, navLink,title, countText, WaterPreMainWorkCardNavigate, color }) {
    const navigate = useNavigate()

    // const handleWaterPreMainWorkCardClick = () => {
    //     navigate(navLink)
    // }
    return (

        <div style={{ height: '100%', cursor: 'pointer' }} className="p-3 shadow blue_borders bg-white mb-md-2 daily-card-width" onClick={() => WaterPreMainWorkCardNavigate(title)}>
            <div className="d-flex flex-column justify-content-between" style={{ height: '100%' }}>
                <div className="text-center" >
                    <div className="d-flex justify-content-between align-items-center">
                        <h4>{count}</h4>
                        <img className={cardName} style={{ height: '40px', width: '40px' }} src={imgPath} alt="" />
                    </div>

                </div>
                <div style={{ borderLeft: '2px solid #d6bea7' }}>
                    <h6 className="text-black ml-3"><b>{title}</b></h6>
                </div>
            </div>
        </div>
    )
}


